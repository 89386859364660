<template>
  <Ethereum :callback="initialize" />
  <HowToPlay id="howtoplay" />
  <Footer id="footer" />
</template>

<script setup>
import Ethereum from '/src/components/common/Ethereum.vue';
import HowToPlay from './components/HowToPlay.vue';
import Footer from '../home/components/Footer.vue';

import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter} from 'vue-router';

const store = useStore();
const router = useRouter();

const isLoggedIn = computed(() => {
  return store.state.ethers.address;
});

const initialize = async () => {};

const isHome = computed(() => {
  return router.currentRoute.value.path === '/';
});

const buttonMsg = computed(() => {
  return "MINT";
});

onMounted(() => {
  window.scrollTo(0,0);
});
</script>
