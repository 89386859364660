'use strict';

// Imports.
import { stakerService } from '../../services';
import { errMsg } from '../../utility';

// Specified actions for mutating the alert state.
export default {

  // Use the staker service to load all known pools
  async loadPools(
    { dispatch, commit },
  ) {
    try {
      let response = await stakerService.loadPools(dispatch);
      commit('updatePools', response);

      // Catch shop retrieving errors.
    } catch (error) {
      const err = errMsg(error);
      await dispatch('alert/error', err, { root: true });
    }
  },

  // Use the staker service to load the status of all staked NFTs (for this user)
  async loadStakedStatus(
    { dispatch, commit },
    { legacy, pools, owned },
  ) {
    try {
      if (!legacy) {
        // call legacy (staker-v1), and if v2 is enabled, then populate the right fields
        let response = await stakerService.loadStakedStatus(pools, owned, true, dispatch);
        commit('updateLegacyStaked', response);
      }
      let response = await stakerService.loadStakedStatus(pools, owned, false, dispatch);
      commit('updateStaked', response);

      // Catch shop retrieving errors.
    } catch (error) {
      const err = errMsg(error);
      await dispatch('alert/error', err, { root: true });
    }
  },

  // Use the staker service to load user's BLOOD balance
  async loadTokenInfo(
    { dispatch, commit },
    { legacy },
  ) {
    try {
      if (!legacy) {
        // call legacy (staker-v1), and if v2 is enabled, then populate the right fields
        let response = await stakerService.loadTokenInfo(true, dispatch);
        commit('updateLegacyTokenInfo', response);
      }
      let response = await stakerService.loadTokenInfo(false, dispatch);
      commit('updateTokenInfo', response);

      // Catch shop retrieving errors.
    } catch (error) {
      const err = errMsg(error);
      await dispatch('alert/error', err, { root: true });
    }
  },

  // stake item into staker
  async stake(
    { dispatch, commit },
    { nfts, poolId },
  ) {
    try {
      await stakerService.stake(nfts, poolId, dispatch);

      // Catch and notify the user of a purchase error.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // unstake item into staker
  async unstake(
    { dispatch, commit },
    { nfts, poolId, legacy },
  ) {

    try {
      await stakerService.unstake(nfts, poolId, legacy, dispatch);

      // Catch and notify the user of a purchase error.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // claim blood from pools
  async claim(
    { dispatch, commit },
    { },
  ) {
    try {
      await stakerService.claim(dispatch);

      // Catch and notify the user of a purchase error.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Use the staker service to load user's claim events
  async loadClaimEvents(
    { dispatch, commit },
    { },
  ) {
    try {
      let response = await stakerService.loadClaimEvents(dispatch);
      commit('updateClaims', response);

      // Catch shop retrieving errors.
    } catch (error) {
      const err = errMsg(error);
      await dispatch('alert/error', err, { root: true });
    }
  },
};
