export const HOME_WHAT_IS_ITEM = {
  title: 'WHAT IS <span>IMPOSTORS</span>',
  subcontent: `
    Metaverses are boring without games, and competitive games lack social connection.
    There needs to be a middle ground. Social gaming bridges the gap between today’s Twitch culture and the future of immersive metaverses.
    <br><br>
    Impostors is a AAA social gaming metaverse that is truly player-owned and powered. Impostors’ first game mode will be a social deduction game.
    <br><br>
    The Impostors Genesis drop will give holders access to all Genesis Season events. Each event provides access to a different part of the Impostorverse.
  `
};

export const HOME_INFO_CARDS = [
  {
    title: 'Play and Earn',
    subcontent: 'NFT holders will earn tradeable NFTs and fungible tokens simply from playing the game.',
    background: 'pink',
    width: 378
  },
  {
    title: 'Community Owned',
    subcontent: 'Token holders will own and control the future of the Impostors Metaverse governed through the community DAO.',
    background: 'blue',
    width: 470
  },
  {
    title: 'Social Metaverse',
    subcontent: 'Social deduction is the first game mode. Custom game modes, map makers, and metaverse expansion are planned for future releases.',
    background: 'yellow',
    width: 425
  }
];

export const HOME_TEASER_VIDEOS = [
  {
    img: require('@/assets/teaser/teaser_001.png'),
    video: "link_to_video",
  },
  {
    img: require('@/assets/teaser/teaser_002.png'),
    video: "link_to_video",
  },
  {
    img: require('@/assets/teaser/teaser_003.png'),
    video: "link_to_video",
  },
  {
    img: require('@/assets/teaser/teaser_004.png'),
    video: "link_to_video",
  },
  {
    img: require('@/assets/teaser/teaser_001.png'),
    video: "link_to_video",
  },
];

export const HOME_ROADMAP_HEADER = {
  title: 'GENESIS ROADMAP',
  subcontent: 'All events will be open to genesis holders if they don’t act Sus.<br />$BLOOD will be required for all Genesis Season events.'
};

export const HOME_ROADMAP_PHASES = [
  {
    background: 'blue',
    items: [
      {
        icon: 'axe',
        title: 'Week 2 - Staking',
        subcontent: 'Stake your Impostors NFT and start earning $BLOOD'
      },
      {
        icon: 'ufo',
        title: 'Week 4 - UFOs',
        subcontent: 'Genesis UFOs mint'
      },
      {
        icon: 'pet',
        title: 'Week 6 - Pets',
        subcontent: 'Genesis Pets mint'
      },
      {
        icon: 'gift',
        title: 'Week 8 - Materials',
        subcontent: 'Materials are used to upgrade UFOs and train Pets'
      },
      {
        icon: 'chest',
        title: 'Week 10 - Cosmetics Chest',
        subcontent: 'Look fly in the game with some loot'
      },
      {
        icon: 'arcade',
        title: 'Super Sus Week - Closed Beta Access Pass',
        subcontent: 'Let the games begin with our sus community!'
      },
      {
        icon: 'calendar',
        title: 'Season Culmination - Genesis Land Mint',
        subcontent: 'Combine your full item set into ImpostorVerse land!'
      }
    ]
  },
  {
    background: 'reverse-blue',
    items: [
      {
        icon: 'face',
        title: 'Open Beta Launch',
      },
      {
        icon: 'vector',
        title: 'Battle pass',
      },
      {
        icon: 'gps-left',
        title: 'Map maker',
      }
    ]
  },
  {
    background: 'green',
    items: [
      {
        icon: 'gps-right',
        title: 'Land goes live',
      },
      {
        icon: 'settings',
        title: 'Game mode maker',
      },
    ]
  },
];

export const HOME_TEAM_HEADER = {
  title: 'TEAM',
  subcontent: 'Impostors is a social gaming metaverse built in Unreal Engine that has been in production since early 2020. The team is composed of over 30 world-class engineers and builders with experience working at AAA studios including Epic Games, Roblox, Pixar, and more..'
};

export const HOME_TEAM_MEMBERS = [
  {
    name: 'Elliot Wainman',
    avatar: 'ellio',
    position: 'Creator of EllioTrades, Co-Founder of SuperFarm',
    details: 'Elliot is one of the most influential NFT and Crypto thought leaders with over 1.2M followers across YouTube and Twitter. He has been advocating for NFT gaming since 2018.'
  },
  {
    name: 'Tim Clancy',
    avatar: 'vogu',
    position: 'Lead Blockchain Architect at SuperFarm',
    details: 'Tim Clancy works at the forefront of NFT gaming, pioneering some of the first blockchain games at Enjin. Tim also worked for Amazon and at Electronic Arts contributing to Battlefield 1.'
  }
];

export const HOME_TEAM_WORKS = [
  { name: 'roblox', src: 'assets/team-worked-1.png' },
  { name: 'farcry', src: 'assets/team-worked-2.png' },
  { name: 'trover', src: 'assets/team-worked-6.png' },
  { name: 'gears', src: 'assets/team-worked-4.png' },
  { name: 'bards', src: 'assets/team-worked-5.png' },
  { name: 'squad', src: 'assets/team-worked-3.png' },
  { name: 'amazon', src: 'images/amazon-logo.png' },
  { name: 'enjin', src: 'images/enjin-logo.svg' },
  { name: 'pixar', src: 'images/pixar-logo.png' },
];

export const HOME_QUESTION_HOW_TO_PLAY = {
  title: 'HOW TO PLAY',
  subcontent: `
    Impostors is a game of survival. Players have the role of either Innocent or Impostor in a murder mystery-themed gameplay.
    <br><br>
    As an Innocent, your goal is to find the killer and vote off all Impostors or complete all tasks before the Impostors try to kill you -and everyone else.
    Impostors have only one goal: kill them all!
  `,
  background: 'pink',
  to: '/nfts',
  buttonLabel: 'LEARN MORE',
};

export const HOME_QUESTION_FAQS = {
  title: 'FAQs',
  subcontent: `
    Questions regarding Mint?
    <br>
    NFTs? Gameplay?
    <br><br>
    Check out our FAQ section
  `,
  background: 'blue',
  to: '/faq',
  buttonLabel: 'READ NOW',
};
