<script setup>
import { Tippy } from 'vue-tippy';
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import { computed } from '@vue/reactivity';

const props = defineProps({
  text: { type: String },
  hidden: { type: Boolean }
});

let hideTooltip = computed(() => props.hidden || !props.text);
</script>

<template>
  <Tippy zIndex="99999">
    <slot></slot>
    <template #content>
      <SkewedDiv background="#1C368F" :border-radius="14" :skew="[0, 12, 4, -3]" :class="s.tooltip"
        :style="[hideTooltip && { display: 'none' }]" borderWidth="2px" borderColor="#F0F907" clipContent>
        <div :class="s.tooltipTitleWrapper">
          <slot name="title"></slot>
        </div>
        <SkewedDiv background="#1D3279" :border-radius="12" :skew="[[2, 2], -2, 4, -3]" :class="s.tooltipContent">
          <slot name="content" />
        </SkewedDiv>
      </SkewedDiv>
    </template>
  </Tippy>
</template>

<style module="s" lang="scss">
.tooltip {
  z-index: 10000;
  position: relative;
  margin-bottom: 8px;

  &:before, &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-top: 6px solid #F0F907;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  &:before {
    border-top-width: 8px;
  }

  &:after {
    bottom: -6px;
    border-top-width: 8px;
    border-top-color: #1D3279;
  }
}

.tooltipTitleWrapper {
  padding: 24px;
  padding-bottom: 16px;
}

.tooltipContent {
  padding: 24px;
}
</style>
