<template>
  <div class="mint-card-wrapper">
    <img src="~@/images/logo.svg" class="header-logo" alt="impostors" />
    <SkewedDiv
      :borderRadius="14"
      :skew="[0, 10, 17, -16]"
      :background="'linear-gradient(to right, #849BE5,#2E4EB6)'"
      class="mint-card"
      :clipContent="true"
    >
      <div v-if="isComingSoon" class="coming-soon-wrapper">
        <img
          src="~@/assets/impostor-music-listening.webp"
          alt="impostor listening to music"
          class="impostor-music"
        />
        <img
          src="~@/assets/coming-soon.png"
          alt="coming soon"
          class="coming-soon-text"
        />
      </div>

      <div :class="{ 'coming-soon': props.isComingSoon }">
        <template v-if="!soldOut">
          <div class="mint-card_wrapper">
            <template v-if="props.isWhitelist">
              <div class="title-whitelist">
                <h1>Whitelist</h1>
                <div class="status-chip">Live</div>
              </div>
            </template>

            <SkewedDiv
              :borderRadius="6"
              :skew="[0, 0, 8, -8]"
              :background="isWhitelist ? 'black' : 'transparent'"
              opacity="20%"
              class="whitelist-wrapper"
            >
              <template v-if="props.isWhitelist">
                <div class="title-wrapper">
                  <SuperEthToggle
                    v-if="props.isConnected && !whitelistDenied"
                    v-model="toggleModel"
                    :value1="'SUPER'"
                    :value2="'ETH'"
                  />

                  <div class="whitelist-price">
                    <h2>Current Price</h2>
                    <div class="price">{{ price }} {{ props.paymentType }}</div>
                  </div>
                </div>
              </template>
              <template v-else>
                <h1>Current Price</h1>
                <div class="price">{{ price }} ETH</div>
              </template>

              <SkewedDiv
                :borderRadius="6"
                :background="
                  whitelistDenied ? '#222' : isWhitelist ? '#8297DC' : 'white'
                "
                :opacity="
                  whitelistDenied ? '40%' : isWhitelist ? '100%' : '20%'
                "
                :skew="[0, 0, 8, -3]"
              >
                <div v-if="whitelistDenied" class="whitelist-denied-message">
                  <img src="@/assets/cry-emoji.png" />
                  <p>Sorry you are not eligible</p>
                </div>

                <div
                  class="mint-controls-box"
                  :class="{
                    'section-disabled': !props.isConnected,
                    'not-whitelisted': whitelistDenied
                  }"
                >
                  <h2>Mint</h2>
                  <div class="mint-controls">
                    <SkewedButton
                      :borderRadius="6"
                      class="button button_increment button_increment-minus"
                      background="var(--color-button-yellow)"
                      @click="decMintQty"
                      :skew="[0, -1, -2, -4]"
                      :disabled="decDisabled"
                    >
                      <MinusIcon />
                    </SkewedButton>
                    <div class="mint-qty">{{ props.mintQty }}</div>
                    <SkewedButton
                      :borderRadius="6"
                      class="button button_increment button_increment-plus"
                      background="var(--color-button-yellow)"
                      :skew="[1, 0, 4, 2]"
                      @click="incMintQty"
                      :disabled="incDisabled"
                    >
                      <PlusIcon />
                    </SkewedButton>
                  </div>
                  <div class="mint-cost">
                    Total
                    <span class="mint-cost_amount">
                      {{ mintCost }}
                      {{ props.paymentType }}
                    </span>
                  </div>
                </div>

                <div v-if="!props.isConnected" class="connect-wallet-wrapper">
                  <ConnectWallet />
                </div>
              </SkewedDiv>
            </SkewedDiv>

            <SkewedButton
              v-if="showMintButton && showApproveButton"
              :borderRadius="6"
              :skew="[0, -2, 0, [4, 4]]"
              background="var(--color-button-yellow)"
              class="mint-button"
              @click="$emit('approve')"
              :disabled="approveButtonDisabled || props.transactionSubmitting"
              :isLoading="props.transactionSubmitting"
              >{{ approveButtonMsg }}</SkewedButton
            >

            <SkewedButton
              v-else-if="showMintButton"
              :borderRadius="6"
              :skew="[0, -2, 0, [4, 4]]"
              background="var(--color-button-yellow)"
              class="mint-button"
              @click="mint(1, true)"
              :disabled="
                mintButtonState.disabled || props.transactionSubmitting
              "
              :isLoading="props.transactionSubmitting"
              >{{ mintButtonState.msg }}</SkewedButton
            >

            <div class="allocations">
              <div class="allocation-block">
                <h4 class="allocation-title">
                  <span>Whitelist Allocation</span>

                  <Tippy>
                    <InfoIcon />
                    <template #content>
                      <SkewedDiv
                        background="#2e4596"
                        :border-radius="4"
                        :skew="[0, 4, 6, -3]"
                        class="tooltip"
                      >
                        The number of NFTs you can mint during the whitelist.
                      </SkewedDiv>
                    </template>
                  </Tippy>
                </h4>
                <p class="allocation-stat">
                  {{ whitelistAllocRemaining }}
                  <span>remaining</span>
                </p>
              </div>
              <div class="allocation-block">
                <h4 class="allocation-title">
                  <span>Total Allocation</span>

                  <Tippy>
                    <InfoIcon />
                    <template #content>
                      <SkewedDiv
                        background="#2e4596"
                        :border-radius="4"
                        :skew="[0, 4, 6, -3]"
                        class="tooltip"
                      >
                        The number of NFTs you can mint from whitelist and
                        public.
                      </SkewedDiv>
                    </template>
                  </Tippy>
                </h4>
                <p class="allocation-stat">
                  {{ publicAllocRemaining }}
                  <span>remaining</span>
                </p>
              </div>
            </div>
          </div>
        </template>

        <template v-if="soldOut">
          <div class="sold-out-wrapper">
            <img src="@/assets/sold-out.png" class="sold-out-img" />
          </div>
        </template>

        <SkewedDiv
          class="sale-progress"
          :class="{ 'sold-out': soldOut }"
          :borderRadius="12"
          :skew="[[11, 8], [11, 0], 16, -16]"
          :background="!soldOut ? 'black' : '#455BA3'"
          :opacity="!soldOut ? '20%' : '100%'"
        >
          <div class="sale-progress_wrapper">
            <span>
              Amount Sold
              <span class="sold-qty">{{ soldQty }}</span>
            </span>
            <span class="sale-total">Total: {{ totalQty }}</span>
          </div>
          <div class="progress-bar_wrapper">
            <ProgressBar
              :progress="mintProgress"
              class="progress-bar"
              :barBackground="soldOut ? '#ff3333' : undefined"
            />
            <p v-if="soldOut">Sold Out</p>
          </div>
        </SkewedDiv>
      </div>
    </SkewedDiv>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { Tippy } from 'vue-tippy';
import SkewedDiv from '/src/components/common/SkewedDiv.vue';
import SkewedButton from '@/components/common/SkewedButton.vue';
import ProgressBar from '@/components/common/ProgressBar.vue';
import MinusIcon from '@/components/icons/MinusIcon.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import InfoIcon from '@/components/icons/InfoIcon.vue';
import ConnectWallet from '@/components/common/ConnectWallet.vue';
import SuperEthToggle from '@/components/common/SuperEthToggle.vue';

let props = defineProps({
  mintQty: { type: Number, default: 1 },
  minMintQty: { type: Number, default: 1 },
  maxMintQty: { type: Number, default: 2 },
  isConnected: { type: Boolean },
  isWhitelist: { type: Boolean },
  isWhitelisted: { type: Boolean },
  isComingSoon: { type: Boolean },
  paymentType: { type: String },
  price: { type: Number, default: 2 },
  soldQty: { type: Number, default: 0 },
  totalQty: { type: Number, default: 7777 },
  whitelistMintCap: { type: Number, default: 2 },
  totalMintCap: { type: Number, default: 5 },
  totalMinted: { type: Number, default: 0 },
  hasEnoughToken: { type: Boolean, default: false },
  superIsApproved: { type: Boolean, default: false },
  transactionSubmitting: { type: Boolean, default: false }
});
let emit = defineEmits(['update:mintQty', 'update:paymentType', 'mint']);

let toggleModel = computed({
  get() {
    return props.paymentType;
  },
  set(v) {
    return emit('update:paymentType', v);
  }
});

let numFormatter = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 5
});
let numFormatterNoDigit = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0
});

let price = computed(() =>
  props.paymentType === 'ETH'
    ? numFormatter.format(props.price)
    : numFormatterNoDigit.format(props.price)
);
let mintCost = computed(() =>
  props.paymentType === 'ETH'
    ? numFormatter.format(props.price * props.mintQty)
    : numFormatterNoDigit.format(props.price * props.mintQty)
);
let soldQty = computed(() => numFormatterNoDigit.format(props.soldQty));
let totalQty = computed(() => numFormatterNoDigit.format(props.totalQty));
let mintProgress = computed(() => (props.soldQty / props.totalQty) * 100);
let totalWhitelistMinted = computed(() =>
  Math.min(props.totalMinted, props.whitelistMintCap)
);
let whitelistAllocRemaining = computed(() =>
  Math.max(props.whitelistMintCap - totalWhitelistMinted.value, 0)
);
let publicAllocRemaining = computed(() =>
  Math.max(props.totalMintCap - props.totalMinted, 0)
);

let incMintQty = () => emit('update:mintQty', props.mintQty + 1);
let incDisabled = computed(() => mintButtonState.value.incDisabled);
let decMintQty = () => emit('update:mintQty', props.mintQty - 1);
let decDisabled = computed(() => mintButtonState.value.decDisabled);

let showApproveButton = computed(
  () => !props.superIsApproved && props.paymentType === 'SUPER'
);
let approveButtonMsg = computed(() => {
  let currentCap = props.isWhitelist
    ? props.whitelistMintCap
    : props.totalMintCap;
  let allocationFilled = props.totalMinted >= currentCap;
  return allocationFilled
    ? 'Whitelist allocation filled'
    : !props.hasEnoughToken
    ? 'Not Enough Super'
    : 'Approve Super';
});
let approveButtonDisabled = computed(() => !props.hasEnoughToken);

let mintButtonState = computed(() => {
  let currentCap = props.isWhitelist
    ? props.whitelistMintCap
    : props.totalMintCap;
  let nextTotal = props.totalMinted + props.mintQty;
  let canMint = nextTotal <= currentCap && props.hasEnoughToken;
  let mintQtyTooLarge =
    props.totalMinted < currentCap && nextTotal > currentCap;
  let nextMintQtyTooLarge =
    props.totalMinted < currentCap && nextTotal + 1 > currentCap;
  let allocationFilled = props.totalMinted >= currentCap;
  let msg = canMint
    ? 'MINT'
    : props.isWhitelist && allocationFilled
    ? 'Whitelist allocation filled'
    : allocationFilled
    ? 'Allocation filled'
    : mintQtyTooLarge
    ? 'Exceeds allocation'
    : !props.hasEnoughToken
    ? `Not Enough ${props.paymentType}`
    : 'Unable To Mint';
  let incDisabled =
    props.mintQty + 1 > props.maxMintQty || nextMintQtyTooLarge || !canMint;
  let decDisabled = props.mintQty - 1 < props.minMintQty;

  return {
    disabled: !canMint,
    msg,
    incDisabled,
    decDisabled
  };
});

let showMintButton = computed(
  () => props.isConnected && (props.isWhitelist ? props.isWhitelisted : true)
);
let whitelistDenied = computed(
  () => props.isConnected && (props.isWhitelist ? !props.isWhitelisted : false)
);
let soldOut = computed(() => props.soldQty >= props.totalQty);
let mint = () => emit('mint', { qnt: props.mintQty, type: props.paymentType });
</script>

<style scoped lang="scss">
@import '../../../assets/style/mixins.scss';

.mint-card-wrapper {
  position: relative;
  flex: 1;
  max-width: 516px;
}

.mint-card {
  width: 100%;
  max-width: 516px;
  font-family: DM Sans;
  font-style: normal;
  font-size: 0.7rem;

  @include mediaOnlyScreenMax(500px) {
    font-size: 0.7rem;

    .mint-card_wrapper {
      padding: 16px;
      padding-top: 36px;
    }

    .button_increment {
      padding: 12px;
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .sale-progress {
      padding: 18px 36px 24px;
      height: auto;

      p {
        bottom: 11px;
      }
    }

    .progress-bar {
      transform: scaleY(0.8);
    }

    .mint-button {
      padding: 14px;
    }
    .allocations {
      font-size: inherit;
      padding: 0 8px;
      h4 {
        span {
          text-align: center;
        }
      }
    }
  }
}

.mint-card_wrapper {
  padding: 36px;
  padding-top: 74px;
}

h1 {
  font-size: 2em;
  text-align: center;
  text-transform: uppercase;
}
.price {
  font-size: 3.2em;
  text-align: center;
}

.title-whitelist {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 8px;

  h1,
  .status-chip {
    font-size: 2.78571429em;
  }

  > * + * {
    margin-left: 20px;
  }
}
.status-chip {
  background: red;
  border-radius: 12px;
  padding: 0 22px;
}

.whitelist-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > * + * {
    margin-left: 16px;
  }

  h2 {
    font-size: 1.7em;
    text-transform: uppercase;
  }
}

.price-comment {
  font-size: 0.9em;
  text-transform: uppercase;
  margin-bottom: 11px;
}

.mint-controls {
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 24px;
  }
}

.mint-controls-box {
  padding: 24px;
  padding-bottom: 32px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.not-whitelisted {
    filter: blur(4px) brightness(0.5);
  }

  > * + * {
    margin-top: 8px;
  }

  h2 {
    font-size: 2.15714286em;
    text-transform: uppercase;
  }
}

.mint-button {
  margin: auto;
  margin-top: -36px;
  width: 70%;
  font-weight: bold;
  padding: 22px;
  text-transform: uppercase;
}

.mint-cost {
  font-size: 2.14em;
  text-transform: uppercase;
}
.mint-cost_amount {
  margin-left: 16px;
  color: var(--color-text-emphasis);
}

.sale-progress {
  height: 154px;
  padding: 24px 48px;
  font-size: 1.64em;
  text-transform: uppercase;

  &.sold-out {
    .sold-qty {
      color: #ff3333;
    }
    .sale-total {
      opacity: 0.4;
    }
  }
}

.sale-progress_wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.section-disabled {
  pointer-events: none;
  filter: blur(4px);
}
.connect-wallet-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-logo {
  max-width: 405px;
  width: 78%;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%) translateY(-45%);
  z-index: 2;
}
.title-wrapper {
  > * + * {
    margin-top: 8px;
  }
}

.coming-soon {
  filter: blur(4px) opacity(0.3);
}
.coming-soon-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 24px;

  .impostor-music {
    width: 70%;
  }

  .coming-soon-text {
    margin-top: -64px;
    max-width: 100%;
  }
}

.whitelist-denied-message {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.07em;
  text-transform: uppercase;

  p {
    max-width: 9em;
    text-align: center;
  }
}

.whitelist-wrapper {
  padding: 16px 24px;
  padding-bottom: -24px;
}

.sold-out-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 60px;
  margin-bottom: -24px;
  overflow: hidden;
}
.sold-out-img {
  margin-right: -26px;
  width: 100%;
  max-width: 500px;
}
.progress-bar_wrapper {
  position: relative;
  p {
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.mint-qty {
  font-size: 4.14285714em;
  min-width: 0.8em;
  text-align: center;
}

.allocations {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  font-size: 1rem;

  svg {
    fill: white;
  }

  & > * + * {
    margin-left: 32px;
  }
}
.allocation-block {
  @include DMSans(1.145em);
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.allocation-title {
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 8px;
  }
}

.allocation-stat {
  margin-top: 8px;

  span {
    opacity: 0.6;
    margin-left: 6px;
  }
}

.tooltip {
  padding: 12px;
  filter: drop-shadow(4px 4px 0px rgba(2, 11, 40, 0.6));
}
</style>
