<script setup>
import FAQCell from '@/pages/faq/components/FAQCell.vue';
import FAQGroup from '@/components/common/FAQGroup.vue';

import { FAQ_ITEMS, SHARED_FAQ_ITEMS } from '@/constants/faq';

const items = [...FAQ_ITEMS, ...SHARED_FAQ_ITEMS]

const props = defineProps({});
</script>

<template>
  <FAQGroup title="General">
    <template #graphic>
      <img src="@/images/genesis-drop-impostor.webp" :class="s.graphic" />
    </template>
    <FAQCell
      v-for="({ title, subcontent }, index) in items"
      :key="index"
      :title="title"
      :subcontent="subcontent"
    />
  </FAQGroup>
</template>

<style module="s" lang="scss">
.graphic {
  position: relative;
  left: -8px;
  transform: scaleX(-100%);
}
</style>
