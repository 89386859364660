<template>
  <div class="nfts-new">
    <section class="nfts-new__cards">
      <div
        v-for="(column, columnId) of cards"
        :key="columnId"
        class="nfts-new__cards-column"
        :class="`nfts-new__cards-column-${columnId+1}`"
      >
        <ImpostorSilhouetteCard
          v-for="({ impostor, background, hideable }, cardId) of column"
          :key="cardId"
          :impostor="impostor"
          :background="background"
          class="nfts-new__card"
          :class="{ hideable }"
        />
      </div>
    </section>
    <section class="nfts-new__what-is">
      <WhatIsItem
        v-for="({ title, subcontent }, index) of NFTS_WHAT_IS_ITEMS"
        :key="index"
        :title="title"
        :subcontent="subcontent"
      />
    </section>
  </div>
</template>

<script setup>
import { ImpostorSilhouetteCard, WhatIsItem } from '@/components/common';
import { IMPOSTORS_SILHOUETTE_BACKGROUND_TYPES, NFTS_WHAT_IS_ITEMS } from '@/constants';

const cards = [
  [
    { background: IMPOSTORS_SILHOUETTE_BACKGROUND_TYPES.PINK, impostor: 3 },
    { background: IMPOSTORS_SILHOUETTE_BACKGROUND_TYPES.BLUE, impostor: 1 },
    { background: IMPOSTORS_SILHOUETTE_BACKGROUND_TYPES.ORANGE, hideable: true, impostor: 3 }
  ],
  [
    { background: IMPOSTORS_SILHOUETTE_BACKGROUND_TYPES.BLUE, impostor: 4 },
    { background: IMPOSTORS_SILHOUETTE_BACKGROUND_TYPES.ORANGE, impostor: 2 },
    { background: IMPOSTORS_SILHOUETTE_BACKGROUND_TYPES.BLUE, hideable: true, impostor: 3 }
  ]
];
</script>

<style scoped lang="scss">
@import '../../../assets/style/mixins.scss';

.nfts-new {
  @include flex(row, center);
  width: 100%;
  padding: 0 48px 0 24px;

  &__cards {
    @include flex(row, flex-start, flex-start);
    column-gap: 32px;

    &-column {
      @include flex(column, flex-start);
      row-gap: 24px; 

      &-1 {
        margin-top: 72px;
      }
    }
  }

  &__what-is {
    max-width: 560px;
    margin-left: 110px;
    @include flex(column, flex-start);
    row-gap: 36px;
  }
}

@include mediaOnlyScreenMax(1280px) {
  .nfts-new {

    &__what-is {
      margin-left: 54px;
    }
  }
}

@include mediaOnlyScreenMax(1050px) {
  .nfts-new {
    @include flex(column, flex-start, center);
    padding: 48px 24px;

    &__cards {
      column-gap: 16px;

      &-column {
        row-gap: 16px;

        &-1 {
          margin-top: 0;
        }

        &-2 {
          margin-top: 72px;
        }
      }
    }

    &__card {
      &.hideable {
        display: none;
      }
    }

    &__what-is {
      margin: 36px 24px 0;
      @include flex(column, flex-start, center);
      row-gap: 36px;
    }
  }
}

@include mediaOnlyScreenMax(640px) {
  .nfts-new {
    &__cards {
      column-gap: 16px;

      &-column {
        row-gap: 16px;

        &-2 {
          margin-top: 64px;
        }
      }
    }
  }
}
</style>
