<template>
  <svg
    viewBox="0 0 138 71"
    fill="none"
    width="50px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4L69 65L134 4"
      stroke="currentColor"
      stroke-width="8"
      stroke-linecap="round"
    ></path>
  </svg>
</template>
