<template>
  <Header :isHome="false" />
  <div class="bg-wrapper">
    <div class="primary-bg"></div>
    <div class="fade"></div>
  </div>
  <Ethereum :callback="initialize" />

  <StakingModal
    :impostors="impostors"
    :isUnstakeModal="isUnstakeModal"
    :pools="pools"
    v-if="showModal"
    @exit="showModal = false"
    @stake="stake"
    @unstake="unstakeConfirm"
    :isSubmitting="stakeInProgress"
  />

  <UnstakeModal
    v-if="showUnstakeModal"
    :data="unstakeModalData"
    @exit="unstakeModalData = null"
    @unstake="unstake"
    :isSubmitting="unstakeInProgress"
  />

  <div class="container">
    <div class="fade" />
    <div class="content-container">
      <img src="../../images/blood-logo.webp" alt="$BLOOD" />
      <div class="blood-text">
        <p class="title">Meet $BLOOD, the utility token within the Impostors Metaverse!</p>
        <p
          class="subtitle"
        >
          Genesis season staking has come to an end!
          V2 staking pools will be added at a later date, stay tuned for more information.
          Using the claim button below will automatically unstake all of your Impostors.
        </p>
      </div>

      <LegacyPool
        v-if="stakerV2Enabled && stakedLegacyImpostors.length > 0"
        :staked="stakedLegacyImpostors"
        :claimAmount="claimLegacyAmount"
        @unstake="unstake"
      />

      <div class="claim-wrapper">
        <SkewedDiv
          class="claim"
          :borderRadius="15"
          :background="'linear-gradient(to right, #849BE5, #2E4EB6)'"
          :skew="
            !isMobile ? [0, [0, -8], [-10, 0], [-10, 0]] : [0, [0, -8], 0, 0]
          "
        >
          <SkewedDiv
            class="info-wrapper"
            :borderRadius="6"
            background="white"
            opacity="0.20"
            :skew="[0, 0, [-6, 0], [-6, 0]]"
          >
            <div class="content">
              <p class="title">AVAILABLE TO CLAIM</p>
              <p class="subtitle">{{ claimAmount }}</p>
              <SkewedButton
                class="button"
                :borderRadius="6"
                :skew="[0, -2, 0, [4, 4]]"
                :disabled="claimAmount == 0"
                @click="claim"
                background="var(--color-button-yellow)"
              >
                <span>CLAIM & UNSTAKE</span>
              </SkewedButton>
            </div>
          </SkewedDiv>
        </SkewedDiv>
        <SkewedDiv
          class="stats"
          :borderRadius="15"
          :background="'var(--color-bg-navy)'"
          :skew="!isMobile ? [0, 0, [-10, 0], 0] : [0, [0, -8], 0, 0]"
        >
          <div class="content">
            <p class="title">TOTAL</p>
            <div class="info-wrapper">
              <span>WALLET BALANCE</span>
              <span>{{ walletBalance }}</span>
            </div>
            <div class="info-wrapper faded">
              <span>LAST COLLECTED</span>
              <span>{{ lastCollected }}</span>
            </div>
            <div class="info-wrapper faded">
              <span>TOTAL COLLECTED</span>
              <span>{{ totalCollected }}</span>
            </div>
          </div>
        </SkewedDiv>
      </div>

      <!-- <div class="stake-wrapper" v-if="impostors.length > 0">
        <SkewedDiv
          :borderRadius="15"
          :background="'var(--color-bg-navy)'"
          :skew="!isMobile ? [0, 0, 0, [-10, -3]] : [0, 0, 0, 0]"
          class="stake"
        >
          <div class="stake-container">
            <p class="title">TOTAL</p>
            <div class="info-wrapper">
              <span>NFTs STAKED</span>
              <span>{{ stakedImpostors.length }}</span>
            </div>
            <div class="info-wrapper">
              <span>NFTs ELIGIBLE TO STAKE</span>
              <span>{{ numEligible }}</span>
            </div>
            <div class="info-wrapper">
              <span>DAILY EARNINGS</span>
              <span>{{ earnings }} $BLOOD</span>
            </div>

            <SkewedDiv
              class="insight"
              :borderRadius="4"
              background="white"
              opacity="0.20"
              :skew="[0, 0, [-6, 0], [-6, 0]]"
            >
              <p class="title">INSIGHT</p>
              <p class="subtitle">
                You have {{ stakedImpostors.length }} NFTs staked earning
                <span
                  style="white-space: nowrap;"
                >{{ earnings }} $BLOOD/day</span>
              </p>
            </SkewedDiv>
            <SkewedButton
              v-if="stakerV2Enabled"
              class="button"
              :borderRadius="6"
              :skew="[0, -2, 0, [4, 4]]"
              background="var(--color-button-yellow)"
              @click="openStakeModal"
            >
              <span>STAKE</span>
            </SkewedButton>
          </div>
        </SkewedDiv>

        <SkewedDiv
          class="impostors"
          :borderRadius="15"
          :background="'linear-gradient(to right, #849BE5, #2E4EB6)'"
          :skew="!isMobile ? [0, 0, [-12, 0], [-14, 0]] : [0, [0, -5], 0, 0]"
        >
          <div class="impostors-container">
            <SkewedDiv
              :borderRadius="6"
              background="white"
              opacity="0.20"
              :skew="[0, 0, [-6, 0], [-6, 0]]"
              clipContent
            >
              <div class="carousel-container">
                <p class="title">YOU ARE STAKING</p>
                <p class="subtitle">{{ stakedImpostors.length }} IMPOSTORS</p>
                <ImpostorsCarousel :impostors="stakedImpostors" />
              </div>
            </SkewedDiv>
            <div class="button-container">
              <SkewedButton
                @click="openUnstakeModal"
                class="button"
                :borderRadius="6"
                :disabled="stakedImpostors.length === 0"
                :skew="[0, 0, [-5, -3], 0]"
                background="#212121"
              >
                <span>View Staked</span>
              </SkewedButton>
              <SkewedButton
                @click="openUnstakeModal"
                class="button"
                :borderRadius="6"
                :disabled="stakedImpostors.length === 0"
                :skew="[0, 0, [-5, -3], 0]"
                background="#212121"
              >
                <span>Unstake</span>
              </SkewedButton>
            </div>
          </div>
        </SkewedDiv>
      </div> -->

      <!-- <div class="none-staked-wrapper" v-else>
        <SkewedDiv
          :borderRadius="15"
          :background="'var(--color-bg-navy)'"
          :skew="!isMobile ? [0, 0, 0, [-15, -3]] : [0, 0, 0, 0]"
          class="none-staked-container"
        >
          <div class="none-staked-content">
            <p class="title">Stake your genesis aliens</p>
            <div class="badge-wrapper">
              <div class="badge">Earn $BLOOD</div>
              <div class="badge">Time-lock bonus</div>
            </div>
            <SkewedButton
              v-if="stakerV2Enabled"
              class="button"
              :borderRadius="6"
              :skew="[0, -2, 0, [4, 4]]"
              :disabled="impostors.length === 0"
              background="var(--color-button-yellow)"
            >
              <span>STAKE</span>
            </SkewedButton>
          </div>
        </SkewedDiv>
        <img
          class="character"
          src="https://impostors.s3.amazonaws.com/assets/impostor-character-golf.webp"
          alt="impostor alien"
        />
      </div> -->

      <div class="faq-wrapper">
        <div class="faq-items">
          <FAQCell
            v-for="({ title, subcontent }, index) of BLOOD_FAQ_ITEMS"
            :key="index"
            v-bind:active="false"
            :title="title"
            :subcontent="subcontent"
            class="faq-page__list-item"
          />
          <FAQCell
            v-for="({ title, subcontent }, index) of BLOOD_ONLY_FAQ_ITEMS"
            :key="index"
            v-bind:active="false"
            :title="title"
            :subcontent="subcontent"
            class="faq-page__list-item"
          />
          <FAQCell
            v-for="({ title, subcontent }, index) of SHARED_FAQ_ITEMS"
            :key="index"
            v-bind:active="false"
            :title="title"
            :subcontent="subcontent"
            class="faq-page__list-item"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Header from '@/pages/layout/Header.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import SkewedButton from '@/components/common/SkewedButton.vue';
import FAQCell from '@/pages/faq/components/FAQCell.vue';
import ImpostorsCarousel from './components/ImpostorsCarousel.vue';
import StakingModal from './components/StakingModal.vue';
import UnstakeModal from './components/UnstakeModal.vue';
import LegacyPool from './components/LegacyPool.vue';

import { BLOOD_FAQ_ITEMS } from '@/constants';
import { SHARED_FAQ_ITEMS } from '@/constants';
import { BLOOD_ONLY_FAQ_ITEMS } from '@/constants';

import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import initializeConfig from '/src/initialize-config';
import useInterval from '/src/utility/useInterval'
import { BigNumber } from 'ethers'
import { l } from '@/datadog';
import { flag } from '@/featureflag';

let config;
const isLoading = ref(true);
const transactionSubmitting = ref(false)
const store = useStore();

onMounted(() => {
  window.scroll(0, 0);
});

window.addEventListener('resize', () => {
  windowWidth.value = window.innerWidth;
});

// computed property for wallet connection.
const isConnected = computed(() => {
  return store.state.ethers.canSign;
});

// computed property for staker pools
const pools = computed(() => {
  return store.state.stake.pools;
});

// computed property for staked NFTs
const impostors = computed(() => {
  return store.state.stake.staked;
});

const initialize = async () => {
  isLoading.value = true;

  // load global config
  config = await initializeConfig();
  await loadAll();

  isLoading.value = false;
}

// use timestamp to noop repetitive loadAll calls
let loadTs = Date.now();
const loadAll = async () => {
  //if (Date.now() < loadTs + 100) return;
  loadTs = Date.now();

  await loadTokenInfo();
  await loadClaimEvents();
  await loadOwnedItems();
  await loadPools();
  await loadStaked();
};


// update current item price every 5 sec
useInterval(async () => {
  await loadTokenInfo();
  await loadClaimEvents();
  await loadPools();
  await loadStaked();
}, 1000 * 15);

const currentAddress = computed(() => {
  return store.state.ethers.address;
});

const stakerV2Enabled = computed(() => {
  return flag.stakerV2Enabled.value;
});

// Loads logged in user's owned items.
const loadOwnedItems = async () => {
  if (isConnected.value && config != null) {
    //console.info("loading owned items at address=", config.itemCollections[config.forceNetwork]);
    await store.dispatch(
      'mint/loadItems',
      {
        mintStore: store.state.mint, // wallet address
        resolveMetadata: false,
      },
      {
        root: true
      }
    );
    //console.info("Owned:", store.state.mint.owned);
  };
}

// Loads staking pools
const loadPools = async () => {
  if (isConnected.value && config != null) {
    //console.info("loading pools at address=", config.stakerAddress[config.forceNetwork]);
    await store.dispatch(
      'stake/loadPools',
      {},
      {
        root: true
      }
    );
    //console.info("Pools:", store.state.stake.pools);
  };
}

// Loads user's staked items.
const loadStaked = async () => {
  if (isConnected.value && config != null) {
    //console.info("loading staked items at address=", config.stakerAddress[config.forceNetwork]);
    await store.dispatch(
      'stake/loadStakedStatus',
      {
        legacy: !stakerV2Enabled.value,
        pools: store.state.stake.pools,
        owned: store.state.mint.owned,
      },
      {
        root: true
      }
    );
  }
  //console.info("Staked:", store.state.stake.staked, store.state.stake.dailyBlood);
}

// loads allowance and amount of owned tokens
const loadTokenInfo = async () => {
  if (isConnected.value) {
    await store.dispatch(
      'stake/loadTokenInfo',
      { legacy: !stakerV2Enabled.value },
      {
        root: true
      }
    );
    //console.info("Balances:", store.state.stake.balances);
  };
}

// loads all claim events for this wallet
const loadClaimEvents = async () => {
  if (isConnected.value) {
    await store.dispatch(
      'stake/loadClaimEvents',
      {},
      {
        root: true
      }
    );
    //console.info("Claims:", store.state.stake.claims);
  };
}

const stakeInProgress = ref(false)

const stake = async (obj) => {
  if (isConnected.value && config != null) {

    // reload all staked items, and ensure we can't
    // double-stake
    await loadStaked();
    let foundDupe = false;
    obj.nfts.forEach(tokenId => {
      let found = store.state.stake.staked.find(token => token.tokenId == tokenId);
      if (found && found.isStaked) {
        foundDupe = true;
      }
    });

    if (foundDupe) {
      await store.dispatch(
        'alert/error',
        {
          message: 'One of these Impostors has been staked already. Please refresh and try again.',
          duration: 10000
        },
        { root: true }
      );
      return;
    }

    stakeInProgress.value = true
    await store.dispatch(
      'stake/stake',
      {
        nfts: obj.nfts,
        poolId: obj.poolId,
      },
      {
        root: true
      }
    );
    loadAll();
    stakeInProgress.value = false
  }
}

const unstakeInProgress = ref(false)

const unstakeConfirm = async (obj) => {
  showModal.value = false;
  unstakeModalData.value = obj;
}

const unstake = async (obj) => {
  unstakeInProgress.value = true
  if (isConnected.value && config != null) {
    await store.dispatch(
      'stake/unstake',
      {
        nfts: obj.nfts,
        poolId: obj.poolId,
        legacy: obj.legacy,
      },
      {
        root: true
      }
    );
    loadAll();
    unstakeModalData.value = null;
    unstakeInProgress.value = false;
  }
}

const claim = async () => {
  transactionSubmitting.value = true
  await store.dispatch(
    'stake/claim',
    {},
    { root: true }
  );

  // when successful, delay a tiny bit before refreshing all data
  await new Promise(resolve => setTimeout(resolve, 500));
  await loadTokenInfo();
  await loadClaimEvents();
  transactionSubmitting.value = false
};

watch(
  currentAddress, (curAdd, oldAdd) => {
    if (curAdd == null) {
      // wallet has locked or disconnected altogether
      // TODO: lookup how to reset store 
      store.state.ethers.canSign = false;
      store.state.stake.owned = [];
      store.state.stake.staked = [];
      store.state.stake.legacy.staked = [];
      store.state.stake.balances.hasBlood = false;
      store.state.stake.legacy.balances.hasBlood = false;
      store.state.stake.balances.claimable = BigNumber.from(0);
      store.state.stake.legacy.balances.claimable = BigNumber.from(0);
      store.state.stake.balances.tokenBalance = BigNumber.from(0);
      store.state.stake.legacy.balances.tokenBalance = BigNumber.from(0);
      store.state.stake.claims.lastCollected = 0.0;
      store.state.stake.claims.totalCollected = 0.0;
      store.dispatch('login/initialize');
      l.unsetUser();
      flag.unsetUser();
    }

    // essentially waits until wallet is ready
    if (curAdd !== oldAdd) {
      l.setUser(curAdd);
      flag.setUser(curAdd);
      ensureWalletConnected(400000).then(loadAll).catch(err => { let do_nothing = err; });
    }
  }
);

// this following function should NOT be required and
// we should be able to watch over isConnected
// TODO: either get the watch to work or move this into ethers.service
function ensureWalletConnected(timeout) {
  var start = Date.now();
  return new Promise(waitForWallet);

  // waitForWallet makes the decision whether the condition is met
  // or not met or the timeout has been exceeded which means
  // this promise will be rejected
  function waitForWallet(resolve, reject) {
    // check if wallet connect has ever been enabled in the past
    store.commit('checkWalletConnectStore');
    // if we have connected previously, dispatch automagic connection
    if (store.state?.walletconnect?.connected) {
      store.dispatch('ethers/connectWCProvider');
    }
    if (store.state?.ethers?.canSign)
      resolve(store.state.ethers.canSign);
    else if (timeout && (Date.now() - start) >= timeout)
      reject(new Error("timeout"));
    else
      setTimeout(waitForWallet.bind(this, resolve, reject), 30);
  }
}

const claimAmount = computed(() => {
  try {
    return parseFloat(store.state.stake.balances.claimable.toString()).toFixed(2);
  } catch (error) {
    return 0;
  }
});

const claimLegacyAmount = computed(() => {
  try {
    return parseFloat(store.state.stake.legacy.balances.claimable.toString()).toFixed(2);
  } catch (error) {
    return 0;
  }
});

const walletBalance = computed(() => {
  try {
    return parseFloat(store.state.stake.balances.tokenBalance).toFixed(2);
  } catch (error) {
    return 0;
  }
});

const earnings = computed(() => {
  try {
    return parseFloat(store.state.stake.dailyBlood.toString()).toFixed(2);
  } catch (error) {
    return 0;
  }
});

const lastCollected = computed(() => {
  try {
    return store.state.stake.claims.lastCollected.toFixed(2);
  } catch (error) {
    return 0;
  }
});

const totalCollected = computed(() => {
  try {
    return store.state.stake.claims.totalCollected.toFixed(2);
  } catch (error) {
    return 0;
  }
});

const stakedImpostors = computed(() => {
  return impostors.value.filter(impostor => impostor.isStaked);
});

const stakedLegacyImpostors = computed(() => {
  let impostors = store.state.stake.legacy.staked;
  return impostors.filter(impostor => impostor.isStaked);
});

const numEligible = computed(() => {
  return impostors.value.length - stakedImpostors.value.length;
});

const windowWidth = ref(window.innerWidth);

const isMobile = computed(() => {
  return windowWidth.value < 580;
});

const showModal = ref(false);
const isUnstakeModal = ref(false);
const unstakeModalData = ref(null);
const showUnstakeModal = computed(() => !!unstakeModalData.value)

const openUnstakeModal = () => {
  isUnstakeModal.value = true;
  showModal.value = true;
};

const openStakeModal = () => {
  isUnstakeModal.value = false;
  showModal.value = true;
};

</script>
<style scoped lang="scss">
.bg-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  .fade {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 300px;

    background-image: linear-gradient(
      0deg,
      var(--color-bg-main),
      55%,
      transparent
    );
  }
  .primary-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 934px;
    width: 100%;
    background-image: linear-gradient(
        0deg,
        var(--color-bg-main),
        15%,
        transparent
      ),
      url("../../images/background.webp");
    background-attachment: scroll;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: contain;
    /* ensures background-size: contain stretches full width */
    min-height: 100vw;
  }
}
.container {
  width: 100%;
  height: 100%;
  min-height: 90vh;
  padding: 40px;
  padding-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 60px;

  .fade {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), #1e1226);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1100px;

    img {
      max-width: 400px;
      height: auto;
      width: 100%;
      object-fit: contain;
      margin-bottom: 12px;
    }

    .blood-text {
      max-width: 600px;
      text-align: center;
      margin-bottom: 22px;
      .title {
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 8px;
      }
      .subtitle {
        font-weight: bold;
        font-size: 1rem;
      }
    }

    .claim-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 22px;

      .claim {
        width: 100%;
        max-width: 500px;
        padding: 40px;
        z-index: 3;

        .info-wrapper {
          padding: 40px;
          padding-top: 30px;

          .content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;

            .title {
              font-size: 32px;
              font-weight: 500;
            }

            .subtitle {
              font-size: 58px;
              font-weight: 500;
            }

            .button {
              position: absolute;
              bottom: -64px;
              width: 100%;

              span {
                font-weight: 500;
              }
            }
          }
        }
      }

      .stats {
        z-index: 2;
        width: 100%;
        height: 220px;
        margin-left: -100px;
        padding-left: 120px;
        margin-top: 14px;

        .content {
          padding: 30px 0px;
          padding-right: 45px;
          .title {
            font-weight: 500;
            font-size: 24px;
            margin-bottom: 20px;
          }

          .info-wrapper {
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            margin-bottom: 18px;
          }

          .faded {
            opacity: 0.7;
          }
        }
      }
    }

    .stake-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 22px;
      .stake {
        width: 100%;
        margin-right: -200px;
        padding-right: 200px;
        z-index: 2;
        height: 544px;
        .stake-container {
          height: 544px;
          padding: 52px 44px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .title {
            font-weight: 500;
            font-size: 24px;
            margin-bottom: 20px;
          }

          .info-wrapper {
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            margin-bottom: 18px;
          }

          .insight {
            padding: 22px 34px;
            font-weight: 600;
            margin-bottom: 22px;

            .title {
              font-size: 20px;
              margin-bottom: 10px;
            }

            .subtitle {
              font-weight: 500;
              opacity: 0.7;
              font-size: 18px;
            }
          }

          .button {
            max-width: 340px;
            width: 100%;
            margin: auto;
            span {
              font-weight: 500;
            }
          }
        }
      }

      .impostors {
        width: 100%;
        max-width: 520px;
        z-index: 3;
        height: fit-content;
        .impostors-container {
          padding: 40px;
          text-align: center;

          .carousel-container {
            padding-top: 28px;
            padding-bottom: 8px;
            padding-right: 4px;

            margin-bottom: 18px;

            .title {
              font-size: 20px;
            }

            .subtitle {
              font-size: 28px;
              margin-bottom: 14px;
            }

            .carousel {
              background-color: blue;
              height: 220px;
            }
          }

          .button-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 12px;

            .button {
              width: 100%;
              span {
                color: white;
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    .none-staked-wrapper {
      width: 95%;
      margin-bottom: 22px;
      margin-top: 30px;
      position: relative;
      align-self: flex-start;

      .none-staked-container {
        width: 100%;
        padding: 48px 64px;

        .title {
          font-size: 34px;
          font-weight: 500;
          margin-bottom: 22px;
        }

        .badge-wrapper {
          display: flex;
          gap: 10px;
          margin-bottom: 22px;
          .badge {
            font-size: 20px;
            padding: 8px 20px;
            border-radius: 4px;
            background: rgba(255, 255, 255, 0.2);
          }
        }

        .button {
          max-width: 320px;
          width: 100%;
          span {
            font-weight: 500;
          }
        }
      }

      .character {
        position: absolute;
        width: 340px;
        height: auto;
        object-fit: contain;
        right: -48px;
        top: -40px;
      }
    }

    .faq-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 16px;
      margin-top: 100px;
      img {
        margin-top: -60px;
        z-index: 2;
        width: 260px;
        height: auto;
        object-fit: contain;
      }

      .faq-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }
  }
}

@media (max-width: 980px) {
  .container .content-container {
    .claim-wrapper {
      flex-direction: column;

      .stats {
        margin-top: -100px;
        padding-top: 110px;
        height: 100%;
        margin-left: 0;
        padding-left: 0;
        width: 100%;

        .content {
          padding-left: 45px;
        }
      }

      .claim {
        max-width: 100%;
      }
    }

    .stake-wrapper {
      flex-direction: column-reverse;

      .impostors {
        max-width: 100%;
      }

      .stake {
        width: 100%;
        margin-top: -100px;
        padding-top: 100px;
        height: 100%;
        margin-right: 0;
        padding-right: 0;
      }
    }
  }

  .container .content-container .none-staked-wrapper .character {
    display: none;
  }
}

@media (max-width: 800px) {
  .container .content-container .faq-wrapper img {
    display: none;
  }

  .container .content-container {
    .none-staked-wrapper {
      width: 100%;
      text-align: center;

      .none-staked-content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          font-size: 5vw;
        }

        .badge-wrapper {
          flex-direction: column;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .container
    .content-container
    .stake-wrapper
    .impostors
    .impostors-container
    .carousel-container {
    padding-bottom: 28px;
  }
}

@media (max-width: 580px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .container .content-container {
    .claim-wrapper .claim .info-wrapper .content {
      .title {
        font-size: 5vw;
        white-space: nowrap;
      }
      .subtitle {
        font-size: 12vw;
        white-space: nowrap;
      }
    }

    .stake-wrapper .impostors {
      height: 100%;
      .impostors-container {
        padding: 24px;
        .button-container {
          flex-direction: column;
        }
      }
    }
  }
}

// Animations
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
