<template>
	<TextInput v-model="input" :isTextArea="false" :label="label" @input="validateInput" :type="'password'" />
	<!-- <div class="error-message">
      {{ errorMessage }}
    </div> -->
</template>

<script setup>
import { ref, computed } from 'vue';
import { accounts } from '@/account';
import TextInput from '@/components/ui/TextInput.vue';

let props = defineProps({
	confirmOnly: { type: Boolean, default: false },
	user: { type: Object },
	label: { type: String, default: 'Password' }
});

let input = ref('');

const { validatePasswordField, validatePasswordConfirmationField, errors } =
	accounts.useFormValidation();

let errorMessage = computed(() => {
	if (props.confirmOnly) {
		return errors.value.passwordConfirm;
	} else {
		return errors.value.password;
	}
});

const validateInput = () => {
	if (props.confirmOnly) {
		validatePasswordConfirmationField(
			'password',
			props.user.password,
			'passwordConfirm',
			input.value
		);
	} else {
		validatePasswordField('password', input.value);
	}
};
</script>