export const BLOOD_FAQ_ITEMS = [
  {
    title: 'What is $BLOOD?',
    subcontent:
      '$BLOOD is an ERC-20 utility token at the center of the Impostors economy. Players accumulate $BLOOD and can redeem it for a variety of perks and in-game items.'
  },
  {
    title:
      'Will my Impostors Genesis NFT rarity affect the amount of $BLOOD I can yield during the Genesis Season staking?',
    subcontent:
      'No, rarity doesn’t affect the amount of $BLOOD holders can yield and as seen in the pools staking mechanism, the amount earned is a flat rate/dependent on which pool you choose to stake in.'
  },
  {
    title: 'Are there any transaction fees when staking?',
    subcontent:
      'Yes, when staking or un-staking from a pool you will need to pay gas fees in $ETH.'
  },
  {
    title: 'What can I use $BLOOD for during the Genesis Season?',
    subcontent:
      '$BLOOD will be required for all Genesis Season events:<br> UFO Mint. Cost: 1800 $BLOOD<br> Pet Mint. Cost: 1800 $BLOOD<br> Materials Chest Mint. Cost: 1500 $BLOOD<br> Cosmetics Chest mint. Cost: 1500 $BLOOD<br> Land mint. Cost: 4000 $BLOOD'
  },
  {
    title: 'What will the use case for $BLOOD be after the Genesis Season?',
    subcontent:
      "Buying in-game items<br> Racing UFOs<br> Upgrading land<br> Leveling up pets<br> Find out more <a href='https://docs.impostors.gg/metapaper/ecosystem-tokenomics' target='_'>here</a>."
  },
  {
    title: 'Do I have to mint the Genesis Season NFTs within a certain period?',
    subcontent:
      'No, there is no expiration date on when you can mint the Genesis Season NFTs available.'
  },
  {
    title: 'What happens to $BLOOD after I spend it?',
    subcontent:
      '100% of the $BLOOD will be burned for mints during the Genesis Season.'
  }
];

export const BLOOD_ONLY_FAQ_ITEMS = [
  {
    title: 'What will the project do with the $SUPER raised?',
    subcontent:
      'The project intends to hold and stake the $SUPER generated from the Impostors mint. By selling a portion of the items in ETH, it allows the project to pay for taxes and operations without being forced to sell the $SUPER tokens. In the unlikely event that any $SUPER is needed for project operations, a transparency report will be issued.'
  }
];

export const SHARED_FAQ_ITEMS = [
  {
    title: 'How is $SUPER token used within the game?',
    subcontent:
      '$SUPER is the governance token for the Impostors Metaverse. All fees generated by the game marketplace are controlled by the SUPER DAO treasury. $SUPER is not earned or emitted within the game (SUPER is hard-capped and non-inflationary whereas $BLOOD has no supply cap).'
  }
];

export const FAQ_ITEMS = [
  {
    title: 'What is the utility of the Impostor Genesis Alien?',
    subcontent:
      'The Genesis alien entitles its holder to participate in all events within the genesis season.'
  },
  {
    title: 'Is Impostors the Game free to play?',
    subcontent:
      'Yes! When the game enters Open Beta it will be free to play for anyone. More details to come in the near future.'
  }
];

export const FAQ_ONLY_ITEMS = [
  {
    title:
      'Do I need to do anything to start earning $BLOOD or does it happen automatically?',
    subcontent:
      "Your Impostors Genesis Alien must be staked in one of the pools to generate $BLOOD. You can find the staking page <a href='/blood'>here</a>."
  }
];

export const CLAIM_FAQ_ITEMS = [
  {
    title: 'How many UFOs will be minted?',
    subcontent:
      'There is a total of 10,420 UFOs that will be minted. 1 UFO mint per Genesis Alien.'
  },
  {
    title:
      'Will we be able to see if an Impostor being sold has minted its UFO?',
    subcontent:
      "Yes, you can check whether an Impostors Genesis Alien has minted a UFO or is still eligible to mint a UFO <a href='https://impostors.gg/check-eligibility'>here</a>."
  },
  {
    title:
      'If I buy an Impostor Genesis Alien and $BLOOD from secondary market, will I be able to participate in the UFO Mint?',
    subcontent:
      "Yes, as long as you have enough $BLOOD and an Impostors Genesis Alien that has not yet minted, you will be able to mint a UFO. If you are planning to buy an Alien on the secondary market please be sure to check if it has already been used to mint a UFO <a href='https://impostors.gg/check-eligibility'>here</a>."
  },
  {
    title: 'How long do I have to mint my UFO? ',
    subcontent: 'There is no time limit on when you can mint your UFO.'
  },
  {
    title: 'How will minting work?',
    subcontent:
      "Impostors holders will visit the claim portal on <a href='https://impostors.gg/claim'>https://impostors.gg</a>. The UFO mint requires 1800 $BLOOD and an Impostors Genesis Alien NFT in the same wallet. Multiple UFOs can be claimed in the same transaction. The claim portal will show the Impostors eligible for claiming an UFO. Choose one or more Impostors and ensure enough $BLOOD is available. The Impostors NFT used to claim will remain in your wallet after the claim.<br/><br/> The first part of the claim is to allow the Impostors redeemer contract to spend $BLOOD. Upon approval, the mint transaction will be sent to the redeemer. When the transaction completes, one or more UFO NFTs will be delivered to your wallet (depending on the amount of UFOs minted).<br/><br/> In order to ensure no one can “game” the system, the rarity and other traits of each UFO remains secret until minted, UFO metadata is automatically refreshed periodically by the Impostors website. When first minted, UFO metadata is unrevealed. After a few minutes, the metadata will be revealed and visible on Gigamart."
  },
  {
    title: 'What are the galaxies and rarities associated with them?',
    subcontent:
      'Goldcomb - Legendary <br/> Draconis  - Epic<br/> Velorum - Rare<br/> Galactech  - Uncommon<br/>'
  },
  {
    title: 'Do I need an Impostors Genesis Alien to be eligible to mint a UFO?',
    subcontent:
      "Yes, you can mint one UFO per Impostors Genesis Alien you hold. If you are planning to buy an Alien on the secondary market please be sure to check if it has already been used to mint a UFO <a href='https://impostors.gg/check-eligibility'>here</a>."
  },
  {
    title: 'How much $BLOOD do I need to have to mint a UFO?',
    subcontent:
      "You will need 1800 $BLOOD (+ ETH for gas) to mint a UFO. If you don’t have enough $BLOOD you can either continue staking until you have enough or purchase it on <a href='https://app.uniswap.org/#/swap?inputCurrency=0x95392f142af1c12f6e39897ff9b09c599666b50c&chain=mainnet'>Uniswap</a>."
  },
  {
    title: 'Are the UFOs we mint generative or non-generative?',
    subcontent:
      "We are currently in Phase 1 of the Genesis UFO roadmap. The placeholder images or non-generative art, will be replaced with the 3D generative art in Phase 2 of the Genesis UFOs roadmap. More information can be found <a href='https://docs.impostors.gg/metapaper/metaverse-economy/ufo-ecosystem'>here</a>."
  },
  {
    title:
      'If I own multiple Genesis Alien’s can I mint multiple UFOs in a single transaction?',
    subcontent: 'Yes, you can mint multiple UFOs in a single transaction.'
  },
  {
    title:
      'Do I need to claim my accumulated $BLOOD from the staking pool before I mint my UFO?',
    subcontent:
      'Yes, you need to claim your accumulated $BLOOD so it is in your wallet in order for the site to register what amount of $BLOOD you have available in your wallet to ensure you have the required amount.'
  },
  {
    title: 'Is the UFO minting contract optimized for low gas?',
    subcontent: 'Yes, it is. We will be using ERC721-A.'
  },
  {
    title: 'What is the utility of UFOs?',
    subcontent:
      "All available information regarding UFOs and their utility within the Impostors Metaverse can be found <a href='https://docs.impostors.gg/metapaper/metaverse-economy/ufo-ecosystem'>here</a>."
  },
  {
    title: 'Will we be able to trade our UFOs but still mint Pets?',
    subcontent:
      'You will be able to trade your UFO but will need to have an Impostor plus a UFO to mint a Pet.'
  }
];

export const CLAIM_FAQ_PET_ITEMS = [
  {
    title: 'How many Pets will be minted?',
    subcontent:
      'There are a total of 10,420 Pets that will be minted. 1 Pet mint per Genesis Alien.'
  },
  {
    title:
      'Will I be able to see if an Impostor/UFO being sold has minted its Pet?',
    subcontent:
      "Yes, you can check whether an Impostors Genesis Alien and/or UFO has minted a Pet or is still eligible to mint a Pet <a href='https://impostors.gg/check-eligibility'>here.</a>"
  },
  {
    title:
      'If I buy an Impostor Genesis Alien, a UFO and $BLOOD from the secondary market, will I be able to participate in the Pet Mint?',
    subcontent:
      'Yes, as long as you have enough $BLOOD, the Impostors Genesis Alien and UFO that has not yet minted a Pet, you will be able to mint a Pet.<br/><br/>If you are planning to buy an Alien/UFO on the secondary market please be sure to check if it has already been used to mint a Pet here.'
  },
  {
    title: 'How long do I have to mint my Pet?',
    subcontent: 'There is no time limit on when you can mint your Pet.'
  },
  {
    title: 'How will minting work?',
    subcontent: [
      "Impostors holders will visit the claim portal on <a href='https://impostors.gg'>https://impostors.gg</a>. The Pet mint requires 1800 $BLOOD, an Impostors Genesis Alien and a UFO NFT in the same wallet. Multiple Pets can be claimed in the same transaction. The claim portal will show the Impostors/UFOs eligible for claiming an Pet. Choose one or more Impostors/UFOs and ensure enough $BLOOD is available. The Impostors and UFO NFTs used to claim will remain in your wallet after the claim.",
      'The first part of the claim is to allow the Impostors redeemer contract to spend $BLOOD. Upon approval, the mint transaction will be sent to the redeemer. When the transaction completes, one or more Pet NFTs will be delivered to your wallet (depending on the amount of Pets minted).',
      'In order to ensure no one can “game” the system, the rarity and other traits of each Pet remain secret until minted, Pet metadata is automatically refreshed periodically by the Impostors website. When first minted, Pet metadata is unrevealed. After a few minutes, the metadata will be revealed and visible on Gigamart.'
    ]
  },
  {
    title: 'What are the galaxies and rarities associated with them?',
    subcontent: `Goldcomb - Legendary <br/>
    Draconis  - Epic<br/>
    Velorum - Rare<br/>
    Galactech  - Uncommon<br/>
    `
  },
  {
    title:
      'Do I need an Impostors Genesis Alien and a UFO to be eligible to mint a Pet?',
    subcontent:
      'Yes, you can mint one Pet per Impostors Genesis Alien and UFO you hold. If you are planning to buy an Alien on the secondary market please be sure to check if they have already been used to mint a Pet <a href="https://impostors.gg/check-eligibility">here.</a>'
  },
  {
    title: 'How much $BLOOD do I need to have to mint a Pet?',
    subcontent:
      'You will need 1800 $BLOOD (+ ETH for gas) to mint a Pet. If you don’t have enough $BLOOD you can either continue staking until you have enough or purchase it on <a href="https://app.uniswap.org/#/swap?inputCurrency=0x95392f142af1c12f6e39897ff9b09c599666b50c&chain=mainnet">Uniswap.</a>'
  },
  {
    title: 'Are the Pets we mint generative or non-generative?',
    subcontent:
      'We are currently in Phase 1 of the Genesis Pet roadmap. The placeholder images or non-generative art, will be replaced with the 3D generative art in Phase 2 of the Genesis Pets roadmap. More information can be found <a href="https://docs.impostors.gg/metapaper/metaverse-economy/pets-ecosystem">here</a>.'
  },
  {
    title:
      'If I own multiple Genesis Aliens and UFOs can I mint multiple Pets in a single transaction?',
    subcontent: 'Yes, you can mint multiple Pets in a single transaction.'
  },
  {
    title:
      'Do I need to claim my accumulated $BLOOD from the staking pool before I mint my Pet?',
    subcontent:
      'Yes, you need to claim your accumulated $BLOOD so it is in your wallet in order for the site to register what amount of $BLOOD you have available in your wallet to ensure you have the required amount.'
  },
  {
    title: 'Is the Pet minting contract optimized for low gas?',
    subcontent: 'Yes, it is. We will be using ERC721-A.'
  },
  {
    title: 'What is the utility of Pets?',
    subcontent:
      'All available information regarding Pets and their utility within the Impostors Metaverse can be found <a href="https://docs.impostors.gg/metapaper/metaverse-economy/pets-ecosystem">here</a>.'
  },
  {
    title: 'Will we be able to trade our Pets and still mint Materials?',
    subcontent:
      'You will be able to trade your Pets but you will need to have an Impostor + UFO + Pet in order to mint a Materials box.'
  }
];

export const CLAIM_FAQ_BOX_ITEMS = [
  {
    title: 'How many Materials Boxes will be minted?',
    subcontent: [
      'There are a total of 10,420 Materials Boxes that will be minted. ',
      'One Material Box mint per Genesis Alien + UFO + Pet combo.'
    ]
  },
  {
    title:
      'Will I be able to see if an Impostor/UFO/Pet being sold has minted its Materials Box?',
    subcontent: [
      'Yes, you can check whether an Impostors Genesis Alien, UFO and/or PET has minted a Materials Box or is still eligible to mint one <a href="https://impostors.gg/check-eligibility">here</a>.'
    ]
  },
  {
    title:
      'If I buy an Impostor Genesis Alien, a UFO, a PET and $BLOOD from the secondary market, will I be able to participate in the Materials Box Mint?',
    subcontent: [
      'Yes, as long as you have enough $BLOOD, an Impostors Genesis Alien, UFO and PET that have not yet minted a Materials Box, you will be able to mint one.',
      'If you are planning to buy an Alien/UFO/PET on the secondary market, please be sure to check if it has already been used to mint a Materials Box <a href="https://impostors.gg/check-eligibility">here</a>.'
    ]
  },
  {
    title: 'How long do I have to mint my Materials Box?',
    subcontent: [
      'There is no time limit on when you can mint your Materials Box.'
    ]
  },
  {
    title: 'How will minting work?',
    subcontent: [
      'Impostors holders will visit the claim portal on <a href="https://impostors.gg">https://impostors.gg</a>. The Materials Box mint requires 1500 $BLOOD, an Impostors Genesis Alien, a UFO and a PET NFT in the same wallet. Multiple Materials Boxes can be claimed in the same transaction. The claim portal will show the Impostors/UFOs/PETS eligible for claiming a Materials Box. Choose one or more Impostors/UFOs/PETS and ensure enough $BLOOD is available. The Impostors, UFOs and PETS NFTs used to claim will remain in your wallet after the claim.',
      'The first part of the claim is to allow the Impostors redeemer contract to spend $BLOOD. Upon approval, the mint transaction will be sent to the redeemer. When the transaction completes, one or more Materials Box NFTs will be delivered to your wallet (depending on the amount of Materials Boxes minted).'
    ]
  },
  {
    title:
      'Do I need an Impostors Genesis Alien, a UFO and a PET to be eligible to mint a Materials Box?',
    subcontent: [
      'Yes, you can mint one Materials Box per Impostors Genesis Alien, UFO and PET combination you hold. If you are planning to buy an Alien/UFO/PET on the secondary market please be sure to check if they have already been used to mint a Materials Box <a href="https://impostors.gg/check-eligibility">here</a>.'
    ]
  },
  {
    title: 'How much $BLOOD do I need to have to mint a Materials Box?',
    subcontent: [
      'You will need 1500 $BLOOD (+ ETH for gas) to mint a Pet. If you don’t have enough $BLOOD you can either continue staking until you have enough or purchase it on <a href="https://app.uniswap.org/#/swap?inputCurrency=0x95392f142af1c12f6e39897ff9b09c599666b50c&chain=mainnet">Uniswap</a>.'
    ]
  },
  {
    title:
      'If I own multiple Genesis Aliens, UFOs and PETS can I mint multiple Materials Boxes in a single transaction?',
    subcontent: [
      'Yes, you can mint multiple Materials Boxes in a single transaction.'
    ]
  },
  {
    title:
      'Do I need to claim my accumulated $BLOOD from the staking pool before I mint my Materials Box?',
    subcontent: [
      'Yes, you need to claim your accumulated $BLOOD so it is in your wallet in order for the site to register what amount of $BLOOD you have available in your wallet to ensure you have the required amount.'
    ]
  },
  {
    title: 'Is the Materials Box minting contract optimized for low gas?',
    subcontent: ['Yes, it is. We will be using ERC721-A.']
  },
  {
    title: 'What is the utility of Materials Boxes?',
    subcontent: [
      'Material Boxes are a placeholder NFT for the Materials NFT’s. More information on Materials can be found <a href="https://docs.impostors.gg/metapaper/metaverse-economy/land-ecosystem">here</a>.'
    ]
  },
  {
    title:
      'Will we be able to trade our Materials Box and still mint Cosmetics Chest?',
    subcontent: [
      'You will be able to trade your Materials Box but you will need to have an Impostor + UFO + Pet + Materials Box in order to mint a Cosmetics Chest.'
    ]
  }
];

export const CLAIM_FAQ_CHEST_ITEMS = [
  {
    title:
      'Do I need an Impostors Genesis Alien + UFO + PET + Materials Box combination to be eligible to mint a Cosmetics Chest?',
    subcontent: [
      'Yes, you can mint one Cosmetics Chest per Impostors Genesis Alien + UFO + PET + Materials Box combination you hold. If you are planning to buy an Alien/UFO/PET/Materials Box on the secondary market please be sure to check if they have already been used in a combination to mint a Cosmetics Chest <a href="https://impostors.gg/check-eligibility">here</a>.'
    ]
  },
  {
    title:
      'Will I be able to see if an Impostor/UFO/Pet/Materials Box being sold has been used in a combination to mint a Cosmetics Chest?',
    subcontent: [
      'Yes, you can check whether an Impostors Genesis Alien, UFO, PET and/or Materials Box has minted a Cosmetics Chest or is still eligible to mint one <a href="https://impostors.gg/check-eligibility">here</a>.'
    ]
  },
  {
    title:
      'If I buy an Impostor Genesis Alien, a UFO, a PET, a Materials Box and $BLOOD from the secondary market, will I be able to participate in the Cosmetics Chests Mint?',
    subcontent: [
      'Yes, as long as you have enough $BLOOD, an Impostors Genesis Alien, UFO, PET and Materials Box that have not yet minted a Cosmetics Chest, you will be able to mint one.',
      'If you are planning to buy an Alien/UFO/PET/Materials Box on the secondary market, please be sure to check if it has already been used to mint a Cosmetics Chest <a href="https://impostors.gg/check-eligibility">here</a>.'
    ]
  },
  {
    title: 'How long do I have to mint my Cosmetics Chest?',
    subcontent: [
      'There is no time limit on when you can mint your Cosmetics Chest.'
    ]
  },
  {
    title: 'How will minting work?',
    subcontent: [
      'Impostors holders will visit the claim portal on <a href="https://impostors.gg/claim">https://impostors.gg/claim</a>. The Cosmetics Chest mint requires 1500 $BLOOD, an Impostors Genesis Alien + UFO +  PET and a Materials Box NFT combination in the same wallet. Multiple Cosmetics Chests can be claimed in the same transaction. The claim portal will show the Impostors/UFOs/PETS/Materials Boxes eligible for claiming a Cosmetics Chest. Choose one or more Impostors/UFOs/PETS/Materials Boxes and ensure enough $BLOOD is available. The Impostors, UFOs, PETS and Materials Boxes NFTs used to claim will remain in your wallet after the claim.',
      'The first part of the claim is to allow the Impostors redeemer contract to spend $BLOOD. Upon approval, the mint transaction will be sent to the redeemer. When the transaction completes, one or more Cosmetics Chests NFTs will be delivered to your wallet (depending on the amount of Cosmetics Chests minted).'
    ]
  },
  {
    title: 'How much $BLOOD do I need to have to mint a Cosmetics Chest?',
    subcontent: [
      'You will need 1500 $BLOOD (+ ETH for gas) to mint a Cosmetics Chest. If you don’t have enough $BLOOD you can either continue staking until you have enough or purchase it on <a href="https://app.uniswap.org/#/swap?inputCurrency=0x95392f142af1c12f6e39897ff9b09c599666b50c&chain=mainnet">Uniswap</a>.'
    ]
  },
  {
    title:
      'If I own multiple Genesis Aliens, UFOs, PETS and Materials Boxes, can I mint multiple Cosmetics Chests in a single transaction?',
    subcontent: [
      'Yes, you can mint multiple Cosmetics Chests in a single transaction.'
    ]
  },
  {
    title:
      'Do I need to claim my accumulated $BLOOD from the staking pool before I mint my Cosmetics Chest?',
    subcontent: [
      'Yes, you need to claim your accumulated $BLOOD so it is in your wallet in order for the site to register what amount of $BLOOD you have available in your wallet to ensure you have the required amount.'
    ]
  },
  {
    title: 'Is the Cosmetics Chest minting contract optimized for low gas?',
    subcontent: ['Yes, it is. We will be using ERC721-A.', '']
  },
  {
    title: 'What is the utility of Cosmetics Chests?',
    subcontent: [
      'Cosmetics Chests are a placeholder NFT for the Cosmetics NFT’s. More information on Cosmetics can be found <a href="https://docs.impostors.gg/metapaper/metaverse-economy/cosmetic-nfts">here</a>.'
    ]
  }
];

export const CLAIM_FAQ_VOXEL_ITEMS = [
  {
    title: 'What are Impostors VX?',
    subcontent: [
      'Impostors VX is a commemorative NFT collection featuring 3D voxelized Impostors that were randomly generated from 174 unique traits based on Impostors Genesis Aliens traits to celebrate Impostors first Susversary. Impostors VX holders can download their VX as a .gltf file from our <a href="https://impostors.gg/">website</a>. Impostors VX are not a requirement for Land.'
    ]
  },
  {
    title: 'How many Impostors VX will be minted?',
    subcontent: [
      'There are a total of 10,420 Impostors VX NFTs that can be minted. One Impostors VX mint per Impostors Genesis Alien.'
    ]
  },
  {
    title:
      'Will we be able to see if an Impostor being sold has minted its Impostors VX?',
    subcontent: [
      'Yes, you can check whether an Impostors Genesis Alien has minted an Impostors VX or is still eligible to mint an Impostor VX here:',
      '<a href="https://impostors.gg/check-eligibility">https://impostors.gg/check-eligibility<a>'
    ]
  },
  {
    title:
      'If I buy an Impostor Genesis Alien from the secondary market, will I be able to participate in the Impostors VX Mint?',
    subcontent: [
      'Yes, as long as you have an Impostors Genesis Alien that has not yet minted its Impostors VX, you will be able to mint one. If you are planning to buy an Alien on the secondary market please be sure to check if it has already been used to mint an Impostors VX here:',
      '<a href="https://impostors.gg/check-eligibility">https://impostors.gg/check-eligibility</a>'
    ]
  },
  {
    title:
      'How long do I have to claim my Impostors VX?',
    subcontent: [
      'There is no time limit on when you can mint your Impostors VX!',
    ]
  },
  {
    title:
      'How will claiming work?',
    subcontent: [
      'Impostors holders will visit the claim portal <a href="https://impostors.gg/claim">https://impostors.gg/claim</a>. The Impostors VX claim requires an Impostors Genesis Alien NFT in your wallet. Multiple Impostors VX can be claimed in the same transaction. The claim portal will show the Impostors Genesis Aliens eligible for claiming an Impostors VX. ',
      'In order to ensure no one can “game” the system, the rarity and other traits of each Impostors VX remains secret until minted, Impostors VX metadata is automatically refreshed periodically by the Impostors website. When first claimed, Impostors VX metadata is unrevealed. After a few minutes, the metadata will be revealed and visible on NFT Marketplaces, such as GigaMart.',
    ]
  },
  {
    title:
      'Do I need an Impostors Genesis Alien to be eligible to claim an Impostors VX?',
    subcontent: [
      'Yes, you can mint one Impostor VX per Impostors Genesis Alien you hold. If you are planning to buy an Alien on the secondary market please be sure to check to see if it has already been used to mint it’s Impostor VX here:',
      '<a href="https://impostors.gg/check-eligibility">https://impostors.gg/check-eligibility</a>'
    ]
  },
  {
    title:
      'Are the Impostors VX we claim generative or non-generative?',
    subcontent: [
      'The Impostors VX are randomly generated from 174 total traits in 10 different trait categories. Therefore, they won’t resemble the Impostors Genesis Aliens that they are being claimed from.',
    ]
  },
  {
    title:
      'If I own multiple Genesis Alien’s can I claim multiple Impostors VX’s in a single transaction?',
    subcontent: [
      'Yes, you can mint multiple Impostors VX’s in a single transaction.',
    ]
  },
  {
    title:
      'How can I download my Impostors VX .gltf file?',
    subcontent: [
      'Once Impostors Genesis holders have claimed their Impostors VX, they can visit the site and download a .gltf file of their VX by following these steps:',
      'Visit <a href="https://impostors.gg/">https://impostors.gg/</a>',
      '<ul><li>Connect the wallet that contains your Impostor(s)</li>'
      + '<li>Press “My Items” located on the wallet dropdown on the top right corner of the website</li>'
      + '<li>Press the Impostors VX for which you want to download the .gltf file</li>'
      + '<li>Press “Download”</li>'
      + '</ul>',
    ]
  },
  {
    title:
      'Why did you choose .gltf for the download file format for Impostors VX?',
    subcontent: [
      'The versatility of the .gltf file format allows holders of Impostors VX to use them in various ways, from 3D modeling software, game engines, virtual and augmented reality to web based experiences, providing endless possibilities for creativity and customization.',
    ]
  },
  {
    title:
      'Where can I use my Impostors VX?',
    subcontent: [
      'We won’t list every 3D software that supports .gltf, but most, including Autodesk Maya, Autodesk 3DS Max and even Microsoft Paint 3D do.',
      'One of the best free to use programs is Blender (<a href="https://www.blender.org/">https://www.blender.org/</a>). It requires time to master, but with a bit of practice and help from some YouTube video tutorials, the end result can be great.',
      'For an easy rendering experience we recommend Light Tracer Render (<a href="https://lighttracer.org/">https://lighttracer.org/</a>) and its free web app <a href="https://lighttracer.org/app.html">https://lighttracer.org/app.html</a> Here you can import multiple .gltf files and tinker around with all sorts of lighting and camera settings.',
    ]
  },
  // {
  //   title:
  //     '',
  //   subcontent: [
  //     '',
  //     ''
  //   ]
  // },
];
