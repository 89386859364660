<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 26 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 10V25C3 26.654 4.346 28 6 28H20C21.654 28 23 26.654 23 25V10H3ZM9 23C9 23.552 8.552 24 8 24C7.448 24 7 23.552 7 23V14C7 13.448 7.448 13 8 13C8.552 13 9 13.448 9 14V23ZM14 23C14 23.552 13.552 24 13 24C12.448 24 12 23.552 12 23V14C12 13.448 12.448 13 13 13C13.552 13 14 13.448 14 14V23ZM19 23C19 23.552 18.552 24 18 24C17.448 24 17 23.552 17 23V14C17 13.448 17.448 13 18 13C18.552 13 19 13.448 19 14V23Z"
      fill="white"
    />
    <path
      d="M24 4H18V3C18 1.346 16.654 0 15 0H11C9.346 0 8 1.346 8 3V4H2C0.897 4 0 4.897 0 6V7C0 7.552 0.448 8 1 8H25C25.552 8 26 7.552 26 7V6C26 4.897 25.103 4 24 4ZM10 3C10 2.449 10.449 2 11 2H15C15.551 2 16 2.449 16 3V4H10V3Z"
      fill="white"
    />
  </svg>
</template>
