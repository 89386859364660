'use strict';

// Specified state transitions per particular actions.
export default {

  updatePools(state, pl) {
    state.pools = pl;
  },

  updateStaked(state, stk) {
    // staker-v2
    state.staked = stk[0];
    state.dailyBlood = stk[1];
  },

  updateLegacyStaked(state, stk) {
    // staker-v1
    state.legacy.staked = stk[0];
    state.legacy.dailyBlood = stk[1];
  },

  updateTokenInfo(state, info) {
    state.balances = info;
  },

  updateLegacyTokenInfo(state, info) {
    state.legacy.balances = info;
  },

  updateClaims(state, claims) {
    state.claims = claims;
  },
};
