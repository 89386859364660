<script setup>
import { TOKEN_CONFIG } from '@/constants';
import { computed } from '@vue/reactivity';
import ImpostorPreviewCard from './ImpostorPreviewCard.vue';
import PlusIcon from '../icons/PlusIcon.vue';
import GigamartIcon from '../icons/GigamartIcon.vue';
const props = defineProps({
  tokenType: {String},
  hideHover: Boolean
});

let gigamartUrl = computed(() => TOKEN_CONFIG[props.tokenType]?.GIGAMART_URL)
</script>

<template>
  <a :class="s.wrapper" :href="gigamartUrl" target="_blank">
    <div :class="[s.wrapper, s.innerWrapper, !props.hideHover && s.showHover]">
      <ImpostorPreviewCard 
        :tokenType="props.tokenType"
        :class="s.tokenPreview"
      />
      <div :class="s.content">
        <PlusIcon :class="s.plusIcon" />
        <p :class="s.text">
          Buy On Gigamart
        </p>
      </div>
      <GigamartIcon :class="s.gigamartIcon" />
    </div>
  </a>
</template>

<style module="s" lang="scss">
.wrapper {
  position: relative;
}
.innerWrapper {
  border-radius: 20px;

  &.showHover:hover {
    box-shadow: 0 0 0 3px var(--color-button-yellow);
  }
}
.tokenPreview {
  border-radius: 20px;
  aspect-ratio: 1;
  opacity: .5;
}
.content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: grid;
  gap: 12px;
  place-content: center;
  place-items: center;
  text-transform: uppercase;
  color: white;
  font-size: 22px;
}
.text {
  max-width: 120px;
  text-align: center;
}
.plusIcon {
  path {
    fill: white
  }
}
.gigamartIcon {
  position: absolute;
  bottom: 16px;;
  right: 16px;
  opacity: .3;
}
</style>
