<template>
  <SkewedDiv
    :border-radius="16"
    clip-path="polygon(0% 0%, 100% 4px, calc(100% - 8px) 100%, 8px 100%)"
    :class-content="addVerticalClass('team-member__content')"
    class-skew-box="team-member__skew-box"
    :class="{ 'vertical': props.vertical }"
    class="team-member"
  >
    <SkewedDiv
      :border-radius="16"
      clip-path="polygon(0% 0%, 100% 4px, calc(100% - 8px) 100%, 8px 100%)"
      class-content="team-member__avatar-content"
      :class-skew-box="addVerticalClass('team-member__avatar-skew-box')"
      class="team-member__avatar"
    >
      <SkewedDiv
        :border-radius="8"
        :background="`url(${require(`@/images/${props.member.avatar}.webp`)}) no-repeat center/cover`"
        clip-path="polygon(0% 0%, 100% 4px, calc(100% - 4px) 100%, 4px 100%)"
        class-content="team-member__avatar-image-content"
        class-skew-box="team-member__avatar-image-skew-box"
        class="team-member__avatar-image"
      >
        <!--
          TODO: delete when problem of blured background image
          of SkewedDiv's skew-box in Safari is solved
        -->
        <img
          v-if="isSafari"
          :src="require(`@/images/${props.member.avatar}.webp`)"
        >
      </SkewedDiv>
    </SkewedDiv>
    <div class="team-member__info">
      <h2>{{ props.member.name }}</h2>
      <h4>{{ props.member.position }}</h4>
      <p>{{ props.member.details }}</p>
    </div>
  </SkewedDiv>
</template>

<script setup>
import { defineProps } from 'vue';
import { SkewedDiv } from '@/components/common';

const props = defineProps({
  member: { type: Object, required: true },
  vertical: { type: Boolean, required: false, default: false },
});

const addVerticalClass = (classes) => classes + (props.vertical ? ' vertical' : '');

const isSafari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
</script>

<style scoped lang="scss">
@import '../../../assets/style/mixins.scss';

.team-member {
  width: 596px;
  min-width: 596px;
  height: 202px;
  min-height: 202px;

  &.vertical {
    width: 282px;
    min-width: 282px;
    height: 432px;
    min-height: 432px;
  }

  :deep(.team-member__content) {
    width: 100%;
    height: 100%;
    @include flex(row, flex-start);
  }

  :deep(.team-member__content.vertical) {
    @include flex(column, flex-start);
  }

  :deep(.team-member__skew-box) {
    background: linear-gradient(88.4deg, #2E4EB6 -6.71%, #2F4EB6 48.8%);
  }

  &__avatar {
    width: 218px;
    min-width: 218px;
    height: 202px;

    .vertical & {
      width: 235px;
      height: 212px;
      min-height: 212px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    :deep(.team-member__avatar-skew-box) {
      background: linear-gradient(95.06deg, #849BE6 -15.83%, #2E4EB6 108.66%);
    }

    :deep(.team-member__avatar-skew-box.vertical) {
      background: transparent;
    }

    :deep(.team-member__avatar-content) {
      width: 100%;
      height: 100%;
      @include flex();
    }


    &-image {
      width: 164px;
      height: 154px;

      .vertical & {
        width: 100%;
        height: 100%;
      }

      img {
        width: 164px;
        height: 154px;
        border-radius: 8px;

        .vertical & {
          width: 235px;
          height: 212px;
        }
      }

      :deep(.team-member__avatar-image-skew-box) {
        background-size: contain;
      }
    }
  }

  &__info {
    padding: 0 16px 0 12px;
    @include flex(column, flex-start, flex-start);

    .vertical & {
      padding: 0 16px 20px 24px;
    }

    h2 {
      @include DMSans(32px, 700);
      text-align: left;

      .vertical & {
        @include DMSans(22px, 700);
      }
    }

    h4 {
      @include DMSans(12px, 700);
      text-transform: uppercase;
      text-align: left;

      .vertical & {
        text-transform: initial;
        margin-top: 4px;
      }
    }

    p {
      @include DMSans(15px, 500);
      text-align: left;
      margin-top: 4px;
      opacity: .6;

      .vertical & {
        @include DMSans(12px, 500);
        margin-top: 12px;
      }
    }
  }
}

</style>
