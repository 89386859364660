<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="24"
    viewBox="0 0 30 24"
    fill="white"
  >
    <path
      d="M28 6H14V2C14 0.896 13.125 0 12 0C11.344 0 10.937 0.172 10 1.16L1.125 10C0.5 10.656 0 11.109 0 12C0 12.891 0.5 13.344 1.125 14L10 22.84C10.938 23.828 11.344 24 12 24C13.125 24 14 23.104 14 22V18H28C29.105 18 30 17.105 30 16V8C30 6.895 29.105 6 28 6Z"
    />
  </svg>
</template>
