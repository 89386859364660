export const FOOTER_BUTTONS = [
  [
    // { label: 'GAME INFO', href: '/' },
    { label: 'NFT', href: '/nfts' },
    // { label: 'MEDIA', href: '/media' },
    { label: 'FAQ', href: '/faq' },
    { label: 'DOCS', href: 'https://docs.impostors.gg/' },
  ],
  [
    
    { label: 'PLATFORM TERMS', href: '/terms' },
    { label: 'GAME TERMS', href: '/game-terms' },
    { label: 'NFT TERMS', href: '/nft-terms' },
    { label: 'PRIVACY', href: '/privacy' },
  ]
];
