'use strict';

// Specified state transitions per particular actions.
export default {
  initialize(state) {
    const min = Math.ceil(1);
    const max = Math.floor(4);
    state.pageIndex = Math.floor(Math.random() * (max - min) + min);
  },
  
  playFabUpdate(state, pf) {
    state.playfab = pf;
    localStorage.setItem('playFab', JSON.stringify(pf));
  }
};
