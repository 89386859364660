'use strict';

// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Prepare state.
const state = {
  tokenInfo: {
    bloodBalance: 0,
    redeemerAllowance: 0,
    hasAllowance: false,
  },
  claimDetails: {},
  eligibleItems: {},
  redeemStatus: {},
  owned: {},
  tokenEligibility: {},
  liveTime: 1651536000
};

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
