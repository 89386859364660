'use strict';

import { useQuery } from 'vue-query';
import { datadogRum } from '@datadog/browser-rum';
import config from './config';

const SEP = "::";

let timers = null;

const init = async () => {
  datadogRum.init(config.datadog);
  timers = {};
}

const setUser = async (addr) => {
  datadogRum.setUser({
    id: addr,
    name: ' Wallet:' + addr,
  });
}

const unsetUser = async (addr) => {
  datadogRum.removeUser();
}

const getFncName = (c) => {
  let fncName = "";
  try {
    fncName = Object.values(c)[0].name;
  } catch (err) {
    try {
      fncName = c.name;
    }
    catch (err) {
    }
  }
  return {
    name: fncName
  };
}

const process = (caller, data, suffix) => {
  if (data.f) {
    if (Array.isArray(data.f)) {
      data.f = data.f.join(SEP)
    }
    data["name"] = data.f;
    data.f.split(SEP).forEach((arg, idx) => {
      data["arg" + idx] = arg;
    });
    data.f = null;
  } else {
    let d = getFncName(caller);
    data["name"] = d.name + (d.name == '' ? suffix : SEP + suffix);
  }
  data["type"] = suffix;
}

const ts = () => {
  let timerName = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
  timers[timerName] = Date.now();
  return timerName;
}

const te = (timerName) => {
  try {
    let timeSpent = (Date.now() - timers[timerName]);
    delete timers[timerName];
    return timeSpent;
  }
  catch (err) {
    return 'unknown';
  }
}

const upl = async (data) => {
  DD_RUM.addAction(data.name, data);
}

const timed = async function(fnc, data, caller) {
  let fncs = Array.isArray(fnc) ? fnc : [fnc]
  let t = ts();
  let resp = [];
  for (let x = 0; x < fncs.length; x++) {
    let r = await fncs[x]();
    resp.push(r);
  }
  perf(caller, t, data);
  // if array return array of responses
  // otherwise return only response
  return Array.isArray(fnc) ? resp : resp[0];
}

const dbg = async (caller, data) => {
  process(caller, data, 'debug');
  upl(data);
}

const err = async (caller, data) => {
  process(caller, data, 'error');
  upl(data);
}

const perf = async (caller, timer, data) => {
  process(caller, data, 'perf');
  data["delay"] = te(timer);
  upl(data);
}

// Export the user service functions.
export {
  SEP,
  init,
  setUser,
  unsetUser,
  timed,
  err,
  dbg
};
