<template>
  <div class="wrapper">
    <div class="info-area">
      <div class="info-area__background">
        <SkewedDiv
          :border-radius="16"
          :clip-path="`polygon(0% 128px, 100% 0%, 100% 100%, 0% 100%)`"
          class-skew-box="info-area__background-big-skew-box"
          class="info-area__background-big"
        />
        <div class="info-area__background-small" />
      </div>
      <div class="info-area__content-wrapper">
        <div class="info-area__content">
          <div class="info-area__impostors">
            <img
              class="info-area__impostor info-area__impostor-music-listening"
              src="~@/assets/impostor-music-listening.webp"
            />
            <img
              class="info-area__impostor info-area__impostor-minigolf"
              src="~@/assets/impostor-minigolf.png"
            />
            <img
              class="info-area__impostor info-area__impostor-chilling"
              src="~@/assets/impostor-chilling.webp"
            />
          </div>
          <div class="info-area__cards">
            <SkewedDiv
              v-for="({ title, subcontent, background, width }, index) of HOME_INFO_CARDS"
              :key="index"
              :border-radius="16"
              :clip-path="`polygon(0% ${infoCardConstant}px, 100% 0%, 100% 100%, ${infoCardConstant}px 100%)`"
              :class-skew-box="`info-area__card-skew-box-${background}`"
              :style="{ width : windowSize.width && windowSize.width > 1050 ? `${width}px` : '100%'}"
              class-content="info-area__card-content"
              class="info-area__card"
            >
              <h2>{{ title }}</h2>
              <p>{{ subcontent }}</p>
            </SkewedDiv>
            <img
              class="info-area__impostor info-area__impostor-1"
              src="~@/images/faq-impostor-1.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import { SkewedDiv } from '@/components/common';
import { HOME_INFO_CARDS } from '@/constants';

const store = useStore();

const windowSize = inject('windowSize');

const infoCardConstant = computed(() => windowSize.value.width > 640 ? 16 : 8);
</script>

<style scoped lang="scss">
@import '../../../assets/style/mixins.scss';

$card-backgrounds:
  'pink' #BA468A,
  'blue' linear-gradient(92.64deg, #415CB5 11.72%, #2445B4 46.34%),
  'yellow' #D49500;

.info-area {
  position: relative;
  @include flex();
  background-color: #412A51;

  &__background {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    &-big {
      height: calc(100% + 32px);
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;

      &:after {
        content: '';
        height: 600px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background: linear-gradient(to top, #1E1226 120px, transparent 100%);
      }

      :deep(.info-area__background-big-skew-box) {
        background-color: #1E1226;
      }
    }

    &-small {
      display: none;
    }
  }

  &__content {

    &-wrapper {
      width: 100%;
      height: 100%;
      padding: 0 48px;
      overflow: hidden;
      @include flex();
    }

    padding-top: 72px;
    margin-right: 40px;
    width: 1220px;
    max-width: 1220px;
    @include flex(row, space-between, stretch);
    position: relative;
    z-index: 2;
  }

  &__impostors {
    position: relative;
    @include flex(column, flex-end, flex-end);
    width: 580px;
  }

  &__impostor {
    position: absolute;

    &-music-listening {
      z-index: 3;
    }

    &-minigolf {
      top: -60px;
    }

    &-chilling {
      top: 110px;
      left: 0px;
    }

    &-1 {
      position: absolute;
      bottom: 0;
      right: -86px;
    }
  }

  &__cards {
    @include flex(column, flex-start, flex-start);
    row-gap: 28px;
    width: calc(580px * 100% / 1120px);
    max-width: 580px;
    margin-bottom: 100px;
  }

  &__card {
    height: 224px;
    @include flex();

    &:nth-child(even) {
      align-self: flex-end;
    }

    @each $name, $background in $card-backgrounds {
      :deep(.info-area__card-skew-box-#{$name}) {
        background: $background;
      }
    }

    :deep(.info-area__card-content) {
      @include flex(column, center, flex-start);
      padding: 42px;
    }

    h2 {
      text-transform: uppercase;
      @include DMSans(34px, 700);
      margin-bottom: 16px;
    }

    p {
      @include DMSans(17px, 400);
    }
  }
}

@include mediaOnlyScreenMax(1050px) {
  .info-area {

    &__background {

      &-big {
        display: none;
      }

      &-small {
        display: inherit;
        position: absolute;
        height: calc(100% - 32px);
        width: 100%;
        bottom: 0;
        left: 0;
        clip-path: polygon(0 64px, 100% 0, 100% 100%, 0 100%);
        background-color: #1E1226;
      }
    }

    &__content {
      padding-top: 0;
      margin-right: initial;
      width: auto;
      @include flex(column, flex-start, center);
    }

    &__impostors {
      position: relative;
      @include flex(column, flex-end, flex-end);
      width: 100%;
    }

    &__impostor {

      &-music-listening {
        position: absolute;
        width: 135px;
        right: 0;
        top: 186px;
        z-index: 3;
      }

      &-minigolf {
        position: absolute;
        top: 506px;
        left: 0px;
        z-index: 3;
        width: 132px;
      }

      &-chilling {
        display: none;
      }

      &-1 {
        width: 140px;
        bottom: 60px;
      }
    }

    &__cards {
      row-gap: 86px;
      width: 470px;
      max-width: 470px;
      min-width: initial;
    }

    &__card {

      &:nth-child(even) {
        align-self: initial;
      }
    }
  }
}

@include mediaOnlyScreenMax(640px) {
  .info-area {

    &__content {

      &-wrapper {
        padding: 0 24px;
      }
    }

    &__impostor {

      &-music-listening {
        right: 0;
        top: 150px;
      }

      &-minigolf {
        top: 446px;
        z-index: 3;
        width: 132px;
      }
    }

    &__cards {
      width: 100%;
      max-width: 322px;
    }

    &__card {
      height: 192px;
      @include flex();

      :deep(.info-area__card-content) {
        padding: 24px;
      }

      h2 {
        @include DMSans(24px, 700);
        margin-bottom: 8px;
      }

      p {
        @include DMSans(14px, 400);
      }
    }
  }
}
</style>
