<template>
  <router-link class="alternate-page-button" :to="to">
    {{ text }} <span class="link">{{ action }}</span>
  </router-link>
</template>

<script>
export default {
  props: ['to', 'text', 'action']
}
</script>

<style scoped>
.alternate-page-button {
  color: white;
  text-decoration: none;
  padding: 20px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  transition: background 0.2s ease;
}

.alternate-page-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.alternate-page-button:active {
  background: rgba(255, 255, 255, 0.2);
}

.link {
  color: #ff0;
  text-decoration: none;
}
</style>