<template>
  <SkewedDiv
    as="button"
    class="button-defaults"
    :class="{
      button__disabled:
        $attrs.disabled === '' || $attrs.disabled === true || props.isLoading,
      button__secondary: variant === 'secondary'
    }"
    classSkewBox="button-skew-box"
    classContent="button-skew-content"
    :background="background"
  >
    <div v-if="props.isLoading" class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <slot />
  </SkewedDiv>
</template>

<script setup>
 import {useAttrs, computed} from 'vue'
import SkewedDiv from './SkewedDiv.vue';
let props = defineProps({
   isLoading: { type: Boolean },
   background: { type: String },
   disabledBackground: { type: String, default: '#b1c16e' },
   disabledColor: { type: String, default: 'rgba(0,0,0,.6)' },
   variant: { type: String },
 });

 let attrs = useAttrs()

let background = computed(() => attrs.disabled === '' || attrs.disabled ? props.disabledBackground : props.background)

</script>

<style scoped lang="scss">
.button-defaults::v-deep {
  padding: 16px;
  color: black;
  font-family: DM Sans;
  font-style: normal;
  font-size: 1.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease 200ms;

  &:hover {
    filter: brightness(0.8);
  }
  &:active {
    filter: brightness(0.7);
  }

  &.button__disabled {
    cursor: auto;
    pointer-events: none;
    color: v-bind('props.disabledColor');
  }

  .button-skew-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .clamp-box,
  .blur-box,
  .button-skew-box {
    cursor: pointer;
  }

  &.button__secondary {

  }
}

.lds-ellipsis {
  --size: 10px;
  --gap: 18px;

  --start: calc(var(--size) / 2);
  --offset: calc(var(--gap) + var(--size));

  display: inline-block;
  position: absolute;
  bottom: calc(((16px / 2) + (var(--size) / 2) - 2px) * -1);
  width: calc((var(--start) * 2) + (var(--offset) * 2) + var(--size));
  /* width: 80px; */
  height: var(--size);

  div {
    position: absolute;
    top: 0;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background: black;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: var(--start);
    animation: lds-ellipsis1 0.6s infinite;
  }
  div:nth-child(2) {
    left: var(--start);
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(3) {
    left: calc(var(--start) + var(--offset));
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    left: calc(var(--start) + var(--offset) * 2);
    animation: lds-ellipsis3 0.6s infinite;
  }
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(var(--offset), 0);
  }
}
</style>
