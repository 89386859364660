<template>
  <Ethereum :callback="initialize" />
  <div class="container">
    <Header :isHome="false" />
    <div class="primary-bg"></div>
    <div class="container-content">
      <div v-if="isLoading || !isReady" class="skeleton">
        <MintSkeleton />
      </div>

      <div v-else class="section-mint">
        <div class="status-section" :class="{ 'status-section_sold-out': soldOut || isPublicMint }">
          <!-- <img src="~@/assets/countdown-area-impostor.webp" class="main-impostor" /> -->
          <img src="~@/assets/impostor-knife-alien.png" class="main-impostor" />
          <SkewedDiv
            :borderRadius="14"
            :skew="[0, 0, 0, [36, 2]]"
            :background="'#2F2348'"
            class="status-banner"
            v-if="!soldOut && !isPublicMint"
          >
            <template v-if="isWhitelist">
              <CountdownBanner
                title="Count down to public"
                :endDate="publicStartDate"
                class="dutch"
              />
            </template>
            <template v-else>
              <CountdownBanner
                title="Count down to whitelist"
                :endDate="whitelistStartDate"
                class="dutch"
              />
            </template>
          </SkewedDiv>
        </div>
        <div class="mint-card-wrapper">
          <MintCard
            class="mint-card"
            v-model:mintQty="mintQty"
            v-model:paymentType="paymentType"
            :isConnected="isConnected"
            :price="mintPrice"
            :soldQty="sold"
            :totalQty="totalCap"
            :isWhitelist="isWhitelist"
            :isWhitelisted="allowance > 0"
            :isComingSoon="isComingSoon"
            :maxMintQty="transactionCap"
            :whitelistMintCap="allowance"
            :totalMintCap="callerCap"
            :totalMinted="totalMinted"
            :hasEnoughToken="hasEnoughToken"
            :superIsApproved="superIsApproved"
            :transactionSubmitting="transactionSubmitting"
            @mint="mint"
            @approve="approveSuper"
          />
          <a v-if="soldOut" :href="GIGAMART_URL" target="_blank">Buy On Gigamart</a>
        </div>
      </div>
    </div>
    <MintFooter />
  </div>
</template>

<script setup>
// Imports.
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { ethers } from 'ethers';
import initializeConfig from '/src/initialize-config';
import useInterval from '/src/utility/useInterval'

import Ethereum from '/src/components/common/Ethereum.vue';
import MintSkeleton from './components/MintSkeleton.vue';
import Header from '../layout/Header.vue';
import MintCard from './components/MintCard.vue';
import DutchAuctionStats from './components/DutchAuctionStats.vue';
import SkewedDiv from '/src/components/common/SkewedDiv.vue';
import CountdownBanner from './components/CountdownBanner.vue';
import MintFooter from './components/MintFooter.vue'
import { merkleService } from '../../services';
import { GIGAMART_URL } from '../../constants';

const paymentTypeWhitelist = ref('ETH');
const paymentType = computed({
  get() {
    return isWhitelist.value ? paymentTypeWhitelist.value : 'ETH'
  },
  set(newValue) {
    paymentTypeWhitelist.value = newValue
  }
});
const mintPrice = computed(() => (
  {
    ETH: isWhitelist.value ? parseFloat(wl_ethStartingPrice.value) : parseFloat(currentPrice.value),
    SUPER: parseInt(wl_tokenStartingPrice.value)
  }[isWhitelist.value ? paymentType.value : 'ETH']))

const isLoading = ref(true);
const transactionSubmitting = ref(false)
const store = useStore();
let config; //global config

const minted = computed(() => store.state.mint.minted);

let now = ref(new Date());

onMounted(() => {
  window.scroll(0, 0);

  // retrieve shop config (when wallet not connected, would rely on infura provider)
  loadShopConfig().then();
});

const initialize = async () => {
  isLoading.value = true; // this controls the initial skeletor
  if (isConnected.value) {
    await loadAll();
  }
  isLoading.value = false;
}

const loadAll = async () => {
  await loadMerkleData();
  await loadShopConfig();
  await getSoldCount();
  await loadCurrentPrice();
  await loadMintedItems();
  await loadTokenInfo();
};

const loadCurrentPrice = async () => {
  await store.dispatch(
    'mint/currentPrice',
    {},
    { root: true }
  );
  return store.state.mint.currentPrice;
}

const loadShopConfig = async () => {
  // load global config
  config = await initializeConfig();

  // delay to ensure all ABIs have loaded
  // not needed once we load ABIs directly instead of via http/get
  // await new Promise(resolve => setTimeout(resolve, 100));

  //console.info("loading shop config at address=", config.shopAddress[store.state.ethers.networkId]);
  await store.dispatch(
    'mint/loadShopConfig',
    {},
    {
      root: true
    }
  );
  getSoldCount();
}

const loadMerkleData = async () => {
  // load whitelist merkle proofs
  await store.dispatch(
    'mint/loadMerkleData',
    {},
    { root: true }
  );
}

const loadMintedItems = async () => {
  if (isConnected.value && config != null) {
    //console.info("loading minted items at address=", config.itemCollections[store.state.ethers.networkId]);
    await store.dispatch(
      'mint/loadMintedItems',
      {
        ethersStore: store.state.ethers, // wallet address (specifically: walletConnect)
      },
      {
        root: true
      }
    );
  };
}

const getSoldCount = async () => {
  if (isReady.value) {
    //console.info("loading minted items at address=", config.itemCollections[store.state.ethers.networkId]);
    await store.dispatch(
      'mint/getSoldCount',
      {},
      {
        root: true
      }
    );
  };
}

// loads allowance and amount of owned tokens
const loadTokenInfo = async () => {
  if (isConnected.value) {
    await store.dispatch(
      'mint/loadTokenInfo',
      { ethersStore: store.state.ethers },
      {
        root: true
      }
    );
  };
}
const approveSuper = async () => {
  transactionSubmitting.value = true
  await store.dispatch('mint/approveSuper', {}, { root: true })
  transactionSubmitting.value = false
}

const isConnected = computed(() => {
  return store.state.ethers.canSign;
});

// update 'now' to current time every 1 sec
useInterval(() => {
  now.value = new Date();
}, 1000);

// update current item price every 5 sec
useInterval(async () => {
  await getSoldCount()
  await loadMintedItems()
}, 1000 * 15);

const isReady = computed(() => {
  return store.state.mint.shopConfig != null;
});

const isWhitelist = computed(() => {
  // uses shopConfig start/end times
  return isReady.value && ((now.value >= wl_tokenStartTime.value && now.value <= wl_tokenEndTime.value) || (now.value >= wl_ethStartTime.value && now.value <= wl_ethEndTime.value));
});

const isPublicMint = computed(() => {
  // uses shopConfig start/end times
  return isReady.value && (now.value >= publicStartTime.value && now.value <= publicEndTime.value);
});

const isOver = computed(() => {
  // uses shopConfig start/end times
  return !isReady.value || (now.value >= publicEndTime.value);
});

const isComingSoon = computed(() => {
  // uses shopConfig start/end times
  return isReady.value && ((now.value < wl_tokenStartTime.value) && (now.value < wl_ethEndTime.value) && (now.value < publicEndTime.value));
});

// computed/helper functions for shop config data
const publicStartDate = computed(() => {
  return new Date(publicStartTime.value);
});

const publicStartTime = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.publicStartTime;
});

const publicEndDate = computed(() => {
  return new Date(publicEndTime.value);
});

const publicEndTime = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.publicEndTime;
});

const publicStartingPrice = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.publicStartingPrice;
});

const publicEndingPrice = computed(() => {
  if (!isReady.value) return 1;
  return store.state.mint.shopConfig.publicEndingPrice;
});

const whitelistStartDate = computed(() => {
  return new Date(wl_tokenStartTime.value);
});

const wl_tokenStartTime = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.tokenStartTime;
});

const wl_tokenEndTime = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.tokenEndTime;
});

const wl_tokenStartingPrice = computed(() => {
  if (!isReady.value) return 0;
  return store.state.mint.shopConfig.tokenStartingPrice;
});

const wl_ethStartTime = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.ethStartTime;
});

const wl_ethEndTime = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.ethEndTime;
});

const wl_ethStartingPrice = computed(() => {
  if (!isReady.value) return 0;
  return store.state.mint.shopConfig.ethStartingPrice;
});

const sold = computed(() => {
  if (!isReady.value) return 0;
  return store.state.mint.soldCount;
});

const totalCap = computed(() => {
  if (!isReady.value) return 0;
  return store.state.mint.shopConfig.totalCap.toNumber();
});

const callerCap = computed(() => {
  if (!isReady.value) return 1;
  return store.state.mint.shopConfig.callerCap.toNumber();
});

const transactionCap = computed(() => {
  if (!isReady.value) return 1;
  return store.state.mint.shopConfig.transactionCap.toNumber();
});


let totalMinted = computed(() => minted.value || 0)

let totalWhitelistMinted = computed(() => Math.min(totalMinted.value, allowance.value))
let whitelistAllocRemaining = computed(() => Math.max(allowance.value - totalMinted.value, 0))
let publicAllocRemaining = computed(() => Math.max(callerCap.value - totalMinted.value, 0))
let canMintNow = computed(() => isWhitelist.value ? whitelistAllocRemaining.value : publicAllocRemaining.value)

const mintQtySelected = ref(transactionCap.value);
let mintQty = computed({
  get() {
    return mintQtySelected.value > canMintNow.value ? Math.max(canMintNow.value, 1) : mintQtySelected.value
  },
  set(v) { mintQtySelected.value = v }
})

let hasEnoughToken = computed(() => {
  const hasBalances = store.state?.mint?.balances;
  if (hasBalances) {
    let { ethBalance, tokenBalance } = store.state.mint.balances;
    let balance = paymentType.value === 'SUPER' ? tokenBalance : ethBalance;
    let cost = mintPrice.value * mintQty.value;
    return balance.gte(ethers.utils.parseEther(cost.toString()))
  }
  return false;
})

let superIsApproved = computed(() => {
  const hasBalances = store.state?.mint?.balances;
  if (hasBalances) {
    let { tokenAllowance } = store.state.mint.balances
    let cost = mintPrice.value * mintQty.value
    return tokenAllowance.gte(ethers.utils.parseEther(cost.toString()))
  }
  return false;
})

const currentPrice = computed(() => {
  const contractCurrentPrice = store.state.mint.currentPrice;
  if (contractCurrentPrice == null && isReady.value) {
    return publicStartingPrice.value;
  }
  return contractCurrentPrice;
});

const currentAddress = computed(() => {
  return store.state.ethers.address;
});

const allowance = computed(() => {
  if (!isReady.value) return 0;
  const proofs = store.state?.mint?.merkleProofs;
  const hasProofs = (proofs && proofs.length > 0);
  return (hasProofs ? store.state.mint.merkleProofs[0].allowance : 0);
});

const mint = async (obj) => {
  let qnt = obj.qnt;
  let useToken = obj.type != "ETH" ? true : false;
  transactionSubmitting.value = true
  // if whitelist is open but user not in whitelist
  if (isWhitelist.value && store.state.mint.merkleProofs.length == 0) {
    await store.dispatch(
      'alert/error',
      {
        message: 'Wallet is not whitelisted',
        duration: 10000
      },
      { root: true }
    );
    transactionSubmitting.value = false
    return;
  }

  // if whitelist and dutchauction period is over
  if (!isWhitelist.value && !isPublicMint.value) {
    await store.dispatch(
      'alert/error',
      {
        message: 'Mint period closed',
        duration: 10000
      },
      { root: true }
    );
    transactionSubmitting.value = false
    return;
  }

  // otherwise, mint....
  await store.dispatch(
    'mint/mint',
    {
      qnt: qnt, // amount of impostors to mint
      isToken: useToken, // user by store/mint.service to correctly calculate amounts to be paid
      isWhitelist: isWhitelist.value, // used by store.service to calculate proof (whitelist)
      mintStore: store.state.mint, // mint prices and whitelists
      ethersStore: store.state.ethers, // wallet address (specifically: walletConnect)
    },
    { root: true }
  );

  // when successful, delay a tiny bit before refreshing store config (and sold values)
  await new Promise(resolve => setTimeout(resolve, 500));

  await Promise.all([
    loadShopConfig(),
    loadMintedItems(),
    loadTokenInfo()
  ])

  transactionSubmitting.value = false
};

watch(
  currentAddress, (curAdd, oldAdd) => {
    if (curAdd == null) {
      // wallet has locked or disconnected altogether
      store.state.ethers.canSign = false;
      store.dispatch('login/initialize');
    }
    merkleService.reset(); // force merkle reload when we read in a new address

    // essentially waits until wallet is ready
    ensureWalletConnected(400000).then(loadAll).catch(err => { let do_nothing = err; });
  }
);

// this following function should NOT be required and
// we should be able to watch over isConnected
// TODO: either get the watch to work or move this into ethers.service
function ensureWalletConnected(timeout) {
  var start = Date.now();
  return new Promise(waitForWallet);

  // waitForWallet makes the decision whether the condition is met
  // or not met or the timeout has been exceeded which means
  // this promise will be rejected
  function waitForWallet(resolve, reject) {
    // check if wallet connect has ever been enabled in the past
    store.commit('checkWalletConnectStore');
    // if we have connected previously, dispatch automagic connection
    if (store.state?.walletconnect?.connected) {
      store.dispatch('ethers/connectWCProvider');
    }
    if (store.state?.ethers?.canSign)
      resolve(store.state.ethers.canSign);
    else if (timeout && (Date.now() - start) >= timeout)
      reject(new Error("timeout"));
    else
      setTimeout(waitForWallet.bind(this, resolve, reject), 30);
  }
}

let soldOut = computed(() => sold.value >= totalCap.value)
</script>

<style scoped lang="scss">
@import "../../assets/style/mixins.scss";

.skeleton {
  padding: 0px 20px;
  padding-bottom: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  height: 100vh;
  overflow: auto;
  position: relative;
  background-color: var(--color-bg-main);
  display: flex;
  flex-direction: column;
}
.container-content {
  flex: 1;
  padding-top: 140px;
}
.primary-bg {
  position: absolute;
  height: 934px;
  width: 100%;
  background-image: linear-gradient(
      0deg,
      var(--color-bg-main),
      15%,
      transparent
    ),
    url("~@/assets/arcade-bg.png");
  background-attachment: scroll;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
  opacity: 0.2;
  pointer-events: none;
}
.section-mint {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @include mediaOnlyScreenMax(1200px) {
    flex-direction: column;
    justify-content: center;

    .main-impostor {
      display: none;
    }

    .status-section {
      order: 2;
    }

    .mint-card-wrapper {
      justify-content: center;
      padding: 0 8px;
      max-width: inherit;
    }
    .mint-card {
      align-self: center;
    }
    .status-section {
      padding: 0 24px;
      align-self: center;
      margin-top: 24px;
      width: 100%;
      font-size: 0.8rem;
    }
    .status-banner {
      --offset: 0;
      width: 100%;
      height: auto;
    }
  }

  @include mediaOnlyScreenMax(500px) {
    .status-section {
      font-size: 0.5rem;
    }
  }
}

.mint-card {
  z-index: 3;
}

.status-section {
  z-index: 1;
  max-width: 750px;
  flex: 1;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;

  &.status-section_sold-out {
    margin-right: 48px;
    img {
      height: 700px;
    }
  }
}

.main-impostor {
  height: 500px;
  margin-bottom: -72px;
  z-index: 2;
}

.status-banner {
  --offset: 55px;
  height: 188px;
  width: calc(100% + var(--offset));
  min-width: calc(680px + var(--offset));
  align-self: flex-end;
  margin-right: calc(var(--offset) * -1);
  padding-right: var(--offset);
}
.dutch {
  padding: 24px;
  padding-left: 64px;
}

.mint-card-wrapper {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  flex: 1;
  max-width: 516px;

  > a {
    margin-top: 24px;
    font-family: DM Sans;
    font-style: normal;
    font-size: 1.64285714rem;
    text-transform: uppercase;
    color: white;
    text-decoration: none;
  }
}
</style>
