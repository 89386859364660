<template>
  <SkewedModal
    :borderRadius="16"
    :skew="[[0, -10], 0, [-6, 0], [8, 0]]"
    background="var(--color-bg-modal)"
    @exit="exit"
    :class="$style['select-items-modal']"
    :classModalContent="$style['select-items-content']"
  >
    <template #title>
      <div :class="$style.titleWrapper">
        <span :class="$style.title">{{ title }}</span>
        <div v-if="showBloodBalance" :class="$style.bloodBalance">
          <img
            :class="$style.bloodIconHeader"
            src="@/images/blood-icon-large.webp"
          />
          <span>{{ bloodBalanceFormat }}</span>
        </div>
      </div>
    </template>

    <template #content>
      <div :class="$style['content-wrapper']">
        <!-- unstaked container -->
        <div :class="$style['impostors-container']">
          <TokenCard
            :token="item"
            :tokenType="item.tokenType"
            v-for="item in itemsSorted"
            :key="item.tokenId"
            :isSelected="selectedItems.has(item.tokenId)"
            :isDisabled="!props.canSelectItem(item)"
            @click="selectImpostor(item)"
          />
          <slot name="tokenCards" />
        </div>
      </div>
    </template>

    <template #footer>
      <div :class="$style['button-wrapper']">
        <SkewedButton
          v-if="!props.singleSelect"
          :borderRadius="6"
          :skew="[0, 0, [-5, -3], 0]"
          background="#000000"
          :class="$style['button']"
          @click="selectAllEligible"
        >
          <span :class="$style['secondary']">{{ selectText }}</span>
        </SkewedButton>
        <SkewedButton
          :borderRadius="6"
          :skew="[0, 0, [-5, -3], 0]"
          background="var(--color-button-yellow)"
          :class="$style.button"
          :disabled="checkEligibility || isSubmitting"
          @click="submit"
        >
          <div>
            <slot name="actionText" :selectedCount="selectedItems.size">
              <span>{{ actionText }}</span>
            </slot>
            <span
              :class="$style['select-count']"
              v-if="selectedItems.size > 0 && !props.singleSelect"
            >
              {{ selectedItems.size }}
            </span>
          </div>
        </SkewedButton>
      </div>
    </template>
  </SkewedModal>
</template>
<script setup>
import SkewedModal from '@/components/common/SkewedModal.vue';
import SkewedButton from '@/components/common/SkewedButton.vue';
import TokenCard from '@/components/common/TokenCard.vue';

import { computed, ref, watch } from 'vue';

const props = defineProps({
  items: { type: Array },
  isSubmitting: { type: Boolean },
  title: { type: String },
  actionText: { type: String },
  selectAllText: { type: String, default: 'Select All' },
  canSelectItem: { type: Function, default: () => true },
  singleSelect: { type: Boolean },
  bloodBalance: { type: [Number], default: 0 },
  showBloodBalance: { type: Boolean }
});
const emit = defineEmits(['exit', 'submit']);

const exit = () => {
  emit('exit');
};

let { format } = new Intl.NumberFormat('en-US', {maximumFractionDigits: 2});
let bloodBalanceFormat = computed(() => format(Math.floor(parseFloat(props.bloodBalance) * 100) / 100));

const selectedItems = ref(new Set());

const itemsSorted = computed(() => {
  return [...props.items].sort((a, b) => {
    let aSelectable = props.canSelectItem(a);
    let bSelectable = props.canSelectItem(b);
    if (aSelectable && bSelectable) {
      return parseInt(a.tokenId, 10) - parseInt(b.tokenId, 10);
    } else if (aSelectable) {
      return -1;
    } else if (bSelectable) {
      return 1;
    } else {
      return 0;
    }
  });
});

const selectImpostor = item => {
  if (!props.canSelectItem(item)) return;

  if (selectedItems.value.has(item.tokenId)) {
    selectedItems.value.delete(item.tokenId);
  } else {
    if (props.singleSelect) {
      selectedItems.value.clear();
    }
    selectedItems.value.add(item.tokenId);
  }
};

let selectable = computed(() => {
  let items = new Set();
  for (let i = 0; i < props.items.length; i++) {
    if (props.canSelectItem(props.items[i])) items.add(props.items[i].tokenId);
  }
  return items;
});

watch(selectable, () => {
  // clear out selection when impostors update after staking
  for (let s of selectedItems.value) {
    if (!selectable.value.has(s)) {
      selectedItems.value.delete(s);
    }
  }
});

const allSelected = computed(() => {
  if (selectedItems.value.size !== selectable.value.size) return false;
  for (let i of selectable.value) if (!selectedItems.value.has(i)) return false;
  return true;
});

const selectAllEligible = () => {
  if (allSelected.value) {
    selectedItems.value.clear();
  } else {
    for (let item of selectable.value) {
      selectedItems.value.add(item);
    }
  }
};

const checkEligibility = computed(() => {
  const hasIneligible = props.items.filter(
    impostor =>
      !props.canSelectItem(impostor) &&
      selectedItems.value.has(impostor.tokenId)
  );
  // reminder: function disables the unstaking button
  return !(selectedItems.value.size > 0 && hasIneligible.length === 0);
});

const selectText = computed(() => {
  return allSelected.value && selectable.value.size > 0
    ? 'Deselect All'
    : props.selectAllText;
});

let submit = () => {
  emit('submit', {
    nftIds: Array.from(selectedItems.value),
    nfts: props.items.filter(({ tokenId }) => selectedItems.value.has(tokenId))
  });
};

const actionTitle = computed(() => {
  return '';
});
</script>

<style module lang="scss">
@import '@/assets/style/mixins.scss';

.select-items-modal {
  &::v-deep .skewed-modal-content {
    padding-bottom: 0px;
  }
}

.bloodBalance {
  display: flex;
  align-items: center;
  gap: 14px;
  background: rgba(33, 33, 33, 0.3);
  padding: 10px 13px;
  border-radius: 5px;
  font-size: 22px;
}

.bloodIconHeader {
  height: 36px;
}

.title {
  flex: 1;
}

.titleWrapper {
  display: flex;
  width: 100%;
  padding-right: 16px;
  @include mMax(550px) {
    flex-direction: column;
    gap: 12px;
  }
}

.select-items-content {
  padding-bottom: 0px !important;
}

.content-wrapper {
  width: 100%;
  padding-left: 3px;
  .impostors-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

.footer-text {
  margin-bottom: 18px;
  padding-top: 20px;
  font-size: 12px;
}

.cards-container {
  padding-top: 18px;
  p {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .cards-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}
.button-wrapper {
  display: flex;
  gap: 16px;
  justify-content: flex-end;

  .button {
    font-weight: 500;
    font-size: 18px;
    padding-right: 33px;
    padding-left: 33px;
    /* max-width: 250px; */
    /* width: 100%; */

    .secondary {
      color: white;
    }

    span {
      text-transform: capitalize;
    }
  }
}

.select-count {
  &:before {
    content: '(';
  }
  &:after {
    content: ')';
  }

  margin-left: 0.3em;
}

@media (max-width: 1060px) {
  .cards-container .cards-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .button-wrapper {
    flex-direction: column;
    .button {
      max-width: 100%;
    }
  }
}

@media (max-width: 470px) {
  .cards-container .cards-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .select-items-modal {
    :global(.top-wrapper) :global(.icon) {
      align-self: flex-start;
    }
  }
}
</style>
