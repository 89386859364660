'use strict';

// Imports.
import axios from 'axios';
import initializeConfig from '../initialize-config';
import { ethersService } from './index';
import { ethers } from 'ethers';
import { errMsg, processError } from '../utility';
import { l } from '@/datadog';
const logName = 'staker.service.js';

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

// attempts to hit contract. on failure,
// flags caller.
// returns: error message (string)
export const checkValidProvider = async () => {
  try {
    // find out if network is valid (or fail)
    let p = await ethersService.getProvider();
    // check what current provider network is using
    let n = await p.getNetwork();
    if (config.forceNetwork != n.chainId) {
      // if current network is not the one user in enforcing, fail
      return errMsg('WrongNetwork');
    }
    // attempts to call expected function from known staker contract
    let networkId = ethers.utils.hexValue(n.chainId);
    let s = config.stakerAddress[networkId];
    let c = new ethers.Contract(s, config.stakerABI, p);
    await c.pools(1);
    return null;
  } catch (error) {
    return errMsg('WrongNetwork');
  }
};

// loads staking pools
// Returns array of struct Pool:
//   item The address of the item contract that is allowed to be staked in
//     this pool.
//   lockedTokensPerSecond The amount of token that each item staked in
//     this pool earns each second while it is locked during the `lockDuration`.
//   unlockedTokensPerSecond The amount of token that each item staked in
//     this pool earns each second while it is unlocked and available for
//     withdrawal.
//   lockDuration The amount of time in seconds wherein this pool requires
//     that the asset remain time-locked and unavailable to withdraw. Once the
//     item has been deposited for `lockDuration` seconds, the item may be
//     withdrawn from the pool and the number of tokens earned changes to the
//     `unlockedTokensPerSecond` rate.
//   deadline Determines the time after which no more deposits are accepted
const loadPools = async function(dispatch) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let stakerAddress = config.stakerAddress[networkId];
  let stakerContract = new ethers.Contract(
    stakerAddress,
    config.stakerABI,
    provider
  );

  let pools = [];
  let baselinePool = 0;
  for (let idx = 0; idx < config.stakerPools.length; idx++) {
    let p = await stakerContract.pools(config.stakerPools[idx]);
    // do not assume first index is baseline
    if (p.lockDuration.eq(0)) {
      baselinePool = idx;
    }
    let deadlineDate = null;
    if (p.deadline.eq(ethers.constants.MaxUint256)) {
      deadlineDate = null;
    } else {
      var d = new Date(0);
      d.setUTCSeconds(p.deadline.toString());
      deadlineDate = d;
    }
    pools.push({
      id: config.stakerPools[idx],
      item: p.item,
      lockedTokensPerSecond: p.lockedTokensPerSecond,
      unlockedTokensPerSecond: p.unlockedTokensPerSecond,
      lockDuration: p.lockDuration,
      deadlineDate: deadlineDate,
      isExpired: false
    });
  }
  // populate objs with U/I friendly fields
  const numSecsDay = 60 * 60 * 24;
  const baselineEarning = pools[baselinePool].lockedTokensPerSecond.mul(numSecsDay);
  pools.forEach(p => {
    p.collectionAddress = p.item;
    const poolEarning = p.lockedTokensPerSecond.mul(numSecsDay);
    // round it off
    p.blood = Math.round(ethers.utils.formatEther(poolEarning));
    let boost = poolEarning.sub(baselineEarning);
    if (boost.eq(0)) {
      p.boost = '';
    } else {
      try {
        // use floats and round number off
        p.boost = '+' + Math.round(parseFloat(boost) * 100 / parseFloat(baselineEarning)) + '%';
        /*
        // the next snippet is required due to precision 
        // being lost (see https://github.com/ethers-io/ethers.js/issues/488)
        // (ie. BigNumber only supports integer)
        boost = boost.mul('1000').div(baselineEarning);
        p.boost = '+' + boost.div('10').toString() + '%';
        */
      } catch (er) {
        if (baselineEarning.eq(0)) {
          // divide by zero is expected
          p.boost = '';
        } else {
          // all other errors are not
          console.error(er);
        }
      }
    }
    let dur = p.lockDuration.div(numSecsDay);
    if (!p.lockDuration.eq(0) && dur.eq(0)) {
      // we lost precision (lock duration is in hours?)... 
      // we can .toNumber() safely since we now
      // BigInteger is dealing with floats
      dur = p.lockDuration.toNumber() / numSecsDay;
    } else {
      dur = dur.toString();
    }
    if (dur == 0) {
      p.duration = 'flexible';
      p.lockPeriod = 'unstake anytime';
    } else {
      const durP = parseFloat(dur).toFixed(0);
      p.duration = durP + ' days';
      p.lockPeriod = 'locked for ' + durP + ' days';
      if (p.deadlineDate - Date.now() <= 0) {
        p.isExpired = true;
      }
    }
  });
  return pools;
};


const makeItemStatusRequest = async function(ownedSet, stakedNfts, userDailyStakedEarning, userDailyBlood, currentTime, itemContract, stakerContract, poolStaking, pool, ogNft, baselineEarning) {
  //console.time('find' + ogNft.tokenId);
  let found = poolStaking.find(tokenId => tokenId == ogNft.tokenId);
  //console.timeEnd('find' + ogNft.tokenId);
  if (found) {
    const numSecsDay = 60 * 60 * 24;
    //console.time('itemStatuses' + ogNft.tokenId);
    let ogStatus = await stakerContract.itemStatuses(ogNft.collectionAddress, ogNft.tokenId);
    //console.timeEnd('itemStatuses' + ogNft.tokenId);
    let holdingTime = currentTime.timestamp - ogStatus.stakedAt.toString();
    const poolEarning = pool.lockedTokensPerSecond.mul(numSecsDay);
    userDailyStakedEarning.push(parseFloat(poolEarning.toString()));
    const minutesRemaining = pool.lockDuration.sub(holdingTime).div(60)
    const daysRemaining = pool.lockDuration.sub(holdingTime).div(numSecsDay);
    const daysRemainingMod = pool.lockDuration.sub(holdingTime).mod(numSecsDay);
    let isUnstakable = pool.lockDuration.sub(holdingTime).lte(0);
    let isStaked = true; // all these are staked
    // TODO: this is a hack from the last-minute genesis season migration patch.
    isStaked = await itemContract.transferLocks(ogNft.tokenId);
    // end hack
    let bloodD = pool.blood;
    if (isUnstakable) {
      bloodD = baselineEarning.toString();
    }
    userDailyBlood.push(Number(bloodD));
    stakedNfts.push({
      tokenId: ogNft.tokenId,
      collectionAddress: ogNft.collectionAddress,
      image: ogNft.metadata.image,
      isStaked: isStaked,
      daysTotal: pool.lockDuration.div(numSecsDay),
      lockDuration: pool.lockDuration,
      bloodPerDay: bloodD,
      stakedPool: ogStatus.stakedPool,
      stakedAt: ogStatus.stakedAt,
      tokenClaimed: ogStatus.tokenClaimed,
      daysStaked: holdingTime / numSecsDay,
      daysRemaining: daysRemaining.lt(0) ? 0 : daysRemaining.add(daysRemainingMod.gt(0) ? 1 : 0),
      minutesRemaining,
      isUnstakable: isUnstakable
    });
    ownedSet.delete(ogNft);

    /*
      userDailyStakedEarning += pool1DailyEarning;
 
      else :
      userDailyStakedEarning += unlockedDailyEarning;
    */
  }
}

// loads staked NFT statues
// Returns array of struct ItemStatus:
//   stakedPool The ID of the pool where this item is currently staked. An
//     ID of zero indicates that the item is not staked to any pool.
//   stakedAt The time when this item was last staked in the pool. This is
//     used to control earning rates due to time-locking in a pool.
//   tokenClaimed The number of tokens claimed by this item so far. This
//     is used to support partial claiming of earned tokens during a pool's full
//     `_lockDuration`.
const loadStakedStatus = async function(pools, owned, legacy, dispatch) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let stakerAddress, stakerABI;
  if (legacy) {
    stakerAddress = config.stakerLegacyAddress[networkId];
    stakerABI = config.stakerLegacyABI;
  } else {
    stakerAddress = config.stakerAddress[networkId];
    stakerABI = config.stakerABI;
  }
  let stakerContract = new ethers.Contract(
    stakerAddress,
    stakerABI,
    provider
  );

  // TODO: this is a hack from the last-minute genesis season migration patch.
  let itemContract = new ethers.Contract(
    config.itemCollections[networkId],
    config.itemABI,
    provider
  );
  // end hack

  // Used while checking for token spend approval.
  let signer = await provider.getSigner();
  let walletAddress = await signer.getAddress();

  let blockNumber = await provider.getBlockNumber();
  let currentTime = await provider.getBlock(blockNumber);

  let stakedNfts = [];
  let userDailyStakedEarning = [];
  let userDailyBlood = [];

  // we use a set which allows us to replace/delete an element while
  // iterating without any issues
  // see specification from %SetIteratorPrototype%.next:
  //    * Repeat while index is less than the total number of 
  //    * elements of entries. The number of elements must be
  //    * redetermined each time this method is evaluated.
  //console.time('create set');
  let ownedSet = new Set();
  owned.forEach(item => ownedSet.add(item));
  //console.timeEnd('create set');

  // identify baseline blood payout values
  // do not assume first index is baseline
  let baselineEarning = 0;
  for (let idx = 0; idx < pools.length; idx++) {
    const pool = pools[idx];
    if (pool.lockDuration.eq(0)) {
      baselineEarning = pool.blood;
      break;
    }
  }

  // populate objs with U/I friendly fields
  for (let idx = 0; idx < pools.length; idx++) {
    const pool = pools[idx];

    let poolStaking;
    if (legacy) {
      // legacy only staker (v1)
      //console.time('getItemsPosition' + pool.id);
      poolStaking = await stakerContract.getItemsPosition(pool.id, walletAddress);
      //console.timeEnd('getItemsPosition' + pool.id);
    } else {
      let itemPosition = await stakerContract.getPosition(pool.id, walletAddress);
      poolStaking = itemPosition[0];
    }

    //const numSecsDay = 60 * 60 * 24;
    const itemStatusLookup = [];
    for (let it = ownedSet.values(), ogNft = null; ogNft = it.next().value;) {
      itemStatusLookup.push(
        makeItemStatusRequest(
          ownedSet,
          stakedNfts,
          userDailyStakedEarning,
          userDailyBlood,
          currentTime,
          itemContract,
          stakerContract,
          poolStaking,
          pool,
          ogNft,
          baselineEarning
        )
      );
    }
    //console.time('promise itemStatuses' + pool.id);
    await Promise.all(itemStatusLookup);
    //console.timeEnd('promise itemStatuses' + pool.id);
  }
  // remaining Nfts are not staked in any pool
  for (let it = ownedSet.values(), ogNft = null; ogNft = it.next().value;) {
    stakedNfts.push({
      tokenId: ogNft.tokenId,
      collectionAddress: ogNft.collectionAddress,
      image: ogNft.metadata.image,
      isStaked: false,
      isUnstakable: true
    });
  }
  let dailyBlood = 0;
  userDailyBlood.forEach(b => dailyBlood += b);

  return [stakedNfts, dailyBlood];
};

// stake array of NFTs into pool
const stake = async function(nfts, poolId, dispatch) {
  l.dbg(this, { f: [logName, 'stake'], p: [nfts, poolId] });

  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let signer = await provider.getSigner();

  let stakerAddress = config.stakerAddress[networkId];
  let stakerContract = new ethers.Contract(
    stakerAddress,
    config.stakerABI,
    signer
  );

  let stakeTx = null;
  try {
    stakeTx = await stakerContract.stake(poolId, nfts);
  } catch (error) {
    await processError(errMsg(error.message), true, dispatch);
  }

  if (stakeTx != null) {
    await dispatch(
      'alert/info',
      {
        message: 'Transaction Submitted',
        metadata: {
          transaction: stakeTx.hash
        },
        duration: 300000
      },
      { root: true }
    );

    await stakeTx
      .wait()
      .then(async result => {
        //console.info('Result from stake attempt', result);
        await dispatch('alert/clear', '', { root: true });
        await dispatch(
          'alert/info',
          {
            message: 'Transaction Confirmed',
            metadata: {
              transaction: stakeTx.hash
            },
            duration: 10000
          },
          { root: true }
        );
      })
      .catch(async function(error) {
        await processError(errMsg(error.message), true, dispatch);
      });
  }
};

// unstake array of NFTs into pool
const unstake = async function(nfts, poolId, legacy, dispatch) {
  l.dbg(this, { f: [logName, 'unstake'], p: [nfts, poolId, legacy] });

  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let signer = await provider.getSigner();

  let stakerAddress, stakerABI;
  if (legacy) {
    stakerAddress = config.stakerLegacyAddress[networkId];
    stakerABI = config.stakerLegacyABI;
  } else {
    stakerAddress = config.stakerAddress[networkId];
    stakerABI = config.stakerABI;
  }

  let stakerContract = new ethers.Contract(
    stakerAddress,
    stakerABI,
    signer
  );

  let unstakeTx = null;
  try {
    unstakeTx = await stakerContract.withdraw(poolId, nfts);//, { gasLimit: 0x1000000 });
  } catch (error) {
    await processError(errMsg(error.message), true, dispatch);
  }

  if (unstakeTx != null) {
    await dispatch(
      'alert/info',
      {
        message: 'Transaction Submitted',
        metadata: {
          transaction: unstakeTx.hash
        },
        duration: 300000
      },
      { root: true }
    );

    await unstakeTx
      .wait()
      .then(async result => {
        //console.info('Result from stake attempt', result);
        await dispatch('alert/clear', '', { root: true });
        await dispatch(
          'alert/info',
          {
            message: 'Transaction Confirmed',
            metadata: {
              transaction: unstakeTx.hash
            },
            duration: 10000
          },
          { root: true }
        );
      })
      .catch(async function(error) {
        await processError(errMsg(error.message), true, dispatch);
      });
  }
};

// Parse the allowance and number of token owned by a given address.
const loadTokenInfo = async function(legacy, dispatch) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let tokenAddress = config.bloodTokenAddress[networkId];
  let signer = await provider.getSigner();
  let walletAddress = await signer.getAddress();

  let stakerAddress, stakerABI;
  if (legacy) {
    stakerAddress = config.stakerLegacyAddress[networkId];
    stakerABI = config.stakerLegacyABI;
  } else {
    stakerAddress = config.stakerAddress[networkId];
    stakerABI = config.stakerABI;
  }

  let erc20Contract = new ethers.Contract(
    tokenAddress,
    config.erc20ABI,
    signer
  );
  let balance = await erc20Contract.balanceOf(walletAddress);

  let stakerContract = new ethers.Contract(
    stakerAddress,
    stakerABI,
    signer
  );

  // let claimable = await stakerContract.pendingClaims(config.stakerPools, walletAddress);
  // TODO: this is a hack from the last-minute genesis season migration patch.
  let claimable = ethers.BigNumber.from(0);
  let stakerClaimAddress = config.stakerClaimAddress[networkId];
  let stakerClaimContract = new ethers.Contract(
    stakerClaimAddress,
    config.stakerClaimABI,
    provider
  );
  let hasClaimed = await stakerClaimContract.claimed(walletAddress);
  if (!hasClaimed) {
    let claimSignatureResponse = await axios.get(`https://api.superfarm.com/storage/${walletAddress.toLowerCase()}`);
    claimable = ethers.BigNumber.from(claimSignatureResponse.data.claimable);
  }
  // end hack

  /*
    lastCollected: BigNumber.from(0),
    totalCollected: BigNumber.from(0)
  */

  return {
    tokenBalance: ethers.utils.formatEther(balance),
    hasBlood: balance.gt(0),
    claimable: ethers.utils.formatEther(claimable)
  };
};

// Claim all tokens from all pools
const claim = async function(dispatch) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let signer = await provider.getSigner();

  let stakerAddress = config.stakerAddress[networkId];

  let stakerContract = new ethers.Contract(
    stakerAddress,
    config.stakerABI,
    signer
  );

  // Determine which specific pools the caller is staked in.
  let caller = await signer.getAddress();
  let positions = {};
  for (let pool of config.stakerPools) {
    positions[pool] = (await stakerContract.getPosition(pool, caller))[0];
  }

  // Optimize claiming to claim for only those pools with valid item holdings.
  let gatheredTokenIds = [];
  let claimingPools = [];
  for (let pool of Object.keys(positions)) {
    let stakedTokens = positions[pool];
    const alreadyGathered = gatheredTokenIds.some(
      id => stakedTokens.includes(id)
    );
    if (stakedTokens.length > 0 && !alreadyGathered) {
      claimingPools.push(pool);
      gatheredTokenIds = gatheredTokenIds.concat(stakedTokens);
    }
  }

  l.dbg(this, { f: [logName, 'claim'], p: claimingPools });

  let claimTx = null;
  try {
    // claimTx = await stakerContract.claim(claimingPools);
    // TODO: this is a hack from the last-minute genesis season migration patch.
    let stakerClaimAddress = config.stakerClaimAddress[networkId];
    let stakerClaimContract = new ethers.Contract(
      stakerClaimAddress,
      config.stakerClaimABI,
      signer
    );
    let bloodTokenAddress = config.bloodTokenAddress[networkId];
    let hasClaimed = await stakerClaimContract.claimed(caller);
    if (!hasClaimed) {
      let claimSignatureResponse = await axios.get(`https://api.superfarm.com/storage/${caller.toLowerCase()}`);
      let claimable = ethers.BigNumber.from(claimSignatureResponse.data.claimable);
      claimTx = await stakerClaimContract.claim(
        bloodTokenAddress,
        claimable,
        claimSignatureResponse.data.v,
        claimSignatureResponse.data.r,
        claimSignatureResponse.data.s
      )
    }
    // end hack
  } catch (error) {
    await processError(errMsg(error.message), true, dispatch);
  }

  if (claimTx != null) {
    await dispatch(
      'alert/info',
      {
        message: 'Transaction Submitted',
        metadata: {
          transaction: claimTx.hash
        },
        duration: 300000
      },
      { root: true }
    );

    await claimTx
      .wait()
      .then(async result => {
        //console.info('Result from stake attempt', result);
        await dispatch('alert/clear', '', { root: true });
        await dispatch(
          'alert/info',
          {
            message: 'Transaction Confirmed',
            metadata: {
              transaction: claimTx.hash
            },
            duration: 10000
          },
          { root: true }
        );
      })
      .catch(async function(error) {
        await processError(errMsg(error.message), true, dispatch);
      });
  }
};

// TODO: this function is replicated here and in mint.service.js
async function safeQueryFilter(contract, event, startBlock, endBlock) {
  let start = startBlock
  // let end = await provider.getBlockNumber()
  let end = endBlock
  let endRange = end

  let results = []
  do {
    if (start >= endRange) {
      endRange = end
    }
    let singleTransfers = []
    try {
      singleTransfers = await contract.queryFilter(event, start, endRange);
    } catch (e) {
      let mid = Math.round((start + endRange) / 2)
      endRange = mid
      continue
    }
    results = results.concat(singleTransfers)
    start = endRange + 1
  } while (endRange < end)
  return results
}

const loadClaimEvents = async function(dispatch) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let stakerAddress = config.stakerAddress[networkId];
  let stakerContract = new ethers.Contract(
    stakerAddress,
    config.stakerABI,
    provider
  );

  let signer = await provider.getSigner();
  let walletAddress = await signer.getAddress();

  // FixedStaker claim events:
  //  - timestamp The block timestamp when this event was emitted.
  //  - caller The caller who triggered the claim.
  //  - poolIds The array of pool IDs where tokens were claimed from.
  //  - amount The amount of `token` claimed by the `caller` in this event.
  let claimsFilter = stakerContract.filters.Claim(null, walletAddress, null, null);
  let singleTransfers = await safeQueryFilter(stakerContract, claimsFilter);

  let latest = new Date(0);
  let totalCollected = 0.0;
  let lastCollected = 0.0;

  for (let t of singleTransfers) {
    const collected = parseFloat(ethers.utils.formatEther(t.args.amount));

    var d = new Date(0);
    d.setUTCSeconds(t.args.timestamp);
    if (d > latest && collected > 0) {
      // use the latest collected value (as long as it isn't 0)
      lastCollected = collected;
      latest = d;
    }

    totalCollected += collected;
  }

  // TODO: this is a hack from the last-minute genesis season migration patch.
  let stakerClaimAddress = config.stakerClaimAddress[networkId];
  let stakerClaimContract = new ethers.Contract(
    stakerClaimAddress,
    config.stakerClaimABI,
    provider
  );
  let claimSignatureResponse = await axios.get(`https://api.superfarm.com/storage/${walletAddress.toLowerCase()}`);
  let claimable = ethers.BigNumber.from(claimSignatureResponse.data.claimable);
  totalCollected += parseFloat(ethers.utils.formatEther(claimable));
  let hasClaimed = await stakerClaimContract.claimed(walletAddress);
  if (hasClaimed) {
    lastCollected = parseFloat(ethers.utils.formatEther(claimable));
  }
  // end hack

  return {
    lastCollected: lastCollected,
    totalCollected: totalCollected
  }
};

// Export the staker service functions.
export const stakerService = {
  loadPools,
  loadStakedStatus,
  stake,
  unstake,
  loadTokenInfo,
  claim,
  loadClaimEvents
};
