<template>
  <Header :isHome="false" />
  <div class="bg-wrapper">
    <div class="primary-bg"></div>
    <div class="fade"></div>
  </div>
  <Ethereum :callback="initialize" />
  <div class="container">
    <MyItemsSkeleton v-if="isLoading" />
    <SkewedDiv v-else-if="isConnected" class="wrapper" :borderRadius="15" background="#2F4EB6" :skew="[0, 2, 10, -10]">
      <template v-if="!!viewNft">
        <ItemDetails @goBack="goBack" :item="viewNft" />
      </template>
      <template v-else>
        <div class="header">
          <h1>My Items</h1>
        </div>

        <SkewedDiv :borderRadius="2" background="#AAB8FF" :skew="[[0, -6], [0, -3], 10, 0]">
          <div class="line"></div>
        </SkewedDiv>

        <SkewedTabs :tabs="tabs" :currentTabIndex="currentTabIndex" @changeTabIndex="handleSelection" class="tabs"
          v-slot="{ tab, isSelected }">
          {{ tab }}
          <span class="tab-count" :class="{ selected: isSelected }">{{
              tabCounts[tab]
          }}</span>
        </SkewedTabs>

        <div class="nft-wrapper">
          <div class="nft revealed" v-for="nft in nfts" :key="`${nft.tokenType}-${nft.tokenId}`"
            @click="viewNftDetails(nft)">
            <div class="img-wrapper">
              <ImpostorStakedPreviewCard v-if="
                stakedImpostorsById[nft.tokenId] &&
                nft.tokenType === IMPOSTOR_TOKEN_TYPE
              " :tokenId="nft.tokenId" :tokenType="nft.tokenType" class="impostor-preview"
                :stakedInfo="stakedImpostorsById[nft.tokenId]" />
              <ImpostorPreviewCard v-else :tokenId="nft.tokenId" :tokenType="nft.tokenType" class="impostor-preview" />
            </div>
            <p>{{ nft.title }} #{{ nft.tokenId }}</p>
          </div>
          <BuyOnGigamart :tokenType="buyType" class="impostor-preview nft gigamart" hideHover />
        </div>
      </template>
    </SkewedDiv>
    <div class="connectWalletWrapper" v-else>
      <ConnectWallet />
    </div>
  </div>
</template>
<script setup>
// Imports.
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import initializeConfig from '/src/initialize-config';
import { l } from '@/datadog';
import { flag } from '@/featureflag';
import { configService } from '@/services';
import { accounts } from '@/account';

import Ethereum from '/src/components/common/Ethereum.vue';
import Header from '../layout/Header.vue';
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import SkewedTabs from '@/components/common/SkewedTabs.vue';
import BuyOnGigamart from '@/components/common/BuyOnGigamart.vue';
import MyItemsSkeleton from './components/MyItemsSkeleton.vue';
import ConnectWallet from '@/components/common/ConnectWallet.vue';
import ImpostorPreviewCard from '@/components/common/ImpostorPreviewCard.vue';
import ImpostorStakedPreviewCard from '@/components/common/ImpostorStakedPreviewCard.vue';
import {
  IMPOSTOR_TOKEN_TYPE,
  UFO_TOKEN_TYPE,
  PET_TOKEN_TYPE,
  BOX_TOKEN_TYPE,
  CHEST_TOKEN_TYPE,
  PASS_TOKEN_TYPE,
  VOXEL_TOKEN_TYPE
} from '@/constants';
import ItemDetails from './components/ItemDetails.vue';
import { useClaimFeatureIsLive } from '@/pages/claim/composables';
import { useQueryClient, useQuery } from 'vue-query';

const { loggedInUser } = accounts.status(store);

let { data: config, isLoading: configIsLoading } = configService.cfg();

const isEthLoaded = ref(false);
const initialize = async () => {
  isEthLoaded.value = true;
};

let { isLoading: isLoadingMyItems } = useQuery(
  'myItems',
  async () => {
    await loadAll();
  },
  {
    enabled: computed(() => isEthLoaded.value && isConnected.value)
  }
);

const isLoading = computed(
  () => !isEthLoaded.value || isLoadingMyItems.value || configIsLoading.value
);

const loadAll = async () => {
  await loadOwnedItems();
  await loadPools();
  await loadStaked();
};

const store = useStore();
const router = useRouter();
// const impostorNfts = ref([]);
const impostorNfts = computed(
  () =>
    (config.value &&
      store.state.claim.owned?.[
      config.value?.itemCollections[config.value?.forceNetwork]
      ]) ??
    []
);
const stakedImpostorsById = computed(() =>
  Object.values(
    (store.state.stake.staked ?? []).filter(({ isStaked }) => isStaked)
  ).reduce(
    (acc, n) => ({
      ...acc,
      [n.tokenId]: n
    }),
    {}
  )
);
const ufoNfts = computed(
  () =>
    (config.value &&
      store.state.claim.owned?.[
      config.value?.ufoCollections?.[config.value?.forceNetwork]
      ]) ??
    []
);
const petNfts = computed(
  () =>
    (config.value &&
      store.state.claim.owned?.[
      config.value?.petCollections?.[config.value?.forceNetwork]
      ]) ??
    []
);
const boxNfts = computed(
  () =>
    (config.value &&
      store.state.claim.owned?.[
      config.value?.boxCollections?.[config.value?.forceNetwork]
      ]) ??
    []
);
const chestNfts = computed(
  () =>
    (config.value &&
      store.state.claim.owned?.[
      config.value?.chestCollections?.[config.value?.forceNetwork]
      ]) ??
    []
);
const passNfts = computed(
  () =>
    (config.value &&
      store.state.claim.owned?.[
      config.value?.passCollections?.[config.value?.forceNetwork]
      ]) ??
    []
);
const voxelNfts = computed(
  () =>
    (config.value &&
      store.state.claim.owned?.[
        config.value?.voxelCollections?.[config.value?.forceNetwork]
      ]) ??
    []
);
const allNfts = computed(() => [
  ...impostorNfts.value,
  ...ufoNfts.value,
  ...petNfts.value,
  ...boxNfts.value,
  ...(claimIsLive.value.chest ? chestNfts.value : []),
  //...(claimIsLive.value.pass ? passNfts.value : [])
  ...(claimIsLive.value.voxel ? voxelNfts.value : [])
]);
const nfts = computed(() => {
  switch (currentTabIndex.value) {
    case 0:
      return allNfts.value;
    case 1:
      return impostorNfts.value;
    case 2:
      return ufoNfts.value;
    case 3:
      return petNfts.value;
    case 4:
      return boxNfts.value;
    case 5:
      return chestNfts.value;
    case 6:
      return voxelNfts.value;
    default:
      return allNfts.value;
  }
});
const claimIsLive = useClaimFeatureIsLive();
const tabs = computed(() => [
  'All',
  'Aliens',
  'UFOs',
  'Pets',
  ...(claimIsLive.value.box ? ['Boxes'] : []),
  ...(claimIsLive.value.chest ? ['Chests'] : []),
//   ...(claimIsLive.value.pass ? ['Passes'] : [])
  ...(claimIsLive.value.voxel ? ['Voxels'] : [])
]);
const tabCounts = computed(() => {
  let counts = {
    Aliens: impostorNfts.value?.length,
    UFOs: ufoNfts.value?.length,
    Pets: petNfts.value?.length,
    Boxes: boxNfts.value?.length,
    Chests: chestNfts.value?.length,
    Passes: passNfts.value?.length,
  Voxels: voxelNfts.value?.length
  };
  return {
    All: Object.values(counts).reduce((a, b) => a + (!b ? 0 : b), 0),
    ...counts
  };
});
const currentTabIndex = ref(0);
const buyType = computed(
  () =>
  ({
    0: IMPOSTOR_TOKEN_TYPE,
    1: IMPOSTOR_TOKEN_TYPE,
    2: UFO_TOKEN_TYPE,
    3: PET_TOKEN_TYPE,
    4: BOX_TOKEN_TYPE,
    5: CHEST_TOKEN_TYPE,
    6: VOXEL_TOKEN_TYPE
  }[currentTabIndex.value])
);
const viewNft = ref(null);

let goBack = () => {
  viewNft.value = null;
};

onMounted(() => {
  window.scroll(0, 0);
});

let queryClient = useQueryClient();

// const isRevealed = nft => nft.metadata?.attributes?.length > 0 || true;
const viewNftDetails = nft => {
  if (nft.tokenType === 'impostor') {
    // prime the cache ahead of time
    queryClient.setQueryData(['impostor', String(nft.tokenId)], nft);
    router.push({
      name: 'ImpostorDetail',
      params: {
        id: String(nft.tokenId)
      }
    });
} else if (nft.tokenType === 'voxel') {
    // prime the cache ahead of time
    queryClient.setQueryData(['voxel', String(nft.tokenId)], nft);
    router.push({
      name: 'VoxelDetail',
      params: {
        id: String(nft.tokenId)
      }
    });
  } else {
    window.open(
      `https://gigamart.com/collections/${nft.collectionAddress}/${nft.tokenId}`,
      '_blank'
    );
  }
};

// Click handler for changing currentTabIndex.
const handleSelection = index => {
  currentTabIndex.value = index;
};

const currentAddress = computed(() => {
  return store.state.ethers.address;
});

// Loads logged in user's owned items.
const loadOwnedItems = async () => {
  if (isConnected.value) {
    // the following code loads all known items
    await store.dispatch(
      'claim/loadEligibleItems',
      {
        wallets: loggedInUser.value.Wallets,
        resolveMetadata: false
      },
      {
        root: true
      }
    );
  }
};

// Loads staking pools
const loadPools = async () => {
  if (isConnected.value) {
    //console.info("loading pools at address=", config.stakerAddress[config.forceNetwork]);
    await store.dispatch(
      'stake/loadPools',
      {},
      {
        root: true
      }
    );
    //console.info("Pools:", store.state.stake.pools);
  }
};

const stakerV2Enabled = computed(() => {
  return flag.stakerV2Enabled.value;
});

// Loads user's staked items.
const loadStaked = async () => {
  if (isConnected.value) {
    //console.info("loading staked items at address=", config.stakerAddress[config.forceNetwork]);
    await store.dispatch(
      'stake/loadStakedStatus',
      {
        legacy: !stakerV2Enabled.value,
        pools: store.state.stake.pools,
        // owned: store.state.mint.owned,
        owned: impostorNfts.value
      },
      {
        root: true
      }
    );
  }
  //console.info("Staked:", store.state.stake.staked, store.state.stake.dailyBlood);
};

// Computed property for wallet connection.
const isConnected = computed(() => {
  return store.state.ethers.canSign;
});

watch(currentAddress, (curAdd, oldAdd) => {
  if (curAdd == null) {
    // wallet has locked or disconnected altogether
    store.state.ethers.canSign = false;
    store.dispatch('login/initialize');
    l.unsetUser();
    flag.unsetUser();
  }

  // essentially waits until wallet is ready
  if (curAdd !== oldAdd) {
    l.setUser(curAdd);
    flag.setUser(curAdd);
    ensureWalletConnected(400000)
      .then(loadAll)
      .catch(err => {
        let do_nothing = err;
      });
  }
});

// this following function should NOT be required and
// we should be able to watch over isConnected
// TODO: either get the watch to work or move this into ethers.service
function ensureWalletConnected(timeout) {
  var start = Date.now();
  return new Promise(waitForWallet);

  // waitForWallet makes the decision whether the condition is met
  // or not met or the timeout has been exceeded which means
  // this promise will be rejected
  function waitForWallet(resolve, reject) {
    // check if wallet connect has ever been enabled in the past
    store.commit('checkWalletConnectStore');
    // if we have connected previously, dispatch automagic connection
    if (store.state?.walletconnect?.connected) {
      store.dispatch('ethers/connectWCProvider');
    }
    if (store.state?.ethers?.canSign) resolve(store.state.ethers.canSign);
    else if (timeout && Date.now() - start >= timeout)
      reject(new Error('timeout'));
    else setTimeout(waitForWallet.bind(this, resolve, reject), 30);
  }
}
</script>
<style scoped lang="scss">
.bg-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  .fade {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 300px;

    background-image: linear-gradient(0deg,
        var(--color-bg-main),
        55%,
        transparent);
  }

  .primary-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 934px;
    width: 100%;
    background-image: linear-gradient(0deg,
        var(--color-bg-main),
        15%,
        transparent),
      url('../../images/background.webp');
    background-attachment: scroll;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: contain;
    pointer-events: none;
    /* ensures background-size: contain stretches full width */
    min-height: 100vw;
  }
}

.container {
  width: 100%;
  height: 100%;
  min-height: 90vh;
  padding-top: 110px;
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 60px;

  .fade {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), #1e1226);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
  }

  .wrapper {
    max-width: 1280px;
    width: 100%;
    margin: 0px 40px;
    padding: 30px 50px;

    .header {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      flex-wrap: wrap;

      h1 {
        font-weight: 500;
        font-size: 40px;
        margin-bottom: 10px;
        margin-right: 14px;
        margin-bottom: 0px;
      }

      .count {
        background-color: rgba(33, 33, 33, 0.2);
        padding: 14px;
        font-size: 18px;
        font-weight: 500;
        border-radius: 26px;
        min-width: 38px;
        width: fit-content;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .line {
      width: 100%;
      height: 10px;
    }

    .tabs {
      padding-top: 18px;
      display: flex;
    }

    .nft-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 10px;
      padding-top: 22px;

      .nft {
        padding: 10px;
        border-radius: 28px;
        transition: all ease 200ms;

        &.revealed {
          cursor: pointer;
        }

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
          border-radius: 20px;
          margin-bottom: 12px;
        }

        p {
          text-align: center;
          font-size: 18px;
          font-weight: bold;
        }

        &:hover {
          background-color: rgba($color: #fff, $alpha: 0.1);
        }

        &:active {
          background-color: rgba($color: #fff, $alpha: 0.15);
        }

        .img-wrapper {
          position: relative;
          margin-bottom: 12px;

          &::after {
            display: block;
            content: '';
            /* 16:9 aspect ratio */
            padding-bottom: 100%;
          }

          .impostor-preview {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 20px;
          }
        }
      }

      .gigamart {
        height: fit-content;

        img {
          margin: 0;
        }
      }
    }
  }

  .connectWalletWrapper {
    width: 100%;
  }
}

.tab-count {
  background: rgba(33, 33, 33, 0.4);
  border-radius: 20px;
  font-size: 16px;
  padding: 3px 9px;
  margin-left: 10px;

  &.selected {
    background: #212121;
    color: white;
  }
}

@media (max-width: 640px) {
  .container .wrapper {
    padding: 30px;
    margin: 0px 20px;

    .nft-wrapper {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }

    .header {
      h1 {
        font-size: 6vw;
      }

      .count {
        font-size: 5vw;
      }
    }
  }
}

@media (max-width: 480px) {
  .container .wrapper .header h1 {
    font-size: 28px;
  }

  .container .wrapper .header .count {
    font-size: 24px;
  }
}
</style>
