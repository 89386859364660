<template>
  <div class="dutch-auction-stats">
    <h3 class="title">
      <DownArrow />
      <span>{{ props.title }}</span>
      <DownArrow />
    </h3>

    <div class="countdown">
      <div v-for="{ number, label } in timeBlocks" :key="label" class="time-block">
        <div class="time-block_num">
          {{ number.value }}
        </div>
        <div class="time-block_label">{{ label }}</div>
      </div>
    </div>

    <div class="progress-wrapper">
      <div class="price-block">
        <div class="price">{{ nextPrice }} ETH</div>
        <div class="price-label">{{ nextLabel }}</div>
      </div>
      <div class="progress-track">
        <div class="progress-bar"></div>
        <div class="progress-price"></div>
      </div>
      <div class="price-block">
        <div class="price">{{ currentPrice }} ETH</div>
        <div class="price-label">{{ currentLabel }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, toRef } from 'vue';
import useCountdown from '/src/utility/useCountdown'
import DownArrow from '/src/components/icons/DownArrow.vue'

let props = defineProps({
  currentPrice: { type: Number, default: 1.95 },
  currentLabel: { type: String, default: 'Current Price' },
  nextPrice: { type: Number, default: 1.8 },
  nextLabel: { type: String, default: 'Next Price' },
  title: { type: String, default: 'Next Price Decrease' },
  auctionInterval: { type: Number, default: 2 * 60 * 60 * 1000 },
  endDate: {
    type: Date, default: () => {
      let date = new Date()
      date.setTime(date.getTime() + .5 * 60 * 60 * 1000)
      return date
    }
  },
})

let { days, hours, minutes, seconds, timeLeft } = useCountdown({ endDate: toRef(props, 'endDate') })
let timeBlocks = computed(() => {
  let blocks = [
    { number: days, label: 'Days' },
    { number: hours, label: 'Hours' },
    { number: minutes, label: 'Mins' },
    { number: seconds, label: 'Secs' },
  ]
  let blocksHiddenIndex = -1
  for (let [i, { number }] of blocks.entries()) {
    if (number.value <= 0) {
      blocksHiddenIndex = i
    } else {
      break
    }
  }
  let displayBlocks = blocks.slice(blocksHiddenIndex + 1)
  return displayBlocks.length === 0 ? [
    { number: seconds, label: 'Secs' },
  ] : displayBlocks
})

let progressPercent = computed(() => props.auctionInterval + '%')
</script>

<style lang="scss" scoped>
@import "../../../assets/style/mixins.scss";

.dutch-auction-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: DM Sans;
  font-style: normal;

  @include mediaOnlyScreenMax(500px) {
    .title {
        margin-right: -24px;
        margin-left: -40px;
      }
      .progress-wrapper {
          font-size: .65rem;
        }
  }
}

.title {
  font-size: 2.42em;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 12px;
  }
}

.countdown {
  display: flex;
  text-transform: uppercase;

  > * + * {
    margin-left: 24px;
  }
}

.time-block {
  display: flex;
  align-items: center;
}
.time-block_num {
  font-family: DM Mono;
  font-size: 3.21em;
  margin-right: 8px;
  min-width: 2ch;
  text-align: center;
  display: flex;

  span {
    min-width: 1ch;
    text-align: right;
  }
  span + span {
    text-align: left;
  }
}
.time-block_label {
  font-size: 1.57em;
  opacity: .4;
}

.price-block {
  display: flex;
  flex-direction: column;
  align-items: center;

  text-transform: uppercase;
}
.price {
  font-size: 1.42em;
}
.price-label {
  font-size: 0.9em;
  opacity: .5;
}

.progress-wrapper {
  align-self: stretch;
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 16px;
  }
}
.progress-track {
  flex: 1;
  height: 31px;
  background-color: rgba(196, 196, 196, 0.2);
  border-radius: 7px;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.progress-bar {
  background-color: #800080;
  width: v-bind("progressPercent");
  border-radius: 0 7px 7px 0;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}
.progress-price {
  position: relative;
  margin-left: 8px;
}

.time-block {
  display: flex;
}
</style>
