<template>
  <SkewedDiv
    classSkewBox="faq-cell__polygon"
    :borderRadius="10"
    :skew="[ 0, 0, 10, -14 ]"
    @click="handleClick"
  >
    <article class="faq-cell" :class="{ active }">
      <div class="faq-cell__title">
        <h3>{{ props.title }}</h3>
        <img src="~@/images/open-icon.svg" />
      </div>
      <div v-if="active" class="faq-cell__content">
        <p
          v-for="(content, index) in subcontent"
          class="faq-cell__text"
          v-html="content"
          :key="index"
        />
      </div>
    </article>
  </SkewedDiv>
</template>

<script setup>
import { defineProps, ref } from 'vue';
import { SkewedDiv } from '@/components/common';
import { computed } from '@vue/reactivity';

const props = defineProps({
  title: { type: String, required: true },
  subcontent: { type: [String, Array], required: true }
});

const active = ref(false);

const handleClick = () => {
  active.value = !active.value;
};

const subcontent = computed(() =>
  Array.isArray(props.subcontent) ? props.subcontent : [props.subcontent]
);
</script>

<style lang="scss">
@import '../../../assets/style/mixins.scss';

.faq-cell {
  padding: 24px;
  min-height: 80px;

  &:hover {
    cursor: pointer;
  }

  &__title {
    @include flex(row, flex-start);

    .active & {
      padding-bottom: 20px;
    }

    h3 {
      @include DMSans(22px, 700);
      flex-grow: 1;
      margin-left: 12px;
    }

    img {
      align-self: flex-start;
      width: 32px;
      height: 32px;
      margin: 0 6px;
      transition: 0.1s;

      .active & {
        transform: rotate(45deg);
      }
    }
  }

  &__content {
    @include DMSans(18px, 400);
    padding: 0 24px 8px 12px;

    & > * + * {
      margin-top: 12px;
    }
  }

  &__polygon {
    background-color: var(--color-white);
    opacity: 0.2;

    .skewed-box:hover & {
      border: 3px solid var(--color-black);
    }
  }

  &__text {
    li {
      list-style-type: circle;
      margin-left: 1em;
    }
  }
}

@media only screen and (max-width: 1050px) {
  .faq-cell {
    min-height: 65px;

    &__title {
      h3 {
        @include DMSans(18px, 700);
      }

      img {
        width: 28px;
        height: 28px;
      }
    }

    &__content {
      @include DMSans(14px, 400);
    }
  }
}

@media (max-width: 640px) {
  .faq-cell {
    min-height: 65px;

    &__title {
      h3 {
        @include DMSans(16px, 700);
      }

      img {
        width: 24px;
        height: 24px;
      }
    }

    &__content {
      @include DMSans(12px, 400);
    }
  }
}
</style>
