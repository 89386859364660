<template>
  <svg width="15" height="23" viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.74854 8.14164C10.4407 8.71117 11.6188 9.34253 12.6578 10.0868C14.0445 8.4593 14.1515 6.352 14.0103 4.91113C12.8109 6.35299 11.0506 7.43399 8.74854 8.14164Z"
      fill="white"
    />
    <path
      d="M0.351056 23C1.55967 21.3368 3.13827 20.9498 5.91319 20.2689C6.98547 20.0059 8.20083 19.7077 9.66865 19.2764C13.092 18.2712 13.9675 16.3396 14.1849 15.2776C14.471 13.8788 13.9702 12.4098 12.9089 11.5351C10.5971 9.6299 8.32369 8.87885 4.93702 8.1597L4.21253 8.00593L4.94753 7.91497C9.07078 7.40469 11.9505 6.06885 13.5071 3.9445C14.6769 2.34827 14.753 0.720335 14.7167 0C13.8048 1.42581 11.9916 1.85841 9.12349 2.54267C7.9335 2.82645 6.58478 3.14827 5.03888 3.60227C1.61556 4.60757 0.740056 6.5391 0.522667 7.60107C0.236515 8.99973 0.737282 10.4689 1.79866 11.3436C4.11047 13.249 6.38384 13.9999 9.7705 14.719L10.4952 14.8728L9.76 14.9638C7.65389 15.2243 5.63635 15.6724 4.07877 16.2253C2.10919 16.9242 0.860541 17.77 0.366909 18.7388C-0.337375 20.1216 0.153682 22.2765 0.351056 23Z"
      fill="white"
    />
  </svg>
</template>
