'use strict';

// Import our module information.
import actions from './actions';
import mutations from './mutations';
import { BigNumber } from 'ethers'

// Prepare state.
const state = {
  staked: [],
  dailyBlood: 0,
  pools: [],
  balances: {
    tokenBalance: BigNumber.from(0),
    hasBlood: false,
    claimable: BigNumber.from(0),
  },
  claims: {
    lastCollected: 0.0,
    totalCollected: 0.0
  },
  // data used only for staker-v1
  legacy: {
    staked: [],
    dailyBlood: 0,
    balances: {
      tokenBalance: BigNumber.from(0),
      hasBlood: false,
      claimable: BigNumber.from(0),
    },
  },
  liveTime: 1649962800
};

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
