<template>
  <div class="wrapper">
    <div class="questions-area">
      <div class="questions-area__background" />
      <div class="questions-area__content">
        <img
          class="questions-area__impostor"
          src="~@/images/questions-impostor.webp"
        />
        <div class="questions-area__cards">
          <QuestionCard
            class="questions-area__card questions-area__card-how-to-play"
            :question="HOME_QUESTION_HOW_TO_PLAY"
          />
          <QuestionCard
            class="questions-area__card questions-area__card-faqs"
            :question="HOME_QUESTION_FAQS"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { QuestionCard } from '.';
import { HOME_QUESTION_HOW_TO_PLAY, HOME_QUESTION_FAQS } from '@/constants';

const store = useStore();
</script>

<style scoped lang="scss">
@import '../../../assets/style/mixins.scss';

.questions-area {
  position: relative;
  width: 100%;
  @include flex(row, center, flex-start);
  padding: 64px 36px 64px 24px;
  margin-bottom: 48px;

  &__background {
    position: absolute;
    width: 100%;
    height: calc(100% + 32px);
    max-height: 718px;
    top: -32px;
    left: 0;
    background: url("~@/images/questions-bg.png") no-repeat bottom/cover;
  }

  &__content {
    max-width: 1220px;
    width: 100%;
    @include flex(row, space-between, flex-start);
    height: fit-content;
  }

  &__impostor {
    @include flex();
    width: 462px;
    z-index: 2;
  }

  &__cards {
    position: relative;
    height: 595px;
    max-width: 610px;
    width: 100%;
  }

  &__card {
    position: absolute;

    &-how-to-play {
      width: 414px;
      top: 0;
      left: 0;
    }

    &-faqs {
      width: 328px;
      bottom: 0;
      right: 0;
    }
  }
}

@include mediaOnlyScreenMax(1280px) {
  .questions-area {

    &__content {
      max-width: 1120px;
    }

    &__impostor {
      width: 362px;
    }
  }
}

@include mediaOnlyScreenMax(1050px) {
  .questions-area {

    padding: 32px 36px 0 24px;

    &__content {
      @include flex(column, flex-start, center);
    }

    &__impostor {
      width: 462px;
    }

    &__cards {
      @include flex(column);
      row-gap: 24px;
      height: auto;
    }

    &__card {
      position: relative;
      width: 100%;
    }
  }
}

@include mediaOnlyScreenMax(640px) {
  .questions-area {

    &__impostor {
      width: 362px;
    }
  }
}
</style>
