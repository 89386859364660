<script setup>
import { ref, computed } from 'vue';
import { Skeletor } from 'vue-skeletor';
import {
  IMPOSTOR_TOKEN_TYPE,
  VOXEL_TOKEN_TYPE,
  TOKEN_CONFIG
} from '@/constants';
import ClaimRow from './ClaimRow.vue';
import ClaimModal from './ClaimModal.vue';
import voxImg from '@/assets/vox_1.png';
import {
  useRedeem,
  useClaimTokenInfo,
  useClaimDetails,
  useApproveRedeemer
} from '../composables';
const props = defineProps({});
let showClaimModal = ref(false);
let hasEligibleItems = ref(true);
let requirements = [{ text: TOKEN_CONFIG[IMPOSTOR_TOKEN_TYPE].DISPLAY_NAME }];
let { data: tokenInfo, isLoading: tokenLoading } = useClaimTokenInfo();
let { data: claimDetails, isLoading: claimLoading } = useClaimDetails();
let isLoading = computed(() => tokenLoading.value || claimLoading.value);
let voxelBloodCost = computed(() =>
  parseFloat(
    claimDetails.value?.[claimDetails.value?.voxelCollectionAddress]?.price ?? 0
  ).toFixed(2)
);
// let { isLoading, mutate: redeem } = useRedeem();
let redeem = useRedeem();
let approve = useApproveRedeemer();
let isSubmitting = computed(
  () => approve.isLoading.value || redeem.isLoading.value
);
let handleCheckoutApprove = () => {
  approve.mutate();
};
let handleCheckoutSubmit = claims => {
  redeem.mutate(
    { round: 4, claims },
    {
      onSuccess: () => {
        showClaimModal.value = false;
      }
    }
  );
  // redeem({ round: 1, claims });
};
</script>
<template>
  <Skeletor
    v-if="isLoading"
    :style="{
      width: '100%',
      height: '160px',
      background: 'rgba(255,255,255,.18)',
      borderRadius: '12px'
    }"
  />
  <ClaimRow
    v-else
    title="VOXEL"
    :class="s.claimRow"
    :enable-claim="hasEligibleItems"
    :blood-cost="voxelBloodCost"
    :requirements="requirements"
    :img-src="voxImg"
    :class-img="s.voxImg"
    learn-more-href="https://docs.impostors.gg/metapaper/metaverse-economy/voxel-ecosystem"
    @claim="showClaimModal = true"
  />
  <ClaimModal
    v-if="showClaimModal"
    title="Claim your Voxel"
    :is-submitting="isSubmitting"
    :is-blood-approved="true"
    :blood-per-item="voxelBloodCost"
    :blood-balance="tokenInfo?.bloodBalance"
    :redeem-round="4"
    :redeem-token="VOXEL_TOKEN_TYPE"
    :claim-slots="[IMPOSTOR_TOKEN_TYPE]"
    claim-type-text="Voxel"
    @exit="showClaimModal = null"
    @approve="handleCheckoutApprove"
    @submit="handleCheckoutSubmit"
  />
</template>
<style module="s" lang="scss">
.claimRow {
  .voxImg {
    height: 200px;
    margin: -24px 0 0;
  }
}
</style>