<template>
  <SkewedDiv
    class="stakeInfoCard"
    :class="{'selected' : isSelected, 'disabled': info.isExpired}"
    :skew="[0, 0, 8, -8]"
    :background="isSelected ? '#0000b8' : 'black'"
    :opacity="isSelected ? 1 : .2"
    :borderRadius="8"
    :borderWidth="isSelected ? '3px' : undefined"
    :borderColor="isSelected ? 'yellow' : undefined"
    clipContent
  >
    <div class="info-content">
      <p class="title">
        <span>{{ info?.duration }}</span>
        <span class="boost">{{ info.boost }}</span>
      </p>
      <p class="info">{{ info.blood }} $BLOOD/day</p>
      <p class="subtext">{{ info.lockPeriod }}</p>
    </div>
    <SkewedDiv
      class="countdown"
      :background="isSelected ? '' : '#172CA5'"
      :skew="[4, -4, 8, -8]"
      :borderRadius="8"
      v-if="!isFlexible"
    >
      <div class="countdown-content">
        <template v-if="info.isExpired" class="countdown">
          <span>Pool has closed</span>
        </template>
        <template v-else>
          <span>Entry in this pool ends in</span><br/>
          <span class="timer">{{ days }}d {{ hours }}h {{ minutes }}m {{ seconds }}s</span>
        </template>
      </div>
    </SkewedDiv>
  </SkewedDiv>
</template>

<script setup>
import { computed, toRef } from 'vue';
import useCountdown from '/src/utility/useCountdown'
import SkewedDiv from '@/components/common/SkewedDiv.vue';

let props = defineProps({
  isSelected: { type: Boolean },
  info: { type: Object },
});

let { days, hours, minutes, seconds } = useCountdown({ endDate: toRef(props.info, 'deadlineDate') })

const isFlexible = computed(() => {
  return !days.value && !hours.value && !minutes.value && !seconds.value && !props.info.isExpired;
});

</script>
<style scoped lang="scss">
.stakeInfoCard {
  /* padding: 16px 24px; */
  /* background: rgba(0, 0, 0, 0.2); */
  /* border-radius: 10px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all ease 200ms;
  cursor: pointer;
  border: 2px solid transparent;

  &::v-deep {
    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  .title {
    &:first-letter {
      text-transform: uppercase;
    }
    font-size: 24px;
    line-height: 1.5em;
    .boost {
      margin-left: 3px;
      font-size: 18px;
      color: #ffa500;
    }
  }
  .info-content {
    flex: 1;
    padding: 18px 24px;
  }
  .info {
    font-size: 16px;
    color: #ffa500;
  }
  .subtext {
    &:first-letter {
      text-transform: uppercase;
    }

    font-size: 14px;
    line-height: 1.5em;
    margin-top: 6px;
  }
  .countdown {
    /* background-color: palevioletred; */
    /* border-radius: 8px; */
    /* font-size: 14px; */
    /* margin: 0 6px; */
    /* height: 57px; */
    padding: 12px 24px;
    .countdown-content {
      min-height: 32px;
    }
    .timer {
      margin-left: 3px;
      font-size: 14px;
      color: #ffa500;
    }
  }
}

.selected {
  /* background: #0000b8; */
  /* border: 2px solid var(--color-button-yellow); */

  &:hover {
    opacity: 1;
  }
}
.disabled {
  opacity: .6;
  pointer-events: none;
}
</style>
