<template>
  <SkewedModal
    :borderRadius="16"
    :skew="[[0, -10], 0, [-6, 0], [8, 0]]"
    background="var(--color-bg-modal)"
    @exit="exit"
  >
    <template #title>
      <span>Select to {{ actionTitle }}</span>
    </template>

    <template #content>
      <div class="content-wrapper">
        <!-- unstaked container -->
        <div v-if="isUnstakeModal" class="impostors-container">
          <ImpostorCard
            :impostor="impostor"
            v-for="impostor in impostorsOrdered.filter(impostor => impostor.isStaked)"
            :key="impostor.tokenId"
            :isSelected="selectedImpostors.has(impostor.tokenId)"
            :isDisabled="impostorUnstakingIsDisabled(impostor)"
            @click="handleSelectImpostorUnstake(impostor)"
            showHover
          />
        </div>
        <!-- staked container -->
        <div v-else class="impostors-container">
          <ImpostorCard
            :impostor="impostor"
            v-for="impostor in unstakedImpostors"
            :key="impostor.tokenId"
            :isSelected="selectedImpostors.has(impostor.tokenId)"
            :isDisabled="impostor.isStaked"
            @click="!impostor.isStaked ? selectImpostor(impostor.tokenId) : ''"
            showTitle
            showHover
          />
          <ImpostorCard
            :impostor="impostor"
            v-for="impostor in stakedImpostors"
            :key="impostor.tokenId"
            :isSelected="false"
            :isDisabled="true"
            showHover
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div v-if="!isUnstakeModal" class="cards-container">
        <p>How long do you want to stake for?</p>
        <div class="cards-wrapper">
          <StakeInfoCard
            v-for="(info, index) in pools"
            :key="index"
            :info="info"
            :isSelected="index === selectedStakeTypeIndex"
            @click="selectedStakeTypeIndex = index"
          />
        </div>
      </div>
      <p class="footer-text">
        After the time lock is completed, your NFT will be unlocked and can be unstaked or restaked. Upon unlocking, your NFT will still be staked earning the minimum {{ unlockedPool.blood }} $BLOOD/day until you decide to unstake or restake your NFT.
      </p>
      <div class="button-wrapper">
        <SkewedButton
          v-if="selectable.size > 0"
          :borderRadius="6"
          :skew="[0, 0, [-5, -3], 0]"
          background="#000000"
          class="button"
          @click="selectAllEligible"
        >
          <span class="secondary">{{ selectText }}</span>
        </SkewedButton>
        <SkewedButton
          :borderRadius="6"
          :skew="[0, 0, [-5, -3], 0]"
          background="var(--color-button-yellow)"
          class="button"
          :disabled="checkEligibility || isSubmitting"
          @click="stake"
        >
          <div>
            <span>{{ actionTitle }} </span>
            <span class="select-count" v-if="selectedImpostors.size > 0">{{
              selectedImpostors.size
            }}</span>
          </div>
        </SkewedButton>
      </div>
    </template>
  </SkewedModal>
</template>
<script setup>
import SkewedModal from '@/components/common/SkewedModal.vue';
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import SkewedButton from '@/components/common/SkewedButton.vue';
import ImpostorCard from './ImpostorCard.vue';
import StakeInfoCard from './StakeInfoCard.vue';

import { computed, ref, watch } from 'vue';

const props = defineProps({
  impostors: { type: Object },
  isUnstakeModal: { type: Boolean },
  isSubmitting: { type: Boolean },
  pools: { type: Object }
});
const emit = defineEmits(['exit', 'stake', 'unstake']);

const exit = () => {
  emit('exit');
};

const selectedImpostors = ref(new Set());
const selectedStakeTypeIndex = ref(0);
const unstakePool = computed(() =>
  props.impostors
    .find(({ tokenId }) => [...selectedImpostors.value][0] === tokenId)
    ?.stakedPool?.toNumber() ?? null
);

const impostorsOrdered = computed(() => [...props.impostors].sort((a,b) => a.tokenId - b.tokenId))

const handleSelectImpostorUnstake = impostor => {
  if (impostor.isUnstakable && !impostorUnstakingIsDisabled(impostor)) {
    selectImpostor(impostor.tokenId);
    if (selectedImpostors.value.size > 0) {
      unstakePool.value = impostor.stakedPool.toNumber();
    } else {
      unstakePool.value = null;
    }
  }
};

const stakedImpostors = computed(() => {
  return impostorsOrdered.value.filter(impostor => impostor.isStaked);
});

const unstakedImpostors = computed(() => {
  return impostorsOrdered.value.filter(impostor => !impostor.isStaked);
});

const impostorUnstakingIsDisabled = impostor => {
  let impostorInSelectedPool =
    unstakePool.value !== null &&
    impostor.stakedPool.toNumber() !== unstakePool.value;
  return !impostor.isUnstakable || impostorInSelectedPool;
};

const selectImpostor = id => {
  if (selectedImpostors.value.has(id)) {
    selectedImpostors.value.delete(id);
  } else {
    selectedImpostors.value.add(id);
  }
};

let selectable = computed(() => {
  let items = new Set();
  if (props.isUnstakeModal) {
    for (let i = 0; i < stakedImpostors.value.length; i++) {
      if (
        stakedImpostors.value[i].isUnstakable &&
        stakedImpostors.value[i].stakedPool.toNumber() === unstakePool.value
      )
        items.add(stakedImpostors.value[i].tokenId);
    }
  } else {
    for (let i = 0; i < unstakedImpostors.value.length; i++) {
      items.add(unstakedImpostors.value[i].tokenId);
    }
  }
  return items;
});

watch(selectable, () => {
  // clear out selection when impostors update after staking
  for (let s of selectedImpostors.value) {
    if (!selectable.value.has(s)) {
      selectedImpostors.value.delete(s);
    }
  }
});

const allSelected = computed(() => {
  if (selectedImpostors.value.size !== selectable.value.size) return false;
  for (let i of selectable.value)
    if (!selectedImpostors.value.has(i)) return false;
  return true;
});

const selectAllEligible = () => {
  if (allSelected.value) {
    selectedImpostors.value.clear();
  } else {
    for (let item of selectable.value) {
      selectedImpostors.value.add(item);
    }
  }
};

const checkEligibility = computed(() => {
  if (props.isUnstakeModal) {
    const hasIneligible = props.impostors.filter(
      impostor =>
        !impostor.isUnstakable && selectedImpostors.value.has(impostor.tokenId)
    );
    // reminder: function disables the unstaking button
    return !(selectedImpostors.value.size > 0 && hasIneligible.length === 0);
  } else {
    return selectedImpostors.value.size === 0;
  }
});

const selectText = computed(() => {
  return allSelected.value && selectable.value.size > 0
    ? 'Deselect All'
    : 'Select All Eligible';
});

let stake = () => {
  if (props.isUnstakeModal) {
    emit('unstake', {
      nfts: Array.from(selectedImpostors.value),
      poolId: unstakePool.value,
      impostors: props.impostors.filter(({ tokenId }) =>
        selectedImpostors.value.has(tokenId)
      )
    });
  } else {
    emit('stake', {
      nfts: Array.from(selectedImpostors.value),
      poolId: props.pools[selectedStakeTypeIndex.value].id
    });
  }
};

// pool which has no lock duration (aka "baseline" pool)
const unlockedPool = computed(() => {
  return props.pools.filter(pool => pool.lockDuration.toString() == '0')[0];
});

const actionTitle = computed(() => {
  return props.isUnstakeModal ? 'unstake' : 'stake';
});
</script>
<style scoped lang="scss">
.content-wrapper {
  width: 100%;
  .impostors-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding: 4px;
  }
}

.footer-text {
  margin-bottom: 18px;
  padding-top: 20px;
  font-size: 12px;
}

.cards-container {
  padding-top: 18px;
  p {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .cards-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}
.button-wrapper {
  display: flex;
  gap: 16px;
  justify-content: flex-end;

  .button {
    font-weight: 500;
    font-size: 18px;
    max-width: 200px;
    width: 100%;

    .secondary {
      color: white;
    }

    span {
      text-transform: capitalize;
    }
  }
}

.select-count {
  &:before {
    content: '(';
  }
  &:after {
    content: ')';
  }

  margin-left: 0.3em;
}

@media (max-width: 1060px) {
  .cards-container .cards-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .button-wrapper {
    flex-direction: column;
    .button {
      max-width: 100%;
    }
  }
}

@media (max-width: 470px) {
  .cards-container .cards-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
