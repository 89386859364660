<template>
  <div class="item-details">
    <!-- Preload media -->
    <div class="preload">
      <template v-for="(media, index) in mediaItems" :key="index">
        <link
          v-if="media.type === 'image'"
          rel="preload"
          as="image"
          :href="media.src"
        />
        <video v-if="media.type === 'video'" preload="auto" :src="media.src" />
      </template>
    </div>

    <div class="header">
      <a href="#" @click.prevent="goBack">
        <BackArrow />
      </a>
      <h1>{{ item.title.toLowerCase() }} #{{ item.tokenId }}</h1>
      <div class="header-actions">
        <SkewedButton
          :borderRadius="4"
          :skew="[-2, 0, 0, 0]"
          background="white"
          :opacity="0.3"
          class="header-button"
          as="a"
          :href="gigamartLink"
          target="_blank"
        >
          <GigamartIcon />
        </SkewedButton>
      </div>
    </div>

    <SkewedDiv
      :borderRadius="2"
      background="#AAB8FF"
      :skew="[[0, -6], [0, -3], 10, 0]"
    >
      <div class="line"></div>
    </SkewedDiv>

    <MediaModal
      v-if="mediaModalIndex !== null"
      :mediaItems="mediaItems"
      :initialIndex="mediaModalIndex"
      :name="name"
      @exit="closeModal"
      @next="mediaModalIndex++"
      @previous="mediaModalIndex--"
    />

    <div class="details-wrapper">
      <div class="section-preview">
        <div class="preview-main">
          <a
            class="nft"
            :token-type="type"
            href="#"
            @click.prevent="mediaModalIndex = 0"
          >
            <ImpostorPreviewCard
              v-if="type === 'impostor'"
              class="image"
              :tokenId="item.tokenId"
            />
            <div class="image" v-else>
              <iframe
                :src="frameSource"
                class="image"
                width="100%"
                height="480px"
                frameborder="0"
              />
            </div>
          </a>
          <SkewedButton
            v-if="type === 'voxel'"
            class="button button_check-elig"
            :href="gltfSource"
            as="a"
            :border-radius="6"
            :skew="[0, 0, [-5, -3], 0]"
            background="#5971C5"
            border-color="var(--color-button-yellow)"
            border-width="2px"
          >
            Download GLTF
          </SkewedButton>
        </div>
        <div v-if="type === 'impostor'" class="preview-options">
          <a
            class="nft"
            href="#"
            :token-type="type"
            @click.prevent="mediaModalIndex = 0"
          >
            <ImpostorPreviewCard class="image" :tokenId="item.tokenId" />
          </a>
          <a
            class="nft video"
            :token-type="type"
            href="#"
            @click.prevent="mediaModalIndex = 1"
          >
            <ImpostorPreviewCard class="image" :tokenId="item.tokenId" />
          </a>
          <a
            class="nft video"
            :token-type="type"
            href="#"
            @click.prevent="mediaModalIndex = 2"
          >
            <ImpostorPreviewCard class="image" :tokenId="item.tokenId" />
          </a>
        </div>
      </div>
      <div class="section-info">
        <h2>name</h2>
        <SkewedDiv
          background="black"
          opacity="0.12"
          :borderRadius="4"
          :skew="[0, 0, 3, -3]"
        >
          <div class="info-block">
            <div class="info-title info-name">{{ name }}</div>
            <div class="info-item edit-name">edit</div>
          </div>
        </SkewedDiv>

        <SkewedDiv
          background="black"
          opacity="0.12"
          :borderRadius="4"
          :skew="[0, 0, 3, -3]"
        >
          <div class="info-block info-block_sub">
            <div class="info-title">NFT OWNER</div>
            <div class="info-item">{{ ownerName }}</div>
          </div>
        </SkewedDiv>

        <template v-if="description">
          <h2>description</h2>

          <SkewedDiv
            background="black"
            opacity="0.12"
            :borderRadius="4"
            :skew="[0, 0, 3, -3]"
          >
            <div class="info-block">
              <p>{{ description }}</p>
            </div>
          </SkewedDiv>
        </template>

        <h2>meta data</h2>

        <SkewedDiv
          :background="stat.bg"
          :opacity="1"
          :borderRadius="4"
          :skew="[0, 0, 3, -3]"
          v-for="(stat, index) of stats"
          :key="index"
        >
          <div class="info-block">
            <div class="info-title">
              <div class="info-label">{{ stat.trait_type }}</div>
              <div class="info-value">{{ stat.value }}</div>
            </div>
            <div class="info-item info-quantity">{{ stat.quantity }}</div>
            <div class="info-item info-percent">{{ stat.percentage }}</div>
            <div class="info-stat">
              <CircularProgress
                class="stat-progress"
                :progress="parseFloat(stat.percentage)"
                :trackColor="stat.statColor"
              />
            </div>
          </div>
        </SkewedDiv>
      </div>
      <div class="section-downloads" v-if="false">
        <h2>owner downloads</h2>

        <SkewedDiv
          background="black"
          opacity="0.12"
          :borderRadius="4"
          :skew="[0, 0, 3, -3]"
          class="downloads"
        >
          <div class="downloads-content">
            <div
              class="download-item"
              v-for="(download, index) of downloads"
              :key="index"
            >
              <p class="download-label">{{ download.name }}</p>
              <div class="download-action">{{ download.fileName }}</div>
              <DownloadIcon />
            </div>
          </div>
        </SkewedDiv>
      </div>
    </div>
  </div>
</template>
<script setup>
// Imports.
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import BackArrow from '@/components/icons/BackArrow.vue';
import DownloadIcon from '@/components/icons/DownloadIcon.vue';

import SkewedDiv from '@/components/common/SkewedDiv.vue';
import SkewedButton from '@/components/common/SkewedButton.vue';
import CircularProgress from '@/components/ui/CircularProgress.vue';
import MediaModal from './MediaModal.vue';
import Stats from '@/constants/stats.json';
import { IMPOSTORS_IMAGE_THUMB_URL } from '@/constants';
import { VOXEL_3D_VIEWER, VOXEL_GLTF_REPO } from '@/constants';
import GigamartIcon from '../../../components/icons/GigamartIcon.vue';
import ImpostorPreviewCard from '../../../components/common/ImpostorPreviewCard.vue';

let props = defineProps({
  item: { type: Object, default: () => ({}) },
  type: { type: String, default: () => '' }
});
let emit = defineEmits(['goBack']);

let goBack = () => {
  emit('goBack');
};

const store = useStore();
let mediaModalIndex = ref(null);

let closeModal = () => {
  mediaModalIndex.value = null;
};

let nft = computed(() => props.item);
let type = computed(() => props.type);
const frameSource = `${VOXEL_3D_VIEWER}/viewer?id=${props.item.tokenId}`;
// const gltfSource = `${VOXEL_GLTF_REPO}/${props.item.tokenId}.gltf`
let gltfSource = computed(
  () => `${VOXEL_GLTF_REPO}/${props.item.tokenId}.gltf`
);

onMounted(() => {
  store.dispatch('claim/loadItemMetadata', {
    collectionAddress: props.item.collectionAddress,
    tokenId: props.item.tokenId
  });
});

let ownerName = computed(() => {
  let owner = props.item.owner;
  let displayed =
    owner.length > 22
      ? owner.substring(0, 6) + '...' + owner.substring(owner.length - 4)
      : owner;
  return displayed;
});
let description = computed(() => metadata.value.description);
let name = computed(() => `${props.item.title} #${props.item.tokenId}`);

let metadata = computed(() => nft.value?.metadata ?? {});

// let mediaItems = computed(() => ([
//   { src: metadata.value.image, type: 'image', name: `impostor-${props.item.tokenId}` },
//   { src: metadata.value.animation_url, type: 'video', name: `impostor-${props.item.tokenId}-emote` },
//   { src: metadata.value.animation_url_360, type: 'video', name: `impostor-${props.item.tokenId}-360` }
// ]))
let mediaItems = computed(() => {
  if (props.type == 'impostor') {
    return [
      {
        src: metadata.value.image,
        type: 'image',
        name: `impostor-${props.item.tokenId}`
      },
      {
        src: metadata.value.animation_url,
        type: 'video',
        name: `impostor-${props.item.tokenId}-emote`
      },
      {
        src: metadata.value.animation_url_360,
        type: 'video',
        name: `impostor-${props.item.tokenId}-360`
      }
    ];
  } else {
    return [
      {
        src: metadata.value.image,
        type: 'image',
        name: `voxel-${props.item.tokenId}`
      }
    ];
  }
});

let rarityColors = [
  { type: 'common', color: '#0C980F', statColor: '#046306', range: [15, 100] },
  { type: 'uncommon', color: '#726F6F', statColor: '#4D4C4C', range: [4, 15] },
  { type: 'rare', color: '#2C6BDD', statColor: '#4830A0', range: [1.5, 4] },
  { type: 'epic', color: '#8326C4', statColor: '#601695', range: [0.5, 1.5] },
  {
    type: 'legendary',
    color: '#BD9721 ',
    statColor: '#957000',
    range: [-1, 0.5]
  }
];

let getTraitRarity = ({ trait_type, value }) => {
  let rarity = {
    quantity: '?',
    percentage: '?%',
    ...rarityColors[0],
    bg: rarityColors[0].color
  };
  let normalize = (str = '') => str.toLowerCase().replaceAll(/\s/gi, '');
  let checkKey = search => obj =>
    Object.keys(obj).map(normalize).includes(normalize(search));
  let getValue = (obj = {}) => Object.values(obj)[0];
  // let category = Stats.traits.find(tCategory => tCategory.hasOwnProperty(trait_type))
  let category = getValue(Stats.traits.find(checkKey(trait_type)));
  if (!category) return rarity;
  // let traitRarity = category[trait_type].find(tRarity => tRarity.hasOwnProperty(value))
  let traitRarity = getValue(category.find(checkKey(value)));
  if (!traitRarity) return rarity;
  let percent = parseFloat(traitRarity.percentage);
  let rarityColor =
    rarityColors.find(({ range: [lt, gt] }) => percent > lt && percent <= gt) ||
    rarityColors[0];
  return {
    ...traitRarity,
    ...rarityColor,
    bg: rarityColor.color
  };
};

let stats = computed(() =>
  // adds {quantity, percentage}
  metadata.value?.attributes?.map(m => ({ ...m, ...getTraitRarity(m) }))
);

let downloads = computed(() => [
  { name: '3D image file', fileName: 'impostor1605.vox' },
  { name: 'Full Body png file', fileName: 'impostor1605.png' },
  { name: 'Rigged 3D Model', fileName: 'impostor1605.vox' },
  { name: 'Portrait png file', fileName: 'impostor1605.png' }
]);

let gigamartLink = computed(
  () =>
    `https://gigamart.com/collections/${props.item.collectionAddress}/${props.item.tokenId}`
);

onMounted(() => {
  window.scroll(0, 0);
});
</script>
<style scoped lang="scss">
.item-details {
  .fade {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), #1e1226);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
  }
  .button {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
  }
  .button_check-elig {
    color: #fff;
    margin-bottom: 6px;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    flex-wrap: wrap;
    h1 {
      font-weight: 500;
      font-size: 40px;
      margin-bottom: 10px;
      margin-right: 14px;
      margin-bottom: 0px;
      margin-left: 16px;
      color: #f0f907;
      text-transform: lowercase;
      &:first-line {
        text-transform: capitalize;
      }
    }

    .count {
      background-color: rgba(33, 33, 33, 0.2);
      padding: 14px;
      font-size: 18px;
      font-weight: 500;
      border-radius: 26px;
      min-width: 38px;
      width: fit-content;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .header-actions {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .header-button {
    padding: 8px;
    svg {
      width: 26px;
    }
  }

  .section-preview {
  }

  .line {
    width: 100%;
    height: 10px;
    margin-bottom: 36px;
  }

  .details-wrapper {
    display: grid;
    grid-template:
      'preview info' auto
      'download info' 1fr
      / 1fr 1fr;
    column-gap: 36px;
    row-gap: 28px;

    @media (max-width: 1060px) {
      display: block;
      grid-template:
        'preview' 1fr
        'info' 1fr
        'download' 1fr
        / 1fr 1fr;
    }
  }

  .downloads {
    padding: 18px 29px;

    .downloads-content > * + * {
      margin-top: 22px;
    }

    .download-item {
      display: flex;
    }

    .download-label {
      flex: 1;
      font-size: 19px;
    }
    .download-action {
      font-size: 17px;
      color: #f0f907;
    }
    .download-action {
      margin-right: 8px;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 15px;
  }

  .section-preview {
    grid-area: preview;

    img {
      min-width: 170px;
    }

    img:hover {
      box-shadow: 0 0 0 4px #ffffff54;
    }

    .video {
      --button-size: 50%;
      position: relative;
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
      }
      &:before {
        width: var(--button-size);
        height: var(--button-size);
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.4);
      }
      &:after {
        width: 35%;
        height: 35%;
        transform: translate(-45%, -50%);
        background: no-repeat center/60% url('~@/assets/play-button.svg');
      }
    }
    .selected {
      box-shadow: 0 0 0 4px #ffffff54;
    }

    .preview-options {
      display: flex;
      margin-top: 22px;

      & > * + * {
        margin-left: 22px;
      }
    }
  }

  .section-info {
    grid-area: info;
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 13px;
    }
  }

  .info-block {
    display: flex;
    align-items: flex-end;
    padding: 16px 32px;

    &.info-block_sub {
      padding-top: 8px;
      padding-bottom: 8px;

      .info-title {
        font-size: 15px;
      }
    }

    .info-title {
      font-size: 22px;
      flex: 1;
    }
    .info-label {
      font-size: 12px;
      opacity: 0.4;
      text-transform: uppercase;
    }
    .info-value {
      font-size: 19px;
    }
    .info-name {
      text-transform: lowercase;
      &:first-line {
        text-transform: capitalize;
      }
    }
    .info-item {
      margin-left: 35px;
      font-size: 19px;
    }
    .info-stat {
      margin-left: 16px;
    }
    .info-quantity {
      width: 4em;
      text-align: right;
    }
    .info-percent {
      width: 3em;
      text-align: right;
    }
    .edit-name {
      display: none;
    }
  }

  .image::v-deep {
    border-radius: 20px;
    img {
      width: 100%;
      border-radius: 20px;
    }
  }

  .stat-progress {
    width: 30px;
  }

  @media (max-width: 640px) {
    .info-block {
      padding: 16px 18px;
      .info-title {
        font-size: 18px;
      }
      .info-label {
        font-size: 12px;
        opacity: 0.4;
        text-transform: uppercase;
      }
      .info-value {
        font-size: 16px;
      }
      .info-item {
        margin-left: 24px;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 640px) {
  .container .wrapper {
    padding: 30px;
    margin: 0px 20px;

    .nft-wrapper {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }

    .header {
      h1 {
        font-size: 6vw;
      }

      .count {
        font-size: 5vw;
      }
    }
  }
}

@media (max-width: 480px) {
  .container .wrapper .header h1 {
    font-size: 28px;
  }

  .container .wrapper .header .count {
    font-size: 24px;
  }
}
.preload {
  display: none;
}
</style>
