'use strict';

export const find2x2MatrixDeterminant = A => {
  return A[0][0] * A[1][1] - A[0][1] * A[1][0];
};

const find2x2MatrixMinors = A => {
  let minorA = [];
  A.forEach((row, rowId) => {
    minorA[rowId] = [];
    row.forEach((value, colId) => {
      minorA[rowId][colId] = A.filter((bRow, bRowId) => bRowId !== rowId).map(
        bRow => bRow.filter((value, bColId) => bColId !== colId)
      )[0][0];
    });
  });
  return minorA;
};

const find2x2MatrixCoFactors = A => {
  let coFactorA = find2x2MatrixMinors(A).map((row, rowId) => {
    return row.map((value, colId) => value * Math.pow(-1, rowId + colId));
  });
  return coFactorA;
};

export const inverse2x2Matrix = A => {
  return transposeMatrix(find2x2MatrixCoFactors(A)).map(row => {
    return row.map(value => value / find2x2MatrixDeterminant(A));
  });
};

export const find3x3MatrixDeterminant = A => {
  return (
    A[0][0] * A[1][1] * A[2][2] +
    A[0][1] * A[1][2] * A[2][0] +
    A[0][2] * A[1][0] * A[2][1] -
    A[0][0] * A[1][2] * A[2][1] -
    A[0][1] * A[1][0] * A[2][2] -
    A[0][2] * A[1][1] * A[2][0]
  );
};

const find3x3MatrixMinors = A => {
  let minorA = [];
  A.forEach((row, rowId) => {
    minorA[rowId] = [];
    row.forEach((value, colId) => {
      minorA[rowId][colId] = find2x2MatrixDeterminant(
        A.filter((bRow, bRowId) => bRowId !== rowId).map(bRow =>
          bRow.filter((value, bColId) => bColId !== colId)
        )
      );
    });
  });
  return minorA;
};

const find3x3MatrixCoFactors = A => {
  let coFactorA = find3x3MatrixMinors(A).map((row, rowId) => {
    return row.map((value, colId) => value * Math.pow(-1, rowId + colId));
  });
  return coFactorA;
};

const transposeMatrix = A => {
  let AT = [];
  A.forEach((row, rowId) => {
    row.forEach((value, columnId) => {
      if (!AT[columnId]) AT[columnId] = [];
      AT[columnId][rowId] = value;
    });
  });
  return AT;
};

export const inverse3x3Matrix = A => {
  return transposeMatrix(find3x3MatrixCoFactors(A)).map(row => {
    return row.map(value => value / find3x3MatrixDeterminant(A));
  });
};

export const multiplyMatrix = (A, B) => {
  const [rowsA, colsA] = [A.length, A[0].length];
  const [rowsB, colsB] = [B.length, B[0].length];
  let C = [];
  if (colsA != rowsB) return false;
  for (let i = 0; i < rowsA; i++) C[i] = [];
  for (let k = 0; k < colsB; k++) {
    for (let i = 0; i < rowsA; i++) {
      let t = 0;
      for (let j = 0; j < rowsB; j++) {
        t += A[i][j] * B[j][k];
      };
      C[i][k] = t;
    };
  };
  return C;
};
