<script>
// export default {inheritAttrs: false}
</script>

<script setup>
import { ref, computed } from 'vue';
import VLazyImage from 'v-lazy-image';
import {
  IMPOSTORS_IMAGE_THUMB_URL,
  UFO_IMAGE_THUMB_URL,
  PET_IMAGE_THUMB_URL,
  BOX_IMAGE_THUMB_URL,
  CHEST_IMAGE_THUMB_URL,
  PASS_IMAGE_THUMB_URL,
  VOXEL_IMAGE_THUMB_URL,
  IMPOSTOR_TOKEN_TYPE,
  UFO_TOKEN_TYPE,
  PET_TOKEN_TYPE,
  BOX_TOKEN_TYPE,
  CHEST_TOKEN_TYPE,
  PASS_TOKEN_TYPE,
  VOXEL_TOKEN_TYPE
} from '@/constants';
import alienFallback from '@/images/alien-silhouette.webp';
import ufoFallback from '@/images/ufo-silhouette.webp';
import petFallback from '@/images/pet-silhouette.webp';
import boxFallback from '@/images/box-silhouette.webp';
import chestFallback from '@/images/chest-silhouette.webp';
import passFallback from '@/images/pass-silhouette.webp';
import voxelFallback from '@/images/pass-silhouette.webp';

const props = defineProps({
  tokenId: { type: [Number, String] },
  classImage: { type: String },
  tokenType: { type: [String], default: IMPOSTOR_TOKEN_TYPE },
  fallbackSrc: { type: [String] }
});

let imageLoaded = ref(false);
let imageError = ref(false);
let imageSrc = computed(() => {
  let src = {
    [IMPOSTOR_TOKEN_TYPE]: `${IMPOSTORS_IMAGE_THUMB_URL}/${props.tokenId}.webp`,
    [UFO_TOKEN_TYPE]: `${UFO_IMAGE_THUMB_URL}/${props.tokenId}.webp`,
    [PET_TOKEN_TYPE]: `${PET_IMAGE_THUMB_URL}/${props.tokenId}.webp`,
    [BOX_TOKEN_TYPE]: `${BOX_IMAGE_THUMB_URL}/${props.tokenId}.webp`,
    [CHEST_TOKEN_TYPE]: `${CHEST_IMAGE_THUMB_URL}/${props.tokenId}.webp`,
	[PASS_TOKEN_TYPE]: `${PASS_IMAGE_THUMB_URL}/${props.tokenId}.webp`,
    [VOXEL_TOKEN_TYPE]: `${VOXEL_IMAGE_THUMB_URL}/${props.tokenId}.webp`
  }[props.tokenType];

  return imageError.value && props.fallbackSrc ? props.fallbackSrc : src;
});

let defaultFallback = computed(() => {
  let src = {
    impostor: { src: alienFallback },
    ufo: { src: ufoFallback },
    pet: { src: petFallback },
    box: { src: boxFallback },
    chest: { src: chestFallback },
	pass: { src: passFallback, classes: ['fallback-no-opacity'] },
    voxel: { src: voxelFallback, classes: ['fallback-no-opacity'] }
  }[props.tokenType];

  return src;
});
</script>

<template>
  <div class="card-wrapper">
    <div class="loading-card" :class="{ loaded: imageLoaded }"></div>
    <!-- <slot name="fallback" v-if="imageError"> -->
    <div
      v-if="(imageError || !props.tokenId) && !props.fallbackSrc"
      class="impostor-image fallback-container"
      :class="[props.classImage, { loaded: imageLoaded }]"
    >
      <img
        :src="defaultFallback.src"
        @load="imageLoaded = true"
        class="fallback-image"
        :class="defaultFallback.classes"
      />
    </div>
    <!-- </slot> -->
    <v-lazy-image
      v-if="props.tokenId"
      :src="imageSrc"
      @load="imageLoaded = true"
      @error="imageError = true"
      class="impostor-image"
      :class="[props.classImage, { loaded: imageLoaded }]"
    />
  </div>
</template>

<style scoped lang="scss">
.card-wrapper {
  position: relative;
  overflow: hidden;
}
.loading-card {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: visibility 0s linear 200ms;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.3),
      rgba(37, 22, 22, 0)
    );
    animation: shimmer 1.5s infinite;
    content: '';
  }

  &.loaded {
    visibility: hidden;
  }
}
.impostor-image {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 200ms ease-in;

  &.loaded {
    opacity: 1;
  }
}
.fallback-container {
  background: #263274;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fallback-image {
  height: 65%;
  max-width: 60%;
  object-fit: contain;
  opacity: 0.4;
}
.fallback-no-opacity {
  opacity: 1;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
