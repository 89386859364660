<template>
  <Header :isHome="false" />
  <div class="bg-wrapper">
    <div class="primary-bg"></div>
    <div class="fade"></div>
  </div>
  <Ethereum :callback="initialize" />
  <NotFoundMain v-if="notFound" />
  <div v-else class="container">
    <MyItemsSkeleton v-if="isLoading" />
    <SkewedDiv v-else-if="isConnected" class="wrapper" :borderRadius="15" background="#2F4EB6" :skew="[0, 2, 10, -10]">
      <ItemDetails @goBack="goBack" type="impostor" :item="nft" />
    </SkewedDiv>
    <div class="connectWalletWrapper" v-else>
      <ConnectWallet />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import Ethereum from '/src/components/common/Ethereum.vue';
import Header from '../layout/Header.vue';
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import MyItemsSkeleton from './components/MyItemsSkeleton.vue';
import ConnectWallet from '@/components/common/ConnectWallet.vue';
import { claimService } from '@/services';
import ItemDetails from './components/ItemDetails.vue';
import { useQuery } from 'vue-query';
import NotFoundMain from '../not-found/components/NotFoundMain.vue';

const isEthLoading = ref(true);
const store = useStore();
const route = useRoute();
const router = useRouter()

const isConnected = computed(() => {
  return store.state.ethers.canSign;
});

const initialize = async () => {
  isEthLoading.value = false;
};

let enabled = computed(() => !isEthLoading.value);
let { data: nft, isLoading: isItemLoading } = useQuery(
  ['impostor', route.params.id],
  () => {
    return claimService.loadItemById({
      collection: 'impostor',
      tokenId: route.params.id,
      dispatch: store.dispatch
    });
  },
  {
    enabled
  }
);

let isLoading = computed(() => isItemLoading.value || isEthLoading.value)
let notFound = computed(() => !isLoading.value && !nft.value)

let goBack = () => {
  router.push({path: `/my-items/${store.state.ethers.address}`})
}
</script>

<style scoped lang="scss">
.bg-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  .fade {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 300px;

    background-image: linear-gradient(0deg,
        var(--color-bg-main),
        55%,
        transparent);
  }

  .primary-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 934px;
    width: 100%;
    background-image: linear-gradient(0deg,
        var(--color-bg-main),
        15%,
        transparent),
      url('../../images/background.webp');
    background-attachment: scroll;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: contain;
    pointer-events: none;
    /* ensures background-size: contain stretches full width */
    min-height: 100vw;
  }
}

.container {
  width: 100%;
  height: 100%;
  min-height: 90vh;
  padding-top: 110px;
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 60px;

  .fade {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), #1e1226);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
  }

  .wrapper {
    max-width: 1280px;
    width: 100%;
    margin: 0px 40px;
    padding: 30px 50px;
  }

  .connectWalletWrapper {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .container .wrapper {
    padding: 30px;
    margin: 0px 20px;
  }
}
</style>
