<script setup>
import { computed } from 'vue';
import { Skeletor } from 'vue-skeletor';
import ClaimRow from './ClaimRow.vue';
import { IMPOSTOR_TOKEN_TYPE, TOKEN_CONFIG } from '@/constants';
import {
  useClaimTokenInfo,
  useClaimDetails,
} from '../composables';

let { isLoading: tokenLoading } = useClaimTokenInfo();
let { isLoading: claimLoading } = useClaimDetails();
let isLoading = computed(() => tokenLoading.value || claimLoading.value);

const props = defineProps({});
// TODO: Refactor modal logic from Claim.vue to this file
</script>

<template>
  <Skeletor
    v-if="isLoading"
    :style="{
      width: '100%',
      height: '160px',
      background: 'rgba(255,255,255,.18)',
      borderRadius: '12px'
    }"
  />
  <ClaimRow
    v-else
    title="UFO"
    class="claim-row"
    :requirements="[
      { text: TOKEN_CONFIG[IMPOSTOR_TOKEN_TYPE].DISPLAY_NAME }
    ]"
    learnMoreHref="https://docs.impostors.gg/metapaper/metaverse-economy/ufo-ecosystem"
  />
</template>

<style module="s" lang="scss">
</style>
