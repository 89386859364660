'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { l } from '@/datadog';
const logName = 'jwt.service.js';

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

// initialize authentication on JWT provider
// Returns message to be signed. Step 1 of JWT token auth process
const auth = async function ({ address }) {
  if (!config) {
    config = await initializeConfig();
  }

  const formData = new FormData();
  formData.append('address', address);
  const response = await fetch(config.jwtProvider, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      address: address
    })
  });
  return response;
};

// continue authentication on JWT provider
// Returns authToken and refreshToken. Step 2 of JWT token auth process
const login = async function ({ address, challenge, signature }) {
  if (!config) {
    config = await initializeConfig();
  }

  const response = await fetch(config.jwtProvider + 'login/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      address: address,
      message: challenge,
      signature: signature
    })
  });
  return response;
};

// provided jwt token, verifies against jwt provider
// Returns JWT payload consisting of original
// signer 'address', 'exp'iration datem 'iat', 'iss', 'jti', 'nbf' and 'sub'
const verify = async function ({ token }) {
  if (!config) {
    config = await initializeConfig();
  }

  const response = await fetch(config.jwtProvider + 'verify/', {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  return response;
};

// Export the jwt service functions.
export const jwtService = {
  auth,
  login,
  verify
};
