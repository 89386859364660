<script setup>
import { computed } from 'vue';
import { Tippy } from 'vue-tippy';
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import SkewedButton from '@/components/common/SkewedButton.vue';
import UFOImg from '@/images/ufo-art.webp';

const props = defineProps({
  bloodCost: { type: [Number, String] },
  enableClaim: { type: Boolean },
  title: { type: String },
  requirements: { type: Array },
  imgSrc: { type: String, default: UFOImg },
  classImg: { type: String, default: UFOImg },
  learnMoreHref: { type: String },
});

let { format } = new Intl.NumberFormat('en-US');

const bloodCostDisplay = computed(() => format(props.bloodCost));
const emit = defineEmits(['claim']);
</script>

<template>
  <SkewedDiv
    class="claim-row"
    :borderRadius="5"
    :skew="[0, 0, 8, -3]"
	background="rgba(0,0,0,.2)"
    classContent="claim-row-content"
  >
    <div class="col_img">
      <img :src="imgSrc" class="claim-img" :class="classImg" alt="ufo claim" />
    </div>

    <div class="col col_info">
      <h2>{{ title }}</h2>
      <p class="description">Requirements for Claiming</p>
      <p v-for="{ text } in requirements" :key="text" class="tag">
        {{ text }}
      </p>
      <p class="tag price">{{ bloodCostDisplay }} $BLOOD</p>
    </div>

    <div class="buttons">
      <div class="col col_btn">
        <router-link to="/check-eligibility">
          <SkewedButton
            class="button button_check-elig"
            :borderRadius="6"
            :skew="[0, 0, [-5, -3], 0]"
            background="#5971C5"
            borderColor="var(--color-button-yellow)"
            borderWidth="2px"
          >
            Check Eligibility
          </SkewedButton>
        </router-link>

        <a
          :href="props.learnMoreHref"
          target="_blank"
          >Learn More</a
        >
      </div>

      <div class="col col_btn">
        <Tippy :style="{ width: '100%' }">
          <SkewedButton
            class="button"
            :borderRadius="6"
            :skew="[0, 0, [-5, -3], 0]"
            background="var(--color-button-yellow)"
            :disabled="!props.enableClaim"
            @click="$emit('claim')"
          >
            Claim
          </SkewedButton>
          <template #content>
            <SkewedDiv
              background="#2e4596"
              :border-radius="4"
              :skew="[0, 4, 6, -3]"
              class="tooltip"
              :style="[props.enableClaim && { display: 'none' }]"
              >You have no eligible claims</SkewedDiv
            >
          </template>
        </Tippy>
      </div>
    </div>
  </SkewedDiv>
</template>

<style lang="scss" scoped>
.claim-row {
  padding: 12px;
  padding-right: 36px;

  &::v-deep .claim-row-content {
    display: flex;
    align-items: center;
  }
}

.claim-img {
  max-width: 210px;
}

img {
  margin: -30px 0 -30px -20px;
}

.col_img {
  min-width: 190px;
  display: flex;
  justify-content: center;
}

.col_info {
  margin-left: 18px;
  min-width: 250px;
}

.col_btn {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 140px;
}

.buttons {
  flex: 1;
  display: flex;
  margin-left: 48px;

  & > * + * {
    margin-left: 39px;
  }
}

.button {
  font-size: 20px;
  font-weight: bold;
  width: 100%;
}

.button_check-elig {
  color: white;
  margin-bottom: 6px;
}

a {
  text-decoration: none;
  color: white;
  font-size: 16px;

  &:hover {
    color: #f0f907;
  }
}

h2 {
  font-size: 30px;
  color: #f0f907;
}

p {
  font-size: 17px;
  margin-bottom: 10px;
}

.tag {
  padding: 3px 9px;
  background-color: rgba(33, 33, 33, 0.2);
  border-radius: 4px;
  font-size: 14px;
  color: #f0f907;
  width: max-content;
  margin-bottom: 4px;
}

.tooltip {
  padding: 12px;
  filter: drop-shadow(4px 4px 0px rgba(2, 11, 40, 0.6));
}

@media (max-width: 1080px) {
  .claim-row {
    &::v-deep .claim-row-content {
      display: grid;
      gap: 18px;
      row-gap: 48px;
      grid-template-columns: auto 1fr;
      grid-template-areas:
        'img info'
        'controls controls';
    }
  }
  .buttons {
    grid-area: controls;
    margin-left: initial;
  }
}

@media (max-width: 600px) {
  .claim-row.claim-row {
    margin-bottom: 12px;

    &::v-deep .claim-row-content {
      display: grid;
      gap: 18px;
      row-gap: 36px;
      grid-template-columns: auto;
      grid-template-areas:
        'img'
        'info'
        'controls';
      justify-items: center;
    }
  }
  .buttons {
    grid-area: controls;
    margin-left: initial;
    flex-direction: column;
    gap: 12px;

    & > * + * {
      margin-left: 0;
    }
  }
  .col_info {
    margin-left: 18px;
    min-width: auto;
  }
}
</style>
