<template>
  <div class="wrapper">
    <div class="countdown-area">
      <div class="countdown-area__background" />
      <div class="countdown-area__content">
        <div class="countdown-area__clock-container">
          <SkewedDiv
            :border-radius="bubbleSizeConstant"
            :clip-path="`polygon(0% 0%, 100% 0%, calc(100% - ${bubbleSizeConstant}px) 100%, ${bubbleSizeConstant}px 100%)`"
            class-content="countdown-area__clock-content"
            class-skew-box="countdown-area__clock-skew-box"
            class="countdown-area__clock"
          >
            <img src="~@/images/logo.svg" class="countdown-area__clock-logo" />
            <h2>
              The
              <span>play-and-earn</span> social deduction game
            </h2>

            <template v-if="!isLoading">
              <div class="mint-area-wrapper">
                <SkewedDiv
                  :border-radius="bubbleSizeConstant"
                  opacity=".1"
                  :clip-path="`polygon(0% ${bubbleSizeConstant}px, 100% 0%, 100% 100%, ${bubbleSizeConstant}px 100%)`"
                  class-content="countdown-area__clock-timer-content"
                  class-skew-box="countdown-area__clock-timer-skew-box"
                  class="countdown-area__clock-timer mint-area"
                >
                  <StatusMint status="SOLD_OUT" />
                </SkewedDiv>
              </div>
            </template>
          </SkewedDiv>
          <img src="~@/images/game-icons.png" class="countdown-area__clock-game-icons" />
        </div>
        <img src="~@/assets/countdown-area-impostor.webp" class="countdown-area__impostor" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { SkewedDiv } from '@/components/common';
import useCountdown from '@/utility/useCountdown';
import StatusMint from './StatusMint.vue';

const windowSize = inject('windowSize');

const store = useStore();

onMounted(() => {
  window.scroll(0, 0);

  // retrieve shop config (when wallet not connected, would rely on infura provider)
  loadShopConfig();
});

const wl_tokenStartTime = computed(() => {
  return (
    store.state.mint.shopConfig?.tokenStartTime ||
    Date.UTC(2022, 2, 28, 19, 0, 0)
  );
});

const whitelistStartDate = computed(() => {
  return new Date(wl_tokenStartTime.value);
});

let {
  formattedDaysLeft,
  formattedHoursLeft,
  formattedMinutesLeft,
  formattedSecondsLeft,
  timeLeft
} = useCountdown({ endDate: whitelistStartDate });

const countdownItems = computed(() => [
  { label: 'D', value: formattedDaysLeft.value },
  { label: 'H', value: formattedHoursLeft.value },
  { label: 'M', value: formattedMinutesLeft.value },
  { label: 'S', value: formattedSecondsLeft.value }
]);

const bubbleSizeConstant = computed(() =>
  windowSize.value.width > 640 ? 16 : 8
);

let isLoading = ref(true);
const loadShopConfig = async () => {
  // delay to ensure all ABIs have loaded
  // not needed once we load ABIs directly instead of via http/get
  await new Promise(resolve => setTimeout(resolve, 100));

  //console.info("loading shop config at address=", config.shopAddress[store.state.ethers.networkId]);
  await store.dispatch(
    'mint/loadShopConfig',
    {},
    {
      root: true
    }
  );
  isLoading.value = false;
};

const sold = computed(() => {
  if (isLoading.value) return 0;
  return store.state?.mint?.shopConfig?.sold.toNumber();
});

const totalCap = computed(() => {
  if (isLoading.value) return 0;
  return store.state?.mint?.shopConfig?.totalCap.toNumber();
});

let soldOut = computed(() => sold.value >= totalCap.value);
</script>

<style scoped lang="scss">
@import "../../../assets/style/mixins.scss";

.countdown-area {
  min-height: 980px;
  height: 980px;
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #412a51;

  &__background {
    position: absolute;
    width: 100%;
    height: 843px;
    top: 0;
    z-index: 0;
    background-image: url("~@/assets/countdown-area-bg.webp");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
  }

  &__content {
    width: 100%;
    max-width: 100%;
    height: 100%;
    @include flex(row, center, flex-start);
    padding: 0 48px;
    position: relative;
  }

  &__clock {
    width: 506px;
    position: relative;

    &-container {
      margin-top: 180px;
      position: relative;
      @include flex(column, center, center);
    }

    :deep(.countdown-area__clock-skew-box) {
      background: linear-gradient(95.14deg, #849be6 -7.98%, #2e4eb6 56.41%);
    }

    :deep(.countdown-area__clock-content) {
      width: 100%;
      height: 100%;
      padding: 80px 36px 36px;
      @include flex(column, space-between, center);
    }

    &-logo {
      width: 506px;
      position: absolute;
      top: -68px;
    }

    h2 {
      width: 342px;
      text-align: center;
      @include DMSans(30px, 700);
      margin-bottom: 24px;

      span {
        color: rgb(204, 154, 38);
      }
    }

    &-timer {
      width: 100%;
      height: 100%;

      :deep(.countdown-area__clock-timer-skew-box) {
        background-color: var(--color-white);
      }

      :deep(.countdown-area__clock-timer-content) {
        height: 100%;
        width: 100%;
        @include flex(column, space-between);
        padding: 44px 0;
      }

      h4 {
        text-transform: uppercase;
        @include DMSans(20px, 700);
      }

      .clock-timer {
        width: 100%;
        @include flex();
        column-gap: 16px;

        &__item {
          @include flex(row, center, baseline);
          column-gap: 4px;

          &-value {
            @include DMSans(48px, 700);
          }

          &-label {
            @include DMSans(19px, 700);
          }
        }
      }
    }

    &-game-icons {
      z-index: 1;
    }
  }

  &__impostor {
    /* allow clicking through to button */
    pointer-events: none;
    width: 574px;
    object-fit: cover;
    object-position: top;
    margin: auto 32px 0px 48px;
    z-index: 1;
  }

  .mint-btn-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 48px;

    h2 {
      display: flex;
      justify-content: center;
      font-size: 39px;
    }

    .mint-btn {
      width: 100%;
      padding: 23px;
    }

    .mint-btn__content {
      display: flex;
      font-size: 25px;
      font-weight: bold;
    }

    .status-chip {
      background: red;
      border-radius: 12px;
      padding: 0 22px;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      margin-left: 16px;
    }

    .mint-link {
      padding: 0 24px;
      text-decoration: none;
      width: 100%;
    }
  }

  .mint-area-wrapper {
    width: 100%;
    padding: 0 24px 0 18px;
  }

  .sirens {
    --offset: -18px;
    &:before,
    &:after {
      content: "";
      background: url("~@/images/siren.svg") center/contain no-repeat;
      width: 50px;
      height: 50px;
      position: absolute;
    }
    &:before {
      left: var(--offset);
      transform: rotateZ(-20deg) scaleX(-1) translateY(-12px);
    }
    &:after {
      right: var(--offset);
      transform: rotateZ(20deg) translateY(-12px);
    }
  }
}

@include mediaOnlyScreenMax(1280px) {
  .countdown-area {
    &__impostor {
      width: 420px;
      margin-bottom: calc((1 - 420 / 574) * 133px);
    }
  }
}

@include mediaOnlyScreenMax(1050px) {
  .countdown-area {
    &__content {
      padding: 0 36px;
      width: 100%;
      @include flex(row, flex-start, flex-start);
    }

    &__clock {
      &-container {
        @include flex(column, flex-start, center);
      }

      :deep(.countdown-area__clock-skew-box) {
        background: linear-gradient(95.14deg, #849be6 -7.98%, #2e4eb6 56.41%);
      }
    }

    &__impostor {
      position: absolute;
      margin: initial;
      width: 300px;
      right: 12px;
      bottom: calc((1 - 300 / 574) * 133px);
    }
  }
}

@include mediaOnlyScreenMax(640px) {
  .countdown-area {
    min-height: 786px;
    height: 786px;

    &__background {
      height: 653px;
    }

    &__clock {
      width: 300px;

      &-container {
        margin-top: 140px;
        width: 100%;
        @include flex();
      }

      :deep(.countdown-area__clock-skew-box) {
        background: linear-gradient(95.14deg, #849be6 -7.98%, #2e4eb6 56.41%);
      }

      :deep(.countdown-area__clock-content) {
        padding: 52px 24px 24px;
      }

      &-logo {
        width: 300px;
        position: absolute;
        top: -42px;
        left: calc((100% - 300px) / 2);
      }

      h2 {
        width: 232px;
        text-align: center;
        @include DMSans(19px, 700);
      }

      &-timer {
        :deep(.countdown-area__clock-timer-skew-box) {
          background-color: var(--color-white);
        }

        :deep(.countdown-area__clock-timer-content) {
          padding: 28px 0;
        }

        h4 {
          text-transform: uppercase;
          @include DMSans(14px, 700);
        }

        .clock-timer {
          column-gap: 10px;

          &__item {
            @include flex(row, center, baseline);
            column-gap: 2px;

            &-value {
              @include DMSans(28px, 700);
            }

            &-label {
              @include DMSans(12px, 700);
            }
          }
        }
      }

      &-game-icons {
        display: none;
      }
    }
    &__impostor {
      position: absolute;
      margin: initial;
      margin-left: 52px;
      width: 250px;
      bottom: calc((1 - 250 / 574) * 133px);
    }
  }
}
</style>
