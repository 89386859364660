<template>
  <div style="background-color: rgb(29,19,37);height: 100vh;">
    <Ethereum :callback="initialize" />
    <Header :isHome="isHome" :buttonMsg="buttonMsg"/>
    <NotFoundMain id="notFound" />
  </div>
</template>
<script>
import Ethereum from '/src/components/common/Ethereum.vue';
import Header from '/src/pages/layout/Header.vue';
import NotFoundMain from './components/NotFoundMain.vue';

import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter} from 'vue-router';

export default {
  components: {
    Ethereum,
    Header,
    NotFoundMain
  },

  setup() {
    const store = useStore();
    const router = useRouter();

    const isLoggedIn = computed(() => {
      return store.state.ethers.address;
    });

    const initialize = async () => {};

    const isHome = computed(() => {
      return router.currentRoute.value.path === '/';
    });

    const buttonMsg = computed(() => {
      return "MINT";
    });

    return {
      store,
      isHome,
      buttonMsg,
      isLoggedIn,
      initialize
    };
  }
};
</script>
