<script setup>
console.log('test')
</script>

<template>
  <div class="privacy-container">
    <div class="privacy-text-container">
      <div>
        <p class="c33"><span class="c0"></span></p>
      </div>
      <p class="c26">
        <span class="c8 c2 c11 c9">Impostors Game Terms of Service</span>
      </p>
      <p class="c26"><span class="c2">Last Updated: August 11, 2022</span></p>
      <h1 class="c16" id="h.gjdgxs">
        <span class="c1"
          >Welcome to the Impostors Game Terms of Service (&ldquo;</span
        ><span class="c1">Terms</span
        ><span class="c1"
          >&rdquo;). This is a legal agreement between you and Super Studios,
          Inc. (&ldquo;</span
        ><span class="c1">Super Studios&rdquo;</span
        ><span class="c1">) regarding our Services. &ldquo;</span
        ><span class="c1">Services</span
        ><span class="c1"
          >&rdquo; means collectively, and sometimes individually, (a) the Game
          and (b) any services we provide relating to our Game, whether or not
          the Game is installed or used on a computer, console, or a mobile
          device. &ldquo;</span
        ><span class="c1">Game</span
        ><span class="c1"
          >&rdquo; means our Impostors game that you download and access that is
          subject to these Terms, regardless of where you download and/or access
          it, and any documentation, software, updates, Virtual Goods and
          Content (each defined below) included in the game.</span
        >
      </h1>
      <p class="c7">
        <span class="c2 c14"
          >IMPORTANT NOTICE REGARDING ARBITRATION FOR U.S. USERS: WHEN YOU AGREE
          TO THESE TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE
          ANY DISPUTE BETWEEN YOU AND US THROUGH BINDING, INDIVIDUAL ARBITRATION
          RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 18
          &ldquo;DISPUTE RESOLUTION AND GOVERNING LAW &ndash; UNITED
          STATES&rdquo; BELOW FOR DETAILS REGARDING ARBITRATION. </span
        ><span class="c8 c2 c11 c9"
          >THESE TERMS ALSO INCLUDE A WAIVER OF RIGHTS BY YOU TO BRING A CLASS
          ACTION AGAINST US AND A LIMITATION ON DAMAGES THAT YOU CAN COLLECT
          FROM US THAT MAY ARISE OUT OF YOUR USE OF THE SERVICES. BY USING THE
          SERVICES, YOU AGREE TO THESE PROVISIONS.</span
        >
      </p>
      <p class="c7">
        <span class="c8 c2 c11 c9"
          >IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT INSTALL, OR USE OUR
          SERVICES. WITH LIMITED EXCEPTION (SEE SECTION 8), ANYTHING YOU
          PURCHASE FROM US IS NON-REFUNDABLE.</span
        >
      </p>
      <ol class="c12 lst-kix_list_1-0 start" start="1">
        <li class="c4">
          <h1 id="h.30j0zll" style="display: inline">
            <span class="c8 c2 c11 c9">Defined Terms</span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c0"
          >You&rsquo;ll notice some capitalized terms in these Terms.
          They&rsquo;re called &ldquo;defined terms,&rdquo; and we use them so
          we don&rsquo;t have to repeat the same language again and again, and
          to make sure that the use of these terms is consistent throughout the
          Terms. &nbsp;We&rsquo;ve included the defined terms throughout because
          we want it to be easy for you to read them in context.
        </span>
      </p>
      <ol class="c12 lst-kix_list_1-0" start="2">
        <li class="c4">
          <h1 id="h.1fob9te" style="display: inline">
            <span class="c8 c2 c11 c9">Additional Terms</span>
          </h1>
        </li>
      </ol>
      <p class="c13">
        <span class="c1"
          >Some Services may be available through accessing (or downloading
          from) (a) Impostors Launcher, Xsolla or a third-party platform, or (b) </span
        ><span>the website and interface located at </span
        ><span class="c5 c10"
          ><a
            class="c20"
            href="https://impostors.gg/"
            >https://impostors.gg/</a
          ></span
        ><span class="c5 c10">&nbsp;</span><span class="c15 c9">(&ldquo;</span
        ><span class="c23 c15 c9">Website</span
        ><span class="c9 c15">&rdquo;), which will link to </span
        ><span class="c1"
          >a third-party platform or store, including but not limited to, the
          Epic Games platform, the Google Play Store and Apple App Store
          (Xsolla, third-party platforms or stores, each, an &ldquo;</span
        ><span class="c2">App Store</span
        ><span class="c1"
          >&rdquo;), and such access will allow you to download the Game. Your
          use of the Services is also governed by any applicable agreements you
          have with any App Store (the &ldquo;</span
        ><span class="c2">App Store Agreement(s)</span
        ><span class="c1"
          >&rdquo;) and/or the separate Terms of Service located at </span
        ><span class="c5 c1 c10"
          ><a
            class="c20"
            href="https://impostors.gg/terms"
            >https://impostors.gg/terms</a
          ></span
        ><span class="c1">&nbsp;(the &ldquo;</span
        ><span class="c2">Website Terms</span><span class="c1">&rdquo;). </span
        ><span class="c0"
          >In the event of a conflict between any other App Store Agreement(s)
          or Website Terms from which you acquire or access our Game and these
          Terms with respect to your use of the Services, these Terms will take
          priority.
        </span>
      </p>
      <p class="c13">
        <span
          >Users may use certain digital assets available through third parties
          in the form of non-fungible cryptocurrency tokens (&ldquo;</span
        ><span class="c23">NFTs</span
        ><span>&rdquo;) (such NFTs, the &ldquo;</span
        ><span class="c23">Impostors NFTs</span
        ><span
          >&rdquo;) in the Game, including but not limited to the Genesis
          Aliens. If you use the Impostors NFTs in the Game, you agree to be
          bound by and comply with the Website Terms. Further, you agree and
          acknowledge that all rights and obligations associated with the
          Impostors NFTs are governed by the NFT Purchase and License Agreement
          (&ldquo;</span
        ><span class="c23">NFT Terms</span
        ><span class="c0"
          >&rdquo;), which may be promulgated by a third party.</span
        >
      </p>
      <p class="c13">
        <span class="c1"
          >The collection of information from you and related to the Services
          (whether obtained through an App Store or Website or not) is governed
          by our Privacy Policy at </span
        ><span class="c5 c1 c10"
          ><a
            class="c20"
            href="https://impostors.gg/privacy"
            >https://impostors.gg/privacy</a
          ></span
        ><span class="c0"
          >. Our Privacy Policy explains how we collect, use, and disclose
          information that we collect from and about you.</span
        >
      </p>
      <ol class="c12 lst-kix_list_1-0" start="3">
        <li class="c4">
          <h1 id="h.3znysh7" style="display: inline">
            <span class="c8 c2 c11 c9">Eligibility and Registration</span>
          </h1>
        </li>
      </ol>
      <ol class="c12 lst-kix_list_1-1 start" start="1">
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5">Age</span
            ><span
              >. While some aspects of the Services are provided to all users,
              for other aspects, such as the ability to use some social
              features, you must be at least 13 years of age (or such other
              minimum age as is applicable in the jurisdiction you live in) </span
            ><span class="c1"
              >and not otherwise barred from using the Services under applicable
              law</span
            ><span class="c0"
              >. If you are under 18 years of age, you must have one of your
              parents (or legal guardians) read these Terms and accept them on
              your behalf. Parents and guardians are responsible for the acts of
              their minor children when using any of our Services, and if you
              are a parent or guardian accepting these Terms, you understand and
              agree that you will be responsible for all uses of our Services by
              your minor child whether or not you authorized such uses. You may
              not create or use a Game Account, or use or access any of our
              Services on behalf of a legal entity or for a commercial purpose.
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Account Creation</span
            ><span class="c1"
              >. To access parts of the Game, you may need to connect your
              account you may have with any App Store (your &ldquo;</span
            ><span class="c2">App Store Account</span
            ><span class="c1"
              >&rdquo;) to the Services or create an account with a third-party
              platform that we direct you to and provide certain information,
              such as your email, username, password, and ETH address (your
              &ldquo;</span
            ><span class="c2">Game Account</span
            ><span class="c1"
              >&rdquo;). To the extent you create your Game Account through the
              use of a third-party account, we may access certain personal
              information that the third party provides to us such as your email
              address and name to help create your Game Account. Further
              information about use of third-party accounts is provided in the
              Privacy Policy at </span
            ><span class="c5 c1 c10"
              ><a
                class="c20"
                href="https://impostors.gg/privacy"
                >https://impostors.gg/privacy</a
              ></span
            ><span class="c0">.</span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Linking Your Wallet</span
            ><span class="c1">. </span
            ><span class="c1 c14 c9"
              >You may use your NFTS, such as your Impostors NFT, in the Game by
              linking your digital wallet(s) on supported bridge extensions.
              Before using your Impostors NFT in the Game, we may ask you to
              download a supported electronic wallet extension and connect and
              unlock your digital wallets with that extension.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Keep Your Information Current</span
            ><span class="c0"
              >. It&rsquo;s important that you provide us with accurate,
              complete and up-to-date information for your Game Account and you
              agree to update such information to keep it that way. If you
              don&rsquo;t, we may suspend or terminate your Game Account. You
              agree that you will not disclose your Game Account password to
              anyone and will notify us immediately of any unauthorized use of
              your Game Account. You are responsible for all activities that
              occur under your Game Account, whether or not you know about them.
              &nbsp;If you believe that your Game Account is no longer secure,
              then you must immediately notify us at support@superfarm.io.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">No Account Sharing.</span
            ><span class="c0"
              >&nbsp; You may not sell, rent, lease, share or provide access to
              your Game Account to anyone else. We reserve all available legal
              rights and remedies to prevent unauthorized use of our Services,
              including, but not limited to, technological barriers, IP mapping,
              and, in serious cases, directly contacting your Internet Service
              Provider (ISP) regarding such unauthorized use.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">No False Accounts.</span
            ><span class="c0"
              >&nbsp;You may not create a Game Account for anyone other than
              yourself.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c22">$SUPER</span
            ><span class="c22"
              >. $SUPER serves as the governance token over the Game metaverse.
              If you are a $SUPER Holder, you may participate in the governance
              of the Game as determined by the DAO governance rules, as may be
              promulgated from time to time. </span
            ><span>As used in these Terms, &ldquo;</span
            ><span class="c23">$SUPER</span
            ><span
              >&rdquo; refers to the ERC-20 token distributed by the following
              Ethereum contract address:
              &nbsp;0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55.
            </span>
          </h2>
        </li>
      </ol>
      <ol class="c12 lst-kix_list_1-0" start="4">
        <li class="c4">
          <h1 id="h.2et92p0" style="display: inline">
            <span class="c8 c2 c11 c9">License</span>
          </h1>
        </li>
      </ol>
      <ol class="c12 lst-kix_list_1-1 start" start="1">
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">License Grant</span
            ><span class="c1"
              >. So long as you comply with these Terms and, as applicable, the
              App Store Agreement(s) or Website Terms, we grant you the
              following license: a personal, non-exclusive, non-transferable,
              non-sublicensable limited right and license to (i) download and
              install the Game on the device permitted by the App Store
              Agreement(s) or Website Terms (if applicable), and (ii) access and
              use the Services, including any Content, for&nbsp;your personal
              entertainment purposes, leveraging only the functionality of the
              Game and Services. We and our licensors reserve all rights not
              granted to you in these Terms. &ldquo;</span
            ><span class="c2">Content</span
            ><span class="c1"
              >&rdquo; means all artwork, titles, themes, objects, characters,
              names, dialogue, catch phrases, stories, animation, concepts,
              sounds, audio-visual effects, methods of operation, musical
              compositions, Virtual Goods (defined in Section 7) and any other
              content within the Services. Content also includes anything
              generated, created, or that is otherwise developed within the
              Services by any user (including you) as a result of interaction
              with the functionality of the Services. &nbsp;</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 id="h.tyjcwt" style="display: inline">
            <span class="c5 c1">Service Limits Based on Where You Live</span
            ><span class="c1">.</span><span class="c1 c6">&nbsp;</span
            ><span class="c1"
              >We may restrict, modify, or limit your access to and use of
              certain Content, Virtual Goods, the Game, or any or all of the
              Services, depending on the territory in which you are located.
              Without limiting the foregoing, Content, Virtual Goods, the Game,
              or the Services may not be available (in whole or in part) where
              you are located or may only be available in a modified version, if
              they do not comply with the laws which apply in your
              country.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Minimum Requirements</span
            ><span class="c0"
              >. The Game may have minimum requirements for the devices and
              systems on which you wish to play the Game. We may publish these
              minimum requirements on the applicable website and/or otherwise
              notify you in writing. For an optimal experience, please ensure
              that your devices and systems will meet these requirements before
              playing the Game.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Seizure Warning</span
            ><span class="c1"
              >. The Game may contain flashing lights, images, and other
              luminous stimulations which may induce epileptic seizures in
              certain individuals. If you or anyone in your household has an
              epileptic condition, please consult your doctor before playing the
              Game. If you experience dizziness, altered vision, eye or muscle
              twitches, loss of awareness, disorientation, any involuntary
              movement, or convulsions while playing, please immediately
              discontinue playing the Game and consult your doctor.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c1 c5">Streaming</span
            ><span class="c1"
              >. You are permitted to make gameplay videos for the Game, subject
              to your compliance with the terms of our [</span
            ><a href="/game-terms/#game-streaming"><span class="c1">Streaming Policy</span
            ></a><span class="c1">] for such gameplay video activity.</span>
          </h2>
        </li>
      </ol>
      <ol class="c12 lst-kix_list_1-0" start="5">
        <li class="c4">
          <h1 id="h.3dy6vkm" style="display: inline">
            <span class="c8 c2 c9 c11">License Restrictions</span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c0"
          >You agree not do any of the following with respect to the Services:
        </span>
      </p>
      <ol class="c12 lst-kix_list_1-1 start" start="6">
        <li class="c3">
          <h2 style="display: inline">
            <span class="c1">except as permitted by our [</span
            ><a href="/game-terms/#game-streaming"><span class="">Streaming Policy</span
            ></a><span class="c0"
              >], use them commercially, for a promotional purpose, or for the
              benefit of any third party or in any manner not permitted by these
              Terms;
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >use, or provide, any unauthorized third-party programs that
              intercept, emulate, or redirect any communication between the
              Services and Super Studios or that collect information about the
              Game;</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >use, or provide ancillary offerings to anyone, that are not
              offered within the Services by us (or the functionality of the App
              Store or the Website), such as hosting, &ldquo;leveling&rdquo;
              services, mirroring our servers, matchmaking, emulation,
              communication redirects, mods, hacks, cheats, bots (or any other
              automated control), trainers and automation programs that interact
              with the Services in any way, tunneling, third party program
              add-ons, and any interference with online or network play;</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >access or use them on more than one device simultaneously in
              violation of these Terms;
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >copy, reproduce, distribute, display, mirror, frame or use them
              (or any of our other materials, intellectual property, or
              proprietary information) in a way that is not authorized by Super
              Studios;
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >sell, rent, lease, license, distribute, or otherwise transfer the
              Services, Game or any Content, including participating in or
              operating so called &ldquo;secondary markets&rdquo; for Content,
              except for the Virtual Goods that may be transferred outside of
              the Game in accordance with these Terms, as communicated or shown
              to you within the functionality of the Game;
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >attempt to reverse engineer (except as otherwise permitted by
              applicable local law), derive source code from, modify, adapt,
              translate, datamine, decompile, or disassemble or make derivative
              works based upon the Services or any Content;
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >remove, disable, circumvent, or modify any technological measure
              we implement to protect them or any of their associated
              intellectual property;
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >create, develop, distribute, or use any unauthorized software
              programs to gain advantage in any online or other game modes or
              otherwise Cheat (as defined below);
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0">violate any applicable law or regulation;</span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >attempt to probe, scan or test its vulnerability or breach any
              security or authentication measures;
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >access, tamper with, or use non-public areas of the
              Services;</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >behave in a manner which is detrimental to the enjoyment of the
              Services by other users as intended by us, in our sole judgment,
              including, without limitation, harassment, use of abusive or
              offensive language, game abandonment, game sabotage, spamming,
              social engineering, or scamming, or contrary to public morals or
              public policy;</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >engage in any behavior that: (i) infringes, misappropriates or
              violates a third party&rsquo;s patent, copyright, trademark, trade
              secret, contractual rights, moral rights or other intellectual
              property rights, or rights of publicity or privacy; (ii) violates,
              or encourages any conduct that would violate any applicable law or
              regulation or would give rise to civil liability; (iii) is
              fraudulent, false, misleading or deceptive, including
              &ldquo;trolling;&rdquo; (iv) is defamatory, obscene, pornographic,
              vulgar or offensive; (v) promotes discrimination, bigotry, racism,
              hatred, harassment or harm against any individual or group; (vi)
              is violent, bullying, or threatening or promotes violence,
              terrorism, or actions that are threatening to any person or
              entity; or (vii) promotes illegal or harmful activities or
              substances;</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >interfere with, or attempt to interfere with, the access of any
              user, host or network, including, without limitation, sending a
              virus, overloading, flooding, spamming, or mail-bombing the
              Services or any of its users;
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >collect or store any information that could be used to identify
              an individual, either itself or combined with other information,
              from the Services from other users of the Services without their
              express permission;</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >trespass, or attempt to gain access to a property or location
              where you do not have permission to be or engage in any activity
              that may result in injury, death, property damage, nuisance or
              other liability;
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >impersonate or misrepresent your affiliation with any person or
              entity;
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >use IP proxying or other methods to disguise the place of your
              residence, whether to circumvent geographical restrictions on the
              Services or for any other purpose;</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >play on another person&rsquo;s Game Account to
              &ldquo;boost&rdquo; that Game Account&rsquo;s status, level or
              rank;
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >use the Services in any way that would affect us adversely or
              reflect negatively on us or the Services or discourage any person
              from using all or any portion of the features of the Services;
              or</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >encourage or enable anyone else to do any of the foregoing.</span
            >
          </h2>
        </li>
      </ol>
      <ol class="c12 lst-kix_list_1-0" start="6">
        <li class="c4">
          <h1 id="h.1t3h5sf" style="display: inline">
            <span class="c8 c2 c11 c9">Ownership of the Services</span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c1"
          >We and our affiliates and licensors own all title, ownership and
          intellectual property rights in the Services. You agree not to remove,
          alter or obscure any copyright, trademark, service mark or other
          proprietary rights notices incorporated in or accompanying the
          Services. &nbsp;You understand and agree that you have no ownership
          interest in the Services or Game or Content therein. The Services (and
          particularly our Game) may have built-in mechanisms designed to
          prevent granting one user an unfair advantage over other users (these
          actions are &ldquo;</span
        ><span class="c2">Cheating</span
        ><span class="c1">&rdquo;, and the software is the &ldquo;</span
        ><span class="c2">Cheat Detection Software</span
        ><span class="c0"
          >&rdquo;). We may add or update our Cheat Detection Software
          periodically as we may require in our sole discretion. The Services
          and/or the Cheat Detection Software may collect and transmit details
          about your Game Account, gameplay, and unauthorized programs or
          processes in connection with Cheating, subject to our Privacy Policy
          and applicable law. In the event that we in our sole discretion
          conclude that you are Cheating, you agree that we may exercise any or
          all of our rights under these Terms, including termination of these
          Terms and your access to our Services.
        </span>
      </p>
      <p class="c7">
        <span class="c0"
          >Subject to applicable privacy laws, although we are not obligated to
          monitor access to or use of the Services or to review or edit any
          Content, we have the right to do so for the purpose of operating and
          publishing the Services, to ensure compliance with these Terms, to
          protect the health or safety of anyone that we believe may be
          threatened, to protect our legal rights and remedies, to report a
          crime or offensive behavior, or to comply with applicable law. We may
          (but don&rsquo;t have to) remove or disable access to any Content, at
          any time and without notice. We may (but don&rsquo;t have to)
          investigate violations of these Terms or conduct that affects the
          Services.</span
        >
      </p>
      <ol class="c12 lst-kix_list_1-0" start="7">
        <li class="c4">
          <h1 id="h.4d34og8" style="display: inline">
            <span class="c8 c2 c11 c9"
              >Virtual Goods, Game Currency, and Subscriptions
            </span>
          </h1>
        </li>
      </ol>
      <ol class="c12 lst-kix_list_1-1 start" start="1">
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1"
              >Purchasing or Obtaining Virtual Goods and Game Currency</span
            ><span class="c1"
              >. You may purchase with real world currency on third-party
              platforms, or earn by playing the Game, the in-game currency,
              $BLOOD (&ldquo;</span
            ><span class="c2">Game Currency</span
            ><span class="c1"
              >&rdquo;). For the avoidance of doubt, Game Currency does not
              include $SUPER. We may also offer certain upgrades and options
              within our Game that you can buy with real world currency or the
              Game Currency, including, but not limited to, Genesis Land, UFOs,
              Pets, Cosmetics Boxes, Materials Boxes, Closed Beta Passes, and
              other Genesis Season Assets in the Game metaverse, and other such
              digital add-on items that may improve your Game experience in some
              way (&ldquo;</span
            ><span class="c2">Virtual Goods</span
            ><span class="c1"
              >&rdquo;). If you purchase Game Currency, Virtual Goods, or a
              Subscription (defined below) (each, a &ldquo;</span
            ><span class="c2">Transaction</span
            ><span class="c0"
              >&rdquo;), your purchase will be made through the functionality
              available through the App Store, Website or other platforms we
              make available to you. Prior to making a Transaction, you should
              make sure you fully understand the agreement that covers your
              Transaction, whether that agreement is an App Store Agreement,
              Website Terms or another third-party platform agreement (like a
              third-party payment processor&rsquo;s terms). There may be limits
              to the quantity and number of times you can purchase Virtual Goods
              or other aspects of your Transaction.
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1"
              >Your Use of Virtual Goods and Game Currency</span
            ><span class="c0"
              >. Virtual Goods and Game Currency are digital items and your use
              of them &nbsp;is governed by these Terms, the App Store
              Agreement(s), the Website Terms, the NFT Terms, or other
              third-party platform agreements, as applicable.
            </span>
          </h2>
        </li>
      </ol>
      <ol class="c12 lst-kix_list_1-2 start" start="1">
        <li class="c16 c27 c25">
          <h3 style="display: inline">
            <span class="c5"
              >Game Currency and Virtual Goods that are Transferable</span
            ><span class="c0"
              >. While the Game Currency and some of the Virtual Goods may be
              transferable outside of the Game, as communicated or shown to you
              within the functionality of the Game, we are not responsible for
              any use or transfer of the Game Currency and Virtual Goods outside
              of the functionality of the Game. You may be entitled to use the
              Game Currency and Virtual Goods with compatible third-party
              applications, but we expressly disclaim and do not make any
              warranty that the Game Currency and Virtual Goods will be
              compatible with such third-party applications. &nbsp;You further
              agree that you will not use or transfer the Game Currency and
              applicable Virtual Goods for inappropriate, harmful, or illegal
              purposes.
            </span>
          </h3>
        </li>
        <li class="c16 c25 c27">
          <h3 style="display: inline">
            <span class="c5">Virtual Goods that are Not Transferable</span
            ><span class="c0"
              >. SOME VIRTUAL GOODS, AS COMMUNICATED OR SHOWN TO YOU WITHIN THE
              FUNCTIONALITY OF THE GAME, HAVE NO MONETARY VALUE AND CANNOT BE
              REDEEMED FOR CASH. SOME VIRTUAL GOODS ARE NON-TRANSFERABLE AND
              NON-TRADABLE, IN WHOLE OR PART, AND ARE NOT PROVIDED FOR
              INVESTMENT PURPOSES. For such Virtual Goods, provided you comply
              with the terms of these Terms and the App Store Agreement(s) and
              Website Terms, we grant you the following license: a personal,
              non-exclusive, non-transferable, non-sublicensable limited right
              and license to use any Virtual Goods you gain access to, whether
              purchased or otherwise obtained, solely in connection with your
              use of the Game and within the Game and for no other purpose.
              &nbsp;Unless expressly permitted by us, you may not trade any such
              Virtual Good with others. We may cancel, revoke, or otherwise
              prevent the use of Virtual Good if we suspect any unauthorized or
              fraudulent activity, and/or to correct any erroneous application
              of any Virtual Goods to your Game Account.</span
            >
          </h3>
        </li>
      </ol>
      <ol class="c12 lst-kix_list_1-1" start="3">
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1"
              >Changes to Game Currency and Virtual Goods.</span
            ><span class="c1"
              >&nbsp;Except as otherwise prohibited by applicable law, we, in
              our sole discretion, may modify, substitute, replace, suspend,
              cancel or eliminate any Game Currency or Virtual Goods, including
              your ability to access or use Game Currency or Virtual Goods,
              without notice or liability to you. YOU AGREE THAT YOU WON&rsquo;T
              ASSERT OR BRING ANY CLAIM AGAINST US, OUR AFFILIATES, DIRECTORS,
              OFFICERS, EMPLOYEES, AGENTS, OR LICENSORS (THE &ldquo;</span
            ><span class="c2">SUPER STUDIOS PARTIES</span
            ><span class="c0"
              >&rdquo;) RELATING TO (A) A CLAIM THAT YOU HAVE A PROPRIETARY
              INTEREST IN ANY GAME CURRENCY OR VIRTUAL GOODS; OR (B) A CLAIM FOR
              AN ALLEGED MONETARY VALUE OF GAME CURRENCY OR VIRTUAL GOODS LOST
              UPON (I) DELETION OR SUSPENSION OF YOUR ACCOUNT, (II) ADJUSTMENTS
              TO THE GAME THAT RESULT IN THE VALUE OF VIRTUAL GOODS OR GAME
              CURRENCY CHANGING, OR (III) MODIFICATION, TERMINATION OR
              EXPIRATION OF THESE TERMS.
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Subscriptions</span
            ><span class="c1"
              >. Although certain features of the Game or the Services may be
              available at no charge, other features and/or Content may only be
              available via a subscription plan (&ldquo;</span
            ><span class="c2">Subscription</span
            ><span class="c0"
              >&rdquo;). Subscriptions, if offered, give full access to all the
              Subscription features as long as Subscription is active. If you
              purchase a Subscription, you must ensure all the information that
              you submit is true and accurate (including, without limitation,
              your credit/debit card number and expiration date, and other
              payment details) and that you have appropriate parental or
              guardian consent if you are under 18 years of age. You agree to
              pay all Subscription fees specified when you purchase a
              Subscription including all applicable taxes.</span
            >
          </h2>
        </li>
      </ol>
      <ol class="c12 lst-kix_list_1-0" start="8">
        <li class="c4">
          <h1 id="h.2s8eyo1" style="display: inline">
            <span class="c8 c2 c11 c9">Refunds</span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c0"
          >Subject to applicable law, (i) the Game and certain Virtual Goods, as
          communicated or shown to you within the functionality of the Game and
          except for those that are transferable outside of the Game, remain our
          property, have no monetary value and are not redeemable, refundable,
          or eligible for any other alternate remedy for any &ldquo;real
          world&rdquo; money or anything of monetary value, unless they are
          defective, unavailable, or do not perform in accordance with the
          specifications we provide; (ii) we may revoke your license to such
          Game or Virtual Goods at any time consistent with these Terms without
          notice or liability to you; and (iii) by purchasing and/or using any
          Game, Virtual Goods and/or Game Currency, if applicable, you hereby
          waive any statutory right you may have to withdraw from your agreement
          to purchase the applicable Game, Virtual and/or Game Currency and you
          agree that you will therefore not be eligible to receive a refund (or
          any alternative remedy) in relation to such Game, Virtual Good and/or
          Game Currency. Where applicable law does not permit you to waive your
          right to so withdraw, you hereby agree that such withdrawal right
          expires immediately upon purchase and/or delivery of your Game,
          Virtual Good and/or Game Currency, as permitted by applicable law.
        </span>
      </p>
      <ol class="c12 lst-kix_list_1-0" start="9">
        <li class="c4">
          <h1 id="h.17dp8vu" style="display: inline">
            <span class="c8 c2 c11 c9"
              >Accessing the Game from an App Store
            </span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c0"
          >Where the Game is made available to you via an App Store (like the
          Apple App Store or Google Play Store), whether or not on your mobile
          device, you acknowledge and agree that:
        </span>
      </p>
      <ul class="c12 lst-kix_list_6-0 start">
        <li class="c16 c19 li-bullet-0">
          <span class="c0"
            >These Terms are between you and us, not the App Store, and we (not
            the App Store), are solely responsible for the Game.</span
          >
        </li>
        <li class="c16 c19 li-bullet-0">
          <span class="c0"
            >The App Store has no obligation to provide any Game maintenance or
            support.</span
          >
        </li>
        <li class="c16 c19 li-bullet-0">
          <span class="c0"
            >If the Game cannot meet its warranties (if any), you can contact
            the App Store and they will refund you the purchase price of the
            Game (if applicable) and, to the maximum extent permitted by
            applicable law, the App Store will have no other warranty obligation
            whatsoever with respect to the Game. Any other claims, losses,
            liabilities, damages, costs or expenses attributable to any failure
            to conform to any warranty will be our sole responsibility.</span
          >
        </li>
        <li class="c16 c19 li-bullet-0">
          <span class="c0"
            >The App Store is not responsible for addressing any claims you have
            or any claims of any third party relating to the Game or your
            possession and use of the Game, including, without limitation:
            (i)&nbsp;product liability claims; (ii) any claim that the Game
            fails to conform to any applicable legal or regulatory requirement;
            and (iii) claims arising under consumer protection or similar
            legislation.</span
          >
        </li>
        <li class="c16 c19 li-bullet-0">
          <span class="c0"
            >In the event of any third-party claim that the Game or your
            possession and use of the Game infringes that third party&rsquo;s
            intellectual property rights, we will be solely responsible for the
            investigation, defense, settlement and discharge of any such
            intellectual property infringement claim to the extent required by
            these Terms.</span
          >
        </li>
        <li class="c16 c19 li-bullet-0">
          <span class="c0"
            >The App Store, and its subsidiaries, are third-party beneficiaries
            of these Terms and upon your acceptance of these Terms, the App
            Store will have the right to enforce these Terms against you as a
            third-party beneficiary thereof.</span
          >
        </li>
        <li class="c16 c19 li-bullet-0">
          <span class="c0"
            >You represent and warrant that (i) you are not located in a country
            that is subject to a U.S. Government embargo, or that has been
            designated by the U.S. Government as a terrorist-supporting country;
            and (ii) you are not listed on any U.S. Government list of
            prohibited or restricted parties.</span
          >
        </li>
        <li class="c16 c19 li-bullet-0">
          <span class="c0"
            >You must also comply with all applicable third-party terms of
            service when using the Game.</span
          >
        </li>
      </ul>
      <ol class="c12 lst-kix_list_1-0" start="10">
        <li class="c4">
          <h1 id="h.3rdcrjn" style="display: inline">
            <span class="c8 c2 c11 c9">Feedback</span>
          </h1>
        </li>
      </ol>
      <p class="c7" id="h.26in1rg">
        <span class="c1"
          >We welcome your feedback, comments and suggestions for improvements
          to the Services (&ldquo;</span
        ><span class="c2">Feedback</span
        ><span class="c0"
          >&rdquo;). You can submit Feedback by emailing us at
          support@superfarm.io or via the functionality of the Services (if
          available). If you provide us with any Feedback, you hereby grant us
          the following: a non-exclusive, transferable, worldwide, perpetual,
          irrevocable, fully-paid, royalty-free license, with the right to
          sublicense, under any and all intellectual property rights that you
          own or control to use, copy, modify, create derivative works based
          upon, distribute copies of, publicly perform, publicly display and
          otherwise exploit the Feedback for any purpose and in any country. If
          you have rights in the Feedback that cannot be licensed to us under
          applicable law (such as moral and other personal rights), you hereby
          waive and agree not to assert those rights. You understand and agree
          that you are freely giving your Feedback, that we don&rsquo;t have to
          use it, and that you will not be compensated in any way for your
          Feedback. You represent and warrant that you have rights in any
          Feedback that you provide to us sufficient to grant us and other
          affected parties the rights described above, including, without
          limitation, intellectual property rights or rights of publicity or
          privacy. &nbsp;
        </span>
      </p>
      <p class="c7">
        <span class="c0"
          >In posting such Feedback, you warrant that you will not use obscene
          or offensive language or submit any material that is, or may
          potentially be, defamatory, abusive or hateful, an invasion of
          anyone&#39;s privacy, harmful to other users, or breach the applicable
          laws. &nbsp;
        </span>
      </p>
      <ol class="c12 lst-kix_list_1-0" start="11">
        <li class="c4">
          <h1 id="h.lnxbz9" style="display: inline">
            <span class="c8 c2 c11 c9">Third Party Websites and Resources</span>
          </h1>
        </li>
      </ol>
      <p class="c7" id="h.35nkun2">
        <span class="c0"
          >The Services may contain links to third-party websites or resources.
          We provide these links only as a convenience and are not responsible
          for the content, products or services on or available from those
          websites or resources or links displayed on such websites. You
          acknowledge sole responsibility for and assume all risk arising from,
          your use of any third-party websites or resources.</span
        >
      </p>
      <ol class="c12 lst-kix_list_1-0" start="12">
        <li class="c4">
          <h1 id="h.1ksv4uv" style="display: inline">
            <span class="c1">Data Charges and Mobile Devices</span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c0"
          >You are responsible for all data-related charges that you may incur
          for using our Services, including, without limitation, mobile,
          text-messaging, and data charges. You should understand or ask your
          service provider what charges you may incur before using the
          Services.</span
        >
      </p>
      <ol class="c12 lst-kix_list_1-0" start="13">
        <li class="c4">
          <h1 id="h.44sinio" style="display: inline">
            <span class="c1">Service and Terms Modifications</span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c0"
          >We may update the Terms from time to time in our sole discretion. If
          we do, we&rsquo;ll let you know by posting the updated Terms on the
          Site, to the App and/or may also send other communications. It&rsquo;s
          important that you review the Terms whenever we update them or you use
          the Services. If you continue to use the Services after we have posted
          updated Terms, it means that you accept and agree to the changes.
          &nbsp;If you don&rsquo;t agree to be bound by the changes, you may not
          use the Services anymore. The only exception is for changes to the
          &ldquo;Dispute Resolution&rdquo; section, for which we will follow the
          process in Section 18. Because our Services are evolving over time we
          may change or discontinue all or any part of the Services, at any time
          and without notice, at our sole discretion.
        </span>
      </p>
      <p class="c7">
        <span class="c0"
          >We may provide patches, updates, or upgrades to the Services that
          must be installed in order for you to continue to use the Services. We
          may update the Services remotely without notifying you, and you hereby
          consent to us applying such patches, updates, and upgrades. If your
          device can prevent automatic updates, you may not be able to access
          the Services until you manually update the Services yourself on your
          device. We may modify, suspend, discontinue, substitute, replace, or
          limit your access to any aspect of the Services at any time. Subject
          to applicable law, you acknowledge that any character data, game
          progress, game customization or other data related to your use of any
          particular Game and other elements unique to the Services may cease to
          be available to you at any time without notice from us, including,
          without limitation, after a patch, update, or upgrade is applied by
          us. You agree that we do not have any maintenance or support
          obligations with respect to the Services.
        </span>
      </p>
      <p class="c7">
        <span class="c0"
          >Subject to applicable law, we may change the price of the Services,
          Game, Virtual Goods, Game Currency, Subscription, or Content, at any
          time, for any reason, without notice or liability to you.</span
        >
      </p>
      <ol class="c12 lst-kix_list_1-0" start="14">
        <li class="c4">
          <h1 id="h.2jxsxqh" style="display: inline">
            <span class="c8 c2 c11 c9">Warranty Disclaimers</span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c0"
          >TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICES ARE PROVIDED
          &ldquo;AS IS,&rdquo; WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING
          THE FOREGOING, THE SUPER STUDIOS PARTIES EXPLICITLY DISCLAIM ANY
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, QUIET ENJOYMENT, TITLE, AND NON-INFRINGEMENT, AND ANY
          WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. The
          Super Studios Parties make no warranty that the Services will meet
          your requirements or be available on an uninterrupted, secure, or
          error-free basis. The Super Studios Parties make no warranty regarding
          the quality, accuracy, timeliness, truthfulness, completeness or
          reliability of the Services.
        </span>
      </p>
      <h2 class="c16 c25">
        <span class="c0"
          >WE FURTHER EXPRESSLY DISCLAIM ALL LIABILITY OR RESPONSIBILITY IN
          CONNECTION WITH THIRD-PARTY SERVICES. NOTHING HEREIN NOR ANY USE OF
          OUR SERVICES IN CONNECTION WITH THIRD-PARTY SERVICES CONSTITUTES OUR
          ENDORSEMENT, RECOMMENDATION OR ANY OTHER AFFILIATION OF OR WITH ANY
          THIRD-PARTY SERVICES.
        </span>
      </h2>
      <h2 class="c16 c25">
        <span class="c0"
          >SUPER STUDIOS DOES NOT REPRESENT OR WARRANT THAT ANY CONTENT ON THE
          SERVICES IS ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. WE
          WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN OR
          TAKEN IN RELIANCE ON MATERIAL OR INFORMATION, CONTAINED ON THE
          SERVICES. WHILE SUPER STUDIOS ATTEMPTS TO MAKE YOUR ACCESS TO AND USE
          OF THE SERVICES AND ANY CONTENT THEREIN SAFE, SUPER STUDIOS CANNOT AND
          DOES NOT REPRESENT OR WARRANT THAT THE SERVICES, ANY CONTENT OR
          VIRTUAL GOODS THEREIN, OR OUR SERVERS ARE FREE OF VIRUSES OR OTHER
          HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT
          YOU DISCLOSE ONLINE. YOU ACCEPT THE INHERENT SECURITY RISKS OF
          PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL
          NOT HOLD US RESPONSIBLE FOR ANY BREACH OF SECURITY.</span
        >
      </h2>
      <h2 class="c16 c25">
        <span class="c0"
          >WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO
          RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF OR
          THE SERVICES, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR
          CLAIMS ARISING FROM: (I) USER ERROR SUCH AS FORGOTTEN PASSWORDS,
          INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED WALLET ADDRESSES;
          (II) SERVER FAILURE OR DATA LOSS; (III) BLOCKCHAIN NETWORKS,
          CRYPTOCURRENCY WALLETS OR CORRUPT FILES; (IV) UNAUTHORIZED ACCESS TO
          THE SERVICES; OR (V) ANY THIRD PARTY ACTIVITIES, INCLUDING WITHOUT
          LIMITATION THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS
          OF ATTACK.</span
        >
      </h2>
      <h2 class="c16 c25">
        <span class="c0"
          >THE SERVICES &nbsp;MAY NOT BE AVAILABLE DUE TO ANY NUMBER OF FACTORS
          INCLUDING, BUT NOT LIMITED TO, PERIODIC SYSTEM MAINTENANCE, SCHEDULED
          OR UNSCHEDULED, ACTS OF GOD, UNAUTHORIZED ACCESS, VIRUSES, DENIAL OF
          SERVICE &nbsp;OR OTHER ATTACKS, TECHNICAL FAILURE OF THE SERVICES
          AND/OR TELECOMMUNICATIONS INFRASTRUCTURE OR DISRUPTION, AND THEREFORE
          WE EXPRESSLY DISCLAIM ANY EXPRESS OR IMPLIED WARRANTY REGARDING THE
          USE AND/OR AVAILABILITY, ACCESSIBILITY, SECURITY OR PERFORMANCE OF THE
          SERVICES &nbsp;CAUSED BY SUCH FACTORS. &nbsp;WE DO NOT MAKE ANY
          REPRESENTATIONS OR WARRANTIES AGAINST THE POSSIBILITY OF DELETION,
          MISDELIVERY OR FAILURE TO STORE COMMUNICATIONS, PERSONALIZED SETTINGS
          OR OTHER DATA.</span
        >
      </h2>
      <h2 class="c16 c25">
        <span class="c0"
          >SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES.
          ACCORDINGLY, SOME OF THE ABOVE DISCLAIMERS OF WARRANTIES MAY NOT APPLY
          TO YOU.</span
        >
      </h2>
      <ol class="c12 lst-kix_list_1-0" start="15">
        <li class="c4">
          <h1 id="h.z337ya" style="display: inline">
            <span class="c8 c2 c11 c9">&nbsp;Limitation of Liability</span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c0"
          >TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW THE SUPER STUDIOS
          PARTIES WILL NOT BE LIABLE IN ANY WAY FOR ANY:
        </span>
      </p>
      <ol class="c12 lst-kix_list_1-1" start="5">
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0">LOSS OF PROFITS, </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0">LOST REVENUE, </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0">LOST SAVINGS, </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0">LOSS OF DATA, OR </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c0"
              >ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, OR
              EXEMPLARY DAMAGES,
            </span>
          </h2>
        </li>
      </ol>
      <p class="c7">
        <span class="c0"
          >ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR THE SERVICES, OR
          THE DELAY OR INABILITY TO USE OR LACK OF FUNCTIONALITY OF THE
          SERVICES, EVEN IN THE EVENT OF ONE OF AN SUPER STUDIOS PARTY&rsquo;S
          FAULT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, INDEMNITY,
          PRODUCT LIABILITY, BREACH OF CONTRACT, BREACH OF WARRANTY, OR
          OTHERWISE AND EVEN IF THAT SUPER STUDIOS PARTY HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. &nbsp;FURTHER, TO THE MAXIMUM EXTENT
          PERMITTED BY APPLICABLE LAW, THE AGGREGATE LIABILITY OF THE SUPER
          STUDIOS PARTIES ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR
          THE SERVICES WILL NOT EXCEED: (A) THE TOTAL AMOUNTS YOU HAVE PAID (IF
          ANY) OR ARE PAYABLE BY YOU TO US FOR THE GAME OR SERVICES IN QUESTION
          DURING THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE EVENTS GIVING RISE
          TO SUCH LIABILITY; OR (B) IF (A) DOES NOT APPLY, FIFTY DOLLARS ($50
          USD). THESE LIMITATIONS AND EXCLUSIONS REGARDING DAMAGES APPLY EVEN IF
          ANY REMEDY FAILS TO PROVIDE ADEQUATE COMPENSATION AND ARE FUNDAMENTAL
          ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN YOU AND US.
        </span>
      </p>
      <p class="c7">
        <span class="c0"
          >Notwithstanding the foregoing, some countries, states, provinces or
          other jurisdictions do not allow the exclusion of certain warranties
          or the limitation of liability as stated above, so the above terms may
          not apply to you. Instead, in such jurisdictions, the foregoing
          exclusions and limitations will apply to the maximum extent permitted
          by the laws of such jurisdictions. Also, you may have additional legal
          rights in your jurisdiction, and nothing in these Terms will prejudice
          such rights that you may have as a consumer of the Services.</span
        >
      </p>
      <ol class="c12 lst-kix_list_1-0" start="16">
        <li class="c4">
          <h1 id="h.3j2qqm3" style="display: inline">
            <span class="c8 c2 c11 c9">Indemnity</span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c0"
          >You agree to indemnify, defend, and hold the Super Studios Parties
          and their employees, officers, directors, agents, contractors, and
          other representatives harmless from all claims, demands, actions,
          losses, liabilities, costs and expenses (including, without
          limitation, attorneys&rsquo; fees, costs, and expert witnesses&rsquo;
          fees) that arise out of or in any way are connected with: (a) your
          access to or use of the Services; or (b) any claim that, if true,
          would constitute a breach by you of these Terms.
        </span>
      </p>
      <ol class="c12 lst-kix_list_1-0" start="17">
        <li class="c4">
          <h1 id="h.1y810tw" style="display: inline">
            <span class="c8 c2 c11 c9">Termination</span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c0"
          >We may suspend or terminate your access to and use of the Services,
          including any Game, Virtual Goods, and Content, with no liability or
          notice to you in the event that (a) we cease providing the Game to
          similarly situated users generally; (b) you breach these Terms
          (including the App Store Agreement(s), Website Terms and our other
          policies specified in these Terms); (c) the owner of the applicable
          App Store terminates your App Store Account; or (d) we otherwise deem
          it necessary to terminate these Terms in our sole discretion, for any
          reason. You may also terminate these Terms by deleting and
          uninstalling the Game on any and all of your devices or by deleting
          your App Store Account. Upon any termination of these Terms, the
          rights granted to you will automatically terminate, you may no longer
          exercise any of those rights or these Terms. &nbsp;Subject to
          applicable law, we may, in our sole discretion, provide continued
          access to and use of the Services prior to such termination.
        </span>
      </p>
      <p class="c7">
        <span class="c0"
          >Where required by applicable law, termination of these Terms do not
          require a court decision to effect termination or a notice served by a
          court bailiff as a prerequisite to termination.</span
        >
      </p>
      <p class="c7">
        <span class="c0"
          >Except to the extent required by law, all payments and fees are
          non-refundable under all circumstances, regardless of whether or not
          these Terms has been terminated.</span
        >
      </p>
      <p class="c7">
        <span class="c0"
          >The following sections will survive termination of these Terms: 6
          (first two sentences only), 10, 15, 16, 18 through 22, and this
          sentence of Section 17.</span
        >
      </p>
      <ol class="c12 lst-kix_list_1-0" start="18">
        <li class="c4">
          <h1 id="h.4i7ojhp" style="display: inline">
            <span class="c8 c2 c11 c9"
              >Dispute Resolution and Governing Law &ndash; United States
            </span>
          </h1>
        </li>
      </ol>
      <p class="c7" id="h.2xcytpi">
        <span class="c0"
          >This section applies if you are accessing, using, or have purchased
          the Services in the United States.</span
        >
      </p>
      <ol class="c12 lst-kix_list_1-1" start="10">
        <li class="c25 c30">
          <h2 id="h.1ci93xb" style="display: inline">
            <span class="c5 c1">Governing Law</span
            ><span class="c0"
              >. These Terms and any action related thereto will be governed by
              the Federal Arbitration Act, federal arbitration law, and the laws
              of the State of Delaware, without regard to its conflict of laws
              provisions. Except as otherwise expressly set forth in Section
              18(b)-(i) below, the exclusive jurisdiction for all Disputes
              (defined below) that you and Super Studios are not required to
              arbitrate will be the state and federal courts located in Los
              Angeles, California, and you and Super Studios each waive any
              objection to jurisdiction and venue in such courts.</span
            >
          </h2>
        </li>
      </ol>
      <h2 class="c25 c29"><span class="c0"></span></h2>
      <ol class="c12 lst-kix_list_1-1" start="11">
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">&nbsp;Mandatory Arbitration of Disputes</span
            ><span class="c1"
              >. We each agree that any dispute, claim or controversy arising
              out of or relating to these Terms or the breach, termination,
              enforcement, interpretation or validity thereof or the use of the
              Services (collectively, &ldquo;</span
            ><span class="c2">Disputes</span
            ><span class="c1">&rdquo;) will be resolved </span
            ><span class="c2"
              >solely by binding, individual arbitration and not in a class,
              representative or consolidated action or proceeding</span
            ><span class="c1"
              >. You and Super Studios agree that the U.S. Federal Arbitration
              Act governs the interpretation and enforcement of these Terms, and
              that you and Super Studios are each waiving the right to a trial
              by jury or to participate in a class action. This arbitration
              provision shall survive termination of these Terms.</span
            ><span class="c1 c14">&nbsp;</span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Exceptions</span
            ><span class="c0"
              >. As limited exceptions to Section 18(b) above: (i)&nbsp;we both
              may seek to resolve a Dispute in small claims court if it
              qualifies; and (ii) we each retain the right to seek injunctive or
              other equitable relief from a court to prevent (or enjoin) the
              infringement or misappropriation of our intellectual property
              rights.
            </span>
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1"
              >Conducting Arbitration and Arbitration Rules</span
            ><span class="c1"
              >. The arbitration will be conducted by the American Arbitration
              Association (&ldquo;</span
            ><span class="c2">AAA</span
            ><span class="c1"
              >&rdquo;) under its Consumer Arbitration Rules (the &ldquo;</span
            ><span class="c2">AAA Rules</span
            ><span class="c1"
              >&rdquo;) then in effect, except as modified by these Terms. The
              AAA Rules are available at </span
            ><span class="c5 c1 c10"
              ><a
                class="c20"
                href="http://www.adr.org"
                >www.adr.org</a
              ></span
            ><span class="c1"
              >&nbsp;or by calling 1-800-778-7879. A party who wishes to start
              arbitration must submit a written Demand for Arbitration to AAA
              and give notice to the other party as specified in the AAA Rules.
              The AAA provides a form Demand for Arbitration at </span
            ><span class="c5 c1 c10"
              ><a
                class="c20"
                href="http://www.adr.org"
                >www.adr.org</a
              ></span
            ><span class="c0">. </span>
          </h2>
        </li>
      </ol>
      <p class="c25 c34">
        <span class="c0"
          >Any arbitration hearings will take place in the county (or parish)
          where you live, unless we both agree to a different location. The
          parties agree that the arbitrator shall have exclusive authority to
          decide all issues relating to the interpretation, applicability,
          enforceability and scope of this arbitration agreement.</span
        >
      </p>
      <ol class="c12 lst-kix_list_1-1" start="14">
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Arbitration Costs</span
            ><span class="c0"
              >. Payment of all filing, administration and arbitrator fees will
              be governed by the AAA Rules, and we won&rsquo;t seek to recover
              the administration and arbitrator fees we are responsible for
              paying, unless the arbitrator finds your Dispute frivolous. If we
              prevail in arbitration we&rsquo;ll pay all of our attorneys&rsquo;
              fees and costs and won&rsquo;t seek to recover them from you. If
              you prevail in arbitration you will be entitled to an award of
              attorneys&rsquo; fees and expenses to the extent provided under
              applicable law.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Injunctive and Declaratory Relief</span
            ><span class="c0"
              >. Except as provided in Section 18(c) above, the arbitrator shall
              determine all issues of liability on the merits of any claim
              asserted by either party and may award declaratory or injunctive
              relief only in favor of the individual party seeking relief and
              only to the extent necessary to provide relief warranted by that
              party&#39;s individual claim. To the extent that you or we prevail
              on a claim and seek public injunctive relief (that is, injunctive
              relief that has the primary purpose and effect of prohibiting
              unlawful acts that threaten future injury to the public), the
              entitlement to and extent of such relief must be litigated in a
              civil court of competent jurisdiction and not in arbitration. The
              parties agree that litigation of any issues of public injunctive
              relief shall be stayed pending the outcome of the merits of any
              individual claims in arbitration.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Class Action Waiver</span
            ><span class="c1">. </span
            ><span class="c2"
              >YOU AND SUPER STUDIOS AGREE THAT EACH MAY BRING CLAIMS AGAINST
              THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
              PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
              PROCEEDING.</span
            ><span class="c0"
              >&nbsp; Further, if the parties&rsquo; dispute is resolved through
              arbitration, the arbitrator may not consolidate another
              person&#39;s claims with your claims and may not otherwise preside
              over any form of a representative or class proceeding. &nbsp;If
              this specific provision is found to be unenforceable, then the
              entirety of this Dispute Resolution section shall be null and
              void.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Effect of Changes on Arbitration</span
            ><span class="c1"
              >. Notwithstanding the provisions of Section 13 &ldquo;Service and
              Terms Modifications&rdquo; above, if Super Studios changes any of
              the terms of this Section 18 &ldquo;Dispute Resolution&rdquo;
              after the date you most recently accepted these Terms, you may
              reject any such change by sending us written notice (including by
              email to </span
            ><span class="c5 c1 c10"
              ><a class="c20" href="mailto:support@superfarm.io"
                >support@superfarm.io</a
              ></span
            ><span class="c1"
              >) within 30 days of the date such change became effective, as
              indicated in the &ldquo;Last Updated&rdquo; date above or in the
              date of Super Studios&rsquo; email to you notifying you of such
              change. By rejecting any change, you are agreeing that you will
              arbitrate any Dispute between you and Super Studios in accordance
              with the terms of this Section 18 &ldquo;Dispute Resolution&rdquo;
              as of the date you most recently accepted these Terms.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Severability</span
            ><span class="c1"
              >. With the exception of any of the provisions in Section 18(f) of
              these Terms (&quot;</span
            ><span class="c2">Class Action Waiver</span
            ><span class="c0"
              >&quot;), if an arbitrator or court of competent jurisdiction
              decides that any part of these Terms is invalid or unenforceable,
              the other parts of these Terms will still apply.</span
            >
          </h2>
        </li>
      </ol>
      <ol class="c12 lst-kix_list_1-0" start="19">
        <li class="c4">
          <h1 id="h.3whwml4" style="display: inline">
            <span class="c8 c2 c11 c9"
              >Dispute Resolution and Governing Law &ndash; Outside the United
              States</span
            >
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c0"
          >This section applies if you are accessing, using, or have purchased
          the Services outside of the United States. These Terms and any action
          related thereto will be governed by the laws of the State of Delaware,
          without regard to its conflict of laws provisions. The exclusive
          jurisdiction for all Disputes will be the state and federal courts
          located in Los Angeles, California, and you and Super Studios each
          waive any objection to jurisdiction and venue in such courts.</span
        >
      </p>
      <ol class="c12 lst-kix_list_1-0" start="20">
        <li class="c4">
          <h1 id="h.2bn6wsx" style="display: inline">
            <span class="c8 c2 c11 c9">No Assignment</span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c0"
          >You may not assign or transfer these Terms, by operation of law or
          otherwise, without our prior written consent. Any attempt by you to
          assign or transfer these Terms, without such consent, will be null and
          void. Notwithstanding the title of this Section, we may freely assign
          or transfer these Terms without restriction. Subject to the foregoing,
          these Terms will bind and inure to the benefit of the parties, their
          successors and permitted assigns.</span
        >
      </p>
      <ol class="c12 lst-kix_list_1-0" start="21">
        <li class="c4">
          <h1 id="h.qsh70q" style="display: inline">
            <span class="c8 c2 c11 c9">Miscellaneous</span>
          </h1>
        </li>
      </ol>
      <ol class="c12 lst-kix_list_1-1 start" start="1">
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Entire Agreement</span
            ><span class="c1 c6">. </span
            ><span class="c0"
              >These Terms and any other document or information referred to in
              these Terms constitutes the entire and exclusive understanding
              between you and us regarding the Services and supersede any and
              all prior oral or written understandings or agreements between you
              and us regarding the Services.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Language</span><span class="c1 c6">. </span
            ><span class="c0"
              >The original language of these Terms is in English; any
              translations are provided for reference purposes only. You waive
              any right you may have under the law of your country to have these
              Terms written or construed in any other language.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Severability</span><span class="c1 c6">. </span
            ><span class="c0"
              >These Terms describe certain legal rights. You may have other
              rights under the laws of your jurisdiction. These Terms do not
              change your rights under the laws of your jurisdiction if the laws
              of your jurisdiction do not permit it to do so. As noted above,
              limitations and exclusions of warranties and remedies in these
              Terms may not apply to you because your jurisdiction may not allow
              them in your particular circumstance. In the event that certain
              provisions of these Terms are held by a court or tribunal of
              competent jurisdiction to be unenforceable, those provisions will
              be enforced only to the furthest extent possible under applicable
              law and the remaining terms of these Terms will remain in full
              force and effect.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">No Waiver</span><span class="c1 c6">. </span
            ><span class="c1"
              >Your and our actions or inactions will not create any other
              rights under these Terms except as what is explicitly written
              within these Terms.</span
            ><span class="c1 c6">&nbsp;</span
            ><span class="c0"
              >Our failure to enforce any right or provision of these Terms will
              not be considered a waiver of such right or provision. The waiver
              of any such right or provision will be effective only if in
              writing and signed by one of our duly authorized representatives.
              Except as expressly set forth in these Terms, the exercise by
              either party of any of its remedies under these Terms will be
              without prejudice to its other remedies under these Terms or
              otherwise.</span
            >
          </h2>
        </li>
        <li class="c3">
          <h2 style="display: inline">
            <span class="c5 c1">Third-Party Rights</span
            ><span class="c1 c6">.</span
            ><span class="c0"
              >&nbsp;Except as described in Section 9, a person who is not a
              party to these Terms will have no right under to enforce any of
              its terms.</span
            >
          </h2>
        </li>
      </ol>
      <ol class="c12 lst-kix_list_1-0" start="22">
        <li class="c4">
          <h1 id="h.3as4poj" style="display: inline">
            <span class="c8 c2 c11 c9">Contact Information</span>
          </h1>
        </li>
      </ol>
      <p class="c24">
        <span class="c1"
          >If you have any questions about these Terms or the Game, please
          contact us at </span
        ><span class="c5 c10"
          ><a class="c20" href="mailto:support@superfarm.io"
            >support@superfarm.io</a
          ></span
        ><span>.</span>
      </p>
      <p class="c18"><span class="c0"></span></p>
      <p class="c18"><span class="c0"></span></p>
      <p class="c18"><span class="c0"></span></p>
      <p class="c18"><span class="c0"></span></p>
      <p class="c18"><span class="c0"></span></p>
      <p class="c18"><span class="c0"></span></p>
      <p class="c18"><span class="c0"></span></p>
      <p class="c18"><span class="c0"></span></p>
      <p class="c26" id="game-streaming">
        <span class="c8 c2 c11 c9">Impostors Game Streaming Policy</span>
      </p>
      <p class="c26"><span class="c0">Last updated: August 11, 2022</span></p>
      <p class="c24">
        <span>This Impostors Game Streaming Policy (this &ldquo;</span
        ><span class="c23">Streaming Policy</span
        ><span
          >&rdquo;) governs your production and/or streaming of Videos (as
          defined below) with respect to the Impostors game (the &ldquo;</span
        ><span class="c23">Impostors Game</span
        ><span>&rdquo;) owned and operated by Super Studios, Inc. (&ldquo;</span
        ><span class="c23">Super Studios</span
        ><span
          >&rdquo; or &ldquo;we&rdquo; and sometimes &ldquo;us&rdquo;) and
          anywhere else where this Streaming Policy is posted.</span
        >
      </p>
      <p class="c7">
        <span class="c5 c6">Introduction</span
        ><span class="c8 c1 c6 c9">. </span>
      </p>
      <p class="c7">
        <span
          >We hope that you enjoy the Impostors Game and support your interest
          in producing game-play videos, whether live or recorded (&ldquo;</span
        ><span class="c23">Videos</span><span>&rdquo;)</span
        ><span class="c21">,</span
        ><span
          >&nbsp;that share your gaming experiences with others, using images,
          art associated with non-fungible cryptocurrency tokens, video, sound
          effects, in-game music or other assets from our games (&ldquo;</span
        ><span class="c23">Content</span
        ><span class="c0"
          >&rdquo;). Please note however, that in most cases using our Content
          without our permission is illegal and a violation of our rights. This
          policy helps inform you of the limited rights we grant you to share
          your experience with our Content with the public in your Videos.</span
        >
      </p>
      <p class="c7">
        <span class="c5 c6"
          >Your use of our Content in Videos must be limited to non-commercial
          purposes, except as expressly stated under this Streaming Policy</span
        ><span class="c8 c1 c6 c9">: </span>
      </p>
      <p class="c7">
        <span class="c0"
          >You may only use our Content in your Videos for non-commercial uses,
          except as we expressly state under this Streaming Policy. As such, you
          may not license your Video which leverages our Content to any company
          or anyone else for a fee or other form of compensation or for any
          other commercial use without first receiving our written authorization
          to do so. Note that we reserve the right to use Videos for commercial
          purposes. Further, any of your Videos that leverage our Content must
          contain commentary, gameplay, or sufficient originality to make it, in
          our sole discretion, educational or promotional. Examples of Videos
          that would NOT qualify under this policy are clips of cut-scenes from
          games or recordings of a particular game&rsquo;s soundtrack (without
          any commentary discussing the cut-scene or soundtrack).</span
        >
      </p>
      <p class="c7">
        <span class="c0"
          >We do however permit you to receive payment based on the following
          two methods, provided you comply with the other portions of this
          Streaming Policy:</span
        >
      </p>
      <ul class="c12 lst-kix_list_26-0 start">
        <li class="c17 li-bullet-0">
          <span class="c0"
            >Monetization of your Video that leverages our Content through
            advertisements served by the platform which hosts your Video such as
            YouTube or Twitch (a &ldquo;</span
          ><span class="c8 c2 c11 c9">Platform</span
          ><span class="c0">&rdquo;).</span>
        </li>
        <li class="c7 c19 li-bullet-0">
          <span class="c0"
            >Receiving donations via a donation link posted on your profile or
            in the Video description on a Platform.</span
          >
        </li>
      </ul>
      <p class="c7">
        <span class="c5 c6">How you may distribute your Video</span
        ><span class="c8 c1 c6 c9">:</span>
      </p>
      <p class="c7">
        <span
          >Subject to the terms of the Impostors Game Terms of Service (the
          &ldquo;</span
        ><span class="c23">Terms</span
        ><span class="c0"
          >&rdquo;) and this Streaming Policy, you may create Videos using our
          Content, and you&rsquo;re free to distribute such Videos on websites
          where viewers are permitted to view these Videos without any charge of
          any kind. We understand that some websites may offer paid services.
          Provided that the website that hosts these Videos provides a free
          method to allow viewers to view them, you may distribute the Videos on
          that website.</span
        >
      </p>
      <p class="c7">
        <span class="c5 c6"
          >What you may not include in your Video that leverages our
          Content</span
        ><span class="c6">:</span>
      </p>
      <p class="c7">
        <span class="c0"
          >You may not include in any Video (nor anywhere linked near or on the
          same webpage as the Video) any content that is prohibited User Content
          under the Terms or any of the following:</span
        >
      </p>
      <ul class="c12 lst-kix_list_27-0 start">
        <li class="c17 li-bullet-0">
          <span class="c0"
            >Anything that could imply that the Video is produced by us or that
            we endorse you or your Video (unless you have an endorsement
            relationship with us as covered by a separate written or other
            agreement);</span
          >
        </li>
        <li class="c17 li-bullet-0">
          <span class="c0"
            >Any information related to cheats, hacks, exploits, bugs, or
            third-party programs, including links to any of the foregoing;
          </span>
        </li>
        <li class="c17 li-bullet-0">
          <span class="c0"
            >Uses of our Content that breach applicable law or are derogatory to
            us or that, in our discretion, may damage the value, goodwill, or
            reputation of us, our affiliates, our products, Content, or brands;
            or</span
          >
        </li>
        <li class="c7 c19 li-bullet-0">
          <span class="c0"
            >Any behavior or conduct that violates public morals and
            ethics.</span
          >
        </li>
      </ul>
      <p class="c7">
        <span class="c5 c6"
          >Ratings &ndash; keep your Videos appropriate as follows</span
        ><span class="c8 c1 c6 c9">: </span>
      </p>
      <p class="c7">
        <span class="c0"
          >Your Video should match the ratings guidelines for the game it
          relates to and must not in any event contain any content that would
          violate the &ldquo;T&rdquo; rating of the Entertainment Software
          Rating Board or the &ldquo;16&rdquo; rating of the Pan European Game
          Information.
        </span>
      </p>
      <p class="c7">
        <span class="c5 c6"
          >Disclosure &ndash; you must identify us as the copyright holder of
          our Content and disclaim our endorsement</span
        ><span class="c0">:</span>
      </p>
      <p class="c7">
        <span class="c0"
          >In any Video that leverages our Content that you provide, you must
          include a prominent disclaimer (either at the beginning of the Video
          or, if live-streaming, near the Video in a visible font) as
          follows:</span
        >
      </p>
      <ul class="c12 lst-kix_list_28-0 start">
        <li class="c7 c19 li-bullet-0">
          <span class="c0"
            >Portions of the content provided here, including trademarks and
            copyrights and any other intellectual property rights, are owned or
            held by Super Studios, Inc. and all rights in and to the same are
            reserved by Super Studios, Inc. This content is not official Super
            Studios, Inc. content and is not endorsed or approved by Super
            Studios, Inc.</span
          >
        </li>
      </ul>
      <p class="c7">
        <span class="c5 c6">Some additional important information</span
        ><span class="c8 c1 c6 c9">:</span>
      </p>
      <p class="c7">
        <span class="c0"
          >As solely determined by us, we may terminate your right to host,
          distribute or otherwise make available a Video that leverages our
          Content for business or other reasons without notice or liability to
          you. &nbsp;In such cases, we may (but do not have to) contact you or
          applicable websites or platforms regarding terminating such rights to
          any such Video.</span
        >
      </p>
      <p class="c24">
        <span class="c5 c6">Contact</span><span class="c0">:</span>
      </p>
      <p class="c24">
        <span
          >If you have any questions about this Streaming Policy or would like
          to seek permission for use of our Content in a manner outside this
          Streaming Policy, please contact us at </span
        ><span class="c5 c10"
          ><a class="c20" href="mailto:support@superfarm.io"
            >support@superfarm.io</a
          ></span
        ><span>.</span>
      </p>
      <p class="c18"><span class="c8 c1 c6 c9"></span></p>
      <div>
        <p class="c32"><span class="c0"></span></p>
        <p class="c33"><span class="c0"></span></p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.privacy-container {
  padding: 65px;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('https://impostors.s3.amazonaws.com/assets/space-background.webp');
  background-position: top;
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  width: 100%;

  @media (max-width: 650px) {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.privacy-text-title {
  margin-bottom: 20px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 43px;
  width: 100%;
  max-width: 1400px;
  /* identical to box height */
}

.privacy-text-container {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.8em;
  max-width: 1400px;
  width: 100%;

  @media (max-width: 650px) {
    font-size: 14px;

    & * {
      text-align: left !important;
    }
  }
}

a {
  color: var(--color-button-yellow);
}

.lst-kix_list_33-5 > li {
  counter-increment: lst-ctn-kix_list_33-5;
}

ol.lst-kix_list_7-0 {
  list-style-type: none;
}

ol.lst-kix_list_38-6 {
  list-style-type: none;
}

ol.lst-kix_list_38-5 {
  list-style-type: none;
}

ol.lst-kix_list_38-8 {
  list-style-type: none;
}

ol.lst-kix_list_33-5.start {
  counter-reset: lst-ctn-kix_list_33-5 0;
}

ol.lst-kix_list_9-0.start {
  counter-reset: lst-ctn-kix_list_9-0 0;
}

ol.lst-kix_list_38-7 {
  list-style-type: none;
}

ol.lst-kix_list_38-2 {
  list-style-type: none;
}

ol.lst-kix_list_38-1 {
  list-style-type: none;
}

ol.lst-kix_list_38-4 {
  list-style-type: none;
}

ol.lst-kix_list_30-0.start {
  counter-reset: lst-ctn-kix_list_30-0 0;
}

ol.lst-kix_list_38-3 {
  list-style-type: none;
}

ol.lst-kix_list_38-0 {
  list-style-type: none;
}

ol.lst-kix_list_13-4.start {
  counter-reset: lst-ctn-kix_list_13-4 0;
}

.lst-kix_list_30-6 > li {
  counter-increment: lst-ctn-kix_list_30-6;
}

ol.lst-kix_list_40-3.start {
  counter-reset: lst-ctn-kix_list_40-3 0;
}

.lst-kix_list_13-0 > li {
  counter-increment: lst-ctn-kix_list_13-0;
}

ol.lst-kix_list_20-2.start {
  counter-reset: lst-ctn-kix_list_20-2 0;
}

ol.lst-kix_list_37-2.start {
  counter-reset: lst-ctn-kix_list_37-2 0;
}

ol.lst-kix_list_7-5 {
  list-style-type: none;
}

ol.lst-kix_list_7-6 {
  list-style-type: none;
}

ol.lst-kix_list_7-7 {
  list-style-type: none;
}

ol.lst-kix_list_7-8 {
  list-style-type: none;
}

ol.lst-kix_list_7-1 {
  list-style-type: none;
}

ol.lst-kix_list_7-2 {
  list-style-type: none;
}

ol.lst-kix_list_7-3 {
  list-style-type: none;
}

ol.lst-kix_list_7-4 {
  list-style-type: none;
}

ol.lst-kix_list_23-2.start {
  counter-reset: lst-ctn-kix_list_23-2 0;
}

.lst-kix_list_40-1 > li {
  counter-increment: lst-ctn-kix_list_40-1;
}

.lst-kix_list_38-6 > li {
  counter-increment: lst-ctn-kix_list_38-6;
}

ol.lst-kix_list_17-1.start {
  counter-reset: lst-ctn-kix_list_17-1 0;
}

.lst-kix_list_35-7 > li {
  counter-increment: lst-ctn-kix_list_35-7;
}

.lst-kix_list_29-8 > li {
  counter-increment: lst-ctn-kix_list_29-8;
}

.lst-kix_list_24-7 > li {
  counter-increment: lst-ctn-kix_list_24-7;
}

ol.lst-kix_list_30-5.start {
  counter-reset: lst-ctn-kix_list_30-5 0;
}

.lst-kix_list_18-8 > li {
  counter-increment: lst-ctn-kix_list_18-8;
}

ol.lst-kix_list_8-8.start {
  counter-reset: lst-ctn-kix_list_8-8 0;
}

.lst-kix_list_7-2 > li {
  counter-increment: lst-ctn-kix_list_7-2;
}

.lst-kix_list_32-8 > li {
  counter-increment: lst-ctn-kix_list_32-8;
}

ol.lst-kix_list_36-5.start {
  counter-reset: lst-ctn-kix_list_36-5 0;
}

.lst-kix_list_24-7 > li:before {
  content: '' counter(lst-ctn-kix_list_24-7, lower-latin) '. ';
}

.lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}

ul.lst-kix_list_27-0 {
  list-style-type: none;
}

ul.lst-kix_list_27-1 {
  list-style-type: none;
}

.lst-kix_list_24-8 > li:before {
  content: '' counter(lst-ctn-kix_list_24-8, lower-roman) '. ';
}

ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}

ul.lst-kix_list_27-2 {
  list-style-type: none;
}

ol.lst-kix_list_9-5.start {
  counter-reset: lst-ctn-kix_list_9-5 0;
}

ol.lst-kix_list_40-8.start {
  counter-reset: lst-ctn-kix_list_40-8 0;
}

.lst-kix_list_24-2 > li:before {
  content: '' counter(lst-ctn-kix_list_24-2, lower-roman) '. ';
}

ol.lst-kix_list_20-7.start {
  counter-reset: lst-ctn-kix_list_20-7 0;
}

.lst-kix_list_24-3 > li:before {
  content: '' counter(lst-ctn-kix_list_24-3, decimal) '. ';
}

.lst-kix_list_24-4 > li:before {
  content: '' counter(lst-ctn-kix_list_24-4, lower-latin) '. ';
}

ol.lst-kix_list_34-2.start {
  counter-reset: lst-ctn-kix_list_34-2 0;
}

.lst-kix_list_9-4 > li {
  counter-increment: lst-ctn-kix_list_9-4;
}

ul.lst-kix_list_27-3 {
  list-style-type: none;
}

.lst-kix_list_24-5 > li:before {
  content: '' counter(lst-ctn-kix_list_24-5, lower-roman) '. ';
}

ul.lst-kix_list_27-4 {
  list-style-type: none;
}

ul.lst-kix_list_27-5 {
  list-style-type: none;
}

ul.lst-kix_list_27-6 {
  list-style-type: none;
}

ul.lst-kix_list_27-7 {
  list-style-type: none;
}

ul.lst-kix_list_27-8 {
  list-style-type: none;
}

.lst-kix_list_24-6 > li:before {
  content: '' counter(lst-ctn-kix_list_24-6, decimal) '. ';
}

ol.lst-kix_list_14-1.start {
  counter-reset: lst-ctn-kix_list_14-1 0;
}

.lst-kix_list_23-6 > li:before {
  content: '' counter(lst-ctn-kix_list_23-6, decimal) '. ';
}

.lst-kix_list_23-3 > li:before {
  content: '' counter(lst-ctn-kix_list_23-3, decimal) '. ';
}

.lst-kix_list_23-7 > li:before {
  content: '' counter(lst-ctn-kix_list_23-7, decimal) '. ';
}

.lst-kix_list_23-2 > li:before {
  content: '' counter(lst-ctn-kix_list_23-2, decimal) '. ';
}

ul.lst-kix_list_16-2 {
  list-style-type: none;
}

ol.lst-kix_list_23-7.start {
  counter-reset: lst-ctn-kix_list_23-7 0;
}

ul.lst-kix_list_16-1 {
  list-style-type: none;
}

ul.lst-kix_list_16-0 {
  list-style-type: none;
}

.lst-kix_list_23-0 > li:before {
  content: '' counter(lst-ctn-kix_list_23-0, decimal) '. ';
}

.lst-kix_list_23-8 > li:before {
  content: '' counter(lst-ctn-kix_list_23-8, decimal) '. ';
}

.lst-kix_list_23-1 > li:before {
  content: '' counter(lst-ctn-kix_list_23-1, decimal) '. ';
}

.lst-kix_list_24-1 > li:before {
  content: '' counter(lst-ctn-kix_list_24-1, lower-latin) '. ';
}

ul.lst-kix_list_16-8 {
  list-style-type: none;
}

ul.lst-kix_list_16-7 {
  list-style-type: none;
}

ul.lst-kix_list_16-6 {
  list-style-type: none;
}

ul.lst-kix_list_16-5 {
  list-style-type: none;
}

.lst-kix_list_24-0 > li:before {
  content: '' counter(lst-ctn-kix_list_24-0, decimal) '. ';
}

ul.lst-kix_list_16-4 {
  list-style-type: none;
}

ul.lst-kix_list_16-3 {
  list-style-type: none;
}

.lst-kix_list_23-4 > li:before {
  content: '' counter(lst-ctn-kix_list_23-4, decimal) '. ';
}

.lst-kix_list_23-5 > li:before {
  content: '' counter(lst-ctn-kix_list_23-5, decimal) '. ';
}

ol.lst-kix_list_31-2.start {
  counter-reset: lst-ctn-kix_list_31-2 0;
}

.lst-kix_list_22-2 > li:before {
  content: '' counter(lst-ctn-kix_list_22-2, decimal) '. ';
}

.lst-kix_list_22-6 > li:before {
  content: '' counter(lst-ctn-kix_list_22-6, decimal) '. ';
}

ol.lst-kix_list_36-0.start {
  counter-reset: lst-ctn-kix_list_36-0 0;
}

.lst-kix_list_8-6 > li {
  counter-increment: lst-ctn-kix_list_8-6;
}

.lst-kix_list_22-0 > li:before {
  content: '' counter(lst-ctn-kix_list_22-0, decimal) '. ';
}

.lst-kix_list_22-8 > li:before {
  content: '' counter(lst-ctn-kix_list_22-8, decimal) '. ';
}

ol.lst-kix_list_9-7 {
  list-style-type: none;
}

ol.lst-kix_list_9-8 {
  list-style-type: none;
}

ol.lst-kix_list_22-5.start {
  counter-reset: lst-ctn-kix_list_22-5 0;
}

ol.lst-kix_list_39-5.start {
  counter-reset: lst-ctn-kix_list_39-5 0;
}

ol.lst-kix_list_9-3 {
  list-style-type: none;
}

ol.lst-kix_list_9-4 {
  list-style-type: none;
}

ol.lst-kix_list_9-5 {
  list-style-type: none;
}

ol.lst-kix_list_34-7.start {
  counter-reset: lst-ctn-kix_list_34-7 0;
}

ol.lst-kix_list_9-6 {
  list-style-type: none;
}

ol.lst-kix_list_9-0 {
  list-style-type: none;
}

ol.lst-kix_list_9-1 {
  list-style-type: none;
}

ol.lst-kix_list_9-2 {
  list-style-type: none;
}

.lst-kix_list_22-4 > li:before {
  content: '' counter(lst-ctn-kix_list_22-4, decimal) '. ';
}

ol.lst-kix_list_32-8.start {
  counter-reset: lst-ctn-kix_list_32-8 0;
}

ol.lst-kix_list_29-2.start {
  counter-reset: lst-ctn-kix_list_29-2 0;
}

.lst-kix_list_25-5 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_25-7 > li:before {
  content: 'o  ';
}

ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}

ol.lst-kix_list_18-3.start {
  counter-reset: lst-ctn-kix_list_18-3 0;
}

ol.lst-kix_list_24-4.start {
  counter-reset: lst-ctn-kix_list_24-4 0;
}

ol.lst-kix_list_18-7 {
  list-style-type: none;
}

ol.lst-kix_list_18-8 {
  list-style-type: none;
}

ol.lst-kix_list_33-0.start {
  counter-reset: lst-ctn-kix_list_33-0 0;
}

ol.lst-kix_list_18-3 {
  list-style-type: none;
}

ol.lst-kix_list_18-4 {
  list-style-type: none;
}

ol.lst-kix_list_18-5 {
  list-style-type: none;
}

ol.lst-kix_list_18-6 {
  list-style-type: none;
}

.lst-kix_list_40-7 > li:before {
  content: '' counter(lst-ctn-kix_list_40-7, lower-latin) ') ';
}

ol.lst-kix_list_18-0 {
  list-style-type: none;
}

ol.lst-kix_list_18-1 {
  list-style-type: none;
}

.lst-kix_list_9-3 > li {
  counter-increment: lst-ctn-kix_list_9-3;
}

ol.lst-kix_list_18-2 {
  list-style-type: none;
}

.lst-kix_list_40-5 > li:before {
  content: '' counter(lst-ctn-kix_list_40-5, lower-roman) '. ';
}

ol.lst-kix_list_15-2.start {
  counter-reset: lst-ctn-kix_list_15-2 0;
}

ol.lst-kix_list_37-7.start {
  counter-reset: lst-ctn-kix_list_37-7 0;
}

ol.lst-kix_list_7-6.start {
  counter-reset: lst-ctn-kix_list_7-6 0;
}

ol.lst-kix_list_29-8 {
  list-style-type: none;
}

ol.lst-kix_list_29-7 {
  list-style-type: none;
}

ol.lst-kix_list_15-3.start {
  counter-reset: lst-ctn-kix_list_15-3 0;
}

ol.lst-kix_list_29-4 {
  list-style-type: none;
}

ol.lst-kix_list_29-3 {
  list-style-type: none;
}

ol.lst-kix_list_29-6 {
  list-style-type: none;
}

ol.lst-kix_list_29-5 {
  list-style-type: none;
}

ol.lst-kix_list_29-0 {
  list-style-type: none;
}

ol.lst-kix_list_29-2 {
  list-style-type: none;
}

ol.lst-kix_list_29-1 {
  list-style-type: none;
}

.lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}

.lst-kix_list_40-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_40-3, decimal) ') ';
}

ol.lst-kix_list_12-2.start {
  counter-reset: lst-ctn-kix_list_12-2 0;
}

.lst-kix_list_40-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_40-1, lower-latin) ') ';
}

ol.lst-kix_list_38-3.start {
  counter-reset: lst-ctn-kix_list_38-3 0;
}

.lst-kix_list_24-8 > li {
  counter-increment: lst-ctn-kix_list_24-8;
}

ol.lst-kix_list_19-0.start {
  counter-reset: lst-ctn-kix_list_19-0 11;
}

ol.lst-kix_list_34-6 {
  list-style-type: none;
}

ol.lst-kix_list_34-5 {
  list-style-type: none;
}

ol.lst-kix_list_34-8 {
  list-style-type: none;
}

.lst-kix_list_7-1 > li {
  counter-increment: lst-ctn-kix_list_7-1;
}

ol.lst-kix_list_34-7 {
  list-style-type: none;
}

.lst-kix_list_38-5 > li {
  counter-increment: lst-ctn-kix_list_38-5;
}

ol.lst-kix_list_34-2 {
  list-style-type: none;
}

ol.lst-kix_list_34-1 {
  list-style-type: none;
}

ol.lst-kix_list_34-4 {
  list-style-type: none;
}

ol.lst-kix_list_34-3 {
  list-style-type: none;
}

ul.lst-kix_list_5-7 {
  list-style-type: none;
}

ul.lst-kix_list_5-8 {
  list-style-type: none;
}

ul.lst-kix_list_5-5 {
  list-style-type: none;
}

ol.lst-kix_list_34-0 {
  list-style-type: none;
}

ul.lst-kix_list_5-6 {
  list-style-type: none;
}

ol.lst-kix_list_32-4.start {
  counter-reset: lst-ctn-kix_list_32-4 0;
}

.lst-kix_list_21-8 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_26-5 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_8-0 > li {
  counter-increment: lst-ctn-kix_list_8-0;
}

ul.lst-kix_list_5-0 {
  list-style-type: none;
}

ol.lst-kix_list_18-2.start {
  counter-reset: lst-ctn-kix_list_18-2 0;
}

ul.lst-kix_list_5-3 {
  list-style-type: none;
}

ul.lst-kix_list_5-4 {
  list-style-type: none;
}

.lst-kix_list_26-8 > li:before {
  content: '\0025aa  ';
}

ul.lst-kix_list_5-1 {
  list-style-type: none;
}

ul.lst-kix_list_5-2 {
  list-style-type: none;
}

.lst-kix_list_21-0 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_26-1 > li:before {
  content: 'o  ';
}

.lst-kix_list_13-1 > li {
  counter-increment: lst-ctn-kix_list_13-1;
}

.lst-kix_list_21-1 > li:before {
  content: 'o  ';
}

ol.lst-kix_list_15-8.start {
  counter-reset: lst-ctn-kix_list_15-8 0;
}

.lst-kix_list_34-3 > li {
  counter-increment: lst-ctn-kix_list_34-3;
}

.lst-kix_list_26-4 > li:before {
  content: 'o  ';
}

ol.lst-kix_list_36-1.start {
  counter-reset: lst-ctn-kix_list_36-1 0;
}

.lst-kix_list_21-5 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_21-4 > li:before {
  content: 'o  ';
}

.lst-kix_list_26-0 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_31-4 > li {
  counter-increment: lst-ctn-kix_list_31-4;
}

ol.lst-kix_list_7-2.start {
  counter-reset: lst-ctn-kix_list_7-2 0;
}

.lst-kix_list_31-2 > li {
  counter-increment: lst-ctn-kix_list_31-2;
}

ol.lst-kix_list_38-8.start {
  counter-reset: lst-ctn-kix_list_38-8 0;
}

ol.lst-kix_list_19-5.start {
  counter-reset: lst-ctn-kix_list_19-5 0;
}

ol.lst-kix_list_12-5 {
  list-style-type: none;
}

ol.lst-kix_list_12-6 {
  list-style-type: none;
}

ol.lst-kix_list_12-7 {
  list-style-type: none;
}

ol.lst-kix_list_12-8 {
  list-style-type: none;
}

ol.lst-kix_list_12-1 {
  list-style-type: none;
}

ol.lst-kix_list_12-2 {
  list-style-type: none;
}

ol.lst-kix_list_12-3 {
  list-style-type: none;
}

.lst-kix_list_35-8 > li {
  counter-increment: lst-ctn-kix_list_35-8;
}

ol.lst-kix_list_12-4 {
  list-style-type: none;
}

ol.lst-kix_list_39-6.start {
  counter-reset: lst-ctn-kix_list_39-6 0;
}

ol.lst-kix_list_12-0 {
  list-style-type: none;
}

.lst-kix_list_25-1 > li:before {
  content: 'o  ';
}

.lst-kix_list_25-0 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_31-1.start {
  counter-reset: lst-ctn-kix_list_31-1 0;
}

ol.lst-kix_list_7-1.start {
  counter-reset: lst-ctn-kix_list_7-1 0;
}

.lst-kix_list_39-0 > li:before {
  content: '' counter(lst-ctn-kix_list_39-0, decimal) '. ';
}

ol.lst-kix_list_23-6 {
  list-style-type: none;
}

ol.lst-kix_list_23-5 {
  list-style-type: none;
}

ol.lst-kix_list_23-8 {
  list-style-type: none;
}

ol.lst-kix_list_23-7 {
  list-style-type: none;
}

.lst-kix_list_39-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_39-1, lower-latin) ') ';
}

ol.lst-kix_list_40-7.start {
  counter-reset: lst-ctn-kix_list_40-7 0;
}

ol.lst-kix_list_23-2 {
  list-style-type: none;
}

.lst-kix_list_37-0 > li {
  counter-increment: lst-ctn-kix_list_37-0;
}

ol.lst-kix_list_23-1 {
  list-style-type: none;
}

ol.lst-kix_list_23-4 {
  list-style-type: none;
}

ol.lst-kix_list_23-3 {
  list-style-type: none;
}

ol.lst-kix_list_23-0 {
  list-style-type: none;
}

ol.lst-kix_list_20-6.start {
  counter-reset: lst-ctn-kix_list_20-6 0;
}

.lst-kix_list_34-1 > li {
  counter-increment: lst-ctn-kix_list_34-1;
}

.lst-kix_list_13-8 > li {
  counter-increment: lst-ctn-kix_list_13-8;
}

.lst-kix_list_38-7 > li {
  counter-increment: lst-ctn-kix_list_38-7;
}

.lst-kix_list_40-8 > li {
  counter-increment: lst-ctn-kix_list_40-8;
}

.lst-kix_list_22-5 > li:before {
  content: '' counter(lst-ctn-kix_list_22-5, decimal) '. ';
}

ol.lst-kix_list_36-8 {
  list-style-type: none;
}

ol.lst-kix_list_36-7 {
  list-style-type: none;
}

ol.lst-kix_list_36-4 {
  list-style-type: none;
}

ol.lst-kix_list_36-3 {
  list-style-type: none;
}

ol.lst-kix_list_36-6 {
  list-style-type: none;
}

ol.lst-kix_list_36-5 {
  list-style-type: none;
}

.lst-kix_list_22-1 > li:before {
  content: '' counter(lst-ctn-kix_list_22-1, decimal) '. ';
}

ol.lst-kix_list_36-0 {
  list-style-type: none;
}

.lst-kix_list_27-4 > li:before {
  content: 'o  ';
}

.lst-kix_list_20-2 > li {
  counter-increment: lst-ctn-kix_list_20-2;
}

ol.lst-kix_list_36-2 {
  list-style-type: none;
}

ol.lst-kix_list_15-7.start {
  counter-reset: lst-ctn-kix_list_15-7 0;
}

ol.lst-kix_list_36-1 {
  list-style-type: none;
}

.lst-kix_list_23-3 > li {
  counter-increment: lst-ctn-kix_list_23-3;
}

.lst-kix_list_13-6 > li {
  counter-increment: lst-ctn-kix_list_13-6;
}

ol.lst-kix_list_14-6.start {
  counter-reset: lst-ctn-kix_list_14-6 0;
}

.lst-kix_list_39-4 > li:before {
  content: '' counter(lst-ctn-kix_list_39-4, lower-latin) '. ';
}

.lst-kix_list_39-8 > li:before {
  content: '' counter(lst-ctn-kix_list_39-8, lower-roman) ') ';
}

.lst-kix_list_8-7 > li {
  counter-increment: lst-ctn-kix_list_8-7;
}

.lst-kix_list_19-6 > li {
  counter-increment: lst-ctn-kix_list_19-6;
}

.lst-kix_list_27-0 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_20-4 > li {
  counter-increment: lst-ctn-kix_list_20-4;
}

.lst-kix_list_9-5 > li {
  counter-increment: lst-ctn-kix_list_9-5;
}

ol.lst-kix_list_19-4.start {
  counter-reset: lst-ctn-kix_list_19-4 0;
}

.lst-kix_list_38-0 > li {
  counter-increment: lst-ctn-kix_list_38-0;
}

.lst-kix_list_32-0 > li {
  counter-increment: lst-ctn-kix_list_32-0;
}

ol.lst-kix_list_33-6.start {
  counter-reset: lst-ctn-kix_list_33-6 0;
}

ol.lst-kix_list_20-1.start {
  counter-reset: lst-ctn-kix_list_20-1 0;
}

.lst-kix_list_25-4 > li:before {
  content: 'o  ';
}

.lst-kix_list_19-4 > li {
  counter-increment: lst-ctn-kix_list_19-4;
}

.lst-kix_list_35-1 > li {
  counter-increment: lst-ctn-kix_list_35-1;
}

.lst-kix_list_24-1 > li {
  counter-increment: lst-ctn-kix_list_24-1;
}

.lst-kix_list_25-8 > li:before {
  content: '\0025aa  ';
}

ol.lst-kix_list_38-4.start {
  counter-reset: lst-ctn-kix_list_38-4 0;
}

ul.lst-kix_list_25-0 {
  list-style-type: none;
}

ol.lst-kix_list_14-7 {
  list-style-type: none;
}

ul.lst-kix_list_25-1 {
  list-style-type: none;
}

ol.lst-kix_list_14-8 {
  list-style-type: none;
}

ul.lst-kix_list_25-2 {
  list-style-type: none;
}

ul.lst-kix_list_25-3 {
  list-style-type: none;
}

ul.lst-kix_list_25-4 {
  list-style-type: none;
}

.lst-kix_list_37-2 > li {
  counter-increment: lst-ctn-kix_list_37-2;
}

ol.lst-kix_list_14-3 {
  list-style-type: none;
}

ol.lst-kix_list_14-4 {
  list-style-type: none;
}

ol.lst-kix_list_14-5 {
  list-style-type: none;
}

ol.lst-kix_list_14-6 {
  list-style-type: none;
}

.lst-kix_list_34-8 > li {
  counter-increment: lst-ctn-kix_list_34-8;
}

.lst-kix_list_15-2 > li {
  counter-increment: lst-ctn-kix_list_15-2;
}

ol.lst-kix_list_14-0 {
  list-style-type: none;
}

.lst-kix_list_22-5 > li {
  counter-increment: lst-ctn-kix_list_22-5;
}

ol.lst-kix_list_14-1 {
  list-style-type: none;
}

.lst-kix_list_40-6 > li:before {
  content: '' counter(lst-ctn-kix_list_40-6, decimal) ') ';
}

ol.lst-kix_list_14-2 {
  list-style-type: none;
}

.lst-kix_list_20-5 > li:before {
  content: '' counter(lst-ctn-kix_list_20-5, decimal) '. ';
}

.lst-kix_list_28-8 > li:before {
  content: '\0025aa  ';
}

ul.lst-kix_list_25-5 {
  list-style-type: none;
}

ul.lst-kix_list_25-6 {
  list-style-type: none;
}

.lst-kix_list_20-1 > li:before {
  content: '' counter(lst-ctn-kix_list_20-1, decimal) '. ';
}

ul.lst-kix_list_25-7 {
  list-style-type: none;
}

ul.lst-kix_list_25-8 {
  list-style-type: none;
}

.lst-kix_list_12-3 > li {
  counter-increment: lst-ctn-kix_list_12-3;
}

.lst-kix_list_24-6 > li {
  counter-increment: lst-ctn-kix_list_24-6;
}

.lst-kix_list_17-3 > li {
  counter-increment: lst-ctn-kix_list_17-3;
}

ol.lst-kix_list_8-4.start {
  counter-reset: lst-ctn-kix_list_8-4 0;
}

.lst-kix_list_28-4 > li:before {
  content: 'o  ';
}

.lst-kix_list_39-3 > li {
  counter-increment: lst-ctn-kix_list_39-3;
}

.lst-kix_list_35-6 > li {
  counter-increment: lst-ctn-kix_list_35-6;
}

ol.lst-kix_list_13-0.start {
  counter-reset: lst-ctn-kix_list_13-0 0;
}

.lst-kix_list_14-4 > li {
  counter-increment: lst-ctn-kix_list_14-4;
}

ol.lst-kix_list_32-3.start {
  counter-reset: lst-ctn-kix_list_32-3 0;
}

.lst-kix_list_18-1 > li {
  counter-increment: lst-ctn-kix_list_18-1;
}

ol.lst-kix_list_8-3.start {
  counter-reset: lst-ctn-kix_list_8-3 0;
}

ol.lst-kix_list_14-5.start {
  counter-reset: lst-ctn-kix_list_14-5 0;
}

.lst-kix_list_28-0 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_32-7 > li {
  counter-increment: lst-ctn-kix_list_32-7;
}

.lst-kix_list_27-8 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_40-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_40-2, lower-roman) ') ';
}

ol.lst-kix_list_20-3.start {
  counter-reset: lst-ctn-kix_list_20-3 0;
}

ol.lst-kix_list_37-1.start {
  counter-reset: lst-ctn-kix_list_37-1 0;
}

.lst-kix_list_36-4 > li {
  counter-increment: lst-ctn-kix_list_36-4;
}

.lst-kix_list_29-1 > li {
  counter-increment: lst-ctn-kix_list_29-1;
}

ol.lst-kix_list_32-7.start {
  counter-reset: lst-ctn-kix_list_32-7 0;
}

.lst-kix_list_19-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_19-1, lower-latin) ') ';
}

.lst-kix_list_19-4 > li:before {
  content: '' counter(lst-ctn-kix_list_19-4, lower-latin) '. ';
}

ol.lst-kix_list_37-8.start {
  counter-reset: lst-ctn-kix_list_37-8 0;
}

.lst-kix_list_31-5 > li {
  counter-increment: lst-ctn-kix_list_31-5;
}

.lst-kix_list_19-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_19-3, decimal) ') ';
}

ol.lst-kix_list_38-0.start {
  counter-reset: lst-ctn-kix_list_38-0 0;
}

.lst-kix_list_15-0 > li {
  counter-increment: lst-ctn-kix_list_15-0;
}

ol.lst-kix_list_24-5.start {
  counter-reset: lst-ctn-kix_list_24-5 0;
}

.lst-kix_list_39-5 > li {
  counter-increment: lst-ctn-kix_list_39-5;
}

.lst-kix_list_32-6 > li {
  counter-increment: lst-ctn-kix_list_32-6;
}

ol.lst-kix_list_29-6.start {
  counter-reset: lst-ctn-kix_list_29-6 0;
}

ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}

ol.lst-kix_list_9-6.start {
  counter-reset: lst-ctn-kix_list_9-6 0;
}

ol.lst-kix_list_22-4.start {
  counter-reset: lst-ctn-kix_list_22-4 0;
}

.lst-kix_list_22-7 > li {
  counter-increment: lst-ctn-kix_list_22-7;
}

.lst-kix_list_37-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_37-0, lower-latin) ') ';
}

.lst-kix_list_33-7 > li {
  counter-increment: lst-ctn-kix_list_33-7;
}

.lst-kix_list_37-7 > li {
  counter-increment: lst-ctn-kix_list_37-7;
}

.lst-kix_list_19-6 > li:before {
  content: '' counter(lst-ctn-kix_list_19-6, decimal) ') ';
}

.lst-kix_list_37-8 > li:before {
  content: '' counter(lst-ctn-kix_list_37-8, lower-roman) '. ';
}

ol.lst-kix_list_8-7.start {
  counter-reset: lst-ctn-kix_list_8-7 0;
}

.lst-kix_list_17-2 > li {
  counter-increment: lst-ctn-kix_list_17-2;
}

.lst-kix_list_37-6 > li:before {
  content: '' counter(lst-ctn-kix_list_37-6, decimal) '. ';
}

.lst-kix_list_32-2 > li {
  counter-increment: lst-ctn-kix_list_32-2;
}

.lst-kix_list_36-2 > li {
  counter-increment: lst-ctn-kix_list_36-2;
}

ol.lst-kix_list_31-8 {
  list-style-type: none;
}

.lst-kix_list_20-5 > li {
  counter-increment: lst-ctn-kix_list_20-5;
}

ol.lst-kix_list_31-5 {
  list-style-type: none;
}

ol.lst-kix_list_31-4 {
  list-style-type: none;
}

ol.lst-kix_list_31-7 {
  list-style-type: none;
}

ol.lst-kix_list_35-0.start {
  counter-reset: lst-ctn-kix_list_35-0 0;
}

ol.lst-kix_list_31-6 {
  list-style-type: none;
}

ol.lst-kix_list_37-3.start {
  counter-reset: lst-ctn-kix_list_37-3 0;
}

ol.lst-kix_list_31-1 {
  list-style-type: none;
}

ol.lst-kix_list_31-0 {
  list-style-type: none;
}

ol.lst-kix_list_31-3 {
  list-style-type: none;
}

.lst-kix_list_37-1 > li:before {
  content: '' counter(lst-ctn-kix_list_37-1, lower-latin) '. ';
}

ol.lst-kix_list_31-2 {
  list-style-type: none;
}

.lst-kix_list_22-0 > li {
  counter-increment: lst-ctn-kix_list_22-0;
}

ol.lst-kix_list_24-0.start {
  counter-reset: lst-ctn-kix_list_24-0 0;
}

.lst-kix_list_18-3 > li {
  counter-increment: lst-ctn-kix_list_18-3;
}

.lst-kix_list_37-3 > li:before {
  content: '' counter(lst-ctn-kix_list_37-3, decimal) '. ';
}

ol.lst-kix_list_33-4.start {
  counter-reset: lst-ctn-kix_list_33-4 0;
}

ol.lst-kix_list_35-7.start {
  counter-reset: lst-ctn-kix_list_35-7 0;
}

ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}

.lst-kix_list_37-3 > li {
  counter-increment: lst-ctn-kix_list_37-3;
}

.lst-kix_list_18-0 > li:before {
  content: '' counter(lst-ctn-kix_list_18-0, decimal) '. ';
}

ol.lst-kix_list_13-3.start {
  counter-reset: lst-ctn-kix_list_13-3 0;
}

.lst-kix_list_38-7 > li:before {
  content: '' counter(lst-ctn-kix_list_38-7, upper-latin) '. ';
}

.lst-kix_list_18-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_18-2, lower-roman) ') ';
}

ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}

.lst-kix_list_38-2 > li:before {
  content: '' counter(lst-ctn-kix_list_38-2, lower-roman) '. ';
}

.lst-kix_list_38-4 > li:before {
  content: '' counter(lst-ctn-kix_list_38-4, upper-latin) '. ';
}

ol.lst-kix_list_14-2.start {
  counter-reset: lst-ctn-kix_list_14-2 0;
}

.lst-kix_list_30-4 > li {
  counter-increment: lst-ctn-kix_list_30-4;
}

.lst-kix_list_38-4 > li {
  counter-increment: lst-ctn-kix_list_38-4;
}

.lst-kix_list_38-5 > li:before {
  content: '' counter(lst-ctn-kix_list_38-5, lower-roman) '. ';
}

.lst-kix_list_27-1 > li:before {
  content: 'o  ';
}

.lst-kix_list_27-3 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_17-5 > li {
  counter-increment: lst-ctn-kix_list_17-5;
}

ol.lst-kix_list_9-4.start {
  counter-reset: lst-ctn-kix_list_9-4 0;
}

ul.lst-kix_list_3-7 {
  list-style-type: none;
}

ul.lst-kix_list_3-8 {
  list-style-type: none;
}

.lst-kix_list_18-8 > li:before {
  content: '' counter(lst-ctn-kix_list_18-8, lower-roman) ') ';
}

ul.lst-kix_list_3-1 {
  list-style-type: none;
}

.lst-kix_list_17-6 > li {
  counter-increment: lst-ctn-kix_list_17-6;
}

ul.lst-kix_list_3-2 {
  list-style-type: none;
}

ol.lst-kix_list_30-1.start {
  counter-reset: lst-ctn-kix_list_30-1 0;
}

ul.lst-kix_list_3-0 {
  list-style-type: none;
}

ul.lst-kix_list_3-5 {
  list-style-type: none;
}

ul.lst-kix_list_3-6 {
  list-style-type: none;
}

ul.lst-kix_list_3-3 {
  list-style-type: none;
}

ul.lst-kix_list_3-4 {
  list-style-type: none;
}

.lst-kix_list_10-7 > li:before {
  content: 'o  ';
}

.lst-kix_list_7-8 > li {
  counter-increment: lst-ctn-kix_list_7-8;
}

.lst-kix_list_20-1 > li {
  counter-increment: lst-ctn-kix_list_20-1;
}

.lst-kix_list_36-4 > li:before {
  content: '' counter(lst-ctn-kix_list_36-4, lower-latin) '. ';
}

.lst-kix_list_10-5 > li:before {
  content: '\0025aa  ';
}

ol.lst-kix_list_13-5.start {
  counter-reset: lst-ctn-kix_list_13-5 0;
}

ol.lst-kix_list_18-6.start {
  counter-reset: lst-ctn-kix_list_18-6 0;
}

.lst-kix_list_29-3 > li {
  counter-increment: lst-ctn-kix_list_29-3;
}

ol.lst-kix_list_20-8 {
  list-style-type: none;
}

ol.lst-kix_list_13-8.start {
  counter-reset: lst-ctn-kix_list_13-8 0;
}

ol.lst-kix_list_20-5 {
  list-style-type: none;
}

ol.lst-kix_list_20-4 {
  list-style-type: none;
}

ol.lst-kix_list_20-7 {
  list-style-type: none;
}

ol.lst-kix_list_20-6 {
  list-style-type: none;
}

.lst-kix_list_9-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_9-2, lower-roman) ') ';
}

ol.lst-kix_list_20-1 {
  list-style-type: none;
}

ol.lst-kix_list_20-0 {
  list-style-type: none;
}

ol.lst-kix_list_20-3 {
  list-style-type: none;
}

ol.lst-kix_list_20-2 {
  list-style-type: none;
}

ol.lst-kix_list_37-6.start {
  counter-reset: lst-ctn-kix_list_37-6 0;
}

ol.lst-kix_list_14-0.start {
  counter-reset: lst-ctn-kix_list_14-0 0;
}

.lst-kix_list_12-5 > li {
  counter-increment: lst-ctn-kix_list_12-5;
}

.lst-kix_list_36-2 > li:before {
  content: '' counter(lst-ctn-kix_list_36-2, lower-roman) '. ';
}

.lst-kix_list_31-1 > li {
  counter-increment: lst-ctn-kix_list_31-1;
}

.lst-kix_list_9-0 > li:before {
  content: '' counter(lst-ctn-kix_list_9-0, decimal) '. ';
}

ul.lst-kix_list_21-1 {
  list-style-type: none;
}

ul.lst-kix_list_21-2 {
  list-style-type: none;
}

ul.lst-kix_list_21-3 {
  list-style-type: none;
}

ul.lst-kix_list_21-4 {
  list-style-type: none;
}

ul.lst-kix_list_21-5 {
  list-style-type: none;
}

ul.lst-kix_list_21-6 {
  list-style-type: none;
}

ul.lst-kix_list_21-7 {
  list-style-type: none;
}

ul.lst-kix_list_21-8 {
  list-style-type: none;
}

ol.lst-kix_list_33-7 {
  list-style-type: none;
}

ol.lst-kix_list_24-3.start {
  counter-reset: lst-ctn-kix_list_24-3 0;
}

ol.lst-kix_list_33-6 {
  list-style-type: none;
}

ol.lst-kix_list_33-8 {
  list-style-type: none;
}

ol.lst-kix_list_33-3 {
  list-style-type: none;
}

ol.lst-kix_list_33-2 {
  list-style-type: none;
}

.lst-kix_list_23-5 > li {
  counter-increment: lst-ctn-kix_list_23-5;
}

.lst-kix_list_11-3 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_33-5 {
  list-style-type: none;
}

ol.lst-kix_list_33-4 {
  list-style-type: none;
}

ul.lst-kix_list_21-0 {
  list-style-type: none;
}

.lst-kix_list_38-8 > li {
  counter-increment: lst-ctn-kix_list_38-8;
}

ol.lst-kix_list_18-4.start {
  counter-reset: lst-ctn-kix_list_18-4 0;
}

ol.lst-kix_list_33-1 {
  list-style-type: none;
}

ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}

ol.lst-kix_list_33-0 {
  list-style-type: none;
}

ol.lst-kix_list_29-1.start {
  counter-reset: lst-ctn-kix_list_29-1 0;
}

ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}

.lst-kix_list_29-1 > li:before {
  content: '' counter(lst-ctn-kix_list_29-1, lower-latin) '. ';
}

.lst-kix_list_20-4 > li:before {
  content: '' counter(lst-ctn-kix_list_20-4, decimal) '. ';
}

ol.lst-kix_list_22-7.start {
  counter-reset: lst-ctn-kix_list_22-7 0;
}

.lst-kix_list_29-3 > li:before {
  content: '' counter(lst-ctn-kix_list_29-3, decimal) '. ';
}

.lst-kix_list_20-2 > li:before {
  content: '' counter(lst-ctn-kix_list_20-2, decimal) '. ';
}

.lst-kix_list_9-8 > li:before {
  content: '' counter(lst-ctn-kix_list_9-8, lower-roman) ') ';
}

ul.lst-kix_list_10-0 {
  list-style-type: none;
}

ol.lst-kix_list_33-1.start {
  counter-reset: lst-ctn-kix_list_33-1 0;
}

.lst-kix_list_28-7 > li:before {
  content: 'o  ';
}

ul.lst-kix_list_10-8 {
  list-style-type: none;
}

ul.lst-kix_list_10-7 {
  list-style-type: none;
}

.lst-kix_list_1-7 > li:before {
  content: '' counter(lst-ctn-kix_list_1-7, lower-latin) ') ';
}

ul.lst-kix_list_10-6 {
  list-style-type: none;
}

ul.lst-kix_list_10-5 {
  list-style-type: none;
}

ul.lst-kix_list_10-4 {
  list-style-type: none;
}

ul.lst-kix_list_10-3 {
  list-style-type: none;
}

.lst-kix_list_1-5 > li:before {
  content: '' counter(lst-ctn-kix_list_1-5, lower-roman) '. ';
}

ul.lst-kix_list_10-2 {
  list-style-type: none;
}

.lst-kix_list_28-5 > li:before {
  content: '\0025aa  ';
}

ul.lst-kix_list_10-1 {
  list-style-type: none;
}

ol.lst-kix_list_9-1.start {
  counter-reset: lst-ctn-kix_list_9-1 0;
}

ol.lst-kix_list_24-2.start {
  counter-reset: lst-ctn-kix_list_24-2 0;
}

ol.lst-kix_list_22-6.start {
  counter-reset: lst-ctn-kix_list_22-6 0;
}

.lst-kix_list_2-1 > li:before {
  content: 'o  ';
}

.lst-kix_list_19-8 > li {
  counter-increment: lst-ctn-kix_list_19-8;
}

.lst-kix_list_2-3 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_35-3 > li:before {
  content: '' counter(lst-ctn-kix_list_35-3, decimal) '. ';
}

ol.lst-kix_list_24-8.start {
  counter-reset: lst-ctn-kix_list_24-8 0;
}

.lst-kix_list_30-4 > li:before {
  content: '' counter(lst-ctn-kix_list_30-4, lower-latin) '. ';
}

.lst-kix_list_20-8 > li {
  counter-increment: lst-ctn-kix_list_20-8;
}

.lst-kix_list_35-6 > li:before {
  content: '' counter(lst-ctn-kix_list_35-6, decimal) '. ';
}

.lst-kix_list_9-1 > li {
  counter-increment: lst-ctn-kix_list_9-1;
}

.lst-kix_list_36-5 > li {
  counter-increment: lst-ctn-kix_list_36-5;
}

.lst-kix_list_3-2 > li:before {
  content: ' ';
}

.lst-kix_list_26-7 > li:before {
  content: 'o  ';
}

.lst-kix_list_8-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_8-1, lower-latin) ') ';
}

ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}

.lst-kix_list_3-5 > li:before {
  content: ' ';
}

.lst-kix_list_18-0 > li {
  counter-increment: lst-ctn-kix_list_18-0;
}

.lst-kix_list_30-7 > li:before {
  content: '' counter(lst-ctn-kix_list_30-7, lower-latin) ') ';
}

.lst-kix_list_40-2 > li {
  counter-increment: lst-ctn-kix_list_40-2;
}

ol.lst-kix_list_30-8.start {
  counter-reset: lst-ctn-kix_list_30-8 0;
}

.lst-kix_list_8-6 > li:before {
  content: '' counter(lst-ctn-kix_list_8-6, decimal) ') ';
}

.lst-kix_list_26-2 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_21-6 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_22-1.start {
  counter-reset: lst-ctn-kix_list_22-1 0;
}

ol.lst-kix_list_35-3.start {
  counter-reset: lst-ctn-kix_list_35-3 0;
}

.lst-kix_list_33-4 > li {
  counter-increment: lst-ctn-kix_list_33-4;
}

.lst-kix_list_21-3 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_18-7.start {
  counter-reset: lst-ctn-kix_list_18-7 0;
}

ol.lst-kix_list_30-2.start {
  counter-reset: lst-ctn-kix_list_30-2 0;
}

ol.lst-kix_list_29-4.start {
  counter-reset: lst-ctn-kix_list_29-4 0;
}

.lst-kix_list_17-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_17-1, lower-latin) ') ';
}

.lst-kix_list_31-8 > li {
  counter-increment: lst-ctn-kix_list_31-8;
}

.lst-kix_list_25-3 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_32-3 > li {
  counter-increment: lst-ctn-kix_list_32-3;
}

.lst-kix_list_16-2 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_39-2 > li {
  counter-increment: lst-ctn-kix_list_39-2;
}

.lst-kix_list_16-5 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_15-3 > li {
  counter-increment: lst-ctn-kix_list_15-3;
}

ol.lst-kix_list_22-2.start {
  counter-reset: lst-ctn-kix_list_22-2 0;
}

ol.lst-kix_list_30-3.start {
  counter-reset: lst-ctn-kix_list_30-3 0;
}

.lst-kix_list_30-7 > li {
  counter-increment: lst-ctn-kix_list_30-7;
}

.lst-kix_list_37-6 > li {
  counter-increment: lst-ctn-kix_list_37-6;
}

ol.lst-kix_list_18-8.start {
  counter-reset: lst-ctn-kix_list_18-8 0;
}

ol.lst-kix_list_29-3.start {
  counter-reset: lst-ctn-kix_list_29-3 0;
}

.lst-kix_list_39-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_39-3, decimal) ') ';
}

ol.lst-kix_list_35-8.start {
  counter-reset: lst-ctn-kix_list_35-8 0;
}

.lst-kix_list_38-1 > li {
  counter-increment: lst-ctn-kix_list_38-1;
}

.lst-kix_list_17-6 > li:before {
  content: '' counter(lst-ctn-kix_list_17-6, decimal) ') ';
}

ol.lst-kix_list_35-5.start {
  counter-reset: lst-ctn-kix_list_35-5 0;
}

.lst-kix_list_2-6 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_14-5 > li {
  counter-increment: lst-ctn-kix_list_14-5;
}

.lst-kix_list_7-5 > li:before {
  content: '' counter(lst-ctn-kix_list_7-5, lower-roman) '. ';
}

.lst-kix_list_27-6 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_19-5 > li {
  counter-increment: lst-ctn-kix_list_19-5;
}

.lst-kix_list_22-7 > li:before {
  content: '' counter(lst-ctn-kix_list_22-7, decimal) '. ';
}

.lst-kix_list_23-2 > li {
  counter-increment: lst-ctn-kix_list_23-2;
}

ol.lst-kix_list_30-4.start {
  counter-reset: lst-ctn-kix_list_30-4 0;
}

.lst-kix_list_30-0 > li {
  counter-increment: lst-ctn-kix_list_30-0;
}

ol.lst-kix_list_35-2.start {
  counter-reset: lst-ctn-kix_list_35-2 0;
}

.lst-kix_list_34-7 > li:before {
  content: '' counter(lst-ctn-kix_list_34-7, lower-latin) '. ';
}

.lst-kix_list_18-5 > li:before {
  content: '' counter(lst-ctn-kix_list_18-5, lower-roman) '. ';
}

.lst-kix_list_13-6 > li:before {
  content: '' counter(lst-ctn-kix_list_13-6, decimal) ') ';
}

.lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}

.lst-kix_list_39-6 > li:before {
  content: '' counter(lst-ctn-kix_list_39-6, decimal) ') ';
}

.lst-kix_list_7-5 > li {
  counter-increment: lst-ctn-kix_list_7-5;
}

.lst-kix_list_15-6 > li:before {
  content: '' counter(lst-ctn-kix_list_15-6, decimal) ') ';
}

.lst-kix_list_29-0 > li {
  counter-increment: lst-ctn-kix_list_29-0;
}

ol.lst-kix_list_24-7.start {
  counter-reset: lst-ctn-kix_list_24-7 0;
}

.lst-kix_list_22-4 > li {
  counter-increment: lst-ctn-kix_list_22-4;
}

.lst-kix_list_31-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_31-3, decimal) ') ';
}

.lst-kix_list_36-7 > li:before {
  content: '' counter(lst-ctn-kix_list_36-7, lower-latin) '. ';
}

.lst-kix_list_10-2 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_13-7 > li {
  counter-increment: lst-ctn-kix_list_13-7;
}

ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}

.lst-kix_list_20-7 > li:before {
  content: '' counter(lst-ctn-kix_list_20-7, decimal) '. ';
}

.lst-kix_list_4-6 > li:before {
  content: ' ';
}

ol.lst-kix_list_29-8.start {
  counter-reset: lst-ctn-kix_list_29-8 0;
}

.lst-kix_list_25-6 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_29-5.start {
  counter-reset: lst-ctn-kix_list_29-5 0;
}

.lst-kix_list_34-2 > li {
  counter-increment: lst-ctn-kix_list_34-2;
}

.lst-kix_list_12-2 > li {
  counter-increment: lst-ctn-kix_list_12-2;
}

.lst-kix_list_9-5 > li:before {
  content: '' counter(lst-ctn-kix_list_9-5, lower-roman) '. ';
}

.lst-kix_list_29-6 > li:before {
  content: '' counter(lst-ctn-kix_list_29-6, decimal) '. ';
}

ol.lst-kix_list_22-0.start {
  counter-reset: lst-ctn-kix_list_22-0 0;
}

.lst-kix_list_24-0 > li {
  counter-increment: lst-ctn-kix_list_24-0;
}

.lst-kix_list_33-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_33-3, decimal) ') ';
}

.lst-kix_list_12-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_12-2, lower-roman) ') ';
}

.lst-kix_list_11-6 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_32-7 > li:before {
  content: '' counter(lst-ctn-kix_list_32-7, lower-latin) ') ';
}

ol.lst-kix_list_30-6.start {
  counter-reset: lst-ctn-kix_list_30-6 0;
}

.lst-kix_list_1-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-2, lower-roman) ') ';
}

.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}

ol.lst-kix_list_35-4.start {
  counter-reset: lst-ctn-kix_list_35-4 0;
}

ol.lst-kix_list_40-8 {
  list-style-type: none;
}

li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

ol.lst-kix_list_29-7.start {
  counter-reset: lst-ctn-kix_list_29-7 0;
}

ol.lst-kix_list_40-6 {
  list-style-type: none;
}

.lst-kix_list_35-0 > li {
  counter-increment: lst-ctn-kix_list_35-0;
}

ol.lst-kix_list_40-7 {
  list-style-type: none;
}

ol.lst-kix_list_40-4 {
  list-style-type: none;
}

.lst-kix_list_18-7 > li {
  counter-increment: lst-ctn-kix_list_18-7;
}

ol.lst-kix_list_40-5 {
  list-style-type: none;
}

ol.lst-kix_list_40-2 {
  list-style-type: none;
}

ol.lst-kix_list_40-3 {
  list-style-type: none;
}

ol.lst-kix_list_40-0 {
  list-style-type: none;
}

ol.lst-kix_list_40-1 {
  list-style-type: none;
}

.lst-kix_list_29-7 > li {
  counter-increment: lst-ctn-kix_list_29-7;
}

.lst-kix_list_40-4 > li:before {
  content: '' counter(lst-ctn-kix_list_40-4, lower-latin) '. ';
}

ol.lst-kix_list_30-7.start {
  counter-reset: lst-ctn-kix_list_30-7 0;
}

.lst-kix_list_28-2 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_14-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_14-1, lower-latin) ') ';
}

.lst-kix_list_14-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_14-3, decimal) ') ';
}

ol.lst-kix_list_15-6 {
  list-style-type: none;
}

ol.lst-kix_list_15-7 {
  list-style-type: none;
}

ul.lst-kix_list_28-0 {
  list-style-type: none;
}

ol.lst-kix_list_15-8 {
  list-style-type: none;
}

.lst-kix_list_14-0 > li:before {
  content: '' counter(lst-ctn-kix_list_14-0, decimal) '. ';
}

.lst-kix_list_14-4 > li:before {
  content: '' counter(lst-ctn-kix_list_14-4, lower-roman) '. ';
}

ul.lst-kix_list_28-1 {
  list-style-type: none;
}

ol.lst-kix_list_15-2 {
  list-style-type: none;
}

ol.lst-kix_list_15-3 {
  list-style-type: none;
}

ol.lst-kix_list_15-4 {
  list-style-type: none;
}

ol.lst-kix_list_18-5.start {
  counter-reset: lst-ctn-kix_list_18-5 0;
}

.lst-kix_list_14-5 > li:before {
  content: '' counter(lst-ctn-kix_list_14-5, lower-roman) '. ';
}

.lst-kix_list_14-7 > li:before {
  content: '' counter(lst-ctn-kix_list_14-7, lower-latin) ') ';
}

ol.lst-kix_list_15-5 {
  list-style-type: none;
}

.lst-kix_list_37-5 > li {
  counter-increment: lst-ctn-kix_list_37-5;
}

ol.lst-kix_list_15-0 {
  list-style-type: none;
}

.lst-kix_list_14-6 > li:before {
  content: '' counter(lst-ctn-kix_list_14-6, decimal) ') ';
}

ol.lst-kix_list_15-1 {
  list-style-type: none;
}

ol.lst-kix_list_7-4.start {
  counter-reset: lst-ctn-kix_list_7-4 0;
}

.lst-kix_list_17-0 > li {
  counter-increment: lst-ctn-kix_list_17-0;
}

.lst-kix_list_9-0 > li {
  counter-increment: lst-ctn-kix_list_9-0;
}

.lst-kix_list_34-6 > li {
  counter-increment: lst-ctn-kix_list_34-6;
}

ul.lst-kix_list_28-2 {
  list-style-type: none;
}

ul.lst-kix_list_28-3 {
  list-style-type: none;
}

ul.lst-kix_list_28-4 {
  list-style-type: none;
}

ul.lst-kix_list_28-5 {
  list-style-type: none;
}

ul.lst-kix_list_28-6 {
  list-style-type: none;
}

ul.lst-kix_list_28-7 {
  list-style-type: none;
}

ul.lst-kix_list_28-8 {
  list-style-type: none;
}

.lst-kix_list_14-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_14-2, lower-roman) ') ';
}

ol.lst-kix_list_32-1.start {
  counter-reset: lst-ctn-kix_list_32-1 0;
}

.lst-kix_list_20-7 > li {
  counter-increment: lst-ctn-kix_list_20-7;
}

ol.lst-kix_list_35-6.start {
  counter-reset: lst-ctn-kix_list_35-6 0;
}

ol.lst-kix_list_12-0.start {
  counter-reset: lst-ctn-kix_list_12-0 0;
}

.lst-kix_list_32-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_32-2, lower-roman) ') ';
}

.lst-kix_list_32-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_32-1, lower-latin) ') ';
}

.lst-kix_list_32-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_32-3, decimal) ') ';
}

.lst-kix_list_14-8 > li:before {
  content: '' counter(lst-ctn-kix_list_14-8, lower-roman) ') ';
}

.lst-kix_list_31-7 > li {
  counter-increment: lst-ctn-kix_list_31-7;
}

.lst-kix_list_39-7 > li {
  counter-increment: lst-ctn-kix_list_39-7;
}

.lst-kix_list_32-0 > li:before {
  content: '' counter(lst-ctn-kix_list_32-0, decimal) '. ';
}

ol.lst-kix_list_15-5.start {
  counter-reset: lst-ctn-kix_list_15-5 0;
}

ol.lst-kix_list_39-8 {
  list-style-type: none;
}

.lst-kix_list_5-0 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_14-8 > li {
  counter-increment: lst-ctn-kix_list_14-8;
}

ol.lst-kix_list_22-3.start {
  counter-reset: lst-ctn-kix_list_22-3 0;
}

ol.lst-kix_list_39-5 {
  list-style-type: none;
}

ol.lst-kix_list_39-4 {
  list-style-type: none;
}

ol.lst-kix_list_39-7 {
  list-style-type: none;
}

ol.lst-kix_list_39-6 {
  list-style-type: none;
}

.lst-kix_list_5-3 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_36-8 > li {
  counter-increment: lst-ctn-kix_list_36-8;
}

ol.lst-kix_list_24-6.start {
  counter-reset: lst-ctn-kix_list_24-6 0;
}

ol.lst-kix_list_39-1 {
  list-style-type: none;
}

ol.lst-kix_list_39-0 {
  list-style-type: none;
}

.lst-kix_list_5-2 > li:before {
  content: '\0025aa  ';
}

ol.lst-kix_list_39-3 {
  list-style-type: none;
}

.lst-kix_list_8-3 > li {
  counter-increment: lst-ctn-kix_list_8-3;
}

ol.lst-kix_list_39-2 {
  list-style-type: none;
}

.lst-kix_list_5-1 > li:before {
  content: 'o  ';
}

ol.lst-kix_list_18-0.start {
  counter-reset: lst-ctn-kix_list_18-0 11;
}

.lst-kix_list_5-7 > li:before {
  content: 'o  ';
}

.lst-kix_list_5-6 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_5-8 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_5-4 > li:before {
  content: 'o  ';
}

.lst-kix_list_5-5 > li:before {
  content: '\0025aa  ';
}

ol.lst-kix_list_12-5.start {
  counter-reset: lst-ctn-kix_list_12-5 0;
}

.lst-kix_list_6-1 > li:before {
  content: 'o  ';
}

.lst-kix_list_6-3 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_32-6.start {
  counter-reset: lst-ctn-kix_list_32-6 0;
}

.lst-kix_list_6-0 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_6-4 > li:before {
  content: 'o  ';
}

ol.lst-kix_list_14-8.start {
  counter-reset: lst-ctn-kix_list_14-8 0;
}

.lst-kix_list_6-2 > li:before {
  content: '\0025aa  ';
}

ol.lst-kix_list_15-0.start {
  counter-reset: lst-ctn-kix_list_15-0 0;
}

ol.lst-kix_list_35-1.start {
  counter-reset: lst-ctn-kix_list_35-1 0;
}

.lst-kix_list_6-8 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_6-5 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_6-7 > li:before {
  content: 'o  ';
}

.lst-kix_list_6-6 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_17-8 {
  list-style-type: none;
}

.lst-kix_list_7-4 > li:before {
  content: '' counter(lst-ctn-kix_list_7-4, lower-latin) '. ';
}

.lst-kix_list_7-6 > li:before {
  content: '' counter(lst-ctn-kix_list_7-6, decimal) ') ';
}

ol.lst-kix_list_17-4 {
  list-style-type: none;
}

.lst-kix_list_18-5 > li {
  counter-increment: lst-ctn-kix_list_18-5;
}

ol.lst-kix_list_19-7.start {
  counter-reset: lst-ctn-kix_list_19-7 0;
}

ol.lst-kix_list_17-5 {
  list-style-type: none;
}

.lst-kix_list_15-5 > li {
  counter-increment: lst-ctn-kix_list_15-5;
}

ol.lst-kix_list_17-6 {
  list-style-type: none;
}

ol.lst-kix_list_24-1.start {
  counter-reset: lst-ctn-kix_list_24-1 0;
}

ol.lst-kix_list_17-7 {
  list-style-type: none;
}

ol.lst-kix_list_17-0 {
  list-style-type: none;
}

ol.lst-kix_list_36-3.start {
  counter-reset: lst-ctn-kix_list_36-3 0;
}

.lst-kix_list_22-2 > li {
  counter-increment: lst-ctn-kix_list_22-2;
}

ol.lst-kix_list_17-1 {
  list-style-type: none;
}

ol.lst-kix_list_17-2 {
  list-style-type: none;
}

.lst-kix_list_7-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_7-2, lower-roman) ') ';
}

ol.lst-kix_list_17-3 {
  list-style-type: none;
}

.lst-kix_list_7-6 > li {
  counter-increment: lst-ctn-kix_list_7-6;
}

.lst-kix_list_31-0 > li {
  counter-increment: lst-ctn-kix_list_31-0;
}

ol.lst-kix_list_22-8.start {
  counter-reset: lst-ctn-kix_list_22-8 0;
}

.lst-kix_list_34-8 > li:before {
  content: '' counter(lst-ctn-kix_list_34-8, lower-roman) '. ';
}

.lst-kix_list_31-0 > li:before {
  content: '' counter(lst-ctn-kix_list_31-0, decimal) '. ';
}

.lst-kix_list_12-6 > li {
  counter-increment: lst-ctn-kix_list_12-6;
}

.lst-kix_list_24-3 > li {
  counter-increment: lst-ctn-kix_list_24-3;
}

.lst-kix_list_13-7 > li:before {
  content: '' counter(lst-ctn-kix_list_13-7, lower-latin) ') ';
}

ol.lst-kix_list_34-4.start {
  counter-reset: lst-ctn-kix_list_34-4 0;
}

.lst-kix_list_7-8 > li:before {
  content: '' counter(lst-ctn-kix_list_7-8, lower-roman) ') ';
}

ol.lst-kix_list_23-0.start {
  counter-reset: lst-ctn-kix_list_23-0 0;
}

.lst-kix_list_15-6 > li {
  counter-increment: lst-ctn-kix_list_15-6;
}

.lst-kix_list_15-5 > li:before {
  content: '' counter(lst-ctn-kix_list_15-5, lower-roman) '. ';
}

.lst-kix_list_31-6 > li:before {
  content: '' counter(lst-ctn-kix_list_31-6, decimal) ') ';
}

.lst-kix_list_31-8 > li:before {
  content: '' counter(lst-ctn-kix_list_31-8, lower-roman) ') ';
}

.lst-kix_list_9-8 > li {
  counter-increment: lst-ctn-kix_list_9-8;
}

.lst-kix_list_13-4 > li {
  counter-increment: lst-ctn-kix_list_13-4;
}

.lst-kix_list_4-1 > li:before {
  content: ' ';
}

.lst-kix_list_31-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_31-2, lower-roman) ') ';
}

.lst-kix_list_31-4 > li:before {
  content: '' counter(lst-ctn-kix_list_31-4, lower-latin) '. ';
}

.lst-kix_list_15-7 > li:before {
  content: '' counter(lst-ctn-kix_list_15-7, lower-latin) ') ';
}

.lst-kix_list_36-0 > li {
  counter-increment: lst-ctn-kix_list_36-0;
}

.lst-kix_list_17-7 > li {
  counter-increment: lst-ctn-kix_list_17-7;
}

ol.lst-kix_list_33-3.start {
  counter-reset: lst-ctn-kix_list_33-3 0;
}

.lst-kix_list_4-3 > li:before {
  content: ' ';
}

.lst-kix_list_4-5 > li:before {
  content: ' ';
}

.lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}

ol.lst-kix_list_38-1.start {
  counter-reset: lst-ctn-kix_list_38-1 0;
}

.lst-kix_list_15-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_15-1, lower-latin) ') ';
}

ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}

.lst-kix_list_24-4 > li {
  counter-increment: lst-ctn-kix_list_24-4;
}

.lst-kix_list_33-1 > li {
  counter-increment: lst-ctn-kix_list_33-1;
}

.lst-kix_list_15-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_15-3, decimal) ') ';
}

.lst-kix_list_22-1 > li {
  counter-increment: lst-ctn-kix_list_22-1;
}

ol.lst-kix_list_39-3.start {
  counter-reset: lst-ctn-kix_list_39-3 0;
}

.lst-kix_list_33-2 > li {
  counter-increment: lst-ctn-kix_list_33-2;
}

.lst-kix_list_40-5 > li {
  counter-increment: lst-ctn-kix_list_40-5;
}

.lst-kix_list_30-2 > li {
  counter-increment: lst-ctn-kix_list_30-2;
}

ol.lst-kix_list_9-2.start {
  counter-reset: lst-ctn-kix_list_9-2 0;
}

.lst-kix_list_20-0 > li {
  counter-increment: lst-ctn-kix_list_20-0;
}

.lst-kix_list_32-4 > li:before {
  content: '' counter(lst-ctn-kix_list_32-4, lower-latin) '. ';
}

.lst-kix_list_19-2 > li {
  counter-increment: lst-ctn-kix_list_19-2;
}

.lst-kix_list_33-8 > li {
  counter-increment: lst-ctn-kix_list_33-8;
}

.lst-kix_list_33-4 > li:before {
  content: '' counter(lst-ctn-kix_list_33-4, lower-latin) '. ';
}

.lst-kix_list_30-3 > li {
  counter-increment: lst-ctn-kix_list_30-3;
}

ol.lst-kix_list_8-8 {
  list-style-type: none;
}

.lst-kix_list_12-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_12-3, decimal) ') ';
}

ol.lst-kix_list_31-4.start {
  counter-reset: lst-ctn-kix_list_31-4 0;
}

.lst-kix_list_38-2 > li {
  counter-increment: lst-ctn-kix_list_38-2;
}

.lst-kix_list_32-6 > li:before {
  content: '' counter(lst-ctn-kix_list_32-6, decimal) ') ';
}

ol.lst-kix_list_8-4 {
  list-style-type: none;
}

.lst-kix_list_12-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_12-1, lower-latin) ') ';
}

ol.lst-kix_list_8-5 {
  list-style-type: none;
}

.lst-kix_list_33-0 > li:before {
  content: '' counter(lst-ctn-kix_list_33-0, decimal) '. ';
}

.lst-kix_list_33-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_33-2, lower-roman) ') ';
}

ol.lst-kix_list_8-6 {
  list-style-type: none;
}

ol.lst-kix_list_8-7 {
  list-style-type: none;
}

.lst-kix_list_32-8 > li:before {
  content: '' counter(lst-ctn-kix_list_32-8, lower-roman) ') ';
}

ol.lst-kix_list_8-0 {
  list-style-type: none;
}

ol.lst-kix_list_8-1 {
  list-style-type: none;
}

.lst-kix_list_23-6 > li {
  counter-increment: lst-ctn-kix_list_23-6;
}

ol.lst-kix_list_8-2 {
  list-style-type: none;
}

.lst-kix_list_13-3 > li {
  counter-increment: lst-ctn-kix_list_13-3;
}

ol.lst-kix_list_13-6.start {
  counter-reset: lst-ctn-kix_list_13-6 0;
}

ol.lst-kix_list_8-3 {
  list-style-type: none;
}

ol.lst-kix_list_40-5.start {
  counter-reset: lst-ctn-kix_list_40-5 0;
}

.lst-kix_list_39-0 > li {
  counter-increment: lst-ctn-kix_list_39-0;
}

.lst-kix_list_34-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_34-0, lower-latin) ') ';
}

.lst-kix_list_14-1 > li {
  counter-increment: lst-ctn-kix_list_14-1;
}

.lst-kix_list_13-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_13-3, decimal) ') ';
}

.lst-kix_list_39-6 > li {
  counter-increment: lst-ctn-kix_list_39-6;
}

.lst-kix_list_34-4 > li:before {
  content: '' counter(lst-ctn-kix_list_34-4, lower-latin) '. ';
}

.lst-kix_list_35-3 > li {
  counter-increment: lst-ctn-kix_list_35-3;
}

.lst-kix_list_34-6 > li:before {
  content: '' counter(lst-ctn-kix_list_34-6, decimal) '. ';
}

.lst-kix_list_13-5 > li:before {
  content: '' counter(lst-ctn-kix_list_13-5, lower-roman) '. ';
}

.lst-kix_list_12-5 > li:before {
  content: '' counter(lst-ctn-kix_list_12-5, lower-roman) '. ';
}

ol.lst-kix_list_13-7.start {
  counter-reset: lst-ctn-kix_list_13-7 0;
}

.lst-kix_list_18-4 > li {
  counter-increment: lst-ctn-kix_list_18-4;
}

.lst-kix_list_36-1 > li {
  counter-increment: lst-ctn-kix_list_36-1;
}

.lst-kix_list_36-7 > li {
  counter-increment: lst-ctn-kix_list_36-7;
}

.lst-kix_list_12-7 > li:before {
  content: '' counter(lst-ctn-kix_list_12-7, lower-latin) ') ';
}

.lst-kix_list_29-4 > li {
  counter-increment: lst-ctn-kix_list_29-4;
}

.lst-kix_list_33-6 > li:before {
  content: '' counter(lst-ctn-kix_list_33-6, decimal) ') ';
}

.lst-kix_list_32-4 > li {
  counter-increment: lst-ctn-kix_list_32-4;
}

.lst-kix_list_33-8 > li:before {
  content: '' counter(lst-ctn-kix_list_33-8, lower-roman) ') ';
}

ol.lst-kix_list_29-0.start {
  counter-reset: lst-ctn-kix_list_29-0 0;
}

.lst-kix_list_34-2 > li:before {
  content: '' counter(lst-ctn-kix_list_34-2, lower-roman) '. ';
}

.lst-kix_list_13-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_13-1, lower-latin) ') ';
}

.lst-kix_list_32-5 > li {
  counter-increment: lst-ctn-kix_list_32-5;
}

.lst-kix_list_34-5 > li {
  counter-increment: lst-ctn-kix_list_34-5;
}

.lst-kix_list_30-5 > li:before {
  content: '' counter(lst-ctn-kix_list_30-5, lower-roman) '. ';
}

.lst-kix_list_22-8 > li {
  counter-increment: lst-ctn-kix_list_22-8;
}

.lst-kix_list_35-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_35-0, lower-latin) ') ';
}

.lst-kix_list_35-1 > li:before {
  content: '' counter(lst-ctn-kix_list_35-1, lower-latin) '. ';
}

.lst-kix_list_35-4 > li:before {
  content: '' counter(lst-ctn-kix_list_35-4, lower-latin) '. ';
}

.lst-kix_list_35-5 > li:before {
  content: '' counter(lst-ctn-kix_list_35-5, lower-roman) '. ';
}

ol.lst-kix_list_40-6.start {
  counter-reset: lst-ctn-kix_list_40-6 0;
}

ol.lst-kix_list_33-2.start {
  counter-reset: lst-ctn-kix_list_33-2 0;
}

.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}

.lst-kix_list_30-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_30-1, lower-latin) ') ';
}

.lst-kix_list_3-0 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_30-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_30-2, lower-roman) ') ';
}

ol.lst-kix_list_20-5.start {
  counter-reset: lst-ctn-kix_list_20-5 0;
}

ol.lst-kix_list_13-1.start {
  counter-reset: lst-ctn-kix_list_13-1 0;
}

.lst-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0;
}

.lst-kix_list_31-6 > li {
  counter-increment: lst-ctn-kix_list_31-6;
}

ol.lst-kix_list_37-5.start {
  counter-reset: lst-ctn-kix_list_37-5 0;
}

.lst-kix_list_3-4 > li:before {
  content: ' ';
}

.lst-kix_list_3-3 > li:before {
  content: ' ';
}

.lst-kix_list_8-0 > li:before {
  content: '' counter(lst-ctn-kix_list_8-0, decimal) '. ';
}

.lst-kix_list_30-6 > li:before {
  content: '' counter(lst-ctn-kix_list_30-6, decimal) ') ';
}

.lst-kix_list_8-7 > li:before {
  content: '' counter(lst-ctn-kix_list_8-7, lower-latin) ') ';
}

.lst-kix_list_38-3 > li {
  counter-increment: lst-ctn-kix_list_38-3;
}

.lst-kix_list_3-8 > li:before {
  content: ' ';
}

.lst-kix_list_8-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_8-3, decimal) ') ';
}

ol.lst-kix_list_22-7 {
  list-style-type: none;
}

ol.lst-kix_list_22-6 {
  list-style-type: none;
}

ol.lst-kix_list_22-8 {
  list-style-type: none;
}

.lst-kix_list_3-7 > li:before {
  content: ' ';
}

ol.lst-kix_list_22-3 {
  list-style-type: none;
}

ol.lst-kix_list_22-2 {
  list-style-type: none;
}

.lst-kix_list_8-4 > li:before {
  content: '' counter(lst-ctn-kix_list_8-4, lower-latin) '. ';
}

ol.lst-kix_list_22-5 {
  list-style-type: none;
}

ol.lst-kix_list_22-4 {
  list-style-type: none;
}

.lst-kix_list_19-1 > li {
  counter-increment: lst-ctn-kix_list_19-1;
}

ol.lst-kix_list_22-1 {
  list-style-type: none;
}

ol.lst-kix_list_22-0 {
  list-style-type: none;
}

ol.lst-kix_list_8-5.start {
  counter-reset: lst-ctn-kix_list_8-5 0;
}

.lst-kix_list_35-8 > li:before {
  content: '' counter(lst-ctn-kix_list_35-8, lower-roman) '. ';
}

.lst-kix_list_17-1 > li {
  counter-increment: lst-ctn-kix_list_17-1;
}

.lst-kix_list_11-1 > li:before {
  content: 'o  ';
}

ol.lst-kix_list_31-0.start {
  counter-reset: lst-ctn-kix_list_31-0 0;
}

.lst-kix_list_11-0 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_9-3.start {
  counter-reset: lst-ctn-kix_list_9-3 0;
}

.lst-kix_list_8-8 > li:before {
  content: '' counter(lst-ctn-kix_list_8-8, lower-roman) ') ';
}

.lst-kix_list_35-4 > li {
  counter-increment: lst-ctn-kix_list_35-4;
}

.lst-kix_list_37-4 > li {
  counter-increment: lst-ctn-kix_list_37-4;
}

.lst-kix_list_16-8 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_16-7 > li:before {
  content: 'o  ';
}

.lst-kix_list_17-8 > li {
  counter-increment: lst-ctn-kix_list_17-8;
}

ol.lst-kix_list_35-8 {
  list-style-type: none;
}

.lst-kix_list_4-8 > li:before {
  content: ' ';
}

ol.lst-kix_list_35-5 {
  list-style-type: none;
}

ol.lst-kix_list_35-4 {
  list-style-type: none;
}

.lst-kix_list_4-7 > li:before {
  content: ' ';
}

ol.lst-kix_list_35-7 {
  list-style-type: none;
}

.lst-kix_list_14-2 > li {
  counter-increment: lst-ctn-kix_list_14-2;
}

ol.lst-kix_list_20-0.start {
  counter-reset: lst-ctn-kix_list_20-0 0;
}

.lst-kix_list_17-0 > li:before {
  content: '' counter(lst-ctn-kix_list_17-0, decimal) '. ';
}

ol.lst-kix_list_35-6 {
  list-style-type: none;
}

ol.lst-kix_list_35-1 {
  list-style-type: none;
}

ol.lst-kix_list_35-0 {
  list-style-type: none;
}

ol.lst-kix_list_35-3 {
  list-style-type: none;
}

ol.lst-kix_list_35-2 {
  list-style-type: none;
}

.lst-kix_list_39-8 > li {
  counter-increment: lst-ctn-kix_list_39-8;
}

ul.lst-kix_list_4-8 {
  list-style-type: none;
}

.lst-kix_list_16-0 > li:before {
  content: '\0025cf  ';
}

ul.lst-kix_list_4-6 {
  list-style-type: none;
}

ul.lst-kix_list_4-7 {
  list-style-type: none;
}

.lst-kix_list_40-6 > li {
  counter-increment: lst-ctn-kix_list_40-6;
}

.lst-kix_list_8-4 > li {
  counter-increment: lst-ctn-kix_list_8-4;
}

.lst-kix_list_16-4 > li:before {
  content: 'o  ';
}

ul.lst-kix_list_4-1 {
  list-style-type: none;
}

.lst-kix_list_16-3 > li:before {
  content: '\0025cf  ';
}

ul.lst-kix_list_4-4 {
  list-style-type: none;
}

ul.lst-kix_list_4-5 {
  list-style-type: none;
}

ul.lst-kix_list_4-2 {
  list-style-type: none;
}

ul.lst-kix_list_4-3 {
  list-style-type: none;
}

ol.lst-kix_list_18-1.start {
  counter-reset: lst-ctn-kix_list_18-1 0;
}

.lst-kix_list_30-1 > li {
  counter-increment: lst-ctn-kix_list_30-1;
}

ol.lst-kix_list_37-4.start {
  counter-reset: lst-ctn-kix_list_37-4 0;
}

ol.lst-kix_list_39-7.start {
  counter-reset: lst-ctn-kix_list_39-7 0;
}

ol.lst-kix_list_8-6.start {
  counter-reset: lst-ctn-kix_list_8-6 0;
}

.lst-kix_list_17-7 > li:before {
  content: '' counter(lst-ctn-kix_list_17-7, lower-latin) ') ';
}

ol.lst-kix_list_38-2.start {
  counter-reset: lst-ctn-kix_list_38-2 0;
}

.lst-kix_list_17-8 > li:before {
  content: '' counter(lst-ctn-kix_list_17-8, lower-roman) ') ';
}

.lst-kix_list_33-0 > li {
  counter-increment: lst-ctn-kix_list_33-0;
}

.lst-kix_list_17-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_17-3, decimal) ') ';
}

.lst-kix_list_17-4 > li:before {
  content: '' counter(lst-ctn-kix_list_17-4, lower-latin) '. ';
}

ol.lst-kix_list_8-0.start {
  counter-reset: lst-ctn-kix_list_8-0 0;
}

.lst-kix_list_7-0 > li:before {
  content: '' counter(lst-ctn-kix_list_7-0, decimal) '. ';
}

ol.lst-kix_list_19-6.start {
  counter-reset: lst-ctn-kix_list_19-6 0;
}

ol.lst-kix_list_9-7.start {
  counter-reset: lst-ctn-kix_list_9-7 0;
}

ol.lst-kix_list_13-8 {
  list-style-type: none;
}

ul.lst-kix_list_26-0 {
  list-style-type: none;
}

.lst-kix_list_2-4 > li:before {
  content: 'o  ';
}

.lst-kix_list_2-8 > li:before {
  content: '\0025aa  ';
}

ul.lst-kix_list_26-1 {
  list-style-type: none;
}

ul.lst-kix_list_26-2 {
  list-style-type: none;
}

ul.lst-kix_list_26-3 {
  list-style-type: none;
}

ol.lst-kix_list_13-4 {
  list-style-type: none;
}

ol.lst-kix_list_13-5 {
  list-style-type: none;
}

ol.lst-kix_list_13-6 {
  list-style-type: none;
}

ol.lst-kix_list_13-7 {
  list-style-type: none;
}

ol.lst-kix_list_13-0 {
  list-style-type: none;
}

ol.lst-kix_list_13-1 {
  list-style-type: none;
}

ol.lst-kix_list_13-2 {
  list-style-type: none;
}

.lst-kix_list_7-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_7-3, decimal) ') ';
}

ol.lst-kix_list_13-3 {
  list-style-type: none;
}

.lst-kix_list_10-0 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_9-7 > li {
  counter-increment: lst-ctn-kix_list_9-7;
}

.lst-kix_list_13-8 > li:before {
  content: '' counter(lst-ctn-kix_list_13-8, lower-roman) ') ';
}

.lst-kix_list_31-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_31-1, lower-latin) ') ';
}

.lst-kix_list_18-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_18-3, decimal) ') ';
}

.lst-kix_list_18-7 > li:before {
  content: '' counter(lst-ctn-kix_list_18-7, lower-latin) ') ';
}

ul.lst-kix_list_26-4 {
  list-style-type: none;
}

ul.lst-kix_list_26-5 {
  list-style-type: none;
}

ul.lst-kix_list_26-6 {
  list-style-type: none;
}

.lst-kix_list_18-6 > li {
  counter-increment: lst-ctn-kix_list_18-6;
}

ul.lst-kix_list_26-7 {
  list-style-type: none;
}

ul.lst-kix_list_26-8 {
  list-style-type: none;
}

ol.lst-kix_list_39-8.start {
  counter-reset: lst-ctn-kix_list_39-8 0;
}

.lst-kix_list_7-7 > li:before {
  content: '' counter(lst-ctn-kix_list_7-7, lower-latin) ') ';
}

.lst-kix_list_36-5 > li:before {
  content: '' counter(lst-ctn-kix_list_36-5, lower-roman) '. ';
}

ol.lst-kix_list_8-1.start {
  counter-reset: lst-ctn-kix_list_8-1 0;
}

.lst-kix_list_15-4 > li:before {
  content: '' counter(lst-ctn-kix_list_15-4, lower-latin) '. ';
}

.lst-kix_list_31-5 > li:before {
  content: '' counter(lst-ctn-kix_list_31-5, lower-roman) '. ';
}

ol.lst-kix_list_19-1.start {
  counter-reset: lst-ctn-kix_list_19-1 0;
}

ol.lst-kix_list_24-8 {
  list-style-type: none;
}

.lst-kix_list_10-4 > li:before {
  content: 'o  ';
}

.lst-kix_list_10-8 > li:before {
  content: '\0025aa  ';
}

ol.lst-kix_list_20-4.start {
  counter-reset: lst-ctn-kix_list_20-4 0;
}

.lst-kix_list_4-0 > li:before {
  content: '' counter(lst-ctn-kix_list_4-0, decimal) '. ';
}

.lst-kix_list_36-1 > li:before {
  content: '' counter(lst-ctn-kix_list_36-1, lower-latin) '. ';
}

ol.lst-kix_list_24-5 {
  list-style-type: none;
}

ol.lst-kix_list_24-4 {
  list-style-type: none;
}

ol.lst-kix_list_24-7 {
  list-style-type: none;
}

.lst-kix_list_15-0 > li:before {
  content: '' counter(lst-ctn-kix_list_15-0, decimal) '. ';
}

.lst-kix_list_15-8 > li:before {
  content: '' counter(lst-ctn-kix_list_15-8, lower-roman) ') ';
}

ol.lst-kix_list_24-6 {
  list-style-type: none;
}

ol.lst-kix_list_24-1 {
  list-style-type: none;
}

ol.lst-kix_list_14-3.start {
  counter-reset: lst-ctn-kix_list_14-3 0;
}

ol.lst-kix_list_24-0 {
  list-style-type: none;
}

ol.lst-kix_list_38-7.start {
  counter-reset: lst-ctn-kix_list_38-7 0;
}

ol.lst-kix_list_24-3 {
  list-style-type: none;
}

.lst-kix_list_15-7 > li {
  counter-increment: lst-ctn-kix_list_15-7;
}

ol.lst-kix_list_24-2 {
  list-style-type: none;
}

.lst-kix_list_4-4 > li:before {
  content: ' ';
}

.lst-kix_list_9-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_9-3, decimal) ') ';
}

ol.lst-kix_list_7-0.start {
  counter-reset: lst-ctn-kix_list_7-0 0;
}

ol.lst-kix_list_32-5.start {
  counter-reset: lst-ctn-kix_list_32-5 0;
}

.lst-kix_list_12-8 > li {
  counter-increment: lst-ctn-kix_list_12-8;
}

ol.lst-kix_list_13-2.start {
  counter-reset: lst-ctn-kix_list_13-2 0;
}

ol.lst-kix_list_4-0 {
  list-style-type: none;
}

ol.lst-kix_list_33-7.start {
  counter-reset: lst-ctn-kix_list_33-7 0;
}

ol.lst-kix_list_37-7 {
  list-style-type: none;
}

ol.lst-kix_list_14-4.start {
  counter-reset: lst-ctn-kix_list_14-4 0;
}

ol.lst-kix_list_37-6 {
  list-style-type: none;
}

.lst-kix_list_9-7 > li:before {
  content: '' counter(lst-ctn-kix_list_9-7, lower-latin) ') ';
}

.lst-kix_list_29-4 > li:before {
  content: '' counter(lst-ctn-kix_list_29-4, lower-latin) '. ';
}

.lst-kix_list_29-8 > li:before {
  content: '' counter(lst-ctn-kix_list_29-8, lower-roman) '. ';
}

ol.lst-kix_list_37-8 {
  list-style-type: none;
}

ol.lst-kix_list_37-3 {
  list-style-type: none;
}

ol.lst-kix_list_37-2 {
  list-style-type: none;
}

ol.lst-kix_list_32-2.start {
  counter-reset: lst-ctn-kix_list_32-2 0;
}

.lst-kix_list_32-5 > li:before {
  content: '' counter(lst-ctn-kix_list_32-5, lower-roman) '. ';
}

ol.lst-kix_list_37-5 {
  list-style-type: none;
}

ol.lst-kix_list_37-4 {
  list-style-type: none;
}

.lst-kix_list_11-4 > li:before {
  content: 'o  ';
}

.lst-kix_list_12-4 > li:before {
  content: '' counter(lst-ctn-kix_list_12-4, lower-latin) '. ';
}

ol.lst-kix_list_37-1 {
  list-style-type: none;
}

ol.lst-kix_list_37-0 {
  list-style-type: none;
}

ul.lst-kix_list_6-6 {
  list-style-type: none;
}

ul.lst-kix_list_6-7 {
  list-style-type: none;
}

ul.lst-kix_list_6-4 {
  list-style-type: none;
}

ul.lst-kix_list_6-5 {
  list-style-type: none;
}

ol.lst-kix_list_37-0.start {
  counter-reset: lst-ctn-kix_list_37-0 0;
}

ul.lst-kix_list_6-8 {
  list-style-type: none;
}

.lst-kix_list_29-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_29-0, lower-latin) ') ';
}

.lst-kix_list_7-4 > li {
  counter-increment: lst-ctn-kix_list_7-4;
}

.lst-kix_list_33-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_33-1, lower-latin) ') ';
}

.lst-kix_list_1-0 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
}

ol.lst-kix_list_19-2.start {
  counter-reset: lst-ctn-kix_list_19-2 0;
}

ol.lst-kix_list_38-5.start {
  counter-reset: lst-ctn-kix_list_38-5 0;
}

ul.lst-kix_list_6-2 {
  list-style-type: none;
}

.lst-kix_list_11-8 > li:before {
  content: '\0025aa  ';
}

ul.lst-kix_list_6-3 {
  list-style-type: none;
}

ul.lst-kix_list_6-0 {
  list-style-type: none;
}

.lst-kix_list_12-0 > li:before {
  content: '' counter(lst-ctn-kix_list_12-0, decimal) '. ';
}

ul.lst-kix_list_6-1 {
  list-style-type: none;
}

.lst-kix_list_1-4 > li:before {
  content: '' counter(lst-ctn-kix_list_1-4, lower-latin) '. ';
}

.lst-kix_list_13-0 > li:before {
  content: '' counter(lst-ctn-kix_list_13-0, decimal) '. ';
}

.lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}

.lst-kix_list_13-4 > li:before {
  content: '' counter(lst-ctn-kix_list_13-4, lower-latin) '. ';
}

.lst-kix_list_34-5 > li:before {
  content: '' counter(lst-ctn-kix_list_34-5, lower-roman) '. ';
}

ol.lst-kix_list_38-6.start {
  counter-reset: lst-ctn-kix_list_38-6 0;
}

.lst-kix_list_33-5 > li:before {
  content: '' counter(lst-ctn-kix_list_33-5, lower-roman) '. ';
}

ol.lst-kix_list_19-3.start {
  counter-reset: lst-ctn-kix_list_19-3 0;
}

.lst-kix_list_2-0 > li:before {
  content: '\0025cf  ';
}

ol.lst-kix_list_33-8.start {
  counter-reset: lst-ctn-kix_list_33-8 0;
}

ol.lst-kix_list_9-8.start {
  counter-reset: lst-ctn-kix_list_9-8 0;
}

.lst-kix_list_1-8 > li:before {
  content: '' counter(lst-ctn-kix_list_1-8, lower-roman) ') ';
}

.lst-kix_list_34-1 > li:before {
  content: '' counter(lst-ctn-kix_list_34-1, lower-latin) '. ';
}

.lst-kix_list_12-8 > li:before {
  content: '' counter(lst-ctn-kix_list_12-8, lower-roman) ') ';
}

.lst-kix_list_8-2 > li {
  counter-increment: lst-ctn-kix_list_8-2;
}

.lst-kix_list_19-0 > li:before {
  content: '' counter(lst-ctn-kix_list_19-0, decimal) '. ';
}

ol.lst-kix_list_17-7.start {
  counter-reset: lst-ctn-kix_list_17-7 0;
}

ol.lst-kix_list_12-6.start {
  counter-reset: lst-ctn-kix_list_12-6 0;
}

.lst-kix_list_23-8 > li {
  counter-increment: lst-ctn-kix_list_23-8;
}

.lst-kix_list_8-1 > li {
  counter-increment: lst-ctn-kix_list_8-1;
}

.lst-kix_list_35-5 > li {
  counter-increment: lst-ctn-kix_list_35-5;
}

ol.lst-kix_list_30-6 {
  list-style-type: none;
}

ol.lst-kix_list_8-2.start {
  counter-reset: lst-ctn-kix_list_8-2 0;
}

ol.lst-kix_list_30-5 {
  list-style-type: none;
}

.lst-kix_list_19-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_19-2, lower-roman) ') ';
}

ol.lst-kix_list_30-8 {
  list-style-type: none;
}

ol.lst-kix_list_30-7 {
  list-style-type: none;
}

ol.lst-kix_list_30-2 {
  list-style-type: none;
}

ol.lst-kix_list_30-1 {
  list-style-type: none;
}

ol.lst-kix_list_30-4 {
  list-style-type: none;
}

ol.lst-kix_list_30-3 {
  list-style-type: none;
}

ol.lst-kix_list_30-0 {
  list-style-type: none;
}

.lst-kix_list_7-0 > li {
  counter-increment: lst-ctn-kix_list_7-0;
}

.lst-kix_list_19-0 > li {
  counter-increment: lst-ctn-kix_list_19-0;
}

.lst-kix_list_29-6 > li {
  counter-increment: lst-ctn-kix_list_29-6;
}

.lst-kix_list_36-6 > li {
  counter-increment: lst-ctn-kix_list_36-6;
}

ol.lst-kix_list_31-3.start {
  counter-reset: lst-ctn-kix_list_31-3 0;
}

ol.lst-kix_list_19-8.start {
  counter-reset: lst-ctn-kix_list_19-8 0;
}

ol.lst-kix_list_36-4.start {
  counter-reset: lst-ctn-kix_list_36-4 0;
}

.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}

.lst-kix_list_19-8 > li:before {
  content: '' counter(lst-ctn-kix_list_19-8, lower-roman) ') ';
}

ol.lst-kix_list_14-7.start {
  counter-reset: lst-ctn-kix_list_14-7 0;
}

ol.lst-kix_list_20-8.start {
  counter-reset: lst-ctn-kix_list_20-8 0;
}

.lst-kix_list_19-5 > li:before {
  content: '' counter(lst-ctn-kix_list_19-5, lower-roman) '. ';
}

ol.lst-kix_list_34-8.start {
  counter-reset: lst-ctn-kix_list_34-8 0;
}

.lst-kix_list_19-7 > li:before {
  content: '' counter(lst-ctn-kix_list_19-7, lower-latin) ') ';
}

.lst-kix_list_9-2 > li {
  counter-increment: lst-ctn-kix_list_9-2;
}

ol.lst-kix_list_23-8.start {
  counter-reset: lst-ctn-kix_list_23-8 0;
}

.lst-kix_list_24-5 > li {
  counter-increment: lst-ctn-kix_list_24-5;
}

.lst-kix_list_37-7 > li:before {
  content: '' counter(lst-ctn-kix_list_37-7, lower-latin) '. ';
}

ol.lst-kix_list_17-2.start {
  counter-reset: lst-ctn-kix_list_17-2 0;
}

.lst-kix_list_13-2 > li {
  counter-increment: lst-ctn-kix_list_13-2;
}

.lst-kix_list_38-0 > li:before {
  content: '(' counter(lst-ctn-kix_list_38-0, lower-latin) ') ';
}

.lst-kix_list_19-7 > li {
  counter-increment: lst-ctn-kix_list_19-7;
}

.lst-kix_list_38-1 > li:before {
  content: '' counter(lst-ctn-kix_list_38-1, upper-latin) '. ';
}

.lst-kix_list_14-3 > li {
  counter-increment: lst-ctn-kix_list_14-3;
}

ol.lst-kix_list_15-6.start {
  counter-reset: lst-ctn-kix_list_15-6 0;
}

.lst-kix_list_37-2 > li:before {
  content: '' counter(lst-ctn-kix_list_37-2, lower-roman) '. ';
}

.lst-kix_list_37-4 > li:before {
  content: '' counter(lst-ctn-kix_list_37-4, lower-latin) '. ';
}

.lst-kix_list_37-5 > li:before {
  content: '' counter(lst-ctn-kix_list_37-5, lower-roman) '. ';
}

.lst-kix_list_12-1 > li {
  counter-increment: lst-ctn-kix_list_12-1;
}

ol.lst-kix_list_31-8.start {
  counter-reset: lst-ctn-kix_list_31-8 0;
}

.lst-kix_list_18-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_18-1, lower-latin) ') ';
}

.lst-kix_list_33-3 > li {
  counter-increment: lst-ctn-kix_list_33-3;
}

.lst-kix_list_38-8 > li:before {
  content: '' counter(lst-ctn-kix_list_38-8, lower-roman) '. ';
}

.lst-kix_list_40-3 > li {
  counter-increment: lst-ctn-kix_list_40-3;
}

.lst-kix_list_38-3 > li:before {
  content: '' counter(lst-ctn-kix_list_38-3, decimal) '. ';
}

.lst-kix_list_23-4 > li {
  counter-increment: lst-ctn-kix_list_23-4;
}

ol.lst-kix_list_23-1.start {
  counter-reset: lst-ctn-kix_list_23-1 0;
}

.lst-kix_list_38-6 > li:before {
  content: '' counter(lst-ctn-kix_list_38-6, decimal) '. ';
}

ol.lst-kix_list_32-0.start {
  counter-reset: lst-ctn-kix_list_32-0 0;
}

ol.lst-kix_list_34-3.start {
  counter-reset: lst-ctn-kix_list_34-3 0;
}

.lst-kix_list_23-1 > li {
  counter-increment: lst-ctn-kix_list_23-1;
}

.lst-kix_list_34-4 > li {
  counter-increment: lst-ctn-kix_list_34-4;
}

ol.lst-kix_list_1-3 {
  list-style-type: none;
}

ol.lst-kix_list_1-4 {
  list-style-type: none;
}

.lst-kix_list_2-7 > li:before {
  content: 'o  ';
}

ol.lst-kix_list_1-5 {
  list-style-type: none;
}

.lst-kix_list_24-2 > li {
  counter-increment: lst-ctn-kix_list_24-2;
}

ol.lst-kix_list_1-6 {
  list-style-type: none;
}

ol.lst-kix_list_1-0 {
  list-style-type: none;
}

.lst-kix_list_2-5 > li:before {
  content: '\0025aa  ';
}

ol.lst-kix_list_1-1 {
  list-style-type: none;
}

ol.lst-kix_list_1-2 {
  list-style-type: none;
}

ol.lst-kix_list_32-8 {
  list-style-type: none;
}

ol.lst-kix_list_17-0.start {
  counter-reset: lst-ctn-kix_list_17-0 0;
}

ol.lst-kix_list_32-7 {
  list-style-type: none;
}

.lst-kix_list_27-5 > li:before {
  content: '\0025aa  ';
}

ol.lst-kix_list_32-4 {
  list-style-type: none;
}

ol.lst-kix_list_32-3 {
  list-style-type: none;
}

ol.lst-kix_list_32-6 {
  list-style-type: none;
}

ol.lst-kix_list_32-5 {
  list-style-type: none;
}

ol.lst-kix_list_32-0 {
  list-style-type: none;
}

.lst-kix_list_22-3 > li {
  counter-increment: lst-ctn-kix_list_22-3;
}

.lst-kix_list_18-6 > li:before {
  content: '' counter(lst-ctn-kix_list_18-6, decimal) ') ';
}

ol.lst-kix_list_32-2 {
  list-style-type: none;
}

ol.lst-kix_list_32-1 {
  list-style-type: none;
}

.lst-kix_list_14-6 > li {
  counter-increment: lst-ctn-kix_list_14-6;
}

.lst-kix_list_39-5 > li:before {
  content: '' counter(lst-ctn-kix_list_39-5, lower-roman) '. ';
}

.lst-kix_list_39-7 > li:before {
  content: '' counter(lst-ctn-kix_list_39-7, lower-latin) ') ';
}

.lst-kix_list_10-1 > li:before {
  content: 'o  ';
}

.lst-kix_list_18-4 > li:before {
  content: '' counter(lst-ctn-kix_list_18-4, lower-latin) '. ';
}

.lst-kix_list_7-7 > li {
  counter-increment: lst-ctn-kix_list_7-7;
}

ol.lst-kix_list_15-1.start {
  counter-reset: lst-ctn-kix_list_15-1 0;
}

ol.lst-kix_list_15-4.start {
  counter-reset: lst-ctn-kix_list_15-4 0;
}

ol.lst-kix_list_1-7 {
  list-style-type: none;
}

ol.lst-kix_list_1-8 {
  list-style-type: none;
}

.lst-kix_list_36-6 > li:before {
  content: '' counter(lst-ctn-kix_list_36-6, decimal) '. ';
}

ol.lst-kix_list_39-2.start {
  counter-reset: lst-ctn-kix_list_39-2 0;
}

.lst-kix_list_10-3 > li:before {
  content: '\0025cf  ';
}

ul.lst-kix_list_11-7 {
  list-style-type: none;
}

.lst-kix_list_15-4 > li {
  counter-increment: lst-ctn-kix_list_15-4;
}

ul.lst-kix_list_11-6 {
  list-style-type: none;
}

ol.lst-kix_list_7-3.start {
  counter-reset: lst-ctn-kix_list_7-3 0;
}

ul.lst-kix_list_11-5 {
  list-style-type: none;
}

.lst-kix_list_36-0 > li:before {
  content: '' counter(lst-ctn-kix_list_36-0, decimal) '. ';
}

.lst-kix_list_36-8 > li:before {
  content: '' counter(lst-ctn-kix_list_36-8, lower-roman) '. ';
}

ol.lst-kix_list_40-2.start {
  counter-reset: lst-ctn-kix_list_40-2 0;
}

ul.lst-kix_list_11-4 {
  list-style-type: none;
}

ul.lst-kix_list_11-3 {
  list-style-type: none;
}

ul.lst-kix_list_11-2 {
  list-style-type: none;
}

ul.lst-kix_list_11-1 {
  list-style-type: none;
}

ul.lst-kix_list_11-0 {
  list-style-type: none;
}

.lst-kix_list_34-0 > li {
  counter-increment: lst-ctn-kix_list_34-0;
}

ul.lst-kix_list_11-8 {
  list-style-type: none;
}

.lst-kix_list_20-8 > li:before {
  content: '' counter(lst-ctn-kix_list_20-8, decimal) '. ';
}

.lst-kix_list_39-1 > li {
  counter-increment: lst-ctn-kix_list_39-1;
}

.lst-kix_list_29-7 > li:before {
  content: '' counter(lst-ctn-kix_list_29-7, lower-latin) '. ';
}

.lst-kix_list_29-5 > li:before {
  content: '' counter(lst-ctn-kix_list_29-5, lower-roman) '. ';
}

.lst-kix_list_20-0 > li:before {
  content: '' counter(lst-ctn-kix_list_20-0, decimal) '. ';
}

.lst-kix_list_9-6 > li:before {
  content: '' counter(lst-ctn-kix_list_9-6, decimal) ') ';
}

.lst-kix_list_9-4 > li:before {
  content: '' counter(lst-ctn-kix_list_9-4, lower-latin) '. ';
}

.lst-kix_list_30-8 > li {
  counter-increment: lst-ctn-kix_list_30-8;
}

ol.lst-kix_list_34-5.start {
  counter-reset: lst-ctn-kix_list_34-5 0;
}

.lst-kix_list_20-6 > li:before {
  content: '' counter(lst-ctn-kix_list_20-6, decimal) '. ';
}

.lst-kix_list_23-0 > li {
  counter-increment: lst-ctn-kix_list_23-0;
}

ul.lst-kix_list_2-8 {
  list-style-type: none;
}

ol.lst-kix_list_12-1.start {
  counter-reset: lst-ctn-kix_list_12-1 0;
}

.lst-kix_list_11-5 > li:before {
  content: '\0025aa  ';
}

ul.lst-kix_list_2-2 {
  list-style-type: none;
}

ul.lst-kix_list_2-3 {
  list-style-type: none;
}

ul.lst-kix_list_2-0 {
  list-style-type: none;
}

ul.lst-kix_list_2-1 {
  list-style-type: none;
}

ol.lst-kix_list_36-2.start {
  counter-reset: lst-ctn-kix_list_36-2 0;
}

ul.lst-kix_list_2-6 {
  list-style-type: none;
}

.lst-kix_list_20-6 > li {
  counter-increment: lst-ctn-kix_list_20-6;
}

.lst-kix_list_1-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-1, lower-latin) ') ';
}

ul.lst-kix_list_2-7 {
  list-style-type: none;
}

.lst-kix_list_11-7 > li:before {
  content: 'o  ';
}

ul.lst-kix_list_2-4 {
  list-style-type: none;
}

ul.lst-kix_list_2-5 {
  list-style-type: none;
}

.lst-kix_list_8-5 > li {
  counter-increment: lst-ctn-kix_list_8-5;
}

.lst-kix_list_1-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-3, decimal) ') ';
}

ol.lst-kix_list_34-6.start {
  counter-reset: lst-ctn-kix_list_34-6 0;
}

.lst-kix_list_28-3 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_14-7 > li {
  counter-increment: lst-ctn-kix_list_14-7;
}

ol.lst-kix_list_7-5.start {
  counter-reset: lst-ctn-kix_list_7-5 0;
}

ol.lst-kix_list_31-5.start {
  counter-reset: lst-ctn-kix_list_31-5 0;
}

ol.lst-kix_list_40-4.start {
  counter-reset: lst-ctn-kix_list_40-4 0;
}

.lst-kix_list_27-7 > li:before {
  content: 'o  ';
}

ol.lst-kix_list_39-4.start {
  counter-reset: lst-ctn-kix_list_39-4 0;
}

.lst-kix_list_28-1 > li:before {
  content: 'o  ';
}

ol.lst-kix_list_19-6 {
  list-style-type: none;
}

.lst-kix_list_35-2 > li:before {
  content: '' counter(lst-ctn-kix_list_35-2, lower-roman) '. ';
}

ol.lst-kix_list_19-7 {
  list-style-type: none;
}

ol.lst-kix_list_19-8 {
  list-style-type: none;
}

.lst-kix_list_30-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_30-3, decimal) ') ';
}

ol.lst-kix_list_19-2 {
  list-style-type: none;
}

ol.lst-kix_list_34-0.start {
  counter-reset: lst-ctn-kix_list_34-0 0;
}

ol.lst-kix_list_19-3 {
  list-style-type: none;
}

ol.lst-kix_list_19-4 {
  list-style-type: none;
}

.lst-kix_list_30-8 > li:before {
  content: '' counter(lst-ctn-kix_list_30-8, lower-roman) ') ';
}

ol.lst-kix_list_19-5 {
  list-style-type: none;
}

.lst-kix_list_35-7 > li:before {
  content: '' counter(lst-ctn-kix_list_35-7, lower-latin) '. ';
}

.lst-kix_list_30-5 > li {
  counter-increment: lst-ctn-kix_list_30-5;
}

ol.lst-kix_list_19-0 {
  list-style-type: none;
}

ol.lst-kix_list_39-1.start {
  counter-reset: lst-ctn-kix_list_39-1 0;
}

ol.lst-kix_list_19-1 {
  list-style-type: none;
}

ol.lst-kix_list_7-7.start {
  counter-reset: lst-ctn-kix_list_7-7 0;
}

.lst-kix_list_3-1 > li:before {
  content: ' ';
}

.lst-kix_list_26-6 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_14-0 > li {
  counter-increment: lst-ctn-kix_list_14-0;
}

ol.lst-kix_list_17-4.start {
  counter-reset: lst-ctn-kix_list_17-4 0;
}

.lst-kix_list_33-6 > li {
  counter-increment: lst-ctn-kix_list_33-6;
}

.lst-kix_list_8-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_8-2, lower-roman) ') ';
}

ol.lst-kix_list_36-7.start {
  counter-reset: lst-ctn-kix_list_36-7 0;
}

ol.lst-kix_list_31-6.start {
  counter-reset: lst-ctn-kix_list_31-6 0;
}

.lst-kix_list_12-0 > li {
  counter-increment: lst-ctn-kix_list_12-0;
}

ol.lst-kix_list_12-3.start {
  counter-reset: lst-ctn-kix_list_12-3 0;
}

ol.lst-kix_list_40-0.start {
  counter-reset: lst-ctn-kix_list_40-0 0;
}

.lst-kix_list_21-2 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_8-5 > li:before {
  content: '' counter(lst-ctn-kix_list_8-5, lower-roman) '. ';
}

.lst-kix_list_15-1 > li {
  counter-increment: lst-ctn-kix_list_15-1;
}

.lst-kix_list_26-3 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_3-6 > li:before {
  content: ' ';
}

.lst-kix_list_36-3 > li {
  counter-increment: lst-ctn-kix_list_36-3;
}

.lst-kix_list_21-7 > li:before {
  content: 'o  ';
}

.lst-kix_list_11-2 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_39-4 > li {
  counter-increment: lst-ctn-kix_list_39-4;
}

.lst-kix_list_40-4 > li {
  counter-increment: lst-ctn-kix_list_40-4;
}

ol.lst-kix_list_31-7.start {
  counter-reset: lst-ctn-kix_list_31-7 0;
}

ol.lst-kix_list_12-4.start {
  counter-reset: lst-ctn-kix_list_12-4 0;
}

.lst-kix_list_16-6 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_35-2 > li {
  counter-increment: lst-ctn-kix_list_35-2;
}

.lst-kix_list_25-2 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_18-2 > li {
  counter-increment: lst-ctn-kix_list_18-2;
}

.lst-kix_list_29-5 > li {
  counter-increment: lst-ctn-kix_list_29-5;
}

.lst-kix_list_22-6 > li {
  counter-increment: lst-ctn-kix_list_22-6;
}

.lst-kix_list_16-1 > li:before {
  content: 'o  ';
}

.lst-kix_list_7-3 > li {
  counter-increment: lst-ctn-kix_list_7-3;
}

ol.lst-kix_list_39-0.start {
  counter-reset: lst-ctn-kix_list_39-0 0;
}

.lst-kix_list_19-3 > li {
  counter-increment: lst-ctn-kix_list_19-3;
}

ol.lst-kix_list_7-8.start {
  counter-reset: lst-ctn-kix_list_7-8 0;
}

.lst-kix_list_12-4 > li {
  counter-increment: lst-ctn-kix_list_12-4;
}

.lst-kix_list_23-7 > li {
  counter-increment: lst-ctn-kix_list_23-7;
}

.lst-kix_list_12-7 > li {
  counter-increment: lst-ctn-kix_list_12-7;
}

.lst-kix_list_39-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_39-2, lower-roman) ') ';
}

.lst-kix_list_40-0 > li {
  counter-increment: lst-ctn-kix_list_40-0;
}

.lst-kix_list_34-7 > li {
  counter-increment: lst-ctn-kix_list_34-7;
}

.lst-kix_list_17-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_17-2, lower-roman) ') ';
}

.lst-kix_list_30-0 > li:before {
  content: '' counter(lst-ctn-kix_list_30-0, decimal) '. ';
}

ol.lst-kix_list_17-3.start {
  counter-reset: lst-ctn-kix_list_17-3 0;
}

ol.lst-kix_list_40-1.start {
  counter-reset: lst-ctn-kix_list_40-1 0;
}

.lst-kix_list_17-5 > li:before {
  content: '' counter(lst-ctn-kix_list_17-5, lower-roman) '. ';
}

ol.lst-kix_list_36-6.start {
  counter-reset: lst-ctn-kix_list_36-6 0;
}

.lst-kix_list_27-2 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_22-3 > li:before {
  content: '' counter(lst-ctn-kix_list_22-3, decimal) '. ';
}

.lst-kix_list_7-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_7-1, lower-latin) ') ';
}

.lst-kix_list_13-5 > li {
  counter-increment: lst-ctn-kix_list_13-5;
}

.lst-kix_list_9-6 > li {
  counter-increment: lst-ctn-kix_list_9-6;
}

.lst-kix_list_29-2 > li {
  counter-increment: lst-ctn-kix_list_29-2;
}

ol.lst-kix_list_23-3.start {
  counter-reset: lst-ctn-kix_list_23-3 0;
}

.lst-kix_list_20-3 > li {
  counter-increment: lst-ctn-kix_list_20-3;
}

.lst-kix_list_31-7 > li:before {
  content: '' counter(lst-ctn-kix_list_31-7, lower-latin) ') ';
}

ol.lst-kix_list_17-8.start {
  counter-reset: lst-ctn-kix_list_17-8 0;
}

ol.lst-kix_list_34-1.start {
  counter-reset: lst-ctn-kix_list_34-1 0;
}

.lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}

.lst-kix_list_37-1 > li {
  counter-increment: lst-ctn-kix_list_37-1;
}

ol.lst-kix_list_17-5.start {
  counter-reset: lst-ctn-kix_list_17-5 0;
}

.lst-kix_list_4-2 > li:before {
  content: ' ';
}

ol.lst-kix_list_23-6.start {
  counter-reset: lst-ctn-kix_list_23-6 0;
}

.lst-kix_list_17-4 > li {
  counter-increment: lst-ctn-kix_list_17-4;
}

.lst-kix_list_15-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_15-2, lower-roman) ') ';
}

.lst-kix_list_36-3 > li:before {
  content: '' counter(lst-ctn-kix_list_36-3, decimal) '. ';
}

.lst-kix_list_10-6 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_9-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_9-1, lower-latin) ') ';
}

ol.lst-kix_list_12-7.start {
  counter-reset: lst-ctn-kix_list_12-7 0;
}

.lst-kix_list_40-7 > li {
  counter-increment: lst-ctn-kix_list_40-7;
}

.lst-kix_list_15-8 > li {
  counter-increment: lst-ctn-kix_list_15-8;
}

.lst-kix_list_40-8 > li:before {
  content: '' counter(lst-ctn-kix_list_40-8, lower-roman) ') ';
}

ol.lst-kix_list_36-8.start {
  counter-reset: lst-ctn-kix_list_36-8 0;
}

.lst-kix_list_37-8 > li {
  counter-increment: lst-ctn-kix_list_37-8;
}

.lst-kix_list_31-3 > li {
  counter-increment: lst-ctn-kix_list_31-3;
}

ol.lst-kix_list_12-8.start {
  counter-reset: lst-ctn-kix_list_12-8 0;
}

.lst-kix_list_20-3 > li:before {
  content: '' counter(lst-ctn-kix_list_20-3, decimal) '. ';
}

.lst-kix_list_29-2 > li:before {
  content: '' counter(lst-ctn-kix_list_29-2, lower-roman) '. ';
}

ol.lst-kix_list_23-5.start {
  counter-reset: lst-ctn-kix_list_23-5 0;
}

.lst-kix_list_8-8 > li {
  counter-increment: lst-ctn-kix_list_8-8;
}

ol.lst-kix_list_17-6.start {
  counter-reset: lst-ctn-kix_list_17-6 0;
}

.lst-kix_list_28-6 > li:before {
  content: '\0025cf  ';
}

.lst-kix_list_1-6 > li:before {
  content: '' counter(lst-ctn-kix_list_1-6, decimal) ') ';
}

.lst-kix_list_33-7 > li:before {
  content: '' counter(lst-ctn-kix_list_33-7, lower-latin) ') ';
}

.lst-kix_list_12-6 > li:before {
  content: '' counter(lst-ctn-kix_list_12-6, decimal) ') ';
}

.lst-kix_list_40-0 > li:before {
  content: '' counter(lst-ctn-kix_list_40-0, decimal) '. ';
}

ol.lst-kix_list_23-4.start {
  counter-reset: lst-ctn-kix_list_23-4 0;
}

.lst-kix_list_34-3 > li:before {
  content: '' counter(lst-ctn-kix_list_34-3, decimal) '. ';
}

.lst-kix_list_2-2 > li:before {
  content: '\0025aa  ';
}

.lst-kix_list_32-1 > li {
  counter-increment: lst-ctn-kix_list_32-1;
}

.lst-kix_list_13-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_13-2, lower-roman) ') ';
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c4 {
  margin-left: 0pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 12pt;
  
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c3 {
  margin-left: 36pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 90pt;
  padding-bottom: 12pt;
  
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c29 {
  padding-top: 0pt;
  text-indent: 36pt;
  padding-bottom: 0pt;
  
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 18pt;
}

.c17 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c30 {
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 90pt;
  padding-bottom: 0pt;
  
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c0 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 1em;

  font-style: normal;
}

.c18 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 1em;
}

.c32 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  
  orphans: 2;
  widows: 2;
  text-align: center;
  height: 1em;
}

.c33 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 1em;
}

.c34 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c13 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c26 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  
  orphans: 2;
  widows: 2;
  text-align: center;
}

.c24 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c7 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c16 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c5 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
}

.c8 {
  vertical-align: baseline;
  font-size: 1em;
}

.c20 {
  text-decoration: inherit;
}

.c1 {
  font-weight: 400;
}

.c27 {
  list-style-position: inside;
  text-indent: 36pt;
}

.c19 {
  margin-left: 36pt;
  padding-left: 0pt;
}

.c21 {
  font-weight: 400;
}

.c2 {
  font-weight: 700;
}

.c31 {
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}

.c12 {
  padding: 0;
  margin: 0;
}

.c6 {
  font-style: italic;
}

.c11 {
  font-style: normal;
}

.c25 {
  margin-left: 36pt;
}

.c23 {
  font-weight: 700;
}

.c9 {
  text-decoration: none;
}

.title {
  padding-top: 24pt;

  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;

  
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 18pt;

  font-size: 24pt;
  padding-bottom: 4pt;

  
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  font-size: 1em;
}

p {
  margin: 0;

  font-size: 1em;
}

h1 {
  padding-top: 0pt;

  font-weight: 700;
  font-size: 1em;
  padding-bottom: 12pt;

  
  orphans: 2;
  widows: 2;
  text-align: justify;
}

h2 {
  padding-top: 0pt;

  font-size: 1em;
  padding-bottom: 12pt;

  
  orphans: 2;
  widows: 2;
  text-align: justify;
}

h3 {
  padding-top: 0pt;

  font-size: 1em;
  padding-bottom: 12pt;

  
  orphans: 2;
  widows: 2;
  text-align: justify;
}

h4 {
  padding-top: 0pt;

  font-size: 1em;
  padding-bottom: 12pt;

  
  orphans: 2;
  widows: 2;
  text-align: justify;
}

h5 {
  padding-top: 0pt;

  font-size: 1em;
  padding-bottom: 12pt;

  
  orphans: 2;
  widows: 2;
  text-align: justify;
}

h6 {
  padding-top: 0pt;

  font-size: 1em;
  padding-bottom: 12pt;

  
  orphans: 2;
  widows: 2;
  text-align: justify;
}
</style>
