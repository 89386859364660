const LAUNCHDARKLY_TEST_ENVIRONMENT_ID = '628d1e137537a114f04c18b3';
const LAUNCHDARKLY_PRODUCTION_ENVIRONMENT_ID = '628d1e137537a114f04c18b4';

const config = {
  launchdarkly: {
    user: null,
    clientSideId: LAUNCHDARKLY_TEST_ENVIRONMENT_ID
  },
  flags: {
    [0]: { key: 'impostors.gg.chest_drop_enabled', status: null },
    [1]: { key: 'impostors.gg.chest_drop_live_time', status: null },

    [2]: { key: 'impostors.gg.pet_drop_enabled', status: null },
    [3]: { key: 'impostors.gg.pet_drop_live_time', status: null },

    [4]: { key: 'impostors.gg.box_drop_enabled', status: null },
    [5]: { key: 'impostors.gg.box_drop_live_time', status: null },

    [6]: { key: 'impostors.gg.pass_drop_enabled', status: null },
    [7]: { key: 'impostors.gg.pass_drop_live_time', status: null },

    [8]: { key: 'impostors.gg.qa.redeemerAddress', status: null },

    [9]: { key: 'impostors.gg.staker_v2_enabled', status: null },

	[10]: { key: 'impostors.gg.voxel_enabled', status: null },
    [11]: { key: 'impostors.gg.voxel_claim_live_time', status: null },

    [12]: { key: 'impostors.gg.playfab_enabled', status: null }
  },
  ALL_FLAGS: 13 // count the number of config.flags
};

export default config;
