'use strict';

// Imports.

// Specified actions for mutating the alert state.
export default {
  async initialize({ dispatch, commit }) {
    commit('initialize');
  },

  async playFabUpdate({ dispatch, commit }, pf) {
    commit('playFabUpdate', pf);
  }
};
