'use strict';

import { ref, computed } from 'vue';
import { PlayFab, PlayFabClient } from 'playfab-sdk';

import config from './config';

import { l } from '@/datadog';
const logName = 'account.helper.js';

const response = ref({});
let store = null;

/*
 *********
 * SETUP *
 *********
 */
const init = (st, fd) => {
  store = st;
  fields = fd;

  // reset errors
  errors.value = {};

  _setup();

  return {
    isSignupButtonDisabled,
    isImpostorsLoggedIn,
    isImpostorsError,
    loggedInUser
  };
};

const _setup = () => {
  PlayFab.settings.titleId = config.playfab.titleId;
};

const loggedInUser = computed(() => {
  return store.state.login?.playfab;
});

/*
 *********
 * AUTH  *
 *********
 */
let fields = null;
const errors = ref({});
const authResponse = ref({ id: null });

const status = st => {
  store = st;
  return { isImpostorsLoggedIn, loggedInUser };
};

const impostorsErrors = () => {
  return authResponse?.value?.errorMessage;
};

const isSignupButtonDisabled = computed(() => {
  let disabled = {
	status: true,
	errors: null
  };
  for (let prop in fields.value) {
    if (!fields.value[prop] || errors.value[prop]) {
	  disabled.status = true;
      disabled.errors = errors.value[prop];
	  console.log("disabled", disabled, prop, errors.value[prop])
      break;
    }
    disabled.status = false;
  }
  return disabled;
});

const isImpostorsLoggedIn = computed(() => {
  return store.state.login?.playfab?.PlayFabId != null;
});

const isImpostorsError = computed(() => {
  return authResponse?.value?.errorMessage != null;
});

const useValidators = () => {
  const isEmpty = (fieldName, fieldValue) => {
    return !fieldValue ? 'The ' + fieldName + ' field is required' : '';
  };

  const minLength = (fieldName, fieldValue, min) => {
    return fieldValue.length < min
      ? `The ${fieldName} must be at least ${min} characters long`
      : '';
  };

  const isEmail = (fieldName, fieldValue) => {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(fieldValue)
      ? 'The input is not a valid ' + fieldName + ' address'
      : '';
  };

  const isNum = (fieldName, fieldValue) => {
    let isNum = /^\d+$/.test(fieldValue);
    return !isNum ? 'The ' + fieldName + ' field only have numbers' : '';
  };

  const isEqual = (msg, fieldValue1, fieldValue2) => {
    return fieldValue1 != fieldValue2 ? msg : '';
  };

  return { isEmpty, minLength, isEmail, isNum, isEqual };
};

// const useSubmitButtonState = (user, errors) => {
//   const isSignupButtonDisabled = computed(() => {
//     let disabled = true;
//     for (let prop in user.value) {
//       if (!user.value[prop] || errors.value[prop]) {
//         disabled = true;
//         break;
//       }
//       disabled = false;
//     }
//     return disabled;
//   });

//   return { isSignupButtonDisabled };
// };

const useFormValidation = () => {
  const { isEmpty, minLength, isEmail, isNum, isEqual } = useValidators();

  const validateNameField = (fieldName, fieldValue) => {
    errors.value[fieldName] = !fieldValue
      ? isEmpty(fieldName, fieldValue)
      : minLength(fieldName, fieldValue, 4);
  };

  const validateEmailField = (fieldName, fieldValue) => {
    errors.value[fieldName] = !fieldValue
      ? isEmpty(fieldName, fieldValue)
      : isEmail(fieldName, fieldValue);
  };

  const validatePasswordField = (fieldName, fieldValue) => {
    errors.value[fieldName] = !fieldValue
      ? isEmpty(fieldName, fieldValue)
      : minLength(fieldName, fieldValue, 8);
  };

  const validatePasswordConfirmationField = (
    fieldName1,
    fieldValue1,
    fieldName2,
    fieldValue2
  ) => {
    errors.value[fieldName2] = isEqual(
      'Passwords are not equal',
      fieldValue1,
      fieldValue2
    );
  };

  return {
    errors,
    validateNameField,
    validateEmailField,
    validatePasswordField,
    validatePasswordConfirmationField
  };
};

/*
 ***********
 * METHODS *
 ***********
 */
const _updateStore = st => {
  store.dispatch('login/playFabUpdate', st);
  _updateUserDataPublisher();
};

const impostorsLogin = ({ Password, Email }) => {
  const keyValue = 'login';
  const login = () => response.value[keyValue];
  try {
    PlayFabClient.LoginWithEmailAddress(
      {
        TitleId: config.playfab.titleId,
        Password: Password,
        Email: Email,
        InfoRequestParameters: {
          GetPlayerProfile: true
        }
      },
      (err, rst) => {
        if (err == null) {
          const x = rst.data;
          x['Email'] = Email;
          x['Password'] = Password;
          response.value[keyValue] = x;
          _updateStore(x);
        } else {
          response.value[keyValue] = err;
          authResponse.value = err;
        }
      }
    );
  } catch (errorReason) {
    response.value[keyValue] = null;
    l.err(this, { f: [logName, keyValue], err: errorReason });
  }

  l.dbg(this, {
    f: [logName, keyValue],
    p: [Email],
    r: [response.value[keyValue]]
  });
  return { login };
};

// if PlayFabClient loses authentication and session, this allows us to re-authenticate
const _login = () => {
  if (isImpostorsLoggedIn.value) {
    _setup();
    impostorsLogin({
      Email: loggedInUser.value.Email,
      Password: loggedInUser.value.Password
    });
  }
};

const impostorsRegister = ({ Email, Password }) => {
  const keyValue = 'register';
  const register = () => response.value[keyValue];
  try {
    PlayFabClient.RegisterPlayFabUser(
      {
        TitleId: config.playfab.titleId,
        Email: Email,
        Password: Password,
        RequireBothUsernameAndEmail: false
        // Username: Username
      },
      (err, rst) => {
        if (err == null) {
          const x = rst.data;
          x['Email'] = Email;
          x['Password'] = Password;
          response.value[keyValue] = x;
          _updateStore(x);
        } else {
          response.value[keyValue] = err;
          authResponse.value = err;
        }
      }
    );
  } catch (errorReason) {
    response.value[keyValue] = null;
    l.err(this, { f: [logName, keyValue], err: errorReason });
  }

  l.dbg(this, {
    f: [logName, keyValue],
    p: [Email],
    r: [response.value[keyValue]]
  });
  return { register };
};

// used mostly to enforce connectivity with playfab
// as some tests seem to indicate that the PlayFabClient
// session is being trampled (somewhere...)
const _playfabConnectionCheck = () => {
  const isLoading = ref(true);

  const keyValue = 'accountInfo';
  const accountInfo = () => response.value[keyValue]?.AccountInfo;
  try {
    PlayFabClient.GetAccountInfo(
      {
        PlayFabId: loggedInUser.value.PlayFabId
      },
      (err, rst) => {
        if (err == null) {
          l.err(this, {
            f: [logName, '_playfabConnectionCheck'],
            success: true
          });
          response.value[keyValue] = rst.data;
        } else {
          l.err(this, {
            f: [logName, '_playfabConnectionCheck'],
            success: err
          });
          response.value[keyValue] = err;
        }
        isLoading.value = false;
      }
    );
  } catch (errorReason) {
    response.value[keyValue] = null;
    l.err(this, { f: [logName, '_playfabConnectionCheck'], err: errorReason });
    _login();
  }

  if (!accountInfo()) {
    setTimeout(() => {
      const { accountInfo, isLoading } = _playfabConnectionCheck();
      return { accountInfo, isLoading };
    }, 1000);
  }

  return { accountInfo, isLoading };
};

const _playfabErrorCheck = errorReason => {
  return errorReason == 'Must be logged in to call this method';
};

// Updates logged-in user's list of wallets
const _updateUserDataPublisher = () => {
  // validate connection
  const { accountInfo, isLoading } = _playfabConnectionCheck();

  const keyValue = 'userData';
  const userData = () => response.value[keyValue];
  try {
    PlayFabClient.GetUserPublisherData(
      {
        PlayFabId: loggedInUser.value.PlayFabId,
        Keys: ['Ethereum']
      },
      (err, rst) => {
        if (err == null) {
          response.value[keyValue] = rst.data;
          let st = loggedInUser.value;
          st['Wallets'] = rst.data.Data?.Ethereum?.Value?.split(',');
          store.dispatch('login/playFabUpdate', st);
        } else {
          response.value[keyValue] = err;
        }
      }
    );

	PlayFabClient.GetUserInventory(
		{
		  PlayFabId: loggedInUser.value.PlayFabId
		},
		(err, rst) => {
		  if (err == null) {
			  console.log("inventory data", rst);
			  let st = loggedInUser.value;
			  st['Inventory'] = rst.data.Inventory;
			  store.dispatch('login/playFabUpdate', st);
		  } else {
			response.value[keyValue] = err;
		  }
		}
	  );

  } catch (errorReason) {
    response.value[keyValue] = null;
    l.err(this, { f: [logName, keyValue], err: errorReason });
  }

  // in the (limited) case that connectivity is still lacking
  // we affect one more call to playfab
  /*
  if (!userData()) {
    setTimeout(() => {
      const { userData } = impostorsUserData();
      return userData;
    }, 1000);
  }
  */

  l.dbg(this, { f: [logName, keyValue], r: [response.value[keyValue]] });
  return { userData };
};

// Updates logged-in user's 'Player Data (Publisher)' on playfab's management interface
const impostorsUpdateUserData = ({ data }) => {
  // validate connection
  const { accountInfo, isLoading } = _playfabConnectionCheck();

  const keyValue = 'updateUserData';
  const updateUserData = () => response.value[keyValue];
  try {
    PlayFabClient.UpdateUserPublisherData(
      {
        PlayFabId: loggedInUser.value.PlayFabId,
        Data: data
      },
      (err, rst) => {
        if (err == null) {
          response.value[keyValue] = rst.status;
          // now that we have succesfully updated on PlayFab, we should be able to
          // query playfab again, and expect to see the updated info
          _updateUserDataPublisher();
        } else {
          response.value[keyValue] = err;
        }
      }
    );
  } catch (errorReason) {
    response.value[keyValue] = null;
    l.err(this, { f: [logName, keyValue], err: errorReason });
    // sometimes PlayFab will fail (incorrectly) on updates if
    // the user recently (<1000ms) logged in. this becomes a problem
    // when a user attempts to add/remove a wallet while not having recently
    // re-authenticated with PlayFab. Luckly, the error message is one stating
    // they need to login. For this reason, we check against it, and will silently
    // re-attempt to update the data. On most cases, we do not need to do this, but
    // when we do, it often takes 2 attempts for it to work
    // TODO: consider increasing timeout time
    if (_playfabErrorCheck(errorReason)) {
      setTimeout(() => impostorsUpdateUserData({ data }), 500);
    }
  }

  l.dbg(this, {
    f: [logName, keyValue],
    p: [data],
    r: [response.value[keyValue]]
  });
  return { updateUserData };
};

// durable object call to check wallet owner
const indexerQuery = async ({ addr, token, host }) => {
  const keyValue = 'walletOwner';
  const walletOwner = () => response.value[keyValue];
  try {
    let doQuery = await config.durableObjects.genPath({
      host: host,
      addr: addr,
      action: config.durableObjects.FIND,
      param: '',
      token: token
    });
    if (!doQuery.ok) {
      response.value[keyValue] = doQuery;
    } else {
      const data = await doQuery.text();
      response.value[keyValue] = data;
    }
  } catch (errorReason) {
    response.value[keyValue] = null;
    l.err(this, {
      f: [logName, keyValue],
      err: errorReason
    });
  }

  l.dbg(this, {
    f: [logName, keyValue],
    p: [addr, token],
    r: [response.value[keyValue]]
  });
  return { walletOwner };
};

const indexerUpdate = async ({ host, pfID, addr, token }) => {
  const keyValue = 'updatedWalletOwner';
  const updatedWalletOwner = () => response.value[keyValue];
  try {
    let doQuery = await config.durableObjects.genPath({
      host: host,
      addr: addr,
      action: config.durableObjects.SET,
      param: pfID,
      token: token
    });
    if (!doQuery.ok) {
      response.value[keyValue] = doQuery;
    } else {
      const data = await doQuery.text();
      response.value[keyValue] = data;
    }
  } catch (errorReason) {
    response.value[keyValue] = null;
    l.err(this, {
      f: [logName, keyValue],
      err: errorReason
    });
  }

  l.dbg(this, {
    f: [logName, keyValue],
    p: [pfID, addr, token],
    r: [response.value[keyValue]]
  });
  return { updatedWalletOwner };
};

const impostorsDeleteWalletOwner = async ({ host, addr, token }) => {
  const keyValue = 'deleteWalletOwner';
  const deleteWalletOwner = () => response.value[keyValue];
  try {
    let doQuery = await config.durableObjects.genPath({
      host: host,
      addr: addr,
      action: config.durableObjects.DELETE,
      param: '',
      token: token
    });
    if (!doQuery.ok) {
      response.value[keyValue] = doQuery;
    } else {
      const data = await doQuery.text();
      response.value[keyValue] = data;
    }
  } catch (errorReason) {
    response.value[keyValue] = null;
    l.err(this, {
      f: [logName, keyValue],
      err: errorReason
    });
  }

  l.dbg(this, {
    f: [logName, keyValue],
    p: [addr, token],
    r: [response.value[keyValue]]
  });
  return { deleteWalletOwner };
};

function _padLeft(nr, n, str) {
  return Array(n - String(nr).length + 1).join(str || '0') + nr;
}

// Export the user service functions.
export {
  useFormValidation,
  impostorsLogin,
  impostorsErrors,
  impostorsRegister,
  impostorsUpdateUserData,
  indexerQuery,
  indexerUpdate,
  impostorsDeleteWalletOwner,
  init,
  status
};
