<script setup>
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import {
  IMPOSTOR_TOKEN_TYPE,
  UFO_TOKEN_TYPE,
  PET_TOKEN_TYPE,
  BOX_TOKEN_TYPE,
  CHEST_TOKEN_TYPE,
  PASS_TOKEN_TYPE
} from '@/constants';

import { computed } from 'vue';
import ImpostorPreviewCard from '@/components/common/ImpostorPreviewCard.vue';

const props = defineProps({
  token: { type: Object },
  tokenType: { type: String, default: IMPOSTOR_TOKEN_TYPE },
  isDisabled: { type: Boolean },
  isSelected: { type: Boolean },
  showTitle: { type: Boolean, default: () => true },
  defaultTitle: { type: String },
  titleTop: { type: Boolean },
  hidePoolInfo: { type: Boolean },
  checkVariant: { type: String, default: 'bottomRight' },
  classCard: { type: String },
});
const TitleNames = {
  [IMPOSTOR_TOKEN_TYPE]: 'IMPOSTOR',
  [UFO_TOKEN_TYPE]: 'UFO',
  [PET_TOKEN_TYPE]: 'PET',
  [BOX_TOKEN_TYPE]: 'BOX',
  [CHEST_TOKEN_TYPE]: 'CHEST',
  [PASS_TOKEN_TYPE]: 'PASS',
};

let titleText = computed(() => {
  if (!props.token?.tokenId && props.defaultTitle) {
    return props.defaultTitle;
  }
  return `${TitleNames[props.tokenType]} #${props.token.tokenId}`;
});
</script>

<template>
  <div>
    <p class="title" v-if="props.showTitle && props.titleTop">
      {{ titleText }}
    </p>
    <div
      class="token-card"
      :class="[{
        disabled: isDisabled,
        selected: isSelected,
        noTitle: !showTitle
      }, props.classCard]"
    >
      <SkewedDiv
        v-if="token.isStaked && !props.hidePoolInfo"
        :borderRadius="6"
        background="black"
        opacity="0.6"
        :skew="[0, 0, [-6, 0], [-6, 0]]"
        class="info"
      >
        <div class="info-content">
          <span class="red">{{ token.bloodPerDay }} $BLOOD/day</span>

          <span v-if="token.daysTotal > 0">
            {{ token.daysRemaining }}/{{ token.daysTotal }} days remaining
          </span>
          <span v-else>FLEXIBLE</span>
        </div>
      </SkewedDiv>
      <ImpostorPreviewCard
        class="image"
        alt="Impostor Image"
        :tokenId="token.tokenId"
        :tokenType="tokenType"
      />
      <img
        v-if="isSelected"
        class="check"
        src="@/images/yellow-check.webp"
        :class="{ checkBottomRight: props.checkVariant === 'bottomRight' }"
      />
    </div>
    <p class="title" v-if="props.showTitle && !props.titleTop">
      {{ titleText }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.token-card {
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  display: flex;

  &:hover {
    box-shadow: 0 0 0 3px var(--color-button-yellow);
  }

  .info {
    z-index: 2;
    position: absolute;
    width: 90%;
    padding: 14px;
    bottom: 10%;
    left: 5%;
    right: 5%;
    font-weight: 600;

    .info-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      text-align: center;

      .red {
        color: #ffa500;
      }
    }
  }

  .image {
    height: auto;
    width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
    border-radius: 20px;
  }

  .check {
    width: 16%;
    height: auto;
    object-fit: contain;
    position: absolute;
    top: 6%;
    right: 4%;
  }

  .checkBottomRight {
    top: initial;
    bottom: 6%;
    width: 30%;
  }
}

.selected {
  /* border: 3px solid var(--color-button-yellow); */
  box-shadow: 0 0 0 3px var(--color-button-yellow);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.title {
  text-align: center;
  font-size: 17px;
  padding: 12px 0;
  text-transform: uppercase;
}
.noTitle {
  height: 100%;
  width: 100%;
}
</style>
