<template>
  <component
    :is="props.is"
    :id="id"
    class="skewed-box"
  >
    <div
      :class="props.polygonClasses"
      :style="polygonStyle"
      class="skewed-box__poligon"
    />
    <div
      v-if="slots.default"
      class="skewed-box__content"
    >
      <slot name="default" />
    </div>
  </component>
</template>

<script setup>
import { computed, defineProps, onMounted, ref, useSlots } from 'vue';
import { isNaN } from 'lodash';
import { v4 as uuid } from 'uuid';
import { find2x2MatrixDeterminant, inverse2x2Matrix, multiplyMatrix } from '@/utility';

const props = defineProps({
  is: { type: null, required: false, default: 'div' },
  polygonBorderRadius: { type: Number, required: false, default: 0 },
  polygonClasses: { type: String, required: false, default: '' },
  polygonOffsets: {
    type: Array,
    required: false,
    default: () => [[0, 0], [0, 0], [0, 0], [0, 0]],
    validator: (value) => {
      if (value.length !== 4) return false;
      let valid = true;
      for (let offset of value) {
        if (offset.length !== 2 || isNaN(parseFloat(offset[0])) || isNaN(parseFloat(offset[1]))) {
          valid = false;
          break;
        }
      };
      return valid;
    }
  }
});

const slots = useSlots();

const perspective = 360;

const id = ref(uuid());

const contentSize = ref({width: 0, height: 0});

const resizeObserver = new ResizeObserver(entries => {
  const rect = entries[0].contentRect;
  contentSize.value = { width: rect.width, height: rect.height };
});

const corners = computed(() => ({
  top: {
    left: {
      x: contentSize.value.width - props.polygonOffsets[0][0],
      y: contentSize.value.height - props.polygonOffsets[0][1]
    },
    right: {
      x: props.polygonOffsets[1][0],
      y: contentSize.value.height - props.polygonOffsets[1][1]
    }
  },
  bottom: {
    left: {
      x: contentSize.value.width - props.polygonOffsets[3][0],
      y: props.polygonOffsets[3][1]
    },
    right: {
      x: props.polygonOffsets[2][0],
      y: props.polygonOffsets[2][1]
    }
  }
}));

const findLineEquationParameters = (x1, y1, x2, y2) => ({
  a: y1 - y2,
  b: x2 - x1,
  c: x1 * y2 - x2 * y1
});

const lines = computed(() => ({
  top: findLineEquationParameters(
    corners.value.top.right.x,
    corners.value.top.right.y,
    corners.value.top.left.x,
    corners.value.top.left.y
  ),
  right: findLineEquationParameters(
    corners.value.bottom.right.x,
    corners.value.bottom.right.y,
    corners.value.top.right.x,
    corners.value.top.right.y
  ),
  bottom: findLineEquationParameters(
    corners.value.bottom.right.x,
    corners.value.bottom.right.y,
    corners.value.bottom.left.x,
    corners.value.bottom.left.y
  ),
  left: findLineEquationParameters(
    corners.value.bottom.left.x,
    corners.value.bottom.left.y,
    corners.value.top.left.x,
    corners.value.top.left.y
  )
}));

const findSideHeight = (key) => corners.value.top[key].y - corners.value.bottom[key].y;
const findSideWidth = (key) => corners.value[key].left.x - corners.value[key].right.x;

const topBottomLinesMatrix = computed(() => [
  [lines.value.top.a, lines.value.top.b],
  [lines.value.bottom.a, lines.value.bottom.b]
]);

const leftRightLinesMatrix = computed(() => [
  [lines.value.left.a, lines.value.left.b],
  [lines.value.right.a, lines.value.right.b]
]);

const topBottomLinesMatrixDeterminant = computed(() => find2x2MatrixDeterminant(topBottomLinesMatrix.value));
const leftRightLinesMatrixDeterminant = computed(() => find2x2MatrixDeterminant(leftRightLinesMatrix.value));

const widthOffset = computed(() => findSideWidth('top') - findSideWidth('bottom'));
const heightOffset = computed(() => findSideHeight('left') - findSideHeight('right'));

const initialHeight = computed(() => initialHeight.value ?? contentSize.value.height);
const initialWidth = computed(() => initialWidth.value ?? contentSize.value.width);

const rotateY = computed(() => {
  if (!topBottomLinesMatrixDeterminant.value) return 0;
  const radius = contentSize.value.width / 2;
  const [deltaH, H, w, W] = [heightOffset.value, contentSize.value.height, initialWidth.value, contentSize.value.width];
  return (180 / Math.PI) * Math.asin((1 - deltaH) / H);
});

const rotateX = computed(() => {
  if (!leftRightLinesMatrixDeterminant.value) return 0;
  const [deltaW, W, h, H] = [widthOffset.value, contentSize.value.width, initialHeight.value, contentSize.value.height];
  return (180 / Math.PI) * Math.asin((1 - deltaW) / W) * (h / H);
});

const findCrossPoint = (D, A, B) => {
  if (!D) return null;
  return multiplyMatrix(inverse2x2Matrix(A), B).map(value => value[0]);
};

const horizontalCrossPoint = computed(() => findCrossPoint(
  topBottomLinesMatrixDeterminant.value,
  topBottomLinesMatrix.value,
  [[lines.value.top.c], [lines.value.bottom.c]]
));

const verticalCrossPoint = computed(() => findCrossPoint(
  leftRightLinesMatrixDeterminant.value,
  leftRightLinesMatrix.value,
  [[lines.value.left.c], [lines.value.right.c]]
));

const skew = computed(() => ({
  x: !verticalCrossPoint.value
    ? 0
    : 180 * Math.atan((verticalCrossPoint.value[0] - contentSize.value.width / 2) / contentSize.value.height / verticalCrossPoint.value[1]) / Math.PI,
  y: !horizontalCrossPoint.value
    ? 0
    : 180 * Math.atan((horizontalCrossPoint.value[1] - contentSize.value.height / 2) / contentSize.value.width / horizontalCrossPoint.value[0]) / Math.PI,
}));

const transform = computed(() => {
  return `perspective(${perspective}px) skew(${skew.value.x}deg, ${skew.value.y}deg) rotateY(${rotateY.value}deg) rotateX(${rotateX.value}deg)`;
});

const polygonStyle = computed(() => ({
  // 'transform-origin': `${widthOffset.value > 0 ? 0: widthOffset.value === 0 ? 50 : 100 }% ${heightOffset.value > 0 ? 100: heightOffset.value === 0 ? 50 : 0}%`,
  'transform': transform.value,
  'border-radius': `${props.polygonBorderRadius}px`,
  'width': `${contentSize.value.width}px`,
  'height': `${contentSize.value.height}px`,
  'left': 0,
  'top': 0,
}));

onMounted(() => {
  resizeObserver.observe(document.getElementById(id.value));
});
</script>

<style lang="scss">
@import "../../assets/style/mixins.scss";

.skewed-box {
  width: 100%;
  height: 100%;
  @include flex();
  position: relative;

  &__poligon {
    position: absolute;
    z-index: 3;
  }

  &__content {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 4;
  }
}
</style>
