<template>
  <Teleport to="body">
    <div class="modal-overlay" />
    <transition name="slide" appear>
      <div class="modal-container" @click="closeModal" v-bind="$attrs">
        <SkewedDiv
          @click.stop
          class="modal"
          :class="classSkewedDiv"
          :borderRadius="borderRadius"
          :background="background"
          :clipPath="clipPath"
          :skew="skew"
          :opacity="opacity"
          :classContent="`${classContent ?? ''} skewedContent`"
          :classSkewBox="classSkewBox"
        >
          <div class="content-container">
            <div class="top-wrapper">
              <span class="title">
                <slot name="title" />
              </span>
              <img class="icon" src="../../images/close-icon.webp" @click="closeModal" />
            </div>
            <SkewedDiv
              v-if="!props.hideTitleLine"
              :borderRadius="2"
              background="#AAB8FF"
              :skew="[0, 0, [-9, -4], [0, -6.5]]"
            >
              <div class="line"></div>
            </SkewedDiv>
            <div class="content skewed-modal-content" :class="[classModalContent, {'no-scroll': noScroll} ]">
              <slot name="content" />
            </div>
            <div class="footer" :class="classFooter">
              <slot name="footer" />
            </div>
          </div>
        </SkewedDiv>
      </div>
    </transition>
  </Teleport>
</template>

<script>
export default { inheritAttrs: false }
</script>

<script setup>
import {onMounted, onUnmounted} from 'vue'
import {v4 as uuidv4} from 'uuid'
import SkewedDiv from '@/components/common/SkewedDiv.vue';

let props = defineProps({
  clipPath: {
    type: String
  },
  skew: {
    type: Array,
    default() {
      return [0, 0, 0, 0];
    }
  },
  borderRadius: { type: Number || null },
  opacity: { type: String },
  classContent: { type: String },
  classSkewBox: { type: String },
  classSkewedDiv: { type: String },
  classModalContent: { type: String },
  classFooter: { type: String },
  background: { type: String },
  title: { type: String },
  as: {
    type: String,
    default() {
      return 'div';
    }
  },
  hideTitleLine: {
    type: Boolean,
  },
  noScroll: {
    type: Boolean,
  },
});

let emits = defineEmits(['exit']);

let id = uuidv4()
let stopScrollingClass = `stop-scrolling-${id}`

onMounted(() => {
 document.body.classList.add(stopScrollingClass);
})
onUnmounted(() => {
 document.body.classList.remove(stopScrollingClass);
})

const closeModal = () => {
  emits('exit');
};
</script>

<style scoped lang="scss">
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.62);
  z-index: 9999;
}

.footer {
  padding-top: 30px;
}

.modal-container {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
  display: flex;
  backdrop-filter: blur(4px);
  overflow-y: auto;

  .modal {
    /* centers without clipping when growing too big
       for viewport */
    margin: auto;
    max-width: min(80vw, 1200px);
    overflow: hidden;
    width: 100%;
    min-height: 50%;
    display: flex;
    flex-direction: column;

    :deep(.skewedContent) {
      flex: 1;

      display: flex;
      flex-direction: column;
    }

    .content-container {
      flex: 1;
      display: flex;
      flex-direction: column;

      padding: 36px 48px;

      .top-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        padding-right: 12px;
        .title {
          flex: 1;
          font-size: 38px;
          font-weight: bold;
        }

        .icon {
          transform: rotate(-3deg);
          height: 28px;
          width: 28px;
          object-fit: contain;
          transition: 200ms ease-in all;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }

          &:active {
            opacity: 0.7;
          }
        }
      }

      .line {
        width: 100%;
        height: 12px;
      }

      .content {
        flex: 1;
        max-height: 55vh;
        padding-top: 20px;
        padding-right: 20px;
        overflow-y: scroll;

        &.no-scroll {
          max-height: initial;
        }

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--color-button-yellow);
          border-radius: 5px;
        }
      }
    }
  }
}


@media (max-width: 730px) {
  .modal-container .modal {
    max-width: 95vw;
    .content-container {
      padding: 34px 28px;
      .top-wrapper {
        margin-bottom: 8px;
        .title {
          font-size: 5.5vw;
        }

        .icon {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

// Animations
.slide-enter-active {
  transition: all 400ms ease;
  transform: scale(1);
}

.slide-enter-from {
  opacity: 0;
  transform: scale(0.9) translateY(30px);
}
</style>

<style>
body[class^='stop-scrolling'], body[class*=' stop-scrolling'] {
  height: 100%;
  overflow: hidden;
}
</style>
