<template>
  <div style="background-color:rgb(29,19,37);height:518px;">
    <div class="gameplay" style="margin-bottom:55px;margin-left:155px;margin-top:90px">
      <div class="gameplay-text-container">
        <h2 class="roadmap-header"> GENESIS DROP </h2>
        <div>
        <img style="margin-top:19px;margin-bottom:14px" src="~@/images/gameplay-divider.svg" />
        </div>
        <p class="gameplay-subtext"> 10,000 available with X trait groups and over X+ traits </p>
      </div>
      <div class="impostor-cell-container" style="height:180px;width:730px">
        <div class="impostor-cell">
          <img src="~@/images/genesis-drop-impostor-1.webp" />
          <p class="impostor-label">COMMON</p>
        </div>
        <div class="impostor-cell">
          <img src="~@/images/genesis-drop-impostor-2.png" />
          <p style="color: #0EC10E;" class="impostor-label">UNCOMMON</p>
        </div>
        <div class="impostor-cell">
          <img src="~@/images/genesis-drop-impostor-3.webp" />
          <p style="color: #0000FF;" class="impostor-label">RARE</p>
        </div>
        <div class="impostor-cell">
          <img src="~@/images/genesis-drop-impostor-4.webp" />
          <p style="color: #800080;" class="impostor-label">EPIC</p>
        </div>
        <div class="impostor-cell">
          <img src="~@/images/genesis-drop-impostor-5.webp" />
          <p style="color: #FFD700;" class="impostor-label">LEGENDARY</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
export default {

  setup() {
    const store = useStore();
    return { store };
  }
};

</script>
<style scoped lang="scss">

.impostor-cell-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.impostor-cell {
width:115px;height:151px;text-align: center;
}

.impostor-label {
margin-top:15px;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 20px;
text-align: center;

color: #FFFFFF;

}

.roadmap-cell-content-2 {
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 22px;
line-height: 29px;
/* identical to box height */

position: relative;
    top: 25%;
    left: 75px;
    color: #FFFFFF;

}

.roadmap-impostor-2 {
margin-top: 19px;
margin-bottom: 14px;
position: absolute;
z-index: 100;
left: 67px;
}

.roadmap-impostor-1 {
margin-top: 19px;
margin-bottom: 14px;
position: absolute;
top: 237px;
z-index: 100;
left: 67px;
}

.img-container {
position: absolute;
    height: 453px;
    width: 569px;
    margin-left: -30px;
    margin-top: 295px;
    font-family: sans-serif;
box-sizing: border-box;
margin: 0;
padding: 0;
}

.roadmap-bubble-subtext {
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 30.0811px;
line-height: 39px;
text-align: left;

color: #FFFFFF;
position: relative;
top: 64px;

}

.roadmap-bubble-subtext-2 {
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 30.0811px;
line-height: 39px;

color: #FFFFFF;
position: relative;
top: 64px;

}

.roadmap-cell-img {
position: absolute;
}

.roadmap-cell-content {
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 17.0811px;
line-height: 22px;

color: #FFFFFF;

}

.roadmap-cell-header {
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 30.0811px;
line-height: 39px;

color: #FFFFFF;

}

.roadmap-cell {
  margin-bottom:21px;
  padding-left: 49px;
  padding-top:  10px;
  text-align: left;
  position:relative;
}

.roadmap-cells-container {
  position: relative;
  left: 40px;
  margin-top: 45px;
}

.gameplay-subscreenshot-text {
  position: relative;
  top: 25%;
}

.gameplay-subscreenshot-img {
    vertical-align: middle;
    position: relative;
    margin: auto;
    top: 25%;
    margin-left: 15px;
    margin-right: 15px;
}

.gameplay-subscreenshot-text {
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 17.0811px;
line-height: 22px;

color: #FFFFFF;

}

.roadmap-cell {
  background-image: url('~@/images/roadmap-cell-background.svg');
  width: 627px;
  height: 81px;
}

.roadmap-cell-2 {
  background-image: url('~@/images/roadmap-cell-background-2.svg');
  width: 369px;
  height: 81px;
  margin-bottom:21px
}

.roadmap-cell-3 {
  background-image: url('~@/images/roadmap-cell-bg-3.svg');
  width: 607px;
  height: 81px;
  margin-bottom:21px
}



.roadmap-bubble-1-content-container {
grid-template-columns: 1.2fr 2fr 8fr;
display: grid;
}

.roadmap-bubble-2-content-container {
grid-template-columns: 1fr 1fr;
display: grid;
}

.roadmap-bubble-3-content-container {
  grid-template-columns: 1fr 2fr 10fr;
  display: grid;
  position: relative;
  top: 300px;
  left: 100px;
}



.roadmap-bubble-container {
width: 979.8px;
height: 684px;
position:relative;
margin-left:164px;
background-image: url('~@/images/impostor-bg-1.webp');
background-size: 100%;
background-attachment: scroll;
background-repeat: no-repeat;
overflow: hidden;
}

.roadmap-bubble-container-2 {
width: 878.8px;
height: 540px;
position:relative;
margin-left:489px;
margin-top:62px;
background-image: url('~@/images/impostor-bg.webp');
background-size: 100%;
background-attachment: scroll;
background-repeat: no-repeat;
overflow: hidden;
}

.roadmap-bubble-container-3 {
width: 1025.8px;
height: 577px;
position:relative;
background-image: url('~@/images/roadmap-bg-3.webp');
background-size: 100%;
background-attachment: scroll;
background-repeat: no-repeat;
overflow: hidden;
position: relative;
top: -250px;
}


.gameplay-screenshots-container {
    margin: auto;
column-gap: 37px;
margin-left: 143px;
margin-right: 136px;
margin-top: 50px;
padding-bottom: 30px;
display: grid;
grid-template-columns: 1fr 1fr;
}

.gameplay-text-container {
display: grid;
grid-template-rows: auto auto auto;
}

.gameplay-subtext {
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 17.0811px;
line-height: 22px;
text-align: left;
margin-bottom:55px;

color: #FFFFFF;

}

.roadmap-header {
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 44.8378px;
line-height: 58px;
text-align: left;

color: #FFFFFF;
margin-top:80px;
}

.bubble-text-container {
top:40px;
position:relative;
margin-left: 50px;
text-align: left;
}

.bubble-header {
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 34px;
line-height: 44px;

color: #FFFFFF;

}

.bubble-main-text {
margin-top:7px;
margin-right:7px;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 17.0811px;
line-height: 22px;

color: #FFFFFF;
}

.questions-blue-bubble {
  background-image: url('~@/images/questions-right-bubble.svg');
  width: 378px;
  height: 291px;
  top: 23%;
  position: relative;
}


.questions-red-bubble {
  background-image: url('~@/images/questions-mid-bubble.svg');
  width: 378px;
  height: 291px;
}

.questions {
    height: 718px;
    background-image: url('~@/images/questions-bg.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    text-align: left;
}

</style>
