<script setup>
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import SkewedButton from '@/components/common/SkewedButton.vue';
import { computed } from '@vue/reactivity';

let props = defineProps({
  staked: { type: Object },
  claimAmount: { type: String },
});

let emit = defineEmits(['unstake'])

const claimAmount = computed(() => {
  return props.claimAmount;
});

const numStakedImpostors = computed(() => {
  return props.staked.length;
});

const hasStakedImpostors = computed(() => {
  return numStakedImpostors > 0;

});

const message = computed(() => {
  return hasStakedImpostors
    ? 'You have Impostors left in the Genesis Season staker pool!'
    : 'You have unclaimed $BLOOD from the Genesis Season staker pool!';
});

const stakedPools = computed(() => {
  const pools = props.staked.reduce((acc, nft) => {
    const poolId = nft.stakedPool.toNumber()
    return {
      ...acc,
      [poolId]: [
        ...(acc[poolId] ?? []), nft
      ]
    }
  }, {}) 
  return Object.entries(pools)
})
</script>

<template>
  <div :class="s['claim-wrapper']">
    <SkewedDiv
      :class="s['claim']"
      :borderRadius="15"
      :background="'linear-gradient(to right, #901E05, #C43C1E)'"
      :skew="!isMobile ? [0, [0, -8], [-10, 0], [-10, 0]] : [0, [0, -8], 0, 0]"
    >
      <SkewedDiv
        :class="s['info-wrapper']"
        :borderRadius="6"
        background="white"
        opacity="0.20"
        :skew="[0, 0, [-6, 0], [-6, 0]]"
      >
        <div :class="s['content']">
          <p :class="s['title']">AVAILABLE TO CLAIM</p>
          <p :class="s['subtitle']">{{ claimAmount }}</p>
          <div 
            v-if="stakedPools.length > 0"
            :class="s.buttonGroup"
          >
            <SkewedButton
              v-for="([poolId, nfts]) in stakedPools" :key="poolId"
              :class="s['button']"
              :borderRadius="6"
              :skew="[0, -2, 0, [4, 4]]"
              :disabled="claimAmount == 0"
              @click="$emit('unstake', {
                nfts: nfts.map((n) => n.tokenId),
                poolId: nfts[0].stakedPool,
                legacy: true
              })"
              background="var(--color-button-yellow)"
            >
              <span>Withdraw Pool {{poolId}} 
                <span :style="{opacity: .6, marginLeft: '8px'}">({{ nfts.length }})</span>
              </span>
            </SkewedButton>
          </div>
        </div>
      </SkewedDiv>
    </SkewedDiv>
    <SkewedDiv
      :class="s['stats']"
      :borderRadius="15"
      :background="'#5e0c03'"
      :skew="!isMobile ? [0, 0, [-10, 0], 0] : [0, [0, -8], 0, 0]"
    >
      <div :class="s['content']">
        <p :class="s['title']">{{ message }}</p>
        <div :class="[s['info-wrapper'], s.faded]">
          <span>NFTs STAKED</span>
          <span>{{ numStakedImpostors }}</span>
        </div>
        <div :class="[s['info-wrapper'], s.faded]">
          <span>$BLOOD TO CLAIM</span>
          <span>{{ claimAmount }}</span>
        </div>
      </div>
    </SkewedDiv>
  </div>
</template>

<style module="s" lang="scss">
.claim-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  margin-bottom: 22px;

  .claim {
    width: 100%;
    max-width: 500px;
    padding: 40px;
    z-index: 3;

    .info-wrapper {
      padding: 40px;
      padding-top: 30px;

      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;

        .title {
          font-size: 32px;
          font-weight: 500;
        }

        .subtitle {
          font-size: 58px;
          font-weight: 500;
        }

        .buttonGroup {
          margin-bottom: -72px;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .button {
          width: 100%;

          span {
            font-weight: 500;
          }
        }
      }
    }
  }

  .stats {
    z-index: 2;
    width: 100%;
    height: 220px;
    margin-right: -100px;
    padding-right: 120px;
    padding-left: 36px;
    margin-top: 14px;

    .content {
      padding: 30px 0px;
      padding-right: 45px;

      .title {
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 20px;
      }

      .info-wrapper {
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        margin-bottom: 18px;
      }

      .faded {
        opacity: 0.7;
      }
    }
  }
}
</style>
