<script setup>
import { computed } from 'vue';

let props = defineProps({
  name: { type: String },
  status: { type: String, default: 'unclaimed' },
  isLoading: { type: Boolean },
  isMessage: { type: Boolean }
});

let chipText = computed(() =>
  props.status === 'claimed' ? 'claimed' : 'unclaimed'
);
let hiddenElements = computed(() => props.isLoading || props.isMessage);
</script>

<template>
  <div
    :class="[s.eligibilityChip, { [s.claimed]: props.status === 'claimed' }]"
  >
    <img
      v-if="props.status === 'claimed'"
      :style="{ visibility: hiddenElements ? 'hidden' : 'visible' }"
      :class="s.emoji"
      src="@/images/prohibited_emoji.webp"
    />
    <img
      v-else
      :class="s.emoji"
      :style="{ visibility: hiddenElements ? 'hidden' : 'visible' }"
      src="@/images/check-mark-button-emoji.webp"
    />

    <div :class="s.name">
      {{ props.isLoading ? 'Checking...' : props.name }}
    </div>
    <div
      :class="[s.chip, { [s.claimedChip]: props.status === 'claimed' }]"
      v-show="!hiddenElements"
    >
      {{ chipText }}
    </div>
  </div>
</template>

<style module="s" lang="scss">
.eligibilityChip {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px;
  border-radius: 4px;
  background: #7a8dd1;
  font-size: 18px;
}
.emoji {
  width: 18px;
  height: 18px;
}
.name {
  flex: 1;
}
.chip {
  background: rgba(255, 255, 255, 0.5);
  padding: 3px 9px;
  border-radius: 44px;
  font-size: 11px;
  font-weight: bold;
  color: #008f00;
  text-transform: uppercase;

}
.claimed {
  background: #8c4f8a;
}

.claimedChip {
  color: #cd2300;
}
</style>
