<script setup>
import { computed } from 'vue';
import { SkewedButton } from '@/components/common';
import { GIGAMART_URL } from '../../../constants';
let props = defineProps({
  status: {
    type: String,
    default: 'MINT',
    validator: v => ['MINT', 'SOLD_OUT', 'BUY'].includes(v)
  }
});
let btnMsg = computed(
  () =>
    ({
      MINT: 'MINT',
      SOLD_OUT: 'BUY ON GIGAMART',
      BUY: 'GIGAMART'
    }[props.status])
);
let btnUrl = computed(
  () =>
    ({
      MINT: '/mint',
      SOLD_OUT: GIGAMART_URL,
      BUY: GIGAMART_URL
    }[props.status])
);
</script>

<template>
  <div class="mint-btn-wrapper">
    <h2 class="sirens" v-if="props.status === 'MINT'">
      MINT IS
      <div class="status-chip">Live</div>
    </h2>

    <h2 class="parties" v-else-if="props.status === 'SOLD_OUT'">SOLD OUT</h2>

    <h2 v-else>BUY ON</h2>

    <a :href="btnUrl" class="mint-link" target="_blank">
      <SkewedButton
        background="var(--color-button-yellow)"
        :skew="[0, -3, 0, [-8, -6]]"
        :borderRadius="8"
        class="mint-btn"
      >
        <div class="mint-btn__content">
          <span class="wallet-label">{{btnMsg}}</span>
        </div>
      </SkewedButton>
    </a>
  </div>
</template>

<style scoped lang="scss">
@import '../../../assets/style/mixins.scss';

.mint-btn-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  width: 100%;
  padding: 0 48px;

  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 39px;
    margin-bottom: 24px;
  }

  .mint-btn {
    width: 100%;
    padding: 23px;
  }

  .mint-btn__content {
    display: flex;
    font-size: 25px;
    font-weight: bold;
  }

  .status-chip {
    background: red;
    border-radius: 12px;
    padding: 0 22px;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    margin-left: 16px;
  }

  .mint-link {
    text-decoration: none;
    width: 100%;
  }

  @include mediaOnlyScreenMax(640px) {
    padding: 0 24px;
    h2 {
      font-size: 14px;
      margin-bottom: 12px;
    }
    .status-chip {
      border-radius: 4px;
      padding-bottom: 4px;
      padding-top: 4px;
    }
    .mint-btn {
      padding: 10px;
    }
  .mint-btn__content {
    font-size: 18px;
  }

  }
}

.sirens {
  --offset: -18px;
  --size: 50px;

  &:before,
  &:after {
    content: '';
    background: url('~@/images/siren.svg') center/contain no-repeat;
    width: var(--size);
    height: var(--size);
    position: absolute;

    @include mediaOnlyScreenMax(640px) {
      --size: 32px;
      --offset: -12px;
    }
  }
  &:before {
    left: var(--offset);
    transform: rotateZ(-20deg) scaleX(-1) translateY(-12px);
  }
  &:after {
    right: var(--offset);
    transform: rotateZ(20deg) translateY(-12px);
  }
}
.parties {
  --offset: 24px;
  --size: 50px;

  &:before,
  &:after {
    content: '';
    background: url('~@/images/party.png') center/contain no-repeat;
    width: var(--size);
    height: var(--size);
    position: absolute;

    @include mediaOnlyScreenMax(640px) {
      --size: 24px;
    }
  }
  &:before {
    left: var(--offset);
  }
  &:after {
    right: var(--offset);
  }
}
</style>
