<script setup>
import { ref, computed } from 'vue';
import { Skeletor } from 'vue-skeletor';
import {
  IMPOSTOR_TOKEN_TYPE,
  UFO_TOKEN_TYPE,
  PET_TOKEN_TYPE,
  BOX_TOKEN_TYPE,
  TOKEN_CONFIG,
} from '@/constants';
import ClaimRow from './ClaimRow.vue';
import ClaimModal from './ClaimModal.vue';
import rowImg from '@/images/materials-box.webp';
import {
  useRedeem,
  useClaimTokenInfo,
  useClaimDetails,
  useApproveRedeemer
} from '../composables';

const TOKEN_TYPE = BOX_TOKEN_TYPE;
const REDEEM_ROUND = 2;

const props = defineProps({});

let showClaimModal = ref(false);
let hasEligibleItems = ref(true);
let requirements = [
  { text: TOKEN_CONFIG[IMPOSTOR_TOKEN_TYPE].DISPLAY_NAME },
  { text: TOKEN_CONFIG[UFO_TOKEN_TYPE].DISPLAY_NAME },
  { text: TOKEN_CONFIG[PET_TOKEN_TYPE].DISPLAY_NAME },
];

let { data: tokenInfo, isLoading: tokenLoading } = useClaimTokenInfo();
let { data: claimDetails, isLoading: claimLoading } = useClaimDetails();

let isLoading = computed(() => tokenLoading.value || claimLoading.value);

let bloodCost = computed(() =>
  parseFloat(
    claimDetails.value?.[claimDetails.value?.boxCollectionAddress]?.price ?? 0
  ).toFixed(2)
);

// let { isLoading, mutate: redeem } = useRedeem();
let redeem = useRedeem();
let approve = useApproveRedeemer();
let isSubmitting = computed(
  () => approve.isLoading.value || redeem.isLoading.value
);

let handleCheckoutApprove = () => {
  approve.mutate();
};
let handleCheckoutSubmit = claims => {
  redeem.mutate(
    { round: REDEEM_ROUND, claims },
    {
      onSuccess: () => {
        showClaimModal.value = false;
      }
    }
  );
  // redeem({ round: 1, claims });
};
</script>

<template>
  <Skeletor
    v-if="isLoading"
    :style="{
      width: '100%',
      height: '160px',
      background: 'rgba(255,255,255,.18)',
      borderRadius: '12px'
    }"
  />
  <ClaimRow
    v-else
    title="Materials Box"
    :class="s.claimRow"
    @claim="showClaimModal = true"
    :enableClaim="hasEligibleItems"
    :bloodCost="bloodCost"
    :requirements="requirements"
    :imgSrc="rowImg"
    :classImg="s.rowImg"
    learnMoreHref="https://docs.impostors.gg/metapaper/metaverse-economy/land-ecosystem"
  />

  <ClaimModal
    v-if="showClaimModal"
    title="Claim your box"
    :isSubmitting="isSubmitting"
    :isBloodApproved="tokenInfo?.hasAllowance(bloodCost)"
    :bloodPerItem="bloodCost"
    :bloodBalance="tokenInfo?.bloodBalance"
    :redeemRound="REDEEM_ROUND"
    :redeemToken="TOKEN_TYPE"
    :claimSlots="[IMPOSTOR_TOKEN_TYPE, UFO_TOKEN_TYPE, PET_TOKEN_TYPE]"
    claimTypeText="Box"
    @exit="showClaimModal = null"
    @approve="handleCheckoutApprove"
    @submit="handleCheckoutSubmit"
  />
</template>

<style module="s" lang="scss">
.claimRow {
  .rowImg {
    margin: -24px 0 0 0;
    max-width: 160px;
  }
}
</style>
