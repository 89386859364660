let merkleconfig = null;

export default async () => {
  merkleconfig =
    merkleconfig === null
      ? await fetch(`${process.env.BASE_URL}merkleconfig.json?t=${Date.now()}`).then(
          response => {
            return response.json();
          }
        )
      : merkleconfig;
  return merkleconfig;
};
