<template>
  <div style="background-color:rgb(29,19,37)">
    <div class="gameplay" style="margin-bottom:55px">
      <div class="gameplay-text-container">
        <h2 class="gameplay-header"> GAMEPLAY </h2>
        <div style="text-align:center">
        <img style="margin-top:19px;margin-bottom:14px" src="~@/images/gameplay-divider.svg" />
        </div>
        <p class="gameplay-subtext"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis sed elit hendrerit a pharetra eu, vitae euismod. Sed facilisis sagittis viverra est aliquet faucibus. </p>
      </div>
      <div class="gameplay-screenshots-container">
        <div class="gameplay-screenshots">
          <img src="~@/images/gameplay-screenshot.svg" />
        </div>
        <div class="gameplay-screenshots-picker">
          <div class="gameplay-subscreenshot-container" data-selected="true">
            <img class="gameplay-subscreenshot-img" src="~@/images/gameplay-subscreenshot-1.webp" />
            <span class="gameplay-subscreenshot-text">Game play title</span>
          </div>
          <div class="gameplay-subscreenshot-container" data-selected="false">
            <img class="gameplay-subscreenshot-img" src="~@/images/gameplay-subscreenshot-2.webp" />
            <span class="gameplay-subscreenshot-text">Multiple Roles</span>
          </div>
          <div class="gameplay-subscreenshot-container" data-selected="false">
            <img class="gameplay-subscreenshot-img" src="~@/images/gameplay-subscreenshot-3.webp" />
            <span class="gameplay-subscreenshot-text">Game play title</span>
          </div>
          <div class="gameplay-subscreenshot-container" data-selected="false">
            <img class="gameplay-subscreenshot-img" src="~@/images/gameplay-subscreenshot-4.webp" />
            <span class="gameplay-subscreenshot-text">Game play title</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
export default {

  setup() {
    const store = useStore();
    return { store };
  }
};

</script>
<style scoped lang="scss">
.gameplay-subscreenshot-text {
position: relative;
top: 25%;
}

.gameplay-subscreenshot-img {
    vertical-align: middle;
    position: relative;
    margin: auto;
    top: 25%;
    margin-left: 15px;
    margin-right: 15px;
}

.gameplay-subscreenshot-text {
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 17.0811px;
line-height: 22px;

color: #FFFFFF;

}

.gameplay-subscreenshot-container {
height:112px;
width:245px;
background-image: url('~@/images/gameplay-selected-square.svg');
background-size: 100%;
background-attachment: scroll;
background-repeat: no-repeat;
overflow: hidden;
}

.gameplay-subscreenshot-container[data-selected="false"] {
  opacity: .3;
  background-image: none

}

.gameplay-screenshots-container {
    margin: auto;
column-gap: 37px;
margin-left: 143px;
margin-right: 136px;
margin-top: 50px;
padding-bottom: 30px;
display: grid;
grid-template-columns: 1fr 1fr;
}

.gameplay-text-container {
display: grid;
grid-template-rows: auto auto auto;
}

.gameplay-subtext {
margin-left: 200px;
margin-right:200px;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 17.0811px;
line-height: 22px;
text-align: center;
margin-bottom:55px;

color: #FFFFFF;

}

.gameplay-header {
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 44.8378px;
line-height: 58px;
text-align: center;

color: #FFFFFF;
}

.bubble-text-container {
top:40px;
position:relative;
margin-left: 50px;
text-align: left;
}

.bubble-header {
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 34px;
line-height: 44px;

color: #FFFFFF;

}

.bubble-main-text {
margin-top:7px;
margin-right:7px;
font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 17.0811px;
line-height: 22px;

color: #FFFFFF;
}

.questions-blue-bubble {
  background-image: url('~@/images/questions-right-bubble.svg');
  width: 378px;
  height: 291px;
  top: 23%;
  position: relative;
}


.questions-red-bubble {
  background-image: url('~@/images/questions-mid-bubble.svg');
  width: 378px;
  height: 291px;
}

.questions {
    height: 718px;
    background-image: url('~@/images/questions-bg.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    text-align: center;
}

</style>
