<script setup>
import { Tippy } from 'vue-tippy';
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import { computed } from '@vue/reactivity';

const props = defineProps({
  text: { type: String },
  hidden: { type: Boolean }
});

let hideTooltip = computed(() => props.hidden || !props.text)
</script>

<template>
  <Tippy zIndex="99999">
    <slot></slot>
    <template #content>
      <SkewedDiv
        background="#2e4596"
        :border-radius="4"
        :skew="[0, 4, 6, -3]"
        :class="s.tooltip"
        :style="[hideTooltip && { display: 'none' }]"
        >{{ text }}</SkewedDiv
      >
    </template>
  </Tippy>
</template>

<style module="s" lang="scss">
.tooltip {
  padding: 12px;
  filter: drop-shadow(4px 4px 0px rgba(2, 11, 40, 0.6));
  z-index: 10000;
}
</style>
