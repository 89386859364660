'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import initializeMerkleconfig from '../initialize-merkleconfig';
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';

let config;

let reset = async function() {
  config = null;
}

let loadMerkleData = async function() {
  // No-op on multiple calls to start the provider.
  if (!config) {
    config = await initializeConfig();
  }

  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();

  let merkleData = [];
  let merkleconfig = await initializeMerkleconfig();

  for (let t = 0; t < merkleconfig.trees.length; t++) {
    let tree = merkleconfig.trees[t]
    for (let leaf of tree.leaves) {
      if (leaf.address.toLowerCase() == address.toLowerCase()) {
        //console.info("merkletree::leaf", leaf, t);
        leaf.proof.forEach(x => ethers.utils.hexlify(x));
        let leafData = {
          proof: leaf.proof,
          index: leaf.index,
          allowance: leaf.allowance,
          address: leaf.address.toLowerCase(),
        };
        merkleData.push(leafData)
      }
    }
  }
  //console.info("loaded merkle data", merkleData)
  return merkleData;
};

// Export the merkle service functions.
export const merkleService = {
  loadMerkleData,
  reset,
};
