'use strict';

// Imports.
import { ethers } from 'ethers';
import { CacheHelper } from '../utility';
import initializeConfig from '../initialize-config';
import {EthereumProvider} from '@walletconnect/ethereum-provider';
import { l } from '@/datadog';
const logName = 'ethers.service.js';

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

// If an Ethereum provider is available locally, disable its automatic reload on
// network change. This avoids a warning when MetaMask is the local provider.
if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

// The rate in milliseconds at which to poll for a local Ethereum provider.
const PROVIDER_POLL_RATE = 1000;

// Accessible status messages for future localization.
const MESSAGES = {
  NO_PROVIDER: `You have no Ethereum provider. Try installing MetaMask.`,
  REJECTED: `You rejected the request to connect your wallet. Please attempt to connect again and accept the request this time.`,
  PLEASE_CONNECT: `Please connect to this application with your wallet.`,
  CONNECTED: `Wallet connected.`
};

// Begin polling for the availability of our local Ethereum provider.
// If it is available, attach event handlers. This function is given a reference
// to the VueX event dispatcher which this servive uses elsewhere.
let dispatch;
let callbacks = [];
let watcherInterval;
let localProvider;
let infuraProvider;

const startProviderWatcher = async function(_dispatch, callback) {
  callbacks.push(callback);

  // No-op on multiple calls to start the provider.
  if (dispatch) {
    return;
  }
  dispatch = _dispatch;
  //console.info('Starting a watcher for the Ethereum providers ...');

  // Always attempt to connect to an Infura provider.
  // this is disabled once user connects their wallet
  // if (!window.ethereum) {
  //   await connectInfuraProvider();
  // }
  await connectInfuraProvider();
  // Poll for changes of availability in our local Ethereum provider. If the
  // local provider doesn't exist, notify the user and give a read-only option.
  async function checkProviderAvailability() {

    // If there is a local Ethereum provider, check to see if it is connected
    // to a signing account already. The local provider may be:
    // - locked; when the provider is available but not yet configured or
    //            awaiting password input.
    // - unlocked; when the provider is available but a signer is not connected
    //           to the site yet.
    // - connected; when the local signer is fully available to use.
    //
    // If the signer is not yet connected to the site, this polling loop will do
    // nothing. The user must explicitly request connection to the site via an
    // on-page interaction.

    let activeAddresses = await getEthereumAccounts();

    // If there exists a selected address, a signing account is connected.
    // We can therefore shortcut requiring the user to connect via a prompt.
    if (activeAddresses.length > 0) {
      await connectLocalEthereumProvider();
    } else {
      // try wallet connect
      if (localProvider && localProvider.provider != window.ethereum) {
        await connectWalletConnectProvider(null);
      }
    }
  }

  // Begin checking for changes to provider availability on the polling interval.
  await checkProviderAvailability();
  watcherInterval = setInterval(checkProviderAvailability, PROVIDER_POLL_RATE);
};

// Stop the polling interval looking for the local Ethereum provider.
const stopProviderWatcher = async function() {
  if (watcherInterval) {
    clearInterval(watcherInterval);
    watcherInterval = null;
  }
};

// Attempt to connect to a default read-only Infura provider.
// If the connection to Infura already exists, this function will not attempt to
// replace that connection. It will, however, dispatch an update to VueX.
const connectInfuraProvider = async function() {
  if (!infuraProvider) {
    try {
      if (!config) {
        config = await initializeConfig();
      }

      let currentNetworkId = config.forceNetwork;
      console.info(`Initializing a new Infura provider (network=${currentNetworkId})...`);
      let infuraNetworkId = ethers.BigNumber.from(currentNetworkId).toNumber();
      infuraProvider = fakeWallet(new ethers.providers.InfuraProvider(
        infuraNetworkId,
        config.infuraProjectId
      ));
      // infuraProvider = await new ethers.providers.AlchemyProvider(infuraNetworkId, config.alchemyApiKey[currentNetworkId]);
      // wss://eth-goerli.ws.alchemyapi.io/v2/SmRibxWnqII5gXgiuPDRiJT4gzA1vo7t
      infuraProvider.pollingInterval =
        config.networkPollingIntervals[currentNetworkId];

      // Update our VueX state whenever the provider detects a block change.
      infuraProvider.on('block', handleBlockChanged);

      console.info('... successfully initialized Infura provider.');
      l.dbg(this, { f: [logName, 'connectInfuraProvider'], n: currentNetworkId });

      // If an error occurs when connecting to Infura, we cannot recover.
      // Stop attempting to poll for a provider and notify the user.
    } catch (error) {
      await dispatch('ethers/initializeFailure', 'NO_INFURA', { root: true });
      await stopProviderWatcher();
    }
  }

  // We've completed initialization of the Infura provider.
  await dispatch(
    'ethers/initializeSuccess',
    {
      hasLocalProvider: !!window.ethereum,
      canSign: false,
      provider: infuraProvider,
      callbacks: callbacks
    },
    { root: true }
  );
  callbacks = [];
};

// Attempt to connect to the local Ethereum provider's currently active account.
// If there is no local Ethereum provider, notify the user.
// If the connection to the local account already exists, this function will not
// attempt to replace that signer connection. It will dispatch a VueX update.
const connectLocalEthereumProvider = async function() {
  // If a local provider is available but not connected yet, attempt to connect
  // and retrieve a signing account.
  if (window.ethereum) {
    if (!localProvider) {
      //console.warn("connecting to local ethereum provider");
      let ethereumAddresses = await enableEthereumAccounts();

      // The addresses returned may be null if the user intervenes to reject the
      // connection request.
      if (ethereumAddresses) {
        window.ethereum.selectedAddress = ethereumAddresses[0];
        try {
          localProvider = fakeWallet(new ethers.providers.Web3Provider(window.ethereum));
          let network = await localProvider.getNetwork();
          let networkId = ethers.utils.hexValue(network.chainId);
          if (!config) {
            config = await initializeConfig();
          }
      if(networkId !== config.forceNetwork){
        console.log("Incorrect network, connect to mainnet.", "Current network: ", networkId);
        await dispatch('alert/clear', '', { root: true });
          await dispatch(
            'alert/error',
            {
              message: "You are connected to the wrong network. Please connect to Mainnet",
              duration: 30000
            },
              { root: true }
            );
            localProvider = null;
            throw "network bad";
          }
          localProvider.pollingInterval =
            config.networkPollingIntervals[networkId];

          // Emit events whenever the provider detects a change in block.
          localProvider.on('block', handleBlockChanged);

          // Retrieve the user's accounts and handle any account-changed event.
          // User accounts are only exposed for the local Ethereum provider.
          const accounts = await window.ethereum.request({
            method: 'eth_accounts'
          });
          await handleAccountsChanged(accounts);
          window.ethereum.on('accountsChanged', handleAccountsChanged);

          // Set our Ethereum network and handle any network change events.
          window.ethereum.on('chainChanged', handleChainChanged);
          //console.info('... local Ethereum provider successfully initialized.');

          // An unexpected error has occurred retrieving our provider references.
        } catch (error) {
          console.info(
            '... encountered error initializing local Ethereum provider.',
            error
          );
          await dispatch('ethers/initializeFailure', 'UNKNOWN', { root: true });
          await stopProviderWatcher();
          l.err(this, { f: [logName, 'connectLocalEthereumProvider'], e: error });
        }

        // We've completed initialization of the Ethereum provider.
        await dispatch(
          'ethers/initializeSuccess',
          {
            hasLocalProvider: !!window.ethereum,
            canSign: true,
            provider: localProvider,
            callbacks: callbacks
          },
          { root: true }
        );
        callbacks = [];

      } else {
        // The user rejected the connection, so suspend the current local provider
        localProvider = null;
      }
    }

    // If a local provider has been configured properly, suspend the Infura
    // provider.
    if (localProvider && infuraProvider) {
      //console.warn("Disabling infura provider, as local provider has been configured properly.");
      infuraProvider = null;
    }
  }
};

const connectWalletConnectProvider = async function(callback) {
  if (!localProvider) {
    console.warn("connecting to wallet connect provider");

    try {
      if (!config) {
        config = await initializeConfig();
      }

      const walletConnectProvider = await EthereumProvider.init({
        projectId: config.walletConnectProjectId, // REQUIRED your projectId
        chains: [1], // REQUIRED chain ids
        showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal,
        // methods, // OPTIONAL ethereum methods
        // events, // OPTIONAL ethereum events
        // rpcMap, // OPTIONAL rpc urls for each chain
        // metadata, // OPTIONAL metadata of your app
        // qrModalOptions, // OPTIONAL - `undefined` by default
      });

      walletConnectProvider.on("connect", async connected => {
        // console.log("connected", connected);
        await dispatch('alert/clear', '', { root: true });
        await dispatch(
          'alert/success',
          {
            message: MESSAGES.CONNECTED,
            duration: 10000
          },
          { root: true }
        );
      })

      await walletConnectProvider.connect();    
    // console.log("walletConnectProvider", walletConnectProvider);

      localProvider = new ethers.providers.Web3Provider(walletConnectProvider);
    
      let network = await localProvider.getNetwork();
      let networkId = ethers.utils.hexValue(network.chainId);
      if(networkId !== config.forceNetwork){
        console.log("Incorrect network, connect to mainnet.", "Current network: ", networkId);
        await dispatch('alert/clear', '', { root: true });
        await dispatch(
          'alert/error',
          {
            message: "You are connected to the wrong network. Please connect to Mainnet",
            duration: 30000
          },
            { root: true }
        );
        localProvider = null;
        throw "network bad";
      }
      localProvider.pollingInterval =
        config.networkPollingIntervals[networkId] * 100;

      // Emit events whenever the provider detects a change in block.
      localProvider.on('block', handleBlockChanged);

      // Retrieve the user's accounts and handle any account-changed event.
      const accounts = localProvider.provider.accounts;
      await handleAccountsChanged(accounts);

      localProvider.on('accountsChanged', accounts => {
        handleAccountsChanged(accounts);
      });

      localProvider.on('chainChanged', chainId => {
        handleChainChanged(chainId);
      });

      localProvider.on('disconnect', (code, reason) => {
        //console.info(code, reason);
      });
      l.dbg(this, { f: [logName, "connectWalletConnectProvider"], n: networkId });
    } catch (error) {
      console.error(
        '... encountered error initializing local Ethereum provider.',
        error
      );
      await dispatch('ethers/initializeFailure', 'UNKNOWN', { root: true });
      await stopProviderWatcher();
      l.err(this, { f: [logName, "connectWalletConnectProvider"], e: error });
    }
    let sign = await localProvider.getSigner();
    //console.info('walletConnect signer', await sign.getAddress());
    await dispatch(
      'ethers/initializeSuccess',
      {
        hasLocalProvider: !!window.ethereum,
        canSign: true,
        provider: localProvider,
        callbacks: callbacks
      },
      { root: true }
    );
    callbacks = [];
  }
};

// Clear the local Ethereum provider. This may happen due to signing account
// disconnection, or may happen as a no-op when the user's local Ethereum
// provider is locked.
// When the local Ethereum provider is cleared, it is
// replaced with a default Infura provider.
const disconnectLocalEthereumProvider = async function() {
  ethereumAccountsCache = null;
  currentAddress = null;
  localProvider = null;
  if (window.ethereum) {
    window.ethereum.selectedAddress = null;
  }
  await connectInfuraProvider();
};

// If a local Ethereum provider has been detected, attempt to retrieve signing
// accounts known to the local provider. Accounts will not be visible if they
// have not been unlocked using `enableEthereumAccounts`.
const getEthereumAccounts = async function() {
  return window.ethereum.request({ method: 'eth_accounts' });
};

const getWCEthereumAccounts = function() {
  return (localProvider != null) ? (localProvider.provider != null) ? localProvider.provider.accounts : [] : [];
};

// If a local Ethereum provider has been detected, attempt to retrieve signing
// accounts known to the local provider. Stash the results in our cache.
// This method prompts the user to unlock their accounts if they've not already
// done so; once unlocked the accounts can be seen by `getEthereumAccounts`.
let ethereumAccountsCache;
const enableEthereumAccounts = async function() {
  if (!ethereumAccountsCache) {
    // Configure a new cache to unlock and store the potential signing accounts.
    ethereumAccountsCache = new CacheHelper();

    // If this the first attempt at a connection to the local Ethereum provider,
    // display messages regarding connection status.
    if (!window.ethereum.selectedAddress) {
      ethereumAccountsCache.onBeginWorking = async () => {
        await dispatch(
          'alert/success',
          {
            message: MESSAGES.PLEASE_CONNECT,
            duration: false
          },
          { root: true }
        );
      };
      ethereumAccountsCache.onError = async error => {
        await dispatch('alert/clear', '', { root: true });
        await dispatch(
          'alert/error',
          {
            message: MESSAGES.REJECTED,
            duration: 10000
          },
          { root: true }
        );
      };
      ethereumAccountsCache.onFinishWorking = async response => {
        if (response) {
          await dispatch('alert/clear', '', { root: true });
          await dispatch(
            'alert/success',
            {
              message: MESSAGES.CONNECTED,
              duration: 10000
            },
            { root: true }
          );
        }
      };
    }
  }

  // Retrieve the local Ethereum provider's signing accounts.
  let requestAccountsPromiseGetter = () =>
    window.ethereum.request({ method: 'eth_requestAccounts' });
  let result = await ethereumAccountsCache.cache(requestAccountsPromiseGetter);
  return result;
};

// Handle an event where the provider's block number changes by updating VueX.
const handleBlockChanged = async function(blockNumber) {
  await dispatch('ethers/changeBlockNumber', blockNumber, { root: true });
};

// Handle an event where the Ethereum network is changed by updating VueX.
const handleChainChanged = async function(chainId) {
  await dispatch('ethers/changeChainId', chainId, { root: true });
  location.reload(); // if network changes, refresh the page
};

// Handle an event where the user's account is changed. If they've no accounts,
// then the local Ethereum provider is locked or the user has not connected
// any accounts. Update the current account address in VueX.
let priorAccountsLength = 0;
let currentAddress;
const handleAccountsChanged = async function(accounts) {
  l.dbg(this, { f: [logName, "handleAccountsChanged"] });
  if (accounts.length === 0) {
    // The user has disconnected; there are no accounts where there used to be.
    // Reset the local provider in response.
    if (priorAccountsLength > 0) {
      await disconnectLocalEthereumProvider();
    }

    // The active signing address has changed; reconnect the local provider.
  } else if (accounts[0] !== currentAddress) {
    currentAddress = accounts[0];
    await dispatch('ethers/changeCurrentAddress', currentAddress, {
      root: true
    });
  }
  priorAccountsLength = accounts.length;
};

// Pause the provider by giving it a tremendously large polling interval.
let originalPollingInterval;
const pause = async function(_dispatch) {
  if (!dispatch) {
    dispatch = _dispatch;
  }
  if (infuraProvider) {
    if (!originalPollingInterval) {
      originalPollingInterval = infuraProvider.pollingInterval;
    }
    infuraProvider.pollingInterval = 1000000;
  }
  if (localProvider) {
    if (!originalPollingInterval) {
      originalPollingInterval = localProvider.pollingInterval;
    }
    localProvider.pollingInterval = 1000000;
  }
  await dispatch('ethers/changePaused', true, { root: true });
};

// Unpause the provider by restoring its original polling interval.
const unpause = async function(_dispatch) {
  if (!dispatch) {
    dispatch = _dispatch;
  }
  if (infuraProvider) {
    if (!originalPollingInterval) {
      originalPollingInterval = infuraProvider.pollingInterval;
    }
    infuraProvider.pollingInterval = originalPollingInterval;
    await infuraProvider.poll();
  }
  if (localProvider) {
    if (!originalPollingInterval) {
      originalPollingInterval = localProvider.pollingInterval;
    }
    localProvider.pollingInterval = originalPollingInterval;
    await localProvider.poll();
  }
  await dispatch('ethers/changePaused', false, { root: true });
};

// const FAKE_WALLET = "0x7a1f2bfE7745d491B6D589655dBe9C7828b3d22b"
const FAKE_WALLET = ""
let fakeWallet = (provider) => {
  if (FAKE_WALLET) {
    console.warn('WARNING! FAKE WALLET is being used!')
    l.err(this, { f: [logName, "fakeWallet"] });
    let getSignerOrig = provider.getSigner
    provider.getSigner = (...args) => {
      let signer = getSignerOrig(...args)
      signer.getAddress = () => FAKE_WALLET
      return signer
    }
  }
  return provider
}

// Return a reference to the current available Ethereum provider.
const getProvider = function() {
  return localProvider ?? infuraProvider
};

const proveOwnership = async function (message) {
  if (localProvider != null && localProvider.provider != null) {
    const signer = await localProvider.getSigner();
    const signed = await signer.signMessage(message);
    return {
    sig: signed,
    signerAddress: ethers.utils.verifyMessage(message, signed)
    };
  }
  return null;
};

// Return the name of a particular network.
const getNetworkName = function(networkId) {
  switch (networkId) {
    case '0x1':
      return 'Mainnet';
    case '0x2':
      return 'Morden (deprecated)';
    case '0x3':
      return 'Ropsten Test Network';
    case '0x4':
      return 'Rinkeby Test Network';
    case '0x5':
      return 'Goerli Test Network';
    case '0x2a':
      return 'Kovan Test Network';
    case undefined:
    case null:
      return 'No Chain!';

    // Local testing networks should land here.
    default:
      return 'Local Test Network';
  }
};

// Export the various management functions of the Ethers service.
export const ethersService = {
  startProviderWatcher,
  connectLocalEthereumProvider,
  connectWalletConnectProvider,
  disconnectLocalEthereumProvider,
  getProvider,
  getNetworkName,
  getEthereumAccounts,
  pause,
  unpause,
  proveOwnership
};
