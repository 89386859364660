'use strict';

// Imports.
import { mintService } from '../../services';
import { merkleService } from '../../services';
import { errMsg } from '../../utility';

// Specified actions for mutating the alert state.
export default {

  // Use the mint service to load shop config options
  async loadShopConfig(
    { dispatch, commit },
  ) {
    try {
      let response = await mintService.loadShopConfig(dispatch);
      commit('updateShopConfig', response);

      // Catch shop retrieving errors.
    } catch (error) {
      const err = errMsg(error);
      await dispatch('alert/error', err, { root: true });
    }
  },

  async getSoldCount(
    { dispatch, commit },
  ) {
    try {
      let soldCount = await mintService.getSoldCount();
      commit('updateSoldCount', soldCount);

      // Catch shop retrieving errors.
    } catch (error) {
      const err = errMsg(error);
      await dispatch('alert/error', err, { root: true });
    }
  },

  // Get current price for an item
  async currentPrice(
    { dispatch, commit },
  ) {
    try {
      let currentPrice = await mintService.currentPrice();
      commit('updateCurrentPrice', currentPrice);

      // Catch and notify the user of a purchase error.
    } catch (error) {
      const err = errMsg(error);
      await dispatch('alert/error', err, { root: true });
    }
  },

  // mint from the shop
  async mint(
    { dispatch, commit },
    { qnt, isToken, isWhitelist, mintStore, ethersStore },
  ) {

    try {

      await mintService.purchaseItem(
        qnt,
        isToken,
        isWhitelist,
        mintStore,
        ethersStore,
        dispatch
      );

      // Catch and notify the user of a purchase error.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Fetch the merkle proofs for a user
  async loadMerkleData(
    { dispatch, commit },
  ) {

    try {
      let merkleData = await merkleService.loadMerkleData();
      commit('updateProofs', merkleData);

      // Catch and notify the user of a purchase error.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Fetch owned items for a user
  async loadItems(
    { dispatch, commit },
    { mintStore, resolveMetadata },
  ) {

    try {
      let owned = await mintService.loadItems(mintStore, resolveMetadata, dispatch);
      commit('updateOwned', owned);

      // Catch and notify the user of a purchase error.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Fetch minted items for a user
  async loadMintedItems(
    { dispatch, commit },
    { ethersStore },
  ) {

    try {
      let owned = await mintService.loadMintedItems(ethersStore);
      commit('updateMinted', owned);

      // Catch and notify the user of a purchase error.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async calcEth(
    { dispatch, commit },
    { eth, wei }
  ) {
    try {
      let response = mintService.calcEth(eth, wei);
      commit('updateNextPrice', response);

      // Catch shop retrieving errors.
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Fetch token (allowance, owned) for a user
  async loadTokenInfo(
    { dispatch, commit },
    { ethersStore },
  ) {

    try {
      let tokenInfo = await mintService.loadTokenInfo(ethersStore, dispatch);
      commit('updateTokenInfo', tokenInfo);

      // Catch and notify the user of any errors
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async approveSuper({ dispatch, commit }) {
    try {
      let response = await mintService.approveSuper({ dispatch })
      if (response?.approvalAmount) {
        commit('updateApproveSuper', response.approvalAmount)
      }
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }

  }
};
