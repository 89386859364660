'use strict';

// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Prepare state.
const state = () => ({
  isLoggedIn: false,
  pageIndex: 1,
  playfab: {}
});

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
