<template>
<SkewedDiv
  :clip-path="`polygon(0% 0%, 100% ${sizeCoeff}px, calc(100% - ${sizeCoeff * 2}px) 100%, ${sizeCoeff * 2}px 100%)`"
  :border-radius="sizeCoeff * 2"
  :class-skew-box="`roadmap-phase__skew-box-${props.background}`"
  class-content="roadmap-phase__content"
  class="roadmap-phase"
>
  <slot name="impostor" />
  <h1>PHASE {{ props.phaseId }}</h1>
  <div class="roadmap-phase__items">
    <SkewedDiv
      v-for="({ icon, title, subcontent }, index) of items"
      :key="index"
      opacity=".2"
      clip-path="polygon(0% 0%, 100% 0%, calc(100% - 8px) 100%, 4px 100%)"
      :border-radius="8"
      class-skew-box="roadmap-phase__item-skew-box"
      :class-content="`roadmap-phase__item-content-${subcontent ? 'subcontent' : 'no-subcontent'}`"
      class="roadmap-phase__item"
    >
      <div
        :class="`roadmap-phase__item-icon-${icon}`"
        class="roadmap-phase__item-icon"
      />
      <h2 :class="{ 'only-title': !subcontent }">
        {{ title }}
      </h2>
      <p
        v-if="subcontent"
        v-html="subcontent"
      />
    </SkewedDiv>
  </div>
</SkewedDiv>
</template>

<script setup>
import { computed, defineProps, inject } from 'vue';
import { SkewedDiv } from '@/components/common';

const props = defineProps({
  phaseId: { type: Number, required: true },
  background: { type: String, required: true }, 
  items: { type: Array, required: true }
});

const windowSize = inject('windowSize');

const sizeCoeff = computed(() => windowSize?.value?.width && windowSize.value.width > 640 ? 8 : 4);
</script>

<style scoped lang="scss">
@import '../../../assets/style/mixins.scss';

$backgrounds:
  'blue' linear-gradient(96.14deg, #849BE6 21.08%, #2E4EB6 83.34%) initial,
  'reverse-blue' linear-gradient(93.21deg, #4864BD 11.06%, #103099 86.85%) matrix(-1, 0, 0, 1, 0, 0),
  'green' linear-gradient(92.09deg, #56B8A6 23.14%, #006F5B 91.49%) initial;

$icons:
  'arcade',
  'axe',
  'calendar',
  'chest',
  'face',
  'gift',
  'gps-left',
  'gps-right',
  'pet',
  'settings',
  'ufo',
  'vector';

.roadmap-phase {
  :deep(.roadmap-phase__content) {
    width: 100%;
    height: 100%;
    padding: 48px 48px 40px 56px;
    @include flex(row, flex-start, flex-start);
  }

  @each $name, $background, $transform in $backgrounds {
    :deep(.roadmap-phase__skew-box-#{$name}) {
      background: $background;
      transform: $transform;
    }
  }

  h1 {
    margin-right: 36px;
    margin-bottom: 24px;
    white-space: nowrap;
    @include DMSans(30px, 700);
  }

  &__items {
    width: 100%;
    @include flex(column, flex-start, stretch);
    row-gap: 20px;
  }

  &__item {
    position: relative;
    
    :deep(.roadmap-phase__item-skew-box) {
      background-color: var(--color-white);
    }

    :deep(.roadmap-phase__item-content-subcontent) {
      padding: 10px 54px;
    }

    :deep(.roadmap-phase__item-content-no-subcontent) {
      padding: 24px 74px;
    }

    &-icon {
      width: 54px;
      height: 54px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: -20px;
      bottom: calc(50% - 27px);

      
      @each $icon in $icons {
        &-#{$icon} {
          background-image: url('~@/images/roadmap/#{$icon}.png');
        }
      }
    }

    h2 {
      @include DMSans(30px, 700);

      &.only-title {
        @include DMSans(22px, 700);
      }
    }

    p {
      @include DMSans(17px, 700);
    }
  }
}

@include mediaOnlyScreenMax(1050px) {
  .roadmap-phase {
    :deep(.roadmap-phase__content) {
      padding: 48px 48px 48px 56px;
      @include flex(column, flex-start, center);
    }
  }
}

@include mediaOnlyScreenMax(640px) {
  .roadmap-phase {
    :deep(.roadmap-phase__content) {
      padding: 24px 24px 24px 36px;
    }

    @each $name, $background, $transform in $backgrounds {
      :deep(.roadmap-phase__skew-box-#{$name}) {
        background: $background;
        transform: $transform;
      }
    }

    h1 {
      @include DMSans(22px, 700);
    }

    &__items {
      width: 100%;
      @include flex(column, flex-start, stretch);
      row-gap: 20px;
    }

    &__item {
      position: relative;
      
      :deep(.roadmap-phase__item-skew-box) {
        background-color: var(--color-white);
      }

      :deep(.roadmap-phase__item-content-subcontent) {
        padding: 18px 42px 12px 54px;
      }

      :deep(.roadmap-phase__item-content-no-subcontent) {
        padding: 18px 42px 16px 54px;
      }

      h2 {
        @include DMSans(26px, 700);
      }

      p {
        @include DMSans(14px, 700);
      }
    }
  }
}
</style>
