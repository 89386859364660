<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
  >
    <path
      d="M8.20019 16.6C8.60019 17.1 9.40019 17.1 9.80019 16.6L15.8002 10.3C16.3002 9.8 15.8002 9 15.0002 9H11.0002C11.0002 9 11.2002 4.4 11.0002 2C10.9002 0.9 10.1002 0 9.00019 0C7.90019 0 7.10019 0.9 7.00019 2C6.80019 4.3 7.00019 9 7.00019 9H3.00019C2.20019 9 1.70019 9.8 2.20019 10.4L8.20019 16.6Z"
      fill="white"
    />
    <path
      d="M16 19H2C0.9 19 0 19.9 0 21C0 21.6 0.4 22 1 22H17C17.6 22 18 21.6 18 21C18 19.9 17.1 19 16 19Z"
      fill="white"
    />
  </svg>
</template>
