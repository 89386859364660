<template>
  <div class="window" :class="indexClass">
    <div class="navigation">
      <div class="buttonsWrapper">
        <div class="button red" @click="closeWindow"></div>
        <div class="button yellow"></div>
        <div class="button green"></div>
      </div>
      <p>{{ title }}</p>
      <div></div>
    </div>
    <slot class="slot"></slot>
  </div>
</template>
<script>
import { computed } from 'vue';

export default {
  props: {
    index: Number,
    title: String
  },

  setup(props, context) {
    const closeWindow = () => {
      context.emit('close');
    };

    const indexClass = computed(() => {
      return `index-${props.index}`;
    });

    return {
      closeWindow,
      indexClass
    };
  }
};
</script>
<style scoped lang="scss">
.index-0 {
  top: calc(30px + 1%);
  left: 8%;
}

.index-1 {
  top: calc(30px + 3%);
  left: 10%;
}

.index-2 {
  top: calc(30px + 5%);
  left: 12%;
}

.index-3 {
  top: calc(30px + 3%);
  left: 14%;
}

.index-4 {
  top: calc(30px + 1%);
  left: 16%;
}
.window {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(100px);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
  max-width: 70vw;
  width: 100%;
  max-height: calc(100vh - 180px);
  height: 100%;
  border-radius: 10px;
  overflow: hidden;

  .navigation {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(100px);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    position: sticky;

    p {
      padding: 10px;
      justify-self: center;
      white-space: nowrap;
    }
    height: 40px;
    .buttonsWrapper {
      display: flex;
      padding: 10px;

      .button {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin: 5px;

        &:hover {
          opacity: 0.9;
        }

        &:active {
          opacity: 0.7;
        }
      }

      .red {
        background-color: #ff544d;
      }

      .yellow {
        background-color: #feb428;
      }

      .green {
        background-color: #23c138;
      }
    }
  }

  .slot {
  }
}
</style>
