<template>
  <Header :isHome="false" :buttonMsg="buttonMsg"/>
  <div class="documents">
    <div class="fade" />
    <SkewedDiv class="container" :borderRadius="15" background="#2F4EB6" :skew="[0, 2, 10, -10]">
        <h1>Documents</h1>
        <SkewedDiv :borderRadius="2" background="#AAB8FF" :skew="[[0, -6], [0, -3], 10, 0]">
          <div class="line"></div>
        </SkewedDiv>
        <div class="wrapper">
          <div class="files">
            <DocumentRow v-for="(document, index) in documents" :key="index" :document="document"/>
          </div>
          <div class="imgWrapper">
            <img src="https://impostors.s3.amazonaws.com/assets/impostor-character-documents.webp" />
          </div>
        </div>
    </SkewedDiv>
  </div>
</template>
<script setup>
import Header from '@/pages/layout/Header.vue';
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import DocumentRow from './components/DocumentRow.vue';

const documents = [{label: 'Download the Whitepaper', size: '2.2mb', url: 'https://impostors.s3.amazonaws.com/assets/test.pdf'},
                   {label: 'Infographic', size: '1.2mb', url: 'https://impostors.s3.amazonaws.com/assets/test.pdf'}];

</script>
<style scoped lang="scss">
.documents {
  background-image: url('../../images/background.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 90vh;
  padding-top: 90px;
  display: flex;
  justify-content: center;
  position: relative;

  .fade {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0), #1E1226);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
  }

  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0px 40px;
    padding: 50px 70px;

    h1 {
      font-weight: 500;
      font-size: 40px;
      margin-bottom: 10px;
    }

    .line {
      width: 100%;
      height: 10px;
    }

    .wrapper {
      margin-top: 30px;
      display: flex;
      gap: 10px;

      .files {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .imgWrapper {
        max-width: 350px;
        width: 100%;
        height: auto;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@media (max-width: 940px) {
  .documents .container .wrapper .imgWrapper {
    display: none;
  }
}

@media (max-width: 660px) {
  .documents {
    padding-top: 110px;
  }
  .documents .container {
    padding: 25px;
    margin: 0 10px;
  }
}
</style>