const config = {
	playfab: {
	  titleId: 'F0D5B',
	  impostorsInventoryId: 'impostors-game-character'
	},
	durableObjects: {
	  walletIndexerPath: 'wallet/',
	  FIND: '',
	  SET: '/set/',
	  DELETE: '/delete/',
	  genPath: cmd => {
		return fetch(
		  cmd.host +
			config.durableObjects.walletIndexerPath +
			cmd.addr +
			cmd.action +
			cmd.param,
		  {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify({
			  token: cmd.token
			})
		  }
		);
	  }
	}
  };
  
  export default config;
  