<template>
  <div class="appContainer">
    <div class="main">
      <router-view />
    </div>
  </div>
</template>
<script>
'use strict';

// Imports.
import { mapState, useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { l } from '@/datadog';
import { flag } from '@/featureflag';

// Set up the default application component.

export default {
  components: {},

  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    l.init();
    flag.init();

    store.dispatch('login/initialize');

    return {
      router,
      store,
    };
  },

  computed: {
    ...mapState({
      alert: state => Object.assign({}, state.alert)
    })
  },

  mounted() {
    this.$store.$toasts = this.$toasts;
    this.$store.commit('checkWalletConnectStore');
  },

  watch: {
    alert: {
      deep: true,
      handler: function (newAlert) {
        let message = newAlert.message;
        if (this.lastMessage === message) {
          // TODO remove this once alert hijacking is removed
          return;
        }
        let type = newAlert.type;
        let duration = newAlert.duration;
        let metadata = newAlert.metadata ? newAlert.metadata : {};

        if (message && type) {
          this.$toasts.base('', {
            message: message,
            type: type,
            duration: duration,
            metadata: metadata
          });
          this.lastMessage = message;
        } else {
          // TODO: dispatch a clear event.
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.appContainer {
  background: #1e1226;
  min-height: 100vh;

  .main {
    height: 100%;
    position: relative;
    overflow-y: hidden;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
