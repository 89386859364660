import { computed, ref } from 'vue';
import { useMutation, useQuery, useQueryClient } from 'vue-query';
import { claimService, configService } from '@/services';
import { useStore } from 'vuex';
import { useNow } from '@vueuse/core';

import { l } from '@/datadog';
import { flag } from '@/featureflag';
const logName = 'composables.js';

export const EligibilityType = {
  Impostor: 0,
  UFO: 1,
  Pet: 2,
  Box: 3,
  Chest: 4,
  Pass: 5,
  Voxel: 6
};

export function useOwned() {
  let store = useStore();
  let byTokenType = obj =>
    Object.values(obj).reduce(
      (acc, v) => ({
        ...acc,
        ...(v.length && { [v[0].tokenType]: v })
      }),
      {}
    );

  return useQuery(
    ['ownedItems'],
    async () => {
      let resp = await l.timed(() => claimService.loadEligibleItems({ resolveMetadata: false, dispatch: store.dispatch }), {
        f: [logName, 'ownedItems']
      });
      let { eligible, owned, ...rest } = resp;
      return {
        eligible,
        owned: byTokenType(owned),
        ...rest
      };
    },
    {
      refetchInterval: 1000 * 15
    }
  );
}

export function useCheckEligibility(type, id) {
  let { data: configRef, isLoading: configIsLoading } = configService.cfg();
  let store = useStore();
  let collectionAddress = computed(() => {
    let config = configRef.value;
    switch (type) {
      case EligibilityType.Impostor:
        return config?.itemCollections;
      case EligibilityType.UFO:
        return config?.ufoCollections;
      case EligibilityType.Pet:
        return config?.petCollections;
      case EligibilityType.Box:
        return config?.boxCollections;
      case EligibilityType.Chest:
        return config?.chestCollections;
      case EligibilityType.Pass:
        return config?.passCollections;
      case EligibilityType.Voxel:
        return config?.voxelCollections;
      default:
        return undefined;
    }
  });
  const enable = computed(
    () => id != null && id.value > 0 && !!collectionAddress.value
  );
  let queryResult = useQuery(
    ['eligibility', type, id],
    async () => {
      return await claimService.checkEligibility({
        collection: collectionAddress.value,
        tokenId: id.value,
        dispatch: store.dispatch,
        type: type
      });
    },
    {
      enabled: enable
    }
  );

  return {
    ...queryResult
  };
}

export function useClaimTokenInfo() {
  let store = useStore();
  return useQuery(
    ['tokenInfo', 'claim'],
    async () => {
      return await claimService.loadTokenInfo({ dispatch: store.dispatch });
    },
    {
      refetchInterval: 1000 * 15
    }
  );
}

export function useClaimDetails() {
  let { dispatch } = useStore()
  return useQuery(['claimDetails'], async () => {
    let resp = await l.timed(() => claimService.getClaimDetails({ dispatch }), {
      f: [logName, 'claimDetails']
    });
    return resp;
  });
}

export function useRedeem() {
  let store = useStore();
  let queryClient = useQueryClient();
  return useMutation(
    ({ round, claims }) => {
      return claimService.redeem({ round: round, tokenIds: claims, dispatch: store.dispatch }, {
        onError: msg => {
          throw new Error(msg);
        }
      });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['ownedItems']);
      }
    }
  );
}

export function useApproveRedeemer() {
  let store = useStore();
  let queryClient = useQueryClient();
  return useMutation(
    () => {
      return claimService.approveRedeemer({ dispatch: store.dispatch });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['tokenInfo']);
      }
    }
  );
}

export function usePetClaimIsLive() {
  let now = useNow();
  return computed(() => now.value.getTime() >= flag.petLiveTime && flag.petEnabled.value);
}

export function useClaimFeatureIsLive() {
  let now = useNow();
  console.log("enable", flag.voxelLiveTime.value);
  return computed(() => ({
    pet: now.value.getTime() >= flag.petLiveTime.value && flag.petEnabled.value,
    box: now.value.getTime() >= flag.boxLiveTime.value && flag.boxEnabled.value,
    chest: now.value.getTime() >= flag.chestLiveTime.value && flag.chestEnabled.value,
    pass: false, //now.value.getTime() >= flag.passLiveTime.value && flag.passEnabled.value,
    voxel: true // && flag.voxelEnabled.value
  }));
}
