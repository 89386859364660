<template>
  <div class="footer">
    <div class="footer__background">
      <SkewedDiv
        :border-radius="16"
        clip-path="polygon(0 30px, 100% 0, 100% 100%, 0 100%)"
        class-skew-box="footer__background-big-skew-box"
        class="footer__background-big"
      />
      <div class="footer__background-small" />
    </div>
    <div class="footer__content">
      <img
        src="~@/assets/impostor-footer.png"
        class="footer__impostor"
      />
      <div class="footer__logo-group">
        <img
          src="~@/assets/footer-imposters-logo.svg"
          class="footer__logo-group-impostors-logo"
        />
        <img
          src="~@/images/game-icons.png"
          class="footer__logo-group-game-icons"
        />
      </div>
      <div
        v-for="(buttonGroup, groupId) of FOOTER_BUTTONS"
        :key="groupId"
        class="footer__button-group"
      >
        <p
          v-for="({ label, href }, buttonId) of buttonGroup"
          :key="buttonId"
          class="footer-cell"
        >
          <a :href="href" :target="href.includes('http') ? '_blank' : ''">{{ label }}</a>
        </p>
      </div>
      <div class="footer__subscribe-group">
        <div class="footer__subscribe-group-info">
          <span class="copyright">Copyright © 2022 Impostors</span>
          <div class="social-icons">
            <TwitterIcon
              class="social-icons__item"
              @click="openUrl('https://twitter.com/PlayImpostors')"
            />
            <DiscordIcon
              class="social-icons__item"
              @click="openUrl('https://discord.gg/ghv8vjj52D')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import DiscordIcon from '/src/components/icons/DiscordIcon.vue';
import TwitterIcon from '/src/components/icons/TwitterIcon.vue';
import { SkewedDiv } from '@/components/common';
import { FOOTER_BUTTONS } from '@/constants';

const store = useStore();

const openUrl = url => {
  window.open(url, '_blank');
};
</script>

<style scoped lang="scss">
@import '../../../assets/style/mixins.scss';

.footer {
  position: relative;
  @include flex(row, stretch, stretch);
  overflow: hidden;
  height: 400px;
  width: 100%;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 0;

    &-big {
      width: 100%;
      height: 100%;

      &:after {
        content: '';
        width: 100%;
        height: 200px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(to top, #415CB5 120px, rgba(65, 92, 181, 0) 100%);
      }

      :deep(.footer__background-big-skew-box) {
        background: #415CB5;
      }
    }

    &-small {
      display: none;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    z-index: 2;
    @include flex(row, space-between, stretch);
    @include DMSans();
  }

  &__impostor {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  &__logo-group {
    position: initial;
    margin-left: 74px;
    margin-top: 108px;
    width: fit-content;
    @include flex(column, flex-start, flex-end);
  }

  &__button-group {
    width: fit-content;
    margin-top: 184px;
    @include flex(column, flex-start, flex-start);

    .footer-cell {
      font-size: 14px;
      line-height: 1.3;
      margin-bottom: 24px;

      &:hover {
        opacity: .5;
      }
    }

    .footer-cell > a {
      color: #FFFFFF;
      text-decoration: none;
    }
  }

  &__subscribe-group {
    width: fit-content;
    margin-top: 108px;
    margin-right: 98px;
    margin-bottom: 48px;
    @include flex(column, flex-end, flex-end);

    &-info {
      @include flex(row, flex-end);
      column-gap: 24px;

      .copyright {
        @include DMSans(12, 400);
      }

      .social-icons {
        @include flex();
        column-gap: 24px;

        &__item {
          &:hover {
          cursor: pointer;
          opacity: 0.6;
        }
        }
      }
    }
  }
}

@media (max-width: 1050px) {
  .footer {
    width: 100%;
    height: 456px;

    &__background {
      &-big {
        display: none;
      }

      &-small {
        display: inherit;
        height: 100%;
        width: 100%;
        background-color: #415CB5;
        clip-path: polygon(0 30px, 100% 0, 100% 100%, 0 100%);
      }
    }

    &__content {
      @include flex(column, flex-start, stretch);
    }

    &__impostor {
      transform: scaleX(-1);
      position: absolute;
      right: 0;
      left: initial;
    }

    &__logo-group {
      margin: initial;
      width: 100%;
      margin-top: 38px;
      @include flex(column);
      margin-bottom: 18px;

      &-impostors-logo {
        width: 335px;
      }

      &-game-icons {
        height: 48px;
      }
    }

    &__button-group {
      margin-left: 24px;
      margin-top: auto;

      .footer-cell {
        font-size: 16px;
        margin-bottom: 6px;
      }
    }

    &__subscribe-group {
      margin-top: auto;
      margin-right: auto;
      margin-left: 24px;
      margin-bottom: 30px;
      @include flex(column, flex-end, flex-end);
      flex-grow: 1;

      &-info {
        @include flex(column-reverse, flex-start, flex-start);
        column-gap: initial;

        .copyright {
          @include DMSans(12, 400);
          margin-top: 18px;
        }

        .social-icon {

          &:hover {
            cursor: pointer;
            opacity: 0.6;
          }
        }
      }
    }
  }
}
</style>
