<script setup>
import { ref, computed, onUpdated } from 'vue';
import SkewedButton from '@/components/common/SkewedButton.vue';
import PreviewCard from '@/components/common/ImpostorPreviewCard.vue';
import EligibilityChip from './EligibilityChip.vue';
import { useCheckEligibility } from '../composables'

const id = ref(null);

let { isLoading, isFetching, isError, error, data, refetch } = useCheckEligibility(props.checkType, id);

const props = defineProps({
  tokenType: { type: String },
  checkType: { type: Number }
});
</script>

<template>
  <div :class="s.checkImpostor">
    <div>
      <PreviewCard
        :tokenId="id"
        :tokenType="props.tokenType"
        :key="id"
        :class="s.previewCard"
        :classImage="s.previewCardImage"
      />
    </div>
    <div :class="s.content">
      <div :class="s.controls">
        <input :class="s.input" type="number" min="1" v-model="id" placeholder="NFT ID" />
        <SkewedButton
          :class="s.button"
          :borderRadius="6"
          :skew="[2, 0, [6, 6], 0]"
          background="var(--color-button-yellow)"
          @click="refetch"
        >
          <span>Check</span>
        </SkewedButton>
      </div>

      <div :class="s.results">
        <template v-if="id === null"></template>
        <template v-else-if="isFetching || isLoading">
          <EligibilityChip :isLoading="true" />
        </template>
        <template v-else-if="!data?.minted">
          <EligibilityChip name="NFT not found" isMessage />
        </template>
        <template v-else-if="!!data">
          <slot name="chips" :data="data" />
        </template>
      </div>
    </div>
  </div>
</template>

<style module="s" lang="scss">
@import "@/assets/style/mixins.scss";

.checkImpostor {
  display: flex;
  gap: 39px;

  @include mMax(950px) {
    flex-direction: column;
  }
}
.previewCard {
  width: 261px;
  height: 261px;
  border-radius: 20px;

  @include mMax(950px) {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
}
.previewCardImage {
  border-radius: 20px;
}
.button.button {
  padding: 18px 66px;
  font-size: 20px;
  font-weight: bold;
}
.content {
  flex: 1;
}
.controls {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 24px;

  @include mMax(950px) {
    flex-direction: column;
  }
}
.input {
  font-size: 18px;
  font-weight: bold;
  max-width: 148px;
  padding: 16px;
  border-radius: 6px;
  border: none;
  color: #212121;
}
.results {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;

  @include mMax(950px) {
    grid-template-columns: 1fr;
  }
}
</style>
