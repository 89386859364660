<script setup>
import SkewedModal from '@/components/common/SkewedModal.vue';
import SkewedButton from '@/components/common/SkewedButton.vue';
import BackArrowRounded from '@/components/icons/BackArrowRounded.vue'
import ForwardArrowRounded from '@/components/icons/ForwardArrowRounded.vue'
import { computed } from 'vue';
import DownloadIcon from '@/components/icons/DownloadIcon.vue';
import {onKeyStroke} from '@vueuse/core'

let props = defineProps({
  mediaItems: { type: Array, default: () => [] },
  initialIndex: { type: Number, default: 0 },
  name: { type: String }
})

let emit = defineEmits(['next', 'previous'])

let displayedMedia = computed(() => props.mediaItems[props.initialIndex])
let nextDisabled = computed(() => props.initialIndex >= props.mediaItems.length - 1)
let prevDisabled = computed(() => props.initialIndex <= 0)
let download = async ({ url, filename }) => {
  let res = await fetch(url)
  let blob = await res.blob()
  let link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = filename
  link.click()
  URL.revokeObjectURL(link.href)
}

onKeyStroke(['ArrowRight'], (e) => {
  e.preventDefault()
  if (!nextDisabled.value) {
    emit('next')
  }
})
onKeyStroke(['ArrowLeft'], (e) => {
  e.preventDefault()
  if (!prevDisabled.value) {
    emit('previous')
  }
})
</script>

<template>
  <div class="media-modal">
    <SkewedModal
      :borderRadius="16"
      :skew="[[0, -10], 0, [-6, 0], [8, 0]]"
      :hideTitleLine="true"
      class="media-modal"
      noScroll
      @exit="$emit('exit')"
    >
      <template #title>
        <div class="top-controls">
          <SkewedButton
            :borderRadius="4"
            :skew="[0, 0, 0, [-3, 3]]"
            background="var(--color-button-yellow)"
            class="nav-button"
            @click="$emit('previous')"
            :disabled="prevDisabled"
          >
            <BackArrowRounded />
          </SkewedButton>
          <SkewedButton
            :borderRadius="4"
            :skew="[0, 0, [-3, 3], 0]"
            background="var(--color-button-yellow)"
            class="nav-button"
            @click="$emit('next')"
            :disabled="nextDisabled"
          >
            <ForwardArrowRounded />
          </SkewedButton>
        </div>
      </template>

      <template #content>
        <div class="content-wrapper">
          <SkewedButton
            :borderRadius="4"
            :skew="[0, 0, 0, [-6, 6]]"
            background="var(--color-button-yellow)"
            class="nav-button side-controls"
            @click="$emit('previous')"
            :disabled="prevDisabled"
          >
            <BackArrowRounded />
          </SkewedButton>
          <div class="media">
            <template v-for="(item, index) in props.mediaItems" :key="index">
              <!-- <template v-if="index === props.initialIndex"> -->
              <template v-if="item.type === 'video'">
                <video
                  controls
                  loop
                  autoplay
                  :style="index === props.initialIndex ? '' : 'display: none;'"
                >
                  <source :src="item.src" type="video/mp4" />
                </video>
              </template>

              <template v-else>
                <img :src="item.src" :style="index === props.initialIndex ? '' : 'display: none;'" />
              </template>
              <!-- </template> -->
            </template>
          </div>
          <SkewedButton
            :borderRadius="4"
            :skew="[0, 0, [-6, 6], 0]"
            background="var(--color-button-yellow)"
            class="nav-button side-controls"
            @click="$emit('next')"
            :disabled="nextDisabled"
          >
            <ForwardArrowRounded />
          </SkewedButton>
        </div>
      </template>

      <template #footer>
        <div class="footer-wrapper">
          <p>{{ props.name }}</p>
          <SkewedButton
            :borderRadius="5"
            :skew="[0, 0, [-6, 4], 0]"
            background="var(--color-button-yellow)"
            class="download-button"
            as="a"
            :href="displayedMedia.src"
            download
            @click.prevent="download({ url: displayedMedia.src, filename: displayedMedia.name })"
          >
            DOWNLOAD
            <DownloadIcon class="button-icon" />
          </SkewedButton>
        </div>
      </template>
    </SkewedModal>
  </div>
</template>

<style scoped lang="scss">
img,
video {
  width: 100%;
  border-radius: 22px;
  max-height: 74vh;
}
video {
  min-width: 100%;
  aspect-ratio: 1;
}

.wrapper {
  &::v-deep {
    .skewed-modal-content.content {
      max-height: initial !important;
    }

  }
}
.media-modal {
  &::v-deep {
    .media {
      display: flex;
      justify-content: center;
      img {
        border-radius: 22px;
        max-height: 80vh;
        width: 100%;
      }
      video {
        border-radius: 22px;
      }
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-button {
    margin: 0 16px;
    padding: 18px 18px;
  }

  .footer-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 32px;
    }

    .download-button {
      margin-left: 24px;
      font-size: 20px;
      padding: 16px 24px;
      text-decoration: none;

      ::v-deep path {
        fill: black;
      }

      .button-icon {
        margin-left: 24px;
      }
    }

    @media (max-width: 1150px) {
      flex-direction: column;
      & > * + * {
        margin-top: 16px;
      }
    }
  }

  .side-controls {
    @media (max-width: 1150px) {
      display: none;
    }
  }

  .top-controls {
    display: none;

    @media (max-width: 1150px) {
      display: flex;

      .nav-button {
        padding: 12px;
        margin: 0 7px;
      }
    }
  }
}
</style>

<style lang="scss">
.media-modal.modal-container {
  .modal {
    align-items: center;
  }
  .skewedContent.content {
    width: fit-content;
  }
  .top-wrapper {
    padding-right: 124px !important;
  }
  @media (max-width: 1150px) {
    .top-wrapper {
      padding-right: 8px !important;
    }
    .modal {
      .content-container {
        padding: 0;
        .skewed-modal-content {
          padding: 0;
        }
      }
    }
  }
}
</style>
