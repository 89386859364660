<template>
  <SkewedModal
    :borderRadius="16"
    :skew="[[0, -10], 0, [-6, 0], [8, 0]]"
    background="linear-gradient(90deg, #849BE5, #2E4EB6)"
    @exit="exit"
    class="unstake-modal"
  >
    <template #title>
      <div class="title">
        <span>Unstake</span>
        <span class="count">{{ data.impostors.length }}</span>
      </div>
    </template>

    <template #content>
      <div class="content-wrapper">
        <!-- unstaked container -->
        <div class="impostors-container">
          <ImpostorCard
            :impostor="impostor"
            v-for="(impostor) in data.impostors"
            :key="impostor.tokenId"
            showTitle
            titleTop
            hidePoolInfo
          />
        </div>
      </div>
    </template>

    <template #footer>
      <SkewedDiv
        background="#000000"
        :opacity=".2"
        class="footer-wrapper"
        :borderRadius="18"
        :skew="[-12, 0, 0, 0]"
      >
        <p
          class="footer-text"
        >
          When you unstake you will need to claim any pending $BLOOD separately.
        </p>
        <div class="button-wrapper">
          <SkewedButton
            :borderRadius="7"
            :skew="[0, 0, 0, [-5, -3]]"
            background="var(--color-button-yellow)"
            class="button"
            @click="unstake"
            :isLoading="isSubmitting"
            :disabled="isSubmitting"
          >
            <div>
              <span>UNSTAKE</span>
            </div>
          </SkewedButton>
        </div>
      </SkewedDiv>
    </template>
  </SkewedModal>
</template>
<script setup>
import SkewedModal from '@/components/common/SkewedModal.vue';
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import SkewedButton from '@/components/common/SkewedButton.vue';
import ImpostorCard from './ImpostorCard.vue';

const props = defineProps({
  data: { type: Object },
  isSubmitting: { type: Boolean }
});
const emit = defineEmits(['exit', 'stake', 'unstake']);

const exit = () => {
  emit('exit');
};

let unstake = () => {
  emit('unstake', { nfts: props.data.nfts, poolId: props.data.poolId });
}
</script>
<style scoped lang="scss">
.content-wrapper {
  width: 100%;
  .impostors-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding: 4px;
  }
}

.footer-text {
  margin-bottom: 18px;
  padding-top: 20px;
  font-size: 16px;
  text-align: center;
}

.button-wrapper {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;

  .button {
    font-weight: 500;
    font-size: 26px;
    max-width: 296px;
    width: 100%;
    padding: 24px;

    span {
      text-transform: capitalize;
    }
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.count {
  font-size: 16px;
  background: #212121;
  border-radius: 29px;
  height: 29px;
  min-width: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
  padding: 0 8px;
}

.footer-wrapper {
  margin: 0 -48px -38px -48px;
  padding: 20px 0 50px 0;
}

@media (max-width: 600px) {
  .button-wrapper {
    flex-direction: column;
    .button {
      max-width: 50%;
    }
  }
  .footer-text {
    padding: 0 28px;
  }
  .footer-wrapper {
    padding-bottom: 24px;
  }
  .button-wrapper {
    .button {
      font-size: 18px;
    }
  }
}
</style>

<style lang="scss">
.unstake-modal {
  .root.modal {
    min-height: 80%;
    > .content {
      position: absolute;
      height: 100%;
      > .content-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;

        .skewed-modal-content {
          flex: 1;
        }
      }
    }

    @media (max-width: 600px) {
      min-height: 100%;
    }

  }
}
</style>
