<script setup>
console.log('test')
</script>

<template>
  <div class="privacy-container">
    <div class="privacy-text-container">
      <p class="c33 title"><span class="c0 c13 c11">IMPOSTORS NFT PURCHASE AND LICENSE AGREEMENT</span></p>
      <p class="c30" id="h.gjdgxs"><span class="c1">This Impostors NFT Purchase and License Agreement (this
          &ldquo;</span><span class="c12 c0">Agreement</span><span class="c1">&rdquo;) is a legally binding agreement by
          and
          between Impostors Protocol Ltd., the subsidiary of the Impostors Foundation (&ldquo;</span><span
          class="c12 c0">Impostors</span><span class="c1">&rdquo; or &ldquo;</span><span class="c12 c0">us</span><span
          class="c1">&rdquo;) and any holder of any Impostors NFT (defined below) (&ldquo;</span><span
          class="c12 c0">you</span><span class="c1">&rdquo; or &ldquo;</span><span class="c0 c12">Holder</span><span
          class="c1">&rdquo;). Impostors and each Holder may be referred to throughout this Agreement collectively as
          the
          &ldquo;</span><span class="c12 c0">Parties</span><span class="c1">&rdquo; or individually as a
          &ldquo;</span><span class="c12 c0">Party</span><span class="c1">&rdquo;. By purchasing or otherwise holding an
          Impostors NFT, you
          acknowledge that you have carefully read and agree to the terms of this Agreement.</span></p>
      <p class="c19 c24" id="h.30j0zll"><span class="c0 c19">WHEN YOU AGREE TO THESE TERMS, YOU ARE AGREEING (WITH
          LIMITED
          EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND </span><span class="c0 c19">IMPOSTORS </span><span
          class="c0 c19">THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTIONS
          14
          &ldquo;DISPUTE RESOLUTION&rdquo; BELOW FOR DETAILS REGARDING ARBITRATION.</span><span
          class="c10 c9">&nbsp;</span><span class="c0">HOWEVER, IF YOU ARE A RESIDENT OF A JURISDICTION WHERE APPLICABLE
          LAW
          PROHIBITS ARBITRATION OF DISPUTES, THE AGREEMENT TO ARBITRATE IN SECTION 14 WILL NOT APPLY TO YOU BUT THE
          PROVISIONS OF SECTION 13 (GOVERNING LAW AND FORUM CHOICE) WILL APPLY INSTEAD.</span></p>
      <ol class="c6 lst-kix_list_6-0 start" start="1">
        <li class="c7">
          <h1 id="h.1fob9te" style="display:inline"><span class="c0">Agreement to Terms. </span><span
              class="c10">&nbsp;&ldquo;</span><span class="c0">Impostors NFT</span><span class="c10">&rdquo; refers to a
              non-fungible token (NFT) (i.e., a controllable electronic record recorded on a blockchain) that is minted
              by a
              smart contract deployed to the Ethereum blockchain at address 0x3110ef5f612208724ca51f5761a69081809f03b7,
              0xc31085b262b3d57c649f8747e4f083685697176e, or 0xec7f8a34b97ac65caad3841659f2cd54285a3950, as applicable ]
              (the &ldquo;</span><span class="c0">Impostors Smart Contract</span><span class="c10">&rdquo;), and that,
              as of
              its genesis issuance, is linked to an image of Impostors Art, as applicable. &ldquo;</span><span
              class="c0">Impostors Art</span><span class="c1">&rdquo; means the digital art, e.g., a particular
              character or
              other 3D interactive asset with a combination of traits and statistics based on rarity, which may be from
              the
              Impostors Genesis Aliens, Impostors Genesis UFOs, and Impostors Genesis Pets collections, as it relates to
              the
              Impostors Game (as defined below), linked to a particular Impostors NFT, which images consists of elements
              compiled by the underlying Impostors Smart Contract, and owned by Impostors. </span></h1>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-1 start" start="1">
        <li class="c5">
          <h2 style="display:inline"><span class="c11 c10 c19">Additional Terms</span><span class="c10 c19">: Impostors
              NFTs
              may be available for purchase on one or more platforms, including but not limited to a platform with a
              website
              and interface located at </span><span class="c11 c10 c27"><a class="c18"
                href="https:/impostors.gg/">https://impostors.gg/</a></span><span>,
            </span><span class="c10 c19">(each, a &ldquo;</span><span class="c0 c19">NFT Site</span><span
              class="c10 c19">&rdquo;), which is not owned or operated by us. </span><span class="c10">The access and
              use of
              the NFT Site is subject to the separate terms of the </span><span class="c10 c19">NFT Site</span><span
              class="c1">. &nbsp;If there is a conflict between the NFT Site terms and this Agreement with respect to
              the
              Impostors NFT and/or the Impostors Art, this Agreement controls. </span></h2>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-0" start="2">
        <li class="c7">
          <h1 style="display:inline"><span class="c12 c0">Ownership of Impostors NFT. </span></h1>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-1 start" start="1">
        <li class="c5">
          <h2 style="display:inline"><span class="c10">When Holder holds an Impostors NFT and owns a digital wallet that
              holds such Impostors NFT, as recorded by the Impostors Smart Contract, Holder owns all personal property
              rights to that Impostors NFT (</span><span class="c10 c28">e.g.</span><span class="c1">, the right to
              freely
              sell, transfer, or otherwise dispose of that Impostors NFT).&nbsp;Such rights, however, do not include the
              ownership of the intellectual property rights in the Impostors Art. Such rights are licensed pursuant to
              Section 3 below. In accordance with your personal property rights, as identified above, at no point may we
              seize, freeze, or otherwise modify the ownership of any Impostors NFT.</span></h2>
        </li>
        <li class="c5">
          <h2 id="h.3znysh7" style="display:inline"><span class="c1 c19">You may participate in the sale and purchase of
              the
              Impostors NFT by linking your digital wallet(s) on supported bridge extensions, which allows you to
              purchase,
              store, and engage in transactions using a currency acceptable to us. Before purchasing an Impostors NFT,
              we
              may ask you to download a supported electronic wallet extension and connect and unlock your digital
              wallets
              with that extension. Once you submit an order to sell or purchase an Impostors NFT, your order is passed
              on to
              the applicable extension, which completes the transaction on your behalf.</span></h2>
        </li>
        <li class="c5">
          <h2 style="display:inline"><span class="c10">Holder represents and warrants that it will not transfer an
              Impostors
              NFT in any subsequent transaction to a Transferee that is (i) located in a country that is subject to a
              U.S.
              Government embargo, or that has been designated by the U.S. Government as a terrorist-supporting country;
              or
              is (ii) listed on any U.S. Government list of prohibited or restricted parties (&ldquo;</span><span
              class="c0">Prohibited Transferees</span><span class="c10">&rdquo;). A&nbsp;&ldquo;</span><span
              class="c0">Secondary Transaction</span><span class="c1">&rdquo; means any transaction in which an
              Impostors
              NFT is sold by one holder to another holder after the initial purchase of the Impostors NFT.</span></h2>
        </li>
        <li class="c5">
          <h2 style="display:inline"><span class="c10">Holders may be offered utility, benefits, or entitlements,
              including,
              but not limited to, use of the Impostors NFT in connection with the Impostors interactive entertainment
              application offered by a third party (such entertainment application, the &ldquo;</span><span
              class="c0">Impostors Game</span><span class="c10">&rdquo;) (collectively, &ldquo;</span><span
              class="c0">Utility</span><span class="c1">&rdquo;) from time to time, but this Agreement does not confer
              any
              Utility except as granted by the licenses set forth in Section 3. &nbsp;Super Studios makes no assurances
              of
              any Utility. &nbsp;Any Utility may be subject to other terms and conditions. &nbsp;Super Studios will not
              be
              responsible in any manner for any Utility offered by any third party. </span></h2>
        </li>
        <li class="c5">
          <h2 style="display:inline"><span class="c1">Super Studios has no obligation to support the resolution of, or
              resolve any, dispute that may arise between Impostors NFT holders.</span></h2>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-0" start="3">
        <li class="c7">
          <h1 style="display:inline"><span class="c0">License. </span></h1>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-1 start" start="1">
        <li class="c5">
          <h2 style="display:inline"><span class="c11 c10">Non-Commercial License</span><span class="c1">. Subject to
              your
              compliance with this Agreement, Impostors hereby grants to you, for so long as you hold an Impostors NFT
              (as
              recorded by the Impostors Smart Contract), a non-exclusive, worldwide, royalty-free, revocable license,
              with
              no right to sublicense, to use, copy, display the Impostors Art linked to your purchased Impostors NFT for
              the
              following purposes: (i) for your own personal, non-commercial use, including to create a reasonable number
              of
              back-up copies and a physical print out, each to be retained only for so long as you hold the associated
              Impostors NFT; (ii) to sell or otherwise transfer the associated Impostors NFT consistent with the
              ownership
              of it (e.g., posting a sales listing on NFT Site); and (iii) to use it in connection with the Impostors
              Game
              or any third party offering compatible with the Impostors NFT. </span></h2>
        </li>
        <li class="c5">
          <h2 style="display:inline"><span class="c11 c10">Commercial License</span><span class="c10">. Subject to your
              compliance with this Agreement, Impostors hereby grants to you, for so long as you hold an Impostors NFT
              (as
              recorded by the Impostors Smart Contract), a non-exclusive, royalty-free, worldwide, revocable license,
              with
              the right to sublicense, to use, copy, distribute, create derivative works of (subject to Section 3(c)),
              and
              display, the Impostors Art linked to your purchased Impostors NFT for Commercial Purposes.
              &ldquo;</span><span class="c0">Commercial Purposes</span><span class="c10">&rdquo; means the use of the
              Impostors Art linked to
              your purchased Impostors NFT for all lawful commercial purposes, whether known now or created in the
              future.
              You will have the right to retain all revenue that you generate through your exercise of your rights under
              this Section.</span></h2>
        </li>
        <li class="c5">
          <h2 style="display:inline"><span class="c11 c10">Derivative Works</span><span class="c10">. The right to
              create
              derivative works of the Impostors Art under Section 3(b) means that you can modify the Impostors Art, for
              example, (i) as needed to depict the Impostor shown in the Impostors Art in merchandising goods or media
              (whether physical or digital) in their full body or back or as a three dimensional creature, or to
              reformat
              the Impostors Art for a particular medium, and &nbsp;(ii) to create variations in the appearance of the
              Impostor shown in the Impostors Art. However, such right does not include the right to create an Impostor
              that
              is substantially or confusingly similar to Impostor(s) associated with other Impostors NFT(s) that you do
              not
              hold. Notwithstanding anything to the contrary herein, you may not create derivative works that infringe
              or
              otherwise transgress the rights in the Impostors Art associated with other Impostors NFTs.</span></h2>
        </li>
        <li class="c5">
          <h2 style="display:inline"><span class="c11 c10">Transfer</span><span class="c1">. The licenses in Section 3
              are
              non-transferrable, except that they will automatically transfer in connection with a transfer of the
              Impostors
              NFT (as recorded by the smart contract). Upon a transfer, the licenses in Section 3 granted to you will
              terminate immediately. &nbsp;In particular, you must stop all commercial uses of the rights granted to you
              in
              Section 3, provided that you may reasonably wind down your activities as a result of such uses in a
              reasonable
              period of time (e.g., selling off then-existing inventory of physical merchandise). &nbsp;If you enter
              into
              any agreements under which you license such rights to a third party, you must ensure that such agreements
              terminate upon transfer of the Impostors NFT.</span></h2>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-0" start="4">
        <li class="c7">
          <h1 id="h.2et92p0" style="display:inline"><span class="c4">Reservation of Rights.</span><span
              class="c4 c9">&nbsp;</span></h1>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-1 start" start="1">
        <li class="c5">
          <h2 style="display:inline"><span class="c11 c10">General</span><span class="c10">. All rights in and to the
              Impostors Art not expressly provided for in this Agreement are hereby reserved by Impostors.</span><span
              class="c0">&nbsp;</span><span class="c10">The Impostors Art is licensed, not sold. Impostors </span><span
              class="c3">owns and will retain all title, interest, ownership rights and intellectual property rights in
              and
              to the Impostors Art. For clarity, nothing in this Agreement will prevent Impostors, its representatives,
              or
              any of its affiliates from using, copying, or displaying the Impostors Art associated with your Impostors
              NFT
              in the Impostors Game, for purposes of streaming the Impostors Game, or for any marketing and promotional
              content regarding the Impostors Game, Impostors NFTs, or other Impostors projects, or from allowing
              third-party service providers to do the same, without any obligation or compensation to you. </span><span
              class="c10">Without limitation,</span><span class="c0">&nbsp;</span><span class="c1">Holder shall not, nor
              permit any third party to do or attempt to, do any of the following without express prior written consent
              from
              Impostors: (i) modify the Impostors Art except as expressly permitted herein; (ii) use the Impostors Art
              in a
              manner that violates applicable law; (iii) use the Impostors Art in connection with media or merchandise
              that
              promotes hate speech, illegal activities, vulgarity (e.g., pornography), offensive behavior, violence,
              cruelty
              or political statements; (iv) attempt to register any trademark, copyright, or otherwise acquire or
              enforce
              intellectual property rights in or to the Impostors Art; or (vi) make defamatory or dishonest statements
              about
              Impostors or the Impostors NFTs or otherwise damage the goodwill, value or reputation of Impostors or the
              Impostors NFTs. </span></h2>
        </li>
        <li class="c5">
          <h2 style="display:inline"><span class="c11 c10">No Rights to Trademarks</span><span class="c1">. For
              avoidance of
              doubt, except to the extent that an Impostors trademark is included in the Impostors Art (in which case
              such
              use of Impostors&rsquo;s trademarks will be subject to Section 3 and solely as embodied in the Impostors
              Art),
              the licenses in Section 3 do not include the right to, and you may not, use any Impostors trademarks
              (e.g.,
              Impostors, Impostors Game or the names of any collections of Impostors NFTs). No trademark or other rights
              based on designation of source or origin are licensed to you. &nbsp;You may not use, attempt to register,
              or
              enforce any rights in any asset, including any domain names, social media accounts or related addresses,
              that
              contains or incorporates any artwork, other representation, name or mark that may be confusingly similar
              to
              such trademarks. For clarity, however, you may use Impostors along with your NFT identification number
              (e.g.,
              &ldquo;Impostors #681&rdquo;) to identify your particular Impostors NFT or Imposters Art linked
              thereto.</span></h2>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-0" start="5">
        <li class="c7">
          <h1 id="h.tyjcwt" style="display:inline"><span class="c0">Payment, Fees and Secondary Transactions. </span>
          </h1>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-1 start" start="1">
        <li class="c5">
          <h2 style="display:inline"><span class="c11 c10">Purchase and Sale</span><span class="c10">.</span><span
              class="c8">&nbsp;Holder hereby agrees to purchase the Impostors NFT at the price determined by Impostors
              (the
              &ldquo;</span><span class="c0 c23">Primary Transaction Purchase Price</span><span class="c8">&rdquo;).
              Without
              limiting any of the foregoing, the valid execution of this Agreement, including the delivery of the
              Impostors
              NFT, shall be conditioned upon Holder&rsquo;s payment of the </span><span class="c10">Primary Transaction
              Purchase Price</span><span class="c8">. </span></h2>
        </li>
        <li class="c5">
          <h2 style="display:inline"><span class="c11 c10">Payment</span><span class="c10">.</span><span
              class="c8">&nbsp;Holder covenants and agrees to pay the Primary Transaction Purchase Price to Impostors,
              and
              in any case no later than three business days after the Effective Date.</span></h2>
        </li>
        <li class="c5">
          <h2 style="display:inline"><span class="c11 c10">Form of Payment</span><span class="c10">. </span><span
              class="c8">Impostors agrees to accept payment for the Primary Transaction Purchase Price via $SUPER;
              provided
              that the Impostors may elect to accept other methods or forms of payment on an as-converted to U.S.
              dollars
              basis in its sole discretion. The U.S. dollar exchange rate for any other forms of payment shall be
              determined
              solely by the Impostors or its assignee or agent in accordance with reasonable and accepted market
              practices
              and additional transaction fees may apply.</span></h2>
        </li>
        <li class="c5">
          <h2 style="display:inline"><span class="c11 c10">Gas Fees</span><span class="c10">. If Holder sells,
              purchases, or
              otherwise transfers the Impostors NFT, any financial transactions that Holder engages in will be conducted
              solely through the Ethereum Network, the blockchain network governing the Impostors NFT, and Holder will
              be
              required to make or receive payments exclusively through the cryptocurrency wallet Holder has connected to
              the
              NFT Site. Impostors has no insight into or control over these payments or transactions, nor does Impostors
              have the ability to reverse any transactions. Accordingly, Impostors will have no liability to you or to
              any
              third party for any claims or damages that may arise as a result of any transactions that you engage in
              via
              the NFT Site. &ldquo;</span><span class="c0">Gas Fees</span><span class="c1">&rdquo; fund the network of
              computers that run the decentralized Ethereum network, meaning that Holder will need to pay a Gas Fee for
              each
              transaction that occurs via the Ethereum network. By buying or selling an Impostors NFT on the NFT Site or
              any
              other platform, you agree to pay all applicable fees and you authorize Impostors to automatically deduct
              fees
              (including any transaction fees, or Gas Fees, as applicable) directly from Holder&rsquo;s payments for the
              Primary Transaction or subsequent Secondary Transactions.</span></h2>
        </li>
        <li class="c5">
          <h2 style="display:inline"><span class="c11 c10">Secondary Transactions</span><span class="c10">. All
              Secondary
              Transactions of the Impostors NFTs are subject to the following terms: (a) the Impostors NFT transferee
              (the
              &ldquo;</span><span class="c0">Transferee</span><span class="c10">&rdquo;) shall, by purchasing,
              accepting,
              accessing or otherwise using the Impostors NFT or Impostors Art, be deemed to accept all of the terms of
              this
              Agreement as a &ldquo;Holder&rdquo; hereof; (b) the Impostors NFT transferor (the &ldquo;</span><span
              class="c0">Transferor</span><span class="c10">&rdquo;) shall provide notice to the Transferee of this
              Agreement, including a link or other method by which the terms of this Agreement can be accessible by the
              Transferee; and (c) Impostors collection creator shall be entitled to receive five percent (5%) of the
              gross
              amounts paid by the Transferee (the &ldquo;</span><span class="c0">Royalty Payment</span><span
              class="c1">&rdquo;). The Royalty Payment may be changed, in accordance with Section 15 of this Agreement.
              Notwithstanding the foregoing, additional fees may apply based on the structure of the Secondary
              Transaction
              in question and as determined by Impostors in its sole discretion. Impostors shall be paid the Royalty
              Payment
              on the same terms and at the same time as Transferor is paid by the Transferee. Holder further
              acknowledges
              and agrees that all subsequent transactions of the Impostors NFT will be effected on the blockchain
              network
              governing the Impostors NFT, and Holder will be required to make or receive payments exclusively through
              its
              cryptocurrency wallet. </span></h2>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-2 start" start="1">
        <li class="c25 c31">
          <h3 style="display:inline"><span class="c1">You further acknowledge and agree that all Secondary Transactions
              will
              be effected on the applicable blockchain network (e.g., Ethereum) compatible with the Impostors NFTs, and
              you
              will be required to make or receive payments exclusively through your cryptocurrency wallet. </span></h3>
        </li>
        <li class="c25 c31">
          <h3 style="display:inline"><span class="c1">Without limitation of any other termination rights, Impostors may
              suspend or terminate the license to the Impostors Art if it has a reasonable basis for believing that you
              have
              engaged in an off-chain sale of the Impostors NFT, or otherwise engaged in any off-chain transactions for
              the
              purchase or sale of the Impostors NFT without making the applicable Royalty Payment. You, and all
              subsequent
              Transferees, shall be responsible for paying the Royalty Payment associated with the Secondary Transaction
              purchase price, regardless of where such purchase price is fulfilled on-chain, off-chain, or in a
              combination
              of the foregoing.</span></h3>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-1" start="6">
        <li class="c5">
          <h2 style="display:inline"><span class="c1">Except as expressly provided herein, ownership of an Impostors NFT
              and
              the licenses in Section 3 are not separable in any way. You may not engage in any transaction or activity
              that
              purports to decouple the licenses in Section 3 from your Impostors NFT.</span></h2>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-0" start="6">
        <li class="c7">
          <h1 style="display:inline"><span class="c0">Impostors&rsquo;s Rights and Obligations to the Impostors Art and
              Impostors NFTs</span><span class="c10">. The Parties acknowledge and agree that Impostors is not
              responsible
              for repairing, supporting, replacing, or maintaining the website hosting the Impostors Art or other
              applications or entitlements which the Impostors NFT is compatible with, nor does Impostors have the
              obligation to maintain any connection or link between an Impostors NFT and the corresponding Impostors
              Art.
            </span></h1>
        </li>
        <li class="c7">
          <h1 id="h.3dy6vkm" style="display:inline"><span class="c0">Warranty Disclaimers and Assumption of
              Risk</span><span class="c0 c28">.</span><span class="c10">&nbsp;Holder represents and warrants that it (a)
              is the age of
              majority in Holder&rsquo;s place of residence (which is typically 18 years of age in most U.S. states) and
              has
              the legal capacity to enter into this Agreement, (b) that Holder will use and interact with the Impostors
              NFTs
              and Impostors Art only for lawful purposes and in accordance with this Agreement, and (c) that Holder will
              not
              use the Impostors NFTs or Impostors Art to violate (and Holder will otherwise not violate) any law,
              regulation
              or ordinance or any right of Impostors, its licensors or any third party, including without limitation,
              any
              right of privacy, publicity, copyright, trademark, or patent. </span></h1>
        </li>
      </ol>
      <h1 class="c25"><span class="c10">THE IMPOSTORS NFTS ARE PROVIDED &ldquo;AS IS,&rdquo; WITHOUT WARRANTY OF ANY
          KIND.
          WITHOUT LIMITING THE FOREGOING, IMPOSTORS EXPLICITLY DISCLAIMS ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
          FITNESS
          FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF
          DEALING OR USAGE OF TRADE. IMPOSTORS MAKES NO WARRANTY THAT THE IMPOSTORS NFTS WILL MEET HOLDER&rsquo;S
          REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. IMPOSTORS MAKES NO WARRANTY
          REGARDING THE QUALITY, ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY INFORMATION OR
          CONTENT ON THE IMPOSTORS NFTS.</span></h1>
      <p class="c25"><span class="c1">IMPOSTORS WILL NOT BE RESPONSIBLE OR LIABLE TO HOLDER FOR ANY LOSS AND TAKES NO
          RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF THE IMPOSTORS NFT, INCLUDING BUT NOT LIMITED
          TO
          ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (I) USER ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY
          CONSTRUCTED
          TRANSACTIONS, OR MISTYPED WALLET ADDRESSES; (II) SERVER FAILURE OR DATA LOSS; (III) CORRUPTED CRYPTOCURRENCY
          WALLET FILES; (IV) UNAUTHORIZED ACCESS TO THE IMPOSTORS NFT; OR (V) ANY UNAUTHORIZED THIRD PARTY ACTIVITIES,
          INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST
          BLOCKCHAIN NETWORK UNDERLYING THE IMPOSTORS NFTS. </span></p>
      <p class="c25"><span class="c1">THE IMPOSTORS NFTS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF THE
          OWNERSHIP RECORD MAINTAINED IN THE APPLICABLE BLOCKCHAIN NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN
          ANY
          UNIQUE DIGITAL ASSET OCCURS ON THE DISTRIBUTED LEDGER WITHIN SUCH BLOCKCHAIN NETWORK, WHICH IMPOSTORS DOES NOT
          CONTROL. IMPOSTORS DOES NOT GUARANTEE THAT IMPOSTORS CAN EFFECT THE TRANSFER OF TITLE OR RIGHT IN ANY
          IMPOSTORS
          NFT. HOLDER BEARS FULL RESPONSIBILITY FOR VERIFYING THE IDENTITY, LEGITIMACY, AND AUTHENTICITY OF ASSETS
          HOLDER
          PURCHASES THROUGH THE NFT SITE. NOTWITHSTANDING INDICATORS AND MESSAGES THAT SUGGEST VERIFICATION, IMPOSTORS
          MAKES
          NO CLAIMS ABOUT THE IDENTITY, LEGITIMACY, OR AUTHENTICITY OF ASSETS ON THE NFT SITE OR ANY PURPORTED
          SUBSEQUENT
          TRANSACTIONS. </span></p>
      <p class="c25"><span class="c1">IMPOSTORS IS NOT RESPONSIBLE ANY KIND OF FAILURE, ABNORMAL BEHAVIOR OF SOFTWARE
          (E.G.,
          WALLET, SMART CONTRACT), BLOCKCHAINS OR ANY OTHER FEATURES OF THE IMPOSTORS NFTS, NFT SITE, OR IMPOSTORS GAME.
          IMPOSTORS IS NOT RESPONSIBLE FOR CASUALTIES DUE TO LATE REPORT BY DEVELOPERS OR REPRESENTATIVES (OR NO REPORT
          AT
          ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE IMPOSTORS NFTS, INCLUDING FORKS, TECHNICAL NODE ISSUES
          OR
          ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT. </span></p>
      <p class="c25"><span class="c1">SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS
          WITH
          CONSUMERS, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.</span></p>
      <ol class="c6 lst-kix_list_6-0" start="8">
        <li class="c7">
          <h1 style="display:inline"><span class="c0">Assumption of Risk</span><span class="c1">. Holder accepts and
              acknowledges all risks associated with the following: </span></h1>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_9-0 start" start="1">
        <li class="c14"><span class="c1">The prices and liquidity of cryptocurrency assets (including any NFTs) are
            extremely volatile. Fluctuations in the price of other digital assets could materially and adversely affect
            the
            Impostors NFTs made available, which may also be subject to significant price volatility. We cannot
            guarantee
            that any holders of NFTs will not lose money.</span></li>
        <li class="c14"><span class="c1">Holder is solely responsible for assessing prior commercial uses of the
            Impostors
            Art and determining whether they affect the value or viability of any future use of the Impostors
            Art.</span>
        </li>
        <li class="c14"><span class="c1">Holder is solely responsible for determining what, if any, taxes apply to
            Holder&rsquo;s purchase, sale, or transfer of the Impostors NFTs. Impostors is not responsible for
            determining
            or paying the taxes that apply to such transactions.</span></li>
        <li class="c14"><span class="c1">Impostors does not store, send, or receive cryptocurrency assets. Any transfer
            of
            cryptocurrency assets occurs within the supporting blockchain that is not controlled by Impostors.
            Transactions
            of the Impostors NFTs may be irreversible, and, accordingly, losses due to fraudulent or accidental
            transactions
            may not be recoverable. Some transactions of the Impostors NFTs shall be deemed to be made when recorded on
            a
            public blockchain ledger, which is not necessarily the date or time that Holder initiated the
            transaction.</span></li>
        <li class="c14"><span class="c1">There are risks associated with using an Internet based digital asset,
            including
            but not limited to, the risk of hardware, software and Internet connections, the risk of malicious software
            introduction, and the risk that third parties may obtain unauthorized access to information stored within
            your
            digital wallet. Impostors will not be responsible for any communication failures, disruptions, errors,
            distortions or delays you may experience when effecting Impostors NFT transactions, however caused.</span>
        </li>
        <li class="c14"><span class="c1">A lack of use or public interest in the creation and development of distributed
            ecosystems could negatively impact the development of those ecosystems and related applications, and could
            therefore also negatively impact the potential utility or value of a certain NFT.</span></li>
        <li class="c14"><span class="c1">Digital assets, including blockchain based assets such as the Impostors NFTs,
            are
            subject to developing laws and regulations throughout the world. </span></li>
        <li class="c14"><span class="c1">Transactions involving the Impostors NFTs may rely on third-party platforms to
            perform transactions. If Impostors is unable to maintain a good relationship with such platform providers;
            if
            the terms and conditions or pricing of such platform providers change; if Impostors violates or cannot
            comply
            with the terms and conditions of such platforms; or if any of such platforms loses market share or falls out
            of
            favor or is unavailable for a prolonged period of time, access to and interactions of the Impostors NFTs
            will
            suffer.</span></li>
        <li class="c14"><span class="c1">By participating in the sale and purchase of the Impostors NFTs, you represent
            that
            you understand the inherent risks associated with using cryptographic and blockchain-based systems, and that
            you
            have a working knowledge of digital assets. Such systems may have vulnerabilities or other failures, or
            other
            abnormal behavior. Impostors is not responsible for any issues with the blockchains, including forks,
            technical
            node issues or any other issues having fund losses as a result. You acknowledge that the cost and speed of
            transacting with cryptographic and blockchain-based systems such as Ethereum are variable and may increase
            at
            any time. You further acknowledge the risk that your digital assets may lose some or all of their value
            while
            they are supplied from Impostors. You further acknowledge that we are not responsible for any of these
            variables
            or risks and cannot be held liable for any resulting losses that you experience while purchasing an
            Impostors
            NFT. Accordingly, you understand and agree to assume full responsibility for all of the risks of
            participating
            in the sale and purchase of the Impostors NFT.</span></li>
      </ol>
      <ol class="c6 lst-kix_list_6-0" start="9">
        <li class="c7">
          <h1 id="h.1t3h5sf" style="display:inline"><span class="c0">Links to Third Party Websites or
              Resources.</span><span class="c1">&nbsp;Use and interaction of the Impostors NFTs and Impostors Art may
              allow Holder to access
              third-party websites (including websites that host the Impostors Art) or other resources. Impostors
              provides
              access only as a convenience and is not responsible for the content, products or services on or available
              from
              those resources or links displayed on such websites. Holder acknowledges sole responsibility for and
              assumes
              all risk arising from Holder&rsquo;s use of any third-party resources. Under no circumstances shall
              Holder&rsquo;s inability to view Impostors Art on a third-party website serve as grounds for a claim
              against
              Impostors. </span></h1>
        </li>
        <li class="c7">
          <h1 id="h.4d34og8" style="display:inline"><span class="c0">Termination of License to Art.</span><span
              class="c1">&nbsp;Holder&rsquo;s licenses to the Impostors Art shall automatically terminate, and all
              rights
              shall revert to Impostors if at any time: (a) Holder breaches any portion of this Agreement or (b) Holder
              engages in any unlawful activity related to the Impostors NFT (including transferring the Impostors NFT to
              a
              Prohibited Transferee). Upon any termination, discontinuation or cancellation of Holder&rsquo;s licenses
              to
              the Impostors Art, Impostors may disable Holder&rsquo;s access to the Impostors Art and Holder shall
              delete,
              remove, or otherwise destroy any back up or single digital or physical copy of the Impostors Art. Upon any
              termination, discontinuation or cancellation of the license in this Agreement, the following Sections will
              survive: 2, 4 through 15.</span></h1>
        </li>
        <li class="c7">
          <h1 id="h.2s8eyo1" style="display:inline"><span class="c0">Indemnity. </span><span
              class="c10">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c3">Holder shall defend,
              indemnify, and hold </span><span class="c10">Impostors</span><span class="c3">, its licensors and
              affiliates
              (the &ldquo;</span><span class="c0 c17">Indemnified Parties</span><span class="c3">&rdquo;) harmless from
              and
              against any and all claims, disputes, demands, damages, losses, costs, investigations, liabilities,
              judgments,
              fines, penalties, settlements, interest, and expenses (including attorneys&rsquo; fees) that directly or
              indirectly arise from or are related to any claim, suit, action, demand, or proceeding made or brought by
              a
              third party (including any person who accesses or transacts using the Impostors NFTs whether or not such
              person personally purchased the Impostors NFTs) against the Indemnified Parties, or on account of the
              investigation, defense, or settlement thereof, arising out of or in connection with </span><span
              class="c10">(a) your access to or use of the NFT Site, (b) your breach of this Agreement, and (c) your
              exercise of the licenses in Section 3.</span><span class="c3">&nbsp;</span></h1>
        </li>
        <li class="c7">
          <h1 id="h.17dp8vu" style="display:inline"><span class="c12 c0">Limitation of Liability.</span></h1>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-1 start" start="1">
        <li class="c5">
          <h2 style="display:inline"><span class="c1">TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER IMPOSTORS NOR ITS
              SERVICE PROVIDERS, INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE IMPOSTORS NFTS WILL BE LIABLE FOR
              ANY
              INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST
              SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR
              SYSTEM
              FAILURE OR THE COST OF SUBSTITUTE IMPOSTORS NFTS OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE
              TERMS
              OR ANY IMPOSTORS NFT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY
              OR
              ANY OTHER LEGAL THEORY, AND WHETHER OR NOT IMPOSTORS OR ITS SERVICE PROVIDERS HAS BEEN INFORMED OF THE
              POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS
              ESSENTIAL
              PURPOSE. </span></h2>
        </li>
        <li class="c5">
          <h2 style="display:inline"><span class="c1">TO THE MAXIMUM EXTENT PERMITTED BY THE LAW OF THE APPLICABLE
              JURISDICTION, IN NO EVENT WILL IMPOSTORS&rsquo;S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH
              THESE
              TERMS OR ANY IMPOSTORS NFT OR ACCESS THE IMPOSTORS ART EXCEED $1,000. </span></h2>
        </li>
        <li class="c5">
          <h2 style="display:inline"><span class="c1">THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
              FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN IMPOSTORS AND HOLDER.</span></h2>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-0" start="13">
        <li class="c7">
          <h1 id="h.3rdcrjn" style="display:inline"><span class="c0">Governing Law and Forum Choice.</span><span
              class="c1">&nbsp;This Agreement and any action related thereto will be governed by the laws of the British
              Virgin Islands, without regard to its conflict of laws provisions. Except as otherwise expressly set forth
              in
              Section 14 &ldquo;Dispute Resolution,&rdquo; the exclusive jurisdiction for all Disputes (defined below)
              that
              Holder and Impostors are not required to arbitrate will be the courts located in the British Virgin
              Islands,
              and Holder and Impostors each waive any objection to jurisdiction and venue in such courts.</span></h1>
        </li>
        <li class="c7">
          <h1 id="h.26in1rg" style="display:inline"><span class="c0">Dispute Resolution.</span><span
              class="c1">&nbsp;</span></h1>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-1 start" start="1">
        <li class="c5">
          <h2 id="h.lnxbz9" style="display:inline"><span class="c10 c11">Mandatory Arbitration of Disputes</span><span
              class="c10">. The Parties each agree that any dispute, claim or controversy arising out of or relating to
              these Terms or the breach, termination, enforcement, interpretation or validity thereof of the Impostors
              NFTs
              transaction (collectively, &ldquo;</span><span class="c0">Disputes</span><span class="c10">&rdquo;) will
              be
              resolved </span><span class="c0">solely by binding, individual arbitration and not in a class,
              representative
              or consolidated action or proceeding</span><span class="c10">. You and Impostors agree that the
            </span><span class="c10 c19">BVI Arbitration Act </span><span class="c10">governs the interpretation and
              enforcement of
              this Agreement, and that you and Impostors are each waiving the right to a trial by jury or to participate
              in
              a class action. This arbitration provision shall survive termination of these Terms.</span><span
              class="c10 c19">&nbsp;</span></h2>
        </li>
        <li class="c5">
          <h2 id="h.35nkun2" style="display:inline"><span class="c11 c10">Exceptions</span><span class="c1">. As limited
              exceptions to Section 14(a) above: (i) both Parties may seek to resolve a Dispute in small claims court if
              it
              qualifies; and (ii) both Parties each retain the right to seek injunctive or other equitable relief from a
              court to prevent (or enjoin) the infringement or misappropriation of our intellectual property rights.
            </span>
          </h2>
        </li>
        <li class="c5">
          <h2 style="display:inline"><span class="c11 c10">Conducting Arbitration and Arbitration Rules</span><span
              class="c10">. The arbitration will be conducted by JAMS under its JAMS Comprehensive Arbitration Rules and
              Procedures (the &ldquo;</span><span class="c0">JAMS Rules</span><span class="c1">&rdquo;) then in effect,
              except as modified by these Terms. The JAMS Rules are available at https://www.jamsadr.com/. A Party who
              wishes to start arbitration must submit a written Demand for Arbitration to JAMS and give notice to the
              other
              Party as specified in the JAMS Rules. JAMS provides a form Demand for Arbitration at
              https://www.jamsadr.com/.
            </span></h2>
        </li>
      </ol>
      <p class="c25 c26"><span class="c1">Any arbitration hearings will take place in the county (or parish) where you
          live,
          unless we both agree to a different location but any such arbitration will be conducted remotely to the extent
          permitted by the JAMS Rules. The Parties agree that the arbitrator shall have exclusive authority to decide
          all
          issues relating to the interpretation, applicability, enforceability, and scope of this arbitration
          agreement.</span></p>
      <ol class="c6 lst-kix_list_6-1" start="4">
        <li class="c5">
          <h2 style="display:inline"><span class="c11 c10">Arbitration Costs</span><span class="c1">. Payment of all
              filing,
              administration and arbitrator fees will be governed by the JAMS Rules, and Impostors won&rsquo;t seek to
              recover the administration and arbitrator fees Impostors is responsible for paying, unless the arbitrator
              finds your Dispute frivolous. If Impostors prevails in arbitration Impostors will pay for all of its
              attorneys&rsquo; fees and costs and won&rsquo;t seek to recover them from you. If you prevail in
              arbitration
              you will be entitled to an award of attorneys&rsquo; fees and expenses to the extent provided under
              applicable
              law.</span></h2>
        </li>
        <li class="c5">
          <h2 id="h.1ksv4uv" style="display:inline"><span class="c11 c10">Injunctive and Declaratory Relief</span><span
              class="c1">. Except as provided in Section 14(b) above, the arbitrator shall determine all issues of
              liability
              on the merits of any claim asserted by either Party and may award declaratory or injunctive relief only in
              favor of the individual Party seeking relief and only to the extent necessary to provide relief warranted
              by
              that party&rsquo;s individual claim. To the extent that you or Impostors prevail on a claim and seek
              public
              injunctive relief (that is, injunctive relief that has the primary purpose and effect of prohibiting
              unlawful
              acts that threaten future injury to the public), the entitlement to and extent of such relief must be
              litigated in a civil court of competent jurisdiction and not in arbitration. The Parties agree that
              litigation
              of any issues of public injunctive relief shall be stayed pending the outcome of the merits of any
              individual
              claims in arbitration.</span></h2>
        </li>
        <li class="c5">
          <h2 id="h.44sinio" style="display:inline"><span class="c11 c10">Class Action Waiver</span><span class="c10">.
            </span><span class="c0">YOU AND IMPOSTORS</span><span class="c10">&nbsp;</span><span class="c0">AGREE THAT
              EACH
              MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
              CLASS
              MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.</span><span class="c1">&nbsp;Further, if the
              parties&rsquo; Dispute is resolved through arbitration, the arbitrator may not consolidate another
              person&rsquo;s claims with your claims, and may not otherwise preside over any form of a representative or
              class proceeding. If this specific provision is found to be unenforceable, then the entirety of this
              Dispute
              Resolution section shall be null and void.</span></h2>
        </li>
        <li class="c5">
          <h2 id="h.2jxsxqh" style="display:inline"><span class="c11 c10">Severability</span><span class="c10">. With
              the
              exception of any of the provisions in Section 14(f) of these Terms (&ldquo;</span><span class="c0">Class
              Action Waiver</span><span class="c1">&rdquo;), if an arbitrator or court of competent jurisdiction decides
              that any part of these Terms is invalid or unenforceable, the other parts of these Terms will still
              apply.</span></h2>
        </li>
      </ol>
      <ol class="c6 lst-kix_list_6-0" start="15">
        <li class="c7">
          <h1 id="h.z337ya" style="display:inline"><span class="c0">General Terms. </span><span class="c10">This
              Agreement
              will transfer and be binding upon and will inure to the benefit of the Parties and their permitted
              successors
              and assigns, in particular any Transferee. This Agreement constitutes the entire agreement, and supersedes
              any
              and all prior or contemporaneous representations, understandings and agreements, between the Parties with
              respect to the subject matter of this Agreement, all of which are hereby merged into this Agreement.
              Without
              limitation, the terms of any other document, course of dealing, or course of trade will not modify this
              Agreement, except as expressly provided in this Agreement or as the Parties may agree in writing. Super
              Studios reserves the right to clarify or amend this Agreement by publicly publishing a new version of the
              Agreement, including, but not limited to the NFT Site, or any successor website. Failure to promptly
              enforce a
              provision of this Agreement will not be construed as a waiver of such provision. Nothing contained in this
              Agreement will be deemed to create, or be construed as creating, a joint venture or partnership between
              the
              parties. Neither Party is, by virtue of this Agreement or otherwise, authorized as an agent or legal
              representative of the other Party. Neither Party to this Agreement is granted any right or authority to
              assume
              or to create any obligation or responsibility, express or implied, on behalf or in the name of the other
              Party, or to bind such other Party in any manner. Nothing contained in this Agreement will be deemed to
              create
              any third-party beneficiary right upon any third party whatsoever. Each of the Parties acknowledges that
              it
              has had the opportunity to have this Agreement reviewed or not by independent legal counsel of its choice.
              If
              any one or more of the provisions of this Agreement should be ruled wholly or partly invalid or
              unenforceable,
              then the provisions held invalid or unenforceable will be deemed amended, and the court or other
              government
              body is authorized to reform the provision(s) to the minimum extent necessary to render them valid and
              enforceable in conformity with the Parties&rsquo; intent as manifested herein. The headings to Sections of
              this Agreement are for convenience or reference only and do not form a part of this Agreement and will not
              in
              any way affect its interpretation. Neither Party will be afforded or denied preference in the construction
              of
              this Agreement, whether by virtue of being the drafter or otherwise. For purposes of this Agreement, the
              words
              and phrases &ldquo;include,&rdquo; &ldquo;includes&rdquo;, &ldquo;including&rdquo; and &ldquo;such
              as&rdquo;
              are deemed to be followed by the words &ldquo;without limitation&rdquo;. Holder may give notice to
              Impostors
              by contacting Impostors at </span><span class="c11 c10 c27"><a class="c18"
                href="mailto:support@superfarm.io">support@superfarm.io</a></span><span class="c10">. Notice is
              effective
              upon receipt. </span></h1>
        </li>
      </ol>
    </div>
  </div>
</template>

<style scoped lang="scss">
.privacy-container {
  padding: 65px;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: top;
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  width: 100%;

  @media (max-width: 650px) {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.privacy-text-title {
  margin-bottom: 20px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 43px;
  width: 100%;
  max-width: 1400px;
  /* identical to box height */
}

.privacy-text-container {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.8em;
  max-width: 1400px;
  width: 100%;

  @media (max-width: 650px) {
    font-size: 14px;

    & * {
      text-align: left !important;
    }
  }
}

a {
  color: var(--color-button-yellow);
}

ol.lst-kix_list_7-0 {
  list-style-type: none
}

.lst-kix_list_14-1>li:before {
  content: "(" counter(lst-ctn-kix_list_14-1, lower-latin) ") "
}

.lst-kix_list_14-3>li:before {
  content: "(" counter(lst-ctn-kix_list_14-3, decimal) ") "
}

ol.lst-kix_list_15-6 {
  list-style-type: none
}

ol.lst-kix_list_15-7 {
  list-style-type: none
}

.lst-kix_list_21-8>li {
  counter-increment: lst-ctn-kix_list_21-8
}

ol.lst-kix_list_15-8 {
  list-style-type: none
}

ol.lst-kix_list_9-0.start {
  counter-reset: lst-ctn-kix_list_9-0 0
}

.lst-kix_list_14-0>li:before {
  content: "" counter(lst-ctn-kix_list_14-0, decimal) ". "
}

.lst-kix_list_14-4>li:before {
  content: "" counter(lst-ctn-kix_list_14-4, lower-roman) ". "
}

ol.lst-kix_list_15-2 {
  list-style-type: none
}

ol.lst-kix_list_15-3 {
  list-style-type: none
}

ol.lst-kix_list_15-4 {
  list-style-type: none
}

ol.lst-kix_list_18-5.start {
  counter-reset: lst-ctn-kix_list_18-5 0
}

.lst-kix_list_6-1>li {
  counter-increment: lst-ctn-kix_list_6-1
}

.lst-kix_list_14-5>li:before {
  content: "" counter(lst-ctn-kix_list_14-5, lower-roman) ". "
}

.lst-kix_list_14-7>li:before {
  content: "" counter(lst-ctn-kix_list_14-7, lower-latin) ") "
}

ol.lst-kix_list_15-5 {
  list-style-type: none
}

ol.lst-kix_list_15-0 {
  list-style-type: none
}

.lst-kix_list_14-6>li:before {
  content: "" counter(lst-ctn-kix_list_14-6, decimal) ") "
}

ol.lst-kix_list_15-1 {
  list-style-type: none
}

ol.lst-kix_list_7-4.start {
  counter-reset: lst-ctn-kix_list_7-4 0
}

ol.lst-kix_list_20-2.start {
  counter-reset: lst-ctn-kix_list_20-2 0
}

.lst-kix_list_17-0>li {
  counter-increment: lst-ctn-kix_list_17-0
}

.lst-kix_list_5-0>li {
  counter-increment: lst-ctn-kix_list_5-0
}

.lst-kix_list_9-0>li {
  counter-increment: lst-ctn-kix_list_9-0
}

ol.lst-kix_list_7-5 {
  list-style-type: none
}

ol.lst-kix_list_7-6 {
  list-style-type: none
}

ol.lst-kix_list_7-7 {
  list-style-type: none
}

ol.lst-kix_list_7-8 {
  list-style-type: none
}

ol.lst-kix_list_7-1 {
  list-style-type: none
}

ol.lst-kix_list_7-2 {
  list-style-type: none
}

ol.lst-kix_list_11-8.start {
  counter-reset: lst-ctn-kix_list_11-8 0
}

ol.lst-kix_list_7-3 {
  list-style-type: none
}

ol.lst-kix_list_7-4 {
  list-style-type: none
}

.lst-kix_list_14-2>li:before {
  content: "(" counter(lst-ctn-kix_list_14-2, lower-roman) ") "
}

.lst-kix_list_20-7>li {
  counter-increment: lst-ctn-kix_list_20-7
}

ol.lst-kix_list_5-3.start {
  counter-reset: lst-ctn-kix_list_5-3 0
}

ol.lst-kix_list_12-0.start {
  counter-reset: lst-ctn-kix_list_12-0 0
}

ol.lst-kix_list_17-1.start {
  counter-reset: lst-ctn-kix_list_17-1 0
}

ol.lst-kix_list_21-6.start {
  counter-reset: lst-ctn-kix_list_21-6 0
}

.lst-kix_list_14-8>li:before {
  content: "" counter(lst-ctn-kix_list_14-8, lower-roman) ") "
}

.lst-kix_list_18-8>li {
  counter-increment: lst-ctn-kix_list_18-8
}

ol.lst-kix_list_8-8.start {
  counter-reset: lst-ctn-kix_list_8-8 0
}

ol.lst-kix_list_15-5.start {
  counter-reset: lst-ctn-kix_list_15-5 0
}

ol.lst-kix_list_10-4.start {
  counter-reset: lst-ctn-kix_list_10-4 5
}

.lst-kix_list_7-2>li {
  counter-increment: lst-ctn-kix_list_7-2
}

.lst-kix_list_5-0>li:before {
  content: "" counter(lst-ctn-kix_list_5-0, decimal) ". "
}

ol.lst-kix_list_6-0 {
  list-style-type: none
}

ol.lst-kix_list_6-1 {
  list-style-type: none
}

.lst-kix_list_14-8>li {
  counter-increment: lst-ctn-kix_list_14-8
}

.lst-kix_list_5-4>li {
  counter-increment: lst-ctn-kix_list_5-4
}

ol.lst-kix_list_16-5 {
  list-style-type: none
}

ol.lst-kix_list_16-6 {
  list-style-type: none
}

ol.lst-kix_list_16-7 {
  list-style-type: none
}

ol.lst-kix_list_16-8 {
  list-style-type: none
}

.lst-kix_list_5-3>li:before {
  content: "(" counter(lst-ctn-kix_list_5-3, lower-roman) ") "
}

ol.lst-kix_list_9-5.start {
  counter-reset: lst-ctn-kix_list_9-5 0
}

ol.lst-kix_list_16-1 {
  list-style-type: none
}

ol.lst-kix_list_16-2 {
  list-style-type: none
}

.lst-kix_list_5-2>li:before {
  content: "(" counter(lst-ctn-kix_list_5-2, lower-latin) ") "
}

ol.lst-kix_list_16-3 {
  list-style-type: none
}

.lst-kix_list_8-3>li {
  counter-increment: lst-ctn-kix_list_8-3
}

ol.lst-kix_list_16-4 {
  list-style-type: none
}

.lst-kix_list_5-1>li:before {
  content: "" counter(lst-ctn-kix_list_5-0, decimal) "." counter(lst-ctn-kix_list_5-1, decimal) " "
}

ol.lst-kix_list_18-0.start {
  counter-reset: lst-ctn-kix_list_18-0 0
}

ol.lst-kix_list_16-0 {
  list-style-type: none
}

.lst-kix_list_5-7>li:before {
  content: "" counter(lst-ctn-kix_list_5-7, decimal) " "
}

ol.lst-kix_list_20-7.start {
  counter-reset: lst-ctn-kix_list_20-7 0
}

.lst-kix_list_5-6>li:before {
  content: "" counter(lst-ctn-kix_list_5-6, decimal) " "
}

.lst-kix_list_5-8>li:before {
  content: " "
}

.lst-kix_list_9-4>li {
  counter-increment: lst-ctn-kix_list_9-4
}

ol.lst-kix_list_6-6 {
  list-style-type: none
}

ol.lst-kix_list_6-7 {
  list-style-type: none
}

.lst-kix_list_5-4>li:before {
  content: "(" counter(lst-ctn-kix_list_5-4, upper-latin) ") "
}

ol.lst-kix_list_6-8 {
  list-style-type: none
}

.lst-kix_list_5-5>li:before {
  content: " "
}

ol.lst-kix_list_6-2 {
  list-style-type: none
}

ol.lst-kix_list_6-3 {
  list-style-type: none
}

ol.lst-kix_list_6-4 {
  list-style-type: none
}

ol.lst-kix_list_16-4.start {
  counter-reset: lst-ctn-kix_list_16-4 0
}

ol.lst-kix_list_6-5 {
  list-style-type: none
}

ol.lst-kix_list_14-1.start {
  counter-reset: lst-ctn-kix_list_14-1 0
}

ol.lst-kix_list_12-5.start {
  counter-reset: lst-ctn-kix_list_12-5 0
}

.lst-kix_list_6-1>li:before {
  content: "(" counter(lst-ctn-kix_list_6-1, lower-latin) ") "
}

.lst-kix_list_6-3>li:before {
  content: "(" counter(lst-ctn-kix_list_6-3, decimal) ") "
}

.lst-kix_list_6-5>li {
  counter-increment: lst-ctn-kix_list_6-5
}

.lst-kix_list_6-8>li {
  counter-increment: lst-ctn-kix_list_6-8
}

.lst-kix_list_6-0>li:before {
  content: "" counter(lst-ctn-kix_list_6-0, decimal) ". "
}

.lst-kix_list_6-4>li:before {
  content: "" counter(lst-ctn-kix_list_6-4, lower-roman) ". "
}

ol.lst-kix_list_14-8.start {
  counter-reset: lst-ctn-kix_list_14-8 0
}

.lst-kix_list_6-2>li:before {
  content: "(" counter(lst-ctn-kix_list_6-2, lower-roman) ") "
}

ol.lst-kix_list_15-0.start {
  counter-reset: lst-ctn-kix_list_15-0 0
}

.lst-kix_list_6-8>li:before {
  content: "" counter(lst-ctn-kix_list_6-8, lower-roman) ") "
}

.lst-kix_list_6-5>li:before {
  content: "" counter(lst-ctn-kix_list_6-5, lower-roman) ". "
}

.lst-kix_list_6-7>li:before {
  content: "" counter(lst-ctn-kix_list_6-7, lower-latin) ") "
}

.lst-kix_list_6-6>li:before {
  content: "" counter(lst-ctn-kix_list_6-6, decimal) ") "
}

ol.lst-kix_list_17-8 {
  list-style-type: none
}

ol.lst-kix_list_10-6.start {
  counter-reset: lst-ctn-kix_list_10-6 0
}

.lst-kix_list_7-4>li:before {
  content: "(" counter(lst-ctn-kix_list_7-4, lower-roman) ") "
}

.lst-kix_list_7-6>li:before {
  content: "" counter(lst-ctn-kix_list_7-6, lower-latin) ". "
}

ol.lst-kix_list_17-4 {
  list-style-type: none
}

.lst-kix_list_18-5>li {
  counter-increment: lst-ctn-kix_list_18-5
}

ol.lst-kix_list_19-7.start {
  counter-reset: lst-ctn-kix_list_19-7 0
}

ol.lst-kix_list_17-5 {
  list-style-type: none
}

ol.lst-kix_list_6-2.start {
  counter-reset: lst-ctn-kix_list_6-2 0
}

.lst-kix_list_15-5>li {
  counter-increment: lst-ctn-kix_list_15-5
}

ol.lst-kix_list_17-6 {
  list-style-type: none
}

ol.lst-kix_list_17-7 {
  list-style-type: none
}

ol.lst-kix_list_17-0 {
  list-style-type: none
}

ol.lst-kix_list_17-1 {
  list-style-type: none
}

ol.lst-kix_list_17-2 {
  list-style-type: none
}

.lst-kix_list_7-2>li:before {
  content: "" counter(lst-ctn-kix_list_7-2, lower-roman) ". "
}

ol.lst-kix_list_17-3 {
  list-style-type: none
}

.lst-kix_list_7-6>li {
  counter-increment: lst-ctn-kix_list_7-6
}

.lst-kix_list_8-6>li {
  counter-increment: lst-ctn-kix_list_8-6
}

.lst-kix_list_12-6>li {
  counter-increment: lst-ctn-kix_list_12-6
}

ol.lst-kix_list_9-7 {
  list-style-type: none
}

ol.lst-kix_list_9-8 {
  list-style-type: none
}

.lst-kix_list_13-7>li:before {
  content: "o  "
}

ol.lst-kix_list_9-3 {
  list-style-type: none
}

ol.lst-kix_list_9-4 {
  list-style-type: none
}

.lst-kix_list_5-7>li {
  counter-increment: lst-ctn-kix_list_5-7
}

ol.lst-kix_list_9-5 {
  list-style-type: none
}

.lst-kix_list_7-8>li:before {
  content: "" counter(lst-ctn-kix_list_7-8, decimal) ". "
}

ol.lst-kix_list_9-6 {
  list-style-type: none
}

.lst-kix_list_15-6>li {
  counter-increment: lst-ctn-kix_list_15-6
}

ol.lst-kix_list_9-0 {
  list-style-type: none
}

ol.lst-kix_list_9-1 {
  list-style-type: none
}

ol.lst-kix_list_9-2 {
  list-style-type: none
}

.lst-kix_list_15-5>li:before {
  content: "" counter(lst-ctn-kix_list_15-5, lower-roman) ". "
}

.lst-kix_list_9-8>li {
  counter-increment: lst-ctn-kix_list_9-8
}

.lst-kix_list_4-1>li:before {
  content: "o  "
}

.lst-kix_list_15-7>li:before {
  content: "" counter(lst-ctn-kix_list_15-7, lower-latin) ") "
}

.lst-kix_list_17-7>li {
  counter-increment: lst-ctn-kix_list_17-7
}

.lst-kix_list_4-3>li:before {
  content: "\0025cf  "
}

.lst-kix_list_4-5>li:before {
  content: "\0025aa  "
}

.lst-kix_list_10-5>li {
  counter-increment: lst-ctn-kix_list_10-5
}

.lst-kix_list_15-1>li:before {
  content: "(" counter(lst-ctn-kix_list_15-1, lower-latin) ") "
}

ol.lst-kix_list_18-3.start {
  counter-reset: lst-ctn-kix_list_18-3 0
}

.lst-kix_list_15-3>li:before {
  content: "(" counter(lst-ctn-kix_list_15-3, decimal) ") "
}

ol.lst-kix_list_18-7 {
  list-style-type: none
}

ol.lst-kix_list_18-8 {
  list-style-type: none
}

.lst-kix_list_16-2>li {
  counter-increment: lst-ctn-kix_list_16-2
}

ol.lst-kix_list_18-3 {
  list-style-type: none
}

ol.lst-kix_list_18-4 {
  list-style-type: none
}

.lst-kix_list_6-4>li {
  counter-increment: lst-ctn-kix_list_6-4
}

ol.lst-kix_list_18-5 {
  list-style-type: none
}

ol.lst-kix_list_18-6 {
  list-style-type: none
}

ol.lst-kix_list_9-2.start {
  counter-reset: lst-ctn-kix_list_9-2 0
}

.lst-kix_list_20-0>li {
  counter-increment: lst-ctn-kix_list_20-0
}

ol.lst-kix_list_16-7.start {
  counter-reset: lst-ctn-kix_list_16-7 0
}

ol.lst-kix_list_18-0 {
  list-style-type: none
}

ol.lst-kix_list_18-1 {
  list-style-type: none
}

.lst-kix_list_9-3>li {
  counter-increment: lst-ctn-kix_list_9-3
}

ol.lst-kix_list_18-2 {
  list-style-type: none
}

.lst-kix_list_11-2>li {
  counter-increment: lst-ctn-kix_list_11-2
}

ol.lst-kix_list_15-2.start {
  counter-reset: lst-ctn-kix_list_15-2 0
}

.lst-kix_list_19-2>li {
  counter-increment: lst-ctn-kix_list_19-2
}

ol.lst-kix_list_8-8 {
  list-style-type: none
}

.lst-kix_list_12-3>li:before {
  content: "" counter(lst-ctn-kix_list_12-3, decimal) ". "
}

ol.lst-kix_list_7-6.start {
  counter-reset: lst-ctn-kix_list_7-6 0
}

ol.lst-kix_list_8-4 {
  list-style-type: none
}

.lst-kix_list_12-1>li:before {
  content: "" counter(lst-ctn-kix_list_12-1, lower-latin) ". "
}

ol.lst-kix_list_8-5 {
  list-style-type: none
}

ol.lst-kix_list_8-6 {
  list-style-type: none
}

ol.lst-kix_list_8-7 {
  list-style-type: none
}

ol.lst-kix_list_8-0 {
  list-style-type: none
}

.lst-kix_list_16-3>li {
  counter-increment: lst-ctn-kix_list_16-3
}

ol.lst-kix_list_8-1 {
  list-style-type: none
}

ol.lst-kix_list_8-2 {
  list-style-type: none
}

ol.lst-kix_list_8-3 {
  list-style-type: none
}

.lst-kix_list_10-4>li {
  counter-increment: lst-ctn-kix_list_10-4
}

ol.lst-kix_list_15-3.start {
  counter-reset: lst-ctn-kix_list_15-3 0
}

.lst-kix_list_14-1>li {
  counter-increment: lst-ctn-kix_list_14-1
}

.lst-kix_list_21-4>li {
  counter-increment: lst-ctn-kix_list_21-4
}

.lst-kix_list_13-3>li:before {
  content: "\0025cf  "
}

ol.lst-kix_list_5-8.start {
  counter-reset: lst-ctn-kix_list_5-8 0
}

.lst-kix_list_13-5>li:before {
  content: "\0025aa  "
}

.lst-kix_list_12-5>li:before {
  content: "" counter(lst-ctn-kix_list_12-5, lower-roman) ". "
}

.lst-kix_list_18-4>li {
  counter-increment: lst-ctn-kix_list_18-4
}

ol.lst-kix_list_12-2.start {
  counter-reset: lst-ctn-kix_list_12-2 0
}

.lst-kix_list_12-7>li:before {
  content: "" counter(lst-ctn-kix_list_12-7, lower-latin) ". "
}

ol.lst-kix_list_6-0.start {
  counter-reset: lst-ctn-kix_list_6-0 0
}

ol.lst-kix_list_21-1.start {
  counter-reset: lst-ctn-kix_list_21-1 0
}

.lst-kix_list_13-1>li:before {
  content: "o  "
}

.lst-kix_list_5-1>li {
  counter-increment: lst-ctn-kix_list_5-1
}

ol.lst-kix_list_19-0.start {
  counter-reset: lst-ctn-kix_list_19-0 0
}

ol.lst-kix_list_21-3.start {
  counter-reset: lst-ctn-kix_list_21-3 0
}

ol.lst-kix_list_11-6 {
  list-style-type: none
}

ol.lst-kix_list_11-7 {
  list-style-type: none
}

ol.lst-kix_list_11-8 {
  list-style-type: none
}

.lst-kix_list_7-1>li {
  counter-increment: lst-ctn-kix_list_7-1
}

ol.lst-kix_list_11-2 {
  list-style-type: none
}

ol.lst-kix_list_11-3 {
  list-style-type: none
}

.lst-kix_list_3-0>li:before {
  content: "\0025cf  "
}

ol.lst-kix_list_11-4 {
  list-style-type: none
}

ol.lst-kix_list_11-5 {
  list-style-type: none
}

ol.lst-kix_list_20-5.start {
  counter-reset: lst-ctn-kix_list_20-5 0
}

ol.lst-kix_list_11-0 {
  list-style-type: none
}

ol.lst-kix_list_11-1 {
  list-style-type: none
}

.lst-kix_list_21-8>li:before {
  content: "" counter(lst-ctn-kix_list_21-8, lower-roman) ". "
}

.lst-kix_list_16-0>li {
  counter-increment: lst-ctn-kix_list_16-0
}

.lst-kix_list_8-0>li {
  counter-increment: lst-ctn-kix_list_8-0
}

.lst-kix_list_10-0>li {
  counter-increment: lst-ctn-kix_list_10-0
}

.lst-kix_list_3-4>li:before {
  content: "o  "
}

ol.lst-kix_list_18-2.start {
  counter-reset: lst-ctn-kix_list_18-2 0
}

.lst-kix_list_3-3>li:before {
  content: "\0025cf  "
}

.lst-kix_list_8-0>li:before {
  content: "" counter(lst-ctn-kix_list_8-0, decimal) ") "
}

.lst-kix_list_8-7>li:before {
  content: "" counter(lst-ctn-kix_list_8-7, lower-latin) ". "
}

.lst-kix_list_3-8>li:before {
  content: "\0025aa  "
}

.lst-kix_list_21-0>li:before {
  content: "(" counter(lst-ctn-kix_list_21-0, decimal) ") "
}

ol.lst-kix_list_10-7.start {
  counter-reset: lst-ctn-kix_list_10-7 0
}

.lst-kix_list_21-1>li:before {
  content: "" counter(lst-ctn-kix_list_21-1, lower-latin) ". "
}

ol.lst-kix_list_15-8.start {
  counter-reset: lst-ctn-kix_list_15-8 0
}

.lst-kix_list_8-3>li:before {
  content: "(" counter(lst-ctn-kix_list_8-3, decimal) ") "
}

ul.lst-kix_list_13-5 {
  list-style-type: none
}

ul.lst-kix_list_13-4 {
  list-style-type: none
}

ul.lst-kix_list_13-3 {
  list-style-type: none
}

ul.lst-kix_list_13-2 {
  list-style-type: none
}

ul.lst-kix_list_13-1 {
  list-style-type: none
}

.lst-kix_list_3-7>li:before {
  content: "o  "
}

ul.lst-kix_list_13-0 {
  list-style-type: none
}

.lst-kix_list_8-4>li:before {
  content: "(" counter(lst-ctn-kix_list_8-4, lower-latin) ") "
}

.lst-kix_list_19-1>li {
  counter-increment: lst-ctn-kix_list_19-1
}

.lst-kix_list_10-2>li {
  counter-increment: lst-ctn-kix_list_10-2
}

ol.lst-kix_list_8-5.start {
  counter-reset: lst-ctn-kix_list_8-5 0
}

ul.lst-kix_list_13-8 {
  list-style-type: none
}

.lst-kix_list_17-1>li {
  counter-increment: lst-ctn-kix_list_17-1
}

.lst-kix_list_11-1>li:before {
  content: "" counter(lst-ctn-kix_list_11-1, lower-latin) ". "
}

ul.lst-kix_list_13-7 {
  list-style-type: none
}

ul.lst-kix_list_13-6 {
  list-style-type: none
}

.lst-kix_list_21-5>li:before {
  content: "" counter(lst-ctn-kix_list_21-5, lower-roman) ". "
}

.lst-kix_list_21-4>li:before {
  content: "" counter(lst-ctn-kix_list_21-4, lower-latin) ". "
}

.lst-kix_list_11-0>li:before {
  content: "" counter(lst-ctn-kix_list_11-0, decimal) ". "
}

ol.lst-kix_list_9-3.start {
  counter-reset: lst-ctn-kix_list_9-3 0
}

.lst-kix_list_8-8>li:before {
  content: "" counter(lst-ctn-kix_list_8-8, lower-roman) ". "
}

.lst-kix_list_16-8>li:before {
  content: "" counter(lst-ctn-kix_list_16-8, lower-roman) ") "
}

.lst-kix_list_16-7>li:before {
  content: "" counter(lst-ctn-kix_list_16-7, lower-latin) ") "
}

ol.lst-kix_list_7-2.start {
  counter-reset: lst-ctn-kix_list_7-2 0
}

.lst-kix_list_17-8>li {
  counter-increment: lst-ctn-kix_list_17-8
}

ol.lst-kix_list_2-0 {
  list-style-type: none
}

ol.lst-kix_list_19-5.start {
  counter-reset: lst-ctn-kix_list_19-5 0
}

.lst-kix_list_4-8>li:before {
  content: "\0025aa  "
}

ol.lst-kix_list_12-5 {
  list-style-type: none
}

ol.lst-kix_list_12-6 {
  list-style-type: none
}

.lst-kix_list_21-5>li {
  counter-increment: lst-ctn-kix_list_21-5
}

.lst-kix_list_4-7>li:before {
  content: "o  "
}

ol.lst-kix_list_12-7 {
  list-style-type: none
}

.lst-kix_list_14-2>li {
  counter-increment: lst-ctn-kix_list_14-2
}

ol.lst-kix_list_20-0.start {
  counter-reset: lst-ctn-kix_list_20-0 0
}

.lst-kix_list_17-0>li:before {
  content: "" counter(lst-ctn-kix_list_17-0, decimal) ". "
}

ol.lst-kix_list_12-8 {
  list-style-type: none
}

ol.lst-kix_list_12-1 {
  list-style-type: none
}

ol.lst-kix_list_12-2 {
  list-style-type: none
}

ol.lst-kix_list_12-3 {
  list-style-type: none
}

ol.lst-kix_list_12-4 {
  list-style-type: none
}

ul.lst-kix_list_4-8 {
  list-style-type: none
}

.lst-kix_list_16-0>li:before {
  content: "" counter(lst-ctn-kix_list_16-0, decimal) ". "
}

ul.lst-kix_list_4-6 {
  list-style-type: none
}

ul.lst-kix_list_4-7 {
  list-style-type: none
}

ol.lst-kix_list_12-0 {
  list-style-type: none
}

.lst-kix_list_21-0>li {
  counter-increment: lst-ctn-kix_list_21-0
}

.lst-kix_list_8-4>li {
  counter-increment: lst-ctn-kix_list_8-4
}

ul.lst-kix_list_4-0 {
  list-style-type: none
}

.lst-kix_list_16-4>li:before {
  content: "" counter(lst-ctn-kix_list_16-4, lower-roman) ". "
}

ul.lst-kix_list_4-1 {
  list-style-type: none
}

ol.lst-kix_list_10-8.start {
  counter-reset: lst-ctn-kix_list_10-8 0
}

.lst-kix_list_16-3>li:before {
  content: "(" counter(lst-ctn-kix_list_16-3, decimal) ") "
}

ul.lst-kix_list_4-4 {
  list-style-type: none
}

ul.lst-kix_list_4-5 {
  list-style-type: none
}

ul.lst-kix_list_4-2 {
  list-style-type: none
}

ul.lst-kix_list_4-3 {
  list-style-type: none
}

.lst-kix_list_11-3>li {
  counter-increment: lst-ctn-kix_list_11-3
}

ol.lst-kix_list_18-1.start {
  counter-reset: lst-ctn-kix_list_18-1 0
}

ol.lst-kix_list_7-1.start {
  counter-reset: lst-ctn-kix_list_7-1 0
}

ol.lst-kix_list_8-6.start {
  counter-reset: lst-ctn-kix_list_8-6 0
}

.lst-kix_list_17-7>li:before {
  content: "" counter(lst-ctn-kix_list_17-7, lower-latin) ") "
}

.lst-kix_list_16-7>li {
  counter-increment: lst-ctn-kix_list_16-7
}

ol.lst-kix_list_21-4.start {
  counter-reset: lst-ctn-kix_list_21-4 0
}

.lst-kix_list_17-8>li:before {
  content: "" counter(lst-ctn-kix_list_17-8, lower-roman) ") "
}

.lst-kix_list_17-3>li:before {
  content: "(" counter(lst-ctn-kix_list_17-3, decimal) ") "
}

.lst-kix_list_17-4>li:before {
  content: "" counter(lst-ctn-kix_list_17-4, lower-roman) ". "
}

ol.lst-kix_list_20-6.start {
  counter-reset: lst-ctn-kix_list_20-6 0
}

ol.lst-kix_list_8-0.start {
  counter-reset: lst-ctn-kix_list_8-0 0
}

.lst-kix_list_7-0>li:before {
  content: "" counter(lst-ctn-kix_list_7-0, decimal) ". "
}

ol.lst-kix_list_19-6.start {
  counter-reset: lst-ctn-kix_list_19-6 0
}

.lst-kix_list_16-5>li {
  counter-increment: lst-ctn-kix_list_16-5
}

ol.lst-kix_list_5-0 {
  list-style-type: none
}

ol.lst-kix_list_5-1 {
  list-style-type: none
}

ol.lst-kix_list_9-7.start {
  counter-reset: lst-ctn-kix_list_9-7 0
}

ol.lst-kix_list_5-2 {
  list-style-type: none
}

.lst-kix_list_2-4>li:before {
  content: " "
}

.lst-kix_list_2-8>li:before {
  content: " "
}

.lst-kix_list_21-2>li {
  counter-increment: lst-ctn-kix_list_21-2
}

.lst-kix_list_20-2>li {
  counter-increment: lst-ctn-kix_list_20-2
}

.lst-kix_list_6-6>li {
  counter-increment: lst-ctn-kix_list_6-6
}

ol.lst-kix_list_15-7.start {
  counter-reset: lst-ctn-kix_list_15-7 0
}

.lst-kix_list_7-3>li:before {
  content: "(" counter(lst-ctn-kix_list_7-3, lower-latin) ") "
}

.lst-kix_list_10-0>li:before {
  content: "" counter(lst-ctn-kix_list_10-0, decimal) ") "
}

.lst-kix_list_9-7>li {
  counter-increment: lst-ctn-kix_list_9-7
}

ol.lst-kix_list_21-7.start {
  counter-reset: lst-ctn-kix_list_21-7 0
}

.lst-kix_list_13-8>li:before {
  content: "\0025aa  "
}

ol.lst-kix_list_14-6.start {
  counter-reset: lst-ctn-kix_list_14-6 0
}

.lst-kix_list_18-3>li:before {
  content: "" counter(lst-ctn-kix_list_18-3, decimal) ". "
}

.lst-kix_list_18-7>li:before {
  content: "" counter(lst-ctn-kix_list_18-7, lower-latin) ". "
}

ol.lst-kix_list_5-7 {
  list-style-type: none
}

ol.lst-kix_list_5-8 {
  list-style-type: none
}

.lst-kix_list_18-6>li {
  counter-increment: lst-ctn-kix_list_18-6
}

ol.lst-kix_list_5-3 {
  list-style-type: none
}

.lst-kix_list_8-7>li {
  counter-increment: lst-ctn-kix_list_8-7
}

.lst-kix_list_19-6>li {
  counter-increment: lst-ctn-kix_list_19-6
}

ol.lst-kix_list_5-4 {
  list-style-type: none
}

ol.lst-kix_list_5-5 {
  list-style-type: none
}

ol.lst-kix_list_5-6 {
  list-style-type: none
}

.lst-kix_list_7-7>li:before {
  content: "" counter(lst-ctn-kix_list_7-7, lower-roman) ". "
}

ol.lst-kix_list_8-1.start {
  counter-reset: lst-ctn-kix_list_8-1 0
}

.lst-kix_list_20-4>li {
  counter-increment: lst-ctn-kix_list_20-4
}

.lst-kix_list_15-4>li:before {
  content: "" counter(lst-ctn-kix_list_15-4, lower-roman) ". "
}

.lst-kix_list_9-5>li {
  counter-increment: lst-ctn-kix_list_9-5
}

ol.lst-kix_list_19-1.start {
  counter-reset: lst-ctn-kix_list_19-1 0
}

.lst-kix_list_5-8>li {
  counter-increment: lst-ctn-kix_list_5-8
}

.lst-kix_list_10-4>li:before {
  content: "(" counter(lst-ctn-kix_list_10-4, lower-latin) ") "
}

.lst-kix_list_10-8>li:before {
  content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ". "
}

ol.lst-kix_list_20-4.start {
  counter-reset: lst-ctn-kix_list_20-4 0
}

.lst-kix_list_4-0>li:before {
  content: "\0025cf  "
}

.lst-kix_list_15-0>li:before {
  content: "" counter(lst-ctn-kix_list_15-0, decimal) ". "
}

.lst-kix_list_15-8>li:before {
  content: "" counter(lst-ctn-kix_list_15-8, lower-roman) ") "
}

ol.lst-kix_list_19-4.start {
  counter-reset: lst-ctn-kix_list_19-4 0
}

ol.lst-kix_list_14-3.start {
  counter-reset: lst-ctn-kix_list_14-3 0
}

.lst-kix_list_15-7>li {
  counter-increment: lst-ctn-kix_list_15-7
}

.lst-kix_list_4-4>li:before {
  content: "o  "
}

ol.lst-kix_list_20-1.start {
  counter-reset: lst-ctn-kix_list_20-1 0
}

.lst-kix_list_19-4>li {
  counter-increment: lst-ctn-kix_list_19-4
}

.lst-kix_list_9-3>li:before {
  content: "" counter(lst-ctn-kix_list_9-3, decimal) ". "
}

ol.lst-kix_list_7-0.start {
  counter-reset: lst-ctn-kix_list_7-0 1
}

.lst-kix_list_12-8>li {
  counter-increment: lst-ctn-kix_list_12-8
}

ol.lst-kix_list_14-7 {
  list-style-type: none
}

ol.lst-kix_list_14-4.start {
  counter-reset: lst-ctn-kix_list_14-4 0
}

ol.lst-kix_list_14-8 {
  list-style-type: none
}

.lst-kix_list_9-7>li:before {
  content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ". "
}

ol.lst-kix_list_14-3 {
  list-style-type: none
}

ol.lst-kix_list_21-8.start {
  counter-reset: lst-ctn-kix_list_21-8 0
}

ol.lst-kix_list_14-4 {
  list-style-type: none
}

ol.lst-kix_list_14-5 {
  list-style-type: none
}

ol.lst-kix_list_14-6 {
  list-style-type: none
}

.lst-kix_list_11-4>li:before {
  content: "" counter(lst-ctn-kix_list_11-4, lower-latin) ". "
}

.lst-kix_list_15-2>li {
  counter-increment: lst-ctn-kix_list_15-2
}

ol.lst-kix_list_14-0 {
  list-style-type: none
}

.lst-kix_list_12-4>li:before {
  content: "" counter(lst-ctn-kix_list_12-4, lower-latin) ". "
}

ol.lst-kix_list_14-1 {
  list-style-type: none
}

ol.lst-kix_list_14-2 {
  list-style-type: none
}

.lst-kix_list_5-3>li {
  counter-increment: lst-ctn-kix_list_5-3
}

.lst-kix_list_20-5>li:before {
  content: "" counter(lst-ctn-kix_list_20-5, lower-roman) ". "
}

.lst-kix_list_7-4>li {
  counter-increment: lst-ctn-kix_list_7-4
}

.lst-kix_list_1-0>li:before {
  content: "\0025cf  "
}

ol.lst-kix_list_19-2.start {
  counter-reset: lst-ctn-kix_list_19-2 0
}

.lst-kix_list_20-1>li:before {
  content: "" counter(lst-ctn-kix_list_20-1, lower-latin) ". "
}

.lst-kix_list_11-8>li:before {
  content: "" counter(lst-ctn-kix_list_11-8, lower-roman) ". "
}

.lst-kix_list_12-3>li {
  counter-increment: lst-ctn-kix_list_12-3
}

ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0
}

.lst-kix_list_12-0>li:before {
  content: "" counter(lst-ctn-kix_list_12-0, lower-roman) ". "
}

.lst-kix_list_17-3>li {
  counter-increment: lst-ctn-kix_list_17-3
}

ol.lst-kix_list_8-4.start {
  counter-reset: lst-ctn-kix_list_8-4 0
}

.lst-kix_list_1-4>li:before {
  content: " "
}

.lst-kix_list_13-0>li:before {
  content: "\0025cf  "
}

.lst-kix_list_21-7>li {
  counter-increment: lst-ctn-kix_list_21-7
}

.lst-kix_list_14-4>li {
  counter-increment: lst-ctn-kix_list_14-4
}

.lst-kix_list_13-4>li:before {
  content: "o  "
}

.lst-kix_list_10-7>li {
  counter-increment: lst-ctn-kix_list_10-7
}

ol.lst-kix_list_19-3.start {
  counter-reset: lst-ctn-kix_list_19-3 0
}

.lst-kix_list_2-0>li:before {
  content: "" counter(lst-ctn-kix_list_2-0, decimal) ". "
}

.lst-kix_list_18-1>li {
  counter-increment: lst-ctn-kix_list_18-1
}

ol.lst-kix_list_8-3.start {
  counter-reset: lst-ctn-kix_list_8-3 0
}

.lst-kix_list_11-5>li {
  counter-increment: lst-ctn-kix_list_11-5
}

ol.lst-kix_list_14-5.start {
  counter-reset: lst-ctn-kix_list_14-5 0
}

ol.lst-kix_list_9-8.start {
  counter-reset: lst-ctn-kix_list_9-8 0
}

.lst-kix_list_1-8>li:before {
  content: " "
}

.lst-kix_list_12-8>li:before {
  content: "" counter(lst-ctn-kix_list_12-8, lower-roman) ". "
}

ol.lst-kix_list_20-3.start {
  counter-reset: lst-ctn-kix_list_20-3 0
}

.lst-kix_list_8-2>li {
  counter-increment: lst-ctn-kix_list_8-2
}

.lst-kix_list_19-0>li:before {
  content: "" counter(lst-ctn-kix_list_19-0, decimal) ". "
}

.lst-kix_list_19-1>li:before {
  content: "(" counter(lst-ctn-kix_list_19-1, lower-latin) ") "
}

ol.lst-kix_list_17-7.start {
  counter-reset: lst-ctn-kix_list_17-7 0
}

ul.lst-kix_list_1-0 {
  list-style-type: none
}

ol.lst-kix_list_12-6.start {
  counter-reset: lst-ctn-kix_list_12-6 0
}

.lst-kix_list_19-4>li:before {
  content: "" counter(lst-ctn-kix_list_19-4, lower-roman) ". "
}

.lst-kix_list_8-1>li {
  counter-increment: lst-ctn-kix_list_8-1
}

ol.lst-kix_list_8-2.start {
  counter-reset: lst-ctn-kix_list_8-2 0
}

.lst-kix_list_19-2>li:before {
  content: "(" counter(lst-ctn-kix_list_19-2, lower-roman) ") "
}

.lst-kix_list_19-3>li:before {
  content: "(" counter(lst-ctn-kix_list_19-3, decimal) ") "
}

ol.lst-kix_list_21-0.start {
  counter-reset: lst-ctn-kix_list_21-0 0
}

.lst-kix_list_15-0>li {
  counter-increment: lst-ctn-kix_list_15-0
}

ol.lst-kix_list_6-6.start {
  counter-reset: lst-ctn-kix_list_6-6 0
}

.lst-kix_list_7-0>li {
  counter-increment: lst-ctn-kix_list_7-0
}

.lst-kix_list_11-0>li {
  counter-increment: lst-ctn-kix_list_11-0
}

ul.lst-kix_list_1-3 {
  list-style-type: none
}

ul.lst-kix_list_1-4 {
  list-style-type: none
}

.lst-kix_list_19-0>li {
  counter-increment: lst-ctn-kix_list_19-0
}

ul.lst-kix_list_1-1 {
  list-style-type: none
}

ul.lst-kix_list_1-2 {
  list-style-type: none
}

ul.lst-kix_list_1-7 {
  list-style-type: none
}

ul.lst-kix_list_1-8 {
  list-style-type: none
}

ul.lst-kix_list_1-5 {
  list-style-type: none
}

ul.lst-kix_list_1-6 {
  list-style-type: none
}

ol.lst-kix_list_9-6.start {
  counter-reset: lst-ctn-kix_list_9-6 0
}

ol.lst-kix_list_16-3.start {
  counter-reset: lst-ctn-kix_list_16-3 0
}

ol.lst-kix_list_19-8.start {
  counter-reset: lst-ctn-kix_list_19-8 0
}

.lst-kix_list_19-8>li:before {
  content: "" counter(lst-ctn-kix_list_19-8, lower-roman) ") "
}

ol.lst-kix_list_14-7.start {
  counter-reset: lst-ctn-kix_list_14-7 0
}

ol.lst-kix_list_20-8.start {
  counter-reset: lst-ctn-kix_list_20-8 0
}

ol.lst-kix_list_11-2.start {
  counter-reset: lst-ctn-kix_list_11-2 0
}

.lst-kix_list_5-2>li {
  counter-increment: lst-ctn-kix_list_5-2
}

.lst-kix_list_19-5>li:before {
  content: "" counter(lst-ctn-kix_list_19-5, lower-roman) ". "
}

.lst-kix_list_19-6>li:before {
  content: "" counter(lst-ctn-kix_list_19-6, decimal) ") "
}

.lst-kix_list_19-7>li:before {
  content: "" counter(lst-ctn-kix_list_19-7, lower-latin) ") "
}

.lst-kix_list_9-2>li {
  counter-increment: lst-ctn-kix_list_9-2
}

ol.lst-kix_list_8-7.start {
  counter-reset: lst-ctn-kix_list_8-7 0
}

.lst-kix_list_17-2>li {
  counter-increment: lst-ctn-kix_list_17-2
}

ol.lst-kix_list_17-2.start {
  counter-reset: lst-ctn-kix_list_17-2 0
}

ol.lst-kix_list_21-5.start {
  counter-reset: lst-ctn-kix_list_21-5 0
}

.lst-kix_list_20-5>li {
  counter-increment: lst-ctn-kix_list_20-5
}

.lst-kix_list_19-7>li {
  counter-increment: lst-ctn-kix_list_19-7
}

.lst-kix_list_14-3>li {
  counter-increment: lst-ctn-kix_list_14-3
}

ol.lst-kix_list_15-6.start {
  counter-reset: lst-ctn-kix_list_15-6 0
}

.lst-kix_list_18-3>li {
  counter-increment: lst-ctn-kix_list_18-3
}

.lst-kix_list_21-6>li {
  counter-increment: lst-ctn-kix_list_21-6
}

.lst-kix_list_10-3>li {
  counter-increment: lst-ctn-kix_list_10-3
}

.lst-kix_list_12-1>li {
  counter-increment: lst-ctn-kix_list_12-1
}

.lst-kix_list_18-0>li:before {
  content: "" counter(lst-ctn-kix_list_18-0, decimal) ". "
}

.lst-kix_list_18-1>li:before {
  content: "(" counter(lst-ctn-kix_list_18-1, lower-latin) ") "
}

.lst-kix_list_18-2>li:before {
  content: "" counter(lst-ctn-kix_list_18-2, lower-roman) ". "
}

ol.lst-kix_list_11-7.start {
  counter-reset: lst-ctn-kix_list_11-7 0
}

ol.lst-kix_list_14-2.start {
  counter-reset: lst-ctn-kix_list_14-2 0
}

.lst-kix_list_16-1>li {
  counter-increment: lst-ctn-kix_list_16-1
}

.lst-kix_list_2-7>li:before {
  content: " "
}

.lst-kix_list_2-5>li:before {
  content: " "
}

.lst-kix_list_17-5>li {
  counter-increment: lst-ctn-kix_list_17-5
}

ol.lst-kix_list_17-0.start {
  counter-reset: lst-ctn-kix_list_17-0 0
}

ol.lst-kix_list_10-3.start {
  counter-reset: lst-ctn-kix_list_10-3 0
}

.lst-kix_list_18-6>li:before {
  content: "" counter(lst-ctn-kix_list_18-6, decimal) ". "
}

ol.lst-kix_list_9-4.start {
  counter-reset: lst-ctn-kix_list_9-4 0
}

ul.lst-kix_list_3-7 {
  list-style-type: none
}

.lst-kix_list_14-6>li {
  counter-increment: lst-ctn-kix_list_14-6
}

ul.lst-kix_list_3-8 {
  list-style-type: none
}

.lst-kix_list_10-1>li:before {
  content: "" counter(lst-ctn-kix_list_10-1, lower-latin) ") "
}

.lst-kix_list_21-3>li {
  counter-increment: lst-ctn-kix_list_21-3
}

.lst-kix_list_18-4>li:before {
  content: "" counter(lst-ctn-kix_list_18-4, lower-latin) ". "
}

.lst-kix_list_18-8>li:before {
  content: "" counter(lst-ctn-kix_list_18-8, lower-roman) ". "
}

ul.lst-kix_list_3-1 {
  list-style-type: none
}

.lst-kix_list_17-6>li {
  counter-increment: lst-ctn-kix_list_17-6
}

ul.lst-kix_list_3-2 {
  list-style-type: none
}

.lst-kix_list_7-7>li {
  counter-increment: lst-ctn-kix_list_7-7
}

ol.lst-kix_list_15-1.start {
  counter-reset: lst-ctn-kix_list_15-1 0
}

ol.lst-kix_list_15-4.start {
  counter-reset: lst-ctn-kix_list_15-4 0
}

ul.lst-kix_list_3-0 {
  list-style-type: none
}

ul.lst-kix_list_3-5 {
  list-style-type: none
}

ul.lst-kix_list_3-6 {
  list-style-type: none
}

ul.lst-kix_list_3-3 {
  list-style-type: none
}

ul.lst-kix_list_3-4 {
  list-style-type: none
}

.lst-kix_list_10-7>li:before {
  content: "" counter(lst-ctn-kix_list_10-7, lower-latin) ". "
}

.lst-kix_list_7-8>li {
  counter-increment: lst-ctn-kix_list_7-8
}

.lst-kix_list_20-1>li {
  counter-increment: lst-ctn-kix_list_20-1
}

.lst-kix_list_10-5>li:before {
  content: "(" counter(lst-ctn-kix_list_10-5, lower-roman) ") "
}

ol.lst-kix_list_18-6.start {
  counter-reset: lst-ctn-kix_list_18-6 0
}

.lst-kix_list_10-3>li:before {
  content: "(" counter(lst-ctn-kix_list_10-3, decimal) ") "
}

.lst-kix_list_15-4>li {
  counter-increment: lst-ctn-kix_list_15-4
}

ol.lst-kix_list_20-8 {
  list-style-type: none
}

ol.lst-kix_list_7-3.start {
  counter-reset: lst-ctn-kix_list_7-3 0
}

ol.lst-kix_list_20-5 {
  list-style-type: none
}

ol.lst-kix_list_20-4 {
  list-style-type: none
}

ol.lst-kix_list_20-7 {
  list-style-type: none
}

.lst-kix_list_11-7>li {
  counter-increment: lst-ctn-kix_list_11-7
}

ol.lst-kix_list_20-6 {
  list-style-type: none
}

.lst-kix_list_9-2>li:before {
  content: "" counter(lst-ctn-kix_list_9-2, lower-roman) ". "
}

ol.lst-kix_list_20-1 {
  list-style-type: none
}

ol.lst-kix_list_20-0 {
  list-style-type: none
}

ol.lst-kix_list_20-3 {
  list-style-type: none
}

ol.lst-kix_list_20-2 {
  list-style-type: none
}

ol.lst-kix_list_5-7.start {
  counter-reset: lst-ctn-kix_list_5-7 0
}

ol.lst-kix_list_14-0.start {
  counter-reset: lst-ctn-kix_list_14-0 0
}

.lst-kix_list_12-5>li {
  counter-increment: lst-ctn-kix_list_12-5
}

.lst-kix_list_20-8>li:before {
  content: "" counter(lst-ctn-kix_list_20-8, lower-roman) ". "
}

.lst-kix_list_5-5>li {
  counter-increment: lst-ctn-kix_list_5-5
}

.lst-kix_list_9-0>li:before {
  content: "(" counter(lst-ctn-kix_list_9-0, lower-latin) ") "
}

.lst-kix_list_16-8>li {
  counter-increment: lst-ctn-kix_list_16-8
}

.lst-kix_list_20-0>li:before {
  content: "" counter(lst-ctn-kix_list_20-0, decimal) ". "
}

ol.lst-kix_list_10-7 {
  list-style-type: none
}

.lst-kix_list_9-6>li:before {
  content: "" counter(lst-ctn-kix_list_9-6, decimal) ". "
}

ol.lst-kix_list_10-8 {
  list-style-type: none
}

ol.lst-kix_list_10-3 {
  list-style-type: none
}

.lst-kix_list_9-4>li:before {
  content: "" counter(lst-ctn-kix_list_9-4, lower-latin) ". "
}

ol.lst-kix_list_10-4 {
  list-style-type: none
}

ol.lst-kix_list_10-5 {
  list-style-type: none
}

.lst-kix_list_11-3>li:before {
  content: "" counter(lst-ctn-kix_list_11-3, decimal) ". "
}

ol.lst-kix_list_10-6 {
  list-style-type: none
}

.lst-kix_list_6-3>li {
  counter-increment: lst-ctn-kix_list_6-3
}

.lst-kix_list_20-6>li:before {
  content: "" counter(lst-ctn-kix_list_20-6, decimal) ". "
}

ol.lst-kix_list_18-4.start {
  counter-reset: lst-ctn-kix_list_18-4 0
}

ol.lst-kix_list_10-0 {
  list-style-type: none
}

ol.lst-kix_list_10-1 {
  list-style-type: none
}

ul.lst-kix_list_2-8 {
  list-style-type: none
}

ol.lst-kix_list_10-2 {
  list-style-type: none
}

ol.lst-kix_list_12-1.start {
  counter-reset: lst-ctn-kix_list_12-1 0
}

.lst-kix_list_20-4>li:before {
  content: "" counter(lst-ctn-kix_list_20-4, lower-latin) ". "
}

.lst-kix_list_11-5>li:before {
  content: "" counter(lst-ctn-kix_list_11-5, lower-roman) ". "
}

ol.lst-kix_list_6-1.start {
  counter-reset: lst-ctn-kix_list_6-1 0
}

ul.lst-kix_list_2-2 {
  list-style-type: none
}

.lst-kix_list_20-2>li:before {
  content: "" counter(lst-ctn-kix_list_20-2, lower-roman) ". "
}

ol.lst-kix_list_21-2.start {
  counter-reset: lst-ctn-kix_list_21-2 0
}

ul.lst-kix_list_2-3 {
  list-style-type: none
}

ul.lst-kix_list_2-1 {
  list-style-type: none
}

.lst-kix_list_9-8>li:before {
  content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ". "
}

ul.lst-kix_list_2-6 {
  list-style-type: none
}

.lst-kix_list_20-6>li {
  counter-increment: lst-ctn-kix_list_20-6
}

.lst-kix_list_1-1>li:before {
  content: " "
}

ul.lst-kix_list_2-7 {
  list-style-type: none
}

.lst-kix_list_11-7>li:before {
  content: "" counter(lst-ctn-kix_list_11-7, lower-latin) ". "
}

ul.lst-kix_list_2-4 {
  list-style-type: none
}

ul.lst-kix_list_2-5 {
  list-style-type: none
}

.lst-kix_list_8-5>li {
  counter-increment: lst-ctn-kix_list_8-5
}

.lst-kix_list_1-3>li:before {
  content: " "
}

ol.lst-kix_list_16-8.start {
  counter-reset: lst-ctn-kix_list_16-8 0
}

ol.lst-kix_list_10-5.start {
  counter-reset: lst-ctn-kix_list_10-5 0
}

ol.lst-kix_list_21-8 {
  list-style-type: none
}

.lst-kix_list_1-7>li:before {
  content: " "
}

ol.lst-kix_list_21-7 {
  list-style-type: none
}

ol.lst-kix_list_21-4 {
  list-style-type: none
}

.lst-kix_list_1-5>li:before {
  content: " "
}

ol.lst-kix_list_21-3 {
  list-style-type: none
}

ol.lst-kix_list_21-6 {
  list-style-type: none
}

ol.lst-kix_list_9-1.start {
  counter-reset: lst-ctn-kix_list_9-1 0
}

.lst-kix_list_14-7>li {
  counter-increment: lst-ctn-kix_list_14-7
}

ol.lst-kix_list_21-5 {
  list-style-type: none
}

.lst-kix_list_5-6>li {
  counter-increment: lst-ctn-kix_list_5-6
}

ol.lst-kix_list_21-0 {
  list-style-type: none
}

ol.lst-kix_list_7-5.start {
  counter-reset: lst-ctn-kix_list_7-5 0
}

ol.lst-kix_list_21-2 {
  list-style-type: none
}

ol.lst-kix_list_21-1 {
  list-style-type: none
}

.lst-kix_list_2-1>li:before {
  content: " "
}

.lst-kix_list_19-8>li {
  counter-increment: lst-ctn-kix_list_19-8
}

.lst-kix_list_2-3>li:before {
  content: " "
}

.lst-kix_list_11-8>li {
  counter-increment: lst-ctn-kix_list_11-8
}

ol.lst-kix_list_19-6 {
  list-style-type: none
}

ol.lst-kix_list_19-7 {
  list-style-type: none
}

ol.lst-kix_list_19-8 {
  list-style-type: none
}

ol.lst-kix_list_19-2 {
  list-style-type: none
}

ol.lst-kix_list_19-3 {
  list-style-type: none
}

ol.lst-kix_list_19-4 {
  list-style-type: none
}

ol.lst-kix_list_19-5 {
  list-style-type: none
}

.lst-kix_list_20-8>li {
  counter-increment: lst-ctn-kix_list_20-8
}

ol.lst-kix_list_19-0 {
  list-style-type: none
}

.lst-kix_list_9-1>li {
  counter-increment: lst-ctn-kix_list_9-1
}

ol.lst-kix_list_19-1 {
  list-style-type: none
}

ol.lst-kix_list_7-7.start {
  counter-reset: lst-ctn-kix_list_7-7 0
}

.lst-kix_list_3-1>li:before {
  content: "o  "
}

.lst-kix_list_3-2>li:before {
  content: "\0025aa  "
}

.lst-kix_list_14-0>li {
  counter-increment: lst-ctn-kix_list_14-0
}

.lst-kix_list_8-1>li:before {
  content: "" counter(lst-ctn-kix_list_8-1, lower-latin) ") "
}

ol.lst-kix_list_17-4.start {
  counter-reset: lst-ctn-kix_list_17-4 0
}

.lst-kix_list_8-2>li:before {
  content: "" counter(lst-ctn-kix_list_8-2, lower-roman) ") "
}

.lst-kix_list_6-0>li {
  counter-increment: lst-ctn-kix_list_6-0
}

.lst-kix_list_3-5>li:before {
  content: "\0025aa  "
}

.lst-kix_list_18-0>li {
  counter-increment: lst-ctn-kix_list_18-0
}

.lst-kix_list_12-0>li {
  counter-increment: lst-ctn-kix_list_12-0
}

ol.lst-kix_list_12-3.start {
  counter-reset: lst-ctn-kix_list_12-3 0
}

ol.lst-kix_list_11-5.start {
  counter-reset: lst-ctn-kix_list_11-5 0
}

.lst-kix_list_21-2>li:before {
  content: "" counter(lst-ctn-kix_list_21-2, lower-roman) ". "
}

.lst-kix_list_8-5>li:before {
  content: "(" counter(lst-ctn-kix_list_8-5, lower-roman) ") "
}

.lst-kix_list_11-1>li {
  counter-increment: lst-ctn-kix_list_11-1
}

.lst-kix_list_8-6>li:before {
  content: "" counter(lst-ctn-kix_list_8-6, decimal) ". "
}

.lst-kix_list_2-0>li {
  counter-increment: lst-ctn-kix_list_2-0
}

.lst-kix_list_15-1>li {
  counter-increment: lst-ctn-kix_list_15-1
}

.lst-kix_list_3-6>li:before {
  content: "\0025cf  "
}

.lst-kix_list_21-6>li:before {
  content: "" counter(lst-ctn-kix_list_21-6, decimal) ". "
}

ol.lst-kix_list_16-6.start {
  counter-reset: lst-ctn-kix_list_16-6 0
}

.lst-kix_list_21-7>li:before {
  content: "" counter(lst-ctn-kix_list_21-7, lower-latin) ". "
}

ol.lst-kix_list_5-0.start {
  counter-reset: lst-ctn-kix_list_5-0 0
}

.lst-kix_list_11-2>li:before {
  content: "" counter(lst-ctn-kix_list_11-2, lower-roman) ". "
}

ol.lst-kix_list_16-0.start {
  counter-reset: lst-ctn-kix_list_16-0 0
}

.lst-kix_list_21-3>li:before {
  content: "" counter(lst-ctn-kix_list_21-3, decimal) ". "
}

ol.lst-kix_list_18-7.start {
  counter-reset: lst-ctn-kix_list_18-7 0
}

ol.lst-kix_list_11-6.start {
  counter-reset: lst-ctn-kix_list_11-6 0
}

ol.lst-kix_list_12-4.start {
  counter-reset: lst-ctn-kix_list_12-4 0
}

.lst-kix_list_16-6>li:before {
  content: "" counter(lst-ctn-kix_list_16-6, decimal) ") "
}

ol.lst-kix_list_10-1.start {
  counter-reset: lst-ctn-kix_list_10-1 0
}

ol.lst-kix_list_6-4.start {
  counter-reset: lst-ctn-kix_list_6-4 0
}

.lst-kix_list_17-1>li:before {
  content: "(" counter(lst-ctn-kix_list_17-1, lower-latin) ") "
}

.lst-kix_list_18-2>li {
  counter-increment: lst-ctn-kix_list_18-2
}

ol.lst-kix_list_5-6.start {
  counter-reset: lst-ctn-kix_list_5-6 0
}

.lst-kix_list_16-1>li:before {
  content: "(" counter(lst-ctn-kix_list_16-1, lower-latin) ") "
}

.lst-kix_list_7-3>li {
  counter-increment: lst-ctn-kix_list_7-3
}

.lst-kix_list_16-2>li:before {
  content: "(" counter(lst-ctn-kix_list_16-2, lower-roman) ") "
}

.lst-kix_list_16-5>li:before {
  content: "" counter(lst-ctn-kix_list_16-5, lower-roman) ". "
}

.lst-kix_list_19-3>li {
  counter-increment: lst-ctn-kix_list_19-3
}

.lst-kix_list_15-3>li {
  counter-increment: lst-ctn-kix_list_15-3
}

ol.lst-kix_list_7-8.start {
  counter-reset: lst-ctn-kix_list_7-8 0
}

.lst-kix_list_12-4>li {
  counter-increment: lst-ctn-kix_list_12-4
}

ol.lst-kix_list_11-0.start {
  counter-reset: lst-ctn-kix_list_11-0 0
}

ol.lst-kix_list_18-8.start {
  counter-reset: lst-ctn-kix_list_18-8 0
}

.lst-kix_list_16-4>li {
  counter-increment: lst-ctn-kix_list_16-4
}

ol.lst-kix_list_6-3.start {
  counter-reset: lst-ctn-kix_list_6-3 0
}

ol.lst-kix_list_10-2.start {
  counter-reset: lst-ctn-kix_list_10-2 0
}

.lst-kix_list_12-7>li {
  counter-increment: lst-ctn-kix_list_12-7
}

.lst-kix_list_17-2>li:before {
  content: "(" counter(lst-ctn-kix_list_17-2, lower-roman) ") "
}

ol.lst-kix_list_5-5.start {
  counter-reset: lst-ctn-kix_list_5-5 0
}

ol.lst-kix_list_16-5.start {
  counter-reset: lst-ctn-kix_list_16-5 0
}

ol.lst-kix_list_17-3.start {
  counter-reset: lst-ctn-kix_list_17-3 0
}

.lst-kix_list_17-6>li:before {
  content: "" counter(lst-ctn-kix_list_17-6, decimal) ") "
}

.lst-kix_list_17-5>li:before {
  content: "" counter(lst-ctn-kix_list_17-5, lower-roman) ". "
}

.lst-kix_list_6-2>li {
  counter-increment: lst-ctn-kix_list_6-2
}

.lst-kix_list_2-6>li:before {
  content: " "
}

ol.lst-kix_list_16-2.start {
  counter-reset: lst-ctn-kix_list_16-2 0
}

.lst-kix_list_14-5>li {
  counter-increment: lst-ctn-kix_list_14-5
}

.lst-kix_list_7-1>li:before {
  content: "(" counter(lst-ctn-kix_list_7-1, lower-latin) ") "
}

.lst-kix_list_7-5>li:before {
  content: "(" counter(lst-ctn-kix_list_7-5, decimal) ") "
}

.lst-kix_list_9-6>li {
  counter-increment: lst-ctn-kix_list_9-6
}

.lst-kix_list_19-5>li {
  counter-increment: lst-ctn-kix_list_19-5
}

ol.lst-kix_list_5-4.start {
  counter-reset: lst-ctn-kix_list_5-4 0
}

ol.lst-kix_list_11-1.start {
  counter-reset: lst-ctn-kix_list_11-1 0
}

.lst-kix_list_18-5>li:before {
  content: "" counter(lst-ctn-kix_list_18-5, lower-roman) ". "
}

.lst-kix_list_13-6>li:before {
  content: "\0025cf  "
}

ol.lst-kix_list_5-1.start {
  counter-reset: lst-ctn-kix_list_5-1 0
}

.lst-kix_list_20-3>li {
  counter-increment: lst-ctn-kix_list_20-3
}

.lst-kix_list_6-7>li {
  counter-increment: lst-ctn-kix_list_6-7
}

.lst-kix_list_16-6>li {
  counter-increment: lst-ctn-kix_list_16-6
}

.lst-kix_list_10-6>li {
  counter-increment: lst-ctn-kix_list_10-6
}

.lst-kix_list_11-6>li {
  counter-increment: lst-ctn-kix_list_11-6
}

ol.lst-kix_list_10-0.start {
  counter-reset: lst-ctn-kix_list_10-0 0
}

.lst-kix_list_7-5>li {
  counter-increment: lst-ctn-kix_list_7-5
}

.lst-kix_list_15-6>li:before {
  content: "" counter(lst-ctn-kix_list_15-6, decimal) ") "
}

ol.lst-kix_list_17-8.start {
  counter-reset: lst-ctn-kix_list_17-8 0
}

.lst-kix_list_11-4>li {
  counter-increment: lst-ctn-kix_list_11-4
}

ol.lst-kix_list_6-8.start {
  counter-reset: lst-ctn-kix_list_6-8 0
}

.lst-kix_list_10-2>li:before {
  content: "" counter(lst-ctn-kix_list_10-2, lower-roman) ") "
}

.lst-kix_list_20-7>li:before {
  content: "" counter(lst-ctn-kix_list_20-7, lower-latin) ". "
}

ol.lst-kix_list_17-5.start {
  counter-reset: lst-ctn-kix_list_17-5 0
}

ol.lst-kix_list_6-5.start {
  counter-reset: lst-ctn-kix_list_6-5 0
}

.lst-kix_list_4-2>li:before {
  content: "\0025aa  "
}

.lst-kix_list_4-6>li:before {
  content: "\0025cf  "
}

.lst-kix_list_17-4>li {
  counter-increment: lst-ctn-kix_list_17-4
}

.lst-kix_list_15-2>li:before {
  content: "(" counter(lst-ctn-kix_list_15-2, lower-roman) ") "
}

.lst-kix_list_10-8>li {
  counter-increment: lst-ctn-kix_list_10-8
}

.lst-kix_list_10-6>li:before {
  content: "" counter(lst-ctn-kix_list_10-6, decimal) ". "
}

.lst-kix_list_9-1>li:before {
  content: "" counter(lst-ctn-kix_list_9-1, lower-latin) ". "
}

ol.lst-kix_list_12-7.start {
  counter-reset: lst-ctn-kix_list_12-7 0
}

.lst-kix_list_15-8>li {
  counter-increment: lst-ctn-kix_list_15-8
}

ol.lst-kix_list_6-7.start {
  counter-reset: lst-ctn-kix_list_6-7 0
}

.lst-kix_list_12-2>li {
  counter-increment: lst-ctn-kix_list_12-2
}

.lst-kix_list_9-5>li:before {
  content: "" counter(lst-ctn-kix_list_9-5, lower-roman) ". "
}

.lst-kix_list_12-2>li:before {
  content: "" counter(lst-ctn-kix_list_12-2, lower-roman) ". "
}

ol.lst-kix_list_12-8.start {
  counter-reset: lst-ctn-kix_list_12-8 0
}

.lst-kix_list_11-6>li:before {
  content: "" counter(lst-ctn-kix_list_11-6, decimal) ". "
}

.lst-kix_list_20-3>li:before {
  content: "" counter(lst-ctn-kix_list_20-3, decimal) ". "
}

ol.lst-kix_list_11-3.start {
  counter-reset: lst-ctn-kix_list_11-3 0
}

.lst-kix_list_1-2>li:before {
  content: " "
}

.lst-kix_list_21-1>li {
  counter-increment: lst-ctn-kix_list_21-1
}

.lst-kix_list_10-1>li {
  counter-increment: lst-ctn-kix_list_10-1
}

.lst-kix_list_8-8>li {
  counter-increment: lst-ctn-kix_list_8-8
}

ol.lst-kix_list_16-1.start {
  counter-reset: lst-ctn-kix_list_16-1 0
}

ol.lst-kix_list_17-6.start {
  counter-reset: lst-ctn-kix_list_17-6 0
}

.lst-kix_list_1-6>li:before {
  content: " "
}

.lst-kix_list_18-7>li {
  counter-increment: lst-ctn-kix_list_18-7
}

.lst-kix_list_12-6>li:before {
  content: "" counter(lst-ctn-kix_list_12-6, decimal) ". "
}

ol.lst-kix_list_11-4.start {
  counter-reset: lst-ctn-kix_list_11-4 0
}

.lst-kix_list_2-2>li:before {
  content: " "
}

.lst-kix_list_13-2>li:before {
  content: "\0025aa  "
}

ol.lst-kix_list_5-2.start {
  counter-reset: lst-ctn-kix_list_5-2 0
}

ol {
  margin: 0;
  padding: 0
}

table td,
table th {
  padding: 0
}

.c14 {
  margin-left: 36pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 36pt;
  padding-bottom: 12pt;
  
  orphans: 2;
  widows: 2;
  text-align: justify
}

.c2 {
  -webkit-text-decoration-skip: none;
  
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  
  font-style: normal
}

.c5 {
  margin-left: 36pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 90pt;
  padding-bottom: 12pt;
  
  orphans: 2;
  widows: 2;
  text-align: justify
}

.c7 {
  margin-left: 0pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 12pt;
  
  orphans: 2;
  widows: 2;
  text-align: justify
}

.c1 {
  
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  
  font-style: normal
}

.c20 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  
  orphans: 2;
  widows: 2;
  text-align: left
}

.c25 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  
  orphans: 2;
  widows: 2;
  text-align: justify
}

.c24 {
  padding-top: 14pt;
  padding-bottom: 14pt;
  
  orphans: 2;
  widows: 2;
  text-align: justify
}

.c30 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  
  orphans: 2;
  widows: 2;
  text-align: justify
}

.c3 {
  
  
  font-weight: 400
}

.c8 {
  
  
  font-weight: 400
}

.c33 {
  padding-top: 12pt;
  padding-bottom: 0pt;
  
  text-align: center
}

.c12 {
  
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal
}

.c15 {
  max-width: 496.8pt;
  padding: 57.6pt 57.6pt 57.6pt 57.6pt
}

.c0 {
  
  font-weight: 700
}

.c13 {
  
  vertical-align: baseline;
  font-style: normal
}

.c32 {
  font-weight: 400;
  
  
}

.c31 {
  margin-left: 0pt;
  list-style-position: inside;
  text-indent: 72pt
}

.c11 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  text-decoration-skip-ink: none
}

.c16 {
  font-weight: 400;
  
  
}

.c10 {
  
  font-weight: 400
}

.c18 {
  
  text-decoration: inherit
}

.c17 {
  
}

.c4 {
  font-weight: 700;
  
}

.c6 {
  padding: 0;
  margin: 0
}

.c22 {
  font-weight: 400;
  
}

.c28 {
  font-style: italic
}

.c29 {
  height: 11pt
}

.c27 {
  
}

.c9 {
  vertical-align: super
}

.c21 {
  
}

.c23 {
  
}

.c26 {
  margin-left: 36pt
}

.title {
  padding-top: 0pt;
  
  font-weight: 700;
  
  padding-bottom: 12pt;
  
  
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: center
}

.subtitle {
  padding-top: 0pt;
  
  font-weight: 700;
  
  padding-bottom: 12pt;
  
  
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

li {
  
  
  
}

p {
  margin: 0;
  
  
  
}

h1 {
  padding-top: 0pt;
  font-size: 18px;
  
  
  padding-bottom: 12pt;
  
  
  orphans: 2;
  widows: 2;
  text-align: justify
}

h2 {
  padding-top: 0pt;
  font-size: 18px;
  
  padding-bottom: 12pt;
  
  
  orphans: 2;
  widows: 2;
  text-align: justify
}

h3 {
  padding-top: 0pt;
  
  
  padding-bottom: 12pt;
  
  
  orphans: 2;
  widows: 2;
  text-align: left
}

h4 {
  padding-top: 0pt;
  
  
  padding-bottom: 12pt;
  
  
  orphans: 2;
  widows: 2;
  text-align: left
}

h5 {
  padding-top: 0pt;
  
  
  padding-bottom: 12pt;
  
  
  orphans: 2;
  widows: 2;
  text-align: left
}

h6 {
  padding-top: 0pt;
  
  
  padding-bottom: 12pt;
  
  
  orphans: 2;
  widows: 2;
  text-align: left
}
</style>
