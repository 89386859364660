<template>
  <form @submit.prevent class="form-wrapper">
    <h2 class="form-title">{{ title }}</h2>
    <slot></slot>
  </form>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
};
</script>

<style scoped>
.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #2E4EB6;
  padding: 40px;
  width: 374px;
  border-radius: 4px;
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}
</style>