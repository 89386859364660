<script setup>
import { ref, computed } from 'vue';
import { SkewedDiv } from '@/components/common';
import Triangle from '@/components/icons/DownTriangle.vue';

const props = defineProps({
  title: String,
  background: {type: String},
  bgVariant: {type: String, default: 'green'},
});

const isOpen = ref(false);

const handleClick = () => {
  isOpen.value = !isOpen.value;
};

const Backgrounds = {
  green: 'linear-gradient(90deg, var(--color-green-400), var(--color-green-600))',
  blue: 'linear-gradient(90deg, var(--color-blue-400), var(--color-blue-500))',
  blue2: 'var(--color-blue-600)',
  purple: 'linear-gradient(90deg, var(--color-purple-400), var(--color-purple-600))',
  pink: 'linear-gradient(90deg, var(--color-pink-400), var(--color-pink-600))',
  orange: 'linear-gradient(90deg, var(--color-orange-400), var(--color-orange-600))',
}

const bg = computed(() => props.background ?? Backgrounds[props.bgVariant])
</script>

<template>
  <SkewedDiv
    :borderRadius="14"
    :background="bg"
    :skew="[0, [4, 8], 24, -6]"
    :class="[s.box, { [s.isOpen]: isOpen }]"
  >
    <article>
      <div :class="s.header"
      @click.prevent="handleClick"
      >
        <h3 :class="s.title">{{ props.title }}</h3>
        <Triangle :class="s.icon" />
        <div :class="s.sectionGraphic">
          <slot name="graphic" />
        </div>
      </div>
      <div v-if="isOpen" :class="s.groupContent">
        <slot />
      </div>
    </article>
  </SkewedDiv>
</template>

<style module="s" lang="scss">
.box {
  padding: 30px 50px;
  margin-left: 110px;
}
.header {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.title {
  flex: 1;
  font-size: 44px;
}
.groupContent {
  display: grid;
  gap: 20px;
  margin-top: 28px;
}
.icon {
  transition: 0.2s ease-out;
  .isOpen & {
    transform: rotate(270deg);
  }
}
.sectionGraphic {
  position: absolute;
  height: 146px;
  transform: translateY(-50%) translateX(-100%);
  top: 50%;
  img {
    max-height: 100%;
    max-width: 100%;
  }
}
@media (max-width: 740px) {
  .title {
    font-size: 24px;
  }
  .icon {
    height: 22px;
  }
  .box {
    padding: 30px 40px;
    margin-left: 110px;
  }
}

@media (max-width: 580px) {
  .sectionGraphic {
    display: none;
  }
  .box {
    margin-left: initial;
  }
}
</style>
