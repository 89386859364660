<template>
	<TextInput v-model="input" :isTextArea="false" label="Email" @input="validateInput" />
</template>

<script setup>
import { ref } from 'vue';
import { accounts } from '@/account';
import TextInput from '@/components/ui/TextInput.vue';

let input = ref('');
const { validateEmailField, errors } = accounts.useFormValidation();
const validateInput = () => {
	validateEmailField('email', input.value);
};
</script>