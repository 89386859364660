'use strict';

// Specified state transitions per particular actions.
export default {

  updateShopConfig(state, cfg) {
    state.shopConfig = cfg;
  },

  updateCurrentPrice(state, currentPrice) {
    state.currentPrice = currentPrice;
  },

  updateProofs(state, userProofs) {
    state.merkleProofs = userProofs;
  },

  updateOwned(state, owned) {
    state.owned = owned;
  },

  updateMinted(state, minted) {
    state.minted = minted;
  },

  updateNextPrice(state, nextPrice) {
    state.nextPrice = nextPrice;
  },

  updateTokenInfo(state, info) {
    state.balances = info;
  },

  updateApproveSuper(state, amount) {
    state.balances.tokenAllowance = amount
  },

  updateSoldCount(state, soldCount) {
    state.soldCount = soldCount;
  }
};
