'use strict';

import { useQuery } from 'vue-query';

let config = null;

const init = async () => {
  if (config === null) {
    let res = await fetch(`${process.env.BASE_URL}config.json?t=${Date.now()}`)
    let rsp = await res.json()
    if (rsp.smartContractABI != null) {
      // if smartContractABI is set, loop through all the elements and http.fetch their content
      let promises = [];
      Object.keys(rsp.smartContractABI).forEach(path =>
        promises.push(
          fetch(`${process.env.BASE_URL}${rsp.smartContractABI[path]}?t=${Date.now()}`)
            .then(r => r.json())
            .then(abiRsp => {
              // the way we setup *ABI.json files is by copy/pasting from hardhat output
              // we need to go up one level, and we do that through Reflection
              return {
                attr: path,
                json: abiRsp.abi,
              }
            })
        )
      );
      // once fetched, wait until all async calls are completed, and populate the config attributes with their values
      let promised = await Promise.all(promises)
      promised.forEach(fetchedJS => {
        Reflect.set(rsp, fetchedJS.attr, fetchedJS.json);
      })
    }
    config = rsp
  }
};

export function cfg() {
  return useQuery(
    'config',
    async () => {
      if (config === null) await init();
      return config;
    },
    {
      enabled: true,
      cacheTime: 1000 * 60 * 10
    }
  );
};

// Export the config service functions.
export const configService = {
  cfg
};
