<script setup>
import { computed, ref, nextTick, watch } from 'vue';
import SkewedModal from '@/components/common/SkewedModal.vue';
import SkewedButton from '@/components/common/SkewedButton.vue';
import NFTSlot from '@/components/common/NFTSlot.vue';
import SkewedTooltip from '@/components/common/SkewedTooltip.vue';
import SkewedDiv from '../../../components/common/SkewedDiv.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import TrashIcon from '@/components/icons/TrashIcon.vue';
import PetImg from '@/images/pet-art-small.webp';
import BoxImg from '@/images/materials-box_small.webp';
import ChestImg from '@/images/chest_small.webp';
import PassImg from '@/images/arcade_small.webp';
import VoxelImg from '@/assets/vox_1.png';
import {
  IMPOSTOR_TOKEN_TYPE,
  UFO_TOKEN_TYPE,
  PET_TOKEN_TYPE,
  BOX_TOKEN_TYPE,
  CHEST_TOKEN_TYPE,
  PASS_TOKEN_TYPE,
  VOXEL_TOKEN_TYPE
} from '@/constants';
import { useOwned } from '../composables';

const props = defineProps({
  isSubmitting: { type: Boolean },
  isBloodApproved: { type: Boolean },
  title: { type: String },
  actionText: { type: String },
  claimTypeText: { type: String },
  bloodPerItem: { type: [Number, String] },
  bloodBalance: { type: [Number], default: 0 },
  claimSlotsActive: { type: Array },
  redeemRound: { type: Number, default: 0 },
  redeemToken: { type: String },
  claimSlots: { type: Array, default: () => [] }
});
const emit = defineEmits(['exit', 'submit', 'approve']);

let { format } = new Intl.NumberFormat('en-US', {maximumFractionDigits: 2});

const defaultItem = {
	[IMPOSTOR_TOKEN_TYPE]: null
};

const slotsConfig = {
  [IMPOSTOR_TOKEN_TYPE]: { title: 'Select Your Impostor' },
  [UFO_TOKEN_TYPE]: { title: 'Select Your UFO' },
  [PET_TOKEN_TYPE]: { title: 'Select Your Pet' },
  [BOX_TOKEN_TYPE]: { title: 'Select Your Box' },
  [CHEST_TOKEN_TYPE]: { title: 'Select Your Chest' },
  [PASS_TOKEN_TYPE]: PassImg,
  [VOXEL_TOKEN_TYPE]: VoxelImg
};

let cart = ref([structuredClone(defaultItem)]);
let selectedTokens = computed(() =>
  cart.value.map(row => Object.values(row).filter(Boolean)).flat()
);

let { data } = useOwned();

let eligibleTokens = computed(() => {
  // create a map of {[string: TokenType]: Token[]}
  // for eligible ids from
  // {[string: TokenAddress]: { round: number, items: TokenData[] }[]}
  return props.claimSlots.reduce((acc, tokenType) => {
    acc[tokenType] = [];
    let addr = data.value?.collections?.[tokenType]?.addr;
    if (addr) {
      acc[tokenType] =
        data.value?.eligible?.[addr]?.find(
          ({ round }) => round === props.redeemRound
        )?.items ?? [];
    }
    return acc;
  }, {});
});

let eligibleIds = computed(() => {
  // create a map of {[string: TokenType]: ids[]}
  return Object.entries(eligibleTokens.value).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [k]: v?.map(t => t?.tokenId)
    }),
    {}
  );
});

let availableTokens = computed(() => {
  return Object.entries(eligibleTokens.value).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [k]: v?.filter(
        t =>
          !selectedTokens.value.find(
            selectedT =>
              t.tokenId === selectedT.tokenId &&
              t.tokenType === selectedT.tokenType
          )
      )
    }),
    {}
  );
});

let addClaimEl = ref(null);

const ClaimReceiveImgs = {
  [PET_TOKEN_TYPE]: PetImg,
  [BOX_TOKEN_TYPE]: BoxImg,
  [CHEST_TOKEN_TYPE]: ChestImg,
  [PASS_TOKEN_TYPE]: PassImg
};
const claimReceiveImg = computed(() => ClaimReceiveImgs[props.redeemToken]);

let addClaim = async () => {
  if (props.isSubmitting) return;
  cart.value.push(structuredClone(defaultItem));
  await nextTick();
  addClaimEl.value?.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

let autoAddClaims = async () => {
  if (props.isSubmitting) return;
  let [smallestEligibleKey, ...restKeys] = Object.entries(availableTokens.value)
    .sort(([, v1], [, v2]) => v1.length - v2.length)
    .map(([k]) => k);
  let claims = availableTokens.value[smallestEligibleKey].map((t, i) => ({
    [smallestEligibleKey]: t,
    ...restKeys.reduce(
      (acc, k) => ({ ...acc, [k]: availableTokens.value[k][i] }),
      {}
    )
  }));
  if (selectedTokens.value.length === 0) {
    cart.value = claims;
  } else {
    cart.value = cart.value.concat(claims);
  }
};

let canAutoAdd = computed(() => {
  return Object.values(eligibleTokens.value).every(
    tokenArr => tokenArr.length > 0
  );
});

let bloodTotalNum = computed(
  () => cart.value.length * parseFloat(props.bloodPerItem)
);
let bloodTotal = computed(() => format(bloodTotalNum.value));

let hasEnoughBlood = computed(() => props.bloodBalance >= bloodTotalNum.value);
let bloodBalanceFormat = computed(() => format(Math.floor(parseFloat(props.bloodBalance) * 100) / 100));
let bloodPerItemFormat = computed(() => format(props.bloodPerItem));

const exit = () => {
  emit('exit');
};

const approve = () => {
  emit('approve');
};

let submit = () => {
  // order of claim slots determines order of claim
  // ID call
  let claims = cart.value.map(claim =>
    props.claimSlots.map(t => claim[t]?.tokenId)
  );
  emit('submit', claims);
};

let canSelectItem = (item = {}, tokenType) => {
  let notAlreadySelected = !selectedTokens.value.find(
    t => t.tokenId === item.tokenId && t.tokenType === tokenType
  );
  let isEligible = (eligibleIds.value?.[tokenType] ?? []).includes(
    item.tokenId
  );
  return notAlreadySelected && isEligible;
};

let allRowsSet = computed(() =>
  cart.value.every(row => { 
    let nfts = Object.values(row)
    return nfts.every(t => !!t?.tokenId) && nfts.length === props.claimSlots.length })
);
let allTokensUnique = computed(
  () =>
    new Set(selectedTokens.value.map(t => `${t?.tokenType}-${t?.tokenId}`))
      .size === selectedTokens.value.length
);
let allTokensEligible = computed(() =>
  selectedTokens.value.every(t =>
    (eligibleIds.value?.[t.tokenType] ?? []).includes(t.tokenId)
  )
);

let canClaim = computed(() => {
  return (
    !props.isSubmitting &&
    hasEnoughBlood.value &&
    allRowsSet.value &&
    allTokensUnique.value &&
    allTokensEligible.value
  );
});
let tooltipMsg = computed(() => {
  return !allRowsSet.value
    ? 'You must fill all claim slots'
    : !allTokensUnique.value
    ? 'NFT used twice in claims'
    : !allTokensEligible.value
    ? 'NFT already used to claim previously'
    : !hasEnoughBlood.value
    ? 'You don\'t have enough blood'
    : null;
});
</script>

<template>
  <SkewedModal
    :borderRadius="16"
    :skew="[[0, -10], 0, [-6, 0], [8, 0]]"
    background="var(--color-bg-modal)"
    @exit="exit"
    :classSkewedDiv="s['select-items-modal']"
    :classModalContent="s['select-items-content']"
  >
    <template #title>
      <div :class="s.titleWrapper">
        <span :class="s.title">{{ title }}</span>
        <div :class="s.bloodBalance">
          <img
            :class="s.bloodIconHeader"
            src="@/images/blood-icon-large.webp"
          />
          <span>{{ bloodBalanceFormat }}</span>
        </div>
      </div>
    </template>

    <template #content>
      <div :class="s['content-wrapper']">
        <!-- unstaked container -->
        <div v-for="(item, index) in cart" :class="s['claimRow']" :key="index">
          <div :class="s.nftSlots">
            <NFTSlot
              v-for="tokenType in props.claimSlots"
              :key="tokenType"
              :token="item[tokenType] || {}"
              :tokenType="tokenType"
              :items="data?.owned?.[tokenType]"
              :class="s.tokenPreview"
              :disabled="props.isSubmitting"
              :defaultTitle="slotsConfig[tokenType]?.title"
              @select="({ nft }) => (item[tokenType] = nft)"
              :canSelectItem="item => canSelectItem(item, tokenType)"
            />
          </div>

          <div :class="s.bloodLineAmount">
            <img
              :class="s.bloodIconInline"
              src="@/images/blood-icon-small.webp"
            />
            <span>{{ bloodPerItemFormat }}</span>
          </div>

          <div v-if="cart.length > 1" :class="s.trashButtonWrapper">
            <SkewedButton
              :borderRadius="5"
              :skew="[0, 0, 3, 0]"
              background="#FF4D4D"
              :class="[s.buttonIcon, s.buttonIconTrash]"
              @click="cart.splice(index, 1)"
              borderColor="black"
              borderOpacity=".2"
              :borderOffset="[3, 3]"
            >
              <TrashIcon />
            </SkewedButton>
          </div>
        </div>

        <a
          href="#"
          :class="s.addClaimRow"
          :style="props.isSubmitting && { opacity: 0.3 }"
          @click.prevent="addClaim"
          ref="addClaimEl"
        >
          <span>Add Another Claim</span>
          <SkewedButton
            :borderRadius="5"
            :skew="[0, 0, 3, 0]"
            background="var(--color-button-yellow)"
            :class="[s.buttonIcon]"
            borderColor="#263EC9"
            :borderOffset="[3, 3]"
          >
            <PlusIcon />
          </SkewedButton>
        </a>
      </div>
    </template>

    <template #footer>
      <div v-if="!hasEnoughBlood" :class="s.notEnoughBloodWrapper">
        <div :class="s.notEnoughBlood">You don't have enough $BLOOD</div>
      </div>
      <SkewedDiv
        background="#5871FC"
        :borderRadius="6"
        :skew="[0, 0, 6, -3]"
        :class="s['footer-wrapper']"
        :classContent="s['footer-content']"
        :borderColor="!hasEnoughBlood ? '#FF5B5B' : ''"
        :borderWidth="!hasEnoughBlood ? '2px' : ''"
      >
        <div :class="s.footerSummaryRow">
          <div :class="[s['footer-col'], s.footerColBlood]">
            <p :class="s.label">$BLOOD TOTAL</p>
            <p :class="s.value">
              <img
                :class="s.bloodIconInline"
                src="@/images/blood-icon-small.webp"
              />
              {{ bloodTotal }}
            </p>
          </div>

          <div :class="[s['footer-col'], s.footerColReceive]">
            <p :class="s.label">
              Will receive
              <span>
                {{ cart.length }}
                x
              </span>
            </p>
            <img :class="s.claimReceiveImg" :src="claimReceiveImg" />
            <p :class="s.value">
              {{ claimTypeText }}
            </p>
          </div>
        </div>

        <div :class="s.claimButtonWrapper">
          <SkewedButton
            v-if="canAutoAdd"
            :borderRadius="6"
            :skew="[0, 0, [-5, -3], 0]"
            background="black"
            :class="[s.button, s.buttonSecondary]"
            @click="autoAddClaims"
            :disabled="isSubmitting"
            disabledBackground="#333"
            disabledColor="#CCC"
          >
            Auto-fill
          </SkewedButton>
          <SkewedTooltip :text="tooltipMsg">
            <div>
              <template v-if="!props.isBloodApproved">
                <SkewedButton
                  :borderRadius="6"
                  :skew="[0, 0, [-5, -3], 0]"
                  background="var(--color-button-yellow)"
                  :class="[s.button]"
                  :disabled="isSubmitting"
                  @click="approve"
                >
                  <span>Approve $BLOOD</span>
                </SkewedButton>
              </template>
              <template v-else>
                <SkewedButton
                  :borderRadius="6"
                  :skew="[0, 0, [-5, -3], 0]"
                  background="var(--color-button-yellow)"
                  :class="[s.button]"
                  :disabled="!canClaim"
                  @click="submit"
                >
                  Claim
                </SkewedButton>
              </template>
            </div>
          </SkewedTooltip>
        </div>
      </SkewedDiv>
    </template>
  </SkewedModal>
</template>

<style module="s" lang="scss">
@import '@/assets/style/mixins.scss';

.select-items-modal {
  max-width: min(75vw, 1100px) !important;
  min-height: initial !important;
  padding-bottom: 20px;

  @include mMax(1000px) {
    max-width: 100% !important;
  }
}

.titleWrapper {
  display: flex;
  width: 100%;
  padding-right: 16px;
  @include mMax(550px) {
    flex-direction: column;
    gap: 12px;
  }
}

.title {
  flex: 1;
}

.bloodBalance {
  display: flex;
  align-items: center;
  gap: 14px;
  background: rgba(33, 33, 33, 0.3);
  padding: 10px 13px;
  border-radius: 5px;
  font-size: 22px;
}

.bloodIconHeader {
  height: 36px;
}

.select-items-content {
  padding-bottom: 0px !important;
}

.content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin-bottom: 1px; /* don't show uncessary scroll */
}

.claimRow {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  gap: 43px;
  /* prevent cut off impostor border */
  margin-left: 3px;
  margin-bottom: 3px;
  background: rgba(43, 65, 186, 1);
  padding: 25px;
  border-radius: 21px;
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fill, minmax(172px, 1fr)); */
  /* grid-column-gap: 20px; */
  /* grid-row-gap: 20px; */
  @include mMax(1000px) {
    flex-direction: column;
    height: auto;
    gap: 12px;
  }
}

.addClaimRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 13px;
  font-size: 20px;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  margin-bottom: 12px;
}

.bloodLineAmount {
  align-self: center;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}

.bloodIconInline {
  width: 26px;
}

.trashButtonWrapper {
  position: absolute;
  top: 15px;
  right: 15px;
}

.rowInfo {
  flex: 1;
}
.rowTitle {
  font-size: 24px;
  font-weight: bold;

  @include mMax(1000px) {
    font-size: 16px;
  }
}
.rowDescription {
  font-size: 24px;
  font-weight: bold;

  @include mMax(1000px) {
    font-size: 16px;
  }
}
.rowValue {
  font-size: 44px;
  font-weight: bold;

  @include mMax(1000px) {
    font-size: 24px;
  }
}

.nftSlots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  @include mMax(550px) {
    gap: 24px;
  }
}

.tokenPreview {
  max-width: 172px;
  /* width: 100%; */
  width: 172px;
}

.footer-wrapper {
  padding: 20px 20px 43px 20px;
}

.footer-content {
  position: relative;

  @include mMax(1000px) {
    flex-direction: column;
    gap: 12px;
  }
}

.footer-col {
  /* flex: 1; */
  display: flex;
  align-items: center;
  padding: 19px;
  gap: 12px;
}

.footerSummaryRow {
  display: flex;
  border-radius: 5px;
  background: rgba(33, 33, 33, 0.3);

  @include mMax(750px) {
    flex-wrap: wrap;
  }
}

.footerColBlood {
  flex: 1;
  padding-right: 38px;
  .value {
    font-size: 22px;
  }
}

.footerColReceive {
  background: rgba(33, 33, 33, 0.3);
  border-radius: 5px;
  align-self: stretch;
  padding-left: 28px;
  padding-right: 28px;
  @include mMax(750px) {
    flex: 1;
  }
}
.claimReceiveImg {
  max-height: 44px;
  margin: -4px 0;
}

.notEnoughBloodWrapper {
  display: grid;
  place-items: center;
}
.notEnoughBlood {
  background-color: #ff5b5b;
  padding: 3px 24px;
  color: white;
  border-radius: 15px 15px 0 0;
  font-size: 16px;
  text-align: center;
  max-width: 90%;
}

.label {
  flex: 1;
  font-size: 22px;
  font-weight: bold;

  @include mMax(1000px) {
    font-size: 16px;
  }
}

.value {
  font-size: 28px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;

  @include mMax(1000px) {
    font-size: 18px;
  }
}

.ufo-img {
  height: 50px;
}

.footer-text {
  margin-bottom: 18px;
  padding-top: 20px;
  font-size: 12px;
}

.cards-container {
  padding-top: 18px;
  p {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .cards-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}

.button-wrapper {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.claimButtonWrapper {
  position: absolute;
  right: 0;
  bottom: -70px;
  display: flex;
  gap: 16px;
}
.button.button {
  font-weight: bold;
  font-size: 20px;
  padding-right: 46px;
  padding-left: 46px;

  .secondary {
    color: white;
  }

  span {
    text-transform: capitalize;
  }
}

.buttonSecondary.buttonSecondary {
  color: white;
}

.buttonIcon.buttonIcon {
  width: 45px;
  height: 45px;
  padding: 11px;
  font-size: 31px;
  font-weight: bold;
}

.buttonIcon.buttonIcon {
  padding-bottom: 13px;
  padding-right: 12px;
  padding-left: 9px;
}

.buttonRemove.buttonRemove {
  color: white;
}

.select-count {
  &:before {
    content: '(';
  }
  &:after {
    content: ')';
  }

  margin-left: 0.3em;
}

@media (max-width: 1060px) {
  .cards-container .cards-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .button-wrapper {
    flex-direction: column;
    .button {
      max-width: 100%;
    }
  }
}

@media (max-width: 470px) {
  .cards-container .cards-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .claimRow {
    padding-top: 74px;
  }
  .claimButtonWrapper {
    position: initial;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 24px;
  }
  .select-items-modal {
    :global(.top-wrapper) :global(.icon) {
      align-self: flex-start;
    }
  }
}
</style>
