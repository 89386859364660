'use strict';

// Specified state transitions per particular actions.
export default {
  updateJwtChallenge(state, challenge) {
    state.challenge = challenge;
  },

  updateJwtLogin(state, upd) {
    state.authToken = upd.login.authToken;
    state.refreshToken = upd.login.refreshToken;
    state.tokenAddress = upd.address;
  },

  updateJwtVerify(state, verify) {
    state.verificationAddress = verify.payload.address;
  }
};
