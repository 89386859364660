<template>
  <div class="input-parent">
    <textarea v-if="isTextArea" :placeholder="placeholder" @input="handleInput" @focus="handleFocus" @blur="handleBlur"
      maxlength="5000" class="textInputField textArea" :id="id" />
    <input v-else :type="type" class="textInputField" @input="handleInput" @focus="handleFocus" @blur="handleBlur"
      maxlength="256" :placeholder="placeholder" />
    <label class="label-name" :class="{ 'focused': isFocused }">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    isTextArea: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },

  emits: ['update:modelValue'],

  data() {
    return {
      content: this.modelValue,
      isFocused: false
    };
  },

  methods: {
    handleInput(e) {
      this.$emit('update:modelValue', e.target.value);

      const hasAutofillText = e.target.value !== '';

      this.isFocused = hasAutofillText || this.isFocused;
    },
    handleFocus() {
      this.isFocused = true;
    },
    handleBlur(e) {
      this.isFocused = e.target.value ? true : false;
    }
  },

  mounted() {
    this.isFocused = true;
    this.isFocused = this.content !== '';
  }
};
</script>

<style scoped>
.textInputField {
  font-size: 14px;
  height: 44px;
  width: 100%;
  padding: 20px 12px 8px 12px;
  border-radius: 4px;
  color: var(--text-color);
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0);
  transition: border 200ms ease;
}

.textInputField::placeholder {
  color: white;
}

.textInputField:hover {
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.textInputField:focus {
  outline: none;
  border: 1px solid rgba(255, 255, 255, 1);
}

.textInputField:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #6E84CD inset;
  -webkit-text-fill-color: #ffffff;
  border: 1px solid #6E84CD;
}

.textInputField:-webkit-autofill:hover {
  border: 1px solid #A8B4E2;
}

.textInputField:-webkit-autofill:focus {
  border: 1px solid rgba(255, 255, 255, 1);

}

.textArea {
  height: auto !important;
}

.input-parent {
  position: relative;
}

.label-name {
  position: absolute;
  top: 12px;
  left: 12px;
  pointer-events: none;
  transition: 0.2s ease all;
}

.label-name.focused {
  top: 7px;
  font-size: 10px;
  opacity: 0.5;
}

.textInputField:-webkit-autofill~.label-name {
  top: 7px;
  font-size: 10px;
  opacity: 0.5;
}
</style>
