<template>
  <div class="root">
    <div class="label" :class="{ selected: selected === 'one' }">{{ label1 }}</div>
    <SkewedDiv
      :borderRadius="6"
      :skew="[0, 0, 0, [1, 4]]"
      :background="'white'"
      opacity="10%"
      class="toggle"
    >
      <div class="toggle-box">
        <SkewedDiv
          :borderRadius="5"
          :skew="[0, 0, 0, [1, 3]]"
          :background="toggleColor"
          class="toggle-highlight"
          :style="highlightStyles"
        />
        <button
          class="toggle-button super-button"
          :class="{ selected: props.value1 === props.modelValue }"
          ref="button1"
          @click="select(props.value1)"
        >
          <SuperIcon width="24" height="24" />
        </button>
        <button
          class="toggle-button eth-button"
          :class="{ selected: props.value2 === props.modelValue }"
          ref="button2"
          @click="select(props.value2)"
        >
          <EthIcon width="24" height="24" />
        </button>
      </div>
    </SkewedDiv>
    <div class="label" :class="{ selected: selected === 'two' }">{{ label2 }}</div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import EthIcon from '@/components/icons/EthIcon.vue'
import SuperIcon from '@/components/icons/SuperIcon.vue'
import SkewedDiv from '@/components/common/SkewedDiv.vue'
let props = defineProps({
  modelValue: { type: String },
  value1: {},
  value2: {},
  label1: { type: String },
  label2: { type: String },
})
let emit = defineEmits(['update:modelValue'])

let label1 = computed(() => props.label1 || props.value1)
let label2 = computed(() => props.label2 || props.value2)

let button1 = ref(null)
let button1Width = computed(() => button1.value ? button1.value.clientWidth : 0)
let button2 = ref(null)
let button2Width = computed(() => button2.value ? button2.value.clientWidth : 0)
let selected = computed(() => props.modelValue === props.value2 ? 'two' : 'one')
let highlightStyles = computed(() => {
  let widthNum = { two: button2Width.value, one: button1Width.value }[selected.value]

  let translate = { one: 0, two: button1Width.value }[selected.value]
  return {
    width: widthNum ? widthNum + 'px' : '0px',
    transform: `translateX(${translate}px)`
  }
})
let toggleColor = computed(() => ({ two: 'var(--color-button-yellow)', one: 'var(--color-super-blue)' }[selected.value]))

let select = (v) => emit('update:modelValue', v)
</script>

<style scoped lang="scss">
.root {
  display: flex;
  justify-content: center;
  align-items: center;

  > * + * {
    margin-left: 12px;
  }
}
.toggle {
  padding: 5px;
}
.toggle-box {
  display: flex;
  position: relative;
}
.toggle-button::v-deep {
  padding: 6px 12px;
  background: none;
  border: none;
  position: relative;
  cursor: pointer;

  &.eth-button.selected {
    path {
      fill: black;
    }
  }
}
.toggle-highlight {
  position: absolute;
  height: 100%;
  width: 30px;
  transition: transform 0.1s ease-out, width 0.1s ease-out;
}

.label {
  font-size: 2.07142857rem;
  opacity: 67%;

  &.selected {
    opacity: 100%;
  }
}
</style>
