<template>
  <component :is="props.as" class="root">
    <div
      v-if="showBorder"
      class="skew-box blur-box clamp-box"
      :class="[ props.classSkewBox, props.classClampBox]"
      :style="[shapeRootStyles, styles, borderRootStyles, borderStyles]"
    />
    <div 
      class="skew-box blur-box clamp-box"
      :class="[ props.classSkewBox, props.classClampBox]"
      :style="[shapeRootStyles, styles]"
      ref="el"
    />
    <div class="content" :class="props.classContent" :style="contentStyles">
      <slot name="default" />
    </div>
  </component>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue';
import { useResizeObserver } from '@vueuse/core';

let props = defineProps({
  // 4 numbers to adjust skew of corners
  // Example: [0, -10, 10, 30]
  // corner points:
  // x1,y1 ----- x2,y2
  // |            |
  // x4,y4 ----- x3,y3
  skew: {
    type: Array,
    default() {
      return [0, 0, 0, 0];
    }
  },
  // border radius is multiplied by 2
  // for compatability with legacy implementation
  // Use css to set going forward.
  borderRadius: { type: Number },
  opacity: { type: [String, Number] },
  classContent: { type: String },
  classSkewBox: { type: String },
  classClampBox: { type: String }, // ledgacy class
  background: { type: String },
  as: {
    type: String,
    default() {
      return 'div';
    }
  },
  clipContent: { type: Boolean, default: false },
  // legacy props for backwards compat
  // Use css to define outlines or dropshadows for this
  borderColor: { type: String, default: '' },
  borderWidth: { type: String, default: '' },
  borderOpacity: { type: [String, Number], default: '' },
  borderOffset: { type: Array }, // [xOffxet, yOffset]
});

let el = ref(null)

function adj(m) { // Compute the adjugate of m
  return [
    m[4]*m[8]-m[5]*m[7], m[2]*m[7]-m[1]*m[8], m[1]*m[5]-m[2]*m[4],
    m[5]*m[6]-m[3]*m[8], m[0]*m[8]-m[2]*m[6], m[2]*m[3]-m[0]*m[5],
    m[3]*m[7]-m[4]*m[6], m[1]*m[6]-m[0]*m[7], m[0]*m[4]-m[1]*m[3]
  ];
}
function multmm(a, b) { // multiply two matrices
  let c = Array(9);
  for (let i = 0; i != 3; ++i) {
    for (let j = 0; j != 3; ++j) {
      let cij = 0;
      for (let k = 0; k != 3; ++k) {
        cij += a[3*i + k]*b[3*k + j];
      }
      c[3*i + j] = cij;
    }
  }
  return c;
}
function multmv(m, v) { // multiply matrix and vector
  return [
    m[0]*v[0] + m[1]*v[1] + m[2]*v[2],
    m[3]*v[0] + m[4]*v[1] + m[5]*v[2],
    m[6]*v[0] + m[7]*v[1] + m[8]*v[2]
  ];
}
function basisToPoints(x1, y1, x2, y2, x3, y3, x4, y4) { // map basis to these points
  let m = [
    x1, x2, x3,
    y1, y2, y3,
     1,  1,  1
  ];
  let v = multmv(adj(m), [x4, y4, 1]);
  return multmm(m, [
    v[0], 0, 0,
    0, v[1], 0,
    0, 0, v[2]
  ]);
}
// corner points:
// x1,y1 ----- x2,y2
// |            |
// x3,y3 ----- x4,y4
function general2DProjection(
  x1s, y1s, x1d, y1d,
  x2s, y2s, x2d, y2d,
  x3s, y3s, x3d, y3d,
  x4s, y4s, x4d, y4d
) {
  let s = basisToPoints(x1s, y1s, x2s, y2s, x3s, y3s, x4s, y4s);
  let d = basisToPoints(x1d, y1d, x2d, y2d, x3d, y3d, x4d, y4d);
  return multmm(d, adj(s));
}

function getTransform2d(width, height, x1r, y1r, x2r, y2r, x3r, y3r, x4r, y4r) {
  let w = width 
  let h = height
  let x1 = x1r
  let y1 = y1r
  let x2 = w - x2r
  let y2 = y2r
  let x3 = x3r
  let y3 = h - y3r
  let x4 = w - x4r
  let y4 = h - y4r
  let t = general2DProjection(0, 0, x1, y1, w, 0, x2, y2, 0, h, x3, y3, w, h, x4, y4);
  for(let i = 0; i != 9; ++i) t[i] = t[i]/t[8];
  t = [t[0], t[3], 0, t[6],
       t[1], t[4], 0, t[7],
       0   , 0   , 1, 0   ,
       t[2], t[5], 0, t[8]];
  t = "matrix3d(" + t.join(", ") + ")";
  return t
}

let computePolygon = (skews = []) => {
  let [x1, y1, x2, y2, x3, y3, x4, y4] = Array(8).fill(0);
  let c = { x1, y1, x2, y2, x3, y3, x4, y4 };

  for (let [i, skew] of skews.entries()) {
    if (Array.isArray(skew)) {
      c[`x${i + 1}`] = Math.abs(skew[0]);
      c[`y${i + 1}`] = Math.abs(skew[1]);
    } else {
      let clockwise = i % 2 === 0 ? 'x' : 'y';
      let counterClockwise = i % 2 === 0 ? 'y' : 'x';
      let direction = Math.sign(skew) > 0 ? clockwise : counterClockwise;
      c[`${direction}${i + 1}`] = Math.abs(skew);
    }
  }

  return `polygon(calc(0% + ${c.x1}px) calc(0% + ${c.y1}px), calc(100% - ${c.x2}px) calc(0% + ${c.y2}px), calc(100% - ${c.x3}px) calc(100% - ${c.y3}px), calc(0% + ${c.x4}px) calc(100% - ${c.y4}px))`;
};

let clipPath = computed(() => props.clipPath ?? computePolygon(props.skew));

const styles = computed(() => ({
  background: props.background,
}));

const contentStyles = computed(() => ({
  // clip path mimics 3d transform to clip content if necessary
  clipPath: props.clipContent ? clipPath.value : ''
}));


let elWidth = ref(0)
let elHeight = ref(0)

useResizeObserver(el, (entries) => {
  const entry = entries[0]
  const {width, height} = entry.contentRect
  elWidth.value = width
  elHeight.value = height
})

let transform = computed(() => {
  let [x1, y1, x2, y2, x3, y3, x4, y4] = Array(8).fill(0);
  let c = { x1, y1, x2, y2, x3, y3, x4, y4 };

  for (let [i, skew] of props.skew.entries()) {
    if (Array.isArray(skew)) {
      c[`x${i + 1}`] = Math.abs(skew[0]);
      c[`y${i + 1}`] = Math.abs(skew[1]);
    } else {
      let clockwise = i % 2 === 0 ? 'x' : 'y';
      let counterClockwise = i % 2 === 0 ? 'y' : 'x';
      let direction = Math.sign(skew) > 0 ? clockwise : counterClockwise;
      c[`${direction}${i + 1}`] = Math.abs(skew);
    }
  }
  
  if (!elWidth.value && !elHeight.value) {
    return null
  }

  return getTransform2d(elWidth.value, elHeight.value, c.x1, c.y1, c.x2, c.y2, c.x4, c.y4, c.x3, c.y3)
})

const shapeRootStyles = computed(() => { 
  return ({
    ...(transform.value && {
      transform: transform.value,
    }),
    ...(props.borderRadius && { borderRadius: `${props.borderRadius * 2}px` }),
    opacity: props.opacity
  }) 
});

let showBorder = computed(() => !!props.borderWidth || !!props.borderOffset);
const borderRootStyles = computed(() => {
  if (props.borderOffset) {
    let [x, y] = props.borderOffset || [];

    return {
      top: `${parseFloat(y) * -1}px`,
      right: `${parseFloat(x) * -1}px`,
      bottom: `${parseFloat(y)}px`,
      left: `${parseFloat(x)}px`,
      opacity: props.borderOpacity
    };
  }

  return {
    top: `-${props.borderWidth}`,
    right: `-${props.borderWidth}`,
    bottom: `-${props.borderWidth}`,
    left: `-${props.borderWidth}`,
    opacity: props.borderOpacity
  };
});
const borderStyles = computed(() => ({
  background: props.borderColor
}));

</script>

<style scoped lang="scss">
.root {
  position: relative;
  border: none;
  background: transparent;
  isolation: isolate;
}
.content {
  position: relative;
  width: 100%;
}
.skew-box {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  transform-origin: 0% 0% 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 1px solid transparent;
}
</style>
