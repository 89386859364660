<template>
  <Ethereum :callback="initialize" />
  <div class="home">
    <Header :is-home="isHome"/>
    <CountdownArea id="countdown" />
    <WhatIsArea id="what-is" />
    <InfoArea id="info" />
    <TeaserArea id="teaser" />
    <GenesisDrop v-if="false" id="genesisdrop" />
    <Roadmap id="roadmap" />
    <TeamArea id="team" />
    <Gameplay v-if="false" id="gameplay" />
    <Questions id="questions" />
    <Community id="community" />
    <Footer id="footer" />
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, provide, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Ethereum from '@/components/common/Ethereum.vue';
import Header from '@/pages/layout/Header.vue';
import {
  Community,
  CountdownArea,
  Footer,
  Gameplay,
  GenesisDrop,
  Questions,
  Roadmap,
  TeamArea,
  TeaserArea,
  InfoArea,
  WhatIsArea,
} from './components';

const store = useStore();
const router = useRouter();

const windowSize = ref({ width: window.innerWidth, height: window.innerHeight });

provide('windowSize', windowSize);

const isLoggedIn = computed(() => store.state.ethers.address);
const isHome = computed(() => router.currentRoute.value.path === '/');
const buttonMsg = computed(() => 'MINT');

const initialize = async () => {};

const onResize = () => {
  windowSize.value = { width: window.innerWidth, height: window.innerHeight };
};

onMounted(() => {
  window.addEventListener('resize', onResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});
</script>

<style lang="scss">

// .home {
//   background-color: #412A51;
// }

.home {
  background-color: #1E1226;
}

// #header {
//   z-index: 4;
// }
/*
For debugging purposes:

* {
    outline: 1px solid red;
    opacity: 1 !important;
    visibility: visible !important;
}

*/
</style>
