<script setup>
import FAQCell from '@/pages/faq/components/FAQCell.vue';
import FAQGroup from '@/components/common/FAQGroup.vue';

import { CLAIM_FAQ_CHEST_ITEMS } from '@/constants/faq';

const items = [...CLAIM_FAQ_CHEST_ITEMS];

const props = defineProps({});
</script>

<template>
  <FAQGroup title="Cosmetics Chest" bgVariant="orange">
    <template #graphic>
      <img src="@/images/chest-art.webp" :class="s.graphic" />
    </template>
    <FAQCell
      v-for="({ title, subcontent }, index) in items"
      :key="index"
      :title="title"
      :subcontent="subcontent"
    />
  </FAQGroup>
</template>

<style module="s" lang="scss">
.graphic {
  position: relative;
  left: -14px;
}
</style>
