<script setup>
import { ref, computed } from 'vue';
import SelectItemsModal from './SelectItemsModal.vue';
import TokenCard from './TokenCard.vue';
import {
  IMPOSTOR_TOKEN_TYPE,
  UFO_TOKEN_TYPE,
  PET_TOKEN_TYPE,
  BOX_TOKEN_TYPE,
  CHEST_TOKEN_TYPE,
  PASS_TOKEN_TYPE
} from '@/constants';
import BuyOnGigamart from './BuyOnGigamart.vue';

const props = defineProps({
  items: { type: Array, default: () => [] },
  token: { type: Object, default: () => ({}) },
  tokenType: String,
  defaultTitle: String,
  canSelectItem: { type: Function, default: () => true },
  disabled: { type: Boolean }
});
const emit = defineEmits(['select']);

let showClaimModal = ref(false);

const itemIsEligible = item => {
  return props.canSelectItem(item);
};
const submitClaimItems = ({ nftIds = [], nfts = [] } = {}) => {
  emit('select', { nftId: nftIds[0], nft: nfts[0] });
  showClaimModal.value = false;
};
const handleTokenCardClick = () => {
  if (props.disabled) return;

  showClaimModal.value = true;
};
const text = {
  [IMPOSTOR_TOKEN_TYPE]: {
    title: 'Select Impostor'
  },
  [UFO_TOKEN_TYPE]: {
    title: 'Select UFO'
  },
  [PET_TOKEN_TYPE]: {
    title: 'Select Pet'
  },
  [BOX_TOKEN_TYPE]: {
    title: 'Select Box'
  },
  [CHEST_TOKEN_TYPE]: {
    title: 'Select Chest'
  },
  [PASS_TOKEN_TYPE]: {
    title: 'Select Pass'
  }
};
const tokenText = computed(() => text[props.tokenType]);
</script>

<template>
  <TokenCard
    :token="token"
    :tokenType="tokenType"
    :isSelected="!!token?.tokenId"
    :class="s.tokenSlot"
    :classCard="s.tokenSlotCard"
    :showTitle="true"
    :defaultTitle="defaultTitle"
    :key="token.tokenId"
    @click="handleTokenCardClick()"
    v-bind="$attrs"
  />

  <SelectItemsModal
    v-if="showClaimModal"
    :items="items"
    @exit="showClaimModal = false"
    @submit="submitClaimItems"
    :title="tokenText.title"
    :actionText="tokenText.title"
    :canSelectItem="itemIsEligible"
    singleSelect
  >
    <template #tokenCards>
      <BuyOnGigamart :tokenType="props.tokenType" />
    </template>
  </SelectItemsModal>
</template>

<style module="s" lang="scss">
.tokenSlotCard {
  &:hover {
    box-shadow: 0 0 0 2px yellow;
  }
}
</style>
