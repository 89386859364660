<template>
  <div class="wrapper">
    <div class="teaser-area">
      <div class="teaser-area__background" />
      <div class="teaser-area__content">
        <SkewedDiv
          :border-radius="playerSizeCoeff * 2"
          :clip-path="`polygon(0% 0%, 100% ${playerSizeCoeff}px, calc(100% - ${playerSizeCoeff}px) 100%, ${playerSizeCoeff}px 100%)`"
          class-content="teaser-area__video-player-content"
          class-skew-box="teaser-area__video-player-skew-box"
          class="teaser-area__video-player"
        >
          <div class="teaser-area__video-player-inner">
            <iframe
              :src="selectedVideo?.video"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              class="teaser-area__video-player-iframe"
              id="teaser-area__video-player-iframe"
            />
          </div>

        </SkewedDiv>
        <Carousel
          v-if="previews.length > 1"
          :items-to-show="numberPreviews"
          :style="{ width: `${64 + numberPreviews * 150 + (numberPreviews - 1) * 24}px` }"
          class="teaser-area__carousel"
          snap-align="start"
        >
          <Slide
            v-for="slide in previews"
            :key="slide"
          >
            <div
              class="teaser-area__carousel-item"
              :class="{ 'selected': slide.selected }"
              :style="{ 'background-image': `url(${slide.img})` }"
              @click="selectVideo(slide)"
            />
          </Slide>
          <template #addons="{ slidesCount }">
            <Navigation v-if="slidesCount > MAX_PREVIEWS" />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script setup>
import '@/assets/teaser/carousel.css';
import { computed, inject, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { SkewedDiv } from '@/components/common';

const store = useStore();

const windowSize = inject('windowSize');

const MAX_PREVIEWS = 4;

const previews = ref([
  {
    img: require('@/assets/teaser/teaser_000.png'),
    video: 'https://www.youtube.com/embed/lqxmmhJKQ8s',
  },
  {
    img: require('@/assets/teaser/teaser_005.webp'),
    video: 'https://www.youtube.com/embed/-ASMfcfMw4c',
  },
]);

const selectedVideo = ref(previews.value[0]);

const numberPreviews = computed(() => previews.value.length < MAX_PREVIEWS ? previews.value.length : MAX_PREVIEWS);

const playerSizeCoeff = computed(() => windowSize.value.width > 640 ? 16 : 8);

const selectVideo = (previewItem) => {
  previews.value = previews.value.map((item) => {
    item.selected = previewItem.video === item.video;
    return item;
  });
  selectedVideo.value = previewItem;
};

onMounted(() => {
  previews.value[0].selected = true;
});
</script>

<style scoped lang="scss">
@import '../../../assets/style/mixins.scss';

.teaser-area {
  width: 100%;
  position: relative;

  &__background {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100% + 72px);
    background: #1E1226;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-image: url('~@/assets/teaser-area-bg.webp');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top center;
    }
  }

  &__content {
    padding: 24px 48px 48px;
    @include flex(column);
  }

  &__video-player {
    width: 100%;
    max-width: 1020px;
    margin-bottom: 64px;

    :deep(.teaser-area__video-player-skew-box) {
      background: linear-gradient(93.79deg, #849BE6 22.5%, #2E4EB6 88.94%);
    }

    :deep(.teaser-area__video-player-content) {
      padding: 32px 48px 24px;
      position: relative;
    }

    :deep(.teaser-area__video-player-inner) {
      position: relative;
      width: 100%;
      padding-top: calc(100% * (9 / 16));
    }

    &-iframe {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 32px;
      overflow: hidden;
    }
  }

  &__carousel {

    &-item {
      width: 150px;
      height: 94px;
      box-sizing: content-box;
      border-radius: 10px;
      @include flex();
      background-size: cover;
      overflow: hidden;

      &:hover {
        cursor: pointer;
      }

      &.selected {
        border: 2px solid #2446B4;
      }
    }
  }
}

@include mediaOnlyScreenMax(1050px) {
  .teaser-area {
    &__carousel {
      display: none;
    }
  }
}

@include mediaOnlyScreenMax(640px) {
  .teaser-area {

    &__background {
      height: calc(100% + 36px);
    }

    &__content {
      padding: 24px;
    }

    &__video-player {

      :deep(.teaser-area__video-player-content) {
        padding: 16px 24px 12px;
      }

      &-iframe {
        border-radius: 16px;
      }
    }
  }
}
</style>
