// Imports.
import { createRouter, createWebHistory } from 'vue-router';

// Page imports.
import Claim from '../pages/claim/Claim.vue';
import CheckEligibility from '../pages/claim/CheckEligibility.vue';
import Blood from '../pages/blood/Blood.vue';
import Home from '../pages/home/Home.vue';
import Faq from '../pages/faq/Faq.vue';
import Nfts from '../pages/nfts/Nfts.vue';
import Terms from '../pages/terms/Terms.vue';
import GameTerms from '../pages/terms/GameTerms.vue';
import NFTTerms from '../pages/terms/NFTTerms.vue';
import NotFound from '../pages/not-found/NotFound.vue';
import Privacy from '../pages/privacy/Privacy.vue';
import Provenance from '../pages/provenance/Provenance.vue';
import MintIntegration from '../pages/mint/MintIntegration.vue';
import Mint from "../pages/mint/Mint.vue"
import MyItems from '../pages/my-items/MyItems.vue';
import ImpostorDetail from '../pages/my-items/ImpostorDetail.vue';
import VoxelDetail from '../pages/my-items/VoxelDetail.vue';
import AccountLogin from '../pages/account/Login.vue';
import AccountSignUp from '../pages/account/SignUp.vue';
import AccountSettings from '../pages/account/Settings.vue';

// meta information used throughout site
const globalMeta = {
  title: "Impostors",
  metaTags: [
    {
      property: 'og:title',
      content: 'Impostors'
    },
    {
      property: 'twitter:title',
      name: 'Impostors'
    },
    {
      name: 'twitter:description',
      content: 'Impostors is a play and earn social deduction game'
    },
    {
      name: 'description',
      content: 'Impostors is a play and earn social deduction game'
    },
    {
      property: 'og:description',
      content: 'Impostors is a play and earn social deduction game'
    },
    {
      name: 'keywords',
      content: 'nft, defi, blockchain'
    },
    {
      property: 'og:image',
      content: '/images/android-chrome-384x384.png'
    },
    {
      property: 'og:url',
      content: 'https://impostors.gg'
    },
    {
      name: 'twitter:image',
      content: '/images/android-chrome-384x384.png'
    },
  ]
};

// Create routes.
const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home,
//     meta: globalMeta,
//   },
  {
    path: '/',
    name: 'Home',
    component: AccountLogin,
    meta: globalMeta
  },
//   {
//     path: '/blood',
//     name: 'Blood',
//     component: Blood,
//     meta: globalMeta,
//   },
//   {
//     path: '/claim',
//     name: 'Claim',
//     component: Claim,
//     meta: globalMeta,
//   },
//   {
//     path: '/check-eligibility',
//     name: 'CheckEligibility',
//     component: CheckEligibility,
//     meta: globalMeta,
//   },
  {
    path: '/faq',
    name: 'FAQ',
    component: Faq,
    meta: globalMeta,
  },
//   {
//     path: '/nfts',
//     name: 'NFTs',
//     component: Nfts,
//     meta: globalMeta,
//   },
//   {
//     path: '/my-items',
//     name: 'MyItems',
//     component: MyItems,
//     meta: globalMeta,
//   },
//   {
//     path: '/impostor/:id',
//     name: 'ImpostorDetail',
//     component: ImpostorDetail,
//     meta: globalMeta,
//   },
//   {
//     path: '/voxel/:id',
//     name: 'VoxelDetail',
//     component: VoxelDetail,
//     meta: globalMeta
//   },
//   {
//     path: '/mint',
//     name: 'Mint',
//     component: Mint,
//     meta: globalMeta,
//   },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: globalMeta,
  },
  {
    path: '/account',
    name: 'AccountLogin',
    component: AccountLogin,
    meta: globalMeta
  },
  {
    path: '/account/settings',
    name: 'AccountSettings',
    component: AccountSettings,
    meta: globalMeta
  },
  {
    path: '/signup',
    name: 'AccountSignUp',
    component: AccountSignUp,
    meta: globalMeta
  },
//   {
//     path: '/provenance',
//     name: 'Provenance',
//     component: Provenance,
//     meta: globalMeta,
//   },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: globalMeta,
  },
  {
    path: '/game-terms',
    name: 'GameTerms',
    component: GameTerms,
    meta: globalMeta,
  },
  {
    path: '/nft-terms',
    name: 'NFTTerms',
    component: NFTTerms,
    meta: globalMeta,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    meta: globalMeta,
  }
];

// Set up the Vue router to operate in web history mode.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


// This callback runs before every route change, including on page load.
// see: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});


export default router;
