<template>
  <div class="wrapper">
    <div class="roadmap-area">
      <AreaHeader
        :header="HOME_ROADMAP_HEADER"
        hide-subcontent-for-small-screen
      />
      <div class="roadmap-area__phases">
        <RoadmapPhase
          v-for="({ background, items }, index) of HOME_ROADMAP_PHASES"
          :key="index"
          :phase-id="index + 1"
          :background="background"
          :items="items"
          :class="`roadmap-area__phase-${index + 1}`"
          class="roadmap-area__phase"
        >
          <template #impostor>
            <div
              :class="`roadmap-area__phase-impostor-${index + 1}`"
              class="roadmap-area__phase-impostor"
            />
          </template>
        </RoadmapPhase>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { AreaHeader, RoadmapPhase } from '.';
import { HOME_ROADMAP_HEADER, HOME_ROADMAP_PHASES } from '@/constants';

const store = useStore();
</script>

<style scoped lang="scss">
@import '../../../assets/style/mixins.scss';

$phase-impostors:
  '1' 293px 396px 176px -155px initial,
  '2' 297px 502px -135px initial -24px,
  '3' 293px 396px 181px -147px initial;

.roadmap-area {
  width: 100%;
  @include flex(column);
  overflow: hidden;
  padding-bottom: 58px;

  &__phases {
    width: 100%;
    max-width: 1300px;
    @include flex(column, flex-start, flex-start);
    row-gap: 120px;
    padding: 0 130px 0 160px;
  }

  &__phase {
    position: relative;
    width: 100%;

    &-1 {
      max-width: 900px;
    }

    &-2 {
      max-width: 780px;
      align-self: flex-end;
    }

    &-3 {
      max-width: 880px;
    }

    &-impostor {
      position: absolute;
      z-index: 10;

      @each $impostor, $width, $height, $bottom, $left, $right in $phase-impostors {
        &-#{$impostor} {
          width: $width;
          height: $height;
          bottom: $bottom;
          left: $left;
          right: $right;
          background-image: url('~@/images/roadmap/roadmap-impostor-#{$impostor}.png');
        }
      }
    }
  }
}

@include mediaOnlyScreenMax(1050px) {
  .roadmap-area {

    &__phases {
      row-gap: 36px;
      padding: 0 48px;
    }

    &__phase {
      position: relative;
      width: 100%;
      
      &-1, &-2, &-3 {
        max-width: initial;
      }

      &-impostor {
        display: none;
      }
    }
  }
}

@include mediaOnlyScreenMax(640px) {
  .roadmap-area {
    padding-bottom: 48px;

    &__phases {
      padding: 0 24px;
    }
  }
}
</style>
