'use strict';

// Import our module information.
import actions from './actions';
import mutations from './mutations';
import { BigNumber } from 'ethers'

// Prepare state.
const state = {
  shopConfig: null,
  currentPrice: null,
  merkleProofs: [],
  owned: [],
  minted: null,
  nextPrice: null,
  soldCount: 0,
  balances: {
    tokenBalance: BigNumber.from(0),
    hasSuper: false,
    tokenAllowance: BigNumber.from(0),
    hasAllowedTokenAccess: false,
    ethBalance: BigNumber.from(0),
    hasEth: false,
  }
};

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
