<template>
	<TextInput v-model="input" :isTextArea="false" label="Username" @input="validateInput" />
	<!-- <div
		v-if="errors.name"
		class="error-message"
	  >
		{{ errors.name }}
	  </div> -->
</template>
  
<script setup>
import { ref } from 'vue';
import { accounts } from '@/account';
import TextInput from '@/components/ui/TextInput.vue';

let input = ref('');
const { validateNameField, errors } = accounts.useFormValidation();
const validateInput = () => {
	validateNameField('name', input.value);
};
</script>
  