<template>
  <div class="notFound">
    <div class="contentWrapper">
        <img src="https://impostors.s3.amazonaws.com/assets/404.webp" />
        <p>That’s a super sus url u just entered. Are u sure u belong here?</p>
        <p>Head back home!</p>
        <SkewedButton
            class="button"
            :borderRadius="6"
            background="var(--color-button-yellow)"
            @click="routeHome"
            :skew="[0, -1, 0, [-6, -4]]">
          <span>BACK HOME</span>
        </SkewedButton>
    </div>
    <img class="character" src="https://impostors.s3.amazonaws.com/assets/impostor-character-404.webp">
  </div>
</template>
<script setup>
import SkewedButton from '@/components/common/SkewedButton.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const routeHome = () => {
  router.push({name: 'Home'});
}
</script>
<style scoped lang="scss">
.notFound {
  background-image: url('https://impostors.s3.amazonaws.com/assets/space-background.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .contentWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 0px 24px;
    
    img {
        max-width: 400px;
        width: 50vw;
        height: auto;
        object-fit: contain;
    }

    p {
      text-align: center;
      font-weight: bold;
      font-size: 30px;
      line-height: 41px;
      max-width: 600px;
    }

    .button {
      width: 100%;
      max-width: 200px;

      span {
        font-weight: bold;
        font-size: 20px;
        color: #212121;
      }
    } 
  }

  .character {
    position: absolute;
    max-width: 330px;
    width: 30vw;
    height: auto;
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 620px) {
  .notFound .contentWrapper {
    height: fit-content;
    img {
      max-width: 240px;
      width: 100%;
    }

    p {
      font-size: 5vw;
      line-height: initial;
    }

    .button {
      margin-bottom: 80px;
    }
  }

  .notFound .character {
    max-width: 200px;
    width: 100%;
  }
}
</style>