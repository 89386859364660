<template>
  <div class="wrapper">
    <div class="team-area">
      <div class="team-area__team">
        <SkewedDiv
          :border-radius="16"
          clip-path="polygon(0% 32px, 100% 0%, 100% 100%, 0% 100%)"
          class-content="team-area__team-background-content"
          class-skew-box="team-area__team-background-skew-box"
          class="team-area__team-background"
        />
        <AreaHeader
          :header="HOME_TEAM_HEADER"
          class="team-area__team-header"
        />
        <div class="team-area__team-content">
          <div
            v-if="!showCarousel"
            class="team-area__members-list"
          >
            <div
              v-for="(member, index) of HOME_TEAM_MEMBERS"
              :key="index"
              :class="{ 'align-right': index % 2 !== 0 }"
              class="team-area__member"
            >
              <TeamMember
                :member="member"
                class="team-area__member-card"
              />
            </div>
            <div class="team-area__members-list-more">+30 more talented staff</div>
          </div>
          <div
            v-if="showCarousel"
            class="team-area__members-carousel"
          >
            <Carousel>
              <Slide
                v-for="(member, index) of HOME_TEAM_MEMBERS"
                :key="index"
              >
                <TeamMember
                  :member="member"
                  class="team-area__member-card"
                  vertical
                />
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
      <div class="team-area__works">
        <SkewedDiv
          :border-radius="16"
          clip-path="polygon(0% 0%, 100% 0%, 100% calc(100% - 32px), 0% 100%)"
          class-content="team-area__works-background-content"
          class-skew-box="team-area__works-background-skew-box"
          class="team-area__works-background team-area__works-background-big"
          background="#2F4EB6"
        />
        <div class="team-area__works-background team-area__works-background-small" />
        <div class="team-area__works-content">
          <h2>Have worked on</h2>
          <div class="team-area__works-items">
            <div
              v-for="({name, src}, index) of HOME_TEAM_WORKS"
              :key="index"
              :class="{ 'align-right': index % 2 !== 0 }"
              class="team-area__works-item"
            >
              <img
                :class="`team-area__works-item-image ${name}`"
                :src="require(`@/${src}`)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import { SkewedDiv } from '@/components/common';
import { AreaHeader, TeamMember } from '.';
import { HOME_TEAM_HEADER, HOME_TEAM_MEMBERS, HOME_TEAM_WORKS } from '@/constants';

const store = useStore();

const windowSize = inject('windowSize');

const showCarousel = computed(() => windowSize.value.width <= 640);
</script>

<style scoped lang="scss">
@import '../../../assets/style/mixins.scss';

$works:
  'roblox' 160px,
  'farcry' 157px,
  'trover' 196px,
  'gears' 188px,
  'bards' 261px,
  'squad' 162px,
  'amazon' 160px,
  'enjin' 150px,
  'pixar' 175px;

.team-area {
  position: relative;

  &__team {
    position: relative;
    @include flex(column);
    overflow: hidden;

    &-background {
      width: 100%;
      height: calc(100% + 32px);
      position: absolute;
      top: 0;

      :deep(.team-area__team-background-skew-box) {
        background-color: #2F2436;
      }
    }

    &-header {
      margin-top: 36px;
    }

    &-content {
      width: 100%;
      max-width: 1260px;
      padding: 0 24px;
      @include flex(column, flex-start, stretch);
      margin-bottom: 172px;
      margin-top: 36px;
    }
  }

  &__members-list {
    width: 100%;
    max-width: 1260px;
    @include flex(column, flex-start, flex-start);

    &-more {
      align-self: flex-start;
      margin-top: -34px;
      @include DMSans(40px, 700);
      padding: 48px 80px;
      z-index: 1;
    }
  }

  &__member {
    position: relative;
    margin-bottom: -36px;

    &.align-right {
      align-self: flex-end;
    }
  }

  &__members-carousel {

    :deep(.carousel__pagination) {
      margin-top: 15px;
    }

    :deep(.carousel__pagination-button) {
      width: 8px;
      height: 8px;
      background-color: var(--color-white);
    }

    :deep(.carousel__pagination-button--active) {
      width: 20px;
      height: 8px;
      background-color: #2F4EB6;
    }
  }

  &__works {
    width: 100%;
    position: relative;
    z-index: 2;

    &-background {
      position: absolute;
      width: 100%;

      &-big {
        height: calc(100% + 32px);
        top: 0;

        &:after {
          content: '';
          background-image: linear-gradient(to bottom, #2F4EB6 120px, rgba(47, 78, 182, 0) 100%);
          position: absolute;
          width: 100%;
          height: 200px;
          clip-path: polygon(0 0, 100% 16px, 100% 100%, 0 100%);
          top: -16px;
        }
      }

      &-small {
        display: none;
      }
    }

    &-content {
      position: relative;
      @include flex(column);

      h2 {
        @include DMSans(32px, 700);
        margin: 24px auto 12px;
      }
    }

    &-items {
      @include flex(row, center, center, wrap);
      column-gap: 32px;
      row-gap: 12px;
      padding: 0 12px 12px;
    }

    &-item {

      &-image {
        width: 150px;

        @each $name, $width in $works {
          &.#{$name} {
            width: $width;
          }
        }
      }
    }
  }
}

@include mediaOnlyScreenMax(1280px) {
  .team-area {

    &__team {

      &-content {
        padding: 0 16px;
        margin-bottom: 96px;
      }
    }

    &__members-list {
      row-gap: 12px;
      padding-bottom: initial;

      &-more {
        display: none;
      }
    }

    &__member {
      position: relative;
      height: initial;
      margin-bottom: 0;

      &.align-right {
        align-self: flex-end;
      }
    }
  }
}

@include mediaOnlyScreenMax(640px) {
  .team-area {

    &__works {
      width: 100%;
      position: relative;
      z-index: 3;

      &-background {
        position: absolute;
        width: 100%;

        &-big {
          display: none;
        }

        &-small {
          display: flex;
          height: calc(100% + 64px);
          clip-path: polygon(0 32px, 100% 0%, 100% calc(100% - 32px), 0 100%);
          background-color: #2F4EB6;
          top: -32px;
        }
      }

      &-content {
        position: relative;
        @include flex(column);

        h2 {
          @include DMSans(26px, 700);
          margin: 36px auto 24px;
        }
      }

      &-items {
        @include flex(column, flex-start, flex-start);
        column-gap: 32px;
        row-gap: 12px;
        padding: 0 36px 12px;
        width: 100%;
        max-width: 352px;
        position: relative;
      }

      &-item {
        &.align-right {
          align-self: flex-end;
        }

        &-image {
          width: 150px;

          @each $name, $width in $works {
            &.#{$name} {
              width: calc(#{$width} * .8);
            }
          }
        }
      }
    }
  }
}
</style>
