export const IMPOSTORS_SILHOUETTE_BACKGROUND_TYPES = {
  BLUE: 'blue',
  ORANGE: 'orange',
  PINK: 'pink'
};

export const IMPOSTORS_IMAGE_URL = 'https://impostors-genesis.mypinata.cloud/ipfs/QmWz3XTv2zdq8Pz5reEKgMMNPs5kQ8TJXUpJhb2B67aSgD'
export const IMPOSTORS_IMAGE_THUMB_URL = 'https://impostor-small-thumbnails.s3.amazonaws.com'
export const UFO_IMAGE_THUMB_URL = 'https://impostors-ufo-thumbs.s3.amazonaws.com'
export const PET_IMAGE_THUMB_URL = 'https://impostors-pet-thumbs.s3.amazonaws.com'
export const BOX_IMAGE_THUMB_URL = 'https://impostors-box-thumbs.s3.amazonaws.com'
export const CHEST_IMAGE_THUMB_URL = 'https://impostors-chest-thumbs.s3.amazonaws.com'
export const PASS_IMAGE_THUMB_URL = 'https://impostors-pass-thumbs.s3.amazonaws.com'
export const VOXEL_IMAGE_THUMB_URL = 'https://impostors-voxel-thumbs.s3.amazonaws.com';
export const VOXEL_3D_VIEWER = `https://impostors-voxel-viewer.pages.dev`;
export const VOXEL_GLTF_REPO = 'https://impostors-voxel-gltf.s3.amazonaws.com';

export const IMPOSTOR_TOKEN_TYPE = 'impostor';
export const UFO_TOKEN_TYPE = 'ufo';
export const PET_TOKEN_TYPE = 'pet';
export const BOX_TOKEN_TYPE = 'box';
export const CHEST_TOKEN_TYPE = 'chest';
export const PASS_TOKEN_TYPE = 'pass';
export const VOXEL_TOKEN_TYPE = 'voxel';

export const GIGAMART_URL = 'https://gigamart.com/collections/impostors-genesis-aliens'
export const GIGAMART_URL_UFO = 'https://gigamart.com/collections/impostors-ufo'
export const GIGAMART_URL_PET = 'https://gigamart.com/collections/impostors-genesis-pets'
export const GIGAMART_URL_BOX = 'https://gigamart.com/collections/impostors-materials-box'
export const GIGAMART_URL_CHEST = 'https://gigamart.com/collections/impostors-cosmetics-chest'
export const GIGAMART_URL_PASS = 'https://gigamart.com/collections/impostors-genesis-aliens'
export const GIGAMART_URL_VOXEL = 'https://gigamart.com/collections/impostorsvoxel'

export const TOKEN_CONFIG = {
  [IMPOSTOR_TOKEN_TYPE]: {
    GIGAMART_URL: GIGAMART_URL,
    DISPLAY_NAME: 'Genesis Alien',
  },
  [UFO_TOKEN_TYPE]: {
    GIGAMART_URL: GIGAMART_URL_UFO,
    DISPLAY_NAME: 'UFO',
  },
  [PET_TOKEN_TYPE]: {
    GIGAMART_URL: GIGAMART_URL_PET,
    DISPLAY_NAME: 'Pet',
  },
  [BOX_TOKEN_TYPE]: {
    GIGAMART_URL: GIGAMART_URL_BOX,
    DISPLAY_NAME: 'Materials Box',
  },
  [CHEST_TOKEN_TYPE]: {
    GIGAMART_URL: GIGAMART_URL_CHEST,
    DISPLAY_NAME: 'Cosmetics Chest',  
  },
  [VOXEL_TOKEN_TYPE]: {
	GIGAMART_URL: GIGAMART_URL_VOXEL,
	DISPLAY_NAME: 'Voxel'
  },
  [PASS_TOKEN_TYPE]: {
    GIGAMART_URL: GIGAMART_URL_PASS,
    DISPLAY_NAME: 'Beta Pass',
  },
}
