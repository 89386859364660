<template>
  <SkewedDiv
    class="progress-bar_root"
    classContent="progress-bar_content"
    :borderRadius="4"
    :skew="[-25, 0, 4, 0]"
    background="white"
    opacity="20%"
  >
    <SkewedDiv
      class="progress-bar_track"
      :borderRadius="4"
      :skew="[-25, 0, 4, 0]"
      :style="barStyles"
      :background="barBackground"
    ></SkewedDiv>
  </SkewedDiv>
</template>

<script setup>
import { computed } from 'vue'
import SkewedDiv from '@/components/common/SkewedDiv.vue';

let props = defineProps({
  progress: {
    type: Number,
    default: () => 0
  },
  barBackground: { type: String, default: "var(--color-orange)" },
  size: {
    type: String,
    default: 'normal'
  }
})


let barStyles = computed(() => ({
  clipPath: props.progress > 0 ? `polygon(0% 0%, max(${props.progress}%, calc(${props.progress}% + 10px)) 0%, max(calc(${props.progress}% - 4px), calc(${props.progress}% + 10px - 4px)) 100%, 0% 100%)` : `polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)`
}))

</script>

<style scoped lang="scss">
.progress-bar_root {
  height: 55px;
}
.progress-bar_root::v-deep .progress-bar_content {
  height: 100%;
}
.progress-bar_track {
  height: 100%;
}
</style>
