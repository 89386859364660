<template>
  <div class="contentArea">
    <Countdown
      labelColor="#FFFFFF"
      :deadlineDate="revealDate"
      :mainColor="mainColor"
      :secondFlipColor="secondColor"
      :flipAnimation="false"
    />
  </div>
</template>
<script>
import { Countdown } from 'vue3-flip-countdown'
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  components: {
    Countdown,
  },

  setup() {
    const store = useStore();

    const revealDate = computed(() => {
      return new Date(Date.UTC(2022, 1, 16, 20, 0, 0));
    });

    const colors = [
      {main: "#005664", second: "#199AA8",},
      {main: "#5A010C", second: "#C84565",},
      {main: "#005900", second: "#309329",},
    ];

    const mainColor = computed(() => {
      return colors[store.state.login.pageIndex -1].second;
    });

    const secondColor = computed(() => {
      return colors[store.state.login.pageIndex -1].second;
    });
    return { store, revealDate, mainColor, secondColor};
  }
};
</script>
<style scoped lang="scss">
.contentArea {
  position: relative;
  padding-top: 175px;
  z-index: 10;
  place-self: center;
  width: 1000px;

  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 57px;
}

@media (max-width: 1150px) {
  .contentArea {
    padding-top: 175px;
  }
}
@media (max-width: 870px) {
  .contentArea {
    padding-top: 175px;
    width: 559px;
  }
}

@media (max-width: 550px) {
  .contentArea {
    padding-top: 175px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 549px) {
  .contentArea {
    padding-top: 175px;
  }
}
</style>
