'use strict';

// Imports.
import { createStore } from 'vuex';

// Import data modules for the store.
import alert from './alert';
import ethers from './ethers';
import mint from './mint';
import login from './login';
import stake from './stake';
import claim from './claim';
import jwt from './jwt';

// Export the data store using our modules.
export default createStore({
  state: {},
  mutations: {
    checkWalletConnectStore(state) {
      if (localStorage.getItem('walletconnect')) {
        state.walletconnect = JSON.parse(localStorage.getItem('walletconnect'));
      }
	  if (localStorage.getItem('playFab')) {
        state.login.playfab = JSON.parse(localStorage.getItem('playFab'));
      }
    },
    disconnectWalletConnectStore(state) {
      if (localStorage.getItem('walletconnect')) {
        localStorage.removeItem('walletconnect');
        state.walletconnect = null;
      }
	  if (localStorage.getItem('playFab')) {
        localStorage.removeItem('playFab');
        state.login.playfab = null;
      }
    }
  },
  actions: {},
  modules: {
    alert,
    ethers,
    mint,
    stake,
    login,
    claim,
    jwt
  }
});
