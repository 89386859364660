<template>
  <SkewedDiv
    :border-radius="cardSizeCoeff"
    :clip-path="`polygon(0% ${cardSizeCoeff}px, 100% 0%, 100% 100%, ${cardSizeCoeff}px 100%)`"
    class-content="question-card__content"
    :class-skew-box="`question-card__skew-box-${props.question.background}`"
    class="question-card"
  >
    <h2>{{ props.question.title }}</h2>
    <p v-html="props.question.subcontent"></p>
    <router-link
      :to="props.question.to"
      class="question-card__router-link"
    >
      <SkewedButton
        :skew="[[0, 0], [0, 0], [0, 0], [-5, -2]]"
        :borderRadius="5"
        class-content="question-card__button-content"
        class-skew-box="question-card__button-skew-box"
        class="question-card__button"
      >{{ props.question.buttonLabel }}</SkewedButton>
    </router-link>
  </SkewedDiv>
</template>

<script setup>
import { computed, defineProps, inject } from 'vue';
import { SkewedDiv, SkewedButton } from '@/components/common';

const props = defineProps({
  question: { type: Object, required: true }
});

const windowSize = inject('windowSize');

const cardSizeCoeff = computed(() => windowSize.value.width > 640 ? 16 : 8);
</script>

<style lang="scss" scoped>
@import '../../../assets/style/mixins.scss';

$backgrounds:
  'pink' #BA468A,
  'blue' linear-gradient(93.04deg, #415CB5 13.42%, #2445B4 53.05%);

.question-card {

  @each $name, $background in $backgrounds {
    :deep(.question-card__skew-box-#{$name}) {
      background: $background;
    }
  }

  :deep(.question-card__content) {
    padding: 34px 36px 26px;
  }

  &__router-link {
    text-decoration: none;
  }

  &__button {
    width: 200px;
    height: 55px;
    
    :deep(.question-card__button-skew-box) {
      background-color: #F0F907;
    }

    :deep(.question-card__button-content) {
      @include DMSans(20px, 700);
      text-transform: uppercase;
    }
  }

  h2 {
    @include DMSans(36px, 700);
    margin-bottom: 8px;
  }

  p {
    @include DMSans(17px, 400);
    margin-bottom: 24px;
  }
}

@include mediaOnlyScreenMax(640px) {
  .question-card {

    :deep(.question-card__content) {
      padding: 34px 32px 20px;
    }

    &__button {
      width: 156px;
      height: 44px;
      

      :deep(.question-card__button-content) {
        @include DMSans(16px, 700);
      }
    }

    h2 {
      @include DMSans(26px, 700);
    }

    p {
      @include DMSans(14px, 400);
    }
  }
  }
</style>
