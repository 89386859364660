'use strict';

// Imports.
import { jwtService } from '../../services';
import { processError, errMsg } from '../../utility';

// Specified actions for mutating the jwt state.
export default {
  async auth({ dispatch, commit }, { address, errorMessage }) {
    try {
      const response = await jwtService.auth({
        address: address
      });
      if (!response.ok) {
        await processError(errorMessage, false, dispatch);
        return;
      }

      const jwtChallenge = await response.json();
      commit('updateJwtChallenge', jwtChallenge);

      // Catch and notify the user of a purchase error.
    } catch (error) {
      await processError(errMsg(error), false, dispatch);
    }
  },

  async login(
    { dispatch, commit },
    { address, challenge, signature, errorMessage }
  ) {
    try {
      const response = await jwtService.login({
        address: address,
        challenge: challenge,
        signature: signature
      });
      if (!response.ok) {
        await processError(errorMessage, false, dispatch);
        return;
      }

      const jwtLogin = await response.json();
      commit('updateJwtLogin', { address: address, login: jwtLogin });

      // Catch and notify the user of a purchase error.
    } catch (error) {
      console.warn(error);
      await processError(errMsg(error), false, dispatch);
    }
  },

  async verify({ dispatch, commit }, { address, token, errorMessage }) {
    try {
      const response = await jwtService.verify({
        token: token
      });
      if (!response.ok) {
        await processError(errorMessage, false, dispatch);
        return;
      }

      const jwtVerify = await response.json();
      commit('updateJwtVerify', jwtVerify);

      // Catch and notify the user of a purchase error.
    } catch (error) {
      console.warn(error);
      await processError(errMsg(error), false, dispatch);
    }
  }
};
