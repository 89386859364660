<template>
	<Ethereum :callback="initialize" />
	<Header :is-home="false" />
	<div class="nfts-page">
		<div class="nfts-page__background">
			<div class="auth-wall" />
			<div class="auth-floor" />
		</div>
		<div class="nfts-page__genesis">
			<div class="nfts-page__genesis-container">
				<img class="nfts-page__impostor" src="~@/assets/impostor-chilling.webp" />
				<div class="auth-form-wrapper">
					<div v-if="!isImpostorsLoggedIn">
						<BaseForm title="Create Account">
							<EmailField v-model="user.email" />
							<PasswordField v-model="user.password" />
							<PasswordField v-model="user.passwordConfirm" :confirm-only="true" :user="user" label="Confirm Password" />
							<SkewedButton class="button" :border-radius="6" :skew="[0, 0, [-5, -3], 0]" background="yellow"
								:disabled="isSignupButtonDisabled.status" @click="signUpButtonPressed">
								CREATE ACCOUNT
							</SkewedButton>
							<div v-if="isImpostorsError" class="error-message">
								{{ accounts.impostorsErrors() }}
							</div>

							<div v-if="isSignupButtonDisabled.errors" class="warntext">
								{{ isSignupButtonDisabled.errors }}
							</div>
						</BaseForm>

					</div>
					<div v-else class="signup-view">
						<h1>Redirecting to settings</h1>
					</div>

					<AlternatePageButton to="/account" text="Already have an account?" action="Login" />
				</div>
			</div>
		</div>
	</div>
</template>
  
<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import BaseForm from '@/components/ui/BaseForm.vue';
import EmailField from './components/EmailField';
import PasswordField from './components/PasswordField';

import Header from '@/pages/layout/Header.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import SkewedButton from '@/components/common/SkewedButton.vue';

import { accounts } from '@/account';
import { flag } from '@/featureflag';
import AlternatePageButton from '@/components/ui/AlternatePageButton.vue';

let user = ref({
	email: '',
	password: '',
	passwordConfirm: ''
});

const store = useStore();
const router = useRouter();
const { isSignupButtonDisabled, isImpostorsLoggedIn, isImpostorsError } =
	accounts.init(store, user);

const signUpButtonPressed = () => {
	if (!flag.playFabEnabled.value) {
		router.push({ path: `/` });
		return;
	}
	if (!isSignupButtonDisabled.value.status) {
		accounts.impostorsRegister({
			Email: user.value.email,
			Password: user.value.password
		});
	}
};

const initialize = async () => { };

const windowSize = ref({
	width: window.innerWidth,
	height: window.innerHeight
});

const onResize = () => {
	windowSize.value = { width: window.innerWidth, height: window.innerHeight };
};

onMounted(() => {
	window.addEventListener('resize', onResize);
});

onUnmounted(() => {
	window.removeEventListener('resize', onResize);
});

watch(isImpostorsLoggedIn, (curState) => {
	if (curState) {
		router.push({ path: `/account/settings` });
	}
});
</script>

<style scoped lang="scss">
@import '@/assets/style/mixins';

$gradient-blue: linear-gradient(93.61deg, #849be6 -9.65%, #2e4eb6 66.26%);

.nfts-page {
	background-color: var(--color-page-background);
	overflow: hidden;
	padding-bottom: 72px;
	will-change: transform;
	min-height: 100vh;

	&__background {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	&__genesis {
		@include flex(column, flex-start);
		padding-top: 206px;

		&-container {
			@include flex(row, space-between);
			max-width: 750px;
			position: relative;
			width: 100%;
			padding: 0 20px;
		}
	}

	@include mediaOnlyScreenMax(720px) {
		.nfts-page__genesis-container {
			flex-direction: column;
			justify-content: center;
			gap: 20px;
		}

		.nfts-page__impostor {
			order: 1;
		}

		.auth-form-wrapper {
			order: 0;
		}
	}

	.warntext,
	.error-message {
		background: #f11;
		padding: 5px;
	}

	.button {
		font-size: 16px;
		font-weight: bold;
		height: 40px;
		margin-bottom: 6px;
		width: 100%;
	}
}

.auth-form-wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
}

.auth-wall {
	background-image: url('~@/images/auth-wall-short.jpg');
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 500px;
}

.auth-floor {
	background-image: url('~@/images/auth-floor.jpg');
	background-position: center;
	background-repeat: repeat;
	background-size: auto 100%;
	height: 100%;
}
</style>