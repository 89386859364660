<script setup>
import { computed, ref, watch } from 'vue';
import SkewedModal from '@/components/common/SkewedModal.vue';
import SkewedButton from '@/components/common/SkewedButton.vue';
import TokenCard from '@/components/common/TokenCard.vue';
import SkewedDiv from '../../../components/common/SkewedDiv.vue';

const props = defineProps({
  items: { type: Array, default: () => [] },
  isSubmitting: { type: Boolean },
  isBloodApproved: { type: Function },
  pools: { type: Object },
  title: { type: String },
  actionText: { type: String },
  selectAllText: { type: String, default: 'Select All' },
  canSelectItem: { type: Function, default: () => true },
  bloodPerItem: { type: [Number, String] },
  bloodBalance: { type: [Number] }
});
const emit = defineEmits(['exit', 'submit', 'approve']);

let { format } = new Intl.NumberFormat('en-US', {maximumFractionDigits: 2});
let bloodBalanceFormat = computed(() => format(Math.floor(parseFloat(props.bloodBalance) * 100) / 100));

let cart = ref(props.items);

let bloodTotalNum = computed(
  () => props.items.length * parseFloat(props.bloodPerItem)
);
let bloodTotal = computed(() => format(bloodTotalNum.value));

let hasEnoughBlood = computed(() => props.bloodBalance >= bloodTotalNum.value);

let hasEnoughApproved = computed(() => props.isBloodApproved(parseFloat(bloodTotalNum.value).toFixed(2)))

const exit = () => {
  emit('exit');
};

const approve = () => {
  emit('approve');
};

let submit = () => {
  emit('submit', {
    nfts: cart.value,
    nftIds: cart.value.map(t => t.tokenId)
  });
};
</script>

<template>
  <SkewedModal
    :borderRadius="16"
    :skew="[[0, -10], 0, [-6, 0], [8, 0]]"
    background="var(--color-bg-modal)"
    @exit="exit"
    :classSkewedDiv="s['select-items-modal']"
    :classModalContent="s['select-items-content']"
  >
    <template #title>
      <div :class="s.titleWrapper">
        <span :class="s.title">{{ title }}</span>
        <div :class="s.bloodBalance">
          <img
            :class="s.bloodIconHeader"
            src="@/images/blood-icon-large.webp"
          />
          <span>{{ bloodBalanceFormat }}</span>
        </div>
      </div>
    </template>

    <template #content>
      <div :class="s['content-wrapper']">
        <!-- unstaked container -->
        <div
          v-for="(item, index) in cart"
          :class="s['token-row']"
          :key="item.tokenId"
        >
          <TokenCard
            :token="item"
            :isSelected="true"
            :class="s.tokenPreview"
            :showTitle="false"
          />

          <div :class="s.rowInfo">
            <p :class="s.rowTitle">IMPOSTOR #{{ item.tokenId }}</p>
            <p :class="s.rowDescription">$BLOOD required</p>
            <p :class="s.rowValue">{{ format(props.bloodPerItem) }}</p>
          </div>

          <div>
            <SkewedButton
              v-if="cart.length > 1"
              :borderRadius="6"
              :skew="[0, 0, [-5, -3], 0]"
              background="#2B40BA"
              :class="[s.button, s.buttonRemove]"
              :disabled="isSubmitting"
              @click="cart.splice(index, 1)"
              >REMOVE</SkewedButton
            >
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <SkewedDiv
        background="#5871FC"
        :borderRadius="6"
        :skew="[0, 0, 6, -3]"
        :class="s['footer-wrapper']"
        :classContent="s['footer-content']"
      >
        <div :class="s['footer-col']">
          <p :class="s.label">$BLOOD TOTAL</p>
          <p :class="s.value">{{ bloodTotal }}</p>
        </div>

        <div :class="s['footer-col']">
          <p :class="s.label">Will receive</p>
          <p :class="s.value">
            <img
              :class="s['ufo-img']"
              src="~@/images/ufo-art-02.webp"
              alt="Unidentified Fyling Object"
            />
            <span v-if="cart.length > 1">
              {{ cart.length }}
              x
            </span>
            UFO
          </p>
        </div>

        <div :class="s['footer-col']">
          <template v-if="!hasEnoughApproved">
            <SkewedButton
              :borderRadius="6"
              :skew="[0, 0, [-5, -3], 0]"
              background="var(--color-button-yellow)"
              :class="[s.button]"
              :disabled="isSubmitting"
              @click="approve"
            >
              <span>Approve $BLOOD</span>
            </SkewedButton>
          </template>
          <template v-else>
            <SkewedButton
              :borderRadius="6"
              :skew="[0, 0, [-5, -3], 0]"
              background="var(--color-button-yellow)"
              :class="[s.button]"
              :disabled="isSubmitting || !hasEnoughBlood"
              @click="submit"
            >
              <template v-if="!hasEnoughBlood">Not Enough $BLOOD</template>
              <template v-else>Claim</template>
            </SkewedButton>
          </template>
        </div>
      </SkewedDiv>
    </template>
  </SkewedModal>
</template>

<style module="s" lang="scss">
@import '@/assets/style/mixins.scss';

.select-items-modal {
  max-width: min(75vw, 1100px) !important;
  min-height: initial !important;

  @include mMax(1000px) {
    max-width: 100% !important;
  }
}

.bloodBalance {
  display: flex;
  align-items: center;
  gap: 14px;
  background: rgba(33, 33, 33, 0.3);
  padding: 10px 13px;
  border-radius: 5px;
  font-size: 22px;
}

.bloodIconHeader {
  height: 36px;
}

.title {
  flex: 1;
}

.titleWrapper {
  display: flex;
  width: 100%;
  padding-right: 16px;
  @include mMax(550px) {
    flex-direction: column;
    gap: 12px;
  }
}

.select-items-content {
  padding-bottom: 0px !important;
}

.content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin-bottom: 1px; /* don't show uncessary scroll */
}

.token-row {
  width: 100%;
  display: flex;
  align-items: center;
  height: 175px;
  gap: 43px;
  /* prevent cut off impostor border */
  margin-left: 3px;
  margin-bottom: 3px;
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fill, minmax(172px, 1fr)); */
  /* grid-column-gap: 20px; */
  /* grid-row-gap: 20px; */
  @include mMax(1000px) {
    flex-direction: column;
    height: auto;
    gap: 12px;
  }
}

.rowInfo {
  flex: 1;
}
.rowTitle {
  font-size: 24px;
  font-weight: bold;

  @include mMax(1000px) {
    font-size: 16px;
  }
}
.rowDescription {
  font-size: 24px;
  font-weight: bold;

  @include mMax(1000px) {
    font-size: 16px;
  }
}
.rowValue {
  font-size: 44px;
  font-weight: bold;

  @include mMax(1000px) {
    font-size: 24px;
  }
}

.tokenPreview {
  max-width: 172px;
  width: 100%;
}

.footer-wrapper {
  padding: 20px 43px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  @include mMax(1000px) {
    flex-direction: column;
    gap: 12px;
  }
}

.footer-col {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.label {
  font-size: 22px;
  font-weight: bold;

  @include mMax(1000px) {
    font-size: 16px;
  }
}

.value {
  font-size: 44px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;

  @include mMax(1000px) {
    font-size: 18px;
  }
}

.ufo-img {
  height: 50px;
}

.footer-text {
  margin-bottom: 18px;
  padding-top: 20px;
  font-size: 12px;
}

.cards-container {
  padding-top: 18px;
  p {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .cards-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}

.button-wrapper {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.button.button {
  font-weight: bold;
  font-size: 20px;
  padding-right: 46px;
  padding-left: 46px;
  /* max-width: 250px; */
  /* width: 100%; */

  .secondary {
    color: white;
  }

  span {
    text-transform: capitalize;
  }
}

.buttonRemove.buttonRemove {
  color: white;
}

.select-count {
  &:before {
    content: '(';
  }
  &:after {
    content: ')';
  }

  margin-left: 0.3em;
}

@media (max-width: 1060px) {
  .cards-container .cards-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .button-wrapper {
    flex-direction: column;
    .button {
      max-width: 100%;
    }
  }
}

@media (max-width: 470px) {
  .cards-container .cards-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
