<template>
  <div class="community">
    <SkewedDiv
      :border-radius="15"
      :clip-path="`polygon(0% 0%, 100% 0%, calc(100% - 16px) 100%, 16px 100%)`"
      class-content="community__card-content"
      class-skew-box="community__card-skew-box"
      class="community__card"
    >
      <div class="community__card-images">
        <img
          src="~@/images/community-bag.svg"
          class="community__card-img community__card-bag"
        />
        <img
          src="~@/images/community-impostor.webp"
          class="community__card-img community__card-impostor"
        />
      </div>
      <div class="community__card-inner">
        <h2>{{ COMMUNITY_INFO.title }}</h2>
        <img src="~@/assets/horizontal-line.png" />
        <p v-html="COMMUNITY_INFO.subcontent" />
        <SkewedButton
          class="discord-button"
          background="linear-gradient(92.62deg, #849BE6 -149.9%, #2E4EB6 29.82%)"
          :skew="[[0, 0], [0, 0], [0, 0], [-5, -2]]"
          :borderRadius="5"
          @click="onDiscordButtonClick"
        >
          JOIN DISCORD
          <DiscordIcon class="discord-button__icon" />
        </SkewedButton>
      </div>
    </SkewedDiv>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import SkewedButton from '@/components/common/SkewedButton.vue';
import DiscordIcon from '@/components/icons/DiscordIcon.vue';
import { COMMUNITY_INFO } from '@/constants';

const store = useStore();

const onDiscordButtonClick = () => {
  window.open(COMMUNITY_INFO.discordLink, '_blank')
}
</script>

<style scoped lang="scss">

@import '../../../assets/style/mixins.scss';

.community {
  background: transparent;
  @include flex();
  padding: 0 84px 64px;
  width: 100%;
  position: initial;

  &__card {
    min-height: 300px;
    width: 100%;
    margin: 0 auto;
    max-width: 1120px;

    :deep(.community__card-skew-box) {
      background: url('~@/images/utility-bg-header.webp');
      background-position: bottom;
      background-size: 1120px;
    }

    &-inner {
      margin-right: 350px;
      padding: 36px 12px 24px 48px;
      @include flex(column, space-between, flex-start);
      height: 100%;

      h2 {
        @include DMSans(38px, 700);
      }

      img {
        margin-top: 6px;
        margin-bottom: 12px;
      }

      p {
        @include DMSans(14px, 400);
      }

      .discord-button {
        margin-top: 18px;
        color: var(--color-white);
        @include DMSans(20px, 700);
        width: 212px;
        height: 55px;

        &__icon {
          margin-left: 12px;
        }

      }
    }

    :deep(.community__card-content) {
      position: relative;
    }

    &-images {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 100%;
    }

    &-img {
      position: absolute;
    }

    &-impostor {
      bottom: -6px;
      right: 0;
    }

    &-bag {
      bottom: 0;
      right: 168px;
    }
  }
}

@include mediaOnlyScreenMax(1050px) {
  .community {

    &__card {
      min-height: initial;

      &-inner {
        margin: 0;
        padding: 0;
        @include flex(column, space-between);
        max-width: 480px;

        h2 {
          text-align: center;
          @include DMSans(28px, 700);
        }

        img {
          width: 298px;
        }

        p {
          text-align: center;
          @include DMSans(14px, 400);
        }
      }

      :deep(.community__card-content) {
        position: relative;
        @include flex(column, flex-start, center);
      }
      

      :deep(.community__card-skew-box) {
        background: transparent;
      }

      &-images {
        position: relative;
        height: 240px;
        width: 260px;
        @include flex();
      }

      &-impostor {
        height: 300px;
        bottom: 0;
        right: 0;
      }

      &-bag {
        height: 142px;
        bottom: 10px;
        left: 0;
      }
    }
  }
}

@include mediaOnlyScreenMax(720px) {
  .community {
    padding: 0 16px 64px;
  }
}
</style>
