<template>
  <SkewedDiv class="documentRow" :borderRadius="3" background="black" opacity="12%" :skew="[0, 0, 7, -4]">
    <div class="row">
      <img class="icon" src="https://impostors.s3.amazonaws.com/assets/pdf-icon.webp" alt="Document Icon" @click="download">
      <div class="info">
        <p class="title" @click="download">{{ document.label }}</p>
        <p class="subtext"> {{ document.size }} </p>
      </div>
      
        <img class="download" src="https://impostors.s3.amazonaws.com/assets/download-icon.webp" @click="download"/>
      
    </div>
  </SkewedDiv>
</template>
<script setup>
import SkewedDiv from '@/components/common/SkewedDiv.vue';

const props = defineProps({
  document: Object
})

const download = () => {
  window.open(props.document.url, '_blank');
}

</script>
<style scoped lang="scss">
  .documentRow {
    width: 100%;
    height: fit-content;
    padding: 16px;
    padding-right: 24px;

    .row {
      display: flex;
      gap: 12px;
      align-items: center;

      .icon {
        width: 48px;
        height: 48px;
        cursor: pointer;
        transition: all ease 200ms;

        &:hover {
            opacity: 0.7;
          }
      }

      .info {
        width: 100%;
        font-size: 18px;

        .title {
          transition: all ease 200ms;
          cursor: pointer;
          width: fit-content;
          &:hover {
            opacity: 0.7;
          }
        }

        .subtext {
          font-size: 14px;
          opacity: 0.5;
        }
      }

      .download {
        width: 24px;
        height: auto;
        object-fit: contain;
        cursor: pointer;
        transition: all 200ms ease;
        &:hover {
          opacity: 0.7;
        }

        &:active {
          opacity: 0.5;
        }
      }
    }
  }
</style>