'use strict';

// Specified state transitions per particular actions.
export default {
  updateTokenInfo(state, tok) {
    state.tokenInfo = tok;
  },

  updateClaimDetails(state, claimDetails) {
    state.claimDetails = claimDetails;
  },

  updateEligible(state, resp) {
    state.eligibleItems = resp.eligible;
    state.owned = resp.owned;
  },

  updateMetadata(state, { collectionAddress, tokenId, metadata }) {
    let nft = state.owned[collectionAddress].find(i => i.tokenId === parseInt(tokenId, 10))
    if (nft) {
      nft.metadata = metadata
    }
  },

  updateTokenEligibility(state, tokenEligibility) {
    state.tokenEligibility = tokenEligibility;
  },

  updateRedeemStatus(state, resp) {
    state.redeemStatus = resp;
  },
};
