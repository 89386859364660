<template>
	<Header :is-home="false" />
	<div class="bg-wrapper">
	  <div class="primary-bg" />
	  <div class="fade" />
	</div>
	<Ethereum :callback="initialize" />
	<NotFoundMain v-if="notFound" />
	<div
	  v-else
	  class="container"
	>
	  <MyItemsSkeleton v-if="isLoading" />
	  <SkewedDiv
		v-else-if="isConnected"
		class="wrapper"
		:border-radius="15"
		background="#2F4EB6"
		:skew="[0, 2, 10, -10]"
	  >
		<ItemDetails
		  :item="nft"
		  type="voxel"
		  @go-back="goBack"
		/>
	  </SkewedDiv>
	  <div
		v-else
		class="connectWalletWrapper"
	  >
		<ConnectWallet />
	  </div>
	</div>
  </template>
  
  <script setup>
  import { computed, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import Ethereum from '/src/components/common/Ethereum.vue';
  import Header from '../layout/Header.vue';
  import SkewedDiv from '@/components/common/SkewedDiv.vue';
  import MyItemsSkeleton from './components/MyItemsSkeleton.vue';
  import ConnectWallet from '@/components/common/ConnectWallet.vue';
  import { claimService } from '@/services';
  import ItemDetails from './components/ItemDetails.vue';
  import { useQuery } from 'vue-query';
  import NotFoundMain from '../not-found/components/NotFoundMain.vue';
  const isEthLoading = ref(true);
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const isConnected = computed(() => {
	return store.state.ethers.canSign;
  });
  const initialize = async () => {
	isEthLoading.value = false;
  };
  let enabled = computed(() => !isEthLoading.value);
  let { data: nft, isLoading: isItemLoading } = useQuery(
	['voxel', route.params.id],
	() => {
	  return claimService.loadItemById({
		collection: 'voxel',
		tokenId: route.params.id,
		dispatch: store.dispatch
	  });
	},
	{
	  enabled
	}
  );
  let isLoading = computed(() => isItemLoading.value || isEthLoading.value);
  let notFound = computed(() => !isLoading.value && !nft.value);
  let goBack = () => {
	router.push({ path: `/my-items/${store.state.ethers.address}` });
  };
  </script>
  
  <style scoped lang="scss">
  .bg-wrapper {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	.fade {
	  background-image: linear-gradient(0deg,
	  var(--color-bg-main),
	  55%,
	  transparent);
	  bottom: 0;
	  height: 300px;
	  left: 0;
	  position: absolute;
	  right: 0;
	}
	.primary-bg {
	  background-attachment: scroll;
	  background-image: linear-gradient(0deg,
	  var(--color-bg-main),
	  15%,
	  transparent),
		url('../../images/background.webp');
	  background-repeat: no-repeat;
	  background-size: contain;
	  bottom: 0;
	  height: 934px;
	  /* ensures background-size: contain stretches full width */
	  min-height: 100vw;
	  overflow: hidden;
	  pointer-events: none;
	  position: absolute;
	  top: 0;
	  width: 100%;
	}
  }
  .container {
	display: flex;
	height: 100%;
	justify-content: center;
	min-height: 90vh;
	padding-bottom: 60px;
	padding-top: 110px;
	position: relative;
	width: 100%;
	.fade {
	  background: linear-gradient(180deg, rgba(0, 0, 0, 0), #1e1226);
	  bottom: 0;
	  height: 20%;
	  left: 0;
	  position: absolute;
	  width: 100%;
	}
	.wrapper {
	  margin: 0 40px;
	  max-width: 1280px;
	  padding: 30px 50px;
	  width: 100%;
	}
	.connectWalletWrapper {
	  width: 100%;
	}
  }
  @media (max-width: 640px) {
	.container .wrapper {
	  margin: 0 20px;
	  padding: 30px;
	}
  }
  </style>