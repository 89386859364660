<template>
  <div class="countdown" v-if="isUpcoming && showSeconds">
    {{ days }}d {{ hours }}h {{ minutes }}m {{ seconds }}s
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    startDate: {
      type: Date
    },

    endDate: {
      type: Date
    },

    hideDate: {
      type: Boolean,
      default: false,
      required: false
    },

    isLight: {
      type: Boolean,
      default: false,
      required: false
    },

    smallFont: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data() {
    return {
      now: new Date(),
      days: '',
      hours: '',
      minutes: '',
      seconds: '',
      distance: ''
    };
  },

  mounted() {
    this.now = new Date();
    // Find the distance between now and the count down date
    if (this.startDate) {
      this.distance = this.startDate.getTime() - new Date().getTime();

      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor(
        (this.distance % (1000 * 60 * 60)) / (1000 * 60)
      );
      this.seconds = Math.floor((this.distance % (1000 * 60)) / 1000);

      // If the count down is finished
      if (this.distance < 0) {
        clearInterval(x);
      }
    }

    let x = setInterval(() => {
      this.now = new Date();
      // Find the distance between now and the count down date
      if (this.startDate) {
        this.distance = this.startDate.getTime() - new Date().getTime();

        // Time calculations for days, hours, minutes and seconds
        this.days = Math.floor(this.distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor(
          (this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.minutes = Math.floor(
          (this.distance % (1000 * 60 * 60)) / (1000 * 60)
        );
        this.seconds = Math.floor((this.distance % (1000 * 60)) / 1000);

        // If the count down is finished
        if (this.distance < 0) {
          clearInterval(x);
        }
      }
    }, 1000);
  },

  computed: {
    daysLabel() {
      return this.days !== 1 ? 'd' : 'd';
    },

    hoursLabel() {
      return this.hours !== 1 ? 'h' : 'h';
    },

    minutesLabel() {
      return this.minutes !== 1 ? 'm' : 'm';
    },

    secondsLabel() {
      return this.seconds !== 1 ? 's' : 's';
    },

    showDays() {
      return this.distance > 1000 * 60 * 60 * 24;
    },

    showHours() {
      return this.distance > 1000 * 60 * 60;
    },

    showMinutes() {
      return this.distance > 1000 * 60;
    },

    showSeconds() {
      return this.distance > 1000;
    },

    gridColumns() {
      if (this.distance > 1000 * 60 * 60 * 24) {
        return { gridTemplateColumns: 'repeat(4, auto)' };
      } else if (this.distance > 1000 * 60 * 60) {
        return { gridTemplateColumns: 'repeat(3, auto)' };
      } else if (this.distance > 1000 * 60) {
        return { gridTemplateColumns: 'repeat(2, auto)' };
      } else {
        return { gridTemplateColumns: 'repeat(1, auto)' };
      }
    },

    fullDateLabel() {
      const options = {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        timeZoneName: 'short'
      };

      // options.timeZone = 'PST';
      // options.timeZoneName = 'short';
      return this.startDate
        ? this.startDate.toLocaleDateString(undefined, options)
        : '';
    },

    borderTheme() {
      if (this.isLight) {
        return {
          backgroundColor: 'transparent',
          borderColor: 'rgba(var(--text-color-rgb), 0.2)'
        };
      } else {
        return {};
      }
    },

    isLive() {
      return this.now > this.startDate && this.endDate > this.now;
    },

    isEnded() {
      const now = new Date();
      return this.endDate < now;
    },

    isUpcoming() {
      const now = new Date();
      return now < this.startDate;
    }
  }
};
</script>

<style scoped>
.countdown {
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-shadow: 0px 0px 5px white;
  font-style: italic;
}
</style>
