<template>
  <div class="dropdownContainer">
    <SkewedButton 
      @click.stop="toggleDropdown"
      background="var(--color-accent-shade)"
      :skew="[0, 0, 0, [-2, -3]]"
      :borderRadius="4"
      class="dropdown"
      >
      <div class="label">
        <span>{{ items?.[currentIndex] }}</span>
        <DownArrowIcon class="svg-icon" :class="showDropdown ? 'flip' : ''"/>
      </div>
    </SkewedButton>
    <SkewedDiv class="dropdownMenu" v-if="showDropdown" :borderRadius="4" background="var(--color-accent-shade)" :skew="[0, 3, 0, 0]">
      <div v-for="(item, index) in items" :key="index" class="itemWrapper" :class="index == currentIndex ? 'current' : ''" @click="selectItem(index)">
        <span class="item">{{ item }}</span>
      </div>
    </SkewedDiv>
  </div>
</template>
<script setup>
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import SkewedButton from '@/components/common/SkewedButton.vue';
import DownArrowIcon from '@/components/icons/DownArrowIcon.vue';

import { ref } from 'vue';

let props = defineProps({
  items: {
    type: Array,
  },
  currentIndex: {
    type: Number
  }
})

let emit = defineEmits(['selectItem']);

const showDropdown = ref(false);

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
}

const selectItem = (index) => {
  emit('selectItem', index);
}

const close = (event) => {
  showDropdown.value = false;
}

document.addEventListener('click', close);

</script>
<style scoped lang="scss">

.dropdownContainer {
  width: 100%;
  position: relative;
  .dropdown {
    width: 100%;
    .label {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0px 20px;

      .svg-icon {
        transition: all ease 200ms;
      }

      .flip {
        transform: rotateZ(180deg);
      }
    }
  }

  .dropdownMenu {
    position: absolute;
    width: 100%;
    padding-top: 10px;
    top: 67px;
    z-index: 2;

    .itemWrapper {
      padding: 20px;
      font-size: 1.7rem;
      font-weight: normal;
      margin: 10px;
      border-radius: 5px;
      transition: all ease 200ms;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      &:active {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .current {
      background-color: rgba(0, 0, 0, 0.16);
    }
  }
}
</style>
