<template>
  <Ethereum :callback="initialize" />
  <NftsMain id="nftsmain" />
  <Community id="community" />
  <Footer id="footer" />
</template>

<script setup>
import Ethereum from '/src/components/common/Ethereum.vue';
import NftsMain from './components/NftsMain.vue';
import Footer from '../home/components/Footer.vue';
import Community from '../home/components/Community.vue';

import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const isLoggedIn = computed(() => {
  return store.state.ethers.address;
});

const initialize = async () => {};

const isHome = computed(() => {
  return router.currentRoute.value.path === '/';
});

const buttonMsg = computed(() => {
  return "MINT";
});

onMounted(() => {
  window.scrollTo(0,0);
});
</script>
