<template>
  <div class="privacy-container">
    <div class="privacy-text-title">Privacy</div>
    <div class="privacy-text-container">
      <p class="c27"><span class="c12">Impostors Privacy Policy</span></p>
      <p class="c27 c20"><span class="c3 c0"></span></p>
      <p class="c39">
        <span class="c12">Last Updated:</span
        ><span class="c2 c0">&nbsp;March 24, 2022</span>
      </p>
      <p class="c7" id="h.gjdgxs">
        <span class="c0"
          >This Privacy Policy is designed to help you understand how Super
          Studios, Inc. (“</span
        ><span class="c12 c0">Super Studios</span><span class="c0">,” “</span
        ><span class="c12 c0">we</span><span class="c0">,” “</span
        ><span class="c12 c0">us</span><span class="c0">,” and “</span
        ><span class="c12 c0">our</span
        ><span class="c2 c0"
          >”) collects, uses, and shares your personal information on the
          Impostors Platform and to help you understand and exercise your
          privacy rights.</span
        >
      </p>
      <p class="c7">
        <span class="c0"
          >Capitalized terms not defined in this Privacy Policy have the meaning
          set forth in the Impostors Platform Terms of Service </span
        ><span class="c24"
          ><a
            class="c15"
            href="https://impostors.gg/terms"
            >https://impostors.gg/terms</a
          ></span
        ><span class="c0">.</span>
      </p>
      <a id="id.1fob9te"></a>
      <ol class="c10 lst-kix_list_1-0 start" start="1">
        <li class="c7 c8">
          <h1 id="h.30j0zll" style="display: inline">
            <span class="c3 c0">SCOPE</span>
          </h1>
        </li>
      </ol>
      <h1 class="c5">
        <span class="c0 c9"
          >This Privacy Policy applies to personal information processed by
          Super Studios on </span
        ><span class="c9">the Impostors website and interface located at </span
        ><span class="c24 c9"
          ><a
            class="c15"
            href="https://urldefense.com/v3/__https:/impostors.gg/__;!!HJT62s_lzg!lSwmclyfF5Tg-63b0kOTiYDDk3NkxhLKb0EncPdH1GVfWjiOV8VpjjdSx0okbOzAjA$"
            >https://impostors.gg/</a
          ></span
        ><span>&nbsp;</span><span class="c9">(the</span><span>&nbsp;</span
        ><span class="c9">“</span><span>Site</span
        ><span class="c9">”) and the corresponding mobile application (“</span
        ><span>App</span
        ><span class="c9"
          >”), including without limitation the purchase of certain digital
          assets in the form of non-fungible cryptocurrency tokens (“</span
        ><span>NFTs</span
        ><span class="c9"
          >”) on the decentralized blockchains on which the NFTs are recorded
          (each, a “</span
        ><span>Blockchain</span
        ><span class="c9"
          >”), as well as the access to the Impostors interactive entertainment
          application (the “</span
        ><span>Impostors Game</span
        ><span class="c9"
          >”), and our online services and software provided on or in connection
          with those services that are accessible via the Site and the App. To
          make this Privacy Policy easier to read, the Site, the App and the
          aforementioned services are collectively called the “</span
        ><span>Services</span
        ><span class="c9"
          >.” &nbsp;For clarity, the Services, the Site and the App do not
          include the SuperFarm smart contract, $SUPER tokens, $BLOOD tokens or
          any other aspect of the Ethereum or other blockchain-based network
          with which the Site, App and Services interact. &nbsp;</span
        >
      </h1>
      <ol class="c10 lst-kix_list_1-0" start="2">
        <li class="c7 c8">
          <h1 id="h.2et92p0" style="display: inline">
            <span class="c0 c9">&nbsp;</span><a id="id.3znysh7"></a
            ><span class="c3 c0">CHANGES TO OUR PRIVACY POLICY</span>
          </h1>
        </li>
      </ol>
      <h1 class="c5">
        <span class="c2 c0"
          >We may revise this Privacy Policy from time to time in our sole
          discretion. If there are any material changes to this Privacy Policy,
          we will notify you as required by applicable law. You understand and
          agree that you will be deemed to have accepted the updated Privacy
          Policy if you continue to use our Services after the new Privacy
          Policy takes effect.</span
        >
      </h1>
      <ol class="c10 lst-kix_list_1-0" start="3">
        <li class="c7 c8">
          <h1 style="display: inline">
            <span class="c3 c0">PERSONAL INFORMATION WE COLLECT</span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c2 c0"
          >The categories of personal information we collect depend on how you
          interact with us, our Services and the requirements of applicable law.
          We collect information that you provide to us, information we obtain
          automatically when you use our Services, and information from other
          sources such as third-party services and organizations, as described
          below.</span
        >
      </p>
      <a id="id.tyjcwt"></a>
      <ol class="c10 lst-kix_list_2-0 start" start="1">
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Information You Provide to Us Directly</span>
        </li>
      </ol>
      <p class="c7">
        <span class="c2 c0"
          >We may collect the following personal information that you provide to
          us.</span
        >
      </p>
      <ul class="c10 lst-kix_list_11-0 start">
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Wallet and Transaction Information</span
          ><span class="c0"
            >. In order to engage in transactions on the Services, you will need
            to provide us with access to or information about your digital
            wallet. As you use the Services, we may collect personal information
            and details associated with the transactions you are involved
            with.</span
          >
        </li>
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Account Information</span
          ><span class="c0"
            >. If you choose to connect your Epic Games account to the Services
            or create an account with us and provide such information, we or our
            service providers may collect your email, username and ETH address.
          </span>
        </li>
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Your Communications with Us</span
          ><span class="c0"
            >. We may collect personal information, such as email address, phone
            number, or mailing address when you request information about our
            Services, register for our newsletter or marketing, request customer
            or technical support or otherwise communicate with us.</span
          >
        </li>
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Interactive Features</span
          ><span class="c0"
            >. We and others who use our Services may collect personal
            information that you submit or make available through our
            interactive features (e.g., commenting functionalities, forums,
            blogs, and social media pages). Any personal information you provide
            on the public sections of these features will be considered
            “public,” unless otherwise required by applicable law, and is not
            subject to the privacy protections referenced herein.
          </span>
        </li>
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Surveys</span
          ><span class="c0"
            >. We may contact you to participate in surveys. If you decide to
            participate, you may be asked to provide certain information which
            may include personal information.</span
          >
        </li>
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Sweepstakes or Contests</span
          ><span class="c0"
            >. We may collect personal information you provide for any
            sweepstakes or contests that we offer. In some jurisdictions, we are
            required to publicly share information of sweepstakes and contest
            winners.</span
          >
        </li>
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Conferences, Trade Shows, and Other Events.</span
          ><span class="c0"
            >&nbsp;We may collect personal information from individuals when we
            attend conferences, trade shows, and other events.
          </span>
        </li>
        <li class="c4 li-bullet-0">
          <span class="c12 c0"
            >Business Development and Strategic Partnerships.</span
          ><span class="c0"
            >&nbsp;We may collect personal information from individuals and
            third parties to assess and pursue potential business opportunities.
          </span>
        </li>
      </ul>
      <ol class="c10 lst-kix_list_2-0" start="2">
        <li class="c4 li-bullet-0">
          <span class="c3 c0">Information Collected Automatically </span>
        </li>
      </ol>
      <p class="c7">
        <span class="c2 c0"
          >We may collect personal information automatically when you use our
          Services:</span
        >
      </p>
      <ul class="c10 lst-kix_list_11-0">
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Automatic Data Collection</span
          ><span class="c2 c0"
            >. We may collect certain information automatically when you use our
            Services, such as your Internet protocol (IP) address, user
            settings, MAC address, cookie identifiers, mobile carrier, mobile
            advertising and other unique identifiers, browser or device
            information, location information (including approximate location
            derived from IP address), and Internet service provider. We may also
            automatically collect information regarding your use of our
            Services, such as pages that you visit before, during and after
            using our Services, information about the links you click, the types
            of content you interact with, the frequency and duration of your
            activities, and other information about how you use our Services.
          </span>
        </li>
        <li class="c4 li-bullet-0">
          <span class="c12 c0"
            >Cookies, Pixel Tags/Web Beacons, and Other Technologies</span
          ><span class="c0"
            >. We, as well as third parties that provide content, advertising,
            or other functionality on our Services, may use cookies, pixel tags,
            local storage, and other technologies (“</span
          ><span class="c12 c0">Technologies</span
          ><span class="c2 c0"
            >”) to automatically collect information through your use of our
            Services.
          </span>
        </li>
      </ul>
      <ul class="c10 lst-kix_list_12-0 start">
        <li class="c19 li-bullet-0">
          <span class="c12 c0">Cookies</span
          ><span class="c2 c0"
            >. Cookies are small text files placed in device browsers that store
            preferences and facilitate and enhance your experience.</span
          >
        </li>
        <li class="c19 li-bullet-0">
          <span class="c12 c0">Pixel Tags/Web Beacons</span
          ><span class="c2 c0"
            >. A pixel tag (also known as a web beacon) is a piece of code
            embedded in our Services that collects information about engagement
            on our Services. The use of a pixel tag allows us to record, for
            example, that a user has visited a particular web page or clicked on
            a particular advertisement. We may also include web beacons in
            e-mails to understand whether messages have been opened, acted on,
            or forwarded.</span
          >
        </li>
      </ul>
      <p class="c7 c34">
        <span class="c2 c0"
          >Our uses of these Technologies fall into the following general
          categories:
        </span>
      </p>
      <ul class="c10 lst-kix_list_12-0">
        <li class="c19 li-bullet-0">
          <span class="c12 c0">Operationally Necessary</span
          ><span class="c2 c0"
            >. This includes Technologies that allow you access to our Services,
            applications, and tools that are required to identify irregular
            website behavior, prevent fraudulent activity and improve security
            or that allow you to make use of our functionality;</span
          >
        </li>
        <li class="c19 li-bullet-0">
          <span class="c12 c0">Performance-Related</span
          ><span class="c0"
            >. We may use Technologies to assess the performance of our
            Services, including as part of our analytic practices to help us
            understand how individuals use our Services (</span
          ><span class="c37 c0">see Analytics below</span
          ><span class="c2 c0">);</span>
        </li>
        <li class="c19 li-bullet-0">
          <span class="c12 c0">Functionality-Related</span
          ><span class="c2 c0"
            >. We may use Technologies that allow us to offer you enhanced
            functionality when accessing or using our Services. This may include
            identifying you when you sign into our Services or keeping track of
            your specified preferences, interests, or past items viewed;
            and</span
          >
        </li>
      </ul>
      <ul class="c10 lst-kix_list_3-0 start">
        <li class="c19 li-bullet-0">
          <span class="c12 c0">Advertising- or Targeting-Related</span
          ><span class="c0"
            >. We may use first party or third-party Technologies to deliver
            content, including ads relevant to your interests, on our Services
            or on third-party websites.</span
          >
        </li>
      </ul>
      <p class="c7 c34">
        <span class="c0 c37"
          >See Section 6 below to understand your choices regarding these
          Technologies.</span
        >
      </p>
      <ul class="c10 lst-kix_list_11-0">
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Analytics</span
          ><span class="c2 c0"
            >. We may use Technologies and other third-party tools to process
            analytics information on our Services.
          </span>
        </li>
      </ul>
      <ul class="c10 lst-kix_list_11-1 start">
        <li class="c32 li-bullet-0">
          <span class="c3 c0">Google Analytics</span
          ><span class="c2 c0"
            >. For more information about how Google uses your data (including
            for its own purposes, e.g. for profiling or linking it to other
            data), please visit </span
          ><span class="c11 c9"
            ><a
              class="c15"
              href="http://www.google.com/policies/privacy/partners/"
              >Google Analytics’ Privacy Policy</a
            ></span
          ><span class="c2 c0"
            >. To learn more about how to opt-out of Google Analytics’ use of
            your information, please click </span
          ><span class="c9 c11"
            ><a
              class="c15"
              href="http://tools.google.com/dlpage/gaoptout"
              >here</a
            ></span
          ><span class="c2 c0">.</span>
        </li>
        <li class="c32 li-bullet-0">
          <span class="c3 c0">PlayFab Analytics</span
          ><span class="c2 c0"
            >. We may use PlayFab to collect analytics information about how
            players use our Services. For more information about how PlayFab
            uses your data (including for its own purposes), please visit </span
          ><span class="c11 c9"
            ><a
              class="c15"
              href="https://privacy.microsoft.com/en-us/privacystatement"
              >PlayFab’s Privacy Policy</a
            ></span
          ><span class="c2 c0">. </span>
        </li>
      </ul>
      <ul class="c10 lst-kix_list_11-0">
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Social Media Platforms. </span
          ><span class="c0">Our Services may contain</span
          ><span class="c12 c0">&nbsp;</span
          ><span class="c2 c0"
            >social media buttons such as Facebook, Twitter and LinkedIn (that
            might include widgets such as the “share this” button or other
            interactive mini programs). These features may collect your IP
            address, which page you are visiting on our Services, and may set a
            cookie to enable the feature to function properly. Your interactions
            with these platforms are governed by the privacy policy of the
            relevant corporate entities providing such platforms.</span
          >
        </li>
      </ul>
      <ol class="c10 lst-kix_list_2-0" start="3">
        <li class="c4 li-bullet-0">
          <span class="c3 c0">Information Collected from Other Sources</span>
        </li>
      </ol>
      <p class="c7">
        <span class="c12 c0">Third-Party Sources.</span
        ><span class="c2 c0"
          >&nbsp;We may obtain information about you from other sources,
          including through third-party services and organizations. For example,
          if you access our Services through a third-party application, such as
          an app store, a third-party login service, or a social networking
          site, we may collect information about you from that third-party
          application that you have made available via your privacy settings.
        </span>
      </p>
      <a id="id.3dy6vkm"></a>
      <ol class="c10 lst-kix_list_1-0" start="4">
        <li class="c7 c8">
          <h1 id="h.1t3h5sf" style="display: inline">
            <span class="c3 c0">HOW WE USE YOUR INFORMATION</span>
          </h1>
        </li>
      </ol>
      <p class="c7" id="h.4d34og8">
        <span class="c2 c0"
          >We use your information for a variety of business purposes, including
          to provide our Services, for administrative purposes, and to market
          our products and Services, as described below.</span
        >
      </p>
      <ol class="c10 lst-kix_list_4-0 start" start="1">
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Provide Our Services</span>
        </li>
      </ol>
      <p class="c7">
        <span class="c2 c0"
          >We use your information to fulfil our contract with you and provide
          you with our Services, such as:</span
        >
      </p>
      <ul class="c10 lst-kix_list_5-0 start">
        <li class="c21 c25 li-bullet-0">
          <span class="c2 c0">Managing your information and accounts;</span>
        </li>
        <li class="c21 c25 li-bullet-0">
          <span class="c2 c0"
            >Providing access to certain areas, functionalities, and features of
            our Services;</span
          >
        </li>
        <li class="c21 c25 li-bullet-0">
          <span class="c2 c0"
            >Answering requests for customer or technical support;
          </span>
        </li>
        <li class="c21 c25 li-bullet-0">
          <span class="c2 c0"
            >Communicating with you about your account, activities on our
            Services, and policy changes;
          </span>
        </li>
        <li class="c21 c25 li-bullet-0">
          <span class="c2 c0"
            >Processing your digital wallet information to facilitate
            transactions via the Services;
          </span>
        </li>
        <li class="c21 c25 li-bullet-0">
          <span class="c2 c0"
            >Processing applications if you apply for a job we post on our
            Services; and
          </span>
        </li>
        <li class="c21 c25 li-bullet-0">
          <span class="c2 c0">Allowing you to register for events.</span>
        </li>
      </ul>
      <p class="c21 c20 c23"><span class="c2 c0"></span></p>
      <ol class="c10 lst-kix_list_4-0" start="2">
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Administrative Purposes</span>
        </li>
      </ol>
      <p class="c33 c39 subtitle">
        <span class="c2 c0"
          >We use your information for various administrative purposes, such
          as:</span
        >
      </p>
      <ul class="c10 lst-kix_list_5-0">
        <li class="c21 c25 li-bullet-0">
          <span class="c2 c0"
            >Pursuing our legitimate interests such as direct marketing,
            research and development (including marketing research), network and
            information security, and fraud prevention;</span
          >
        </li>
        <li class="c21 c25 li-bullet-0">
          <span class="c2 c0"
            >Detecting security incidents, protecting against malicious,
            deceptive, fraudulent or illegal activity, and prosecuting those
            responsible for that activity;</span
          >
        </li>
        <li class="c21 c25 li-bullet-0">
          <span class="c2 c0"
            >Measuring interest and engagement in our Services;
          </span>
        </li>
        <li class="c21 c25 li-bullet-0" id="h.2s8eyo1">
          <span class="c2 c0"
            >Improving, upgrading or enhancing our Services;
          </span>
        </li>
        <li class="c21 c25 li-bullet-0">
          <span class="c2 c0">Developing new products and Services;</span>
        </li>
        <li class="c21 c25 li-bullet-0">
          <span class="c2 c0"
            >Ensuring internal quality control and safety;</span
          >
        </li>
      </ul>
      <ul class="c10 lst-kix_list_6-0 start">
        <li class="c13 li-bullet-0">
          <span class="c2 c0"
            >Authenticating and verifying individual identities;</span
          >
        </li>
        <li class="c13 li-bullet-0">
          <span class="c2 c0"
            >Debugging to identify and repair errors with our Services;</span
          >
        </li>
        <li class="c13 li-bullet-0">
          <span class="c2 c0"
            >Auditing relating to interactions, transactions and other
            compliance activities;</span
          >
        </li>
        <li class="c13 li-bullet-0">
          <span class="c2 c0">Enforcing our agreements and policies; and</span>
        </li>
        <li class="c25 c41 li-bullet-0">
          <span class="c2 c0">Complying with our legal obligations.</span>
        </li>
      </ul>
      <ol class="c10 lst-kix_list_4-0" start="3">
        <li class="c6 li-bullet-0">
          <span class="c12 c0"
            >Marketing and Advertising our Products and Services</span
          >
        </li>
      </ol>
      <p class="c26">
        <span class="c2 c0"
          >We may use personal information to tailor and provide you with
          content and advertisements. We may provide you with these materials as
          permitted by applicable law.
        </span>
      </p>
      <p class="c7 c33 subtitle">
        <span class="c2 c0"
          >Some of the ways we may market to you include email campaigns, custom
          audiences advertising, and “interest-based” or “personalized
          advertising,” including through cross-device tracking.</span
        >
      </p>
      <p class="c7 c33 subtitle">
        <span class="c2 c0"
          >If you have any questions about our marketing practices or if you
          would like to opt out of the use of your personal information for
          marketing purposes, you may contact us at any time as set forth below.
        </span>
      </p>
      <ol class="c10 lst-kix_list_4-0" start="4">
        <li class="c6 li-bullet-0">
          <span class="c12 c0">Other Purposes</span>
        </li>
      </ol>
      <p class="c7">
        <span class="c2 c0"
          >We also use your information for other purposes as requested by you
          or as permitted by applicable law.</span
        >
      </p>
      <ul class="c10 lst-kix_list_7-0 start">
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Consent</span
          ><span class="c2 c0"
            >. We may use personal information for other purposes that are
            clearly disclosed to you at the time you provide personal
            information or with your consent.</span
          >
        </li>
        <li class="c4 li-bullet-0">
          <span class="c12 c0">De-identified and Aggregated Information.</span
          ><span class="c2 c0"
            >&nbsp;We may use personal information and other information about
            you to create de-identified and/or aggregated information, such as
            de-identified demographic information, de-identified location
            information, information about the device from which you access our
            Services, or other analyses we create.</span
          >
        </li>
      </ul>
      <a id="id.17dp8vu"></a>
      <ul class="c10 lst-kix_list_7-0">
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Share Content with Friends. </span
          ><span class="c2 c0"
            >Our Services may offer various tools and functionalities. For
            example, we may allow you to provide information about your friends
            through our referral services. Our referral services may allow you
            to forward or share certain content with a friend or colleague, such
            as an email inviting your friend to use our Services.</span
          >
        </li>
      </ul>
      <a id="id.26in1rg"></a><a id="id.3rdcrjn"></a><a id="id.1ksv4uv"></a
      ><a id="id.35nkun2"></a>
      <ol class="c10 lst-kix_list_1-0" start="5">
        <li class="c7 c8">
          <h1 id="h.lnxbz9" style="display: inline">
            <span class="c3 c0">HOW WE DISCLOSE YOUR INFORMATION</span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c2 c0"
          >We disclose your information to third parties for a variety of
          business purposes, including to provide our Services, to protect us or
          others, or in the event of a major business transaction such as a
          merger, sale, or asset transfer, as described below.
        </span>
      </p>
      <ol class="c10 lst-kix_list_8-0 start" start="1">
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Disclosures to Provide our Services</span>
        </li>
      </ol>
      <p class="c7">
        <span class="c2 c0"
          >The categories of third parties with whom we may share your
          information are described below.
        </span>
      </p>
      <ul class="c10 lst-kix_list_9-0 start">
        <li class="c7 c30 li-bullet-0">
          <span class="c12">Notice Regarding Use of Blockchain.</span
          ><span class="c2 c0"
            >&nbsp;If you elect to sell, purchase, or trade any NFTs on the
            Impostors Platform, any financial transactions that you engage in
            will be conducted solely through the Blockchain network governing
            such NFT. Information about your transactions will be provided to
            the applicable Blockchain and may be accessible to third parties due
            to the nature of the Blockchain.</span
          >
        </li>
        <li class="c7 c30 li-bullet-0">
          <span class="c12 c0"
            >Other Users of the Services and Parties You Transact With.</span
          ><span class="c2 c0"
            >&nbsp;Some of your personal information may be visible to other
            users of the Services (e.g., game information or NFTs featured on
            generally accessible parts of the Impostors Game). In addition, to
            complete NFT transactions via the Services, we will need to share
            some of your personal information with the person or company that
            you are transacting with. &nbsp; &nbsp; &nbsp;</span
          >
        </li>
        <li class="c7 c30 li-bullet-0">
          <span class="c12 c0">Third Party Websites and Applications. </span
          ><span class="c0"
            >You may choose to share personal information or interact with
            third-party websites and/or third-party applications, including, but
            not limited to, third-party electronic wallet extensions. Once your
            personal information has been shared with a third-party website or a
            third-party application, it will also be subject to such third
            party’s privacy policy. We encourage you to closely read each
            third-party website or third-party application privacy policy before
            sharing your personal information or otherwise interacting with
            them. Please note that we do not control and we are not responsible
            for the third-party website’s or the third-party application’s
            processing of your personal information.</span
          >
        </li>
        <li class="c7 c30 li-bullet-0">
          <span class="c12 c0">Service Providers</span
          ><span class="c2 c0"
            >. We may share your personal information with our third-party
            service providers who use that information to help us provide our
            Services. This includes service providers that provide us with IT
            support, hosting, payment processing, customer service, and related
            services.</span
          >
        </li>
        <li class="c7 c30 li-bullet-0">
          <span class="c12 c0">Business Partners</span
          ><span class="c2 c0"
            >. We may share your personal information with business partners to
            provide you with a product or service you have requested. We may
            also share your personal information to business partners with whom
            we jointly offer products or services.</span
          >
        </li>
        <li class="c7 c30 li-bullet-0">
          <span class="c12 c0">Affiliates</span
          ><span class="c2 c0"
            >. We may share your personal information with members of our
            corporate family.
          </span>
        </li>
        <li class="c7 c30 li-bullet-0">
          <span class="c12 c0">Advertising Partners</span
          ><span class="c2 c0"
            >. We may share your personal information with third-party
            advertising partners. These third-party advertising partners may set
            Technologies and other tracking tools on our Services to collect
            information regarding your activities and your device (e.g., your IP
            address, cookie identifiers, page(s) visited, location, time of
            day). These advertising partners may use this information (and
            similar information collected from other services) for purposes of
            delivering personalized advertisements to you when you visit digital
            properties within their networks. This practice is commonly referred
            to as “interest-based advertising” or “personalized advertising.”
          </span>
        </li>
        <li class="c7 c30 li-bullet-0">
          <span class="c0 c12">APIs/SDKs</span
          ><span class="c0"
            >. We may use third-party application program interfaces (“</span
          ><span class="c12 c0">APIs</span
          ><span class="c0">”) and software development kits (“</span
          ><span class="c12 c0">SDKs</span
          ><span class="c2 c0"
            >”) as part of the functionality of our Services. For more
            information about our use of APIs and SDKs, please contact us as set
            forth below.</span
          >
        </li>
      </ul>
      <ol class="c10 lst-kix_list_8-0" start="2">
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Disclosures to Protect Us or Others</span>
        </li>
      </ol>
      <p class="c7" id="h.44sinio">
        <span class="c2 c0"
          >We may access, preserve, and disclose any information we store
          associated with you to external parties if we, in good faith, believe
          doing so is required or appropriate to: comply with law enforcement or
          national security requests and legal process, such as a court order or
          subpoena; protect your, our, or others’ rights, property, or safety;
          enforce our policies or contracts; collect amounts owed to us; or
          assist with an investigation or prosecution of suspected or actual
          illegal activity.</span
        >
      </p>
      <ol class="c10 lst-kix_list_8-0" start="3">
        <li class="c4 li-bullet-0">
          <span class="c12 c0"
            >Disclosure in the Event of Merger, Sale, or Other Asset
            Transfers</span
          >
        </li>
      </ol>
      <p class="c7">
        <span class="c2 c0"
          >If we are involved in a merger, acquisition, financing due diligence,
          reorganization, bankruptcy, receivership, purchase or sale of assets,
          or transition of service to another provider, your information may be
          sold or transferred as part of such a transaction, as permitted by law
          and/or contract.</span
        >
      </p>
      <a id="id.2jxsxqh"></a><a id="id.3j2qqm3"></a>
      <ol class="c10 lst-kix_list_1-0" start="6">
        <li class="c7 c8">
          <h1 id="h.z337ya" style="display: inline">
            <span class="c3 c0">YOUR PRIVACY CHOICES AND RIGHTS</span>
          </h1>
        </li>
      </ol>
      <a id="id.1y810tw"></a>
      <p class="c7">
        <span class="c12 c0">Your Privacy Choices</span
        ><span class="c2 c0"
          >. The privacy choices you may have about your personal information
          are determined by applicable law and are described below.
        </span>
      </p>
      <a id="id.4i7ojhp"></a>
      <ul class="c10 lst-kix_list_7-0">
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Email Communications</span
          ><span class="c2 c0"
            >. If you receive an unwanted email from us, you can use the
            unsubscribe link found at the bottom of the email to opt out of
            receiving future emails. Note that you will continue to receive
            transaction-related emails regarding products or Services you have
            requested. We may also send you certain non-promotional
            communications regarding us and our Services, and you will not be
            able to opt out of those communications (e.g., communications
            regarding our Services or updates to our Terms of Service or this
            Privacy Policy).
          </span>
        </li>
      </ul>
      <a id="id.gjdgxs"></a>
      <ul class="c10 lst-kix_list_7-0">
        <li class="c4 li-bullet-0">
          <span class="c3 c0">Mobile Devices</span
          ><span class="c2 c0"
            >. We may send you push notifications through our mobile
            application. You may opt out from receiving these push notifications
            by changing the settings on your mobile device. With your consent,
            we may also collect precise location-based information via our
            mobile application. You may opt out of this collection by changing
            the settings on your mobile device.</span
          >
        </li>
        <li class="c4 li-bullet-0">
          <span class="c12 c0">“Do Not Track</span><span class="c0">.</span
          ><span class="c12 c0">”</span
          ><span class="c0">&nbsp;Do Not Track (“</span
          ><span class="c12 c0">DNT</span
          ><span class="c2 c0"
            >”) is a privacy preference that users can set in certain web
            browsers. Please note that we do not respond to or honor DNT signals
            or similar mechanisms transmitted by web browsers.</span
          >
        </li>
      </ul>
      <a id="id.2xcytpi"></a>
      <ul class="c10 lst-kix_list_7-0">
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Cookies and Interest-Based Advertising</span
          ><span class="c0"
            >. You may stop or restrict the placement of Technologies on your
            device or remove them by adjusting your preferences as your browser
            or device permits. However, if you adjust your preferences, our
            Services may not work properly. Please note that cookie-based
            opt-outs are not effective on mobile applications. However, you may
            opt-out of personalized advertisements on some mobile applications
            by following the instructions for </span
          ><span class="c24"
            ><a
              class="c15"
              href="https://support.google.com/googleplay/android-developer/answer/6048248?hl%3Den"
              >Android</a
            ></span
          ><span class="c0">, </span
          ><span class="c24"
            ><a
              class="c15"
              href="https://support.apple.com/en-us/HT202074"
              >iOS</a
            ></span
          ><span class="c0">&nbsp;and </span
          ><span class="c24"
            ><a
              class="c15"
              href="https://www.networkadvertising.org/mobile-choice/"
              >others</a
            ></span
          ><span class="c2 c0">.</span>
        </li>
      </ul>
      <p class="c7 c34">
        <span class="c0"
          >The online advertising industry also provides websites from which you
          may opt out of receiving targeted ads from data partners and other
          advertising partners that participate in self-regulatory programs. You
          can access these and learn more about targeted advertising and
          consumer choice and privacy by visiting the </span
        ><span class="c24"
          ><a
            class="c15"
            href="http://www.networkadvertising.org/managing/opt_out.asp"
            >Network Advertising Initiative</a
          ></span
        ><span class="c0">, </span
        ><span class="c24"
          ><a
            class="c15"
            href="http://www.aboutads.info/choices/"
            >the Digital Advertising Alliance</a
          ></span
        ><span class="c0">, </span
        ><span class="c24"
          ><a
            class="c15"
            href="https://www.youronlinechoices.eu/"
            >the European Digital Advertising Alliance</a
          ></span
        ><span class="c0">, and </span
        ><span class="c24"
          ><a
            class="c15"
            href="https://youradchoices.ca/choices/"
            >the Digital Advertising Alliance of Canada</a
          ></span
        ><span class="c2 c0">. &nbsp;</span>
      </p>
      <p class="c7 c34">
        <span class="c2 c0"
          >Please note you must separately opt out in each browser and on each
          device.
        </span>
      </p>
      <a id="id.1ci93xb"></a>
      <p class="c7">
        <span class="c12 c0">Your Privacy Rights</span
        ><span class="c2 c0"
          >. In accordance with applicable law, you may have the right to:</span
        >
      </p>
      <ul class="c10 lst-kix_list_7-0">
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Access Personal Information </span
          ><span class="c0"
            >about you, including: (i) confirming whether we are processing your
            personal information; (ii) obtaining access to or a copy of your
            personal information; or (iii) receiving an electronic copy of
            personal information that you have provided to us, or asking us to
            send that information to another company (aka the right of data
            portability);</span
          >
        </li>
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Request Correction </span
          ><span class="c0"
            >of your personal information where it is inaccurate or incomplete.
            In some cases, we may provide self-service tools that enable you to
            update your personal information;</span
          >
        </li>
        <li class="c4 li-bullet-0">
          <span class="c12 c0">Request Deletion </span
          ><span class="c0">of your personal information;</span
          ><span class="c3 c0">&nbsp;</span>
        </li>
        <li class="c4 li-bullet-0" id="h.3whwml4">
          <span class="c12 c0">Request Restriction of or Object</span
          ><span class="c0"
            >&nbsp;to our processing of your personal information; and
          </span>
        </li>
        <li class="c13 li-bullet-0">
          <span class="c12 c0">Withdraw Your Consent </span
          ><span class="c0"
            >to our processing of your personal information.</span
          ><span class="c3 c0">&nbsp;</span>
        </li>
      </ul>
      <p class="c16 c20"><span class="c2 c0"></span></p>
      <p class="c16">
        <span class="c2 c0"
          >If you would like to exercise any of these rights, please contact us
          as set forth in Section 15 below. We will process such requests in
          accordance with applicable laws.
        </span>
      </p>
      <a id="id.2bn6wsx"></a><a id="id.3as4poj"></a>
      <h1 class="c16 c34 c33 c36" id="h.qsh70q"><span class="c3 c0"></span></h1>
      <ol class="c10 lst-kix_list_1-0" start="7">
        <li class="c16 c8">
          <h1 style="display: inline">
            <span class="c3 c0">SECURITY OF YOUR INFORMATION</span>
          </h1>
        </li>
      </ol>
      <p class="c16 c20"><span class="c2 c0"></span></p>
      <p class="c16">
        <span class="c0"
          >We take steps designed to ensure that your information is treated
          securely and in accordance with this Privacy Policy.</span
        ><span class="c37 c0">&nbsp;</span
        ><span class="c2 c0"
          >Unfortunately, no system is 100% secure, and we cannot ensure or
          warrant the security of any information you provide to us. To the
          fullest extent permitted by applicable law, we do not accept liability
          for unauthorized disclosure.</span
        >
      </p>
      <p class="c16 c20"><span class="c2 c0"></span></p>
      <p class="c16" id="h.1pxezwc">
        <span class="c0"
          >By using our Services or providing personal information to us, you
          agree that we may communicate with you electronically regarding
          security, privacy, and administrative issues relating to your use of
          our Services. If we learn of a security system’s breach, we may
          attempt to notify you electronically by posting a notice on our
          Services, by mail or by sending an email to you.</span
        ><a id="id.49x2ik5"></a><a id="id.2p2csry"></a
        ><span class="c2 c0">&nbsp;</span>
      </p>
      <p class="c16 c20 c35"><span class="c2 c0"></span></p>
      <ol class="c10 lst-kix_list_1-0" start="8">
        <li class="c16 c8">
          <h1 style="display: inline">
            <span class="c3 c0">INTERNATIONAL DATA TRANSFERS</span>
          </h1>
        </li>
      </ol>
      <p class="c16 c20"><span class="c2 c0"></span></p>
      <p class="c7">
        <span class="c2 c0"
          >All information processed by us may be transferred, processed, and
          stored anywhere in the world, including, but not limited to, the
          United States or other countries, which may have data protection laws
          that are different from the laws where you live. We endeavor to
          safeguard your information consistent with the requirements of
          applicable laws.</span
        >
      </p>
      <a id="id.147n2zr"></a><a id="id.23ckvvd"></a>
      <ol class="c10 lst-kix_list_1-0" start="9">
        <li class="c7 c8">
          <h1 id="h.3o7alnk" style="display: inline">
            <span class="c3 c0">RETENTION OF PERSONAL INFORMATION </span>
          </h1>
        </li>
      </ol>
      <p class="c7">
        <span class="c2 c0"
          >We store the personal information we collect as described in this
          Privacy Policy for as long as you use our Services or as necessary to
          fulfill the purpose(s) for which it was collected, provide our
          Services, resolve disputes, establish legal defenses, conduct audits,
          pursue legitimate business purposes, enforce our agreements, and
          comply with applicable laws. &nbsp;</span
        >
      </p>
      <ol class="c10 lst-kix_list_1-0" start="10">
        <li class="c7 c8">
          <h1 id="h.ihv636" style="display: inline">
            <span class="c3 c0"
              >SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS</span
            >
          </h1>
        </li>
      </ol>
      <p class="c16">
        <span
          >This Supplemental California Privacy Policy only applies to our
          processing of personal information that is subject to the California
          Consumer Privacy Act of 2018 (“</span
        ><span class="c12">CCPA</span
        ><span class="c2 c0"
          >”). The CCPA provides California residents with the right to know
          what categories of personal information Super Studios has collected
          about them and whether Super Studios disclosed that personal
          information for a business purpose (e.g., to a service provider) in
          the preceding twelve months. California residents can find this
          information below:</span
        >
      </p>
      <p class="c16 c20"><span class="c2 c0"></span></p>
      <a id="t.6ec5b935d2f64ef08ccb950611d94743479c5b45"></a><a id="t.0"></a>
      <table class="c17">
        <tbody>
          <tr class="c1">
            <td class="c31" colspan="1" rowspan="1">
              <p class="c27">
                <span class="c3 c0"
                  >Category of Personal Information Collected by Super Studios
                </span>
              </p>
            </td>
            <td class="c22" colspan="1" rowspan="1">
              <p class="c43">
                <span class="c3 c0"
                  >Categories of Third Parties Personal Information is Disclosed
                  to for a Business Purpose</span
                >
              </p>
            </td>
          </tr>
          <tr class="c1">
            <td class="c31" colspan="1" rowspan="1">
              <p class="c21"><span class="c3 c0">Identifiers </span></p>
              <p class="c21">
                <span
                  >Internet Protocol address, email address, account name, or
                  other similar identifiers.</span
                >
              </p>
            </td>
            <td class="c22" colspan="1" rowspan="1">
              <ul class="c10 lst-kix_list_10-0 start">
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0">Service providers</span>
                </li>
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0"
                    >Third-party websites or applications (wallet
                    providers)</span
                  >
                </li>
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0">Blockchain networks </span>
                </li>
              </ul>
              <p class="c28 c20 c38"><span class="c2 c0"></span></p>
            </td>
          </tr>
          <tr class="c1">
            <td class="c31" colspan="1" rowspan="1">
              <p class="c21">
                <span class="c3 c0"
                  >Personal information categories listed in Cal. Civ. Code §
                  1798.80(e)</span
                >
              </p>
              <p class="c21">
                <span class="c9 c29"
                  >Employment, employment history, bank account number, credit
                  card number, debit card number, or any other financial
                  information</span
                >
              </p>
            </td>
            <td class="c22" colspan="1" rowspan="1">
              <ul class="c10 lst-kix_list_10-0">
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0">Service providers</span>
                </li>
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0"
                    >Third-party websites or applications (wallet
                    providers)</span
                  >
                </li>
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0">Blockchain networks </span>
                </li>
              </ul>
              <p class="c20 c28"><span class="c2 c0"></span></p>
            </td>
          </tr>
          <tr class="c1">
            <td class="c31" colspan="1" rowspan="1">
              <p class="c21">
                <span class="c3 c0">Commercial information</span>
              </p>
              <p class="c21">
                <span class="c29 c9"
                  >Records of personal property, products or services purchased,
                  obtained, or considered, or other purchasing or consuming
                  histories or tendencies.</span
                >
              </p>
              <p class="c21 c20"><span class="c3 c0"></span></p>
            </td>
            <td class="c22" colspan="1" rowspan="1">
              <ul class="c10 lst-kix_list_10-0">
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0">Service providers</span>
                </li>
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0"
                    >Third-party websites or applications (wallet
                    providers)</span
                  >
                </li>
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0">Blockchain networks </span>
                </li>
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0"
                    >Other users or third parties you interact with</span
                  >
                </li>
              </ul>
            </td>
          </tr>
          <tr class="c1">
            <td class="c31" colspan="1" rowspan="1">
              <p class="c21">
                <span class="c3 c0"
                  >Internet or other electronic network activity</span
                >
              </p>
              <p class="c21">
                <span
                  >Browsing history, search history, information on a consumer's
                  interaction with an internet website, application, or
                  advertisement.</span
                >
              </p>
            </td>
            <td class="c22" colspan="1" rowspan="1">
              <ul class="c10 lst-kix_list_10-0">
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0">Service providers</span>
                </li>
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0"
                    >Other users or third parties you interact with</span
                  >
                </li>
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0">Advertising partners</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr class="c1">
            <td class="c31" colspan="1" rowspan="1">
              <p class="c21"><span class="c3 c0">Geolocation data</span></p>
            </td>
            <td class="c22" colspan="1" rowspan="1">
              <ul class="c10 lst-kix_list_10-0">
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0">Service providers</span>
                </li>
              </ul>
            </td>
          </tr>
          <tr class="c1">
            <td class="c31" colspan="1" rowspan="1">
              <p class="c21">
                <span class="c3 c0"
                  >Professional or employment-related information</span
                >
              </p>
              <p class="c21">
                <span class="c29 c9"
                  >Current or past job history or performance evaluations.</span
                >
              </p>
            </td>
            <td class="c22" colspan="1" rowspan="1">
              <ul class="c10 lst-kix_list_10-0">
                <li class="c28 c30 li-bullet-0">
                  <span class="c2 c0">Service providers</span>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="c16 c20"><span class="c2 c0"></span></p>
      <p class="c16">
        <span class="c2 c0"
          >The categories of sources from which we collect personal information
          and our business and commercial purposes for using personal
          information are set forth above.
        </span>
      </p>
      <p class="c16 c20"><span class="c2 c0"></span></p>
      <p class="c16 c20"><span class="c3 c0"></span></p>
      <p class="c16">
        <span class="c3 c0"
          >Additional Privacy Rights for California Residents</span
        >
      </p>
      <p class="c16 c20"><span class="c3 c0"></span></p>
      <p class="c16">
        <span class="c12 c0"
          >“Sales” of Personal Information under the CCPA. </span
        ><span class="c0"
          >For purposes of the CCPA, Super Studios does not “sell” personal
          information, nor do we have actual knowledge of any “sale” of personal
          information of minors under 16 years of age. </span
        ><span class="c29 c9"
          >That said, we may share information with third-party advertising
          partners for the purpose of promoting our Services as described above,
          including for cross-context behavioral advertising. To the extent that
          such sharing is considered a “sale” under the CCPA, you may opt-out of
          having your personal information shared with such advertising partners
          by following the instructions found above in the section titled, </span
        ><span class="c24 c29 c9"
          ><a class="c15" href="#id.2xcytpi"
            >Your Privacy Choices and Rights &gt; Your Privacy Choices &gt;
            Cookies and Interest-Based Advertising</a
          ></span
        ><span class="c29 c9"
          >, or by contacting &nbsp;us as set forth in “</span
        ><span class="c24 c29 c9"
          ><a class="c15" href="#id.30j0zll">Contact Us</a></span
        ><span class="c29 c9">” below].</span>
      </p>
      <p class="c16 c20"><span class="c3 c0"></span></p>
      <p class="c16">
        <span class="c12 c0">Non-Discrimination.</span
        ><span class="c0"
          >&nbsp;California residents have the right not to receive
          discriminatory treatment by us for the exercise of their rights
          conferred by the CCPA.</span
        ><span class="c12 c0">&nbsp;</span>
      </p>
      <p class="c18">
        <span class="c12 c0">Authorized Agent. </span
        ><span class="c2 c0"
          >Only you, or someone legally authorized to act on your behalf, may
          make a verifiable consumer request related to your personal
          information. To designate an authorized agent, please contact us as
          set forth below.</span
        >
      </p>
      <p class="c18">
        <span class="c12 c0">Verification</span
        ><span class="c2 c0"
          >. When you make a request, we will ask you to provide sufficient
          information that allows us to reasonably verify you are the person
          about whom we collected personal information or an authorized
          representative, which may include confirming the email address
          associated with any personal information we have about you.
        </span>
      </p>
      <p class="c16 c20"><span class="c2 c0"></span></p>
      <p class="c16">
        <span class="c2 c0"
          >If you are a California resident and would like to exercise any of
          your rights under the CCPA, please contact us as set forth below. We
          will process such requests in accordance with applicable laws.
        </span>
      </p>
      <p class="c16 c20"><span class="c2 c0"></span></p>
      <ol class="c10 lst-kix_list_1-0" start="11">
        <li class="c7 c8">
          <h1 id="h.32hioqz" style="display: inline">
            <span class="c3 c0">SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS</span>
          </h1>
        </li>
      </ol>
      <p class="c16">
        <span class="c2 c0"
          >If you are a resident of Nevada, you have the right to opt-out of the
          sale of certain personal information to third parties who intend to
          license or sell that personal information. You can exercise this right
          by contacting us as set forth below with the subject line “Nevada Do
          Not Sell Request” and providing us with your name and the email
          address associated with your account. Please note that we do not
          currently sell your personal information as sales are defined in
          Nevada Revised Statutes Chapter 603A.</span
        >
      </p>
      <ol class="c10 lst-kix_list_1-0" start="12">
        <li class="c26 c8">
          <h1 id="h.1hmsyys" style="display: inline">
            <span class="c3 c0">CHILDREN’S INFORMATION</span>
          </h1>
        </li>
      </ol>
      <p class="c16">
        <span class="c2 c0"
          >The Services are not directed to children under 13 (or other age as
          required by local law), and we do not knowingly collect personal
          information from children. If you learn that your child has provided
          us with personal information without your consent, you may contact us
          as set forth below. &nbsp;If we learn that we have collected a child’s
          personal information in violation of applicable law, we will promptly
          take steps to delete such information.</span
        >
      </p>
      <p class="c16 c20"><span class="c2 c0"></span></p>
      <ol class="c10 lst-kix_list_1-0" start="13">
        <li class="c7 c8">
          <h1 style="display: inline">
            <span class="c3 c0">THIRD-PARTY WEBSITES/APPLICATIONS</span>
          </h1>
        </li>
      </ol>
      <p class="c7" id="h.41mghml">
        <span class="c2 c0"
          >The Services may contain links to other websites/applications and
          other websites/applications may reference or link to our Services.
          These third-party services are not controlled by us. We encourage our
          users to read the privacy policies of each website and application
          with which they interact. We do not endorse, screen or approve, and
          are not responsible for, the privacy practices or content of such
          other websites or applications. Providing personal information to
          third-party websites or applications is at your own risk.
        </span>
      </p>
      <ol class="c10 lst-kix_list_1-0" start="14">
        <li class="c7 c8">
          <h1 style="display: inline">
            <span class="c3 c0">SUPERVISORY AUTHORITY</span>
          </h1>
        </li>
      </ol>
      <p class="c16" id="h.1v1yuxt">
        <span class="c2 c0"
          >If you are located in the European Economic Area, Switzerland, or the
          United Kingdom you have the right to lodge a complaint with a
          supervisory authority if you believe our processing of your personal
          information violates applicable law.</span
        ><a id="id.3fwokq0"></a><a id="id.vx1227"></a><a id="id.2grqrue"></a>
      </p>
      <p class="c16 c20"><span class="c3 c0"></span></p>
      <ol class="c10 lst-kix_list_1-0" start="15">
        <li class="c7 c8">
          <h1 style="display: inline"><span class="c3 c0">CONTACT US</span></h1>
        </li>
      </ol>
      <p class="c16" id="h.4f1mdlm">
        <span
          >&nbsp;If you have any questions about our privacy practices or this
          Privacy Policy, or to exercise your rights as detailed in this Privacy
          Policy, please contact us via our </span
        ><span class="c24"
          ><a
            class="c15"
            href="https://discord.gg/impostors"
            >Discord channel</a
          ></span
        ><span>&nbsp;or at </span
        ><span class="c24"
          ><a class="c15" href="mailto:support@superfarm.io"
            >support@superfarm.io</a
          ></span
        ><span class="c2 c0">. </span>
      </p>
    </div>
  </div>
</template>
<script>
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    const isLoggedIn = computed(() => {
      return store.state.ethers.address;
    });

    const initialize = async () => {};

    return {
      store,
      isLoggedIn,
      initialize
    };
  }
};
</script>

<style scoped lang="scss">
.privacy-container {
  padding: 65px;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('https://impostors.s3.amazonaws.com/assets/space-background.webp');
  background-position: top;
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  width: 100%;

  font-size: 18px;
  @media (max-width: 650px) {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.privacy-text-title {
  margin-bottom: 20px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 43px;
  width: 100%;
  max-width: 1400px;
  /* identical to box height */
}

.privacy-text-container {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.8em;
  max-width: 1400px;
  width: 100%;

  @media (max-width: 650px) {
    font-size: 14px;
    & * {
      text-align: left !important;
    }
  }
}

a {
  color: var(--color-button-yellow);
}

@media (max-width: 900px) {
  .privacy-container {
    padding-top: 90px;
  }
}
.lst-kix_list_14-1 > li:before {
  content: ' ';
}
.lst-kix_list_14-3 > li:before {
  content: ' ';
}
.lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}
.lst-kix_list_14-0 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_14-4 > li:before {
  content: ' ';
}
.lst-kix_list_14-5 > li:before {
  content: ' ';
}
.lst-kix_list_14-7 > li:before {
  content: ' ';
}
ol.lst-kix_list_13-4.start {
  counter-reset: lst-ctn-kix_list_13-4 0;
}
.lst-kix_list_14-6 > li:before {
  content: ' ';
}
ul.lst-kix_list_9-3 {
  list-style-type: none;
}
ul.lst-kix_list_9-4 {
  list-style-type: none;
}
ul.lst-kix_list_9-1 {
  list-style-type: none;
}
ul.lst-kix_list_9-2 {
  list-style-type: none;
}
ul.lst-kix_list_9-7 {
  list-style-type: none;
}
.lst-kix_list_13-0 > li {
  counter-increment: lst-ctn-kix_list_13-0;
}
ul.lst-kix_list_9-8 {
  list-style-type: none;
}
ul.lst-kix_list_9-5 {
  list-style-type: none;
}
ul.lst-kix_list_9-6 {
  list-style-type: none;
}
ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}
ul.lst-kix_list_9-0 {
  list-style-type: none;
}
.lst-kix_list_14-2 > li:before {
  content: ' ';
}
.lst-kix_list_4-3 > li {
  counter-increment: lst-ctn-kix_list_4-3;
}
.lst-kix_list_14-8 > li:before {
  content: ' ';
}
ol.lst-kix_list_8-8.start {
  counter-reset: lst-ctn-kix_list_8-8 0;
}
.lst-kix_list_5-0 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}
ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}
.lst-kix_list_5-3 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_5-2 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_8-3 > li {
  counter-increment: lst-ctn-kix_list_8-3;
}
.lst-kix_list_5-1 > li:before {
  content: 'o  ';
}
.lst-kix_list_5-7 > li:before {
  content: 'o  ';
}
.lst-kix_list_5-6 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_5-8 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_5-4 > li:before {
  content: 'o  ';
}
.lst-kix_list_5-5 > li:before {
  content: '\0025aa  ';
}
ol.lst-kix_list_12-5.start {
  counter-reset: lst-ctn-kix_list_12-5 0;
}
.lst-kix_list_6-1 > li:before {
  content: 'o  ';
}
.lst-kix_list_6-3 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_6-0 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_6-4 > li:before {
  content: 'o  ';
}
ul.lst-kix_list_16-2 {
  list-style-type: none;
}
ul.lst-kix_list_16-1 {
  list-style-type: none;
}
ul.lst-kix_list_16-0 {
  list-style-type: none;
}
.lst-kix_list_6-2 > li:before {
  content: '\0025aa  ';
}
ul.lst-kix_list_16-8 {
  list-style-type: none;
}
ul.lst-kix_list_16-7 {
  list-style-type: none;
}
ul.lst-kix_list_16-6 {
  list-style-type: none;
}
.lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}
.lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}
ul.lst-kix_list_16-5 {
  list-style-type: none;
}
ul.lst-kix_list_16-4 {
  list-style-type: none;
}
.lst-kix_list_6-8 > li:before {
  content: '\0025aa  ';
}
ul.lst-kix_list_16-3 {
  list-style-type: none;
}
.lst-kix_list_6-5 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_6-7 > li:before {
  content: 'o  ';
}
.lst-kix_list_6-6 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_7-4 > li:before {
  content: 'o  ';
}
.lst-kix_list_7-6 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_7-2 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_8-6 > li {
  counter-increment: lst-ctn-kix_list_8-6;
}
.lst-kix_list_12-6 > li {
  counter-increment: lst-ctn-kix_list_12-6;
}
ol.lst-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0;
}
.lst-kix_list_13-7 > li:before {
  content: '' counter(lst-ctn-kix_list_13-7, lower-latin) '. ';
}
.lst-kix_list_7-8 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_4-7 > li {
  counter-increment: lst-ctn-kix_list_4-7;
}
ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}
.lst-kix_list_15-5 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_13-4 > li {
  counter-increment: lst-ctn-kix_list_13-4;
}
.lst-kix_list_4-1 > li:before {
  content: '' counter(lst-ctn-kix_list_4-1, lower-latin) '. ';
}
.lst-kix_list_15-7 > li:before {
  content: 'o  ';
}
.lst-kix_list_4-3 > li:before {
  content: '' counter(lst-ctn-kix_list_4-3, decimal) '. ';
}
.lst-kix_list_4-5 > li:before {
  content: '' counter(lst-ctn-kix_list_4-5, lower-roman) '. ';
}
.lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}
.lst-kix_list_15-1 > li:before {
  content: 'o  ';
}
ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}
ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}
.lst-kix_list_15-3 > li:before {
  content: '\0025cf  ';
}
ol.lst-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0;
}
ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}
ol.lst-kix_list_8-8 {
  list-style-type: none;
}
.lst-kix_list_12-3 > li:before {
  content: ' ';
}
ol.lst-kix_list_8-4 {
  list-style-type: none;
}
.lst-kix_list_12-1 > li:before {
  content: ' ';
}
ol.lst-kix_list_8-5 {
  list-style-type: none;
}
ol.lst-kix_list_8-6 {
  list-style-type: none;
}
ol.lst-kix_list_8-7 {
  list-style-type: none;
}
ol.lst-kix_list_8-0 {
  list-style-type: none;
}
ol.lst-kix_list_8-1 {
  list-style-type: none;
}
ol.lst-kix_list_8-2 {
  list-style-type: none;
}
.lst-kix_list_13-3 > li {
  counter-increment: lst-ctn-kix_list_13-3;
}
ol.lst-kix_list_13-6.start {
  counter-reset: lst-ctn-kix_list_13-6 0;
}
ol.lst-kix_list_8-3 {
  list-style-type: none;
}
.lst-kix_list_13-3 > li:before {
  content: '' counter(lst-ctn-kix_list_13-3, decimal) '. ';
}
.lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}
.lst-kix_list_13-5 > li:before {
  content: '' counter(lst-ctn-kix_list_13-5, lower-roman) '. ';
}
.lst-kix_list_12-5 > li:before {
  content: ' ';
}
ol.lst-kix_list_13-7.start {
  counter-reset: lst-ctn-kix_list_13-7 0;
}
ol.lst-kix_list_12-2.start {
  counter-reset: lst-ctn-kix_list_12-2 0;
}
.lst-kix_list_12-7 > li:before {
  content: ' ';
}
.lst-kix_list_4-2 > li {
  counter-increment: lst-ctn-kix_list_4-2;
}
.lst-kix_list_13-1 > li:before {
  content: '' counter(lst-ctn-kix_list_13-1, lower-latin) '. ';
}
.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}
ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}
.lst-kix_list_3-0 > li:before {
  content: '\0025cf  ';
}
ul.lst-kix_list_5-7 {
  list-style-type: none;
}
ol.lst-kix_list_13-1.start {
  counter-reset: lst-ctn-kix_list_13-1 0;
}
ul.lst-kix_list_5-8 {
  list-style-type: none;
}
ul.lst-kix_list_5-5 {
  list-style-type: none;
}
ul.lst-kix_list_5-6 {
  list-style-type: none;
}
.lst-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0;
}
.lst-kix_list_8-0 > li {
  counter-increment: lst-ctn-kix_list_8-0;
}
ul.lst-kix_list_5-0 {
  list-style-type: none;
}
.lst-kix_list_3-4 > li:before {
  content: 'o  ';
}
ul.lst-kix_list_5-3 {
  list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
  content: '\0025cf  ';
}
ul.lst-kix_list_5-4 {
  list-style-type: none;
}
ul.lst-kix_list_5-1 {
  list-style-type: none;
}
.lst-kix_list_8-0 > li:before {
  content: '' counter(lst-ctn-kix_list_8-0, upper-latin) '. ';
}
ul.lst-kix_list_5-2 {
  list-style-type: none;
}
.lst-kix_list_8-7 > li:before {
  content: '' counter(lst-ctn-kix_list_8-7, lower-latin) '. ';
}
.lst-kix_list_3-8 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_13-1 > li {
  counter-increment: lst-ctn-kix_list_13-1;
}
.lst-kix_list_8-3 > li:before {
  content: '' counter(lst-ctn-kix_list_8-3, decimal) '. ';
}
.lst-kix_list_3-7 > li:before {
  content: 'o  ';
}
.lst-kix_list_8-4 > li:before {
  content: '' counter(lst-ctn-kix_list_8-4, lower-latin) '. ';
}
ol.lst-kix_list_8-5.start {
  counter-reset: lst-ctn-kix_list_8-5 0;
}
.lst-kix_list_11-1 > li:before {
  content: 'o  ';
}
.lst-kix_list_11-0 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_8-8 > li:before {
  content: '' counter(lst-ctn-kix_list_8-8, lower-roman) '. ';
}
ol.lst-kix_list_2-2 {
  list-style-type: none;
}
.lst-kix_list_16-8 > li:before {
  content: '\0025aa  ';
}
ol.lst-kix_list_2-3 {
  list-style-type: none;
}
ol.lst-kix_list_2-4 {
  list-style-type: none;
}
.lst-kix_list_16-7 > li:before {
  content: 'o  ';
}
ol.lst-kix_list_2-5 {
  list-style-type: none;
}
ol.lst-kix_list_2-0 {
  list-style-type: none;
}
ol.lst-kix_list_2-1 {
  list-style-type: none;
}
.lst-kix_list_4-8 > li:before {
  content: '' counter(lst-ctn-kix_list_4-8, lower-roman) '. ';
}
ol.lst-kix_list_12-5 {
  list-style-type: none;
}
ol.lst-kix_list_12-6 {
  list-style-type: none;
}
.lst-kix_list_4-7 > li:before {
  content: '' counter(lst-ctn-kix_list_4-7, lower-latin) '. ';
}
ol.lst-kix_list_12-7 {
  list-style-type: none;
}
ol.lst-kix_list_12-8 {
  list-style-type: none;
}
ol.lst-kix_list_12-1 {
  list-style-type: none;
}
ol.lst-kix_list_12-2 {
  list-style-type: none;
}
ol.lst-kix_list_12-3 {
  list-style-type: none;
}
ol.lst-kix_list_12-4 {
  list-style-type: none;
}
.lst-kix_list_16-0 > li:before {
  content: '\0025cf  ';
}
ol.lst-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0;
}
.lst-kix_list_8-4 > li {
  counter-increment: lst-ctn-kix_list_8-4;
}
.lst-kix_list_16-4 > li:before {
  content: 'o  ';
}
.lst-kix_list_16-3 > li:before {
  content: '\0025cf  ';
}
ol.lst-kix_list_2-6 {
  list-style-type: none;
}
ol.lst-kix_list_2-7 {
  list-style-type: none;
}
ol.lst-kix_list_2-8 {
  list-style-type: none;
}
ol.lst-kix_list_8-6.start {
  counter-reset: lst-ctn-kix_list_8-6 0;
}
ul.lst-kix_list_12-0 {
  list-style-type: none;
}
ol.lst-kix_list_8-0.start {
  counter-reset: lst-ctn-kix_list_8-0 0;
}
.lst-kix_list_7-0 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_13-8 > li {
  counter-increment: lst-ctn-kix_list_13-8;
}
.lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}
ol.lst-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0;
}
ol.lst-kix_list_13-8 {
  list-style-type: none;
}
.lst-kix_list_2-4 > li:before {
  content: '' counter(lst-ctn-kix_list_2-4, lower-latin) '. ';
}
.lst-kix_list_2-8 > li:before {
  content: '' counter(lst-ctn-kix_list_2-8, lower-roman) '. ';
}
ol.lst-kix_list_13-4 {
  list-style-type: none;
}
ol.lst-kix_list_13-5 {
  list-style-type: none;
}
ol.lst-kix_list_13-6 {
  list-style-type: none;
}
ol.lst-kix_list_13-7 {
  list-style-type: none;
}
ol.lst-kix_list_13-0 {
  list-style-type: none;
}
ol.lst-kix_list_13-1 {
  list-style-type: none;
}
ol.lst-kix_list_13-2 {
  list-style-type: none;
}
.lst-kix_list_7-3 > li:before {
  content: '\0025cf  ';
}
ol.lst-kix_list_13-3 {
  list-style-type: none;
}
ul.lst-kix_list_7-5 {
  list-style-type: none;
}
.lst-kix_list_10-0 > li:before {
  content: '\0025cf  ';
}
ul.lst-kix_list_7-6 {
  list-style-type: none;
}
ul.lst-kix_list_7-3 {
  list-style-type: none;
}
ul.lst-kix_list_7-4 {
  list-style-type: none;
}
.lst-kix_list_13-6 > li {
  counter-increment: lst-ctn-kix_list_13-6;
}
.lst-kix_list_13-8 > li:before {
  content: '' counter(lst-ctn-kix_list_13-8, lower-roman) '. ';
}
ul.lst-kix_list_7-7 {
  list-style-type: none;
}
ul.lst-kix_list_7-8 {
  list-style-type: none;
}
ul.lst-kix_list_7-1 {
  list-style-type: none;
}
.lst-kix_list_8-7 > li {
  counter-increment: lst-ctn-kix_list_8-7;
}
ul.lst-kix_list_7-2 {
  list-style-type: none;
}
ul.lst-kix_list_7-0 {
  list-style-type: none;
}
.lst-kix_list_7-7 > li:before {
  content: 'o  ';
}
ol.lst-kix_list_8-1.start {
  counter-reset: lst-ctn-kix_list_8-1 0;
}
.lst-kix_list_15-4 > li:before {
  content: 'o  ';
}
.lst-kix_list_10-4 > li:before {
  content: 'o  ';
}
.lst-kix_list_10-8 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_4-0 > li:before {
  content: '' counter(lst-ctn-kix_list_4-0, upper-latin) '. ';
}
ul.lst-kix_list_15-3 {
  list-style-type: none;
}
ul.lst-kix_list_15-2 {
  list-style-type: none;
}
.lst-kix_list_15-0 > li:before {
  content: '\0025cf  ';
}
ul.lst-kix_list_15-1 {
  list-style-type: none;
}
.lst-kix_list_15-8 > li:before {
  content: '\0025aa  ';
}
ul.lst-kix_list_15-0 {
  list-style-type: none;
}
.lst-kix_list_4-4 > li:before {
  content: '' counter(lst-ctn-kix_list_4-4, lower-latin) '. ';
}
ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}
ul.lst-kix_list_15-8 {
  list-style-type: none;
}
ul.lst-kix_list_15-7 {
  list-style-type: none;
}
ul.lst-kix_list_15-6 {
  list-style-type: none;
}
.lst-kix_list_9-3 > li:before {
  content: '\0025cf  ';
}
ul.lst-kix_list_15-5 {
  list-style-type: none;
}
ul.lst-kix_list_15-4 {
  list-style-type: none;
}
.lst-kix_list_12-8 > li {
  counter-increment: lst-ctn-kix_list_12-8;
}
ol.lst-kix_list_13-2.start {
  counter-reset: lst-ctn-kix_list_13-2 0;
}
ol.lst-kix_list_4-0 {
  list-style-type: none;
}
ol.lst-kix_list_4-1 {
  list-style-type: none;
}
ol.lst-kix_list_4-2 {
  list-style-type: none;
}
ol.lst-kix_list_4-3 {
  list-style-type: none;
}
.lst-kix_list_9-7 > li:before {
  content: 'o  ';
}
.lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}
.lst-kix_list_11-4 > li:before {
  content: 'o  ';
}
.lst-kix_list_12-4 > li:before {
  content: ' ';
}
ul.lst-kix_list_6-6 {
  list-style-type: none;
}
ul.lst-kix_list_6-7 {
  list-style-type: none;
}
ul.lst-kix_list_6-4 {
  list-style-type: none;
}
ul.lst-kix_list_6-5 {
  list-style-type: none;
}
ul.lst-kix_list_6-8 {
  list-style-type: none;
}
ol.lst-kix_list_4-8 {
  list-style-type: none;
}
.lst-kix_list_1-0 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
}
ol.lst-kix_list_4-4 {
  list-style-type: none;
}
ul.lst-kix_list_6-2 {
  list-style-type: none;
}
.lst-kix_list_11-8 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_12-3 > li {
  counter-increment: lst-ctn-kix_list_12-3;
}
ol.lst-kix_list_4-5 {
  list-style-type: none;
}
ul.lst-kix_list_6-3 {
  list-style-type: none;
}
ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}
ol.lst-kix_list_4-6 {
  list-style-type: none;
}
ul.lst-kix_list_6-0 {
  list-style-type: none;
}
.lst-kix_list_12-0 > li:before {
  content: '\0025cf  ';
}
ol.lst-kix_list_4-7 {
  list-style-type: none;
}
ul.lst-kix_list_6-1 {
  list-style-type: none;
}
ol.lst-kix_list_8-4.start {
  counter-reset: lst-ctn-kix_list_8-4 0;
}
.lst-kix_list_1-4 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-4, lower-roman) ') ';
}
.lst-kix_list_13-0 > li:before {
  content: '' counter(lst-ctn-kix_list_13-0, upper-latin) '. ';
}
ul.lst-kix_list_14-4 {
  list-style-type: none;
}
ul.lst-kix_list_14-3 {
  list-style-type: none;
}
.lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}
ol.lst-kix_list_13-0.start {
  counter-reset: lst-ctn-kix_list_13-0 0;
}
ul.lst-kix_list_14-2 {
  list-style-type: none;
}
.lst-kix_list_13-4 > li:before {
  content: '' counter(lst-ctn-kix_list_13-4, lower-latin) '. ';
}
ul.lst-kix_list_14-1 {
  list-style-type: none;
}
ul.lst-kix_list_14-0 {
  list-style-type: none;
}
ul.lst-kix_list_14-8 {
  list-style-type: none;
}
ul.lst-kix_list_14-7 {
  list-style-type: none;
}
.lst-kix_list_2-0 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, upper-latin) '. ';
}
ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}
ol.lst-kix_list_8-3.start {
  counter-reset: lst-ctn-kix_list_8-3 0;
}
ul.lst-kix_list_14-6 {
  list-style-type: none;
}
ul.lst-kix_list_14-5 {
  list-style-type: none;
}
.lst-kix_list_4-5 > li {
  counter-increment: lst-ctn-kix_list_4-5;
}
.lst-kix_list_1-8 > li:before {
  content: '' counter(lst-ctn-kix_list_1-8, lower-latin) ') ';
}
.lst-kix_list_12-8 > li:before {
  content: ' ';
}
.lst-kix_list_8-2 > li {
  counter-increment: lst-ctn-kix_list_8-2;
}
.lst-kix_list_4-1 > li {
  counter-increment: lst-ctn-kix_list_4-1;
}
ol.lst-kix_list_12-6.start {
  counter-reset: lst-ctn-kix_list_12-6 0;
}
.lst-kix_list_8-1 > li {
  counter-increment: lst-ctn-kix_list_8-1;
}
ol.lst-kix_list_8-2.start {
  counter-reset: lst-ctn-kix_list_8-2 0;
}
ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}
.lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}
ol.lst-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0;
}
.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}
ol.lst-kix_list_8-7.start {
  counter-reset: lst-ctn-kix_list_8-7 0;
}
.lst-kix_list_13-2 > li {
  counter-increment: lst-ctn-kix_list_13-2;
}
.lst-kix_list_12-1 > li {
  counter-increment: lst-ctn-kix_list_12-1;
}
ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
ol.lst-kix_list_13-3.start {
  counter-reset: lst-ctn-kix_list_13-3 0;
}
ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}
ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}
ol.lst-kix_list_1-3 {
  list-style-type: none;
}
ol.lst-kix_list_1-4 {
  list-style-type: none;
}
.lst-kix_list_2-7 > li:before {
  content: '' counter(lst-ctn-kix_list_2-7, lower-latin) '. ';
}
.lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}
ol.lst-kix_list_1-5 {
  list-style-type: none;
}
ol.lst-kix_list_1-6 {
  list-style-type: none;
}
ol.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_2-5 > li:before {
  content: '' counter(lst-ctn-kix_list_2-5, lower-roman) '. ';
}
ol.lst-kix_list_1-1 {
  list-style-type: none;
}
ol.lst-kix_list_1-2 {
  list-style-type: none;
}
ul.lst-kix_list_3-7 {
  list-style-type: none;
}
ul.lst-kix_list_3-8 {
  list-style-type: none;
}
.lst-kix_list_10-1 > li:before {
  content: 'o  ';
}
ul.lst-kix_list_3-1 {
  list-style-type: none;
}
ul.lst-kix_list_3-2 {
  list-style-type: none;
}
ul.lst-kix_list_3-0 {
  list-style-type: none;
}
ol.lst-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0;
}
ol.lst-kix_list_1-7 {
  list-style-type: none;
}
ul.lst-kix_list_3-5 {
  list-style-type: none;
}
ol.lst-kix_list_1-8 {
  list-style-type: none;
}
ul.lst-kix_list_3-6 {
  list-style-type: none;
}
ul.lst-kix_list_3-3 {
  list-style-type: none;
}
ul.lst-kix_list_3-4 {
  list-style-type: none;
}
.lst-kix_list_10-7 > li:before {
  content: 'o  ';
}
.lst-kix_list_10-5 > li:before {
  content: '\0025aa  ';
}
ol.lst-kix_list_13-5.start {
  counter-reset: lst-ctn-kix_list_13-5 0;
}
.lst-kix_list_10-3 > li:before {
  content: '\0025cf  ';
}
ul.lst-kix_list_11-7 {
  list-style-type: none;
}
ul.lst-kix_list_11-6 {
  list-style-type: none;
}
.lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}
ul.lst-kix_list_11-5 {
  list-style-type: none;
}
ul.lst-kix_list_11-4 {
  list-style-type: none;
}
ul.lst-kix_list_11-3 {
  list-style-type: none;
}
ol.lst-kix_list_13-8.start {
  counter-reset: lst-ctn-kix_list_13-8 0;
}
ul.lst-kix_list_11-2 {
  list-style-type: none;
}
ul.lst-kix_list_11-1 {
  list-style-type: none;
}
ul.lst-kix_list_11-0 {
  list-style-type: none;
}
.lst-kix_list_9-2 > li:before {
  content: '\0025aa  ';
}
ul.lst-kix_list_11-8 {
  list-style-type: none;
}
.lst-kix_list_12-5 > li {
  counter-increment: lst-ctn-kix_list_12-5;
}
.lst-kix_list_9-0 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_9-6 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_9-4 > li:before {
  content: 'o  ';
}
.lst-kix_list_11-3 > li:before {
  content: '\0025cf  ';
}
ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}
ol.lst-kix_list_12-1.start {
  counter-reset: lst-ctn-kix_list_12-1 0;
}
ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}
.lst-kix_list_11-5 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_9-8 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_1-1 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-1, upper-latin) ') ';
}
.lst-kix_list_11-7 > li:before {
  content: 'o  ';
}
ul.lst-kix_list_10-0 {
  list-style-type: none;
}
.lst-kix_list_8-5 > li {
  counter-increment: lst-ctn-kix_list_8-5;
}
.lst-kix_list_1-3 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-3, upper-roman) ') ';
}
ul.lst-kix_list_10-8 {
  list-style-type: none;
}
.lst-kix_list_4-8 > li {
  counter-increment: lst-ctn-kix_list_4-8;
}
ul.lst-kix_list_10-7 {
  list-style-type: none;
}
.lst-kix_list_1-7 > li:before {
  content: '' counter(lst-ctn-kix_list_1-7, lower-roman) ') ';
}
ul.lst-kix_list_10-6 {
  list-style-type: none;
}
ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}
ul.lst-kix_list_10-5 {
  list-style-type: none;
}
ul.lst-kix_list_10-4 {
  list-style-type: none;
}
ul.lst-kix_list_10-3 {
  list-style-type: none;
}
.lst-kix_list_1-5 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-5, lower-latin) ') ';
}
ul.lst-kix_list_10-2 {
  list-style-type: none;
}
ul.lst-kix_list_10-1 {
  list-style-type: none;
}
.lst-kix_list_2-1 > li:before {
  content: '' counter(lst-ctn-kix_list_2-1, lower-latin) '. ';
}
.lst-kix_list_2-3 > li:before {
  content: '' counter(lst-ctn-kix_list_2-3, decimal) '. ';
}
.lst-kix_list_3-1 > li:before {
  content: 'o  ';
}
.lst-kix_list_3-2 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_8-1 > li:before {
  content: '' counter(lst-ctn-kix_list_8-1, lower-latin) '. ';
}
ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}
.lst-kix_list_8-2 > li:before {
  content: '' counter(lst-ctn-kix_list_8-2, lower-roman) '. ';
}
.lst-kix_list_3-5 > li:before {
  content: '\0025aa  ';
}
ol.lst-kix_list_12-3.start {
  counter-reset: lst-ctn-kix_list_12-3 0;
}
.lst-kix_list_8-5 > li:before {
  content: '' counter(lst-ctn-kix_list_8-5, lower-roman) '. ';
}
.lst-kix_list_8-6 > li:before {
  content: '' counter(lst-ctn-kix_list_8-6, decimal) '. ';
}
.lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}
.lst-kix_list_3-6 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_11-2 > li:before {
  content: '\0025aa  ';
}
ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}
ol.lst-kix_list_12-4.start {
  counter-reset: lst-ctn-kix_list_12-4 0;
}
.lst-kix_list_16-6 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_4-4 > li {
  counter-increment: lst-ctn-kix_list_4-4;
}
.lst-kix_list_16-1 > li:before {
  content: 'o  ';
}
ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0;
}
.lst-kix_list_16-2 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_16-5 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_12-4 > li {
  counter-increment: lst-ctn-kix_list_12-4;
}
.lst-kix_list_12-7 > li {
  counter-increment: lst-ctn-kix_list_12-7;
}
.lst-kix_list_2-6 > li:before {
  content: '' counter(lst-ctn-kix_list_2-6, decimal) '. ';
}
.lst-kix_list_7-1 > li:before {
  content: 'o  ';
}
.lst-kix_list_7-5 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_13-5 > li {
  counter-increment: lst-ctn-kix_list_13-5;
}
.lst-kix_list_13-6 > li:before {
  content: '' counter(lst-ctn-kix_list_13-6, decimal) '. ';
}
.lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}
.lst-kix_list_15-6 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_10-2 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_4-6 > li {
  counter-increment: lst-ctn-kix_list_4-6;
}
.lst-kix_list_13-7 > li {
  counter-increment: lst-ctn-kix_list_13-7;
}
ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}
.lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}
.lst-kix_list_4-2 > li:before {
  content: '' counter(lst-ctn-kix_list_4-2, lower-roman) '. ';
}
.lst-kix_list_4-6 > li:before {
  content: '' counter(lst-ctn-kix_list_4-6, decimal) '. ';
}
.lst-kix_list_15-2 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_10-6 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_9-1 > li:before {
  content: 'o  ';
}
ol.lst-kix_list_12-7.start {
  counter-reset: lst-ctn-kix_list_12-7 0;
}
.lst-kix_list_12-2 > li {
  counter-increment: lst-ctn-kix_list_12-2;
}
.lst-kix_list_9-5 > li:before {
  content: '\0025aa  ';
}
.lst-kix_list_12-2 > li:before {
  content: ' ';
}
ol.lst-kix_list_12-8.start {
  counter-reset: lst-ctn-kix_list_12-8 0;
}
.lst-kix_list_11-6 > li:before {
  content: '\0025cf  ';
}
.lst-kix_list_1-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-2, lower-roman) ') ';
}
.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
.lst-kix_list_8-8 > li {
  counter-increment: lst-ctn-kix_list_8-8;
}
.lst-kix_list_1-6 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-6, decimal) ') ';
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_12-6 > li:before {
  content: ' ';
}
.lst-kix_list_2-2 > li:before {
  content: '' counter(lst-ctn-kix_list_2-2, lower-roman) '. ';
}
.lst-kix_list_13-2 > li:before {
  content: '' counter(lst-ctn-kix_list_13-2, lower-roman) '. ';
}
ol {
  margin: 0;
  padding: 0;
}
table {
  width: 100%;
}
table td,
table th {
  padding: 8px !important;
}
.c22 {
  border-right-style: solid;
  padding: 2.9pt 2.9pt 2.9pt 2.9pt;
  border-bottom-color: #bfbfbf;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #bfbfbf;
  vertical-align: top;
  border-right-color: #bfbfbf;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 155.8pt;
  border-top-color: #bfbfbf;
  border-bottom-style: solid;
}
.c31 {
  border-right-style: solid;
  padding: 2.9pt 2.9pt 2.9pt 2.9pt;
  border-bottom-color: #bfbfbf;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #bfbfbf;
  vertical-align: top;
  border-right-color: #bfbfbf;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 311.6pt;
  border-top-color: #bfbfbf;
  border-bottom-style: solid;
}
.c19 {
  margin-left: 54pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 6pt;

  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c6 {
  margin-left: 36pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 12pt;

  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c4 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 12pt;

  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c11 {
  -webkit-text-decoration-skip: none;
  color: #0000ff;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 1em;

  font-style: normal;
}
.c13 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;

  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c32 {
  margin-left: 72pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 6pt;

  orphans: 2;
  widows: 2;
  text-align: left;
}
.c7 {
  padding-top: 0pt;
  padding-bottom: 12pt;

  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c39 {
  padding-top: 0pt;
  padding-bottom: 12pt;

  orphans: 2;
  widows: 2;
  text-align: left;
}
.c18 {
  padding-top: 14pt;
  padding-bottom: 0pt;

  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c41 {
  padding-top: 0pt;
  padding-bottom: 6pt;

  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c21 {
  padding-top: 0pt;
  padding-bottom: 0pt;

  orphans: 2;
  widows: 2;
  text-align: left;
}
.c28 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.05;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c3 {
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 1em;

  font-style: normal;
}
.c43 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.05;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c2 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 1em;

  font-style: normal;
}
.c26 {
  padding-top: 12pt;
  padding-bottom: 12pt;

  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c27 {
  padding-top: 0pt;
  padding-bottom: 0pt;

  orphans: 2;
  widows: 2;
  text-align: center;
}
.c16 {
  padding-top: 0pt;
  padding-bottom: 0pt;

  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c14 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 1em;

  font-style: italic;
}
.c24 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #0000ff;
  text-decoration: underline;
}
.c40 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-style: normal;
}
.c17 {
  margin-left: -2.9pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}
.c8 {
  margin-left: 0pt;
  list-style-position: inside;
  page-break-after: avoid;
}
.c42 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c23 {
  margin-left: 36pt;
  text-indent: -18pt;
}
.c15 {
  text-decoration: inherit;
}
.c10 {
  padding: 0;
  margin: 0;
}
.c25 {
  margin-left: 36pt;
  padding-left: 0pt;
}
.c30 {
  margin-left: 18pt;
  padding-left: 0pt;
}
.c36 {
  text-indent: 18pt;
  height: 24pt;
}
.c20 {
  height: 1em;
}
.c29 {
}
.c1 {
  height: 0pt;
}
.c35 {
  text-indent: 36pt;
}
.c37 {
  font-style: italic;
}
.c12 {
  font-weight: 700;
}
.c34 {
  margin-left: 36pt;
}
.c33 {
  page-break-after: avoid;
}
.c38 {
  margin-left: 18pt;
}
.c0 {
}
.c9 {
  font-weight: 400;
}
.title {
  padding-top: 12pt;

  font-weight: 700;
  font-size: 16pt;
  padding-bottom: 12pt;

  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.subtitle {
  padding-top: 0pt;

  font-weight: 700;
  font-size: 1em;
  padding-bottom: 12pt;

  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  font-size: 1em;
}
p {
  margin: 0;

  font-size: 1em;
}
h1 {
  padding-top: 0pt;

  font-weight: 700;
  font-size: 1em;
  padding-bottom: 12pt;

  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h2 {
  padding-top: 0pt;

  font-size: 1em;
  padding-bottom: 12pt;

  orphans: 2;
  widows: 2;
  text-align: justify;
}
h3 {
  padding-top: 0pt;

  font-size: 1em;
  padding-bottom: 12pt;

  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 0pt;

  font-size: 1em;
  padding-bottom: 12pt;

  orphans: 2;
  widows: 2;
  text-align: justify;
}
h5 {
  padding-top: 0pt;

  font-size: 1em;
  padding-bottom: 12pt;

  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 0pt;

  font-size: 1em;
  padding-bottom: 12pt;

  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
