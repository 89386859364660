<template>
  <div class="navbarWrapper">
    <div class="navbar">
      <div @click="openUrl('https://twitter.com/PlayImpostors')">
        <img class="icon" src="@/images/twitter.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
   methods: {
    openUrl: (url) => {
      window.open(url, '_blank');
    },
   }
}
</script>

<style scoped lang="scss">
.navbarWrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 130px;
  background-image: url('../../../images/navbar.svg');
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: auto;

  .navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    padding-top: 20px;
    padding-right: 20px;

    .icon {
      width: 25px;
      object-fit: contain;
      cursor: pointer;
      transition: all 200ms ease;

      &:hover {
        opacity: 0.7;
      }

      &:active {
        opacity: 1;
      }
    }
  }
}

</style>
