<template>
  <div class="mint">
    <img src="https://miro.medium.com/max/1400/1*cTDIP-m43Q0MhnSRzGPd4g.jpeg" />

    <div class="dropdown" v-if="isConnected">
      <DropdownMenu
        class="dropdown"
        :selection="number"
        :items="numbers"
        @selected="handleSelection(index, $event)"
      />
      <button @click="purchaseItem">Mint</button>
    </div>

    <div v-if="!isConnected">
      Please connect your wallet.
    </div>
  </div>
</template>
<script>
// Imports.
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

// Component imports.
import DropdownMenu from '/src/components/ui/DropdownMenu.vue';

export default {
  components: {
    DropdownMenu
  },

  setup() {
    const store = useStore();

    const number = ref(1);
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const handleSelection = (index, selection) => {
      number.value = selection;
    };

    onMounted(() => {
      window.scroll(0, 0);
    });

    const isConnected = computed(() => {
      return store.state.ethers.canSign;
    });

    const isLive = computed(() => {
      return store.state.mint.startDate < new Date();
    });

    const purchaseItem = async () => {
      await store.dispatch(
        'mint/purchaseItem',
        {
          poolId: 0,
          groupId: 1,
          assetId: 0,
          amount: number.value
        },
        { root: true }
      );
    };

    return {
      isConnected,
      isLive,
      number,
      numbers,
      handleSelection,
      purchaseItem
    };
  }
};
</script>
<style scoped lang="scss">
.mint {
  padding: 60px 20px;
  display: flex;
  align-items: center;
  color: white;
  flex-direction: column;

  img {
    height: 300px;
    width: 300px;
    object-fit: contain;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    button {
      width: 80px;
      height: 35px;
    }
  }

  .bullImage {
    width: 240px;
    margin-bottom: 20px;
    border-radius: 50%;
  }

  .heading {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 1em;
    font-weight: 700;
    text-transform: uppercase;
  }

  .total {
    margin-bottom: 20px;
    font-weight: 700;
  }

  .subtitle {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .mintGrid {
    display: grid;
    align-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

@media (max-width: 700px) {
  .mint .heading {
    font-size: 12vw;
  }
}
</style>
