<template>
<div>
  <div class="tabsContainer">
    <SkewedTab
      @click="setTabIndex(index)"
      v-for="(tab, index) in tabs"
      :key="index"
      :isEnabled="currentTabIndex === index"
      :borderRadius="3"
      :skew="[0, 0, [-2, 0], [-3, 0]]"
      :background="'var(--color-button-yellow)'">
        <slot :tab="tab" :isSelected="currentTabIndex === index">
          {{tab}}
        </slot>
    </SkewedTab>
  </div>
  <SkewedDropdown class="dropdown" :items="tabs" :currentIndex="currentTabIndex" @selectItem="setTabIndex" />
</div>
</template>
<script setup>
import SkewedTab from '@/components/common/SkewedTab.vue';
import SkewedDropdown from '@/components/common/SkewedDropdown.vue';

let props = defineProps({
  tabs: {
    type: Array,
    required: true
  },
  currentTabIndex: {
    type: Number
  }
});

let emit = defineEmits(['changeTabIndex']);

const setTabIndex = (index) => {
  emit('changeTabIndex', index)
};

</script>
<style scoped lang="scss">
.tabsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.dropdown {
  display: none;
}

@media (max-width: 640px) {
  .tabsContainer {
    display: none;
  }

  .dropdown {
    display: inherit;
  }
}
</style>
