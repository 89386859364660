export const NFTS_GENESIS_DROP = {
  title: 'Genesis Drop',
  subcontent: 'The Genesis drop contains some of the scarcest and most critical NFTs in the entire Impostors metaverse.<br><br>They grant holders early-access to the most pivotal elements in the metaverse and give each holder a significant play-and-earn advantage.'
};

export const NFTS_WHAT_IS_ITEMS = [
  {
    title: 'Genesis Season',
    subcontent: 'The Genesis Season consists of a variety of events exclusively available to holders of the Genesis Season Pass alien. Each event is designed to test the sus-ness of its holders and form the strongest community in the metaverse.'
  },
  {
    title: 'Intergalactic Scarcity',
    subcontent: 'There will only ever be 10,420 Impostors Genesis Aliens. The Genesis Drop will be limited to 10,000 items for sale. 420 Items will be reserved for the team, marketing, giveaways, and other promotional events.'
  }
];
