<template>
  <transition name="fade" mode="out-in">
    <div class="wallet-modal-container" v-if="store.state.alert.showWalletModal">
      <WalletModal @exit="hideWalletModal" />
    </div>
  </transition>
  <div class="connectWalletWrapper">
    <SkewedButton
      @click="showWalletModal"
      :borderRadius="6"
      :skew="[0, -2, 0, [4, 4]]"
      background="var(--color-button-yellow)"
      class="connect-button"
    >Login</SkewedButton>
  </div>
</template>
<script setup>
// Imports.
import { useStore } from 'vuex';
import { computed } from 'vue';

// Component imports.
import WalletModal from '/src/pages/layout/WalletModal.vue';
//import Button from '/src/components/ui/Button.vue';
import SkewedButton from './SkewedButton.vue';

let props = defineProps()

const store = useStore();

const isWalletModalVisible = computed(() => {
  return store.state.alert.showWalletModal;
});

const showWalletModal = () => {
  store.dispatch('alert/showWalletModal', { root: true });
};

const hideWalletModal = () => {
  store.dispatch('alert/hideWalletModal', { root: true });
};

</script>
<style scoped lang="scss">
.connectWalletWrapper {
  width: 100%;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
// Animations
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.connect-button {
  font-weight: bold;
  text-transform: uppercase;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
