import { ref, unref, computed, watchEffect, watch } from 'vue';

export default function useCountdown({
  endDate = new Date(Date.UTC(2022, 2, 7, 20, 0, 0))
} = {}) {
  let endDateUnref = unref(endDate);
  let countDownDifference = ref(endDateUnref - new Date().getTime());

  const formattedDaysLeft = computed(() => {
    var daysLeft = Math.floor(
      countDownDifference.value / (24 * 60 * 60 * 1000)
    );
    return daysLeft < 0 ? 0 : ('0' + daysLeft).slice(-2);
  });

  const formattedHoursLeft = computed(() => {
    var hoursLeft = Math.floor(
      (countDownDifference.value % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
    );
    return hoursLeft < 0 ? 0 : ('0' + hoursLeft).slice(-2);
  });

  const formattedMinutesLeft = computed(() => {
    var minutesLeft = Math.floor(
      (countDownDifference.value % (60 * 60 * 1000)) / (1000 * 60)
    );
    return minutesLeft < 0 ? 0 : ('0' + minutesLeft).slice(-2);
  });

  const formattedSecondsLeft = computed(() => {
    var secondsLeft = Math.floor(
      (countDownDifference.value % (60 * 1000)) / 1000
    );
    return secondsLeft < 0 ? 0 : ('0' + secondsLeft).slice(-2);
  });

  watchEffect(() => {
    if (countDownDifference.value > 0) {
      setTimeout(() => {
        let endDateUnref = unref(endDate);
        var now = new Date().getTime();

        var distance = endDateUnref - now;
        countDownDifference.value = distance;
      }, 1000);
    }
  });

  watch(endDate, () => {
    let endDateUnref = unref(endDate);
    countDownDifference.value = endDateUnref - new Date().getTime();
  });

  return {
    formattedDaysLeft,
    formattedHoursLeft,
    formattedMinutesLeft,
    formattedSecondsLeft,
    days: formattedDaysLeft,
    hours: formattedHoursLeft,
    minutes: formattedMinutesLeft,
    seconds: formattedSecondsLeft,
    timeLeft: countDownDifference,
  };
}
