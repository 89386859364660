<template>
  <div class="walletModal__overlay" @click="exitModal"></div>
  <transition name="slide" appear>
	<div
      v-if="!showWeb3Modal"
      class="impostorsModal"
    >
      <img
        class="impostor-chilling"
        src="~@/assets/impostor-chilling.webp"
      />
      <SkewedDiv
        :border-radius="5"
        background="#5971C5"
        class="login-row"
        class-content="login-row-content"
        :skew="[0, 2, 10, -10]"
	v-if="!this.ethereum.address"
      >
        <div class="col_text">
          <h2>WEB3 LOGIN</h2>
        </div>
        <div class="col_controls">
          <SkewedButton
            class="button"
            :border-radius="6"
            :skew="[0, 0, [-5, -3], 0]"
            background="var(--color-button-yellow)"
            @click="web3Modal = true"
          >
            LOGIN
          </SkewedButton>
        </div>
      </SkewedDiv>

      <SkewedDiv
        v-if="playFabEnabled"
        :border-radius="5"
        background="#5971C5"
        class="login-row"
        class-content="login-row-content"
        :skew="[0, 2, 10, -10]"
      >
        <div class="col_text">
          <h2>IMPOSTOR LOGIN</h2>
        </div>
        <div class="col_controls">
          <router-link
            to="/account"
            class="link"
          >
            <SkewedButton
              class="button link"
              :border-radius="6"
              :skew="[0, 0, [-5, -3], 0]"
              background="#211226"
              @click="exitModal()"
            >
              LOGIN
            </SkewedButton>
          </router-link>
        </div>
      </SkewedDiv>
    </div>
    <div
      v-else
      class="modal"
    >
      <p>{{ modalPrompt }}</p>
      <div class="wallet-provider" @click="handleMetaMask">
        <p><span v-if="!ethereum.hasLocalProvider">Get</span> MetaMask</p>
        <img src="../../images/metamask-icon.svg" />
      </div>
      <br />
	  <br />
      <div class="wallet-provider" @click="handleWalletConnect">
        <p>WalletConnect</p>
        <img src="../../images/walletconnect-logo.svg" />
      </div>
    </div>
  </transition>
</template>

<script>
'use strict';

// Imports.
import { mapState } from 'vuex';
import store from '../../store';
import SkewedDiv from '/src/components/common/SkewedDiv';
import SkewedButton from '@/components/common/SkewedButton.vue';
import { flag } from '@/featureflag';

// Set up the default component.
export default {
  components: {
    SkewedDiv,
    SkewedButton
  },
  emits: ['exit'],

  data() {
    return {
      show: true,
	  web3Modal: false
    };
  },

  computed: {
    ...mapState({
      ethereum: state => state.ethers
    }),
    modalPrompt() {
      return 'Connect to your Wallet';
	},
    showWeb3Modal() {
      return this.web3Modal;
    },
    playFabEnabled() {
      return flag.playFabEnabled.value;
    }
  },

  methods: {
    async handleMetaMask() {
      if (this.ethereum.hasLocalProvider) {
        await store.dispatch('ethers/connectWallet', '', { root: true });
        this.$emit('exit');
        // If there is no local Ethereum provider, this button should direct the
        // user to install MetaMask.
      } else {
        window.open('https://www.metamask.io', '_blank');
      }
    },
    async handleWalletConnect() {
	  this.$emit('exit');
      await store.dispatch('ethers/connectWCProvider', '', { root: true });
    },
    exitModal() {
      this.$emit('exit');
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/style/mixins';
.walletModal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}

.impostorsModal {
  background-color: var(--color-accent-blue);
  /* border: 1px solid white; */
  border-radius: 24px;
  color: #fff;
  height: 400px;
  left: 50%;
  padding: 40px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  z-index: 999;
}
.impostor-chilling {
  left: -20px;
  position: absolute;
  top: -20px;
}
.impostorsModal > p {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3em;
  margin-bottom: 32px;
  text-align: center;
}
.login-row {
  margin-bottom: 50px;
  margin-left: 230px;
  margin-top: 10px;
  padding: 12px;
  padding-right: 36px;
  width: 60%;
  :deep(.login-row-content) {
    display: grid;
    gap: 18px;
    grid-template-areas:
      'text'
      'controls';
    grid-template-columns: auto;
    justify-items: center;
  }
  :deep(.button) {
    font-size: 16px;
    font-weight: bold;
    height: 20px;
    margin-bottom: 6px;
    width: 200px;
  }
  :deep(.link) {
    color: #ff0;
    text-decoration: none;
    &:visited {
      color: #ff0;
      text-decoration: none;
    }
  }
  :deep(h2) {
    margin-bottom: -10px;
    margin-top: 20px;
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 400px;
  padding: 40px;
  background-color: var(--color-accent-blue);
  color: white;
  /* border: 1px solid white; */
  border-radius: 24px;
}

.modal > p {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 1.3em;
  font-weight: 600;
  text-align: center;
}

.wallet-provider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  transition: box-shadow 200ms ease;
  font-size: 16px;
  font-weight: 500;
  height: 70px;
  padding: 15px 25px;
}

.wallet-provider:hover {
  cursor: pointer;
  box-shadow: 0 8px 15px 0 rgba(255, 255, 255, 0.25);
}

.wallet-provider:active {
  box-shadow: none;
}

.slide-enter-active {
  transition: opacity 0.5s ease;
}

.slide-enter-from,
.slide-leave-active {
  opacity: 0;
}
</style>
