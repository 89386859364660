<template>
  <Ethereum :callback="initialize" />
  <div v-if="isLoading" class="skeleton">
    <MintSkeleton />
  </div>
  <div v-else class="mint">
    <div class="heading">Wallet connect test</div>

    <div class="text" v-if="!isConnected">
      <ConnectWallet />
    </div>
    <div class="buyWrapper" v-else>
      <div v-if="store.state.mint.shopConfig">
        <section>
          <h2>Whitelist (SUPER)</h2>
          <p>starttime: {{ new Date(wl_tokenStartTime) }}</p>
          <p>endtime: {{ new Date(wl_tokenEndTime) }}</p>
          <p>starting price: {{ wl_tokenStartingPrice }}</p>
          <div>
            <Button @click="mint(1, true)">Mint</Button>
          </div>
        </section>

        <section>
          <h2>Whitelist (ETH)</h2>
          <p>starttime: {{ new Date(wl_ethStartTime) }}</p>
          <p>endtime: {{ new Date(wl_ethEndTime) }}</p>
          <p>starting price: {{ wl_ethStartingPrice }}</p>
          <div>
            <Button @click="mint(1, false)">Mint</Button>
          </div>
        </section>

        <section>
          <h2>Dutch Auction</h2>
          <p>starttime: {{ new Date(publicStartTime) }}</p>
          <p>endtime: {{ new Date(publicEndTime) }}</p>
          <p>starting price: {{ publicStartingPrice }}</p>
          <p>ending price: {{ publicEndingPrice }}</p>
          <p>current price: {{ currentPrice }}</p>
          <p>next price: {{ nextPrice }}</p>
          <p>transactionCap: {{ transactionCap }}</p>
          <p>callerCap: {{ callerCap }}</p>
          <p>tickDuration: {{ tickDuration }}</p>
          <p>tickAmount: {{ tickAmount }}</p>
          <p>countdownBar (0-100): {{ countdownBar }}</p>
          <p>expectedCurrentPrice (wei): {{ expectedCurrentPrice }}</p>
          <div>
            <Button @click="mint(1, false)">Mint</Button>
          </div>
        </section>

        <section>
          <h2>Phase</h2>
          <div v-if="isWhitelist">Whitelists are open</div>
          <div v-else-if="isDutchAuction">Dutch auction open</div>
          <div v-else>Minting closed</div>
        </section>

        <section>
          <h2>Stats</h2>
          <p>Minted: {{ sold }} / {{ totalCap }}</p>
        </section>

        <section>
          <h2>Wallet Info</h2>
          <p>Address: {{ currentAddress }}</p>
          <p>Whitelist Allowance: {{ allowance }}</p>

          <section style="margin: 5px 10px" v-for="(item, key) in owned" :key="key">
            <h3>Impostor ID: {{ item.tokenId }}</h3>
            <p>blockHash: {{ item.blockHash }}</p>
            <p>blockNumber: {{ item.blockNumber }}</p>
            <p>data: {{ item.data }}</p>
            <p>txHash: {{ item.txHash }}</p>
            <p>txIndex: {{ item.txIndex }}</p>

            <section style="margin: 5px 10px">
              <!-- example of conditional showing based on error -->
              <p v-if="item.metadata.err">Error: {{ item.metadata.err }}</p>

              <!-- looping through all json attributes -->
              <p
                style="max-width:600px; margin-bottom: 5px;"
                v-for="(value, attr, key) in item.metadata"
                :key="key"
              >
                <b>{{ attr }}</b>
                : {{ value }}
              </p>
            </section>
          </section>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
// Imports.
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import initializeConfig from '/src/initialize-config';

// Component imports.
import Button from '/src/components/ui/Button.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import MintSkeleton from './components/MintSkeleton.vue';
import ConnectWallet from '/src/components/common/ConnectWallet.vue';

export default {
  components: {
    Button,
    Ethereum,
    MintSkeleton,
    ConnectWallet
  },

  setup() {
    const isLoading = ref(true);
    const store = useStore();
    let config; //global config
    const owned = ref([]);

    onMounted(() => {
      window.scroll(0, 0);
    });

    const initialize = async () => {
      isLoading.value = true;

      // load global config
      config = await initializeConfig();

      // delay to ensure all ABIs have loaded
      // not needed once we load ABIs directly instead of via http/get
      await new Promise(resolve => setTimeout(resolve, 100));

      // retrieve shop config
      if (isConnected.value) {
        loadMerkleData();
        loadShopConfig();
        loadCurrentPrice();
        loadOwnedItems();
      }
      isLoading.value = false;
    }

    const loadCurrentPrice = async () => {
      if (isConnected.value) {
        await store.dispatch(
          'mint/currentPrice',
          {},
          { root: true }
        );
        return store.state.mint.currentPrice;
      }
    }

    const loadShopConfig = async () => {
      if (isConnected.value) {
        console.info("loading shop config at address=", config.shopAddress[store.state.ethers.networkId]);
        await store.dispatch(
          'mint/loadShopConfig',
          {},
          {
            root: true
          }
        );
      }
    }

    const loadMerkleData = async () => {
      // load whitelist merkle proofs
      await store.dispatch(
        'mint/loadMerkleData',
        {},
        { root: true }
      );
    }

    const loadOwnedItems = async () => {
      console.info("loading owned items at address=", config.itemCollections[store.state.ethers.networkId][0]);
      await store.dispatch(
        'mint/loadItems',
        {
          mintStore: store.state.mint, // wallet address
        },
        {
          root: true
        }
      );
      owned.value = store.state.mint.owned;
      // fulfill promises before returning...
      for (let i = 0; i < owned.value.length; i++) {
        let o = owned.value[i];
        let p = await Promise.resolve(o.metadata);
        o.metadata = p;
      }
    }

    const isConnected = computed(() => {
      return store.state.ethers.canSign;
    });

    // update 'now' to current time every 1 sec
    let now = ref(new Date());
    setInterval(() => {
      now.value = new Date();
    }, 1000);

    // update current item price every 10 sec
    setInterval(() => {
      // store.state.mint.shopConfig gets clobbered somewhere when RPC connection fails
      if (isConnected.value) {
        loadCurrentPrice();
      }
    }, 1000 * 5);

    const isWhitelist = computed(() => {
      // uses shopConfig start/end times
      return (now.value >= wl_tokenStartTime.value && now.value <= wl_tokenEndTime.value) || (now.value >= wl_ethStartTime.value && now.value <= wl_ethEndTime.value);
    });

    const isDutchAuction = computed(() => {
      // uses shopConfig start/end times
      return (now.value >= publicStartTime.value && now.value <= publicEndTime.value);
    });

    // computed/helper functions for shop config data
    const publicStartTime = computed(() => {
      return store.state.mint.shopConfig.publicStartTime;
    });

    const publicEndTime = computed(() => {
      return store.state.mint.shopConfig.publicEndTime;
    });

    const publicStartingPrice = computed(() => {
      return store.state.mint.shopConfig.publicStartingPrice;
    });

    const publicEndingPrice = computed(() => {
      return store.state.mint.shopConfig.publicEndingPrice;
    });

    const wl_tokenStartTime = computed(() => {
      return store.state.mint.shopConfig.tokenStartTime;
    });

    const wl_tokenEndTime = computed(() => {
      return store.state.mint.shopConfig.tokenEndTime;
    });

    const wl_tokenStartingPrice = computed(() => {
      return store.state.mint.shopConfig.tokenStartingPrice;
    });

    const wl_ethStartTime = computed(() => {
      return store.state.mint.shopConfig.ethStartTime;
    });

    const wl_ethEndTime = computed(() => {
      return store.state.mint.shopConfig.ethEndTime;
    });

    const wl_ethStartingPrice = computed(() => {
      return store.state.mint.shopConfig.ethStartingPrice;
    });

    const sold = computed(() => {
      return store.state.mint.shopConfig.sold;
    });

    const totalCap = computed(() => {
      return store.state.mint.shopConfig.totalCap;
    });

    const callerCap = computed(() => {
      return store.state.mint.shopConfig.callerCap;
    });

    const transactionCap = computed(() => {
      return store.state.mint.shopConfig.transactionCap;
    });

    const tickDuration = computed(() => {
      return store.state.mint.shopConfig.tickDuration;
    });

    const tickAmount = computed(() => {
      return store.state.mint.shopConfig.tickAmount;
    });

    const countdownBar = computed(() => {
      let timeElapsed = (now.value - publicStartTime.value) / 1000;
      if (timeElapsed > 0) {
        let ticksElapsed = timeElapsed / tickDuration.value;
        return Math.floor(100 - (100 * (timeElapsed % tickDuration.value) / tickDuration.value));
      } else {
        return 100;
      }
    });

    const expectedCurrentPrice = computed(() => {
      let timeElapsed = (now.value - publicStartTime.value) / 1000;
      if (timeElapsed > 0) {
        let ticksElapsed = timeElapsed / tickDuration.value;
        let priceDecrease = ticksElapsed * tickAmount.value;
        return store.state.mint.shopConfig.publicStartingPriceWei - priceDecrease;
      }
      return store.state.mint.shopConfig.publicStartingPriceWei;
    });

    // computed/helper functions for current price and allowance
    const currentPrice = computed(() => {
      return store.state.mint.currentPrice;
    });

    const nextPrice = computed(() => {
      store.dispatch(
        'mint/calcEth',
        {
          eth: currentPrice.value,
          wei: tickAmount.value
        },
        { root: true }
      );
      return store.state.mint.nextPrice;
    });

    const currentAddress = computed(() => {
      return store.state.ethers.address;
    });

    const allowance = computed(() => {
      const hasProofs = store.state.mint.merkleProofs.length > 0;
      return (hasProofs ? store.state.mint.merkleProofs[0].allowance : 0);
    });

    const mint = async (qnt, useToken) => {
      // if whitelist is open but user not in whitelist
      if (isWhitelist.value && store.state.mint.merkleProofs.length == 0) {
        await store.dispatch(
          'alert/error',
          {
            message: 'Wallet is not whitelisted',
            duration: 10000
          },
          { root: true }
        );
        return;
      }

      // if whitelist and dutchauction period is over
      if (!isWhitelist.value && !isDutchAuction.value) {
        await store.dispatch(
          'alert/error',
          {
            message: 'Mint period closed',
            duration: 10000
          },
          { root: true }
        );
        return;
      }

      // otherwise, mint....
      await store.dispatch(
        'mint/mint',
        {
          qnt: qnt, // amount of impostors to mint
          isToken: useToken, // user by store/mint.service to correctly calculate amounts to be paid
          isWhitelist: isWhitelist.value, // used by store.service to calculate proof (whitelist)
          mintStore: store.state.mint, // mint prices and whitelists
        },
        { root: true }
      );

      // when successful, delay a tiny bit before refreshing store config (and sold values)
      await new Promise(resolve => setTimeout(resolve, 500));

      loadShopConfig();
      loadOwnedItems();
    };

    watch(
      currentAddress, (curAdd, oldAdd) => {
        initialize();
      }
    );

    return {
      initialize,

      publicStartTime,
      publicEndTime,
      publicStartingPrice,
      publicEndingPrice,
      wl_tokenStartTime,
      wl_tokenEndTime,
      wl_tokenStartingPrice,
      wl_ethStartTime,
      wl_ethEndTime,
      wl_ethStartingPrice,
      sold,
      totalCap,
      transactionCap,
      tickDuration,
      tickAmount,
      callerCap,

      now,
      currentPrice,
      nextPrice,
      currentAddress,
      allowance,
      owned,

      store,

      mint,

      countdownBar,
      expectedCurrentPrice,

      isConnected,
      isLoading,
      isWhitelist,
      isDutchAuction,
    };
  }
};
</script>
<style scoped lang="scss">
.skeleton {
  padding: 0px 20px;
  padding-bottom: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mint {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  section {
    margin-bottom: 20px;
  }

  button {
    cursor: pointer;
    padding: 5px 20px;
    border-radius: 10px;
    height: 100%;
    transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
      transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    color: white;
    background-color: #f277ad;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    letter-spacing: 3px;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    display: inline-block;
    margin-top: 10px;
  }

  button:hover {
    box-shadow: 0 14px 40px 0 rgba(0, 0, 0, 0.2);
    transform: translate(0px, -2px) scale(1.05);
    color: white;
  }

  button:active {
    box-shadow: none;
    transform: none;
  }

  .buyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .mintWrapper {
      margin-bottom: 20px;

      p {
        margin-bottom: 10px;
      }
    }

    .dropdown {
      grid-template-columns: auto 1fr;
      margin-right: 10px;
    }
  }

  .mintWrapper {
    margin-bottom: 20px;

    p {
      margin-bottom: 10px;
    }
  }

  .dropdownWrapper {
    .dropdown {
      display: grid;
      grid-template-columns: auto 1fr;
      margin-right: 10px;
    }
  }

  .heading {
    margin-bottom: 50px;
    font-size: 30px;
    line-height: 1em;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  .text {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    text-shadow: 0 0 2px white;
  }

  .imageContainer {
    max-width: 250px;
    max-height: 250px;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      border: 2px solid white;
    }
  }
}

@media (max-width: 700px) {
  .mint .heading {
    font-size: 12vw;
  }
}
</style>
