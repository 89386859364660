<template>
  <div class="toast" :style="toastStyle">
    <GreenCheckIcon v-if="isTransactionConfirmed" />
    <ToastSpinnerIcon v-if="isTransactionSubmitted" />
    <div>
      <div class="toast-title" :style="messageStyle">{{ message }}</div>
      <div v-if="metadata.amount">{{ metadata.amount }}</div>
    </div>
    <div class="button" v-if="metadata.transaction">
      <ToastButton
        :isSecondary="true"
        @click="openEtherscan(metadata.transaction)"
      >
        <p class="button-text">View on Etherscan</p>
      </ToastButton>
    </div>
    <button class="close" :style="buttonStyle" @click="closeToast">
      <CloseIcon />
    </button>
  </div>
</template>

<script>
// Imports.
import { defineToastComponent } from 'vue-my-toasts';
import { mapActions } from 'vuex';
import store from '/src/store';

// Icon Imports.
import CloseIcon from '/src/components/icons/CloseIcon';
import ToastSpinnerIcon from '/src/components/icons/ToastSpinnerIcon';
import GreenCheckIcon from '/src/components/icons/GreenCheckIcon';

// Component Imports.
import ToastButton from '/src/components/ui/ToastButton';

export default defineToastComponent({
  name: 'Toast Component',
  components: {
    ToastButton,
    CloseIcon,
    ToastSpinnerIcon,
    GreenCheckIcon
  },

  props: {
    badge: {
      type: [String, Boolean],
      required: false,
      default: false,

      id: {
        type: String,
        required: true
      },

      duration: {
        type: Number,
        required: false,
        default: 3000 // Default timing: 3 seconds
      },

      message: {
        type: String,
        required: true
      },

      metadata: {
        type: Object
      }
    },

    metadata: {
      type: Object
    }
  },

  // Update global list of toast ids.
  mounted() {
    store.dispatch(
      'alert/updateDeployedToastIds',
      { deployedToastId: this.id },
      { root: true }
    );
  },

  computed: {
    isTransactionConfirmed() {
      return this.message === 'Transaction Confirmed';
    },

    isTransactionSubmitted() {
      return this.message === 'Transaction Submitted';
    },

    messageStyle() {
      if (
        this.type === 'success' ||
        this.type === 'warning' ||
        this.type === 'error'
      ) {
        return {
          color: 'white'
        };
      } else {
        return {};
      }
    },

    toastStyle() {
      if (this.type === 'base') {
        return {};
      } else if (this.type === 'success') {
        return {
          backgroundColor: 'green'
        };
      } else if (this.type === 'warning') {
        return {
          backgroundColor: 'orange'
        };
      } else if (this.type === 'error') {
        return {
          backgroundColor: '#cd0000'
        };
      }
    },

    buttonStyle() {
      if (
        this.type === 'success' ||
        this.type === 'warning' ||
        this.type === 'error'
      ) {
        return {
          color: 'white'
        };
      } else {
        return {};
      }
    }
  },

  methods: {
    ...mapActions('toast', ['updateDeployedToastIds']),

    closeToast() {
      this.$emit('remove');
    },

    openEtherscan(transaction) {
      window.open(`https://etherscan.io/tx/${transaction}`, '_blank');
    }
  }
});
</script>

<style scoped>
.toast {
  display: grid;
  max-height: 100%;
  padding: 20px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: auto;
  grid-template-rows: auto;
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-accent-shade);
  border-radius: 10px;
  background-color: var(--color-accent-blue);
  backdrop-filter: blur(40px);
  position: relative;
  width: fit-content;
  word-wrap: break-word;
  max-width: 96vw;
  color: white;
}

.toast-title {
  margin-left: 10px;
  max-width: 90vw;
}

.button-text {
  display: block;
}

.close {
  right: 15px;

  display: flex;
  width: 40px;
  height: 40px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer;
  background: none;
  border: none;
  color: white;
}

.close:hover {
  background-color: rgba(255, 2555, 255, 0.1);
}

.close:active {
  background-color: rgba(255, 255, 255, 0.2);
}
</style>
