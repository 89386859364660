'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';
import { errMsg, processError } from '../utility';

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

const big2ts = bn => bn.toNumber() * 1000;

const calcEth = (eth, wei) => {
  return ethers.utils.formatEther(ethers.utils.parseEther(eth).sub(wei));
};

// attempts to hit contract. on failure,
// flags caller.
// returns: error message (string)
export const checkValidProvider = async () => {
  try {
    // find out if network is valid (or fail)
    let p = await ethersService.getProvider();
    // check what current provider network is using
    let n = await p.getNetwork();
    if (config.forceNetwork != n.chainId) {
      // if current network is not the one user in enforcing, fail
      return errMsg('WrongNetwork');
    }
    // attempts to call expected function from known mint contract
    let networkId = ethers.utils.hexValue(n.chainId);
    let s = config.shopAddress[networkId];
    let c = new ethers.Contract(s, config.mintShopABI, p);
    await c.startTime();
    return null;
  } catch (error) {
    return errMsg('WrongNetwork');
  }
};

const getSoldCount = async function() {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];

  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );

  let sold = await shopContract.sold();

  return (sold) ? sold.toNumber() : 0;
};

// loads shop config
const loadShopConfig = async function(dispatch) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let shopAddress = config.shopAddress[networkId];
  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );

  /// The time when the public sale begins.
  let pStart = await shopContract.startTime();

  /// The time when the public sale ends.
  let pEnd = await shopContract.endTime();

  /// The price at which the public sale is set.
  let pPrice = await shopContract.price();

  /// The total number of items sold by the shop.
  let sold = await shopContract.sold();

  /// The maximum number of items from the `collection` that may be sold.
  let totalCap = await shopContract.totalCap();

  /// The maximum number of items that a single address may purchase.
  let callerCap = await shopContract.callerCap();

  /// The maximum number of items that may be purchased in a single transaction.
  let transactionCap = await shopContract.transactionCap();

  let numWl = await shopContract.whitelistCount();
  let tokStart, tokEnd, tokPrice, eStart, eEnd, ePrice;
  for (let j = 0; j < numWl; j++) {
    const wl = await shopContract.whitelists(j);
    if (wl.token == ethers.constants.AddressZero) {
      eStart = wl.startTime;
      eEnd = wl.endTime;
      ePrice = wl.price;
    } else {
      tokStart = wl.startTime;
      tokEnd = wl.endTime;
      tokPrice = wl.price;
    }
  }

  return {
    publicStartTime: big2ts(pStart),
    publicEndTime: 1743107210000,
    publicStartingPrice: ethers.utils.formatEther(pPrice),
    publicStartingPriceWei: pPrice,
    tokenStartTime: big2ts(tokStart),
    tokenEndTime: big2ts(tokEnd),
    tokenStartingPrice: ethers.utils.formatEther(tokPrice),
    tokenStartingPriceWei: tokPrice,
    ethStartTime: big2ts(eStart),
    ethEndTime: big2ts(eEnd),
    ethStartingPrice: ethers.utils.formatEther(ePrice),
    ethStartingPriceWei: ePrice,
    sold: sold,
    transactionCap: transactionCap,
    totalCap: totalCap,
    callerCap: callerCap
  };
};

// Get the current price of an item
const currentPrice = async function() {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];

  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );

  let currentPrice = await shopContract
    .price()
    .then(p => ethers.utils.formatEther(p));
  return currentPrice;
};

// Purchase an item from the shop.
const purchaseItem = async function(
  qnt,
  isToken,
  isWhitelist,
  mintStore,
  ethersStore,
  dispatch
) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];
  let tokenAddress = config.superTokenAddress[networkId];

  let isValid = await ethers.utils.isAddress(shopAddress);
  if (!isValid) {
    return; // TODO: throw useful error.
  }

  // Used while checking for token spend approval.
  let signer = await provider.getSigner();

  // Purchase the item.
  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    signer
  );

  let proof = {
    id: isToken ? 1 : 0,
    index: 0,
    allowance: 0
  };

  if (mintStore.merkleProofs.length == 0) {
    // proof is only verified when whitelist are open, so using spoofed values have no negative impact during public mints
    let nilLeaf = ethers.utils.solidityKeccak256(
      ['uint256', 'address', 'uint256'],
      [1, ethers.constants.AddressZero, 0]
    );
    proof.proof = [nilLeaf, nilLeaf, nilLeaf];
  } else {
    for (let listEntry of mintStore.merkleProofs) {
      proof.proof = listEntry.proof;
      proof.index = listEntry.index;
      proof.allowance = listEntry.allowance;
    }
  }

  /*
   * calculate price: if whitelist, take in consideration shopConfig and token/eth values.
   *                 otherwise, ping shopContract for current price
   */
  let price = 0;
  if (isWhitelist) {
    price = isToken
      ? mintStore.shopConfig.tokenStartingPriceWei
      : mintStore.shopConfig.ethStartingPriceWei;
    if (isToken) {
      price = ethers.BigNumber.from('0');
      let erc20Contract = new ethers.Contract(
        tokenAddress,
        config.erc20ABI,
        signer
      );
      let allowance = ethers.BigNumber.from(0);
      try {
        allowance = await erc20Contract.allowance(
          ethersStore.address,
          shopAddress
        );
      } catch (error) {
        await processError(errMsg(error.message), true, dispatch);
        // give up
        return;
      }
      // TODO: make a more robust check for the exact required allowance
      if (!allowance.gt(0)) {
        let approvalTx = null;
        try {
          approvalTx = await erc20Contract.approve(
            shopAddress,
            ethers.constants.MaxUint256
          ); // TODO: prompt the user for a configurable allowance input
        } catch (error) {
          await processError(errMsg(error.message), true, dispatch);
        }

        await dispatch(
          'alert/info',
          {
            message: 'Transaction Submitted',
            metadata: {
              transaction: approvalTx.hash
            },
            duration: 300000
          },
          { root: true }
        );
        await approvalTx.wait();
      }
    }
  } else {
    price = await shopContract.price();
  }

  let totalSpend = price.mul(qnt);

  let gasPrice = 0;
  await provider.getGasPrice().then(currentGasPrice => {
    gasPrice = ethers.utils.hexlify(parseInt(currentGasPrice));
  });

  /*
  console.info(
    'Minting:',
    qnt,
    proof,
    ethers.utils.formatEther(totalSpend),
    ethers.utils.formatEther(gasPrice)
  );
  */

  let gasLimit = ethers.utils.hexlify('0x100000');
  /*
  let gasLimit = await provider.estimateGas(shopContract.mint(
    qnt,
    proof,
    {
      value: totalSpend,
    }
  ));
  */

  let mintTx = null;
  try {
    mintTx = await shopContract.mint(qnt, proof, {
      value: totalSpend
      //gasPrice: gasPrice,
      //gasLimit: gasLimit
    });
  } catch (error) {
    console.error(error);
    await processError(errMsg(error.message), true, dispatch);
  }

  if (mintTx != null) {
    await dispatch(
      'alert/info',
      {
        message: 'Transaction Submitted',
        metadata: {
          transaction: mintTx.hash
        },
        duration: 300000
      },
      { root: true }
    );

    await mintTx
      .wait()
      .then(async result => {
        //console.info('Result from mint attempt', result);
        await dispatch('alert/clear', '', { root: true });
        await dispatch(
          'alert/info',
          {
            message: 'Transaction Confirmed',
            metadata: {
              transaction: mintTx.hash
            },
            duration: 10000
          },
          { root: true }
        );
      })
      .catch(async function(error) {
        await processError(errMsg(error.message), true, dispatch);
      });
  }
};

async function safeQueryFilter(contract, event, startBlock, endBlock) {
  let start = startBlock
  // let end = await provider.getBlockNumber()
  let end = endBlock
  let endRange = end

  let results = []
  do {
    if (start >= endRange) {
      endRange = end
    }
    let singleTransfers = []
    try {
      singleTransfers = await contract.queryFilter(event, start, endRange);
    } catch (e) {
      let mid = Math.round((start + endRange) / 2)
      endRange = mid
      continue
    }
    results = results.concat(singleTransfers)
    start = endRange + 1
  } while (endRange < end)
  return results
}

// Parse the items owned by a given address.
const loadItems = async function(mintStore, resolveMetadata, dispatch) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider, network, networkId;
  try {
    provider = await ethersService.getProvider();
    network = await provider.getNetwork();
    networkId = ethers.utils.hexValue(network.chainId);
  } catch (error) {
    return;
  }

  let itemCollectionAddress = config.itemCollections[networkId];

  let isValid = ethers.utils.isAddress(itemCollectionAddress);
  if (!isValid) {
    return; // TODO: throw useful error.
  }

  // Check for token spend approval.
  let signer = await provider.getSigner();
  let walletAddress = await signer.getAddress();

  let itemContract = new ethers.Contract(
    itemCollectionAddress,
    config.itemABI,
    provider
  );

  // Check relative item transfer events to determine this user's current
  // inventory.
  let ownershipData = {};
  // Single transfer events.
  let filterToWallet = itemContract.filters.Transfer(null, walletAddress)
  let filterFromWallet = itemContract.filters.Transfer(walletAddress, null)
  let singleTransfers = [
    ...await safeQueryFilter(itemContract, filterToWallet),
    ...await safeQueryFilter(itemContract, filterFromWallet),
  ].sort((a, b) => {
    let block = a.blockNumber - b.blockNumber
    if (block !== 0) {
      return block
    }

    return a.transactionIndex - b.transactionIndex
  })
  /*
  console.info(
    `Processing ${singleTransfers.length} single transfer events ...`
  );
  */
  for (let t of singleTransfers) {
    ownershipData[t.args.tokenId.toNumber()] = {
      collectionAddress: t.address,
      tokenId: t.args.tokenId.toNumber(),
      blockHash: t.blockHash,
      blockNumber: t.blockNumber,
      data: t.data,
      txHash: t.transactionHash,
      txIndex: t.transactionIndex,
      metadata: null,
      owner: t.args.to
    };
  }

  let myItems = Object.values(ownershipData)
    .filter((tokenData) => tokenData.owner === walletAddress);

  const metadataURI = await itemContract.metadataUri();
  for (let item of myItems) {
    let resp = {
      image: `https://impostors-genesis.mypinata.cloud/ipfs/QmWz3XTv2zdq8Pz5reEKgMMNPs5kQ8TJXUpJhb2B67aSgD/${item.tokenId}.png`
    };
    if (resolveMetadata) {
      resp = await axios
        .get(metadataURI + item.tokenId)
        .then(resp => {
          return resp.data;
        })
        .catch(err => {
          return { err: err.message };
        });
    }
    item.metadata = resp
  }
  return myItems
};

// Parse the allowance and number of token minted by a given address.
const loadMintedItems = async function(
  ethersStore, dispatch
) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let shopAddress = config.shopAddress[networkId];
  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );

  let mintedCount = await shopContract.purchaseCounts(ethersStore.address);

  return mintedCount.toNumber();
};

// Parse the allowance and number of token owned by a given address.
const loadTokenInfo = async function(ethersStore, dispatch) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];
  let tokenAddress = config.superTokenAddress[networkId];
  let signer = await provider.getSigner();

  let erc20Contract = new ethers.Contract(
    tokenAddress,
    config.erc20ABI,
    signer
  );
  let allowance = await erc20Contract.allowance(
    ethersStore.address,
    shopAddress
  );
  let balance = await erc20Contract.balanceOf(ethersStore.address);
  let ethBalance = await provider.getBalance(ethersStore.address);

  return {
    tokenBalance: balance,
    hasSuper: balance.gt(0),
    tokenAllowance: allowance,
    hasAllowedTokenAccess: allowance.gt(0),
    ethBalance: ethBalance,
    hasEth: ethBalance.gt(0)
  };
};

const approveSuper = async function({ dispatch }) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];
  let tokenAddress = config.superTokenAddress[networkId];
  let signer = await provider.getSigner();

  let erc20Contract = new ethers.Contract(
    tokenAddress,
    config.erc20ABI,
    signer
  );

  let approvalTx = null;
  let approvalAmount = ethers.constants.MaxUint256;
  try {
    approvalTx = await erc20Contract.approve(shopAddress, approvalAmount); // TODO: prompt the user for a configurable allowance input

    await dispatch(
      'alert/info',
      {
        message: 'Transaction Submitted',
        metadata: {
          transaction: approvalTx.hash
        },
        duration: 300000
      },
      { root: true }
    );
    let result = await approvalTx.wait();
    await dispatch('alert/clear', '', { root: true });
    await dispatch(
      'alert/info',
      {
        message: 'SUPER Approved for Mint',
        metadata: {
          transaction: approvalTx.hash
        },
        duration: 10000
      },
      { root: true }
    );
    return { tx: result, approvalAmount };
  } catch (error) {
    await processError(errMsg(error.message), true, dispatch);
  }
};

// Export the user service functions.
export const mintService = {
  loadShopConfig,
  currentPrice,
  getSoldCount,
  purchaseItem,
  loadItems,
  calcEth,
  loadTokenInfo,
  loadMintedItems,
  approveSuper
};
