<template>
  <component :is="props.as" class="root">
    <div :style="[shapeRootStyles]">
      <div
        v-if="showBorder"
        class="clamp-box"
        :class="props.classClampBox"
        :style="[clampStyles, borderRootStyles]"
      >
        <div class="blur-box" :style="blurStyles">
          <div
            class="skew-box"
            :class="props.classSkewBox"
            :style="[styles, borderStyles]"
          ></div>
        </div>
      </div>
      <div
        class="clamp-box"
        :class="props.classClampBox"
        :style="[clampStyles]"
      >
        <div class="blur-box" :style="blurStyles">
          <div
            class="skew-box"
            :class="props.classSkewBox"
            :style="styles"
          ></div>

          <svg
            style="visibility: hidden; position: absolute"
            width="0"
            height="0"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
          >
            <defs>
              <filter :id="blurFilterId">
                <feGaussianBlur
                  in="SourceGraphic"
                  :stdDeviation="borderRadius"
                />
              </filter>
              <filter :id="clampFilterId">
                <feColorMatrix
                  in="SourceGraphic"
                  mode="matrix"
                  :values="colorMatrix"
                />
              </filter>
              <filter :id="blurClampFilterId">
                <feGaussianBlur
                  in="SourceGraphic"
                  :stdDeviation="borderRadius"
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  :values="colorMatrix"
                  result="clamp"
                />
                <feComposite in="SourceGraphic" in2="clamp" operator="atop" />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    <div class="content" :class="props.classContent" :style="contentStyles">
      <slot name="default" />
    </div>
  </component>
</template>

<script setup>
import { reactive, ref, toRef, computed, defineProps } from 'vue';
import { v4 as uuid } from 'uuid';

let props = defineProps({
  // For the design we want to pass polygon clip paths here.
  // Example: polygon(0 0, 100% 10%, 100% 100%, 3% 100%)
  // Each percentage is in an [x, y] tuple.
  // Order of parameters are (top right, top left, bottom right, bottom left).
  clipPath: {
    type: String
  },
  // 4 numbers to adjust skew of corners
  // Example: [0, -10, 10, 30]
  skew: {
    type: Array,
    default() {
      return [0, 0, 0, 0];
    }
  },
  borderRadius: { type: Number },
  opacity: { type: [String, Number] },
  classContent: { type: String },
  classSkewBox: { type: String },
  classClampBox: { type: String },
  background: { type: String },
  as: {
    type: String,
    default() {
      return 'div';
    }
  },
  clipContent: { type: Boolean, default: false },
  borderColor: { type: String, default: '' },
  borderWidth: { type: String, default: '' },
  borderOpacity: { type: [String, Number], default: '' },
  borderOffset: { type: Array }, // [xOffxet, yOffset]
  method: { type: String }
});

let transformMode = computed(() => props.method === 'transform')

let computePolygon = (skews = []) => {
  let [x1, y1, x2, y2, x3, y3, x4, y4] = Array(8).fill(0);
  let c = { x1, y1, x2, y2, x3, y3, x4, y4 };

  for (let [i, skew] of skews.entries()) {
    if (Array.isArray(skew)) {
      c[`x${i + 1}`] = Math.abs(skew[0]);
      c[`y${i + 1}`] = Math.abs(skew[1]);
    } else {
      let clockwise = i % 2 === 0 ? 'x' : 'y';
      let counterClockwise = i % 2 === 0 ? 'y' : 'x';
      let direction = Math.sign(skew) > 0 ? clockwise : counterClockwise;
      c[`${direction}${i + 1}`] = Math.abs(skew);
    }
  }

  return `polygon(calc(0% + ${c.x1}px) calc(0% + ${c.y1}px), calc(100% - ${c.x2}px) calc(0% + ${c.y2}px), calc(100% - ${c.x3}px) calc(100% - ${c.y3}px), calc(0% + ${c.x4}px) calc(100% - ${c.y4}px))`;
};

// Because of safari issues, we apply blur and clamp filters
// to separate divs to fix weird render errors.
// all other browsers use the combined blur and clamp filter
let isSafari =
  navigator.userAgent.indexOf('Safari') != -1 &&
  navigator.userAgent.indexOf('Chrome') == -1;

let blurFilterId = ref(uuid());
let clampFilterId = ref(uuid());
let blurClampFilterId = ref(uuid());
let blurStyles = computed(() =>
  isSafari
    ? { filter: `url("#${blurFilterId.value}")` }
    : {
        filter: `url("#${blurClampFilterId.value}")`
      }
);
let clampStyles = computed(() =>
  isSafari ? { filter: `url("#${clampFilterId.value}")` } : {}
);

let clipPath = computed(() => props.clipPath ?? computePolygon(props.skew));

const styles = computed(() => ({
  background: props.background,
  clipPath: clipPath.value
}));

const contentStyles = computed(() => ({
  clipPath: props.clipContent ? clipPath.value : ''
}));

let sharp = computed(() => {
  return props.borderRadius * 2;
});
let adjust = computed(() => {
  return props.borderRadius * -1;
});
let colorMatrix = computed(() => {
  return `1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 ${sharp.value} ${adjust.value}`;
});

const shapeRootStyles = computed(() => ({
  opacity: props.opacity
}));

let showBorder = computed(() => !!props.borderWidth || !!props.borderOffset);
const borderRootStyles = computed(() => {
  if (props.borderOffset) {
    let [x, y] = props.borderOffset || [];

    return {
      top: `${parseFloat(y) * -1}px`,
      right: `${parseFloat(x) * -1}px`,
      bottom: `${parseFloat(y)}px`,
      left: `${parseFloat(x)}px`,
      opacity: props.borderOpacity
    };
  }

  return {
    top: `-${props.borderWidth}`,
    right: `-${props.borderWidth}`,
    bottom: `-${props.borderWidth}`,
    left: `-${props.borderWidth}`,
    opacity: props.borderOpacity
  };
});
const borderStyles = computed(() => ({
  background: props.borderColor
}));

let borderRadius = toRef(props, 'borderRadius');

// watchEffect(() => {
//   styles.background = props.background
// });
</script>

<style scoped lang="scss">
.root {
  position: relative;
  border: none;
  background: transparent;
}
.content {
  position: relative;
  width: 100%;
}
.clamp-box {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.blur-box {
  height: 100%;

  .skew-box {
    height: 100%;
    width: 100%;
    border: none;
  }
}
</style>
