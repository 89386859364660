<script setup>
import ConnectWallet from '@/components/common/ConnectWallet.vue';
import Header from '@/pages/layout/Header.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import Divider from '@/components/common/Divider.vue';

import CheckNFT from './components/CheckNFT.vue';
import EligibilityChip from './components/EligibilityChip.vue';
import { EligibilityType, useClaimFeatureIsLive } from './composables';
import { configService } from '@/services';

import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

const isLoading = ref(true);
const store = useStore();

onMounted(() => {
  window.scroll(0, 0);
  configService.cfg();
});

const initialize = async () => {
  isLoading.value = true;

  await loadAll();

  isLoading.value = false;
};

const loadAll = async () => { };

// computed property for wallet connection.
const isConnected = computed(() => {
  return store.state.ethers.canSign;
});

const getStatus = eligibility => (eligibility ? 'unclaimed' : 'claimed');

const claimIsLive = useClaimFeatureIsLive();
</script>

<template>
  <Header :isHome="false" />
  <div :class="s['bg-wrapper']">
    <div :class="s['primary-bg']"></div>
    <div :class="s.fade"></div>
  </div>
  <Ethereum :callback="initialize" />

  <div :class="s.container">
    <div :class="s.fade" />
    <SkewedDiv :class="s.wrapper" :borderRadius="15" background="#2F4EB6" :skew="[0, 2, 10, -10]">
      <div :class="s.header">
        <h1 :class="s.title">Check Eligibility</h1>
        <router-link :to="'/claim'">
          <img :class="s.icon" src="../../images/close-icon.webp" />
        </router-link>
      </div>

      <Divider />

      <template v-if="isLoading">
        <Skeletor
          :style="{
            width: '100%',
            height: '260px',
            background: 'rgba(255,255,255,.18)',
            borderRadius: '12px'
          }"
        />
      </template>
      <template v-else-if="isConnected">
        <SkewedDiv
          :class="s.content"
          :classContent="s.innerContent"
          :borderRadius="8"
          background="#5971C5"
          :skew="[0, 0, 6, -4]"
        >
          <div>
            <h2 :class="s.sectionHeading">Check Impostor Alien</h2>
            <CheckNFT tokenType="impostor" :checkType="EligibilityType.Impostor">
              <template #chips="{ data }">
                <EligibilityChip name="UFO" :status="getStatus(data?.ufoEligible)" />
                <EligibilityChip name="Pet" :status="getStatus(data?.petEligible)" />
                <EligibilityChip
                  v-if="claimIsLive.box"
                  name="Materials Box"
                  :status="getStatus(data?.boxEligible)"
                />
                <EligibilityChip
                  v-if="claimIsLive.chest"
                  name="Cosmetics Chest"
                  :status="getStatus(data?.chestEligible)"
                />
                <EligibilityChip
                  v-if="claimIsLive.pass"
                  name="Beta Pass"
                  :status="getStatus(data?.passEligible)"
                />
				<EligibilityChip
                  v-if="claimIsLive.voxel"
                  name="Voxel"
                  :status="getStatus(data?.voxelEligible)"
                />
              </template>
            </CheckNFT>
          </div>
          <div>
            <h2 :class="s.sectionHeading">Check Impostor UFO</h2>
            <CheckNFT tokenType="ufo" :checkType="EligibilityType.UFO">
              <template #chips="{ data }">
                <EligibilityChip name="Pet" :status="getStatus(data?.petEligible)" />
                <EligibilityChip
                  v-if="claimIsLive.box"
                  name="Materials Box"
                  :status="getStatus(data?.boxEligible)"
                />
                <EligibilityChip
                  v-if="claimIsLive.chest"
                  name="Cosmetics Chest"
                  :status="getStatus(data?.chestEligible)"
                />
                <EligibilityChip
                  v-if="claimIsLive.pass"
                  name="Beta Pass"
                  :status="getStatus(data?.passEligible)"
                />
              </template>
            </CheckNFT>
          </div>
          <div v-if="claimIsLive.box">
            <h2 :class="s.sectionHeading">Check Impostor Pet</h2>
            <CheckNFT tokenType="pet" :checkType="EligibilityType.Pet">
              <template #chips="{ data }">
                <EligibilityChip
                  v-if="claimIsLive.box"
                  name="Materials Box"
                  :status="getStatus(data?.boxEligible)"
                />
                <EligibilityChip
                  v-if="claimIsLive.chest"
                  name="Cosmetics Chest"
                  :status="getStatus(data?.chestEligible)"
                />
                <EligibilityChip
                  v-if="claimIsLive.pass"
                  name="Beta Pass"
                  :status="getStatus(data?.passEligible)"
                />
              </template>
            </CheckNFT>
          </div>
          <div v-if="claimIsLive.chest">
            <h2 :class="s.sectionHeading">Check Impostor Box</h2>
            <CheckNFT tokenType="box" :checkType="EligibilityType.Box">
              <template #chips="{ data }">
                <EligibilityChip
                  v-if="claimIsLive.chest"
                  name="Cosmetics Chest"
                  :status="getStatus(data?.chestEligible)"
                />
                <EligibilityChip
                  v-if="claimIsLive.pass"
                  name="Beta Pass"
                  :status="getStatus(data?.passEligible)"
                />
              </template>
            </CheckNFT>
          </div>
          <div v-if="claimIsLive.pass">
            <h2 :class="s.sectionHeading">Check Impostor Chest</h2>
            <CheckNFT tokenType="chest" :checkType="EligibilityType.Chest">
              <template #chips="{ data }">
                <EligibilityChip
                  v-if="claimIsLive.pass"
                  name="Beta Pass"
                  :status="getStatus(data?.passEligible)"
                />
              </template>
            </CheckNFT>
          </div>
        </SkewedDiv>
      </template>
      <template v-else>
        <div class="connectWalletWrapper">
          <ConnectWallet />
        </div>
      </template>
    </SkewedDiv>
  </div>
</template>

<style module="s" lang="scss">
@import "@/assets/style/mixins.scss";

.bg-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  .fade {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 300px;

    background-image: linear-gradient(
      0deg,
      var(--color-bg-main),
      55%,
      transparent
    );
  }
  .primary-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 934px;
    width: 100%;
    background-image: linear-gradient(
        0deg,
        var(--color-bg-main),
        15%,
        transparent
      ),
      url("../../images/background.webp");
    background-attachment: scroll;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: contain;
    pointer-events: none;
    /* ensures background-size: contain stretches full width */
    min-height: 100vw;
  }
}

.wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0px 40px;
  padding: 50px 70px;

  @include mMax(600px) {
    padding: 24px 36px;
  }
}

.content {
  margin-top: 11px;
  padding: 34px 43px;

  @include mMax(600px) {
    padding: 24px 24px;
  }
}

.innerContent {
  display: flex;
  flex-direction: column;
  gap: 47px;
}

.container {
  width: 100%;
  height: 100%;
  min-height: 90vh;
  padding: 40px;
  padding-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 60px;

  @include mMax(600px) {
    padding-right: 12px;
    padding-left: 12px;
  }
}

.sectionHeading {
  font-size: 30px;
  font-weight: bold;
  color: #f0f907;
  margin-bottom: 24px;
  @include mMax(600px) {
    font-size: 18px;
  }
}

.title {
  flex: 1;
  font-size: 24px;
}

.header {
  display: flex;
}

.icon {
  transform: rotate(-3deg);
  height: 28px;
  width: 28px;
  object-fit: contain;
  transition: 200ms ease-in all;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
}
</style>
