<script setup>
import {computed} from 'vue'
let props = defineProps({
  progress: {type: Number, default: 100},
  size: { type: Number, default: 75 },
  factor: {type: Number, default: 0.3},
  trackColor: {type: String, default: '#EA6679'}
})

let strokeSize = computed(() => props.size * props.factor)
let strokePx = computed(() => `${strokeSize.value}px`)
let radius = computed(() => props.size - (strokeSize.value / 2))
let viewBox = computed(() => `0 0 ${props.size * 2} ${props.size * 2}`)

</script>

<template>
  <svg :viewBox="viewBox" overflow="visible">
    <circle class="track" :cx="props.size" :cy="props.size" :r="radius" pathLength="100" />
    <circle class="progress" :cx="props.size" :cy="props.size" :r="radius" pathLength="100" />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  --stroke-width: v-bind('strokePx');

  .track,
  .progress {
    fill: transparent;
  }

  .progress {
    stroke: v-bind('props.trackColor');
    stroke-width: var(--stroke-width);
    stroke-dasharray: 100;
    stroke-dashoffset: calc(100 - v-bind('props.progress'));
    stroke-linecap: round;
    transform-origin: center;
    transform: rotate(-90deg);
  }
  .track {
    stroke: hsla(0, 0%, 100%, 0.5);
    stroke-width: calc(var(--stroke-width));
  }
}
</style>
