const config = {
  datadog: {
    applicationId: '4c6a8762-f4fb-458a-85c3-9ef09517f1bb',
    clientToken: 'pub8cabb58d08363669f0765c87b50bceba',
    site: 'datadoghq.com',
    service: 'impostors.gg',
    sampleRate: 100,
    trackInteractions: true
  },
};

export default config;
