<template>
  <Header :isHome="false" />
  <div class="bg-wrapper">
    <div class="primary-bg"></div>
    <div class="fade"></div>
  </div>
  <Ethereum :callback="initialize" />

  <SelectItemsModal
    v-if="showClaimModal"
    :items="owned"
    @exit="showClaimModal = false"
    @submit="submitClaimItems"
    title="Select Impostor"
    actionText="Select Impostors"
    :canSelectItem="isEligibleForUFOClaim"
    :bloodBalance="bloodBalance"
    showBloodBalance
  >
    <template #tokenCards>
      <BuyOnGigamart :tokenType="IMPOSTOR_TOKEN_TYPE" />
    </template>

    <template #actionText="{ selectedCount }">
      <span>{{ `Select ${selectedCount === 1 ? 'Impostor' : 'Impostors'}` }}</span>
    </template>
  </SelectItemsModal>

  <CheckoutModalUFO
    v-if="showCheckoutModal"
    :items="checkoutData"
    @exit="checkoutData = null"
    @approve="handleCheckoutApprove"
    @submit="handleCheckoutSubmit"
    title="Checkout"
    :bloodPerItem="ufoBloodCost"
    :isBloodApproved="hasAllowance"
    :isSubmitting="checkoutSubmitting"
    :bloodBalance="bloodBalance"
  />

  <div class="container">
    <div class="fade" />
    <SkewedDiv class="wrapper" :borderRadius="15" background="#2F4EB6" :skew="[0, 2, 10, -10]">
      <div class="header">
        <h1>Claim Items</h1>
      </div>

      <Divider />
      <div class="content">
        <template v-if="isLoading">
          <Skeletor
            :style="{
              width: '100%',
              height: '160px',
              background: 'rgba(255,255,255,.18)',
              borderRadius: '12px'
            }"
          />
        </template>
        <template v-else-if="isConnected">
          <ClaimUFORow
            @claim="showClaimModal = true"
            :enableClaim="hasEligibleItems"
            :bloodCost="ufoBloodCost"
          />
          <ClaimPetRow v-if="claimIsLive.pet" />
          <ClaimBoxRow v-if="claimIsLive.box" />
          <ClaimChestRow v-if="claimIsLive.chest" />
          <!-- <ClaimPassRow v-if="claimIsLive.pass" /> -->
		  <ClaimVoxelRow v-if="claimIsLive.voxel" />
        </template>
        <template v-else>
          <div class="connectWalletWrapper">
            <ConnectWallet />
          </div>
        </template>
      </div>
    </SkewedDiv>

    <ClaimFAQ />
  </div>
</template>
<script setup>
import { Skeletor } from 'vue-skeletor';
import ConnectWallet from '@/components/common/ConnectWallet.vue';
import Header from '@/pages/layout/Header.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import Divider from '@/components/common/Divider.vue';
import ClaimUFORow from './components/ClaimUFORow.vue';
import SelectItemsModal from '@/components/common/SelectItemsModal.vue';
import BuyOnGigamart from '@/components/common/BuyOnGigamart.vue';
import CheckoutModalUFO from './components/CheckoutModalUFO.vue';
import ClaimPetRow from './components/ClaimPetRow.vue';
import ClaimBoxRow from './components/ClaimBoxRow.vue';
import ClaimChestRow from './components/ClaimChestRow.vue';
import ClaimPassRow from './components/ClaimPassRow.vue';
import ClaimVoxelRow from './components/ClaimVoxelRow.vue';
import ClaimFAQ from './components/ClaimFAQ.vue';
import { IMPOSTOR_TOKEN_TYPE, TOKEN_CONFIG } from '@/constants';

import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import useInterval from '/src/utility/useInterval';
import { l } from '@/datadog';
import {
  useClaimFeatureIsLive,
} from './composables';
import { flag } from '@/featureflag';

const isLoading = ref(true);
const store = useStore();

const showClaimModal = ref(false);

const submitClaimItems = ({ nftIds, nfts }) => {
  showClaimModal.value = false;
  checkoutData.value = nfts;
};

const checkoutData = ref(null);
const checkoutSubmitting = ref(false);
const showCheckoutModal = computed(() => !!checkoutData.value);

const handleCheckoutApprove = async () => {
  checkoutSubmitting.value = true;
  await approveRedeemer();
  checkoutSubmitting.value = false;
};

const handleCheckoutSubmit = async ({ nftIds }) => {
  checkoutSubmitting.value = true;
  await redeem(0, nftIds);
  checkoutData.value = null;
  checkoutSubmitting.value = false;
};

onMounted(() => {
  window.scroll(0, 0);
});

const initialize = async () => {
  isLoading.value = true;

  await loadAll();

  isLoading.value = false;
};

const loadAll = async () => {
  let promises = [
    () => loadTokenInfo(),
    () => getClaimDetails(),
    () => loadEligibleItems()
  ];
  return l.timed(promises, { f: 'loadAll' });
};

let owned = computed(
  () => store.state.claim.owned[impostorsCollectionAddress.value]
);

// update current item price every 15 sec
useInterval(async () => {
  await loadTokenInfo();
  await loadEligibleItems();
}, 1000 * 15);

const currentAddress = computed(() => {
  return store.state.ethers.address;
});

// computed property for wallet connection.
const isConnected = computed(() => {
  return store.state.ethers.canSign;
});

const bloodBalance = computed(() => {
  try {
    return parseFloat(store.state.claim.tokenInfo.bloodBalance);
  } catch (error) {
    return 0;
  }
});

const hasAllowance = computed(() => {
  try {
    return store.state.claim.tokenInfo.hasAllowance;
  } catch (error) {
    return false;
  }
});

const impostorsCollectionAddress = computed(() => {
  try {
    return store.state.claim.claimDetails.impostorsCollectionAddress;
  } catch (error) {
    return 0;
  }
});

const ufoCollectionAddress = computed(() => {
  try {
    return store.state.claim.claimDetails.ufoCollectionAddress;
  } catch (error) {
    return 0;
  }
});

const petCollectionAddress = computed(() => {
  try {
    return store.state.claim.claimDetails.petCollectionAddress;
  } catch (error) {
    return 0;
  }
});

const boxCollectionAddress = computed(() => {
  try {
    return store.state.claim.claimDetails.boxCollectionAddress;
  } catch (error) {
    return 0;
  }
});

const chestCollectionAddress = computed(() => {
  try {
    return store.state.claim.claimDetails.chestCollectionAddress;
  } catch (error) {
    return 0;
  }
});

const passCollectionAddress = computed(() => {
  try {
    return store.state.claim.claimDetails.passCollectionAddress;
  } catch (error) {
    return 0;
  }
});

const ufoBloodCost = computed(() => {
  try {
    return parseFloat(
      store.state.claim.claimDetails[ufoCollectionAddress.value].price
    ).toFixed(2);
  } catch (error) {
    return 0;
  }
});

const hasEligibleItems = computed(() => {
  try {
    return (
      impostorsCollectionAddress.value != 0 &&
      store.state.claim.eligibleItems[impostorsCollectionAddress.value].length >
      0
    );
  } catch (error) { }
  return false;
});

const approveRedeemer = async () => {
  await store.dispatch('claim/approveRedeemer', {}, { root: true });
  await loadTokenInfo();
};

const getClaimDetails = async () => {
  if (isConnected.value) {
    await store.dispatch(
      'claim/getClaimDetails',
      {},
      {
        root: true
      }
    );
    //console.log("claimDetails", store.state.claim.claimDetails);
  }
};

const loadEligibleItems = async () => {
  if (isConnected.value) {
    l.timed(
      () =>
        store.dispatch(
          'claim/loadEligibleItems',
          {
            resolveMetadata: false
          },
          {
            root: true
          }
        ),
      { f: 'loadEligibleItems' },
      this
    );
    //console.log("Eligible Items", store.state.claim.eligibleItems);
  }
};

const redeem = async (round, inputTokens) => {
  // redundant check for redeem status of tokens
  let outdatedRedemptionStatus = false;
  await store.dispatch(
    'claim/checkRedeemStatus',
    {
      round: round,
      collection: impostorsCollectionAddress.value,
      tokenIds: inputTokens
    },
    {
      root: true
    }
  );
  outdatedRedemptionStatus =
    store.state.claim.redeemStatus.results.includes(true);
  if (outdatedRedemptionStatus) {
    await store.dispatch(
      'alert/error',
      {
        message:
          'One of the selected items has been redeemed already. Please refresh and try again.',
        duration: 10000
      },
      { root: true }
    );
    return;
  }

  let tokenIds = null;
  // Code has been rewritten for all other claims (ie. PET: see ClaimPetRow.vue, BOX: see ClaimBoxRow.vue, etc)
  switch (round) {
    case 0:
      // redeeming for UFO
      // input will be 1 dimensional array of impostor ids
      // ex: redeem(0, ["1", "2"]
      tokenIds = new Array(inputTokens.length);
      for (let i = 0; i < inputTokens.length; i++) {
        tokenIds[i] = new Array();
        tokenIds[i].push(parseInt(inputTokens[i]));
      }
      break;
  }

  await store.dispatch(
    'claim/redeem',
    {
      round: round,
      tokenIds: tokenIds
    },
    {
      root: true
    }
  );
  await loadEligibleItems();
};

// loads allowance and amount of owned tokens
const loadTokenInfo = async () => {
  if (isConnected.value) {
    l.timed(
      () =>
        store.dispatch(
          'claim/loadTokenInfo',
          {},
          {
            root: true
          }
        ),
      { f: 'loadTokenInfo' },
      this
    );
    //console.info("Balances:", store.state.claim);
  }
};

watch(currentAddress, (curAdd, oldAdd) => {
  if (curAdd == null) {
    // wallet has locked or disconnected altogether
    // TODO: lookup how to reset store
    store.state.ethers.canSign = false;
    store.state.claim.tokenInfo.bloodBalance = 0;
    store.state.claim.tokenInfo.redeemerAllowance = 0;
    store.state.claim.tokenInfo.hasAllowance = false;
    store.state.claim.claimDetails = {};
    store.state.claim.eligibleItems = {};
    store.dispatch('login/initialize');
    l.unsetUser();
    flag.unsetUser();
  }

  // essentially waits until wallet is ready
  if (curAdd !== oldAdd) {
    l.setUser(curAdd);
    flag.setUser(curAdd);
    ensureWalletConnected(400000)
      .then(loadAll)
      .catch(err => {
        let do_nothing = err;
      });
  }
});

// this following function should NOT be required and
// we should be able to watch over isConnected
// TODO: either get the watch to work or move this into ethers.service
function ensureWalletConnected(timeout) {
  var start = Date.now();
  return new Promise(waitForWallet);

  // waitForWallet makes the decision whether the condition is met
  // or not met or the timeout has been exceeded which means
  // this promise will be rejected
  function waitForWallet(resolve, reject) {
    // check if wallet connect has ever been enabled in the past
    store.commit('checkWalletConnectStore');
    // if we have connected previously, dispatch automagic connection
    if (store.state?.walletconnect?.connected) {
      store.dispatch('ethers/connectWCProvider');
    }
    if (store.state?.ethers?.canSign) resolve(store.state.ethers.canSign);
    else if (timeout && Date.now() - start >= timeout)
      reject(new Error('timeout'));
    else setTimeout(waitForWallet.bind(this, resolve, reject), 30);
  }
}

const eligibleIdsUFOClaim = computed(() => {
  let filterBy = 'ufoCollections';
  return eligibleIds(filterBy);
});

const eligibleIdsPETClaim = computed(() => {
  let filterBy = 'petCollections';
  return eligibleIds(filterBy);
});

const eligibleIds = filterBy => {
  let ids = {
    impostors: [],
    ufos: [],
    pets: [],
    boxes: [],
    chests: [],
    passes: []
  };

  try {
    for (let imp of store.state.claim.eligibleItems[
      impostorsCollectionAddress.value
    ].filter(x => x.claim == filterBy)) {
      imp.items.forEach(x => ids.impostors.push(x.tokenId));
    }
    for (let imp of store.state.claim.eligibleItems[
      ufoCollectionAddress.value
    ].filter(x => x.claim == filterBy)) {
      imp.items.forEach(x => ids.ufos.push(x.tokenId));
    }
    for (let imp of store.state.claim.eligibleItems[
      petCollectionAddress.value
    ].filter(x => x.claim == filterBy)) {
      imp.items.forEach(x => ids.pets.push(x.tokenId));
    }
    for (let imp of store.state.claim.eligibleItems[
      boxCollectionAddress.value
    ].filter(x => x.claim == filterBy)) {
      imp.items.forEach(x => ids.boxes.push(x.tokenId));
    }
    for (let imp of store.state.claim.eligibleItems[
      chestCollectionAddress.value
    ].filter(x => x.claim == filterBy)) {
      imp.items.forEach(x => ids.chests.push(x.tokenId));
    }
    for (let imp of store.state.claim.eligibleItems[
      passCollectionAddress.value
    ].filter(x => x.claim == filterBy)) {
      imp.items.forEach(x => ids.passes.push(x.tokenId));
    }
  } catch (er) { }

  return ids;
};

const isEligibleForUFOClaim = item =>
  eligibleIdsUFOClaim.value.impostors.includes(item.tokenId);

const claimIsLive = useClaimFeatureIsLive();
</script>
<style scoped lang="scss">
.bg-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  .fade {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 300px;

    background-image: linear-gradient(
      0deg,
      var(--color-bg-main),
      55%,
      transparent
    );
  }
  .primary-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 934px;
    width: 100%;
    background-image: linear-gradient(
        0deg,
        var(--color-bg-main),
        15%,
        transparent
      ),
      url("../../images/background.webp");
    background-attachment: scroll;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: contain;
    pointer-events: none;
    /* ensures background-size: contain stretches full width */
    min-height: 100vw;
  }
}

.wrapper {
  width: 100%;
  margin: 0px 40px;
  padding: 50px 70px;
  @media (max-width: 700px) {
    padding: 24px 36px;
  }
}

.content {
  margin-top: 48px;
  display: grid;
  gap: 48px;
}

.button {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: bold;
  padding-right: 69px;
  padding-left: 70px;
}

/* .claim-row { */
/*   margin-top: 48px; */
/* } */

.container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  height: 100%;
  min-height: 90vh;
  padding: 40px;
  padding-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 60px;

  @media (max-width: 700px) {
    padding-left: 12px;
    padding-right: 12px;
  }
}
</style>
