<template>
	<Header :is-home="false" />
	<div class="bg-wrapper">
	  <div class="primary-bg" />
	  <div class="fade" />
	</div>
	<Ethereum :callback="initialize" />
  
	<div class="container">
	  <div class="fade" />
	  <SkewedDiv
		class="wrapper"
		:border-radius="15"
		background="#2F4EB6"
		:skew="[0, 2, 10, -10]"
	  >
		<div class="header">
		  <h1>Account Settings</h1>
		</div>
  
		<Divider />
		<div class="content">
		  <template v-if="isLoading">
			<Skeletor
			  :style="{
				width: '100%',
				height: '160px',
				background: 'rgba(255,255,255,.18)',
				borderRadius: '12px'
			  }"
			/>
		  </template>
		  <template v-else-if="isImpostorsLoggedIn">
			<h2>Added Wallets</h2>
			<div class="wallet-content">
			  <div
				v-for="wallet in loggedInUser.Wallets"
				:key="`${wallet}`"
			  >
				<SkewedDiv
				  class="linked-wallet-button"
				  :border-radius="5"
				  :skew="[0, 0, 8, -3]"
				  background="#212121"
				>
				  <div class="col col_info">
					<p class="description">
					  {{ wallet }}
					</p>
				  </div>
				  <div class="closeIcon">
					<CloseIcon @click="removeWallet(wallet)" />
				  </div>
				</SkewedDiv>
				<div v-if="inventory[wallet.toLowerCase()]">
					<SkewedDiv 
						v-for="(walletItem, i) in inventory[wallet.toLowerCase()]"
						class="linked-item"
						:key="i"
						:border-radius="5"
						:skew="[0, 0, 8, -3]"
						background="#FF0066"
					>
					IMPOSTOR #{{ walletItem }}

					</SkewedDiv>
				</div>
				
			  </div>
			</div>
  
			<SkewedButton
			  v-if="isImpostorsLoggedIn && currentAddress"
			  class="add-wallet-button"
			  background="yellow"
			  :skew="[0, 0, [-3, 0], [-3, 0]]"
			  :border-radius="3"
			  @click="addWallet()"
			>
			  <span class="item-text">+ add a wallet</span>
			</SkewedButton>
  
			<SkewedButton
			  v-if="!currentAddress"
			  class="add-wallet-button"
			  background="yellow"
			  :skew="[0, 0, [-3, 0], [-3, 0]]"
			  :border-radius="3"
			  @click="connectWallet()"
			>
			  <span class="item-text">connect wallet</span>
			</SkewedButton>
  
			<h2>Linked Impostors Account</h2>
			<SkewedDiv
			  class="linked-impostor-button"
			  :border-radius="5"
			  :skew="[0, 0, 8, -3]"
			  background="#212121"
			>
			  <div class="col col_info">
				<h2>{{ loggedInUser?.Email }}</h2>
			  </div>
			</SkewedDiv>
		  </template>
		  <template v-else>
			<div class="connectWalletWrapper">
			  <ConnectWallet />
			</div>
		  </template>
		</div>
	  </SkewedDiv>
	</div>
	<div class="walletModal__overlay" @click="exitModal" v-if="showWalletModal===true"></div>
	<div
      v-if="showWalletModal===true"
      class="modal"
    >
      <!-- <p>{{ modalPrompt }}</p> -->
      <div class="wallet-provider" @click="handleMetaMask">
        <p><span >Get</span> MetaMask</p>
        <img src="../../images/metamask-icon.svg" />
      </div>
      <br />
	  <br />
      <div class="wallet-provider" @click="handleWalletConnect">
        <p>WalletConnect</p>
        <img src="../../images/walletconnect-logo.svg" />
      </div>
    </div>
  </template>
  <script setup>
  import { computed, onMounted, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import { Skeletor } from 'vue-skeletor';
  import { accounts } from '@/account';
  import { l } from '@/datadog';
  import { ethersService, configService } from '@/services';
  import { flag } from '@/featureflag';
  import { processError } from '@/utility';
  import { formatAddress } from '@/utility/format';
  import useInterval from '@/utility/useInterval';
  
  import ConnectWallet from '@/components/common/ConnectWallet.vue';
  import Ethereum from '@/components/common/Ethereum.vue';
  import SkewedDiv from '@/components/common/SkewedDiv.vue';
  import SkewedButton from '@/components/common/SkewedButton.vue';
  import Divider from '@/components/common/Divider.vue';
  import CloseIcon from '@/components/icons/CloseIcon.vue';
  import Header from '@/pages/layout/Header.vue';
  
  const isLoading = ref(true);
  let showWalletModal = ref(false);
  const store = useStore();
  
  // load global config
  let { data: config, isLoading: configIsLoading } = configService.cfg();
  
  const { isImpostorsLoggedIn, loggedInUser } = accounts.status(store);
  
  onMounted(() => {
	window.scroll(0, 0);
  });
  
  // update every 60 sec
  useInterval(async () => {
	await loadAll();
  }, 1000 * 60);
  
  const initialize = async () => {
	isLoading.value = true;
	await loadAll();
	isLoading.value = false;
  };
  
  const loadAll = async () => {};
  
  const currentAddress = computed(() => {
	return store.state.ethers.address;
  });
  
  const displayModal = computed(() => {
	return showWalletModal;
  });

  const inventory = computed(() => {
	let items = [];
	for(let item of loggedInUser.value.Inventory){
		if(items[item.CustomData.WalletAddress.toLowerCase()]){
			items[item.CustomData.WalletAddress.toLowerCase()].push(item.CustomData.ID)
		}else{
			items[item.CustomData.WalletAddress.toLowerCase()] = [item.CustomData.ID]
		}
	}
	return items;
  });

  // computed property for wallet connection.
  const isConnected = computed(() => {
	return store.state.ethers.canSign && currentAddress.value && config.value;
  });
  
  const proveOwnership = async ({ curAdd, message }) => {
	try {
	  const { sig, signerAddress } = await ethersService.proveOwnership(message);
	  return {
		sig: sig,
		isOwner: signerAddress.toUpperCase() == curAdd.toUpperCase(),
		actualOwner: signerAddress
	  };
	} catch (err) {
	  return false;
	}
  };
  
  const jwtChallenge = computed(() => {
	return store.state.jwt.challenge;
  });
  
  const hasChallenge = computed(() => {
	return jwtChallenge.value != null;
  });
  
  const jwtAuthToken = computed(() => {
	return store.state.jwt.authToken;
  });
  
  const hasAuthToken = computed(() => {
	return jwtAuthToken.value != null;
  });
  
  const jwtRefreshToken = computed(() => {
	return store.state.jwt.refreshToken;
  });
  
  const jwtTokenAddress = computed(() => {
	return store.state.jwt.tokenAddress;
  });
  
  const hasRefreshToken = computed(() => {
	return jwtRefreshToken.value != null;
  });
  
  const jwtVerification = computed(() => {
	return store.state.jwt.verificationAddress;
  });
  
  const _fail = async (msg, dispatch) => {
	await processError(msg, false, dispatch);
  };
  
  // kicks off a series of network calls the jwt provider (cloudflare worker `auth-production`)
  const getJwtToken = async errorMessage => {
	// if the token we have matches the address being tested, no need for any external calls
	if (hasAuthToken.value && jwtTokenAddress.value == currentAddress.value)
	  return;
  
	await store.dispatch(
	  'jwt/auth',
	  {
		address: currentAddress.value,
		errorMessage: errorMessage
	  },
	  {
		root: true
	  }
	);
	if (!hasChallenge.value) throw _fail(errorMessage, store.dispatch);
  
	const { sig, isOwner, actualOwner } = await proveOwnership({
	  curAdd: currentAddress.value, // address of the wallet that was just used to sign the message
	  message: jwtChallenge.value.message
	});
	if (!isOwner) throw _fail(errorMessage, store.dispatch);
  
	await store.dispatch(
	  'jwt/login',
	  {
		address: currentAddress.value,
		challenge: jwtChallenge.value.message,
		signature: sig,
		errorMessage: errorMessage
	  },
	  {
		root: true
	  }
	);
	if (!hasAuthToken.value) throw _fail(errorMessage, store.dispatch);
  };
  
  const addWallet = async () => {
	const actionErr = ' Please try to add this wallet again.';
	let errorMessage = 'Error during authentication process.' + actionErr;
	if (isConnected.value) {
		console.log("config", config.value.forceNetwork)
		let wallets = new Set(loggedInUser.value.Wallets);
		for(let wallet of wallets){
			if(wallet.toLowerCase() === currentAddress.value.toLowerCase()){
				await store.dispatch('alert/clear', '', { root: true });
				await store.dispatch(
				'alert/warning',
				{
					message: 'Wallet already exists, please connect to a different account',
					duration: 10000
				},
				{ root: true }
				);
				throw "wallet exists";
			}
		}

	  try {
		await store.dispatch('alert/clear', '', { root: true });
		await store.dispatch(
		  'alert/info',
		  {
			message: 'Awaiting confirmation...',
			duration: 10000
		  },
		  { root: true }
		);
  
		await getJwtToken(errorMessage);
  
		// query cloudflare durable-objects worker (indexer) to see current PlayFabId of this wallet (if it exists)
		const { walletOwner } = await accounts.indexerQuery({
		  host: config.value.playFabIndexerProvider,
		  addr: currentAddress.value,
		  token: jwtAuthToken.value
		});
		if (
		  walletOwner() != '' &&
		  walletOwner() != loggedInUser.value.PlayFabId
		) {
		  // wallet registered to another user. reclaim it via cloudflare worker (playfab-admin-api)
		  const response = await fetch(
			config.value.playFabAdminProvider + 'reclaim/',
			{
			  method: 'POST',
			  headers: {
				'Content-Type': 'application/json'
			  },
			  body: JSON.stringify({
				address: currentAddress.value,
				token: jwtAuthToken.value,
				currentOwner: walletOwner()
			  })
			}
		  );
		  const resp = await response.json();
		  if (resp.error != null) {
			await store.dispatch('alert/clear', '', { root: true });
			throw null;
		  }
		}
  

		wallets.add(currentAddress.value);
  
		const { updateUserData } = accounts.impostorsUpdateUserData({
		  data: { Ethereum: [...wallets].toString().replace(/\s/g, '') }
		});
		updateUserData(); // no-op
  
		const { updatedWalletOwner } = await accounts.indexerUpdate({
		  host: config.value.playFabIndexerProvider,
		  pfID: loggedInUser.value.PlayFabId,
		  addr: currentAddress.value,
		  token: jwtAuthToken.value
		});
  
		updatedWalletOwner(); // no-op
  
		await store.dispatch('alert/clear', '', { root: true });
	  } catch (err) {
		const errMsg = `Adding a new wallet failed. Please try again`;
		await processError(errMsg, true, store.dispatch);
		return;
	  }
	}
  };
  
  const removeWallet = async addr => {
	const actionErr = ' Please try to remove this wallet again.';
	let errorMessage = 'Error during authentication process.' + actionErr;
	try {
	  await store.dispatch('alert/clear', '', { root: true });
	  await store.dispatch(
		'alert/info',
		{
		  message: 'Awaiting confirmation...',
		  duration: 10000
		},
		{ root: true }
	  );
  
	  // removing a wallet is a single call (/revoke) that resolves to a multi-step process:
	  // we hit revoke (cloudflare pf-admin worker) which will check if the user has that
	  // wallet (on playfab), and if they do, will revoke all their related inventory,
	  // then will remove the wallet from the users' playfab account.
	  // The indexer (cloudflare indexer worker) is responsible for mapping
	  // the walletAddress=>playFabID - and is required to allow for lookups. The indexer
	  // however, is protected through JWT tokens, which would then force the user to sign a request
	  // before removing a wallet... we don't that from the usability point of view. So the workaround
	  // is to remove from playfab, and keep it on the indexer. This out-of-sync status is fine,
	  // as long as the playfab-watcher code (node code that periodically queries the contract
	  // and syncs up any transfers to the playfab inventory) also takes this into consideration.
	  //
	  // TL;DR: remove wallet from user' wallet on playfab, and since we remove their wallet,
	  // we will also revoke all tokens (from this wallet) from playfab's inventory
	  const response = await fetch(
		config.value.playFabAdminProvider + 'revoke/',
		{
		  method: 'POST',
		  headers: {
			'Content-Type': 'application/json'
		  },
		  body: JSON.stringify({
			address: addr.toUpperCase(),
			pfID: loggedInUser.value.PlayFabId
		  })
		}
	  );
	  const resp = await response.json();
	  if (resp.error != null) {
		await store.dispatch('alert/clear', '', { root: true });
		throw null;
	  }
	  let wallets = new Set(loggedInUser.value.Wallets);
	  wallets.delete(addr);
	  loggedInUser.value.Wallets = [...wallets];
	  const { updateUserData } = accounts.impostorsUpdateUserData({
		  data: { Ethereum: [...wallets].toString().replace(/\s/g, '') }
	  });
	  updateUserData(); // no-op
	  await store.dispatch('alert/clear', '', { root: true });
	} catch (err) {
	  const errMsg = `Removing wallet failed. Please try again`;
	  await processError(errMsg, true, store.dispatch);
	  return;
	}
  };
  
  const connectWallet = async () => {
	showWalletModal.value = true;
	console.log("showWalletModal", showWalletModal.value);
	// await store.dispatch('alert/showWalletModal', '', { root: true });
  };

  const exitModal = async () => {
	showWalletModal.value = false;
  };

  const handleMetaMask = async () => {
	await store.dispatch('ethers/connectWallet', '', { root: true });
	showWalletModal.value = false;
  };

  const handleWalletConnect = async () => {
	showWalletModal.value = false;
	await store.dispatch('ethers/connectWCProvider', '', { root: true });
  };
  
  watch(currentAddress, (curAdd, oldAdd) => {
	if (curAdd == null) {
	  // wallet has locked or disconnected altogether
	  // TODO: lookup how to reset store
	  store.state.ethers.canSign = false;
	  store.state.jwt.authToken = null;
	  store.dispatch('login/initialize');
	  l.unsetUser();
	  flag.unsetUser();
	}
  
	// essentially waits until wallet is ready
	if (curAdd !== oldAdd) {
	  l.setUser(curAdd);
	  flag.setUser(curAdd);
	  ensureWalletConnected(400000)
		.then(loadAll)
		.catch(err => {
		  let do_nothing = err;
		});
	}
  });
  
  
  watch(isImpostorsLoggedIn, (curState, oldState) => {
	if (oldState && !curState) {
	  router.push({ path: `/account` });
	}
  });
  
  
  // this following function should NOT be required and
  // we should be able to watch over isConnected
  // TODO: either get the watch to work or move this into ethers.service
  function ensureWalletConnected(timeout) {
	var start = Date.now();
	return new Promise(waitForWallet);
  
	// waitForWallet makes the decision whether the condition is met
	// or not met or the timeout has been exceeded which means
	// this promise will be rejected
	function waitForWallet(resolve, reject) {
	  // check if wallet connect has ever been enabled in the past
	  store.commit('checkWalletConnectStore');
	  // if we have connected previously, dispatch automagic connection
	  if (store.state?.walletconnect?.connected) {
		store.dispatch('ethers/connectWCProvider');
	  }
	  if (store.state?.ethers?.canSign) resolve(store.state.ethers.canSign);
	  else if (timeout && Date.now() - start >= timeout)
		reject(new Error('timeout'));
	  else setTimeout(waitForWallet.bind(this, resolve, reject), 30);
	}
  }
  </script>
  <style scoped lang="scss">
  .closeIcon {
	align-items: center;
	border-radius: 9px;
	bottom: auto;
	color: #fff;
	cursor: pointer;
	display: flex;
	height: 20px;
	justify-content: center;
	left: auto;
	position: absolute;
	right: -12%;
	top: 0%;
	transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
	width: 20px;
  
	&:hover {
	  background-color: hsla(0, 0%, 100%, 0.1);
	}
  
	&:active {
	  background-color: hsla(0, 0%, 100%, 0.2);
	}
  }
  
  .linked-impostor-button {
	max-width: 400px;
	padding: 12px;
	padding-left: 30px;
	padding-right: 36px;
  
	:deep(.claim-row-content) {
	  align-items: center;
	  display: flex;
	}
  }
  
  .linked-wallet-button {
	//max-width: 200px;
	padding: 12px;
	padding-left: 30px;
	padding-right: 66px;
  
	:deep(.claim-row-content) {
	  align-items: center;
	  display: flex;
	}
  }

  .linked-item {
	margin-top:5px;
	padding:5px;
	padding-left:30px;
  }
  
  .add-wallet-button {
	font-size: 13px;
	font-weight: bold;
	max-width: 250px;
	padding: 12px;
	padding-left: 30px;
	padding-right: 36px;
  }
  
  .bg-wrapper {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
  
	.fade {
	  background-image: linear-gradient(0deg,
	  var(--color-bg-main),
	  55%,
	  transparent);
	  bottom: 0;
	  height: 300px;
	  left: 0;
	  position: absolute;
	  right: 0;
	}
  
	.primary-bg {
	  background-attachment: scroll;
	  background-image: linear-gradient(0deg,
	  var(--color-bg-main),
	  15%,
	  transparent),
		url('../../images/background.webp');
	  background-repeat: no-repeat;
	  background-size: contain;
	  bottom: 0;
	  height: 934px;
	  /* ensures background-size: contain stretches full width */
	  min-height: 100vw;
	  overflow: hidden;
	  pointer-events: none;
	  position: absolute;
	  top: 0;
	  width: 100%;
	}
  }
  
  .wrapper {
	margin: 0 40px;
	padding: 50px 70px;
	width: 100%;
	@media (max-width: 700px) {
	  padding: 24px 36px;
	}
  }
  
  .content {
	display: grid;
	gap: 48px;
	margin-top: 48px;
  }
  
  .wallet-content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 40px;
  }
  
  .button {
	font-size: 20px;
	font-weight: bold;
	padding-left: 70px;
	padding-right: 69px;
	text-transform: capitalize;
  }
.walletModal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 400px;
  padding: 40px;
  background-color: var(--color-accent-blue);
  color: white;
  /* border: 1px solid white; */
  border-radius: 24px;
}
.modal > p {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 1.3em;
  font-weight: 600;
  text-align: center;
}

.wallet-provider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  transition: box-shadow 200ms ease;
  font-size: 16px;
  font-weight: 500;
  height: 70px;
  padding: 15px 25px;
}

.wallet-provider:hover {
  cursor: pointer;
  box-shadow: 0 8px 15px 0 rgba(255, 255, 255, 0.25);
}

.wallet-provider:active {
  box-shadow: none;
}
  /* .claim-row { */
  /*   margin-top: 48px; */
  /* } */
  
  .container {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: auto;
	max-width: 1200px;
	min-height: 90vh;
	padding: 40px;
	padding-bottom: 60px;
	padding-top: 110px;
	position: relative;
	width: 100%;
  
	@media (max-width: 700px) {
	  padding-left: 12px;
	  padding-right: 12px;
	}
  }
  </style>
  