<template>
  <div class="wrapper">
    <div class="what-is-area">
      <div class="what-is-area__cards">
        <div
          v-for="(column, columnId) of cards"
          :key="columnId"
          class="what-is-area__cards-column"
          :class="`what-is-area__cards-column-${columnId+1}`"
        >
          <ImpostorSilhouetteCard
            v-for="({ impostor, background, hideable }, cardId) of column"
            :key="cardId"
            :impostor="impostor"
            :background="background"
            class="what-is-area__card"
            :class="{ hideable }"
          />
        </div>
      </div>
      <div class="what-is-area__what-is">
        <WhatIsItem
          :title="HOME_WHAT_IS_ITEM.title"
          :subcontent="HOME_WHAT_IS_ITEM.subcontent"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ImpostorSilhouetteCard, WhatIsItem } from '@/components/common';
import { HOME_WHAT_IS_ITEM, IMPOSTORS_SILHOUETTE_BACKGROUND_TYPES } from '@/constants';

const store = useStore();

const cards = [
  [
    { background: IMPOSTORS_SILHOUETTE_BACKGROUND_TYPES.PINK, impostor: 1 },
    { background: IMPOSTORS_SILHOUETTE_BACKGROUND_TYPES.BLUE, impostor: 2 }
  ],
  [
    { background: IMPOSTORS_SILHOUETTE_BACKGROUND_TYPES.BLUE, impostor: 3 },
    { background: IMPOSTORS_SILHOUETTE_BACKGROUND_TYPES.ORANGE, impostor: 4 }
  ]
];
</script>

<style scoped lang="scss">
@import '../../../assets/style/mixins.scss';
.what-is-area {
  @include flex(row, flex-start, flex-start);
  width: 100%;
  padding: 0 48px 0 532px;
  height: 480px;
  position: relative;
  background-color: #412A51;

  &__cards {
    @include flex(row, flex-start, flex-start);
    position: absolute;
    bottom: 0px;
    left: -64px;
    column-gap: 32px;

    &-column {
      @include flex(column, flex-start);
      row-gap: 24px; 

      &-2 {
        margin-top: 96px;
      }
    }
  }

  &__what-is {
    max-width: 640px;
    margin-left: 110px;
    @include flex(column, flex-start);
    row-gap: 36px;
  }
}

@include mediaOnlyScreenMax(1280px) {
  .what-is-area {

    &__what-is {
      margin-left: 54px;
    }
  }
}

@include mediaOnlyScreenMax(1050px) {
  .what-is-area {
    @include flex(column, flex-start, center);
    padding: 480px 24px 60px;
    height: initial;

    &__cards {
      top: -180px;
      left: calc(50% - 299px);
    }

    &__what-is {
      margin: 36px 24px 0;
      @include flex(column, flex-start, center);
      row-gap: 36px;
    }
  }
}

@include mediaOnlyScreenMax(640px) {
  .what-is-area {
    padding: 220px 24px 60px;
    &__cards {
      column-gap: 16px;
      top: -160px;
      left: calc(50% - 173px);

      &-column {
        row-gap: 16px;

        &-2 {
          margin-top: 64px;
        }
      }
    }
  }
}
</style>
